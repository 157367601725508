import React from "react";
import { Form } from "@redriver/cinnamon";
import { getClients } from "./actions";

const ClientsDropdown = (props) => (
    <Form.Dropdown {...props} 
        lookupAction={getClients}
        lookupOptions={{
            transform: data => data.map(i => ({ value: i.id, text:  i.name }))
        }} 
    />
);

export default ClientsDropdown;