import { apiPost, apiGet, apiPut, apiDelete } from "@redriver/cinnamon";

const namespace = "ADMIN/USAGE_TYPES",
  ActionTypes = {
    CreateUsageType: `${namespace}/CREATE_USAGE_TYPE`,
    GetUsageTypes: `${namespace}/GET_ALL_USAGE_TYPES`,
    GetAllUsageTypes: `${namespace}/GET_USAGE_TYPES`,
    UpdateUsageType: `${namespace}/UPDATE_USAGE_TYPE`,
    DeleteUsageType: `${namespace}/DELETE_USAGE_TYPE`,
  };

export const createUsageType = (data = {}, params = {}) =>
  apiPost(ActionTypes.CreateUsageType, "large-format/usage-types", {
    ...data,
    ...params,
  });

export const getUsageTypes = ({ pagination }, params) =>
  apiGet(ActionTypes.GetUsageTypes, "large-format/usage-types", params);

export const getAllUsageTypes = ({ dealerOfficeId }) =>
  apiGet(
    ActionTypes.GetUsageTypes,
    `large-format/all-usage-types/${dealerOfficeId}`
  );

export const updateUsageType = (data = {}, { id, imageData }) => {
  const imageObject = !imageData
    ? null
    : {
        name: data.image.name,
        content: imageData.split(",")[1],
        size: data.image.size,
        contentType: data.image.type,
      };

  return apiPut(ActionTypes.UpdateUsageType, `large-format/usage-types/${id}`, {
    image: imageObject,
  });
};

export const deleteUsageType = ({ id }) =>
  apiDelete(ActionTypes.DeleteUsageType, `large-format/usage-types/${id}`);
