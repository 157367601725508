import React, { useState } from "react";
import { Table, Button } from "semantic-ui-react";
import { getQS, getCSB } from ".";
import { ListBuilder, Table as CinTable } from "@redriver/cinnamon";
import { numericFormatter } from "components/displays/DisplayHelper";
import { SortableHeader } from "../../../../../../shared/components/tables/sortableTable";
import { InfoModalWrapper } from "components/modals/";

export const QuarterlyServices = ({ isSummary }) => {
  const [showManaged, setShowManaged] = useState(false);

  let headers = [
    { field: "service", title: "Service", key: 1 },
    { field: "payableTo", title: "Payable To", key: 2 },
    { field: "value", title: `Value (${process.env.MAJOR_CURRENCY})`, key: 3 },
    {
      field: "managed",
      title: `Managed (${process.env.MAJOR_CURRENCY})`,
      key: 4,
      className: "light-red-highlight",
      display: !isSummary && showManaged,
    },
    {
      field: "nonManaged",
      title: `Non-Managed (${process.env.MAJOR_CURRENCY})`,
      key: 5,
      className: "light-red-highlight",
      display: !isSummary && showManaged,
    },
    {
      field: "",
      title: "",
      className: "empty",
      key: 6,
      display: !(!isSummary && showManaged),
      sortable: false,
    },
    {
      field: "",
      title: "",
      className: "empty",
      key: 7,
      display: !(!isSummary && showManaged),
      sortable: false,
    },
    { field: "", title: "", className: "empty", key: 8, sortable: false },
    {
      field: "scheduleSettlementPercent",
      title: "%",
      className: "red-highlight",
      display: !isSummary,
      key: 9,
    },
    {
      field: "scheduleSettlement",
      title: `${process.env.MAJOR_CURRENCY}`,
      className: "red-highlight",
      display: !isSummary,
      key: 10,
    },
    {
      field: "scheduleSettlementManaged",
      title: `Managed (${process.env.MAJOR_CURRENCY})`,
      className: "light-red-highlight",
      display: !isSummary && showManaged,
      key: 11,
      colSpan: 2,
    },
    {
      field: "scheduleSettlementNonManaged",
      title: `Non-Managed (${process.env.MAJOR_CURRENCY})`,
      className: "light-red-highlight",
      display: !isSummary && showManaged,
      key: 12,
    },
    {
      field: "outstanding",
      title: `Total Liability Values (${process.env.MAJOR_CURRENCY})`,
      display: isSummary,
      key: 13,
    },
    {
      field: "",
      title: "",
      className: "empty",
      key: 14,
      display: isSummary,
      sortable: false,
    },
    {
      field: "",
      title: "",
      className: "empty",
      key: 15,
      display: isSummary,
      sortable: false,
    },
    {
      field: "settlement",
      title: `Custom Settlement Values (${process.env.MAJOR_CURRENCY})`,
      className: "yellow-highlight",
      display: isSummary,
      key: 16,
    },
    {
      field: "",
      title: "",
      className: "empty",
      key: 17,
      display: !(!isSummary && showManaged),
      sortable: false,
    },
  ];

  return (
    <ListBuilder
      loadAction={getQS}
      renderList={(data, state, events) =>
        data.qs && (
          <React.Fragment>
            {!isSummary && (
              <Button
                primary
                icon={showManaged ? "right minus" : "right plus"}
                content="Managed/Non Managed"
                onClick={() => setShowManaged(!showManaged)}
              />
            )}
            <Table
              className={
                "liability-quarterly-services right-align " +
                (showManaged && "managed")
              }
            >
              {!isSummary && (
                <React.Fragment>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        className="empty border-right"
                        colSpan={6}
                      ></Table.HeaderCell>
                      <Table.HeaderCell
                        className="red-highlight settlement-title"
                        colSpan={2}
                      >
                        Schedule Settlement
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                </React.Fragment>
              )}
              <SortableHeader columns={headers} state={state} events={events} />
              <Table.Body>
                {data.qs.map((d) => (
                  <TableRowQS
                    key={d.id}
                    data={d}
                    showManaged={showManaged}
                    isSummary={isSummary}
                  />
                ))}
                {!isSummary && (
                  <React.Fragment>
                    <EmptyRow showManaged={showManaged} isSummary={isSummary} />
                    <TableRowQS
                      isSummary={isSummary}
                      showManaged={showManaged}
                      data={{
                        service: "Calc Rounding",
                        payableTo: data.calcRoundingPayaleTo,
                        cfaPayment: data.calcRoundingCFAPayment,
                        value: data.calcRoundingValue,
                        managed: data.calcRoundingManged,
                        nonManaged: data.calcRoundingNonManged,
                        scheduleSettlementPercent:
                          data.calcRoundingScheduleSettlementPercent,
                        scheduleSettlement: data.calcRoundingScheduleSettlement,
                        scheduleSettlementManaged:
                          data.calcRoundingScheduleSettlementManaged,
                        scheduleSettlementNonManaged:
                          data.calcRoundingScheduleSettlementnonManaged,
                      }}
                    />
                  </React.Fragment>
                )}
                <BlankRow />
                <BlankRow />
                <CalcRow
                  isSummary={isSummary}
                  showManaged={showManaged}
                  data={data}
                />
                <BlankRow isSummary={isSummary} />
                {isSummary && (
                  <React.Fragment>
                    {" "}
                    <EmptyFooterRow
                      isSummary={isSummary}
                      showManaged={showManaged}
                    />{" "}
                  </React.Fragment>
                )}
                <EmptyFooterRow
                  isSummary={isSummary}
                  showManaged={showManaged}
                />
                <FooterRow
                  showManaged={showManaged}
                  data={{
                    info: `Sub Total (${process.env.MAJOR_CURRENCY})`,
                    value1: data.total,
                    value2: isSummary
                      ? data.settlementTotal
                      : data.scheduleSettlementTotal,
                  }}
                  isSummary={isSummary}
                />
                <EmptyFooterRow
                  isSummary={isSummary}
                  showManaged={showManaged}
                />
                {isSummary && (
                  <React.Fragment>
                    <FooterRow
                      showManaged={showManaged}
                      data={{
                        info: "Rounding",
                        value1: data.totalRounding,
                        value2: data.totalSettlementRounding,
                      }}
                      isSummary={isSummary}
                    />
                    <EmptyFooterRow isSummary={isSummary} />
                    <FooterRow
                      showManaged={showManaged}
                      data={{
                        info: `Total (${process.env.MAJOR_CURRENCY})`,
                        value1: data.subTotal,
                        value2: data.settlementSubTotal,
                      }}
                      isSummary={isSummary}
                    />
                    <CSBMLinkFooterRow />
                    <SettlementFooterRow data={data} />
                    <EmptyFooterRow isSummary={isSummary} />
                  </React.Fragment>
                )}
              </Table.Body>
            </Table>
          </React.Fragment>
        )
      }
    ></ListBuilder>
  );
};

const CalcRow = ({ isSummary, showManaged, data }) => (
  <Table.Row>
    <Table.Cell className="empty"></Table.Cell>
    <Table.Cell className="empty"></Table.Cell>
    <Table.Cell className="total">
      {numericFormatter(data.valueTotal)}
    </Table.Cell>
    {!isSummary && showManaged ? (
      <React.Fragment>
        <Table.Cell className="total">
          {numericFormatter(data.managedTotal)}
        </Table.Cell>
        <Table.Cell className="total">
          {numericFormatter(data.nonManagedTotal)}
        </Table.Cell>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Table.Cell className="empty"></Table.Cell>
        <Table.Cell className="empty"></Table.Cell>
      </React.Fragment>
    )}
    <Table.Cell
      className={"empty " + (!isSummary ? "no-right-border" : "")}
    ></Table.Cell>
    {isSummary && (
      <React.Fragment>
        <Table.Cell className="total">
          {numericFormatter(data.outstandingTotal)}
        </Table.Cell>
        <Table.Cell className="empty"></Table.Cell>
      </React.Fragment>
    )}

    <Table.Cell className="empty"></Table.Cell>
    <Table.Cell className="total">
      {numericFormatter(
        isSummary ? data.settlementValueTotal : data.scheduleSettlementSubTotal
      )}
    </Table.Cell>
    {!isSummary && showManaged && (
      <React.Fragment>
        <Table.Cell className="total" colSpan={2}>
          {numericFormatter(data.scheduleSettlementManaged)}
        </Table.Cell>
        <Table.Cell className="total">
          {numericFormatter(data.scheduleSettlementNonManaged)}
        </Table.Cell>
      </React.Fragment>
    )}
  </Table.Row>
);

const CustomSettlementBreakdownModal = () => {
  return (
    <InfoModalWrapper
      floated="right"
      title="Custom Settlement Breakdown"
      linkText="Breakdown"
    >
      <div className="liability-csb">
        <ListBuilder
          withTable
          loadAction={getCSB}
          renderList={(tableProps) => (
            <CinTable
              {...tableProps}
              className=" outline-grid right-align"
              dataKey="id"
            >
              <CinTable.Column
                render={(item) => numericFormatter(item.finance, "-")}
                title={`Finance (${process.env.MAJOR_CURRENCY})`}
                width="20%"
              />
              <CinTable.Column
                render={(item) => numericFormatter(item.meterServices, "-")}
                title={`Meter Services (${process.env.MAJOR_CURRENCY})`}
                width="20%"
              />
              <CinTable.Column
                render={(item) => numericFormatter(item.quarterlyServices, "-")}
                title={`Quarterly Services (${process.env.MAJOR_CURRENCY})`}
                width="20%"
              />
              <CinTable.Column
                render={(item) => numericFormatter(item.rounding, "-")}
                title={`Rounding (${process.env.MAJOR_CURRENCY})`}
                width="20%"
              />
              <CinTable.Column
                render={(item) => numericFormatter(item.total, "-")}
                title={`Totals (${process.env.MAJOR_CURRENCY})`}
                width="20%"
              />
            </CinTable>
          )}
        ></ListBuilder>
      </div>
    </InfoModalWrapper>
  );
};

const TableRowQS = ({ data, isSummary, showManaged }) => (
  <Table.Row>
    <Table.Cell style={{ width: "13%" }} className="center-align">
      {data.service}
    </Table.Cell>
    <Table.Cell style={{ width: "13%" }} className="center-align">
      {data.payableTo}
    </Table.Cell>
    <Table.Cell style={{ width: "10%" }}>
      {numericFormatter(data.value, "-")}
    </Table.Cell>
    {showManaged && !isSummary ? (
      <React.Fragment>
        <Table.Cell style={{ width: "9%" }}>{data.managed}</Table.Cell>
        <Table.Cell style={{ width: "9%" }}>{data.nonManaged}</Table.Cell>
      </React.Fragment>
    ) : (
      <Table.Cell className="empty" style={{ width: "15%" }}></Table.Cell>
    )}
    <Table.Cell
      className="empty"
      style={{ width: isSummary ? "1%" : showManaged ? "2%" : "14%" }}
    ></Table.Cell>
    {!(!isSummary && showManaged) && (
      <Table.Cell
        style={{ width: isSummary ? "14%" : "1%" }}
        className="empty"
      ></Table.Cell>
    )}
    <Table.Cell style={{ width: "12%" }}>
      {isSummary
        ? numericFormatter(data.outstanding, "-")
        : numericFormatter(data.scheduleSettlementPercent, 0, 0)}
    </Table.Cell>
    {isSummary && (
      <React.Fragment>
        <Table.Cell style={{ width: "2%" }} className="empty"></Table.Cell>
        <Table.Cell style={{ width: "2%" }} className="empty"></Table.Cell>
      </React.Fragment>
    )}
    <Table.Cell style={{ width: "12%" }}>
      {isSummary
        ? numericFormatter(data.settlement, "-")
        : numericFormatter(data.scheduleSettlement)}
    </Table.Cell>
    {showManaged && !isSummary ? (
      <React.Fragment>
        <Table.Cell colSpan={2} style={{ width: "9%" }}>
          {data.scheduleSettlementManaged}
        </Table.Cell>
        <Table.Cell style={{ width: "9%" }}>
          {data.scheduleSettlementNonManaged}
        </Table.Cell>
      </React.Fragment>
    ) : (
      <Table.Cell
        style={{ width: isSummary ? "2%" : "1%" }}
        className="empty"
      ></Table.Cell>
    )}
  </Table.Row>
);

const BlankRow = () => (
  <Table.Row className="empty-footer">
    <Table.Cell className="empty" colSpan={11}></Table.Cell>
  </Table.Row>
);

const EmptyRow = ({ showManaged, isSummary }) => (
  <Table.Row>
    <Table.Cell>
      <br />
    </Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell></Table.Cell>
    {!isSummary && showManaged && (
      <React.Fragment>
        <Table.Cell></Table.Cell>
        <Table.Cell></Table.Cell>
      </React.Fragment>
    )}
    <Table.Cell className="empty"></Table.Cell>
    <Table.Cell
      className={!isSummary && showManaged ? "" : "empty"}
    ></Table.Cell>
    <Table.Cell
      className={!isSummary && showManaged ? "" : "empty"}
    ></Table.Cell>
    <Table.Cell
      className={showManaged && !isSummary && "no-right-border"}
    ></Table.Cell>
    <Table.Cell></Table.Cell>
    {showManaged && !isSummary && (
      <Table.Cell className="border-right"></Table.Cell>
    )}
    <Table.Cell
      className={showManaged && !isSummary ? "no-right-border empty" : "empty"}
    ></Table.Cell>
  </Table.Row>
);

const EmptyFooterRow = ({ isSummary, showManaged }) => (
  <Table.Row className="empty-footer">
    <Table.Cell className="empty" colSpan={isSummary ? 4 : 5}></Table.Cell>
    <Table.Cell
      className={isSummary ? "highlight" : "red-highlight"}
      colSpan={isSummary ? 4 : showManaged ? 2 : 3}
    ></Table.Cell>
    <Table.Cell
      className={isSummary ? "yellow-highlight" : "red-highlight"}
      colSpan={!isSummary && showManaged ? 2 : 3}
    ></Table.Cell>
  </Table.Row>
);

const CSBMLinkFooterRow = () => (
  <Table.Row className="empty-footer">
    <Table.Cell className="empty" colSpan={4}></Table.Cell>
    <Table.Cell className="highlight" colSpan={4}></Table.Cell>
    <Table.Cell className="yellow-highlight"></Table.Cell>
    <Table.Cell className="yellow-highlight">
      <CustomSettlementBreakdownModal />
    </Table.Cell>
    <Table.Cell className="yellow-highlight"></Table.Cell>
  </Table.Row>
);

const SettlementFooterRow = ({ data }) => (
  <Table.Row className="footer">
    <Table.Cell className="empty" colSpan={4}></Table.Cell>
    <Table.Cell className="highlight"></Table.Cell>
    <Table.Cell className="total left-align">
      Custom Schedule Settlement %
    </Table.Cell>
    <Table.Cell className="highlight"></Table.Cell>
    <Table.Cell className="highlight"></Table.Cell>
    <Table.Cell className="yellow-highlight"></Table.Cell>
    <Table.Cell className="total">
      {numericFormatter(data.scheduleSettlement)}
    </Table.Cell>
    <Table.Cell className="yellow-highlight"></Table.Cell>
  </Table.Row>
);

const FooterRow = ({ data, isSummary, showManaged }) => (
  <Table.Row className="footer">
    <Table.Cell className="empty" colSpan={isSummary ? 4 : 5}></Table.Cell>
    <Table.Cell
      className={isSummary ? "highlight" : "red-highlight"}
    ></Table.Cell>
    {isSummary && (
      <Table.Cell className="total left-align">{data.info}</Table.Cell>
    )}
    <Table.Cell className="total">
      {isSummary
        ? numericFormatter(data.value1)
        : `Total ${process.env.MAJOR_CURRENCY}`}
    </Table.Cell>
    {isSummary && (
      <React.Fragment>
        <Table.Cell className="highlight"></Table.Cell>
        <Table.Cell className="yellow-highlight"></Table.Cell>
      </React.Fragment>
    )}
    <Table.Cell className={data.value2 < 0 ? "red" : ""}>
      <b>
        {data.value2 < 0 ? "(" : ""}
        {numericFormatter(
          data.value2 < 0 ? Math.abs(data.value2) : data.value2
        )}
        {data.value2 < 0 ? ")" : ""}
      </b>
    </Table.Cell>
    <Table.Cell
      style={{ width: isSummary ? "1%" : showManaged ? "2%" : "1%;" }}
      className={isSummary ? "yellow-highlight" : "red-highlight"}
    ></Table.Cell>
  </Table.Row>
);
