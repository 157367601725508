import { mockSuccess, MockArrayData } from "@redriver/cinnamon";

const NAMESPACE = "PAPER";

const ActionTypes = {
  GetPaper: `${NAMESPACE}/GET_PAPER`,
};

const mockPaper = new MockArrayData([
  {
    paperInclusive: {
      isPaperInclusive: {
        id: "5ba5631c-2afc-475a-bedc-9b822dc8e017",
        isFixed: false,
        value: true,
      },
      paperInclusiveChargeType: "A4 & A3",
      isCostEffectiveA3: "true",
      a4CustomerCurrentSpend: {
        id: "a4dead38-8e47-4134-a811-9580140a4817",
        paperWeight: "80gsm",
        quality: null,
        specialType: null,
        pricePerReam: 2.84,
        quantityPerReam: 500,
        reamsPerBox: 5,
        totalReamsBoughtPerQuarter: null,
        deliveryChargesPerQuarter: 0,
        otherChargesPerQuarter: null,
        description: null,
      },
      a3CustomerCurrentSpend: {
        id: "9911cd0d-45a8-48e1-8619-c16fdfbe60d7",
        paperWeight: "80gsm",
        quality: null,
        specialType: null,
        pricePerReam: 4.07,
        quantityPerReam: 500,
        reamsPerBox: 5,
        totalReamsBoughtPerQuarter: null,
        deliveryChargesPerQuarter: 0,
        otherChargesPerQuarter: null,
        description: null,
      },
      a4ManagedPaperInclusiveSpend: {
        id: "9bc925f5-173b-44e2-98d7-808e12e64546",
        paperWeight: "80gsm",
        quality: null,
        pricePerReam: 4.07,
        quantityPerReam: 500,
        reamsPerBox: 5,
        specialType: "Plain",
        deliveryChargesPerQuarter: 0,
        otherChargesPerQuarter: null,
        description: "Xerox Colour Impressions 003R97663",
      },
      a3ManagedPaperInclusiveSpend: {
        id: "a74b71e2-fad1-49e4-ab59-02ffaec8b5f4",
        paperWeight: "80gsm",
        quality: null,
        pricePerReam: 4.07,
        quantityPerReam: 500,
        reamsPerBox: 5,
        specialType: "Plain",
        totalReamsBoughtPerQuarter: null,
        deliveryChargesPerQuarter: 0,
        otherChargesPerQuarter: null,
        description: "Xerox Colour Impressions 003R97663",
      },
      specialPaperCost: 0,
      specialPaperCostDescription: null,
      specialPaperCostSku: "",
      deliveryCost: 0,
      minDeliveryCost: 0,
      deliveryCostDescription: null,
      deliveryCostSku: "",
      paperInclusiveAdditionalTerms: "",
      paperInclusiveDisableMinLogic: "false",
      paperIncomeAdjustment: false,
      additionalCosts: [
        {
          id: "60368724-d18a-4fee-bdc7-cc5102a3f1df",
          value: "0",
          sku: null,
          customerReference: null,
          description: null,
        },
        {
          id: "70c63df6-ccfc-4275-9f6b-cdbfdbb325d1",
          value: "0",
          sku: null,
          customerReference: null,
          description: null,
        },
      ],
      summary: {
        calculator: {
          description1: `${process.env.MAJOR_CURRENCY}7.23 + 20% markup - ${process.env.MAJOR_CURRENCY}8.36`,
          description2: `${process.env.MAJOR_CURRENCY}8.63 @ 415 per ream`,
          total: 3600.54,
        },
        units: 0,
        specialPaperCalcPricing: 315.65,
        minDeliveryCost: 249.12,
        pricePerQtr: 4165.31,
        singleClickA3Reduction: -2082.65,
        tmvsa: 2082.66,
        costPerUnit: 1.0101851,
        reamsToDeliver: 415,
        inclusivePaper: 1.619827,
        specialPaperPrice: 0.1536756,
        deliveryAllowance: 0.249348,
        totalIncMinDelivery: 30,
        quarterlyContractVol: 205400,
        contractVolumeInBoxedReams: 5,
        amethystAmount: 49.45,
        minDiffToCreateInAmethyst: -49.45,
        likelyMinGmBelowActioned: 49.46,
        totalIncludedMinimumDeliveryCharge: 30,
        totalAdditionalCosts: 0,
        reams: 411,
        boxes: 83,
        reamsRoundedUp: 415,
      },
      qtrServiceAdjustment: {
        applied: true,
        amountAtComparisonSnapshot: 637.87,
        adjustedWithNewSettings: 544.88,
        value: 92.99,
      },
      id: "d68fb222-8ba3-4c79-b9a7-d3d63d0082d7",
    },
  },
]);

export const getPaperSchedule = () => {
  return mockSuccess(ActionTypes.GetPaper, {
    response: mockPaper._data[0].paperInclusive,
  });
};
