import { mockSuccess, MockArrayData } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULES/COMMUNICATION";

const ActionTypes = {
  GetCommunicationInfo: `${NAMESPACE}/GET_ITEMS`,
  GetContacts: `${NAMESPACE}/GET_CONTACTS`,
  GetSupplierContacts: `${NAMESPACE}/GET_SUPPLIER_CONTACTS`,
};

const info = new MockArrayData([
  {
    id: 1,
    addresses: [
      {
        id: 1,
        type: 1,
        typeName: "Invoice Address",
        addressLine1: "unit 1",
        addressLine2: "Parsonage Business Park",
        addressLine3: "",
        city: "Horsham",
        county: "West Sussex",
        postcode: "RH12 4AL",
        country: "United Kingdom",
      },
      {
        id: 2,
        type: 2,
        typeName: "Territory",
        addressLine1: "Fareham Office",
        addressLine2: "Business Park",
        addressLine3: "",
        city: "Fareham",
        county: "Hampshire",
        postcode: "PO12 1UP",
        country: "United Kingdom",
      },
    ],
    contacts: [
      {
        id: 1,
        prefix: "Mr",
        name: "Jack Bloggs",
        email: "jbloggs@company.co.uk",
        phone: "01403 222 444",
        role: "IT Manager",
        territory: "Fareham",
      },
      {
        id: 2,
        prefix: "Mr",
        name: "Ian Potter",
        email: "ipotter@company.co.uk",
        phone: "01403 222 485",
        role: "Finance Director",
        territory: "Fareham, London",
      },
      {
        id: 3,
        prefix: null,
        name: "Accounts Payable",
        email: "ap@company.co.uk",
        phone: "01403 222 800",
        role: "Accounts Payable",
        territory: "All",
      },
      {
        id: 4,
        prefix: "Mr",
        name: "Gary Smith",
        email: "gsmith@company.co.uk",
        phone: "01403 222 600",
        role: "IT Director",
        territory: "Fareham",
      },
      {
        id: 5,
        prefix: "Mr",
        name: "Jack Bloggs",
        email: "jbloggs@company.co.uk",
        phone: "01403 222 444",
        role: "IT Manager",
        territory: "Fareham",
      },
      {
        id: 6,
        prefix: "Mr",
        name: "Ian Potter",
        email: "ipotter@company.co.uk",
        phone: "01403 222 485",
        role: "Finance Director",
        territory: "Fareham, London",
      },
      {
        id: 7,
        prefix: null,
        name: "Accounts Payable",
        email: "ap@company.co.uk",
        phone: "01403 222 800",
        role: "Accounts Payable",
        territory: "All",
      },
      {
        id: 8,
        prefix: "Mr",
        name: "Gary Smith",
        email: "gsmith@company.co.uk",
        phone: "01403 222 600",
        role: "IT Director",
        territory: "Fareham",
      },
    ],
    invoiceDeliveryDetails: {
      type: 1,
      email: "ap@company.co.uk",
      cc: "jbloggs@company.co.uk; ipotter@company.co.uk",
    },
    supplierContactDetails: {
      main: {
        email: "supplier@admin.co.uk",
        phone: "023870 555 000",
      },
      admin: {
        email: "admin@admin.co.uk",
        phone: "023870 555 500",
      },
      general: [
        {
          id: 1,
          name: "Katrina Clayton",
          email: "kc@supplier.co.uk",
          phone: "02380 555 412",
          role: "Account Manager",
        },
        {
          id: 2,
          name: "Deborah Faulkner",
          email: "df@supplier.co.uk",
          phone: "02380 555 424",
          role: "Supplier Admin",
        },
        {
          id: 3,
          name: "Edith Kingsley",
          email: "ek@supplier.co.uk",
          phone: "02380 555 465",
          role: "Supplier Admin",
        },
        {
          id: 4,
          name: "Taylor Kidd",
          email: "kc@supplier.co.uk",
          phone: "02380 555 435",
          role: "Supplier Admin",
        },
        {
          id: 5,
          name: "Shannon May",
          email: "sm@supplier.co.uk",
          phone: "02380 555 438",
          role: "Supplier Admin",
        },
        {
          id: 6,
          name: "Katrina Clayton",
          email: "kc@supplier.co.uk",
          phone: "02380 555 412",
          role: "Account Manager",
        },
        {
          id: 7,
          name: "Deborah Faulkner",
          email: "df@supplier.co.uk",
          phone: "02380 555 424",
          role: "Supplier Admin",
        },
        {
          id: 8,
          name: "Edith Kingsley",
          email: "ek@supplier.co.uk",
          phone: "02380 555 465",
          role: "Supplier Admin",
        },
        {
          id: 9,
          name: "Taylor Kidd",
          email: "kc@supplier.co.uk",
          phone: "02380 555 435",
          role: "Supplier Admin",
        },
        {
          id: 10,
          name: "Shannon May",
          email: "sm@supplier.co.uk",
          phone: "02380 555 438",
          role: "Supplier Admin",
        },
      ],
    },
  },
]);

export const getCommunicationInfo = () => {
  return mockSuccess(ActionTypes.GetCommunicationInfo, {
    delay: 500,
    response: info._data[0],
  });
};

export const getContacts = () => {
  return mockSuccess(ActionTypes.GetCommunicationInfo, {
    delay: 500,
    response: info._data[0].contacts,
  });
};

export const getSupplierContacts = () => {
  return mockSuccess(ActionTypes.GetSupplierContacts, {
    delay: 500,
    response: info._data[0].supplierContactDetails.general,
  });
};
