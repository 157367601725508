import React, { useState } from "react";
import { Grid, GridColumn, GridRow, Button } from "semantic-ui-react";
import { Form, FormBuilder, Format } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { useTranslation } from "react-i18next";
import { navigateGridForm } from "features/../../../shared/components/forms/formHelper";
import { TimezonesDropdownLookup } from "features/../../../shared/features/System/lookups";
import { DealerStatusDropdown, getDealer, updateDealer } from "../actions";
import { ContentPanel } from "components/displays";
import { DealerStatus } from "features/../../../shared/constants/enums";
import { SPVLookupDropdown } from "features/../../../shared/features/SPVs/lookups";
import AddressForm from "features/Dealers/DealerOffice/AddressForm";

const DetailsSummary = ({ id, onSubmitted }) => {
  const { t } = useTranslation();
  const [timeStamp, setTimeStamp] = useState(Date.now());
  return (
    <FormBuilder
      loadAction={getDealer}
      loadParams={{ id, timeStamp }}
      initialReadOnly
      submitAction={updateDealer}
      submitParams={id}
      onSubmitted={() => {
        onSubmitted();
        setTimeStamp(Date.now());
      }}
      renderForm={(formProps, state, events) => {
        if (!formProps.value) return null;
        return (
          <Form
            {...formProps}
            onKeyDown={(e) => {
              navigateGridForm(e, 2, 1, false);
            }}
          >
            <ContentPanel
              title="Summary"
              wrapperCssClass="outline-grid"
              headerCssClass="defaults-page-title"
              headerContent={
                <PermissionCheck target={Targets.Dealer} action={Actions.Edit}>
                  <div className="flex">
                    <IconButton
                      primary
                      icon={state.readOnly ? Pen : Save}
                      content={
                        state.readOnly
                          ? t("shared:buttons:edit")
                          : t("shared:buttons:save")
                      }
                      floated="right"
                      onClick={() =>
                        state.readOnly
                          ? events.onToggleReadOnly()
                          : events.onSubmit({ reset: ["readOnly"] })
                      }
                      className={
                        !state.readOnly ? "positive-title-action" : null
                      }
                    />
                    {!state.readOnly && (
                      <Button
                        className="cancel-title-action"
                        floated="right"
                        content={t("shared:buttons:cancel")}
                        onClick={() => {
                          events.onReset();
                        }}
                      />
                    )}
                  </div>
                </PermissionCheck>
              }
            >
              <Form.Object field="basicDetails">
                <Grid className="dealer-summary-content">
                  <GridColumn
                    mobile={16}
                    tablet={16}
                    computer={10}
                    largeScreen={7}
                    widescreen={7}
                    floated="left"
                  >
                    <Grid>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          <h3>Company Information</h3>
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          Legal Entity Name:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="name" />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          Supplier Short Name:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="shortName" />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          Supplier Code:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="code" maxLength={6} />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          CRM ID:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Numeric field="crmId" readOnly />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          Jurisdiction:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="jurisdiction" />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          Timezone:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <TimezonesDropdownLookup
                            field="timezone"
                            placeholder="Select Timezone"
                          />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          Company Registration Number:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="companyRegistrationNumber" />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          VAT Registration Number:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="vatRegistrationNumber" />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          EIN Number:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="einNumber" />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          DUNS Number:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="dunsNumber" />
                        </GridColumn>
                      </GridRow>
                    </Grid>

                    <Grid>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          <h3>SPV Details</h3>
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          SPV Associated:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <SPVLookupDropdown
                            field="spvId"
                            placeholder="Select SPV"
                            renderReadOnly={() => {
                              return (
                                <React.Fragment>
                                  {formProps?.value?.basicDetails?.spvName}
                                </React.Fragment>
                              );
                            }}
                          />
                        </GridColumn>
                      </GridRow>
                      {state.readOnly && (
                        <GridRow>
                          <GridColumn
                            mobile={7}
                            tablet={6}
                            computer={7}
                            largeScreen={7}
                            widescreen={5}
                          >
                            SPV Associated Id:
                          </GridColumn>
                          <GridColumn
                            mobile={9}
                            tablet={10}
                            computer={8}
                            largeScreen={9}
                            widescreen={10}
                          >
                            {" "}
                            <Form.Input field="spvCode" />
                          </GridColumn>
                        </GridRow>
                      )}
                    </Grid>
                  </GridColumn>

                  <GridColumn
                    mobile={16}
                    tablet={16}
                    computer={10}
                    largeScreen={7}
                    widescreen={7}
                    floated="left"
                  >
                    <Grid>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          <h3>Status</h3>
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          Status:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          {state.readOnly ? (
                            <span>
                              {
                                DealerStatus[
                                  formProps?.value?.basicDetails?.status
                                ]
                              }
                            </span>
                          ) : (
                            <DealerStatusDropdown
                              field="status"
                              placeholder="Select Status"
                            />
                          )}
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          Start Date:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {state.readOnly ? (
                            <Format.Date
                              value={formProps?.value?.basicDetails?.startDate}
                              format="DD MMM YYYY"
                            />
                          ) : (
                            <Form.Date local field="startDate" />
                          )}
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          End Date:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {state.readOnly ? (
                            <Format.Date
                              value={formProps?.value?.basicDetails?.endDate}
                              format="DD MMM YYYY"
                            />
                          ) : (
                            <Form.Date local field="endDate" />
                          )}
                        </GridColumn>
                      </GridRow>
                    </Grid>
                    <AddressForm />
                  </GridColumn>
                </Grid>
              </Form.Object>
            </ContentPanel>
          </Form>
        );
      }}
    />
  );
};

export default DetailsSummary;
