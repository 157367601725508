import React from "react";
import { PageContent } from "@redriver/cinnamon";

const AccessDenied = ({ message }) => {
  return (
    <PageContent>
      <br />
      <h4>Access Denied</h4>
      <div className="content-container not-list">
        <p>{message}</p>
      </div>
    </PageContent>
  );
};

export default AccessDenied;
