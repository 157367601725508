import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { FundersList } from "features/Companies/Funders";
import { BreadcrumbBar } from "pages/nav";

const FundersListPage = () => (
  <PageContent fluid className="funder-container">
    <BreadcrumbBar />
    <FundersList />
  </PageContent>
);

export default FundersListPage;
