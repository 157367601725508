import React from "react";
import { Form } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";

const TargetMarketModalForm = ({ formProps, dealerOfficeId }) => {
  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'largeFormat.targetMarket'});
  return(
  <Form {...formProps} className="large-format-setting-modal">
    <Form.Input
      field="name"
      label={t("modalFormName")}
      required
      placeholder={t("modalFormPlaceholder")}
    />
  </Form>
)};

export default TargetMarketModalForm;
