import React from "react";
import { ContentPanel } from "components/displays";

const FinancialDetails = () => {
  return (
  <ContentPanel title="Financial Details" wrapperCssClass="outline-grid  dealers-details" headerCssClass="defaults-page-title">
    
  </ContentPanel>
  )
};

export default FinancialDetails;
