import React, { forwardRef } from "react";
import { Button, Grid } from "semantic-ui-react";
import { FormBuilder, Form, Loadable } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { Targets, Actions } from "constants/permissions";
import { updateTieredTonerRestrictions } from "./actions";
import { getDefaultsRestrictions } from "..";

const TieredTonerRestrictions = forwardRef(({ settingsId }, ref) => {
  return (
    <div ref={ref}>
      <FormBuilder
        initialReadOnly
        loadAction={getDefaultsRestrictions}
        loadParams={{ settingsId }}
        submitAction={updateTieredTonerRestrictions}
        submitParams={{ settingsId }}
        renderLoading={false}
        renderSubmitting={false}
        renderError={false}
        loadTransform={(data) => ({
          availableToDealer: data.tieredToner.availableToDealer,
          availableToAll: data.tieredToner.availableToAll,
          ...data.TieredToner,
        })}
        renderForm={(formProps, state, events) => {
          return (
            <React.Fragment>
              <Loadable loading={state.loading || state.submitting}>
                <Form
                  {...formProps}
                  disabled={state.submitting || state.readOnly}
                >
                  <div className="extra-settings-panel-top">
                    <div className="extra-settings-panel-columns paper">
                      <div className="content-panel">
                        <div className="office-general-settings">
                          <h4 className="settings-title">
                            Tiered Toner Availability
                          </h4>
                          <div className="content-container principal-list">
                            <div className="action-bar-div">
                              <div className="cin action-bar-item">
                                <PermissionCheck
                                  target={Targets.Defaults}
                                  action={Actions.Edit}
                                >
                                  {!state.readOnly && (
                                    <Button
                                      primary
                                      className="cancel-title-action"
                                      floated="right"
                                      content="Cancel"
                                      onClick={events.onReset}
                                    />
                                  )}
                                  <IconButton
                                    primary
                                    icon={state.readOnly ? Pen : Save}
                                    content={state.readOnly ? "Edit" : "Save"}
                                    floated="right"
                                    onClick={() =>
                                      state.readOnly
                                        ? events.onToggleReadOnly()
                                        : events.onSubmit({
                                            reset: ["readOnly"],
                                          })
                                    }
                                    className={
                                      !state.readOnly
                                        ? "positive-title-action"
                                        : null
                                    }
                                  />
                                </PermissionCheck>
                              </div>
                            </div>
                          </div>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width={16}>
                                <Form.Checkbox
                                  field="availableToDealer"
                                  label="Available To Supplier"
                                  readOnly={state.readOnly}
                                />
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                              <Grid.Column width={3}></Grid.Column>
                              <Grid.Column width={13}>
                                <Form.RadioGroup
                                  field="availableToAll"
                                  readOnly={state.readOnly}
                                  options={[
                                    {
                                      value: false,
                                      text: "selectable by IPS/Supplier Admin View Only as an option)",
                                    },
                                    {
                                      value: true,
                                      text: "Selectable by All Views as an option",
                                    },
                                  ]}
                                />
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </Loadable>
            </React.Fragment>
          );
        }}
      />
    </div>
  );
});

export default TieredTonerRestrictions;
