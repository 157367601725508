import React from "react";
import { ModalBuilder } from "@redriver/cinnamon";
import { IconButton } from "features/../../../shared/components/buttons";
import { updatePrinter } from "./actions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import PrinterModalForm from "./PrinterModalForm";
import { TranslatedModal } from "../../../../components/modals";

const EditPrinter = ({
  as: As = IconButton,
  onSubmitted,
  dealerOfficeId,
  printer,
  ...props
}) => (
  <PermissionCheck target={Targets.LargeFormat} action={Actions.Edit}>
    <ModalBuilder
      withForm
      initialData={printer}
      submitAction={updatePrinter}
      submitParams={{ printerId: printer.id, dealerOfficeId }}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => (
        <As onClick={showModal} {...props}>
          Edit
        </As>
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Edit {...modalProps} header="Edit printer">
          <PrinterModalForm formProps={formProps} dealerOfficeId={dealerOfficeId} />
        </TranslatedModal.Edit>
      )}
    />
  </PermissionCheck>
);

export default EditPrinter;
