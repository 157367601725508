import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Image, Icon } from "semantic-ui-react";
import moment from "moment";
import { DealerRoutes, ScheduleMatch } from "constants/routes";
import { getDealerOfficeHeaderInfo } from "../../../../shared/features/DealerOffices";
import { Format } from "@redriver/cinnamon";

const CompanyLogo = () => {
  const dispatch = useDispatch();

  const [logoUri, setLogoUri] = useState(undefined);
  const [timezone, setTimezone] = useState(undefined);
  const [name, setName] = useState(undefined);
  const [time, setTime] = useState(moment());

  const scheduleMatch = useRouteMatch(ScheduleMatch());
  const scheduleId = scheduleMatch ? scheduleMatch.params.id : null;

  const dealerOfficeMatch = useRouteMatch(DealerRoutes.Office());
  const dealerOfficeId = dealerOfficeMatch ? dealerOfficeMatch.params.id : null;

  useEffect(() => {
    // Keep time updated
    const interval = setInterval(() => {
      setTime(moment());
    }, 10000);

    (async function fetchLogo() {
      const { success, response } = await dispatch(
        getDealerOfficeHeaderInfo({ dealerOfficeId, scheduleId }),
      );
      setLogoUri(() => (success ? response.imageUrl : null));
      setTimezone(success ? response.timezone : null);
      setName(success ? response.name : null);
    })();

    return () => interval && clearInterval(interval);
  }, [dealerOfficeId, scheduleId, dispatch]);

  return (
    <div className="site-header-container">
      {logoUri && <Image src={logoUri} className="logo" />}
      <div className="info">
        <div className="office">
          <div className="icon-wrapper">
            <Icon name="compass outline" />
          </div>
          <label>{name || "(No Supplier Office selected)"}</label>
        </div>
        <div className="time">
          <label>Local Time:</label>
          <Format.Time timezone={timezone} value={time} format="HH:mm z" />
          {/* To be added at a later date, customer doesn't want this right now but partially implemented.
          <SwitchDealerOfficeModal
            onSubmit={(formData, _) => {
              history.push(
                `${AppRoutes.Agreements}?dealerOfficeId=${formData.dealerOfficeId}`
              );
            }}
            currentDealerOfficeId={dealerOfficeId}
          />
          */}
        </div>
      </div>
    </div>
  );
};

export default CompanyLogo;
