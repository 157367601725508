import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useFormField } from '@redriver/cinnamon';

const WriteTextToClipboardButton = ({
  valueToWrite = undefined,
  formFieldName = undefined
}) => {
  const [successState, setSuccessState] = useState(undefined);
  const [timeoutRef, setTimeoutRef] = useState(undefined);
  const timeout = () => setTimeoutRef(setTimeout(() => setSuccessState(undefined), 2500));

  if (!valueToWrite && formFieldName)
    [valueToWrite] = useFormField(formFieldName);

  return (
    <Button
      disabled={!valueToWrite}
      icon={(() => {
        switch (successState) {
          case true:  return 'clipboard check';
          case false: return 'warning sign';
          default:    return 'clipboard outline';
        }
      })()}
      content={(() => {
        switch (successState) {
          case true:  return 'Copied';
          case false: return 'Failed to Copy';
          default:    return 'Copy to Clipboard';
        }
      })()}
      onClick={() => {
        if (!valueToWrite)
          return;

        clearTimeout(timeoutRef);

        try {
          navigator.clipboard.writeText(valueToWrite);
        } catch (x) {
          setSuccessState(false);
          if (x instanceof DOMException)
            /*
            * browsers may throw if access to the clipboard is refused - this is
            * quite legitimate, and is safe for user to retry, so gracefully continue
            */
            timeout();
          else
            /*
            * ...anything else is actually exceptional
            */
            throw x;
        }

        setSuccessState(true);
        timeout();
      }} />
  );
};

export default WriteTextToClipboardButton;
