import React from "react";
import { ContentPanel } from "components/displays";
import {
  TableDivider,
  numericFormatter,
} from "components/displays/DisplayHelper";
import { Table } from "semantic-ui-react";

const Totals = ({ totals }) => {
  return (
    <ContentPanel
      title="Totals"
      wrapperCssClass="outline-grid"
      headerCssClass="defaults-page-title"
    >
      <div className="padded totals">
        <TotalsTable totals={totals} />
      </div>
    </ContentPanel>
  );
};

const TotalsTable = ({ totals }) => (
  <Table className="totals-table">
    <Table.Body>
      <Table.Row className="strong grey">
        <Table.Cell></Table.Cell>
        <Table.Cell>Value {process.env.MAJOR_CURRENCY}</Table.Cell>
      </Table.Row>
      <TableDivider />
      <Table.Row>
        <Table.Cell>Initial Total</Table.Cell>
        <Table.Cell> {numericFormatter(totals?.initialTotal)}</Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>Adjustment</Table.Cell>
        <Table.Cell> {numericFormatter(totals?.adjustment)}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>FMV Conversion</Table.Cell>
        <Table.Cell> {numericFormatter(totals?.fmvConversion)}</Table.Cell>
      </Table.Row>
      <Table.Row className="strong grey">
        <Table.Cell>Funder Total</Table.Cell>
        <Table.Cell> {numericFormatter(totals?.funderTotal)}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Settlement via rewrite</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(totals?.settlementViaRewrite)}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>FMV Settlement via rewrite</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(totals?.fmvSettlementViaRewrite)}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="strong grey">
        <Table.Cell>Amount to Collect</Table.Cell>
        <Table.Cell> {numericFormatter(totals?.amountToCollect)}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Periods Collected</Table.Cell>
        <Table.Cell> {numericFormatter(totals?.periodsCollected)}</Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>Reallocated to Continuing Payment</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(totals?.reallocatedToContinuingPayment)}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Periods Settled via invoice</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(totals?.periodsSettledViaInvoice)}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="strong grey">
        <Table.Cell>Minimum Outstanding</Table.Cell>
        <Table.Cell> {numericFormatter(totals?.minimumOutstanding)}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Additional Units Charge</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(totals?.additionalUnitsCharge)}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="strong grey">
        <Table.Cell>Total Outstanding</Table.Cell>
        <Table.Cell> {numericFormatter(totals?.totalOutstanding)}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export default Totals;
