import React from "react";
import { Wizard } from "@redriver/cinnamon";
import { Menu } from "semantic-ui-react";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import {
  AdditionalUnitsBilling,
  MinimumBilling,
  MiscellaneousInvoicing,
  ScheduleCreditNote,
  InvoiceSearch,
} from "./Customer";

const FinanceSteps = {
  MinimumBilling: "minimum-billing",
  AdditionalUnitsBilling: "additional-units-billing",
  ScheduleCreditNote: "schedule-credit-note-details",
  MiscellaneousInvoicing: "miscellaneous-invoicing",
  InvoiceSearch: "invoice-search",
};

const CustomerFinance = () => {
  const NavigationControls = ({ steps, activeStep, onGoToStep }) => {
    return steps.map((s) => (
      <Menu.Item
        value={0}
        key={s.key}
        active={s === activeStep}
        onClick={() => onGoToStep(s.key)}
      >
        {s.title}
      </Menu.Item>
    ));
  };

  const stepData = [
    {
      key: FinanceSteps.MinimumBilling,
      title: "Minimum Billing",
    },
    {
      key: FinanceSteps.AdditionalUnitsBilling,
      title: "Additional Units Billing",
    },
    {
      key: FinanceSteps.ScheduleCreditNote,
      title: "Schedule Credit Note",
    },
    {
      key: FinanceSteps.MiscellaneousInvoicing,
      title: "Miscellaneous Invoicing",
    },
    {
      key: FinanceSteps.InvoiceSearch,
      title: "Invoice Search",
    },
  ];

  return (
    <PermissionCheck target={Targets.Invoice} action={Actions.View}>
      <div className="customer-finance">
        <Wizard steps={stepData}>
          <div className="wizard-navigation horizontal-scrollbar">
            <Wizard.Nav
              render={(wizardContext) => (
                <Menu>
                  <NavigationControls {...wizardContext} />
                </Menu>
              )}
            />
          </div>
          <Wizard.Step
            step={FinanceSteps.MinimumBilling}
            render={() => <MinimumBilling />}
          />
          <Wizard.Step
            step={FinanceSteps.AdditionalUnitsBilling}
            render={() => <AdditionalUnitsBilling />}
          />
          <Wizard.Step
            step={FinanceSteps.ScheduleCreditNote}
            render={() => <ScheduleCreditNote />}
          />
          <Wizard.Step
            step={FinanceSteps.MiscellaneousInvoicing}
            render={() => <MiscellaneousInvoicing />}
          />
          <Wizard.Step
            step={FinanceSteps.InvoiceSearch}
            render={() => <InvoiceSearch />}
          />
        </Wizard>
      </div>
    </PermissionCheck>
  );
};

export default CustomerFinance;
