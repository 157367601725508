import React, { forwardRef } from "react";
import { Grid, Button } from "semantic-ui-react";
import { FormBuilder, Form, Loadable } from "@redriver/cinnamon";
import { updateDisableMinLogic } from "./actions";
import { getDefaultsRestrictions } from "..";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { Targets, Actions } from "constants/permissions";
import { DisableMinLogicOptionsRadioGroup } from "features/../../../shared/features/Settings/lookups";
import AccordionHelper from "components/displays/AccordionHelper";

const DisableMinimumLogic = forwardRef(
  ({ settingsId, toggleVisibility, collapsed }, ref) => {
    const availableToDealerOptions = [
      { value: false, text: "Selectable from IPS View Only" },
      {
        value: true,
        text: "Selectable from IPS & IPS Supplier Admin View Only",
      },
    ];

    return (
      <React.Fragment>
        <div ref={ref} className="disable-min-logic">
          <FormBuilder
            initialReadOnly
            loadAction={getDefaultsRestrictions}
            loadParams={{ settingsId }}
            submitAction={updateDisableMinLogic}
            submitParams={{ settingsId }}
            renderLoading={false}
            renderSubmitting={false}
            renderError={false}
            loadTransform={(data) => ({
              ...data.disableMinLogic,
            })}
            renderForm={(formProps, state, events) => {
              return (
                <AccordionHelper
                  collapsed={collapsed}
                  toggleVisibility={toggleVisibility}
                  title="Disable Minimum Logic"
                >
                  <React.Fragment>
                    <div className="defaults-page-title cost-per-device-edit flex">
                      <div className="flex title-bar"></div>
                      <PermissionCheck
                        target={Targets.DefaultsRestrictions}
                        action={Actions.Edit}
                      >
                        <div className="disable-min-logic-edit">
                          <IconButton
                            primary
                            icon={state.readOnly ? Pen : Save}
                            content={state.readOnly ? "Edit" : "Save"}
                            floated="right"
                            onClick={() =>
                              state.readOnly
                                ? events.onToggleReadOnly()
                                : events.onSubmit({ reset: ["readOnly"] })
                            }
                            className={
                              !state.readOnly ? "positive-title-action" : null
                            }
                            loading={state.loading || state.submitting}
                            disabled={state.loading || state.submitting}
                          />
                          {!state.readOnly && (
                            <Button
                              primary
                              className="cancel-title-action"
                              floated="right"
                              content="Cancel"
                              onClick={events.onReset}
                            />
                          )}
                        </div>
                      </PermissionCheck>
                    </div>
                    <Loadable loading={state.loading || state.submitting}>
                      <Form
                        {...formProps}
                        disabled={state.submitting || state.readOnly}
                      >
                        <Grid className="disable-min-logic-box">
                          <Grid.Row>
                            <Form.RadioGroup
                              field="availableToDealer"
                              options={availableToDealerOptions}
                              readOnly={state.readOnly}
                            />
                          </Grid.Row>
                          <Grid.Row className="check-boxes">
                            <DisableMinLogicOptionsRadioGroup
                              field="availableOptions"
                              readOnly={state.readOnly}
                            />
                          </Grid.Row>
                        </Grid>
                      </Form>
                    </Loadable>
                  </React.Fragment>
                </AccordionHelper>
              );
            }}
          />
        </div>
      </React.Fragment>
    );
  }
);

export default DisableMinimumLogic;
