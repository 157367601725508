import React from "react";
import ProductScheduleGrid from "./ProductScheduleGrid";
import { getProductSchedules, getDeletedProductSchedules } from "./actions";

const ProductSchedule = () => {
  return (
    <React.Fragment>
      <ProductScheduleGrid
        title="Live Product Schedule"
        loadAction={getProductSchedules}
        uniqueKey="psGrid1"
      ></ProductScheduleGrid>
      <br />
      <ProductScheduleGrid
        title="Removed Product Schedule"
        loadAction={getDeletedProductSchedules}
        removedView={true}
        uniqueKey="psGrid2"
      ></ProductScheduleGrid>
    </React.Fragment>
  );
};

export default ProductSchedule;
