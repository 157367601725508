import React from "react";
import { SidePanel, Form, Lookup } from "@redriver/cinnamon";
import { FilterButton } from "features/../../../shared/components/buttons";
import { DealerOfficesTypeAheadLookup } from "features/../../../shared/features/Settings";
import { scheduleStatusLookup } from "./lookups";
import { InlineLoader } from "features/../../../shared/components/forms/InlineLoader";

const SchedulesFilters = ({ title, filters, onChange, formProps, onReset }) => {
  return (
    <SidePanel
      closeIcon
      trigger={<FilterButton />}
      className="schedules-list-filter-side-panel"
    >
      <div>
        <div className="ui header filters-title">
          <div className="title-text">{title}</div>
          <div className="clear-button" onClick={onReset}>
            (clear)
          </div>
        </div>
        <Form {...formProps} className="highlighted-inputs">
          <div className="ui form highlighted-inputs">
            <DealerOfficesTypeAheadLookup
              field="supplier"
              fluid
              clearable
              multiple
              label="Office"
            />
            <div className="field">
              <label>
                Status
                <a
                  onClick={() => {
                    const nextFilters = {
                      ...filters,
                    };
                    nextFilters.status = ["Active", "Inactive"];
                    onChange(nextFilters);
                  }}
                  className="select-all-button"
                >
                  (Select All)
                </a>
              </label>

              <Lookup
                lookup={scheduleStatusLookup}
                lookupParams={{}}
                render={({ response }) => {
                  return (
                    <div>
                      {!response ? (
                        <InlineLoader isActive={true} size="small" />
                      ) : (
                        <Form.CheckboxGroup field="status" options={response} />
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </Form>
      </div>
    </SidePanel>
  );
};

export default SchedulesFilters;
