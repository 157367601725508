import React, { useState } from "react";
import { Button, Form, Icon } from "semantic-ui-react";
import { getMeterReadingSchedule } from "./actions";
import { ContentPanel } from "components/displays";
import { numericFormatter } from "components/displays/DisplayHelper";
import { SimpleTableBuilder } from "../../../../../../shared/components/tables/sortableTable";
import classNames from "classnames";

const columns = [
  {
    title: "Unit",
    field: "unit",
    width: "1%",
    render: (item) => (
      <span className={item.credited == "Credit" && "blue"}>{item.unit}</span>
    ),
  },
  {
    title: "Unit Description",
    field: "unitDescription",
    width: "9%",
    render: (item) => (
      <span className={item.credited == "Credit" && "blue"}>
        {item.unitDescription}
      </span>
    ),
  },
  {
    title: "Managed Meter",
    field: "managedMeter",
    width: "12%",
    render: (item) => (
      <span className={item.credited == "Credit" && "blue"}>
        {item.managedMeter}
      </span>
    ),
  },
  {
    title: "Quarter Start Date",
    field: "quarterStartDate",
    width: "9%",
    render: (item) => (
      <span className={item.credited == "Credit" && "blue"}>
        {item.quarterStartDate}
      </span>
    ),
  },
  {
    title: "Unit Usage",
    field: "unitUsage",
    width: "6%",
    render: (item) => (
      <span className={item.unitUsage < 0 && "red"}>
        {numericFormatter(item.unitUsage, 0, 0)}
      </span>
    ),
  },
  {
    title: "Schedule Quarterly Volume",
    field: "scheduleQuarterlyVolume",
    width: "15%",
    render: (item) => (
      <span className={item.scheduleQuarterlyVolume < 0 && "red"}>
        {numericFormatter(item.scheduleQuarterlyVolume, 0, 0)}
      </span>
    ),
  },
  {
    title: "Additional Units",
    field: "additionalUnits",
    width: "9%",
    render: (item) => (
      <span className={item.additionalUnits < 0 && "red"}>
        {numericFormatter(item.additionalUnits, 0, 0)}
      </span>
    ),
  },
  {
    title: "Headroom Volume",
    field: "headroomVolume",
    width: "12%",
    render: (item) => (
      <span className={item.headroomVolume < 0 && "red"}>
        {numericFormatter(item.headroomVolume, 0, 0)}
      </span>
    ),
  },
  {
    title: "Additional Units Invoice Number",
    field: "additionalUnitsInvoiceNumber",
    width: "15%",
    render: (item) => (
      <span className={item.credited == "Credit" && "blue"}>
        {item.additionalUnitsInvoiceNumber}
      </span>
    ),
  },
  {
    title: "Credited",
    field: "credited",
    width: "6%",
    render: (item) => (
      <span className={item.credited == "Credit" && "blue"}>
        {item.credited}
      </span>
    ),
  },
];

const MeterReading = () => {
  const [tableExtended, setTableExtended] = useState(false);

  return (
    <ContentPanel
      title="Meter Readings"
      wrapperCssClass="schedule-meter-reading outline-grid"
      headerCssClass="defaults-page-title"
    >
      <div className="spaced">
        <div className="flex schedule-meter-reading-utility-bar">
          <div className="schedule-meter-reading-search">
            <Form.Input
              field="search"
              placeholder="search"
              icon={<Icon name="search" />}
              className=""
            />
          </div>
          <div>
            <Button primary content="Export" />
          </div>
        </div>
        <br />
        <div
          className={classNames(
            "vertical-scrollbar",
            tableExtended ? "extended" : "retracted"
          )}
        >
          <SimpleTableBuilder
            emptyMessage="No meter readings found"
            columns={columns}
            dataKey="id"
            loadAction={getMeterReadingSchedule}
          />
        </div>
        <div className="extension-icon">
          <Button onClick={() => setTableExtended(!tableExtended)}>
            {tableExtended ? (
              <Icon name="angle up" />
            ) : (
              <Icon name="angle down" />
            )}
          </Button>
        </div>
      </div>
    </ContentPanel>
  );
};

export default MeterReading;
