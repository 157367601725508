import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { Dashboard } from "features/Dashboard";
import { BreadcrumbBar } from "pages/nav";

const DashboardPage = () => (
  <PageContent fluid>
    <BreadcrumbBar />
    <Dashboard />
  </PageContent>
);
export default DashboardPage;
