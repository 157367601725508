import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AppRoutes, CustomerRoutes } from "constants/routes";
import { CustomersListPage } from "./Customers";
import { CustomerDetails } from "features/Companies/Customers";

const CompaniesRouter = () => (
  <Switch>
    <Route exact path={AppRoutes.Customers} component={CustomersListPage} />
    <Route exact path={CustomerRoutes.Details()} component={CustomerDetails} />
    <Route render={() => <Redirect to={AppRoutes.Customers} />} />
  </Switch>
);

export default CompaniesRouter;
