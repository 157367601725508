import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { DealersList } from "features/Dealers";
import { BreadcrumbBar } from "pages/nav";

const DealersListPage = () => (
  <PageContent fluid className="dealers-container">
    <BreadcrumbBar />
    <DealersList />
  </PageContent>
);

export default DealersListPage;
