import { mockSuccess, MockArrayData } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULE/METER_READINGS";

const ActionTypes = {
  GetMeterReadings: `${NAMESPACE}/GET_METER_READINGS`,
};

const meterReadings = new MockArrayData([
  {
    id: 1,
    unit: "B1",
    unitDescription: "Mono",
    managedMeter: "NO",
    quarterStartDate: "30-Sep-2020",
    unitUsage: 54946,
    scheduleQuarterlyVolume: 86500,
    additionalUnits: 0,
    headroomVolume: -31554,
    additionalUnitsInvoiceNumber: "V602623",
    credited: "",
  },
  {
    id: 2,
    unit: "B2",
    unitDescription: "Mono",
    managedMeter: "NO",
    quarterStartDate: "30-Sep-2020",
    unitUsage: 45941,
    scheduleQuarterlyVolume: 57000,
    additionalUnits: 0,
    headroomVolume: -11059,
    additionalUnitsInvoiceNumber: "V602623",
    credited: "",
  },
  {
    id: 3,
    unit: "C1",
    unitDescription: "Colour",
    managedMeter: "YES",
    quarterStartDate: "30-Sep-2020",
    unitUsage: 27900,
    scheduleQuarterlyVolume: 32700,
    additionalUnits: 0,
    headroomVolume: -4800,
    additionalUnitsInvoiceNumber: "V602623",
    credited: "",
  },
  {
    id: 4,
    unit: "C2",
    unitDescription: "Colour",
    managedMeter: "YES",
    quarterStartDate: "30-Sep-2020",
    unitUsage: 10676,
    scheduleQuarterlyVolume: 11000,
    additionalUnits: 0,
    headroomVolume: -324,
    additionalUnitsInvoiceNumber: "V602623",
    credited: "",
  },
  {
    id: 5,
    unit: "S1",
    unitDescription: "Scan",
    managedMeter: "NO",
    quarterStartDate: "30-Sep-2020",
    unitUsage: 16093,
    scheduleQuarterlyVolume: 21500,
    additionalUnits: 0,
    headroomVolume: -5407,
    additionalUnitsInvoiceNumber: "V602623",
    credited: "",
  },
  {
    id: 6,
    unit: "B1",
    unitDescription: "Mono",
    managedMeter: "NO",
    quarterStartDate: "30-Jun-2020",
    unitUsage: 39132,
    scheduleQuarterlyVolume: 86500,
    additionalUnits: 0,
    headroomVolume: -47368,
    additionalUnitsInvoiceNumber: "V599889",
    credited: "",
  },
  {
    id: 7,
    unit: "B2",
    unitDescription: "Mono",
    managedMeter: "NO",
    quarterStartDate: "30-Jun-2020",
    unitUsage: 45465,
    scheduleQuarterlyVolume: 57000,
    additionalUnits: 0,
    headroomVolume: -11535,
    additionalUnitsInvoiceNumber: "V599889",
    credited: "",
  },
  {
    id: 8,
    unit: "C1",
    unitDescription: "Colour",
    managedMeter: "YES",
    quarterStartDate: "30-Jun-2020",
    unitUsage: 45068,
    scheduleQuarterlyVolume: 32700,
    additionalUnits: 12368,
    headroomVolume: 0,
    additionalUnitsInvoiceNumber: "V599889",
    credited: "",
  },
  {
    id: 9,
    unit: "C2",
    unitDescription: "Colour",
    managedMeter: "YES",
    quarterStartDate: "30-Jun-2020",
    unitUsage: 16672,
    scheduleQuarterlyVolume: 11000,
    additionalUnits: 5672,
    headroomVolume: 0,
    additionalUnitsInvoiceNumber: "V599889",
    credited: "",
  },
  {
    id: 10,
    unit: "S1",
    unitDescription: "Scan",
    managedMeter: "NO",
    quarterStartDate: "30-Jun-2020",
    unitUsage: 19928,
    scheduleQuarterlyVolume: 21500,
    additionalUnits: 0,
    headroomVolume: -1572,
    additionalUnitsInvoiceNumber: "V599889",
    credited: "",
  },
  {
    id: 11,
    unit: "B1",
    unitDescription: "Mono",
    managedMeter: "NO",
    quarterStartDate: "30-Mar-2020",
    unitUsage: 70626,
    scheduleQuarterlyVolume: 86500,
    additionalUnits: 0,
    headroomVolume: -15874,
    additionalUnitsInvoiceNumber: "V597228",
    credited: "",
  },
  {
    id: 12,
    unit: "B2",
    unitDescription: "Mono",
    managedMeter: "NO",
    quarterStartDate: "30-Mar-2020",
    unitUsage: 54180,
    scheduleQuarterlyVolume: 57000,
    additionalUnits: 0,
    headroomVolume: -2820,
    additionalUnitsInvoiceNumber: "V597228",
    credited: "",
  },
  {
    id: 13,
    unit: "C1",
    unitDescription: "Colour",
    managedMeter: "YES",
    quarterStartDate: "30-Mar-2020",
    unitUsage: 42211,
    scheduleQuarterlyVolume: 32700,
    additionalUnits: 9511,
    headroomVolume: 0,
    additionalUnitsInvoiceNumber: "V597228",
    credited: "",
  },
  {
    id: 14,
    unit: "C2",
    unitDescription: "Colour",
    managedMeter: "YES",
    quarterStartDate: "30-Mar-2020",
    unitUsage: 12028,
    scheduleQuarterlyVolume: 11000,
    additionalUnits: 1028,
    headroomVolume: 0,
    additionalUnitsInvoiceNumber: "V597228",
    credited: "",
  },
  {
    id: 15,
    unit: "S1",
    unitDescription: "Scan",
    managedMeter: "NO",
    quarterStartDate: "30-Mar-2020",
    unitUsage: 22203,
    scheduleQuarterlyVolume: 21500,
    additionalUnits: 703,
    headroomVolume: 0,
    additionalUnitsInvoiceNumber: "V597228",
    credited: "",
  },
  {
    id: 16,
    unit: "B1",
    unitDescription: "Mono",
    managedMeter: "NO",
    quarterStartDate: "30-Dec-2019",
    unitUsage: 68508,
    scheduleQuarterlyVolume: 86500,
    additionalUnits: 0,
    headroomVolume: -17992,
    additionalUnitsInvoiceNumber: "V594332",
    credited: "",
  },
  {
    id: 17,
    unit: "B2",
    unitDescription: "Mono",
    managedMeter: "NO",
    quarterStartDate: "30-Dec-2019",
    unitUsage: 56032,
    scheduleQuarterlyVolume: 57000,
    additionalUnits: 0,
    headroomVolume: -968,
    additionalUnitsInvoiceNumber: "V594332",
    credited: "",
  },
  {
    id: 18,
    unit: "C1",
    unitDescription: "Colour",
    managedMeter: "YES",
    quarterStartDate: "30-Dec-2019",
    unitUsage: 51735,
    scheduleQuarterlyVolume: 32700,
    additionalUnits: 19035,
    headroomVolume: 0,
    additionalUnitsInvoiceNumber: "V594332",
    credited: "",
  },
  {
    id: 19,
    unit: "C2",
    unitDescription: "Colour",
    managedMeter: "YES",
    quarterStartDate: "30-Dec-2019",
    unitUsage: 15435,
    scheduleQuarterlyVolume: 11000,
    additionalUnits: 4435,
    headroomVolume: 0,
    additionalUnitsInvoiceNumber: "V594332",
    credited: "",
  },
  {
    id: 20,
    unit: "S1",
    unitDescription: "Scan",
    managedMeter: "NO",
    quarterStartDate: "30-Dec-2019",
    unitUsage: 21171,
    scheduleQuarterlyVolume: 21500,
    additionalUnits: 0,
    headroomVolume: -329,
    additionalUnitsInvoiceNumber: "V594332",
    credited: "",
  },
  {
    id: 21,
    unit: "B1",
    unitDescription: "Mono",
    managedMeter: "NO",
    quarterStartDate: "30-Dec-2019",
    unitUsage: -78508,
    scheduleQuarterlyVolume: -86500,
    additionalUnits: 0,
    headroomVolume: 7992,
    additionalUnitsInvoiceNumber: "Y24125",
    credited: "Credit",
  },
  {
    id: 22,
    unit: "B2",
    unitDescription: "Mono",
    managedMeter: "NO",
    quarterStartDate: "30-Dec-2019",
    unitUsage: -56032,
    scheduleQuarterlyVolume: -57000,
    additionalUnits: 0,
    headroomVolume: 968,
    additionalUnitsInvoiceNumber: "Y24125",
    credited: "Credit",
  },
  {
    id: 23,
    unit: "C1",
    unitDescription: "Colour",
    managedMeter: "NO",
    quarterStartDate: "30-Dec-2019",
    unitUsage: -61735,
    scheduleQuarterlyVolume: -32700,
    additionalUnits: -29035,
    headroomVolume: 0,
    additionalUnitsInvoiceNumber: "Y24125",
    credited: "Credit",
  },
  {
    id: 24,
    unit: "C2",
    unitDescription: "Colour",
    managedMeter: "YES",
    quarterStartDate: "30-Dec-2019",
    unitUsage: -15435,
    scheduleQuarterlyVolume: -11000,
    additionalUnits: -4435,
    headroomVolume: 0,
    additionalUnitsInvoiceNumber: "Y24125",
    credited: "Credit",
  },
  {
    id: 25,
    unit: "S1",
    unitDescription: "Scan",
    managedMeter: "NO",
    quarterStartDate: "30-Dec-2019",
    unitUsage: -21171,
    scheduleQuarterlyVolume: -21500,
    additionalUnits: 0,
    headroomVolume: 329,
    additionalUnitsInvoiceNumber: "Y24125",
    credited: "Credit",
  },
  {
    id: 26,
    unit: "B1",
    unitDescription: "Mono",
    managedMeter: "NO",
    quarterStartDate: "30-Dec-2019",
    unitUsage: 78508,
    scheduleQuarterlyVolume: 86500,
    additionalUnits: 0,
    headroomVolume: -7992,
    additionalUnitsInvoiceNumber: "V584221",
    credited: "YES",
  },
  {
    id: 27,
    unit: "B2",
    unitDescription: "Mono",
    managedMeter: "NO",
    quarterStartDate: "30-Dec-2019",
    unitUsage: 56032,
    scheduleQuarterlyVolume: 57000,
    additionalUnits: 0,
    headroomVolume: -968,
    additionalUnitsInvoiceNumber: "V584221",
    credited: "YES",
  },
  {
    id: 28,
    unit: "C1",
    unitDescription: "Colour",
    managedMeter: "YES",
    quarterStartDate: "30-Dec-2019",
    unitUsage: 61735,
    scheduleQuarterlyVolume: 32700,
    additionalUnits: 29035,
    headroomVolume: 0,
    additionalUnitsInvoiceNumber: "V584221",
    credited: "YES",
  },
  {
    id: 29,
    unit: "C2",
    unitDescription: "Colour",
    managedMeter: "YES",
    quarterStartDate: "30-Dec-2019",
    unitUsage: 15435,
    scheduleQuarterlyVolume: 11000,
    additionalUnits: 4435,
    headroomVolume: 0,
    additionalUnitsInvoiceNumber: "V584221",
    credited: "YES",
  },
  {
    id: 30,
    unit: "S1",
    unitDescription: "Scan",
    managedMeter: "NO",
    quarterStartDate: "30-Dec-2019",
    unitUsage: 21171,
    scheduleQuarterlyVolume: 21500,
    additionalUnits: 0,
    headroomVolume: -329,
    additionalUnitsInvoiceNumber: "V584221",
    credited: "YES",
  },
]);

export const getMeterReadingSchedule = () => {
  return mockSuccess(ActionTypes.GetMeterReadings, {
    delay: 100,
    response: meterReadings._data,
  });
};
