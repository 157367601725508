import React, { useState } from "react";
import { ContentPanel } from "components/displays";

import { Tab } from "semantic-ui-react";
import NextPriceIncrease from "./NextPriceIncrease";
import HistoricIncreases from "./HistoricIncreases";

const panes = [
  {
    menuItem: "Next Price Increase",
    render: () => (
      <Tab.Pane>
        <NextPriceIncrease />
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Historic Increases",
    render: () => (
      <Tab.Pane>
        <HistoricIncreases />
      </Tab.Pane>
    ),
  },
];

const PriceIncreases = () => {
  const handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div>
      <ContentPanel
        title="Price Increases"
        wrapperCssClass="container price-increase-container"
        headerCssClass="defaults-page-title"
      >
        <div className="padded">
          <Tab
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={handleTabChange}
          />
        </div>
      </ContentPanel>
    </div>
  );
};

export default PriceIncreases;
