import React from "react";
import { FormBuilder, Form } from "@redriver/cinnamon";
import { Image } from "semantic-ui-react";

const SearchMeteredServices = ({ value, onChange, onFocus, searchImage }) => (
  <FormBuilder
    value={value}
    onChange={onChange}
    renderForm={(formProps) => (
      <Form {...formProps}>
        <Form.Input
          field="search"
          placeholder="Search Metered Services"
          icon={<Image src={searchImage} />}
          className="search-input highlighted-input"
          onFocus={onFocus}
        />
      </Form>
    )}
  />
);

export default SearchMeteredServices;
