import {  apiGet,  apiPut,} from "@redriver/cinnamon";


const NAMESPACE = "FUNDER_TYPE_SETTINGS";

export const ActionTypes = {
    GetFunderTypeSettings: `${NAMESPACE}/GET`,
    UpdateFunderTypeSettings: `${NAMESPACE}/PUT`,
};


export const getFunderTypeSettings = ({ settingsId, type }) =>{
    return settingsId
    ? apiGet(ActionTypes.GetFunderTypeSettings, `funder-type/settings/${settingsId}/${type}`)
    : {};
};


export const updateFunderTypeSettings = (formData, { settingsId, type }) =>{
    return apiPut(ActionTypes.UpdateFunderTypeSettings, `funder-type/settings/${settingsId}/${type}`, formData);
};