import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { BreadcrumbBar } from "pages/nav";
import { ContentPanel } from "components/displays";

const SupplierPage = () => (
  <PageContent fluid className="cfa-container">
      <BreadcrumbBar />
      <ContentPanel
        title="Supplier Finance"
        wrapperCssClass="outline-grid"
        headerCssClass="defaults-page-title"
      >
        <div className="padded">
          Content to go here
        </div>
      </ContentPanel>
    </PageContent>
);

export default SupplierPage;
