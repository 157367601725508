import {
  setApiOptions,
  enableJwtAuth,
  setLoggingOptions,
  enableSentryLogger,
  enableSegmentLogger,
  enableHotjarLogger,
  setPreferenceDefaults,
  setResponsiveOptions,
  createDefaultReduxStore,
  enablePreferencesFromApi,
  apiGet,
  apiPut,
  notifyError,
  ApiError,
} from "@redriver/cinnamon";
import reducers from "./reducers";
import { onRefreshJwt, onDeauthenticated } from "features/System";
import { ActionTypes } from "actions";
import "./translation/i18n";

export const setupApp = () => {
  configureApi();
  configureAuth();
  configureLogging();
  configurePreferences();
  configureResponsive();
  const store = configureStore();
  return { store };
};

// api / auth modules

const configureApi = () =>
  setApiOptions({
    apiUrl: process.env.API_URL,
    onErrorNotification: (message) => {
      return notifyError(message, { expiry: 10000 });
    },
    onErrorResponse: (response) => {
      return new Promise((resolve) => {
        const responseType = response.headers.get("content-type");
        const errorCode = response.headers.get("X-Saffron-Requestid");
        if (responseType && responseType.includes("application/json")) {
          response
            .json()
            .then((data) => {
              if (isUnexpectedErrorMessage(data[0].message)) {
                data[0].message =
                  "Oops, Something went wrong server side. \n Please contact our support team." +
                  "\n Error Code: " +
                  errorCode;
                data[0].code = undefined;
              }
              return resolve(new ApiError(data, response.status));
            })
            .catch((error) =>
              resolve(new ApiError(error.message, response.status))
            );
        } else if (responseType && responseType.includes("text/")) {
          response
            .text()
            .then((data) => resolve(new ApiError(data, response.status)))
            .catch((error) =>
              resolve(new ApiError(error.message, response.status))
            );
        } else {
          resolve(
            new ApiError(
              "Oops, Something went wrong server side. \n Please contact our support team.",
              response.status
            )
          );
        }
      });
    },
  });

const configureAuth = () =>
  enableJwtAuth({
    tokenKey: `${process.env.COOKIE_PREFIX}-token-key`,
    jwtClaims: {
      emailVerified: {
        type: "boolean",
        key: "ev",
      },
      userId: {
        type: "string",
        key: "sub",
      },
      userName: {
        type: "join",
        keys: ["fn", "ln"],
        separator: " ",
      },
      userEmail: {
        type: "string",
        key: "e",
        identity: "email",
      },
      userType: {
        type: "string",
        key: "ut",
      },
      userArea: {
        type: "string",
        key: "ua",
      },
      permissions: {
        type: "object",
        keys: "^p_(.+)",
      },
    },
    onRefreshJwt,
    onDeauthenticated,
  });

// logging module

const configureLogging = () => {
  setLoggingOptions({
    environment: process.env.LOGGING_ENVIRONMENT,
    release: process.env.LOGGING_RELEASE,
  });
  if (process.env.SENTRY_DSN) {
    enableSentryLogger(process.env.SENTRY_DSN);
  }
  if (process.env.SEGMENT_APIKEY) {
    enableSegmentLogger(process.env.SEGMENT_APIKEY);
  }
  if (process.env.HOTJAR_SITEID) {
    enableHotjarLogger(process.env.HOTJAR_SITEID);
  }
};

// preferences module

const configurePreferences = () =>
  enablePreferencesFromApi(
    apiGet(ActionTypes.GetUserPreferences, `user-preferences`),
    (params) =>
      apiPut(ActionTypes.SaveUserPreferences, `user-preferences`, params)
  );
setPreferenceDefaults({
  timezone: "Europe/London",
  customerTableConfig: "",
});

// responsive module

const configureResponsive = () =>
  setResponsiveOptions({
    screenSizes: {
      mobile: { max: 639, className: "mobile" },
      tablet: { min: 640, max: 1067, className: "tablet" },
      desktop: { min: 1068 },
    },
  });

// redux store module

const configureStore = () =>
  createDefaultReduxStore({
    reducers,
    reduxDevTools: process.env.REDUX_DEVTOOLS,
  });

const isUnexpectedErrorMessage = (message) => {
  return message?.toLowerCase().includes("unexpected");
};
