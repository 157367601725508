import React from 'react';
import { Table, ModalBuilder } from '@redriver/cinnamon';
import { Targets, Actions } from 'constants/permissions';
import { PermissionCheck } from 'features/../../../shared/components/auth';
import { deleteUsageType } from './actions';
import { TranslatedModal } from '../../../../components/modals';

const RemoveUsageType = ({
  data,
  onSubmitted
}) => (
  <PermissionCheck
    target={Targets.LargeFormat}
    action={Actions.Delete}
  >
    <ModalBuilder
      submitAction={deleteUsageType}
      submitParams={{
        id: data.id
      }}
      onSubmitted={onSubmitted}
      renderTrigger={open => (
        <Table.MenuItem onClick={open}>
          Delete
        </Table.MenuItem>
      )}
      renderModal={modalProps => (
        <TranslatedModal.Confirmation
          {...modalProps}
          header='Delete Usage Type'
          onClick={event =>
            event && event.stopPropagation()
          }    
        >
          <span>
            Would you like to delete this Usage Type?
          </span>
        </TranslatedModal.Confirmation>
      )}
    />
  </PermissionCheck>
);

export default RemoveUsageType;
