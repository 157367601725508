import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { BreadcrumbBar } from "pages/nav";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { CFAList as CFAListFeature } from "features/CFA";

const CFAList = () => {
  return (
    <PermissionCheck target={Targets.CFA} action={Actions.View}>
      <PageContent fluid className="cfa-container">
        <BreadcrumbBar />
        <CFAListFeature />
      </PageContent>
    </PermissionCheck>
  );
};

export default CFAList;
