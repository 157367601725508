import React from "react";
import { ModalBuilder, Modal, Table } from "@redriver/cinnamon";

const RemoveMeteredServiceModal = ({ submitAction, onSubmitted, service }) => (
  <ModalBuilder
    submitAction={submitAction}
    submitParams={service.id}
    onSubmitted={onSubmitted}
    renderTrigger={(showModal) => (
      <Table.MenuItem onClick={showModal}>Delete</Table.MenuItem>
    )}
    renderModal={(modalProps) => (
      <Modal.Delete
        {...modalProps}
        header="Remove Metered Service"
        submitLabel="Remove Metered Service"
      >
        <p>
          {"Are you sure you wish to remove '"}
          {service.description}
          {"'?"}
        </p>
      </Modal.Delete>
    )}
  />
);

export default RemoveMeteredServiceModal;
