import {savePreferences, updatePreference } from "@redriver/cinnamon";


export const  saveTsPreferences = async ( id, config, dispatch ) =>   {
    await dispatch(updatePreference(
        id, config,
    ));
    await dispatch(savePreferences());
}

