import React from "react";
import { connect } from "react-redux";
import { Lookup } from "@redriver/cinnamon";
import {
  RateTableLookupDropdown,
  RateGroupLookupDropdown,
  LookupTypes,
  YieldBands,
  EditableLiquidityChargesTable,
  getLiquidityChargesByRateGroupId,
  updateLiquidityCharges,
  setLiquidityChargesForm,
  getRates,
  getDefaultRateGroup,
  RateMatrix,
  RateTypeLookupDropdown,
} from "features/../../../shared/features/Settings";
import {
  clearRatesState,
  setRateGroupAsync,
  getRateTableTypesLookupData,
  setRateTableFilters,
  setRateType,
  setComparisonRateType,
} from "../actions";
import { getSettingsState, getRatesState } from "../selectors";
import RRPFieldTable from "./RRPFieldsTable";
import { Targets, Actions } from "constants/permissions";
import {
  withPermissions,
  PermissionCheck,
} from "features/../../../shared/components/auth";

class RateTables extends React.Component {
  async componentDidMount() {
    const {
      getDefaultRateGroup,
      data,
      clearRatesState,
      getRateTableTypesLookupData,
      setRateTableFilters,
    } = this.props;

    clearRatesState();
    getDefaultRateGroup(false, data.id, getRatesState);
    await getRateTableTypesLookupData();
    setRateTableFilters();
  }

  render() {
    const {
      data,
      filters,
      rateGroupId,
      rateTableType,
      rateTableTypeName,
      rates,
      ratesLoading,
      liquidityLoading,
      liquidityCharges,
      setRateGroupAsync,
      updateLiquidityCharges,
      getLiquidityChargesByRateGroupId,
      setLiquidityChargesForm,
      getRates,
      setRateTableFilters,
      setRateType,
      setComparisonRateType,
      rateType,
      comparisonRateType,
    } = this.props;

    const dealerOfficeId = data.id;
    if (rateGroupId == undefined || null) return null;
    return (
      <PermissionCheck target={Targets.Rates} action={Actions.View}>
        <Lookup
          lookup={LookupTypes.RateTableTypes}
          render={({ response }) => (
            <div>
              <div className="content-panel rates-tables">
                <div className="top">
                  <div className="top-container-1">
                    <div className="header-dropdown rate-group">
                      <label>Rate Group</label>
                      <RateGroupLookupDropdown
                        lookupParams={{
                          includeInactive: true,
                          search: null,
                          dealerOfficeId,
                        }}
                        value={rateGroupId}
                        onChange={async (event, data) => {
                          await setRateGroupAsync(data);
                        }}
                      />
                    </div>
                    {rateGroupId && filters.rateTableType && (
                      <YieldBands
                        rateGroupId={rateGroupId}
                        paymentMethod={filters.paymentMethod}
                        funderType={filters.funderType}
                        financeType={filters.financeType}
                      />
                    )}
                    {rateGroupId &&
                      filters.funderType &&
                      filters.financeType &&
                      filters.paymentMethod && (
                        <div className="rrp-field-table">
                          <RRPFieldTable
                            rateGroupId={rateGroupId}
                            filters={filters}
                          />
                        </div>
                      )}
                  </div>

                  <div className="top-container-2">
                    <div className="header-dropdown rate-table-type">
                      <RateTableLookupDropdown
                        value={rateTableType}
                        onChange={(event, data) => {
                          const matchedType =
                            response.find((x) => x.value === data.value) || {};
                          setRateTableFilters(matchedType);
                        }}
                      />
                    </div>
                    <EditableLiquidityChargesTable
                      fullTitle={rateTableTypeName}
                      liquidityRecords={liquidityCharges}
                      reloadRatesOnFormChange={true}
                      includeIpSyndicateCharge={true}
                      funderType={filters.funderType}
                      financeType={filters.financeType}
                      paymentMethod={filters.paymentMethod}
                      onCancel={() =>
                        getLiquidityChargesByRateGroupId(getRatesState)
                      }
                      loading={liquidityLoading}
                      updateLiquidityCharges={updateLiquidityCharges}
                      getLiquidityChargesByRateGroupId={
                        getLiquidityChargesByRateGroupId
                      }
                      setLiquidityChargesForm={setLiquidityChargesForm}
                      getRatesState={getRatesState}
                      getRates={getRates}
                    />
                  </div>
                </div>
                <div className="bottom">
                  <div className="bottom-container-1">
                    <div className="header-dropdown rate-table-type">
                      <RateTypeLookupDropdown
                        value={rateType}
                        paymentMethod={filters.paymentMethod}
                        onChange={(event, data) => {
                          setRateType(data.value);
                        }}
                      />
                    </div>
                    <div className="content-panel rates-tables efa-rates-section">
                      {rateType && rateType !== "" && (
                        <RateMatrix
                          activeTab={rateType}
                          rates={rates}
                          loading={liquidityLoading || ratesLoading}
                          filters={filters}
                        />
                      )}
                    </div>
                  </div>
                  <div className="bottom-container-2">
                    <div className="header-dropdown rate-table-type">
                      <RateTypeLookupDropdown
                        value={comparisonRateType}
                        onChange={(event, data) => {
                          setComparisonRateType(data.value);
                        }}
                      />
                    </div>
                    <div className="content-panel rates-tables efa-rates-section">
                      {comparisonRateType && comparisonRateType !== "" && (
                        <RateMatrix
                          activeTab={comparisonRateType}
                          rates={rates}
                          loading={liquidityLoading || ratesLoading}
                          filters={filters}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      </PermissionCheck>
    );
  }
}

const actions = {
  getDefaultRateGroup,
  setRateGroupAsync,
  clearRatesState,
  updateLiquidityCharges,
  getLiquidityChargesByRateGroupId,
  setLiquidityChargesForm,
  getRates,
  getRateTableTypesLookupData,
  setRateTableFilters,
  setRateType,
  setComparisonRateType,
};

const mapStateToProps = (state) => {
  const { rates, ratesLoading, liquidityCharges, liquidityLoading, filters } =
    getRatesState(state);

  const { rateType, comparisonRateType } = getSettingsState(state);

  const { rateGroupId, rateTableType, rateTableTypeName } = filters;

  return {
    rates: rates,
    ratesLoading,
    lockedById: rates.lockedById,
    lockedByName: rates.lockedByName,
    filters,
    rateGroupId,
    rateTableType,
    rateTableTypeName,
    liquidityCharges,
    liquidityLoading,
    rateType,
    comparisonRateType,
  };
};

export default connect(mapStateToProps, actions)(withPermissions(RateTables));
