import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { DataUploads } from "features/DataUploads";
import { BreadcrumbBar } from "pages/nav";

const DataUploadsPage = () => (
  <PageContent className="standard-page data-uploads-page">
    <BreadcrumbBar />
    <DataUploads />
  </PageContent>
);

export default DataUploadsPage;
