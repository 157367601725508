import React from "react";
import { Table } from "@redriver/cinnamon";
import EditTargetMarket from "./EditTargetMarket";
import DeleteTargetMarket from "./DeleteTargetMarket";

const TargetMarketActions = ({ targetMarket, dealerOfficeId, onRefresh }) => {
  return (
    <Table.Menu>
      <EditTargetMarket
        dealerOfficeId={dealerOfficeId}
        targetMarket={targetMarket}
        onSubmitted={onRefresh}
        as={Table.MenuItem}
      />
      <DeleteTargetMarket
        targetMarket={targetMarket}
        onSubmitted={onRefresh}
        as={Table.MenuItem}
      />
    </Table.Menu>
  );
};

export default TargetMarketActions;
