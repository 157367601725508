import React from "react";
import { Button, Icon } from "semantic-ui-react";
import {
  ContentBuilder,
  ModalBuilder,
  Modal,
  ErrorMessage,
  notifySuccess,
} from "@redriver/cinnamon";
import { getIsInMaintenancePeriod } from "features/../../../shared/features/System/actions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import { startMaintenancePeriod, stopMaintenancePeriod } from "./actions";
import { useDispatch } from "react-redux";

const StartAndStopMaintenancePeriod = ({ onSubmitted }) => {
  const dispatch = useDispatch();
  return (
    <PermissionCheck
      target={Targets.MaintenancePeriodNotifications}
      action={Actions.Create}
    >
      <ContentBuilder
        loadAction={getIsInMaintenancePeriod}
        renderContent={(
          contentBuilderData,
          contentBuilderState,
          contentBuilderEvents,
        ) => {
          const inMaintenancePeriod = contentBuilderData.isInMaintenancePeriod;

          if (
            !contentBuilderState.loading &&
            inMaintenancePeriod !== true &&
            inMaintenancePeriod !== false
          ) {
            // This should never happen, this protects against an invalid value defaulting to false.
            return (
              <ErrorMessage error="Failed to retrieve maintenance period information" />
            );
          }

          const actionText = inMaintenancePeriod ? "Stop " : "Start";
          const buttonText = `${actionText} Maintenance Period`;
          const messageText = inMaintenancePeriod
            ? "This will send a notification email to all users to inform them that the maintenance period has come to an end, and remove the maintenance banner from the user-interface."
            : "This will send a notification email to all users to inform them that the maintenance period has started and add a maintenance banner to the user-interface.";
          return (
            <ModalBuilder
              submitAction={
                inMaintenancePeriod
                  ? stopMaintenancePeriod
                  : startMaintenancePeriod
              }
              onSubmitted={() => {
                contentBuilderEvents.onRefresh();
                onSubmitted();
                dispatch(
                  notifySuccess(
                    inMaintenancePeriod
                      ? "Maintenance Period stopped. The banner will disappear on Opal and Amethyst shortly"
                      : "Maintenance Period started. A banner will appear on Opal and Amethyst shortly.",
                  ),
                );
              }}
              renderTrigger={(showModal) => (
                <Button
                  onClick={showModal}
                  negative
                  className="maintenance-period-button"
                >
                  <Icon name="warning sign" />
                  {buttonText}
                </Button>
              )}
              renderModal={(modalProps) => (
                <Modal.Confirmation {...modalProps}>
                  {`Are you sure you want to ${actionText} the Maintenance Period?`}
                  <br />
                  <br />
                  {messageText}
                </Modal.Confirmation>
              )}
            />
          );
        }}
      />
    </PermissionCheck>
  );
};

export default StartAndStopMaintenancePeriod;
