import React from "react";
import { Wizard } from "@redriver/cinnamon";
import { Menu } from "semantic-ui-react";
import DataUploadsList from "./DataUploadsList";
import UploadTemplatesList from "./UploadTemplatesList";

const Tabs = {
  DataUploads: "data-uploads",
  Templates: "templates",
};

const DataUploads = () => {
  const NavigationControls = ({ steps, activeStep, onGoToStep }) => {
    return steps.map((s) => (
      <Menu.Item
        value={0}
        key={s.key}
        active={s === activeStep}
        onClick={() => onGoToStep(s.key)}
      >
        {s.title}
      </Menu.Item>
    ));
  };

  const stepData = [
    {
      key: Tabs.DataUploads,
      title: "Settings Data Uploads",
    },
    {
      key: Tabs.Templates,
      title: "Settings Upload Templates",
    },
  ];

  return (
    <div>
      <Wizard steps={stepData}>
        <div className="data-uploads-wizard-navigation">
          <Wizard.Nav
            render={(wizardContext) => (
              <Menu>
                <NavigationControls {...wizardContext} />
              </Menu>
            )}
          />
        </div>
        <Wizard.Step
          step={Tabs.DataUploads}
          render={() => <DataUploadsList />}
        />
        <Wizard.Step
          step={Tabs.Templates}
          render={() => <UploadTemplatesList />}
        />
      </Wizard>
    </div>
  );
};

export default DataUploads;
