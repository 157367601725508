import React, { useEffect, useState } from "react";
import { ContentPanel } from "components/displays";
import {
  ListBuilder,
  ActionBar,
  FormBuilder,
  usePreferences,
  Form,
} from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import {
  TableConfigurator,
  ConfigurableTable,
} from "features/CustomConfig/TableConfig";
import { getInvoices } from "../actions";
import {
  numericFormatter,
  dateFormatter,
} from "components/displays/DisplayHelper";
import { FilterGrid } from ".";
import { Image } from "semantic-ui-react";
import Search from "features/../../../shared/assets/purple-search.svg";
import { Link } from "react-router-dom";

const InvoiceSearch = () => {
  const { preferences } = usePreferences();
  const [config, setConfig] = useState(preferences.invoiceSearchTableConfig);
  const [pageSizeConfig, setPageSizeConfig] = useState(
    preferences.invoiceSearchTableConfigPageSize
      ? preferences.invoiceSearchTableConfigPageSize
      : 25
  );

  const [selectedItems, setSelected] = useState({});

  useEffect(() => {
    setConfig(preferences.invoiceSearchTableConfig);
  }, [preferences.invoiceSearchTableConfig]);

  useEffect(() => {
    preferences.invoiceSearchTableConfigPageSize &&
      setPageSizeConfig(preferences.invoiceSearchTableConfigPageSize);
  }, [preferences.invoiceSearchTableConfigPageSize]);

  const columns = [
    {
      field: "id",
      title: "Selectable Column",
      selectableColumn: true,
      sortable: false,
    },
    {
      field: "invoiceNo",
      title: "Invoice Number",
      render: item => <Link className="invoice-link">{item.invoiceNo}</Link>,
    },
    { field: "customer", title: "Customer", width: "20em" },
    {
      field: "invoiceDate",
      title: "Invoice Date",
      render: item => dateFormatter(item.invoiceDate),
    },
    { field: "scheduleNumber", title: "Schedule Number" },
    { field: "invoiceType", title: "Invoice Type" },
    { field: "supplier", title: "Supplier" },
    { field: "spv", title: "SPV" },
    {
      field: "periodFrom",
      title: "Period From",
      render: item => dateFormatter(item.periodFrom),
    },
    {
      field: "periodTo",
      title: "Period To",
      render: item => dateFormatter(item.periodTo),
    },
    { field: "currency", title: "Currency" },
    {
      field: "netValue",
      title: "Net Value (£)",
      render: item => numericFormatter(item.netValue),
    },
    {
      field: "vat",
      title: "VAT (£)",
      render: item => numericFormatter(item.vat),
    },
    {
      field: "grossValue",
      title: "Gross Value (£)",
      render: item => numericFormatter(item.grossValue),
    },
  ];

  return (
    <ListBuilder
      withTable
      initialPagination={{ pageSize: pageSizeConfig, pageNumber: 1 }}
      dataTransform={data => data.results}
      totalTransform={data => data.totalResults}
      loadAction={getInvoices}
      loadParams={{ pageSize: pageSizeConfig }}
      renderList={(tableProps, state, events) => (
        <ContentPanel
          title=""
          wrapperCssClass="outline-grid customer-billing-list invoice-search"
          headerCssClass="defaults-page-title"
          headerContent={
            <FilterBar
              state={state}
              events={events}
              config={config}
              columns={columns}
              initialPageSize={25}
              pageSizeConfig={pageSizeConfig}
            ></FilterBar>
          }
        >
          <div className="spaced">
            <div className="vertical-scrollbar large">
              <ConfigurableTable
                state={state}
                events={events}
                tableProps={tableProps}
                emptyMessage="No invoices found"
                columns={columns}
                config={config}
                selectedItems={selectedItems}
                setSelected={setSelected}
              />
            </div>
            <div className="padded">
              <TablePagination
                pageSize={pageSizeConfig}
                pageNumber={state.pagination.pageNumber}
                onChange={events.onChangePagination}
                totalItems={state.total}
              />
            </div>
          </div>
        </ContentPanel>
      )}
    />
  );
};

const FilterBar = ({
  state,
  events,
  config,
  columns,
  initialPageSize,
  pageSizeConfig,
}) => (
  <FormBuilder
    value={state.filters}
    onChange={events.onChangeFilters}
    renderForm={formProps => (
      <Form {...formProps}>
        <ActionBar className="filter-display">
          <ActionBar.Item>
            <h4 className="finance-title">Invoice Search</h4>
            <div className="sml-screen">
              <TableConfigurator
                config={config}
                columns={columns}
                configId="invoiceSearchTableConfig"
                titlePreFix="Invoice Search"
                initialPageSize={initialPageSize}
                pageSizeConfig={pageSizeConfig}
              />
            </div>
            <div className="text-search-wrapper">
              <Form.Input
                field="search"
                placeholder="Search"
                icon={<Image src={Search} />}
                className="search-input highlighted-input"
              />
            </div>
          </ActionBar.Item>
          <ActionBar.Item fluid></ActionBar.Item>
          <ActionBar.Item className="right-header-col">
            <FilterGrid
              config={config}
              columns={columns}
              configId="invoiceSearchTableConfig"
              titlePreFix="Invoice Search"
              initialPageSize={initialPageSize}
              pageSizeConfig={pageSizeConfig}
            />
          </ActionBar.Item>
        </ActionBar>
      </Form>
    )}
  />
);

export default InvoiceSearch;
