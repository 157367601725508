import { mockSuccess, MockArrayData } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULES/SERVICES";

const ActionTypes = {
  GetScheduleServices: `${NAMESPACE}/GET_SCHEDULE_SERVICES`,
  GetAdditionalScheduleServices: `${NAMESPACE}/GET_ADDITIONAL_SCHEDULE_SERVICES`,
};

const mockServices = new MockArrayData([
  {
    key: 1,
    services: "Excel",
    value: 66,
    skuCode: "",
    billingCode: "EXCEL",
    type: "Supplier",
    percentageOfTotal: 20.4,
    priceIncreaseApplicable: true,
    capitalisedService: false,
    notionalCPU: 0.146,
  },
  {
    key: 2,
    services: "Papercut Support",
    value: 65.17,
    skuCode: "",
    billingCode: "PCUTSUP",
    type: "Supplier",
    percentageOfTotal: 20.2,
    priceIncreaseApplicable: true,
    capitalisedService: false,
    notionalCPU: 0.166,
  },
  {
    key: 3,
    services: "SCR",
    value: 100,
    skuCode: "",
    billingCode: "CAPRES",
    type: "Supplier",
    percentageOfTotal: 31,
    priceIncreaseApplicable: true,
    capitalisedService: false,
    notionalCPU: 0.221,
  },
  {
    key: 4,
    services: "Print Security",
    value: 40.32,
    skuCode: "",
    billingCode: "PRINTSEC",
    type: "Supplier",
    percentageOfTotal: 12.5,
    priceIncreaseApplicable: false,
    capitalisedService: true,
    notionalCPU: 0.089,
  },
  {
    key: 5,
    services: "Service Reserve",
    value: 23.75,
    skuCode: "",
    billingCode: "SVRRES",
    type: "Supplier",
    percentageOfTotal: 7.4,
    priceIncreaseApplicable: false,
    capitalisedService: false,
    notionalCPU: 0.052,
  },
  {
    key: 6,
    services: "Admin Fee",
    value: 20.4,
    skuCode: "",
    billingCode: "ADMINFEE",
    type: "Admin",
    percentageOfTotal: 6.3,
    priceIncreaseApplicable: true,
    capitalisedService: false,
    notionalCPU: 0.045,
  },
  {
    key: 7,
    services: "IPS Admin Fee",
    value: 4.2,
    skuCode: "",
    billingCode: "IPSADMINFEE",
    type: "IP",
    percentageOfTotal: 1.3,
    priceIncreaseApplicable: true,
    capitalisedService: false,
    notionalCPU: 0.01,
  },
]);

const mockAdditionalServices = new MockArrayData([
  {
    key: 11,
    services: "Device Charge",
    value: 3,
    skuCode: "",
    billingCode: "DEVICE",
    type: "Supplier",
    percentageOfTotal: 0.7,
    priceIncreaseApplicable: false,
    capitalisedService: false,
    notionalCPU: 0.014,
  },
  {
    key: 12,
    services: "Cut Sheet paper Inclusive",
    value: 85.41,
    skuCode: "",
    billingCode: "PAPER",
    type: "Supplier",
    percentageOfTotal: 21.2,
    priceIncreaseApplicable: true,
    capitalisedService: false,
    notionalCPU: 0.397,
  },
  {
    key: 13,
    services: "Quarterly Paper Adjustment",
    value: 10.12,
    skuCode: "",
    billingCode: "PAPERADJ",
    type: "Supplier",
    percentageOfTotal: 2.5,
    priceIncreaseApplicable: false,
    capitalisedService: false,
    notionalCPU: 0.047,
  },
]);

export const getScheduleQuarterlyServices = () => {
  return mockSuccess(ActionTypes.GetScheduleServices, {
    delay: 200,
    response: mockServices,
  });
};

export const getScheduleAdditionalQuarterlyServices = () => {
  return mockSuccess(ActionTypes.GetAdditionalScheduleServices, {
    delay: 200,
    response: mockAdditionalServices,
  });
};
