import React from "react";

const ContentPanel = ({
  wrapperCssClass,
  headerCssClass,
  title,
  children,
  headerContent,
}) => (
  <div className={"content-panel " + wrapperCssClass}>
    <div className={headerCssClass + " flex"}>
      {title && (
        <div className="flex title-bar">
          <h4 className="settings-title">{title}</h4>
        </div>
      )}
      {headerContent}
    </div>
    {children}
  </div>
);

export default ContentPanel;
