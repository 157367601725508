import React from "react";
import { Segment, Header, Divider } from "semantic-ui-react";
import { PageContent, Breadcrumb } from "@redriver/cinnamon";
import { AppRoutes } from "constants/routes";
import { EditUser } from "features/../../../shared/features/Settings";

const UserPage = ({ match }) => {
  const userId = match.params.id;
  return (
    <PageContent>
      <Breadcrumb>
        <Breadcrumb.Item link={AppRoutes.Users} icon="chevron left">
          Return to Users
        </Breadcrumb.Item>
      </Breadcrumb>
      <Segment padded>
        <Header as="h4">Edit User</Header>
        <Divider hidden />
        <EditUser userId={userId} />
      </Segment>
    </PageContent>
  );
};

export default UserPage;
