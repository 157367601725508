import React, { useEffect, useState } from "react";
import {
  ListBuilder,
  ActionBar,
  usePreferences,
  Form,
  FormBuilder,
} from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { getCutSheetPaper } from "./actions";
import {
  ConfigurableTable,
  TableConfigurator,
} from "features/CustomConfig/TableConfig";
import { Image, Checkbox } from "semantic-ui-react";
import Search from "features/../../../shared/assets/purple-search.svg";
import { InfoModalWrapper } from "components/modals";
import { ViewTypeOptions } from "features/../../../shared/constants/enums";
import { numericFormatter } from "components/displays/DisplayHelper";

const ListCutSheetPaper = ({ dealerOfficeId }) => {
  const { preferences } = usePreferences();
  const [config, setConfig] = useState(preferences.cutSheetPaperTableConfig);
  const [pageSizeConfig, setPageSizeConfig] = useState(
    preferences.cutSheetPaperTableConfigPageSize
      ? preferences.cutSheetPaperTableConfigPageSize
      : 25
  );

  useEffect(() => {
    setConfig(preferences.cutSheetPaperTableConfig);
  }, [preferences.cutSheetPaperTableConfig]);

  useEffect(() => {
    preferences.cutSheetPaperTableConfigPageSize &&
      setPageSizeConfig(preferences.cutSheetPaperTableConfigPageSize);
  }, [preferences.cutSheetPaperTableConfigPageSize]);

  const [selectedItems, setSelected] = useState({});

  const columns = [
    { field: "description", title: "Description" },
    { field: "productSku", title: "Product SKU" },
    { field: "note", title: "Note" },
    { field: "colour", title: "Colour" },
    { field: "paperSize", title: "IOS/US" },
    { field: "widthMM", title: "Width (mm)" },
    { field: "lengthMM", title: "Length (mm)" },
    { field: "sheetsPerReam", title: "Sheets per Ream" },
    { field: "reamsPerBox", title: "Reams per Box" },
    { field: "weight", title: "Weight" },
    { field: "weightUnit", title: "Unit" },
    {
      field: "defaultPrice",
      title: "Default Price (£)",
      render: (item) => numericFormatter(item.defaultPrice, 2),
    },
    {
      field: "id",
      title: "Default Loaded",
      sortable: false,
      render: (item) => item.default && <Checkbox checked={true} />,
    },
  ];

  return (
    <React.Fragment>
      <h4>Cut Sheet Paper</h4>
      <ListBuilder
        withTable
        initialPagination={{ pageSize: pageSizeConfig, pageNumber: 1 }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        loadAction={dealerOfficeId ? getCutSheetPaper : null}
        loadParams={{ dealerOfficeId }}
        renderList={(tableProps, state, events) =>
          events && (
            <div className="content-container cut-sheet-paper-list ">
              <FormBuilder
                value={state.filters}
                onChange={events.onChangeFilters}
                renderForm={(formProps) => (
                  <Form {...formProps}>
                    <ActionBar>
                      <ActionBar.Item fluid>
                        <Form.Input
                          field="search"
                          placeholder="Search"
                          icon={<Image src={Search} />}
                          className="search-input highlighted-input"
                        />
                      </ActionBar.Item>
                      <ActionBar.Item align="right">
                        <Form.Dropdown
                          field="view"
                          label="View"
                          placeholder="(All)"
                          options={ViewTypeOptions}
                          inline
                        />
                      </ActionBar.Item>
                      <ActionBar.Item align="right">
                        <InfoModalWrapper title="Import Data">
                          Not implemented.
                        </InfoModalWrapper>
                        <InfoModalWrapper title="Export Data">
                          Not implemented.
                        </InfoModalWrapper>
                        <TableConfigurator
                          config={config}
                          columns={columns}
                          configId="cutSheetPaperTableConfig"
                          titlePreFix="Cut Sheet Paper"
                          initialPageSize={25}
                          pageSizeConfig={pageSizeConfig}
                        />
                      </ActionBar.Item>
                    </ActionBar>
                  </Form>
                )}
              />

              <div className="horizontal-scrollbar centre-align">
                <ConfigurableTable
                  state={state}
                  events={events}
                  tableProps={tableProps}
                  emptyMessage="No items found"
                  columns={columns}
                  config={config}
                  selectedItems={selectedItems}
                  setSelected={setSelected}
                />
              </div>

              <TablePagination
                pageNumber={state.pagination.pageNumber}
                onChange={events.onChangePagination}
                pageSize={state.pagination.pageSize}
                totalItems={state.total}
              />
            </div>
          )
        }
      />
    </React.Fragment>
  );
};

export default ListCutSheetPaper;
