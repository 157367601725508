import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULES";
export const ActionTypes = {
  GetScheduleDetails: `${NAMESPACE}/GET_SCHEDULE_DETAILS`,
  ClearScheduleDetails: `${NAMESPACE}/CLEAR_SCHEDULE_DETAILS`,
};

export const getScheduleDetails = (id) => async (dispatch) => {
  const details = await dispatch(
    apiGet(ActionTypes.GetScheduleDetails, `schedules/${id}`)
  );

  return details.response;
};

export const clearScheduleDetails = () => ({
  type: ActionTypes.ClearScheduleDetails,
});
