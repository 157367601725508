import React from "react";
import { connect } from "react-redux";
import { Grid, Button } from "semantic-ui-react";
import { Form, FormBuilder, Loadable } from "@redriver/cinnamon";
import { IconButton } from "features/../../../shared/components/buttons";
import { paymentMethods, funderTypes } from "constants/enums";
import { getResponsiveState } from "../../../../../../../../shared/modules/responsive/selectors";
import { getDefaults, setDefaults } from "./actions";
import {
  PaymentMethodsRadioGroup,
  FunderTypesRadioGroup,
} from "../../../../../../../../shared/features/Settings";
import { AccordionContentPanel } from "components/displays";
import Pen from "assets/pen.svg";
import Save from "assets/save.svg";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";

const CapitalSettingsGrid = ({
  settingsId,
  isMobile,
  collapsedOnLoad,
  expand,
  contract,
  setExpand,
}) => {
  return (
    <div className="defaults-grid">
      <FormBuilder
        initialReadOnly
        loadAction={getDefaults}
        loadParams={{ settingsId }}
        submitAction={setDefaults}
        submitParams={settingsId}
        renderLoading={false}
        renderSubmitting={false}
        renderForm={(formProps, state, events) => {
          const readOnlyPaymentMethod = paymentMethods.find(
            (x) => x.value == formProps.value.paymentMethod
          );
          const readOnlyFunderType = funderTypes.find(
            (x) => x.value == formProps.value.funderType
          );

          return (
            <AccordionContentPanel
              title="Capital"
              wrapperCssClass="outline-grid payment-method-restrictions"
              headerCssClass="defaults-page-title"
              collapsedOnLoad={collapsedOnLoad}
              expand={expand}
              contract={contract}
              headerContent={
                <PermissionCheck
                  target={Targets.Defaults}
                  action={Actions.Edit}
                >
                  <div>
                    <IconButton
                      primary
                      icon={state.readOnly ? Pen : Save}
                      content={state.readOnly ? "Edit" : "Save"}
                      floated="right"
                      onClick={async () => {
                        if (state.readOnly) {
                          await events.onRefresh();
                          events.onToggleReadOnly();
                        } else {
                          events.onSubmit({ reset: ["readOnly"] });
                        }
                        setExpand(!expand);
                      }}
                      className={
                        !state.readOnly ? "positive-title-action" : null
                      }
                      loading={state.loading || state.submitting}
                      disabled={state.loading || state.submitting}
                    />
                    {!state.readOnly && (
                      <Button
                        primary
                        className="cancel-title-action"
                        floated="right"
                        content="Cancel"
                        onClick={events.onReset}
                      />
                    )}
                  </div>
                </PermissionCheck>
              }
            >
              <div className="padded">
                <Loadable loading={state.loading || state.submitting}>
                  <Form
                    {...formProps}
                    disabled={state.submitting || state.readOnly}
                    className="highlighted-inputs"
                  >
                    <Grid
                      stackable
                      columns={!isMobile ? 4 : 3}
                      className="defaults-grid"
                    >
                      <Grid.Row columns={state.readOnly ? 4 : 3}>
                        <Grid.Column width={8}>
                          {state.readOnly ? (
                            <div className="field">
                              <label>Default Payment Method</label>
                              <div className="read-only">
                                <p>
                                  {readOnlyPaymentMethod
                                    ? readOnlyPaymentMethod.text
                                    : ""}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <PaymentMethodsRadioGroup
                              field="paymentMethod"
                              label="Default Payment Method"
                              options={
                                formProps.value.availablePaymentMethods
                                  ? paymentMethods.filter((x) =>
                                      formProps.value.availablePaymentMethods.includes(
                                        x.value
                                      )
                                    )
                                  : paymentMethods
                              }
                            />
                          )}
                        </Grid.Column>
                        <Grid.Column width={4}>
                          {state.readOnly ? (
                            <div className="field">
                              <label>Payment By</label>
                              <div className="read-only">
                                <p>
                                  {readOnlyFunderType
                                    ? readOnlyFunderType.text
                                    : ""}
                                </p>
                              </div>
                            </div>
                          ) : (
                            <FunderTypesRadioGroup
                              field="funderType"
                              label="Payment By"
                            />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Loadable>
              </div>
            </AccordionContentPanel>
          );
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { screenSize } = getResponsiveState(state);
  return {
    isMobile: screenSize === "small",
  };
};

export default connect(mapStateToProps)(CapitalSettingsGrid);
