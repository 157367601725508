import React from "react";
import { ModalBuilder } from "@redriver/cinnamon";
import { IconButton } from "features/../../../shared/components/buttons";
import { editRollWidth } from "./actions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import RollWidthModalForm from "./RollWidthModalForm";
import { TranslatedModal } from "../../../../components/modals";

const EditRollWidth = ({
  as: As = IconButton,
  onSubmitted,
  dealerOfficeId,
  rollWidth,
  ...props
}) => (
  <PermissionCheck target={Targets.LargeFormat} action={Actions.Edit}>
    <ModalBuilder
      withForm
      initialData={rollWidth}
      submitAction={editRollWidth}
      submitParams={{ id: rollWidth.id, dealerOfficeId }}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <As onClick={showModal} {...props}>
          Edit
        </As>
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Edit
          {...modalProps}
          className='roll-width'
          header="Edit Roll Width"
          submitLabel="Update"
        >
          <RollWidthModalForm
            formProps={formProps}
            dealerOfficeId={dealerOfficeId}
          />
        </TranslatedModal.Edit>
      )}
    />
  </PermissionCheck>
);

export default EditRollWidth;
