import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Lookup } from "@redriver/cinnamon";
import {
  getDefaultRateGroup,
  getYieldRates,
  setYieldRatesForm,
  saveYieldRates,
  RateTableLookupDropdown,
  RateGroupLookupDropdown,
  LookupTypes,
  YieldBands,
  EditableYieldRateTable,
} from "features/../../../shared/features/Settings";
import {
  clearRatesState,
  setRateGroupAsync,
  lockYields,
  unlockYields,
  setRateTableFilters,
} from "../actions";
import { getRatesState } from "../selectors";
import {
  withCurrentUser,
  withPermissions,
  PermissionCheck,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";

class YieldTables extends React.Component {
  state = {
    yieldRateType1: null,
    yieldRateType2: null,
    editingTable1: false,
    editingTable2: false,
    hasLockAccess: true, // update to default to false, query back-end
    adminUnlocked: false,
    rateGroupId: null,
  };

  componentDidMount() {
    const { getDefaultRateGroup, data, clearRatesState, setRateTableFilters } =
      this.props;

    clearRatesState();
    getDefaultRateGroup(true, data.id, getRatesState);
    setRateTableFilters();
  }

  mapYields(selectedRateType, yields) {
    if (!selectedRateType || !yields) return null;

    const [funderType, financeType, paymentMethod] =
      selectedRateType.split("_");
    return yields.find(
      (x) =>
        x.funderType === funderType &&
        x.financeType === financeType &&
        x.paymentMethod === paymentMethod
    );
  }

  mapYieldGroupData(selectedRateType, yields) {
    if (!selectedRateType || !yields) return null;

    const [funderType, financeType, paymentMethod] =
      selectedRateType.split("_");

    const funderTypeGroup = yields
      .map((group, index) => ({ index, funderType: group.key, group }))
      .find((x) => x.funderType == funderType);
    const financeTypeGroup = funderTypeGroup.group.yields
      .map((group, index) => ({ index, financeType: group.key, group }))
      .find((x) => x.financeType == financeType);
    const paymentMethodGroup = financeTypeGroup.group.yields
      .map((group, index) => ({
        index,
        paymentMethod: group.paymentMethod,
        group,
      }))
      .find((x) => x.paymentMethod == paymentMethod);

    let result = {
      funderIndex: funderTypeGroup.index,
      funderTitle: funderTypeGroup.group.title,
      financeTypeIndex: financeTypeGroup.index,
      financeTypeTitle: financeTypeGroup.group.title,
      paymentMethodIndex: paymentMethodGroup.index,
      paymentMethodTitle: paymentMethodGroup.group.title,
    };

    return result;
  }

  render() {
    const {
      data,
      rateGroupId,
      yieldRates,
      ratesLoading,
      setRateGroupAsync,
      setYieldRatesForm,
      unlockYields,
      getYieldRates,
      saveYieldRates,
      setRateTableFilters,
      rateTableType,
      financeType,
      funderType,
      paymentMethod,
    } = this.props;
    const {
      yieldRateType1,
      yieldRateType2,
      editingTable1,
      editingTable2,
      hasLockAccess,
    } = this.state;

    const dealerOfficeId = data.id;

    const flattenedYields = yieldRates
      ? yieldRates
          .map((funderType) =>
            funderType.yields.map((financeType) => financeType.yields).flat()
          )
          .flat()
      : [];

    const yields1 = this.mapYields(yieldRateType1, flattenedYields);
    const yields2 = this.mapYields(yieldRateType2, flattenedYields);

    const yield1Data = this.mapYieldGroupData(yieldRateType1, yieldRates) || {};
    const yield2Data = this.mapYieldGroupData(yieldRateType2, yieldRates) || {};

    return (
      <PermissionCheck target={Targets.Rates} action={Actions.View}>
        <Lookup
          lookup={LookupTypes.RateTableTypes}
          render={({ response }) => (
            <div className="content-panel yield-tables">
              <div className="rate-table-group">
                <div className="header-dropdown">
                  <label className="dropdown-label rate-group-label">
                    Rate Group
                  </label>
                  <RateGroupLookupDropdown
                    lookupParams={{
                      includeInactive: true,
                      search: null,
                      dealerOfficeId,
                    }}
                    value={rateGroupId}
                    onChange={async (event, data) => {
                      await setRateGroupAsync(data);
                      this.setState({ rateGroupId: rateGroupId });
                      getYieldRates(getRatesState);
                    }}
                  />
                </div>
                <div className="header-dropdown">
                  <label className="dropdown-label">Rate Table Type</label>
                  <RateTableLookupDropdown
                    value={rateTableType}
                    onChange={(event, data) => {
                      const matchedType =
                        response.find((x) => x.value === data.value) || {};
                      setRateTableFilters(matchedType);
                    }}
                  />
                </div>
              </div>
              {rateGroupId && rateTableType && (
                <YieldBands
                  rateGroupId={rateGroupId}
                  paymentMethod={paymentMethod}
                  funderType={funderType}
                  financeType={financeType}
                />
              )}

              <div className="yields-container">
                <div className="main-section">
                  <div className="header-dropdown rate-table-type">
                    <RateTableLookupDropdown
                      onChange={(event, data) => {
                        this.setState({
                          yieldRateType1: data.value,
                        });
                      }}
                    />
                  </div>

                  <EditableYieldRateTable
                    rates={yields1}
                    title={`${yield1Data.funderTitle} ${yield1Data.financeTypeTitle} ${yield1Data.paymentMethodTitle}`}
                    fullTitle={`${yield1Data.funderTitle} ${yield1Data.financeTypeTitle} ${yield1Data.paymentMethodTitle}`}
                    readOnly={!editingTable1}
                    onToggleReadOnly={() =>
                      this.setState({ editingTable1: !editingTable1 })
                    }
                    hasLockAccess={hasLockAccess}
                    onFormChange={(field, data, applyChanges) =>
                      setYieldRatesForm(
                        getRatesState,
                        field,
                        data,
                        applyChanges,
                        yield1Data.funderIndex,
                        yield1Data.financeTypeIndex,
                        yield1Data.paymentMethodIndex
                      )
                    }
                    onSubmit={async () => {
                      return await saveYieldRates(yields1, rateGroupId);
                    }}
                    onCancel={async () => {
                      setTimeout(async () => {
                        unlockYields(rateGroupId);
                        const res = await getYieldRates(getRatesState);
                        if (res.success) {
                          this.setState({
                            editingTable1: false,
                          });
                        }
                      }, 1000);
                    }}
                    loading={ratesLoading}
                  />
                </div>
                <div className="comparison-section">
                  <div className="header-dropdown rate-table-type">
                    <RateTableLookupDropdown
                      onChange={(event, data) => {
                        this.setState({
                          yieldRateType2: data.value,
                        });
                      }}
                    />
                  </div>

                  <EditableYieldRateTable
                    rates={yields2}
                    title={`${yield2Data.funderTitle} ${yield2Data.financeTypeTitle} ${yield2Data.paymentMethodTitle}`}
                    fullTitle={`${yield2Data.funderTitle} ${yield2Data.financeTypeTitle} ${yield2Data.paymentMethodTitle}`}
                    readOnly={!editingTable2}
                    onToggleReadOnly={() =>
                      this.setState({ editingTable2: !editingTable2 })
                    }
                    hasLockAccess={hasLockAccess}
                    onFormChange={(field, data, applyChanges) =>
                      setYieldRatesForm(
                        getRatesState,
                        field,
                        data,
                        applyChanges,
                        yield2Data.funderIndex,
                        yield2Data.financeTypeIndex,
                        yield2Data.paymentMethodIndex
                      )
                    }
                    onSubmit={async () => {
                      return await saveYieldRates(yields2, rateGroupId);
                    }}
                    onCancel={async () => {
                      unlockYields(rateGroupId);
                      const res = await getYieldRates(getRatesState);
                      if (res.success) {
                        this.setState({
                          editingTable2: false,
                        });
                      }
                    }}
                    loading={ratesLoading}
                  />
                </div>
              </div>
            </div>
          )}
        />
      </PermissionCheck>
    );
  }
}

const actions = {
  getDefaultRateGroup,
  setRateGroupAsync,
  setYieldRatesForm,
  getYieldRates,
  lockYields,
  unlockYields,
  clearRatesState,
  saveYieldRates,
  setRateTableFilters,
};

const mapStateToProps = (state) => {
  const { rates, ratesLoading, filters } = getRatesState(state);
  const { rateGroupId, rateTableType, financeType, funderType, paymentMethod } =
    filters;

  return {
    yieldRates: rates.yieldRates,
    ratesLoading,
    lockedById: rates.lockedById,
    lockedByName: rates.lockedByName,
    rateTableType,
    rateGroupId,
    financeType,
    funderType,
    paymentMethod,
  };
};

export default compose(
  connect(mapStateToProps, actions),
  withCurrentUser
)(withPermissions(YieldTables));
