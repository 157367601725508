import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "PRODUCT";
const ActionTypes = {
  GetProductSummary: `${NAMESPACE}/GET_PRODUCT_SUMMARY`,
  GetProducts: `${NAMESPACE}/GET`,
};

const mockSummaryData = {
  supplierName: "KONICA MINOLTA UK LIMITED",
  supplierId: "58645",
  territory: "LONDON",
  office: "Office 1",
  country: "UK",
  identifier: "LW84411952",
  companyName: "Test Company LLP",
};

export const getProductSummary = () => {
  return mockSuccess(ActionTypes.GetProductSummary, {
    response: mockSummaryData,
  });
};

export const getProducts = () => {
  return mockSuccess(ActionTypes.GetProducts, {
    delay: 200,
    response: MockProductData,
  });
};

const MockProductData = {
  totalResults: 18,
  results: [
    {
      systemID: 5446,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "ACTX Ltd",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 4587,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "Adams Joinery Ltd",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 2656,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "Aegis Medical Centre",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 2253,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "Alan Simpson And Co",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 6541,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "ALS Ltd",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 3525,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "Andrew Murphy Chartered",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 1522,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "Anser Solutions Ltd",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 2332,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "Avocet Aviation",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 5225,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "Bmi Southend Private Hospital",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 8792,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "Chadwell St Mary Primary",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 4558,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "Ethelbert Specialist Homes Ltd",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 2112,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "St Johns Church",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 2564,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "St Mar Mother Of God",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 6323,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "The Shehadeh Medical Centre",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 5552,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "Time Accounts Ltd",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 1472,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "YTL Ltd",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 3691,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "Wentworth Club Ltd",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
    {
      systemID: 1568,
      identifierNumber: "LW84411952",
      manufacturerAndModel: "KYOCERA P6026CDN",
      company: "XYZ Test Company",
      productLocation: "123 Made Up Street, Neverland, Surrey, KT13 3PL",
      status: "Live",
      installDate: "01/01/2022",
      titleHolder: "IP Syndicate",
      securityHolder: "IP Syndicate",
      funder: "Maquarie",
      currentContractRef: "A1B73749/022",
    },
  ],
};
