import React from "react";
import { isNil, capitalize } from "lodash";

const FormatDisplay = (item) => {
  let value = item.value;

  switch (item.label) {
    case "supplier":
      return "Office";
  }

  let returnString = `${capitalize(item.label)}: ${value}`;
  return returnString.replace(",", ", ");
};

const SchedulesFilterDisplay = ({ filters, onChange }) => {
  const items = filters
    ? Object.keys(filters)
        .filter(
          (f) =>
            f !== "search" &&
            f !== "sortDirection" &&
            f !== "sortName" &&
            f != undefined &&
            f != null
        )
        .map((k) => {
          return {
            label: k,
            value: filters[k],
          };
        })
    : [];

  if (!items.length) return null;

  return (
    <div className="filter-display">
      {items
        .filter((i) => !isNil(i.value) && i.value != "")
        .map((item) => (
          <div className="filter-display-item" key={item.label}>
            {FormatDisplay(item)}
          </div>
        ))}
      <div
        onClick={() => {
          const nextFilters = {
            ...filters,
          };
          delete nextFilters.status;
          delete nextFilters.supplier;
          onChange(nextFilters);
        }}
        className="clear-button"
      >
        (clear)
      </div>
    </div>
  );
};

export default SchedulesFilterDisplay;
