import React from "react";
import { AccordionContentPanel } from "components/displays";
import { Form } from "@redriver/cinnamon";
import { IconButton } from "../../../../shared/components/buttons";
import { Button } from "semantic-ui-react";
import Pen from "assets/pen.svg";
import Save from "assets/save.svg";



const EditableFormWrapper = ({state, events, formProps, children, title, formWrapperCssClass, wrapperCssClass, headerCssClass }) => {

  return(
    <AccordionContentPanel
      title={title}
      wrapperCssClass={wrapperCssClass}
      headerCssClass={headerCssClass}
      headerContent={     
      <div>
        <IconButton
          primary
          icon={state.readOnly ? Pen : Save}
          content={state.readOnly ? "Edit" : "Save"}
          floated="right"
          onClick={async () => {
            if (state.readOnly) {
              await events.onRefresh();
              events.onToggleReadOnly();
            } else {
              events.onSubmit({ reset: ["readOnly"] });
            }
          }}
          className={!state.readOnly ? "positive-title-action" : null}
          loading={state.loading || state.submitting}
          disabled={state.loading || state.submitting}
        />
        {!state.readOnly && (
          <Button
            primary
            className="cancel-title-action"
            floated="right"
            content="Cancel"
            onClick={events.onReset}
          />
        )}
    </div>
    }
    >
      <div className={formWrapperCssClass}>
        <Form {...formProps}>
          {children}
        </Form>
      </div>
    </AccordionContentPanel>

)};


export default EditableFormWrapper;
