import React, { useRef, useState } from "react";
import CapitalSettingsGrid from "./Capital/CapitalSettingsGrid";
import { PaymentMethodRestrictions } from "../RestrictionSettings";
import { Menu } from "semantic-ui-react";
import FunderSettings from "./FunderSettings/FunderSettings";
import {
  FunderType,
  FunderTypeCssClass,
} from "../../../../../../../shared/constants/enums";

const CapitalSettings = ({ settingsId }) => {
  const [expandCapitalSettingGrid, setExpandCapitalSettingGrid] =
    useState(false);
  const [expandPaymentMethodRestrictions, setExpandPaymentMethodRestrictions] =
    useState(false);
  const [expandCoFunderCFA, setExpandCoFunderCFA] = useState(false);
  const [expandFunderCFA, setExpandFunderCFA] = useState(false);
  const [expandSupplierCFA, setExpandSupplierCFA] = useState(false);

  const [contractAccordions, setContractAccordions] = useState(false);
  const [activeMenu, setActiveMenu] = useState(0);

  const capitalSettingGridRef = useRef();
  const paymentMethodRestrictionsRef = useRef();
  const coFunderCFARef = useRef();
  const funderCFARef = useRef();
  const supplierCFARef = useRef();

  const refs = [
    { ref: capitalSettingGridRef },
    { ref: paymentMethodRestrictionsRef },
    { ref: coFunderCFARef },
    { ref: funderCFARef },
    { ref: supplierCFARef },
  ];

  const focusOnTarget = (reference) => {
    setActiveMenu(reference);
    const focus = refs[reference];
    focus.ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const resetMenu = () => {
    setContractAccordions(!contractAccordions);
  };

  return (
    <div className="capital-settings">
      <Menu>
        <Menu.Item
          active={activeMenu == 0}
          onClick={() => {
            resetMenu(),
              setTimeout(
                setExpandCapitalSettingGrid(!expandCapitalSettingGrid),
                1000
              ),
              setTimeout(focusOnTarget(0), 100);
          }}
        >
          Capital Settings
        </Menu.Item>
        <Menu.Item
          active={activeMenu == 1}
          onClick={() => {
            resetMenu(),
              setExpandPaymentMethodRestrictions(
                !expandPaymentMethodRestrictions
              ),
              focusOnTarget(1);
          }}
        >
          Payment Method
        </Menu.Item>
        <Menu.Item
          active={activeMenu == 2}
          onClick={() => {
            resetMenu(),
              setExpandCoFunderCFA(!expandCoFunderCFA),
              focusOnTarget(2);
          }}
        >
          Funder CFA
        </Menu.Item>
        <Menu.Item
          active={activeMenu == 3}
          onClick={() => {
            resetMenu(), setExpandFunderCFA(!expandFunderCFA), focusOnTarget(3);
          }}
        >
          Supplier CFA
        </Menu.Item>
        <Menu.Item
          active={activeMenu == 4}
          onClick={() => {
            resetMenu(),
              setExpandSupplierCFA(!expandSupplierCFA),
              focusOnTarget(4);
          }}
        >
          Co-Funded CFA
        </Menu.Item>
      </Menu>
      <br />

      <div ref={capitalSettingGridRef}>
        <CapitalSettingsGrid
          settingsId={settingsId}
          collapsedOnLoad={false}
          expand={expandCapitalSettingGrid}
          setExpand={setExpandCapitalSettingGrid}
          contract={contractAccordions}
        />
      </div>
      <br />
      <div ref={paymentMethodRestrictionsRef}>
        <PaymentMethodRestrictions
          settingsId={settingsId}
          collapsedOnLoad={true}
          expand={expandPaymentMethodRestrictions}
          setExpand={setExpandPaymentMethodRestrictions}
          contract={contractAccordions}
        />
      </div>
      <br />
      <div ref={coFunderCFARef}>
        <FunderSettings
          settingsId={settingsId}
          collapsedOnLoad={true}
          contract={contractAccordions}
          title="Funder CFA"
          type={FunderType.Funder}
          cssClass={FunderTypeCssClass.Funder}
          setExpand={setExpandCoFunderCFA}
          expand={expandCoFunderCFA}
        />
      </div>
      <br />
      <div ref={funderCFARef}>
        <FunderSettings
          settingsId={settingsId}
          collapsedOnLoad={true}
          expand={expandFunderCFA}
          setExpand={setExpandFunderCFA}
          contract={contractAccordions}
          title="Supplier CFA"
          type={FunderType.Dealer}
          cssClass={FunderTypeCssClass.Dealer}
        />
      </div>
      <br />
      <div ref={supplierCFARef}>
        <FunderSettings
          settingsId={settingsId}
          collapsedOnLoad={true}
          expand={expandSupplierCFA}
          setExpand={setExpandSupplierCFA}
          contract={contractAccordions}
          title="Co-Funded-CFA"
          type={FunderType.CoFunded}
          cssClass={FunderTypeCssClass.CoFunded}
        />
      </div>
    </div>
  );
};

export default CapitalSettings;
