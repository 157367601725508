import React from "react";
import { addRollWidth } from "./actions";
import { IconButton } from "features/../../../shared/components/buttons";
import { ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import RollWidthModalForm from "./RollWidthModalForm";
import { TranslatedModal } from "../../../../components/modals";
import { useTranslation } from "react-i18next";

const AddRollWidth = ({
  as: As = IconButton,
  onAdded,
  dealerOfficeId,
  ...props
}) => {
  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'largeFormat.rollWidth'});
  return(
  <PermissionCheck target={Targets.LargeFormat} action={Actions.Create}>
    <ModalBuilder
      withForm
      submitAction={addRollWidth}
      submitParams={{ dealerOfficeId }}
      onSubmitted={onAdded}
      renderTrigger={showModal => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Add
          {...modalProps}
          className='roll-width'
          header={t("createRollWidth")}
          submitLabel={t("createRollWidth")}
        >
          <RollWidthModalForm
            formProps={formProps}
          />
        </TranslatedModal.Add>
      )}
    />
  </PermissionCheck>
)};

export default AddRollWidth;
