import React from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { Button, Image } from "semantic-ui-react";
import Add from "features/../../../shared/assets/white-add.svg";

const AddModalWrapper = ({
  children,
  floated,
  linkText,
  title,
  submitLabel,
  submitIcon,
  modalClassName,
  size,
}) => {
  return (
    <React.Fragment>
      <ModalBuilder
        renderTrigger={(showModal) => (
          <Button primary floated={floated} icon onClick={showModal}>
            <Image src={Add} alt="" aria-hidden="true" />
            <span>{linkText || title}</span>
          </Button>
        )}
        renderModal={(modalProps) => (
          <Modal
            submitLabel={submitLabel}
            submitIcon={submitIcon}
            header={title}
            className={modalClassName}
            size={size}
            {...modalProps}
          >
            {children}
          </Modal>
        )}
      />
    </React.Fragment>
  );
};

export default AddModalWrapper;
