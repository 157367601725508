import React from "react";
import { AccordionContentPanel } from "components/displays";
import { Format } from "@redriver/cinnamon";
import { SimpleTableBuilder } from "features/../../../shared/components/tables/sortableTable";
import { numericFormatter } from "components/displays/DisplayHelper";
import {
  getFunderDetails,
  getSupplierDetails,
  getCoFunderDetails,
  getGrandTotals,
} from "./actions";
import { Options } from "./Options";

const columns = [
  {
    field: "cfaReference",
    title: "CFA Reference",
    sortable: false,
    width: "4.8em",
  },
  {
    field: "funder",
    title: "Funder",
    sortable: false,
    width: "6.6em",
  },
  {
    field: "startDate",
    title: "Start Date",
    sortable: false,
    width: "6em",
    render: (x) => <Format.Date value={x.startDate} format="DD MMM YYYY" />,
  },
  {
    field: "status",
    title: "Status",
    sortable: false,
    width: "4.7em",
  },
  {
    field: "rateType",
    title: "Rate Type",
    sortable: false,
    width: "5.5em",
  },
  {
    field: "cfaPayment",
    title: `CFA Payment ${process.env.MAJOR_CURRENCY}`,
    sortable: false,
    width: "4.9em",
    render: (x) => numericFormatter(x.cfaPayment),
  },
  {
    field: "periodsOutstanding",
    title: "Periods Outstanding",
    sortable: false,
    width: "5.6em",
    render: (x) => numericFormatter(x.periodsOutstanding, 0, 3),
  },
  {
    field: "minimumOutstanding",
    title: `Minimum Outstanding ${process.env.MAJOR_CURRENCY}`,
    sortable: false,
    width: "5.6em",
    render: (x) => numericFormatter(x.minimumOutstanding),
  },
  {
    field: "scheduleAdditionalUnits",
    title: `Schedule Additional Units ${process.env.MAJOR_CURRENCY}`,
    sortable: false,
    width: "6.4em",
    render: (x) => numericFormatter(x.scheduleAdditionalUnits),
  },
  {
    field: "totalOutstanding",
    title: `Total Outstanding ${process.env.MAJOR_CURRENCY}`,
    sortable: false,
    width: "5.6em",
    render: (x) => numericFormatter(x.totalOutstanding),
  },
  {
    field: "financeVolume",
    title: "Finance Volume",
    sortable: false,
    width: "5.1em",
    render: (x) => numericFormatter(x.financeVolume),
  },
  {
    field: "scheduleTotalOutstanding",
    title: `Schedule Total Outstanding ${process.env.MAJOR_CURRENCY}`,
    sortable: false,
    width: "5.6em",
    render: (x) => numericFormatter(x.scheduleTotalOutstanding),
  },
  {
    field: "continuingPaymentOutstanding",
    title: `Continuing Payment Outstanding ${process.env.MAJOR_CURRENCY}`,
    sortable: false,
    width: "5.6em",
    render: (x) => numericFormatter(x.continuingPaymentOutstanding),
  },
  {
    field: "accruedAdditionalUnitsNotIncluded",
    title: `Accrued Additional Units NOT Included ${process.env.MAJOR_CURRENCY}`,
    sortable: false,
    width: "7.6em",
    render: (x) => numericFormatter(x.accruedAdditionalUnitsNotIncluded),
  },
  {
    field: "funderOutstanding",
    title: `Funder Outstanding ${process.env.MAJOR_CURRENCY}`,
    sortable: false,
    width: "5.6em",
    render: (x) => numericFormatter(x.funderOutstanding),
  },
  {
    sortable: false,
    width: "2.62em",
    render: () => <Options />,
  },
];

const CFADetails = () => {
  return (
    <React.Fragment>
      <TablePanel
        title="Funder"
        loadAction={getFunderDetails}
        className="cfa-detail-table"
      />
      <TablePanel
        title="Supplier"
        loadAction={getSupplierDetails}
        className="cfa-detail-table"
      />
      <TablePanel
        title="Co Funded"
        loadAction={getCoFunderDetails}
        className="cfa-detail-table co-funded"
      />
      <TablePanel
        contentClassName="highlight"
        title="Total"
        loadAction={getGrandTotals}
        className="cfa-detail-table total"
      />
    </React.Fragment>
  );
};

const TablePanel = ({ title, loadAction, className, contentClassName }) => (
  <div className="padded">
    <AccordionContentPanel
      title={title}
      wrapperCssClass={"outline-grid filtered-grid " + contentClassName}
      headerCssClass="defaults-page-title"
    >
      <div className="spaced horizontal-scrollbar">
        <SimpleTableBuilder
          withTable
          loadAction={loadAction}
          columns={columns}
          className={className}
        />
      </div>
    </AccordionContentPanel>
  </div>
);

export default CFADetails;
