import React from "react";
import { getScheduleInvoices } from "./actions";
import { ContentPanel } from "components/displays";
import { numericFormatter } from "components/displays/DisplayHelper";
import { SimpleTableBuilder } from "../../../../../../shared/components/tables/sortableTable";

const columns = [
  {
    field: "invoiceNumber",
    title: "Invoice Number",
    render: (item) => (
      <span className={item.isCreditNote && "red"}>
        <a href={void 0}>{item.invoiceNumber}</a>
      </span>
    ),
  },
  {
    field: "date",
    title: "Date",
    render: (item) => (
      <span className={item.isCreditNote && "red"}>{item.date}</span>
    ),
  },
  {
    field: "type",
    title: "Type",
    render: (item) => (
      <span className={item.isCreditNote && "red"}>{item.type}</span>
    ),
  },
  {
    field: "periodFrom",
    title: "Period From",
    render: (item) => (
      <span className={item.isCreditNote && "red"}>{item.periodFrom}</span>
    ),
  },
  {
    field: "periodTo",
    title: "Period To",
    render: (item) => (
      <span className={item.isCreditNote && "red"}>{item.periodTo}</span>
    ),
  },
  {
    field: "net",
    title: `Net (${process.env.MAJOR_CURRENCY})`,
    render: (item) => (
      <span className={item.isCreditNote && "red"}>
        {numericFormatter(item.net)}
      </span>
    ),
  },
  {
    field: "tax",
    title: `Tax (${process.env.MAJOR_CURRENCY})`,
    render: (item) => (
      <span className={item.isCreditNote && "red"}>
        {numericFormatter(item.tax)}
      </span>
    ),
  },
  {
    field: "gross",
    title: `Gross (${process.env.MAJOR_CURRENCY})`,
    render: (item) => (
      <span className={item.isCreditNote && "red"}>
        {numericFormatter(item.gross)}
      </span>
    ),
  },
  {
    field: "paymentStatus",
    title: "Payment Status",
    render: (item) => (
      <span className={item.isCreditNote && "red"}>{item.paymentStatus}</span>
    ),
  },
  {
    field: "daysOverdue",
    title: "Days Overdue",
    render: (item) => (
      <span className={item.isCreditNote && "red"}>{item.daysOverdue}</span>
    ),
  },
  {
    title: `Late Payment Charge (${process.env.MAJOR_CURRENCY})`,
    render: (item) => (
      <span className={item.isCreditNote && "red"}>
        {numericFormatter(item.latePaymentCharge, "-")}
      </span>
    ),
  },
  {
    field: "created",
    title: "Created",
    render: (item) => (
      <span className={item.isCreditNote && "red"}>{item.created}</span>
    ),
  },
];

const Invoices = () => {
  return (
    <ContentPanel
      title="Invoices"
      wrapperCssClass="outline-grid"
      headerCssClass="defaults-page-title"
    >
      <div className="padded">
        <div className="schedule-services-body-container">
          <SimpleTableBuilder
            emptyMessage="No invoices found"
            columns={columns}
            className="schedule-services-body"
            dataKey="id"
            loadAction={getScheduleInvoices}
          />
        </div>
      </div>
    </ContentPanel>
  );
};

export default Invoices;
