import React from "react";
import { PageContent } from "@redriver/cinnamon";
import SPVsList from "features/SPVs/SPVsList";
import { BreadcrumbBar } from "pages/nav";

const SPVsListPage = () => (
  <PageContent fluid>
    <BreadcrumbBar />
    <SPVsList />
  </PageContent>
);

export default SPVsListPage;
