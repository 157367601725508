import { mockSuccess, MockArrayData } from "@redriver/cinnamon";

const NAMESPACE = "TERMS_CONDITIONS";

const ActionTypes = {
  GetTermsAndConditions: `${NAMESPACE}/GET_TERMS_AND_CONDITIONS`,
  GetTAndCMeteredItems: `${NAMESPACE}/GET_METERED_SERVICES`,
  GetTAndCQuarterlyServices: `${NAMESPACE}/GET_QUARTERLY_SERVICES`,
};

const mockTermsConditions = new MockArrayData([
  {
    details: {
      masterDocument: "Docusign Doc Link",
      masterTandCRef: "341(4)",
      masterId: "MA2678",
      masterStartDate: "11-Jun-2018",
      scheduleDocument: "Docusign Doc Link",
      scheduleTandCRef: "342(3)",
      scheduleId: "SC85412",
      scheduleStartDate: "11-Mar-2021",
    },
    additionalTerms: {
      masterAdditionalTerms: "",
      scheduleAdditionalTerms: "",
    },
    meteredItems: [
      {
        id: "1",
        itemId: "B",
        description: "Black Unit",
        ipsTandCRef: "250",
        supplierTandCRef: "B8541",
        linkToClause: "Link",
      },
      {
        id: "2",
        itemId: "C",
        description: "Colour Unit",
        ipsTandCRef: "251",
        supplierTandCRef: "C5541",
        linkToClause: "Link",
      },
    ],
    quarterlyServices: [
      {
        id: "1",
        description: "Papercut Support",
        ipsTandCRef: "412",
        supplierTandCRef: "PC541",
        linkToClause: "Link",
      },
      {
        id: "2",
        description: "Print Security",
        ipsTandCRef: "488",
        supplierTandCRef: "PS222",
        linkToClause: "Link",
      },
    ],
  },
]);

export const getTermsAndConditions = () => {
  return mockSuccess(ActionTypes.GetTermsAndConditions, {
    delay: 200,
    response: mockTermsConditions,
  });
};

export const getTAndCMeteredItems = () => {
  return mockSuccess(ActionTypes.GetTAndCMeteredItems, {
    delay: 500,
    response: mockTermsConditions._data[0].meteredItems,
  });
};

export const getTAndCQuarterlyServices = () => {
  return mockSuccess(ActionTypes.GetTAndCQuarterlyServices, {
    delay: 500,
    response: mockTermsConditions._data[0].quarterlyServices,
  });
};
