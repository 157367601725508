import React from "react";
import { Button } from "semantic-ui-react";
import { FormBuilder, Form, Loadable } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { IconButton } from "features/../../../shared/components/buttons";
import {
  PaymentMethodsRadioGroup,
  PaymentMethodsCheckboxGroup,
} from "features/../../../shared/features/Settings/lookups";
import {
  PaymentMethodAvailability,
  paymentMethodAvailabilityOptions,
} from "features/../../../shared/constants/enums";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { paymentMethods } from "features/../../../shared/constants/enums/helpers";
import { Targets, Actions } from "constants/permissions";
import {
  updatePaymentMethodRestrictions,
} from "./actions";
import { getDefaultsRestrictions } from "..";
import { AccordionContentPanel } from "components/displays";


const PaymentMethodRestrictions = ({ settingsId, collapsedOnLoad, expand, contract, setExpand }) => {
  return (
    <div className="defaults-grid payment-method-restrictions">
      <FormBuilder
        initialReadOnly
        loadAction={getDefaultsRestrictions}
        loadParams={{ settingsId }}
        submitAction={updatePaymentMethodRestrictions}
        submitParams={{ settingsId }}
        renderLoading={false}
        renderSubmitting={false}
        renderError={false}
        loadTransform={data => ({
          ...data.paymentMethod,
          availableToDealer: data.paymentMethod.availableToDealer
            ? PaymentMethodAvailability.IpsAndDealer
            : PaymentMethodAvailability.IpsOnly,
        })}
        renderForm={(formProps, state, events) => {
          return (
            <AccordionContentPanel title=" Payment Method (Master Creation Only)"
              wrapperCssClass="outline-grid payment-method-restrictions"
              headerCssClass="defaults-page-title"
              collapsedOnLoad={collapsedOnLoad}
              expand={expand}
              contract={contract}
              headerContent={
                <PermissionCheck
                  target={Targets.DefaultsRestrictions}
                  action={Actions.Edit}>
                  <div>
                    <IconButton
                      primary
                      icon={state.readOnly ? Pen : Save}
                      content={state.readOnly ? "Edit" : "Save"}
                      floated="right"
                      onClick={() => {
                        state.readOnly
                          ? events.onToggleReadOnly()
                          : events.onSubmit({ reset: ["readOnly"] })
                          , setExpand(!expand)
                      }
                      }
                      className={
                        !state.readOnly ? "positive-title-action" : null
                      }
                      loading={state.loading || state.submitting}
                      disabled={state.loading || state.submitting}
                    />
                    {!state.readOnly && (
                      <Button
                        primary
                        className="cancel-title-action"
                        floated="right"
                        content="Cancel"
                        onClick={events.onReset}
                      />
                    )}
                  </div>
                </PermissionCheck>
              }>

              <div className="principal-list padded">
                <Loadable loading={state.loading || state.submitting}>
                  <Form {...formProps} disabled={state.submitting || state.readOnly} className="highlighted-inputs">
                    <Form.RadioGroup
                      field="availableToDealer"
                      options={paymentMethodAvailabilityOptions}
                      readOnly={state.readOnly}
                    />
                    <div className="default-available-wrapper">
                      <PaymentMethodsRadioGroup
                        field="default"
                        label="Default"
                        options={paymentMethods.map(item => ({
                          text: "",
                          value: item.value,
                        }))}
                        readOnly={state.readOnly}
                      />
                      <PaymentMethodsCheckboxGroup
                        field="availableOptions"
                        label="Available"
                        readOnly={state.readOnly}
                      />
                    </div>
                  </Form>
                </Loadable>
              </div>
            </AccordionContentPanel>
          );
        }} />
    </div>
  );
};

export default PaymentMethodRestrictions;
