import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { Button, Icon } from "semantic-ui-react";

import { createDealerOffice } from "./actions";
import DealerOfficeForm from "./DealerOfficeForm";

const AddDealerOffice = ({
  data,
  dealerStructureId,
  depth,
  dealerId,
  content,
  fullCrmCode,
  onRefresh,
}) => (
  <ModalBuilder
    withForm
    submitAction={createDealerOffice}
    submitParams={{ dealerStructureId, dealerId, depth }}
    initialData={data}
    onSubmitted={onRefresh}
    renderTrigger={(showModal) => (
      <Button className="new-btn blue" onClick={showModal}>
        <Icon name="add" />
        {content}
      </Button>
    )}
    renderModal={(modalProps, formProps) => (
      <Modal.Add
        {...modalProps}
        header="Create Supplier Office"
        submitLabel="Save"
        size="large"
      >
        <DealerOfficeForm
          {...formProps}
          className="supplier-office-form-modal"
          fullCrmCode={`${fullCrmCode}-${data.crmId}`}
        />
      </Modal.Add>
    )}
  />
);

export default AddDealerOffice;
