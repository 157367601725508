import React from "react";
import { Wizard, ContentBuilder } from "@redriver/cinnamon";
import { Menu } from "semantic-ui-react";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { CFASummary, CFAProducts, CFAScheduleDetails, CFAPeriodView } from ".";
import { getCFAData } from "./actions";

const CFASteps = {
  Summary: "summary",
  Products: "products",
  ScheduleDetails: "schedule-details",
  PeriodView: "period-view",
};

const CFA = ({ cfaId }) => {
  const NavigationControls = ({ steps, activeStep, onGoToStep }) => {
    return steps.map((s) => (
      <Menu.Item
        value={0}
        key={s.key}
        active={s === activeStep}
        onClick={() => onGoToStep(s.key)}
      >
        {s.title}
      </Menu.Item>
    ));
  };

  const stepData = [
    {
      key: CFASteps.Summary,
      title: "Summary",
    },
    {
      key: CFASteps.Products,
      title: "Products",
    },
    {
      key: CFASteps.ScheduleDetails,
      title: "Schedule Details",
    },
    {
      key: CFASteps.PeriodView,
      title: "Period View",
    },
  ];

  return (
    <PermissionCheck target={Targets.CFA} action={Actions.View}>
      <div>
        <ContentBuilder
          loadAction={getCFAData}
          loadParams={cfaId}
          renderContent={(data) => <SummaryTable cfa={data}></SummaryTable>}
        />

        <Wizard steps={stepData}>
          <div className="products-wizard-navigation">
            <Wizard.Nav
              render={(wizardContext) => (
                <Menu>
                  <NavigationControls {...wizardContext} />
                </Menu>
              )}
            />
          </div>
          <Wizard.Step
            step={CFASteps.Summary}
            render={() => <CFASummary cfaId={cfaId} />}
          />
          <Wizard.Step
            step={CFASteps.Products}
            render={() => <CFAProducts />}
          />
          <Wizard.Step
            step={CFASteps.ScheduleDetails}
            render={() => <CFAScheduleDetails cfaId={cfaId} />}
          />
          <Wizard.Step
            step={CFASteps.PeriodView}
            render={() => <CFAPeriodView />}
          />
        </Wizard>
      </div>
    </PermissionCheck>
  );
};

const SummaryTable = ({ cfa }) => (
  <table className="ui celled table">
    <thead>
      <tr>
        <th>CFA Reference</th>
        <th>Funder Type</th>
        <th>Funder</th>
        <th>Payment Method</th>
        <th>Payment Profile</th>
        <th>Rate Type</th>
        <th>CFA Status</th>
        <th>Current Schedule Status</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td data-label="cfaReference">{cfa.cfaReference}</td>
        <td data-label="funderType">{cfa.funderType}</td>
        <td data-label="funder">{cfa.funder}</td>
        <td data-label="paymentMethod">{cfa.paymentMethod}</td>
        <td data-label="paymentProfile">{cfa.paymentProfile}</td>
        <td data-label="rateType">{cfa.rateType}</td>
        <td data-label="cfaStatus">{cfa.cfaStatus}</td>
        <td data-label="currentScheduleStatus">{cfa.currentScheduleStatus}</td>
      </tr>
    </tbody>
  </table>
);

export default CFA;
