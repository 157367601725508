import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "COMPANIES/CUSTOMERS";

const ActionTypes = {
  getNewCustomerCrmUrl: `${NAMESPACE}/GET_NEW_CRM_URL`,
};

export const getNewCustomerCrmUrl = ({ data }) => {
  return apiGet(
    ActionTypes.getNewCustomerCrmUrl,
    `clients/new-customer-crm-url`,
    data
  );
};
