import React, { useRef, useState } from "react";
import {
  VolumeSelectionRestrictions,
  CostPerDevice,
  AgreementStability,
  DisableMinimumLogic,
  SettlementParameters,
} from "../RestrictionSettings";
import { Grid } from "semantic-ui-react";

const ScheduleSettings = ({ settingsId }) => {
  const volumeSelectionRef = useRef();
  const costPerDeviceRef = useRef();
  const disableMinimumLogicRef = useRef();
  const agreementStabilityRef = useRef();
  const settlementParametersRef = useRef();

  const refs = [
    { ref: volumeSelectionRef },
    { ref: costPerDeviceRef },
    { ref: disableMinimumLogicRef },
    { ref: agreementStabilityRef },
    { ref: settlementParametersRef },
  ];

  const [volumeSelectionCollapsed, setVolumeSelectionCollapsed] =
    useState(true);
  const [costPerDeviceCollapsed, setCostPerDeviceCollapsed] = useState(true);
  const [disableMinLogicCollapsed, setDisableMinLogicCollapsed] =
    useState(true);
  const [agreementStabilityCollapsed, setAgreementStabilityCollapsed] =
    useState(true);
  const [settlementParametersCollapsed, setSettlementParametersCollapsed] =
    useState(true);

  const focusOnTarget = (reference) => {
    const focus = refs[reference];
    focus.ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <React.Fragment>
      {settingsId && (
        <Grid className="settings-grid">
          <Grid.Row className="settings-grid-nav-header">
            <Grid.Column>
              <div
                onClick={() => {
                  setVolumeSelectionCollapsed(!volumeSelectionCollapsed);
                  setTimeout(function () {
                    focusOnTarget(0);
                  }, 1);
                }}
              >
                Volume Selection
              </div>
            </Grid.Column>
            <Grid.Column>
              <div
                onClick={() => {
                  setCostPerDeviceCollapsed(!costPerDeviceCollapsed);
                  setTimeout(function () {
                    focusOnTarget(1);
                  }, 1);
                }}
              >
                Cost Per Device
              </div>
            </Grid.Column>
            <Grid.Column>
              <div
                onClick={() => {
                  setDisableMinLogicCollapsed(!disableMinLogicCollapsed);
                  setTimeout(function () {
                    focusOnTarget(2);
                  }, 1);
                }}
              >
                Disable Minimum Logic
              </div>
            </Grid.Column>
            <Grid.Column>
              <div
                onClick={() => {
                  setAgreementStabilityCollapsed(!agreementStabilityCollapsed);
                  setTimeout(function () {
                    focusOnTarget(3);
                  }, 1);
                }}
              >
                Agreement Stability
              </div>
            </Grid.Column>
            <Grid.Column className="last-col">
              <div
                onClick={() => {
                  setSettlementParametersCollapsed(
                    !settlementParametersCollapsed
                  );
                  setTimeout(function () {
                    focusOnTarget(4);
                  }, 1);
                }}
              >
                Settlement Parameters
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div className="flex title-bar">
              <h4 className="settings-title">Schedule Settings</h4>
            </div>
          </Grid.Row>
          <Grid.Row className="settings-grid-row">
            <VolumeSelectionRestrictions
              ref={volumeSelectionRef}
              settingsId={settingsId}
              collapsed={volumeSelectionCollapsed}
              toggleVisibility={() =>
                setVolumeSelectionCollapsed(!volumeSelectionCollapsed)
              }
            />
          </Grid.Row>
          <Grid.Row className="settings-grid-row">
            <CostPerDevice
              ref={costPerDeviceRef}
              collapsed={costPerDeviceCollapsed}
              settingsId={settingsId}
              toggleVisibility={() =>
                setCostPerDeviceCollapsed(!costPerDeviceCollapsed)
              }
            />
          </Grid.Row>
          <Grid.Row className="settings-grid-row">
            <DisableMinimumLogic
              ref={disableMinimumLogicRef}
              collapsed={disableMinLogicCollapsed}
              settingsId={settingsId}
              toggleVisibility={() =>
                setDisableMinLogicCollapsed(!disableMinLogicCollapsed)
              }
            />
          </Grid.Row>
          <Grid.Row className="settings-grid-row">
            <AgreementStability
              ref={agreementStabilityRef}
              collapsed={agreementStabilityCollapsed}
              settingsId={settingsId}
              toggleVisibility={() =>
                setAgreementStabilityCollapsed(!agreementStabilityCollapsed)
              }
            />
          </Grid.Row>
          <Grid.Row className="settings-grid-row">
            <SettlementParameters
              ref={settlementParametersRef}
              collapsed={settlementParametersCollapsed}
              settingsId={settingsId}
              toggleVisibility={() =>
                setSettlementParametersCollapsed(!settlementParametersCollapsed)
              }
            />
          </Grid.Row>
          {
            //  <DocumentFeeStructure settingsId={settingsId} />
          }
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ScheduleSettings;
