import React, { useEffect } from "react";
import { Wizard } from "@redriver/cinnamon";
import { ScheduleSteps } from "constants/enums";
import { Summary } from "./Summary";
import SummaryHeader from "./Summary/SummaryHeader";
import { connect } from "react-redux";
import { getScheduleDetails, clearScheduleDetails } from "./actions";

import { ScheduleCharges } from "./Charges";
import { ProductSchedule } from "./ProductSchedule";
import { LargeFormat } from "./LargeFormat";
import { Item } from "./Item";
import { Attachments } from "./Attachments";
import { Notes } from "./Notes";
import { Paper } from "./Paper";
import { ScheduleServices } from "./Services";
import { Invoices } from "./Invoices";
import { MeterReading } from "./MeterReadings";
import { TermsConditions } from "./TermsConditions";
import { Communication } from "./Communication";
import { LiabilitySettlement } from "./LiabilitySettlement";
import { CFA } from "./CFA";
import { PriceIncreases } from "./PriceIncreases";

const ManageSchedule = ({
  scheduleId,
  clearScheduleDetails,
  getScheduleDetails,
}) => {
  useEffect(() => {
    clearScheduleDetails();
    getScheduleDetails(scheduleId);
  }, [scheduleId, clearScheduleDetails, getScheduleDetails]);

  const NavigationControls = ({ steps, activeStep, onGoToStep }) => {
    return steps.map((s) => (
      <div
        className={s === activeStep ? "active" : ""}
        onClick={() => onGoToStep(s.key)}
        key={s.key}
      >
        <div>
          <p>{s.title}</p>
        </div>
      </div>
    ));
  };

  const stepData = [
    {
      key: ScheduleSteps.Summary,
      title: "Summary",
    },
    {
      key: ScheduleSteps.Charges,
      title: "Charges",
    },
    {
      key: ScheduleSteps.Item,
      title: "Item",
    },
    {
      key: ScheduleSteps.CFA,
      title: "CFA",
    },
    {
      key: ScheduleSteps.Services,
      title: "Services",
    },
    {
      key: ScheduleSteps.Paper,
      title: "Paper",
    },
    {
      key: ScheduleSteps.LargeFormat,
      title: "Large Format",
    },
    {
      key: ScheduleSteps.Schedule,
      title: "Schedule",
    },
    {
      key: ScheduleSteps.MeterReadings,
      title: "Meter Readings",
    },
    {
      key: ScheduleSteps.LSU,
      title: "Liability/Settlement",
    },
    {
      key: ScheduleSteps.PriceIncrease,
      title: "Price Increase",
    },
    {
      key: ScheduleSteps.Invoices,
      title: "Invoices",
    },
    {
      key: ScheduleSteps.TermsAndConditions,
      title: "T's & C's",
    },
    {
      key: ScheduleSteps.Communication,
      title: "Communication",
    },
    {
      key: ScheduleSteps.Attachments,
      title: "Attachments",
    },
    {
      key: ScheduleSteps.Notes,
      title: "Notes",
    },
  ];

  return (
    <div className="schedule-viewing">
      <SummaryHeader />
      <Wizard steps={stepData}>
        <div className="schedule-wizard-navigation">
          <Wizard.Nav
            render={(wizardContext) => (
              <NavigationControls {...wizardContext} />
            )}
          />
        </div>
        <Wizard.Step
          step={ScheduleSteps.Summary}
          render={() => <Summary scheduleId={scheduleId} />}
        />
        <Wizard.Step
          step={ScheduleSteps.Charges}
          render={() => <ScheduleCharges scheduleId={scheduleId} />}
        />
        <Wizard.Step step={ScheduleSteps.Item} render={() => <Item />} />
        <Wizard.Step
          step={ScheduleSteps.CFA}
          render={() => <CFA scheduleId={scheduleId} />}
        />
        <Wizard.Step
          step={ScheduleSteps.Services}
          render={() => <ScheduleServices />}
        />
        <Wizard.Step step={ScheduleSteps.Paper} render={() => <Paper />} />
        <Wizard.Step
          step={ScheduleSteps.Schedule}
          render={() => <ProductSchedule />}
        />
        <Wizard.Step
          step={ScheduleSteps.LargeFormat}
          render={() => <LargeFormat />}
        />
        <Wizard.Step
          step={ScheduleSteps.MeterReadings}
          render={() => <MeterReading />}
        />
        <Wizard.Step
          step={ScheduleSteps.LSU}
          render={() => <LiabilitySettlement />}
        />
        <Wizard.Step
          step={ScheduleSteps.PriceIncrease}
          render={() => <PriceIncreases />}
        />
        <Wizard.Step
          step={ScheduleSteps.Invoices}
          render={() => <Invoices />}
        />
        <Wizard.Step
          step={ScheduleSteps.Attachments}
          render={() => <Attachments />}
        />
        <Wizard.Step
          step={ScheduleSteps.TermsAndConditions}
          render={() => <TermsConditions />}
        />
        <Wizard.Step step={ScheduleSteps.Notes} render={() => <Notes />} />
        <Wizard.Step
          step={ScheduleSteps.Communication}
          render={() => <Communication></Communication>}
        />
      </Wizard>
    </div>
  );
};

const actions = {
  getScheduleDetails,
  clearScheduleDetails,
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, actions)(ManageSchedule);
