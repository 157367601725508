import React, { useState } from "react";
import { FunderRoutes } from "constants/routes";
import { Link } from "react-router-dom";
import {
  ActionBar,
  ListBuilder,
  FormBuilder,
  Format,
} from "@redriver/cinnamon";
import { getFunderList } from "./actions";
import { Icon, Table, TableCell } from "semantic-ui-react";
import { ContentPanel } from "components/displays";
import { blankDataFormatter } from "components/displays/DisplayHelper";
import { TablePagination } from "components/pagination";
import { SortHeader } from "features/../../../shared/components/tables/sortableTable";
import FunderCreateModal from "./FunderCreateModal";

const FundersList = () => {
  const [tablesToShow, setTablesToShow] = useState([]);

  return (
    <div className="funder-list">
      <ListBuilder
        debounceFilters={600}
        loadAction={getFunderList}
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        initialFilters={{
          search: "",
        }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        renderList={(data, state, events) => (
          <React.Fragment>
            <ContentPanel
              title="Funders"
              wrapperCssClass="outline-grid funders-list yellow"
              headerCssClass="defaults-page-title yellow"
              headerContent={
                <FilterBar
                  state={state}
                  events={events}
                  initialPageSize={25}
                  onAdded={events.onRefresh}
                ></FilterBar>
              }
            >
              <div className="spaced">
                <FunderTable
                  state={state}
                  events={events}
                  data={data}
                  isLegalEntity={true}
                  tablesToShow={tablesToShow}
                  setTablesToShow={setTablesToShow}
                />
                <br />
                <TablePagination
                  pageNumber={state.pagination.pageNumber}
                  onChange={events.onChangePagination}
                  pageSize={state.pagination.pageSize}
                  totalItems={state.total}
                />
              </div>
            </ContentPanel>
          </React.Fragment>
        )}
      />
    </div>
  );
};

const FunderTable = ({
  data,
  tablesToShow,
  setTablesToShow,
  state,
  events,
}) => {
  function toggleRow(id) {
    if (tablesToShow.indexOf(id) > -1) {
      let index = tablesToShow.indexOf(id);
      if (index !== -1) {
        const arrNew = [...tablesToShow];
        arrNew.splice(index, 1);
        setTablesToShow(arrNew);
      }
    } else {
      setTablesToShow(tablesToShow.concat(id));
    }
  }
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ width: "2%" }}></Table.HeaderCell>
          <Table.HeaderCell>
            <SortHeader
              field="name"
              title="Funder Name"
              value={state.filters}
              onChange={events.onChangeFilters}
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <SortHeader
              field="shortName"
              title="Short Name"
              value={state.filters}
              onChange={events.onChangeFilters}
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <SortHeader
              field="jurisdiction"
              title="Jurisdiction"
              value={state.filters}
              onChange={events.onChangeFilters}
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <SortHeader
              field="address"
              title="Address"
              value={state.filters}
              onChange={events.onChangeFilters}
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <SortHeader
              field="supplierCount"
              title="Suppliers"
              value={state.filters}
              onChange={events.onChangeFilters}
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <SortHeader
              field="startDate"
              title="Start Date"
              value={state.filters}
              onChange={events.onChangeFilters}
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <SortHeader
              field="endDate"
              title="End Date"
              value={state.filters}
              onChange={events.onChangeFilters}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data &&
          data.map((item) => (
            <React.Fragment key={item.id}>
              <Table.Row>
                <Table.Cell onClick={() => toggleRow(item.id)}>
                  {item.suppliers && (
                    <Icon
                      name="dropdown"
                      rotated={
                        !(tablesToShow.indexOf(item.id) > -1)
                          ? "counterclockwise"
                          : null
                      }
                    />
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Link to={FunderRoutes.Funder(item.id)}>{item.name} </Link>
                </Table.Cell>
                <Table.Cell>{item.shortName}</Table.Cell>
                <TableCell>{item.jurisdiction}</TableCell>
                <TableCell>
                  {blankDataFormatter(item.address, "Not provided")}
                </TableCell>
                <TableCell>{item.supplierCount}</TableCell>
                <TableCell>
                  {item.startDate == null ? (
                    "-"
                  ) : (
                    <Format.Date value={item.startDate} format="DD MMM YYYY" />
                  )}
                </TableCell>
                <TableCell>
                  {item.endDate == null ? (
                    "-"
                  ) : (
                    <Format.Date value={item.endDate} format="DD MMM YYYY" />
                  )}
                </TableCell>
              </Table.Row>
              <Table.Row hidden={!tablesToShow.some((x) => x === item.id)}>
                <Table.Cell colSpan={8}>
                  {item.suppliers && (
                    <div className="padded">
                      <h4 className="left-align">Supplier/SPV Association</h4>
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell style={{ width: "12%" }}>
                              Supplier
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ width: "12%" }}>
                              SPV
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ width: "12%" }}>
                              Funder Code
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ width: "12%" }}>
                              Start Date
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ width: "12%" }}>
                              End Date
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ width: "12%" }}>
                              Status
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {item.suppliers.map((item) => (
                            <React.Fragment key={item.id}>
                              <Table.Row>
                                <Table.Cell>{item.name}</Table.Cell>
                                <Table.Cell>{item.spv}</Table.Cell>
                                <Table.Cell>{item.funderCode}</Table.Cell>

                                <TableCell>
                                  {item.startDate == null ? (
                                    "-"
                                  ) : (
                                    <Format.Date
                                      value={item.startDate}
                                      format="DD MMM YYYY"
                                    />
                                  )}
                                </TableCell>
                                <TableCell>
                                  {item.endDate == null ? (
                                    "-"
                                  ) : (
                                    <Format.Date
                                      value={item.endDate}
                                      format="DD MMM YYYY"
                                    />
                                  )}
                                </TableCell>
                                <TableCell>{item.status}</TableCell>
                              </Table.Row>
                            </React.Fragment>
                          ))}
                        </Table.Body>
                      </Table>
                    </div>
                  )}
                </Table.Cell>
              </Table.Row>
            </React.Fragment>
          ))}
      </Table.Body>
    </Table>
  );
};

const FilterBar = ({ events }) => (
  <FormBuilder
    renderForm={() => (
      <ActionBar className="filter-display">
        <ActionBar.Item>
          {/*<TextSearch
            filters={state.filters}
            onChange={events.onChangeFilters}
          ></TextSearch>*/}
        </ActionBar.Item>
        <ActionBar.Item fluid></ActionBar.Item>
        <ActionBar.Item>
          <FunderCreateModal onRefresh={events.onRefresh} />
        </ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          {/* <FilterSidePanel formProps={formProps} />*/}
        </ActionBar.Item>
      </ActionBar>
    )}
  />
);

export default FundersList;
