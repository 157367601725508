import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Grid, GridColumn, GridRow, Button } from "semantic-ui-react";
import { Form, FormBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { useTranslation } from "react-i18next";
import { navigateGridForm } from "features/../../../shared/components/forms/formHelper";
import {
  getSPVBillingInformation,
  updateSPVBillingInformation,
} from "./actions";
import { ContentPanel } from "components/displays";
import { AccountTypeDropdownLookup } from "./lookups";
import { AccountType } from "features/../../../shared/constants/enums";
import { AccountTypeDescription } from "features/../../../shared/constants/enums/AccountType";

const SPVBillingInformation = ({ spvId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [originalData, setOriginalData] = useState();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await dispatch(getSPVBillingInformation(spvId));
      setOriginalData(result);
      setLoading(false);
    })();
  }, [spvId, dispatch]);

  useEffect(() => {
    resetData();
  }, [originalData, resetData]);

  const resetData = useCallback(() => {
    setFormData(originalData);
  }, [originalData]);

  const updateOriginalData = (formData) => {
    setOriginalData(formData);
  };

  return (
    <div className="spv-billing-information">
      <FormBuilder
        value={formData}
        onChange={(fd) => {
          setFormData(fd);
        }}
        initialReadOnly
        loading={loading}
        submitAction={updateSPVBillingInformation}
        submitParams={spvId}
        onSubmitted={() => updateOriginalData(formData)}
        renderForm={(formProps, state, events) => {
          if (!formProps.value) return null;
          return (
            <Form
              {...formProps}
              onKeyDown={(e) => {
                navigateGridForm(e, 2, 1, false);
              }}
            >
              <ContentPanel
                title="Billing Information"
                wrapperCssClass="outline-grid violet"
                headerCssClass="defaults-page-title violet"
                headerContent={
                  <PermissionCheck target={Targets.SPVs} action={Actions.Edit}>
                    <div className="flex">
                      <IconButton
                        primary
                        icon={state.readOnly ? Pen : Save}
                        content={
                          state.readOnly
                            ? t("shared:buttons:edit")
                            : t("shared:buttons:save")
                        }
                        floated="right"
                        onClick={() =>
                          state.readOnly
                            ? events.onToggleReadOnly()
                            : events.onSubmit({ reset: ["readOnly"] })
                        }
                        className={
                          !state.readOnly ? "positive-title-action" : null
                        }
                      />
                      {!state.readOnly && (
                        <Button
                          className="cancel-title-action"
                          floated="right"
                          content={t("shared:buttons:cancel")}
                          onClick={() => {
                            resetData();
                            events.onReset();
                          }}
                        />
                      )}
                    </div>
                  </PermissionCheck>
                }
              >
                <Grid className="spv-summary">
                  <Form.Array field="billingInformation" arrayKey="id">
                    {({ fields }) => (
                      <GridColumn
                        mobile={16}
                        tablet={16}
                        computer={10}
                        largeScreen={7}
                        widescreen={7}
                        floated="left"
                      >
                        <Grid>
                          <GridRow>
                            <GridColumn
                              mobile={7}
                              tablet={6}
                              computer={7}
                              largeScreen={7}
                              widescreen={5}
                            >
                              IPS Account Type:
                            </GridColumn>
                            <GridColumn
                              mobile={9}
                              tablet={10}
                              computer={8}
                              largeScreen={9}
                              widescreen={10}
                            >
                              {" "}
                              {state.readOnly ? (
                                <span>
                                  {
                                    AccountTypeDescription[
                                      fields.ipsAccountType
                                    ]
                                  }
                                </span>
                              ) : (
                                <AccountTypeDropdownLookup field="ipsAccountType" />
                              )}
                            </GridColumn>
                          </GridRow>
                          <GridRow>
                            <GridColumn
                              mobile={7}
                              tablet={6}
                              computer={7}
                              largeScreen={7}
                              widescreen={5}
                            >
                              IPS Company Id:
                            </GridColumn>
                            <GridColumn
                              mobile={9}
                              tablet={10}
                              computer={8}
                              largeScreen={9}
                              widescreen={10}
                            >
                              {" "}
                              <Form.Input field="ipsCompanyId" />
                            </GridColumn>
                          </GridRow>
                          <GridRow>
                            <GridColumn
                              mobile={7}
                              tablet={6}
                              computer={7}
                              largeScreen={7}
                              widescreen={5}
                            >
                              Company Name:
                            </GridColumn>
                            <GridColumn
                              mobile={9}
                              tablet={10}
                              computer={8}
                              largeScreen={9}
                              widescreen={10}
                            >
                              {" "}
                              <Form.Input field="companyName" />
                            </GridColumn>
                          </GridRow>
                        </Grid>
                        <Form.Object field="address">
                          <Grid>
                            <GridRow>
                              <GridColumn
                                mobile={7}
                                tablet={6}
                                computer={7}
                                largeScreen={7}
                                widescreen={5}
                              >
                                Address Line 1:
                              </GridColumn>
                              <GridColumn
                                mobile={9}
                                tablet={10}
                                computer={8}
                                largeScreen={9}
                                widescreen={10}
                              >
                                {" "}
                                <Form.Input field="line1" />
                              </GridColumn>
                            </GridRow>
                            <GridRow>
                              <GridColumn
                                mobile={7}
                                tablet={6}
                                computer={7}
                                largeScreen={7}
                                widescreen={5}
                              >
                                Address Line 2:
                              </GridColumn>
                              <GridColumn
                                mobile={9}
                                tablet={10}
                                computer={8}
                                largeScreen={9}
                                widescreen={10}
                              >
                                {" "}
                                <Form.Input field="line2" />
                              </GridColumn>
                            </GridRow>
                            <GridRow>
                              <GridColumn
                                mobile={7}
                                tablet={6}
                                computer={7}
                                largeScreen={7}
                                widescreen={5}
                              >
                                Address Line 3:
                              </GridColumn>
                              <GridColumn
                                mobile={9}
                                tablet={10}
                                computer={8}
                                largeScreen={9}
                                widescreen={10}
                              >
                                {" "}
                                <Form.Input field="line3" />
                              </GridColumn>
                            </GridRow>
                            <GridRow>
                              <GridColumn
                                mobile={7}
                                tablet={6}
                                computer={7}
                                largeScreen={7}
                                widescreen={5}
                              >
                                Town/City:
                              </GridColumn>
                              <GridColumn
                                mobile={9}
                                tablet={10}
                                computer={8}
                                largeScreen={9}
                                widescreen={10}
                              >
                                {" "}
                                <Form.Input field="town" />
                              </GridColumn>
                            </GridRow>
                            <GridRow>
                              <GridColumn
                                mobile={7}
                                tablet={6}
                                computer={7}
                                largeScreen={7}
                                widescreen={5}
                              >
                                County:
                              </GridColumn>
                              <GridColumn
                                mobile={9}
                                tablet={10}
                                computer={8}
                                largeScreen={9}
                                widescreen={10}
                              >
                                {" "}
                                <Form.Input field="county" />
                              </GridColumn>
                            </GridRow>
                            <GridRow>
                              <GridColumn
                                mobile={7}
                                tablet={6}
                                computer={7}
                                largeScreen={7}
                                widescreen={5}
                              >
                                Post Code:
                              </GridColumn>
                              <GridColumn
                                mobile={9}
                                tablet={10}
                                computer={8}
                                largeScreen={9}
                                widescreen={10}
                              >
                                {" "}
                                <Form.Input field="postcode" />
                              </GridColumn>
                            </GridRow>
                            <GridRow>
                              <GridColumn
                                mobile={7}
                                tablet={6}
                                computer={7}
                                largeScreen={7}
                                widescreen={5}
                              >
                                Country:
                              </GridColumn>
                              <GridColumn
                                mobile={9}
                                tablet={10}
                                computer={8}
                                largeScreen={9}
                                widescreen={10}
                              >
                                {" "}
                                <Form.Input field="country" />
                              </GridColumn>
                            </GridRow>
                          </Grid>
                        </Form.Object>
                        <Grid>
                          <GridRow>
                            <GridColumn
                              mobile={7}
                              tablet={6}
                              computer={7}
                              largeScreen={7}
                              widescreen={5}
                            >
                              {AccountTypeDescription[fields.ipsAccountType] ===
                              AccountType.SalesLedger
                                ? "Send Sales Invoices via:"
                                : "Send Self Bill Invoices via:"}
                            </GridColumn>
                            <GridColumn
                              mobile={9}
                              tablet={10}
                              computer={8}
                              largeScreen={9}
                              widescreen={10}
                            >
                              {" "}
                              <Form.Input field="sendSalesInvoicesVia" />
                            </GridColumn>
                          </GridRow>
                          <GridRow>
                            <GridColumn
                              mobile={7}
                              tablet={6}
                              computer={7}
                              largeScreen={7}
                              widescreen={5}
                            >
                              Email Address:
                            </GridColumn>
                            <GridColumn
                              mobile={9}
                              tablet={10}
                              computer={8}
                              largeScreen={9}
                              widescreen={10}
                            >
                              {" "}
                              <Form.Input field="emailAddress" />
                            </GridColumn>
                          </GridRow>
                          <GridRow>
                            <GridColumn
                              mobile={7}
                              tablet={6}
                              computer={7}
                              largeScreen={7}
                              widescreen={5}
                            >
                              CC:
                            </GridColumn>
                            <GridColumn
                              mobile={9}
                              tablet={10}
                              computer={8}
                              largeScreen={9}
                              widescreen={10}
                            >
                              {" "}
                              <Form.Input field="ccEmailAddress" />
                            </GridColumn>
                          </GridRow>
                        </Grid>
                      </GridColumn>
                    )}
                  </Form.Array>
                </Grid>
              </ContentPanel>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default SPVBillingInformation;
