import {
  apiGet,
  apiPost,
  apiPut,
  apiRequest,
  mockSuccess,
} from "@redriver/cinnamon";

const NAMESPACE = "FUNDERS";

const ActionTypes = {
  getFunderDetails: `${NAMESPACE}/GET_FUNDER`,
  getFunderList: `${NAMESPACE}/GET_FUNDERS`,
  getFunderSummaryDetails: `${NAMESPACE}/GET_FUNDER_SUMMARY_DETAILS`,
  getFunderContactDetails: `${NAMESPACE}/GET_FUNDER_CONTACT_DETAILS`,
  getFunderCFADetails: `${NAMESPACE}/GET_FUNDER_CFA_DETAILS`,
  getFunderDocuments: `${NAMESPACE}/GET_FUNDER_DOCUMENTS`,
  uploadDocument: `${NAMESPACE}/UPLOAD_DOCUMENT`,
  createFunder: `${NAMESPACE}/CREATE_FUNDER`,
  updateFunderSummaryDetails: `${NAMESPACE}/UPDATE_FUNDER_SUMMARY_DETAILS`,
};

export const getFunderDetails = (funderId) => {
  return apiGet(ActionTypes.getFunderDetails, `funders/${funderId}/`);
};

export const getFunderList = ({ pagination }) => {
  return apiGet(ActionTypes.getFunderList, `funders/list/`, { ...pagination });
};

export const getFunderSummaryDetails = (funderId) => {
  return apiGet(
    ActionTypes.getFunderSummaryDetails,
    `funders/${funderId}/summary`
  );
};

export const UpdateFunderSummaryDetails = (data, funderId) => {
  return apiPut(
    ActionTypes.updateFunderSummaryDetails,
    `funders/${funderId}/summary`,
    data
  );
};

export const createFunder = (formData) => {
  return apiPost(ActionTypes.createFunder, `funders/`, formData);
};

const funderContacts = [
  {
    id: 1,
    firstName: "Andrew",
    lastName: "Madden",
    status: "Active",
    position: "Finance Director",
    relationship: "Decision Maker",
    telephone: "+44 (0)1483 651852",
    email: "amadden@BNP.co.uk",
    location: "123, Made Up Street, Neverland, Surrey, KT13 3PL",
    timeZone: "GMT",
  },
  {
    id: 2,
    firstName: "Jane",
    lastName: "Sloan",
    status: "Active",
    position: "Secretary",
    relationship: "Main Liaison",
    telephone: "+44 (0)7896 548562",
    email: "jsloan@BNP.co.uk",
    location: "123, Made Up Street, Neverland, Surrey, KT13 3PL",
    timeZone: "GMT",
  },
  {
    id: 3,
    firstName: "James",
    lastName: "Smith",
    status: "Active",
    position: "Site Manager",
    relationship: "Technical",
    telephone: "+44 (0)1896 482698",
    email: "jsmith@BNP.co.uk",
    location: "123, Made Up Street, Neverland, Surrey, KT13 3PL",
    timeZone: "GMT",
  },
  {
    id: 4,
    firstName: "Joe",
    lastName: "Bloggs",
    status: "Active",
    position: "Software Developer",
    relationship: "Technical",
    telephone: "+44 (0)7090 381248",
    email: "jbloggs@BNP.co.uk",
    location: "123, Made Up Street, Neverland, Surrey, KT13 3PL",
    timeZone: "GMT",
  },
  {
    id: 5,
    firstName: "Charlotte",
    lastName: "Worthington",
    status: "Active",
    position: "HR Manager",
    relationship: "Staffing",
    telephone: "+44 (0)1896 482698",
    email: "cworthington@BNP.co.uk",
    location: "123, Made Up Street, Neverland, Surrey, KT13 3PL",
    timeZone: "GMT",
  },
];

const funderCFADetails = {
  totalResults: 25,
  results: [
    {
      id: 1,
      ref: "A1C80655",
      status: "Live",
      associatedSupplier: "Principal UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "09/05/2019",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 3727.61,
      term: "Monthly",
      periodsRemaining: 12,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Abacus Employment Services Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 2,
      ref: "A1B57569",
      status: "Live",
      associatedSupplier: "Principal UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "12/05/2020",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 435.94,
      term: "Monthly",
      periodsRemaining: 15,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Abacus Employment Services Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 3,
      ref: "A1C91140",
      status: "Live",
      associatedSupplier: "Principal UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "11/21/2016",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 5160.85,
      term: "Monthly",
      periodsRemaining: 16,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Adam Architecture Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 4,
      ref: "A1C23805",
      status: "Live",
      associatedSupplier: "Principal UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "08/21/2017",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 12031.61,
      term: "Quarterly",
      periodsRemaining: 17,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Adam Architecture Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 5,
      ref: "Y0046540",
      status: "Continuing Payments",
      associatedSupplier: "Principal UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "09/19/2016",
      endDate: "09/19/2020",
      currency: process.env.CURRENCY_CODE,
      payment: 636.12,
      term: "Quarterly",
      periodsRemaining: 0,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Adams & Remers Llp",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 6,
      ref: "Z0032638",
      status: "Continuing Payments",
      associatedSupplier: "Principal UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "09/19/2018",
      endDate: "09/19/2020",
      currency: process.env.CURRENCY_CODE,
      payment: 149.52,
      term: "Quarterly",
      periodsRemaining: 0,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Adams & Remers Llp",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 7,
      ref: "A1B32831",
      status: "Live",
      associatedSupplier: "Konica Minolta UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "03/19/2019",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      customer: "Adams & Remers Llp",
      payment: 1788.02,
      term: "Quarterly",
      periodsRemaining: 3,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Adams & Remers Llp",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 8,
      ref: "A1b12940",
      status: "Live",
      associatedSupplier: "Konica Minolta UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "07/03/2019",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 110.9,
      term: "Quarterly",
      periodsRemaining: 7,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Africa Collection UK Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 9,
      ref: "A1C39669",
      status: "Live",
      associatedSupplier: "Konica Minolta UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "05/07/2019",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 2943.56,
      term: "Quarterly",
      periodsRemaining: 4,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Aksward Collection UK Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Stp - Billing",
    },
    {
      id: 10,
      ref: "Y0002621",
      status: "Live",
      associatedSupplier: "Konica Minolta UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "08/23/2018",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 1655.36,
      term: "Monthly",
      periodsRemaining: 12,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "DTP Hospitality UK Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 11,
      ref: "A1A64944",
      status: "Live",
      associatedSupplier: "Konica Minolta UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "11/23/2019",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 460.13,
      term: "Monthly",
      periodsRemaining: 9,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "DTP Hospitality UK Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 12,
      ref: "Z00027276",
      status: "Dead",
      associatedSupplier: "Principal UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "09/19/2018",
      endDate: "09/19/2020",
      currency: process.env.CURRENCY_CODE,
      payment: 358.97,
      term: "Monthly",
      periodsRemaining: 0,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "E J Coombs Solicitors Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 13,
      ref: "Z0073501",
      status: "Dead",
      associatedSupplier: "Principal UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "10/20/2016",
      endDate: "10/20/2019",
      currency: process.env.CURRENCY_CODE,
      payment: 146.57,
      term: "Quarterly",
      periodsRemaining: 0,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Expresspace Limited",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 14,
      ref: "A1A43534",
      status: "Continuing Payments",
      associatedSupplier: "Principal UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "12/10/2014",
      endDate: "12/10/2019",
      currency: process.env.CURRENCY_CODE,
      payment: 358.84,
      term: "Quarterly",
      periodsRemaining: 0,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Francesco Group (Holdings) Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 15,
      ref: "A1C65954",
      status: "Live",
      associatedSupplier: "Principal UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "04/09/2016",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 518.04,
      term: "Quarterly",
      periodsRemaining: 7,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Francesco Group (Holdings) Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Pending TMV Reached",
    },
    {
      id: 16,
      ref: "A1A18668",
      status: "Dead",
      associatedSupplier: "Konica Minolta UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "05/25/2018",
      endDate: "02/25/2020",
      currency: process.env.CURRENCY_CODE,
      payment: 616.38,
      term: "Monthly",
      periodsRemaining: 0,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Ings Engineering Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 17,
      ref: "A1C80655",
      status: "Live",
      associatedSupplier: "Konica Minolta UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "09/05/2019",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 3727.61,
      term: "Quarterly",
      periodsRemaining: 12,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Abacus Employment Services Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 18,
      ref: "A1B57569",
      status: "Live",
      associatedSupplier: "Konica Minolta UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "12/05/2020",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 435.94,
      term: "Quarterly",
      periodsRemaining: 15,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Abacus Employment Services Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 19,
      ref: "A1c91140",
      status: "Live",
      associatedSupplier: "Principal UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "11/21/2016",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 5160.85,
      term: "Quarterly",
      periodsRemaining: 16,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Adam Architecture Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 20,
      ref: "A1c23801",
      status: "Live",
      associatedSupplier: "Principal UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "08/21/2017",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 12031.61,
      term: "Quarterly",
      periodsRemaining: 17,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Adam Architecture Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 21,
      ref: "Y0046540",
      status: "Continuing Payments",
      associatedSupplier: "Principal UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "09/19/2016",
      endDate: "09/19/2020",
      currency: process.env.CURRENCY_CODE,
      payment: 636.12,
      term: "Monthly",
      periodsRemaining: 0,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Adams & Remers Llp",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Customer Settled",
    },
    {
      id: 22,
      ref: "Z0032636",
      status: "Continuing Payments",
      associatedSupplier: "Principal UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "09/19/2018",
      endDate: "09/19/2020",
      currency: process.env.CURRENCY_CODE,
      payment: 149.52,
      term: "Monthly",
      periodsRemaining: 0,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Adams & Remers Llp",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 23,
      ref: "A1B32831",
      status: "Live",
      associatedSupplier: "Konica Minolta UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "05/19/2019",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      customer: "Adams & Remers Llp",
      payment: 1788.02,
      term: "Monthly",
      periodsRemaining: 3,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Adams & Remers Llp",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Stop - Billing",
    },
    {
      id: 24,
      ref: "A1B12940",
      status: "Live",
      associatedSupplier: "Konica Minolta UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "07/03/2019",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 110.9,
      term: "Monthly",
      periodsRemaining: 7,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Africa Collection UK Ltd",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
    {
      id: 25,
      ref: "A1C39669",
      status: "Live",
      associatedSupplier: "Konica Minolta UK Limited",
      associatedSpv: "IPS SPV I LTD",
      startDate: "05/07/2019",
      endDate: "",
      currency: process.env.CURRENCY_CODE,
      payment: 2943.56,
      term: "Monthly",
      periodsRemaining: 4,
      initialReference: "KM1103-MA1731-SC1422",
      initialCustomer: "Aksward Limited",
      currentReference: "KM1103-MA1731-SC1422",
      currentStatus: "Live",
    },
  ],
};

export const getFunderContacts = () => {
  return mockSuccess(ActionTypes.getFunderContactDetails, {
    delay: 100,
    response: funderContacts,
  });
};

export const getFunderCFADetails = () => {
  return mockSuccess(ActionTypes.getFunderContactDetails, {
    delay: 100,
    response: funderCFADetails,
  });
};

export const getFunderDocuments = ({ filters, pagination }, funderId) => {
  let data = {
    ...filters,
    ...pagination,
  };
  return apiGet(
    ActionTypes.getFunderDocuments,
    `funders/${funderId}/documents`,
    data
  );
};

export const uploadFunderDocuments = (formData, funderId) => {
  const data = new FormData();

  data.append("file", formData.file);
  data.append("documentNumber", formData.documentNumber);
  data.append("supplierId", formData.supplierId);

  //optional fields
  if (formData.type) data.append("type", formData.type);
  if (formData.description) data.append("description", formData.description);

  return apiRequest({
    type: ActionTypes.uploadDocument,
    method: "POST",
    path: `funders/${funderId}/upload-documents`,
    contentType: null,
    body: data,
  });
};
