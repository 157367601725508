import { apiGet, apiPost, apiPut, apiDelete } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/ROLL_WIDTH";

const ActionTypes = {
  AddRollWidth: `${NAMESPACE}/ADD_ROLL_WIDTH`,
  GetRollWidth: `${NAMESPACE}/GET_ROLL_WIDTH`,
  UpdateRollWidth: `${NAMESPACE}/UPDATE_ROLL_WIDTH`,
  DeleteRollWidth: `${NAMESPACE}/DELETE_ROLL_WIDTH`,
};

export const addRollWidth = (data, { dealerOfficeId }) => {
  let obj = {
    ...data,
    dealerOfficeId: dealerOfficeId.dealerOfficeId,
  };
  return apiPost(ActionTypes.AddRollWidth, `roll-width`, obj);
};

export const getRollWidth = ({ pagination }, { dealerOfficeId }) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetRollWidth, "roll-width", {
    pageNumber,
    pageSize,
    dealerOfficeId,
  });
};

export const editRollWidth = (data, { id, dealerOfficeId }) => {
  return apiPut(ActionTypes.UpdateRollWidth, `roll-width/${id}`, {
    ...data,
    dealerOfficeId: dealerOfficeId.dealerOfficeId,
  });
};
export const deleteRollWidth = rollWidthId =>
  apiDelete(ActionTypes.DeleteRollWidth, `roll-width/${rollWidthId}`);
