import React, { useEffect, useState } from "react";
import { ContentPanel } from "components/displays";
import {
  ListBuilder,
  ActionBar,
  FormBuilder,
  usePreferences,
  Form,
} from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import {
  ConfigurableTable,
  TableConfigurator,
} from "features/CustomConfig/TableConfig";
import { Image } from "semantic-ui-react";
import Search from "features/../../../shared/assets/purple-search.svg";
import { getMinimumBilling } from "../actions";
import { AddModalWrapper } from "components/modals";
import {
  numericFormatter,
  dateFormatter,
  blankDataFormatter,
} from "components/displays/DisplayHelper";
import { FilterGrid } from ".";

const MinimumBilling = () => {
  const { preferences } = usePreferences();
  const [config, setConfig] = useState(preferences.minimumBillingTableConfig);
  const [pageSizeConfig, setPageSizeConfig] = useState(
    preferences.minimumBillingTableConfigPageSize
      ? preferences.minimumBillingTableConfigPageSize
      : 25
  );

  const [selectedItems, setSelected] = useState({});

  useEffect(() => {
    setConfig(preferences.minimumBillingTableConfig);
  }, [preferences.minimumBillingTableConfig]);

  useEffect(() => {
    preferences.minimumBillingTableConfigPageSize &&
      setPageSizeConfig(preferences.minimumBillingTableConfigPageSize);
  }, [preferences.minimumBillingTableConfigPageSize]);

  const columns = [
    {
      field: "id",
      title: "Selectable Column",
      selectableColumn: true,
      sortable: false,
    },
    { field: "customer", title: "Customer", width: "20em" },
    { field: "customerIdentifier", title: "Customer ID", width: "10em" },
    { field: "scheduleNumber", title: "Schedule Number" },
    { field: "supplier", title: "Supplier" },
    { field: "supplierOffice", title: "Supplier Office" },
    { field: "spv", title: "SPV" },
    { field: "scheduleStatus", title: "Schedule Status" },
    { field: "currentTmvRemaining", title: "Current TMV Remaining" },
    { field: "managedUsage", title: "Managed Usage" },
    {
      field: "taxDate",
      title: "Tax Date",
      render: (item) => dateFormatter(item.taxDate),
    },
    { field: "invoiceSubtype", title: "Invoice Subtype" },
    { field: "invoicingPeriod", title: "Invoice Period" },
    {
      field: "monthInQtr",
      title: "Month in Quarter",
      render: (item) => blankDataFormatter(item.monthInQtr, "N/A"),
    },
    {
      field: "minimumDueDate",
      title: "Minimum Due Date",
      render: (item) => dateFormatter(item.minimumDueDate),
    },
    { field: "currency", title: "Currency" },
    {
      field: "netValue",
      title: "Net Value (£)",
      render: (item) => numericFormatter(item.netValue, 0, 2),
    },
  ];

  return (
    <ListBuilder
      withTable
      initialPagination={{ pageSize: pageSizeConfig, pageNumber: 1 }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      loadAction={getMinimumBilling}
      loadParams={{ pageSize: pageSizeConfig }}
      renderList={(tableProps, state, events) => (
        <ContentPanel
          title=""
          wrapperCssClass="outline-grid customer-billing-list minimum-billing"
          headerCssClass="defaults-page-title"
          headerContent={
            <FilterBar
              state={state}
              events={events}
              config={config}
              columns={columns}
              initialPageSize={25}
              pageSizeConfig={pageSizeConfig}
            ></FilterBar>
          }
        >
          <div className="spaced">
            <div className="vertical-scrollbar large">
              <ConfigurableTable
                state={state}
                events={events}
                tableProps={tableProps}
                emptyMessage="No invoices found"
                columns={columns}
                config={config}
                selectedItems={selectedItems}
                setSelected={setSelected}
                dataKey="id"
              />
            </div>
            <div className="padded">
              <TablePagination
                pageSize={pageSizeConfig}
                pageNumber={state.pagination.pageNumber}
                onChange={events.onChangePagination}
                totalItems={state.total}
              />
            </div>
            <div className="footer">
              <AddModalWrapper title="Create Selected Invoice/s">
                Not Implemented
              </AddModalWrapper>
            </div>
          </div>
        </ContentPanel>
      )}
    />
  );
};

const FilterBar = ({
  state,
  events,
  config,
  columns,
  initialPageSize,
  pageSizeConfig,
}) => (
  <FormBuilder
    value={state.filters}
    onChange={events.onChangeFilters}
    renderForm={(formProps) => (
      <Form {...formProps}>
        <ActionBar className="filter-display">
          <ActionBar.Item>
            <h4 className="finance-title">Minimum Billing</h4>
            <div className="sml-screen">
              <TableConfigurator
                config={config}
                columns={columns}
                configId="minimumBillingTableConfig"
                titlePreFix="Minimum Billing"
                initialPageSize={initialPageSize}
                pageSizeConfig={pageSizeConfig}
              />
            </div>
            <div className="text-search-wrapper">
              <Form.Input
                field="search"
                placeholder="Search"
                icon={<Image src={Search} />}
                className="search-input highlighted-input"
              />
            </div>
            <Form.DateRange
              field="dateRange"
              className="highlighted-input"
              local
            />
          </ActionBar.Item>
          <ActionBar.Item fluid></ActionBar.Item>
          <ActionBar.Item className="right-header-col">
            <FilterGrid
              config={config}
              columns={columns}
              configId="minimumBillingTableConfig"
              titlePreFix="Minimum Billing"
              initialPageSize={initialPageSize}
              pageSizeConfig={pageSizeConfig}
            />
          </ActionBar.Item>
        </ActionBar>
      </Form>
    )}
  />
);

export default MinimumBilling;
