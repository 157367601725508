import React, { useEffect, useState } from "react";
import { ContentPanel, AccordionContentPanel } from "components/displays";

import { Grid, GridColumn, GridRow, Tab, Table } from "semantic-ui-react";
import {
  getLsSummary,
  getOSB,
  QuarterlyServices,
  MeteredServices,
  Finances,
  ContractSettlement,
} from ".";
import { ContentBuilder, ActionMenu, Format } from "@redriver/cinnamon";
import {
  yesNoFormatter,
  numericFormatter,
  currencyFormatter,
} from "components/displays/DisplayHelper";

import { AddButton } from "../../../../../../shared/components/buttons";
import { SimpleTableBuilder } from "../../../../../../shared/components/tables/sortableTable";

const panes = [
  {
    menuItem: "Summary",
    render: () => (
      <Tab.Pane>
        <Summary></Summary>
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Contract Settlement Discount % Calculation",
    render: () => (
      <Tab.Pane>
        <ContractSettlement />
      </Tab.Pane>
    ),
  },
];

const LiabilitySettlement = () => {
  const handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);

  const [expand, setExpand] = useState(false);
  const [isSummary, setIsSummary] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);

  //use effect to open all modals if closed.
  useEffect(() => {
    if (expand) {
      setExpand(false);
    }
  }, [expand]);

  //effect to set isSummary value when tab is changed.
  useEffect(() => {
    setIsSummary(activeIndex == 0);
  }, [activeIndex]);

  return (
    <div className={isSummary ? "ls-summary" : "ls-contract-settlement"}>
      <ContentPanel
        title="Liability & Settlement"
        wrapperCssClass="container liability-container"
        headerCssClass="defaults-page-title"
      >
        <div className="padded">
          <ActionMenu>
            <ActionMenu.Item
              text="Raise Outstanding Schedule Value"
              onClick={() => alert("First")}
            />
            <ActionMenu.Item text="Raise Settlement Quote" />
            <ActionMenu.Item text="Raise Settlement Invoice" />
            <ActionMenu.Item text="Custom Settlement Generation" />
          </ActionMenu>

          <Tab
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={handleTabChange}
          />
          <br />
          <AddButton
            primary
            content="Expand All"
            onClick={() => setExpand(true)}
          />
          <br />
          <br />
          <AccordionContentPanel
            title="Finance"
            hideTitle={false}
            wrapperCssClass="outline-grid"
            headerCssClass="defaults-page-title"
            expand={expand}
            headerContent={
              !isSummary && <CpiTable title="Finance" value={7.461} />
            }
          >
            <div className="spaced">
              <Finances isSummary={isSummary} />
            </div>
          </AccordionContentPanel>
          <br />
          <AccordionContentPanel
            title="Metered Services"
            hideTitle={false}
            wrapperCssClass="outline-grid"
            headerCssClass="defaults-page-title"
            expand={expand}
          >
            <div className="spaced">
              <MeteredServices isSummary={isSummary} />
            </div>
          </AccordionContentPanel>
          <br />
          <AccordionContentPanel
            title="Quarterly Services"
            hideTitle={false}
            wrapperCssClass="outline-grid"
            headerCssClass="defaults-page-title"
            expand={expand}
            headerContent={
              !isSummary && <CpiTable title="Service" value={0.333} />
            }
          >
            <div className="spaced">
              <QuarterlyServices isSummary={isSummary} />
            </div>
          </AccordionContentPanel>
        </div>
      </ContentPanel>
    </div>
  );
};

const Summary = () => {
  return (
    <Grid columns={2} doubling>
      <GridRow>
        <GridColumn>
          <ContentBuilder
            loadAction={getLsSummary}
            dataTransform={(data) => data._data}
            renderContent={(data) => (
              <React.Fragment>
                <Table className="details">
                  <Table.Body>
                    <Table.Row className="strong">
                      <Table.Cell>Applicable From Date:</Table.Cell>
                      <Table.Cell>
                        <Format.Date
                          value={data.applicableFromDt}
                          format="DD MMM YYYY"
                        />
                      </Table.Cell>
                      <Table.Cell className="no-border"></Table.Cell>
                      <Table.Cell className="no-border"></Table.Cell>
                    </Table.Row>
                    <EmptyRow />
                    <DetailsTableRow
                      data={{
                        title: "Schedule Total Managed Volume",
                        value: numericFormatter(data.scheduleTotalVol, 0, 0),
                      }}
                    ></DetailsTableRow>
                    <DetailsTableRow
                      data={{
                        title: "Total Managed Volume Used",
                        value: numericFormatter(
                          data.scheduleTotalVolUser,
                          0,
                          0
                        ),
                      }}
                    ></DetailsTableRow>
                    <Table.Row className="strong">
                      <Table.Cell colSpan={2} className="left-align">
                        Total Managed Volume Remaining
                      </Table.Cell>
                      <Table.Cell>
                        {numericFormatter(data.totalVolRemaining, 0, 0)}
                      </Table.Cell>
                      <Table.Cell className="no-border left">a</Table.Cell>
                    </Table.Row>
                    <EmptyRow />
                    <DetailsTableRow
                      data={{
                        title: "Quarterly Managed Volume",
                        value: numericFormatter(data.quarterlyManagedVol, 0, 0),
                        value2: "b",
                      }}
                    ></DetailsTableRow>
                    <DetailsTableRow
                      data={{
                        title: "Quarterly Minimum Payment",
                        value: currencyFormatter(data.quarterlyMinimumPayment),
                        value2: "c",
                      }}
                    ></DetailsTableRow>
                    <EmptyRow />
                    <Table.Row className="strong">
                      <Table.Cell colSpan={2} className="left-align">
                        Outstanding Schedule Value
                      </Table.Cell>
                      <Table.Cell>
                        {currencyFormatter(data.outstandingScheduleValue)}
                      </Table.Cell>
                      <Table.Cell className="no-border left">
                        = a / b * c
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row className="no-border">
                      <Table.Cell colSpan={3} className="right">
                        <a href={void 0}>Breakdown</a>
                      </Table.Cell>
                      <Table.Cell
                        colSpan={1}
                        className="no-border"
                      ></Table.Cell>
                    </Table.Row>
                    <DetailsTableRow
                      data={{
                        title: "Apply Schedule Discount",
                        value: yesNoFormatter(data.applyDiscount),
                        middleRowClass: "center",
                      }}
                    ></DetailsTableRow>
                    <DetailsTableRow
                      data={{
                        title: "Schedule Discount",
                        value: numericFormatter(data.discountAmount),
                        symbol: "%",
                      }}
                    ></DetailsTableRow>
                    <DetailsTableRow
                      data={{
                        title: "Schedule Discount Value",
                        value: (
                          <Format.Number
                            value={data.discountValue}
                            format={process.env.CURRENCY_CODE}
                            prefix={`(${process.env.MAJOR_CURRENCY}`}
                            suffix=")"
                          />
                        ),
                      }}
                    ></DetailsTableRow>
                    <EmptyRow />
                    <DetailsTableRow
                      data={{
                        title: "Include Early Termination Fee",
                        value: yesNoFormatter(data.incEarlyTermFee),
                        middleRowClass: "center",
                      }}
                    ></DetailsTableRow>
                    <DetailsTableRow
                      data={{
                        title: "Early Termination Fee",
                        value: currencyFormatter(data.earlyTermFee),
                      }}
                    ></DetailsTableRow>
                    <Table.Row className="no-border">
                      <Table.Cell colSpan={3} className="left">
                        <a href={void 0}>+ Additional Termination Fee</a>
                      </Table.Cell>
                      <Table.Cell
                        colSpan={1}
                        className="no-border"
                      ></Table.Cell>
                    </Table.Row>
                    <Table.Row className="strong">
                      <Table.Cell colSpan={2} className="left-align">
                        Settlement Payable ({process.env.MAJOR_CURRENCY})
                      </Table.Cell>
                      <Table.Cell>
                        {currencyFormatter(data.settlementPayment)}
                      </Table.Cell>
                      <Table.Cell className="no-border"></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </React.Fragment>
            )}
          ></ContentBuilder>
        </GridColumn>
        <GridColumn>
          <ContentPanel
            title="Outstanding Schedule Breakdown"
            wrapperCssClass="container outline-grid"
            headerCssClass="defaults-page-title"
          >
            <div className="padded">
              <SimpleTableBuilder
                emptyMessage="No breakdowns found"
                loadAction={getOSB}
                columns={outstandingScheduleColumns}
                className="osb"
              />
            </div>
          </ContentPanel>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

const EmptyRow = () => (
  <Table.Row className="no-border">
    <Table.Cell colSpan={3}></Table.Cell>
    <Table.Cell colSpan={1} className="no-border"></Table.Cell>
  </Table.Row>
);

const DetailsTableRow = ({ data }) => (
  <Table.Row>
    <Table.HeaderCell colSpan={2}>{data.title}</Table.HeaderCell>
    <Table.Cell className={data.middleRowClass}>
      {data.value}
      {data.symbol}
    </Table.Cell>
    <Table.Cell className="no-border left">{data.value2}</Table.Cell>
  </Table.Row>
);

const CpiTable = ({ title, value }) => {
  return (
    <Table className="header-tbl">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ width: "150px" }}>
            {title} CPI (p)
          </Table.HeaderCell>
          <Table.HeaderCell style={{ width: "150px" }}>
            {value}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    </Table>
  );
};

const outstandingScheduleColumns = [
  { field: "dealer", title: "", width: "16%", sortable: false },
  {
    field: "finance",
    render: (item) => numericFormatter(item.finance, "-"),
    title: `Finance (${process.env.MAJOR_CURRENCY})`,
    width: "16%",
  },
  {
    field: "meterService",
    render: (item) => numericFormatter(item.meterService, "-"),
    title: `Meter Services (${process.env.MAJOR_CURRENCY})`,
    width: "17%",
  },
  {
    field: "quarterlyService",
    render: (item) => numericFormatter(item.quarterlyService, "-"),
    title: `Quarterly Services (${process.env.MAJOR_CURRENCY})`,
    width: "17%",
  },
  {
    field: "rounding",
    render: (item) => numericFormatter(item.rounding, "-"),
    title: `Rounding (${process.env.MAJOR_CURRENCY})`,
    width: "17%",
  },
  {
    field: "total",
    render: (item) => numericFormatter(item.total, "-"),
    title: `Totals (${process.env.MAJOR_CURRENCY})`,
    width: "17%",
  },
];

export default LiabilitySettlement;
