import { AppRoutes, ProductRoutes } from "constants/routes";
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ProductPage from "./ProductPage";
import ProductsListPage from "./ProductListPage";

const ProductRouter = () => (
    <Switch>
      <Route exact path={AppRoutes.Products} component={ProductsListPage} />
      <Route exact path={ProductRoutes.Product()} component={ProductPage} />
      <Route render={() => <Redirect to={AppRoutes.Products} />} />
    </Switch>
);

export default ProductRouter;
