import React, { useState } from "react";
import {
  getDealerAddendumDocumentNumbers,
  updateDealerAddendumDocumentNumbers,
} from "./actions";
import { ContentPanel } from "components/displays";
import { FormBuilder, Form } from "@redriver/cinnamon";
import { IconButton } from "features/../../../shared/components/buttons";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { useTranslation } from "react-i18next";
import { Grid, GridColumn, GridRow, Button } from "semantic-ui-react";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { navigateGridForm } from "features/../../../shared/components/forms/formHelper";
const DealerAddendumDocumentNumbers = ({ id }) => {
  const { t } = useTranslation();
  const [timeStamp, setTimeStamp] = useState(Date.now());

  return (
    <FormBuilder
      loadAction={getDealerAddendumDocumentNumbers}
      loadParams={{ id, timeStamp }}
      initialReadOnly
      submitAction={updateDealerAddendumDocumentNumbers}
      submitParams={id}
      onSubmitted={() => {
        setTimeStamp(Date.now());
      }}
      renderForm={(formProps, state, events) => {
        if (!formProps.value) return null;
        return (
          <Form
            {...formProps}
            onKeyDown={(e) => {
              navigateGridForm(e, 2, 1, false);
            }}
          >
            <ContentPanel
              title="Addendum Document Numbers"
              wrapperCssClass="outline-grid supplier-header request-email-content"
              headerCssClass="defaults-page-title"
              headerContent={
                <PermissionCheck target={Targets.Dealer} action={Actions.Edit}>
                  <div className="flex">
                    <IconButton
                      primary
                      icon={state.readOnly ? Pen : Save}
                      content={
                        state.readOnly
                          ? t("shared:buttons:edit")
                          : t("shared:buttons:save")
                      }
                      floated="right"
                      onClick={() =>
                        state.readOnly
                          ? events.onToggleReadOnly()
                          : events.onSubmit({ reset: ["readOnly"] })
                      }
                      className={
                        !state.readOnly ? "positive-title-action" : null
                      }
                    />
                    {!state.readOnly && (
                      <Button
                        className="cancel-title-action"
                        floated="right"
                        content={t("shared:buttons:cancel")}
                        onClick={() => {
                          events.onReset();
                        }}
                      />
                    )}
                  </div>
                </PermissionCheck>
              }
            >
              <Grid className="dealer-summary-content">
                <GridColumn
                  mobile={16}
                  tablet={16}
                  computer={10}
                  largeScreen={7}
                  widescreen={7}
                  floated="left"
                >
                  <Grid>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        Large Format Printing Equipment:
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {" "}
                        <Form.Input field="largeFormatDocumentNumber" />
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        Software Support:
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {" "}
                        <Form.Input field="softwareSupportDocumentNumber" />
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        Product Schedule Appendix:
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {" "}
                        <Form.Input field="productScheduleDocumentNumber" />
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        Paper Inclusive Addendum:
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {" "}
                        <Form.Input field="paperInclusiveAddendumNumber" />
                      </GridColumn>
                    </GridRow>
                  </Grid>
                </GridColumn>
              </Grid>
            </ContentPanel>
          </Form>
        );
      }}
    />
  );
};

export default DealerAddendumDocumentNumbers;
