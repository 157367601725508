import React, { forwardRef } from "react";
import { Accordion, Icon, Button } from "semantic-ui-react";
import { FormBuilder, Form } from "@redriver/cinnamon";
import {
  getSettlementParametersDefaults,
  updateSettlementParametersDefaults,
} from "./actions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { Targets, Actions } from "constants/permissions";

const SettlementParameters = forwardRef(
  ({ settingsId, toggleVisibility, collapsed }, ref) => {
    return (
      <React.Fragment>
        <div ref={ref} className="cost-per-device-title">
          <FormBuilder
            initialReadOnly
            loadAction={getSettlementParametersDefaults}
            loadParams={{ settingsId }}
            submitAction={updateSettlementParametersDefaults}
            submitParams={{ settingsId }}
            renderLoading={false}
            renderSubmitting={false}
            renderError={false}
            renderForm={(formProps, state, events) => {
              return (
                <Accordion className="settlement-parameters-settings">
                  <Accordion.Title
                    active={!collapsed}
                    onClick={toggleVisibility}
                  >
                    <div ref={ref}>
                      <div onClick={toggleVisibility}>
                        <div className="title">
                          <h5>Settlement Parameters</h5>
                          {toggleVisibility && (
                            <Icon
                              name="dropdown"
                              rotated={collapsed ? "counterclockwise" : null}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Accordion.Title>
                  <Accordion.Content active={!collapsed}>
                    <div className="defaults-page-title flex">
                      <div className="flex title-bar"></div>
                      <PermissionCheck
                        target={Targets.DefaultsRestrictions}
                        action={Actions.Edit}
                      >
                        <div className="settlement-parameters-edit">
                          <IconButton
                            primary
                            icon={state.readOnly ? Pen : Save}
                            content={state.readOnly ? "Edit" : "Save"}
                            floated="right"
                            onClick={() =>
                              state.readOnly
                                ? events.onToggleReadOnly()
                                : events.onSubmit({ reset: ["readOnly"] })
                            }
                            className={
                              !state.readOnly ? "positive-title-action" : null
                            }
                            loading={state.loading || state.submitting}
                            disabled={state.loading || state.submitting}
                          />
                          {!state.readOnly && (
                            <Button
                              primary
                              className="cancel-title-action"
                              floated="right"
                              content="Cancel"
                              onClick={events.onReset}
                            />
                          )}
                        </div>
                      </PermissionCheck>
                    </div>
                    <Form {...formProps} className="">
                      <div className="settlement-parameters-box">
                        <div className="title">Included Percentage/Amount</div>

                        <div className="settlement-parameters-data">
                          <Form.Numeric
                            formatValue
                            inline
                            label="Funder CFA"
                            field="funderCFA"
                            className=""
                            subLabel="%"
                            subLabelPosition="right"
                          />
                          <Form.Numeric
                            formatValue
                            inline
                            label="Supplier CFA"
                            field="supplierCFA"
                            className=""
                            subLabel="%"
                            subLabelPosition="right"
                          />
                          <Form.Numeric
                            formatValue
                            inline
                            label="Co-Funded CFA"
                            field="coFundedCFA"
                            className=""
                            subLabel="%"
                            subLabelPosition="right"
                          />
                          <Form.Numeric
                            formatValue
                            inline
                            label="Metered Services"
                            field="meterServices"
                            className=""
                            subLabel="%"
                            subLabelPosition="right"
                          />
                          <Form.Numeric
                            formatValue
                            inline
                            label="Quarterly Services"
                            field="quarterlyServices"
                            className=""
                            subLabel="%"
                            subLabelPosition="right"
                          />
                          <Form.Numeric
                            formatValue
                            inline
                            label="Termination Fee"
                            field="terminationFee"
                            className=""
                            subLabel={process.env.MAJOR_CURRENCY}
                            subLabelPosition="left"
                            decimalPlaces={2}
                          />
                          <Form.Numeric
                            formatValue
                            inline
                            label="Kyanite Module"
                            field="kyaniteModule"
                            className=""
                            subLabel={process.env.MAJOR_CURRENCY}
                            subLabelPosition="left"
                            decimalPlaces={2}
                          />
                          <Form.Numeric
                            formatValue
                            inline
                            label="Other"
                            field="other"
                            className=""
                            subLabel={process.env.MAJOR_CURRENCY}
                            subLabelPosition="left"
                            decimalPlaces={2}
                          />
                        </div>
                      </div>
                    </Form>
                  </Accordion.Content>
                </Accordion>
              );
            }}
          />
        </div>
      </React.Fragment>
    );
  }
);

export default SettlementParameters;
