import React from "react";
import { addPrinter } from "./actions";
import { IconButton } from "features/../../../shared/components/buttons";
import { ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import PrinterModalForm from "./PrinterModalForm";
import { TranslatedModal } from "../../../../components/modals";
import { useTranslation } from "react-i18next";

const AddPrinter = ({ as: As = IconButton, onAdded, dealerOfficeId, ...props }) => {
  
  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'largeFormat.printer'});

  return(
    <PermissionCheck target={Targets.LargeFormat} action={Actions.Create}>
      <ModalBuilder
        withForm
        submitAction={addPrinter}
        submitParams={{ dealerOfficeId }}
        onSubmitted={onAdded}
        renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
        renderModal={(modalProps, formProps) => (
          <TranslatedModal.Add
            {...modalProps}
            header={t("createPrinter")}
            submitLabel={t("createPrinter")}
          >
            <PrinterModalForm formProps={formProps} dealerOfficeId={dealerOfficeId} />
          </TranslatedModal.Add>
        )}
      />
    </PermissionCheck>
  )
};

export default AddPrinter;
