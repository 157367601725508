import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { ContentPanel } from "components/displays";
import { BreadcrumbBar } from "pages/nav";
import CFA from "features/CFA/CFA";

const CFADetails = ({ match }) => {
  return (
    <PageContent fluid className="cfa-container">
      <BreadcrumbBar />
      <ContentPanel
        title="CFA"
        wrapperCssClass="outline-grid"
        headerCssClass="defaults-page-title"
      >
        <div className="padded">
          <CFA cfaId={match.params.id} />
        </div>
      </ContentPanel>
    </PageContent>
  );
};

export default CFADetails;
