import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { ProductList } from "features/Products";
import { BreadcrumbBar } from "pages/nav";

const ProductsListPage = () => (
  <PageContent fluid className="schedules-container">
    <BreadcrumbBar />
    <ProductList />
  </PageContent>
);

export default ProductsListPage;
