import React from "react";
import { SidePanel, Form } from "@redriver/cinnamon";
import { FilterButton } from "features/../../../shared/components/buttons";
import { withCurrentUser } from "features/../../../shared/components/auth";

const FilterSidePanel = ({ formProps, onReset }) => {
  return (
    <SidePanel
      closeIcon
      className="users-filter-side-panel"
      trigger={<FilterButton />}
    >
      <div>
        <div className="ui header filters-title">
          <div className="title-text">Filters</div>
          <div className="clear-button" onClick={onReset}>
            (clear)
          </div>
        </div>
        <Form {...formProps} className="highlighted-inputs">
          <div className="ui form highlighted-inputs">
            <p>Filters to go here</p>
          </div>
        </Form>
      </div>
    </SidePanel>
  );
};

export default withCurrentUser(FilterSidePanel);
