import React from "react";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import {
  getRateGroupDetails,
  updateRateGroupDetails,
} from "features/../../../shared/features/Settings/Rates/actions.js";
import moment from "moment";
import { Message } from "semantic-ui-react";
import RateGroupStatus from "features/../../../shared/constants/enums/RateGroupStatus";
import { TranslatedModal } from "features/../../../shared/components/modals";
import { useTranslation } from "react-i18next";

const yesNoOpts = [
  { text: "No", value: "false" },
  { text: "Yes", value: "true" },
];

const EditRateGroupDetailsModal = ({
  open,
  setModalVisibility,
  onEdited,
  id,
}) => {
  const { t } = useTranslation("supplierOffices", {
    keyPrefix: "rates.editRateGroupDetailsModal",
  });

  return (
    <ModalBuilder
      withForm
      loadAction={getRateGroupDetails}
      loadParams={{ rateGroupId: id }}
      submitAction={updateRateGroupDetails}
      submitParams={{ rateGroupId: id }}
      onSubmitted={() => {
        setModalVisibility(false);
        onEdited();
      }}
      visible={open}
      onCancel={() => setModalVisibility(false)}
      renderModal={(modalProps, formProps) => {
        const disabled =
          formProps.value.status === "Active" ||
          formProps.value.status === "Expired";
        return (
          <TranslatedModal.Edit
            {...modalProps}
            header={t("header")}
            submitLabel={t("submitLabel")}
            className="rate-modal-form"
          >
            <Form {...formProps}>
              <Form.Group widths="equal">
                <Form.Input
                  field="name"
                  label={t("name")}
                  fluid
                  required
                  placeholder={t("namePlaceholder")}
                />
                <Form.Date
                  dateFormat="DD MMM YYYY"
                  instant
                  field="applicableFromUtc"
                  label={t("applicableFrom")}
                  fluid
                  placeholder={t("applicableFromPlaceholder")}
                  minDate={moment()}
                  disabled={
                    formProps.value.status === RateGroupStatus.Active ||
                    formProps.value.status === RateGroupStatus.Expired
                  }
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Numeric
                  field="rrpPercentage"
                  label={t("rrp")}
                  decimalPlaces={2}
                  minValue={0}
                  maxValue={99.99}
                  formatValue
                  fluid
                  required={!disabled}
                  disabled={disabled}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  field="origin"
                  label={t("appliedTo")}
                  readOnly
                  fluid
                />
                <Form.Input field="status" label={t("status")} readOnly fluid />
              </Form.Group>
            </Form>
            {(formProps.value.status === RateGroupStatus.Active ||
              formProps.value.status === RateGroupStatus.Expired) && (
              <Message info content={t("message")} />
            )}
          </TranslatedModal.Edit>
        );
      }}
    />
  );
};

export default EditRateGroupDetailsModal;
