export default {
  View: "v",
  Create: "c",
  Edit: "e",
  Delete: "d",
  Archive: "a",
  Renew: "r",
  ClearLock: "cl",
  Download: "dl",
  OverrideCustomerSigned: "ocs",
  OverrideIpsSigned: "ois",
  DealerView: "dv",
  SetOwner: "so",
  Void: "vd",
  ReviewByDealer: "rd",
  ReviewByIps: "ri",
  Approve: "ap",
  Reset: "rt",
  UpdateIPSPermissions: "uip",
  UpdateIPSUsers: "uipusr",
};
