import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULES/CFA/OPTIONS";

const ActionTypes = {
  GetCfaPayment: `${NAMESPACE}/GET_CFA_PAYMENT`,
  GetContinuingPayments: `${NAMESPACE}/GET_CONTINUING_PAYMENTS`,
  GetCfaDetails: `${NAMESPACE}/GET_CFA_DETAILS`,
  GetProducts: `${NAMESPACE}/GET_PRODUCTS`,
  GetFMVInfo: `${NAMESPACE}/GET_FMV_INFO`,
};

const mockCFADetails = {
  reference: "ABC9110",
  funder: "Funder 1",
  status: "Part Converted",
};

const mockCfaPayment = [
  {
    id: 1,
    title: "CFA Payment",
    minimum: 1320.26,
    scheduleSplit: 20.58,
    billingCode: "FUNFIN",
  },
];

const mockContinuingPayments = [
  {
    id: 1,
    title: "Funder 1",
    split: 10,
    minimum: 132.03,
    billingCode: "FUNFINCP",
  },
  {
    id: 2,
    title: "Konica Minolta",
    split: 70,
    minimum: 924.18,
    billingCode: "KMFINCP",
  },
  {
    id: 3,
    title: "IP Syndicate",
    split: 20,
    minimum: 264.05,
    billingCode: "IPSFINCP",
  },
  {
    id: 4,
  },
  {
    id: 5,
    title: "Total",
    split: 100,
    minimum: 1320.26,
    billingCode: "",
  },
];

const mockProducts = [
  {
    id: 1,
    identifierNumber: "13456abcidf",
    manModel: "KM C300I",
    status: "Retained",
  },
  {
    id: 2,
    identifierNumber: "6ef16we1f6w1ef6ew51",
    manModel: "KM C300I",
    status: "Retained",
  },
  {
    id: 3,
    identifierNumber: "we68f1we6f1w6e8f1e",
    manModel: "KM C300I",
    status: "Retained",
  },
  {
    id: 4,
    identifierNumber: "wefw6e8f1w65e1f",
    manModel: "KM C300I",
    status: "Retained",
  },
  {
    id: 5,
    identifierNumber: "wef1w6ef1w65e1f",
    manModel: "KM C300I",
    status: "Retained",
  },
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
  {},
];

const mockFmvInfo = {
  converted: 1200,
  unconverted: 600,
  total: 1800,
  sumToCollect: 1204.64,
  fmvSettled: 0,
  cfAmount: 1204.64,
  valueDueToFunder: 600,
  dueDate: "06/15/2023",
  invoicedToSupplier: "Yes/No",
  amountInvoiced: 300,
  paidToFunder: "Yes/no",
  amountPaid: 300,
  unconvertedAmountSettled: 0,
  unconvertedAmountCF: 300,
};

export const getCfaDetails = () => {
  return mockSuccess(ActionTypes.GetCfaDetails, {
    response: mockCFADetails,
  });
};

export const getCfaPayment = () => {
  return mockSuccess(ActionTypes.GetCfaPayment, {
    response: mockCfaPayment,
  });
};

export const getContinuingPayments = () => {
  return mockSuccess(ActionTypes.GetContinuingPayments, {
    response: mockContinuingPayments,
  });
};

export const getProducts = () => {
  return mockSuccess(ActionTypes.GetProducts, {
    response: mockProducts,
  });
};

export const getFMVInfo = () => {
  return mockSuccess(ActionTypes.GetFMVInfo, {
    response: mockFmvInfo,
  });
};
