import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { downloadBoilerPlateTandCs } from "./actions";
import FileSaver from "file-saver";

const DownloadBoilerPlateTandCs = ({ item }) => {
  const itemId = item.id;
  return (
    <ModalBuilder
      submitAction={downloadBoilerPlateTandCs}
      submitParams={{ id: itemId }}
      onSubmitted={result => {
        result && FileSaver.saveAs(result, `${item.reference}.html`);
      }}
      renderTrigger={showModal => (
        <Button
          primary
          onClick={showModal}
          title="Download"
          icon="download"
          className="download-btn"
        />
      )}
      renderModal={modalProps => (
        <Modal.Confirmation {...modalProps} header="Download File">
          Are you sure you want to download?
        </Modal.Confirmation>
      )}
    />
  );
};

export default DownloadBoilerPlateTandCs;
