import React, { forwardRef } from "react";
import AccordionHelper from "components/displays/AccordionHelper";
import TieredTonerRestrictions from "../../RestrictionSettings/TieredTonerRestrictions";

const TieredTonerSettings = forwardRef(
  ({ settingsId, toggleVisibility, collapsed }, ref) => (
    <React.Fragment>
      <AccordionHelper
        title="Tiered Toner Settings"
        toggleVisibility={toggleVisibility}
        collapsed={collapsed}
      >
        <TieredTonerRestrictions
          settingsId={settingsId}
          ref={ref}
        ></TieredTonerRestrictions>
      </AccordionHelper>
    </React.Fragment>
  )
);

export default TieredTonerSettings;
