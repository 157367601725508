import { Form, FormBuilder } from "@redriver/cinnamon";
import { Widget, WidgetColumn, WidgetRow } from "components/widgets";
import React from "react";
import { Image } from "semantic-ui-react";

//temporary placeholder images
//todo - once graphs have been implemented properly, these can go
import TempBarChart from "assets/tempDashboardPlaceholders/largeBarChart.png";
import TempMiniGreenChart from "assets/tempDashboardPlaceholders/miniChartGreen.png";
import TempMiniPurpleChart from "assets/tempDashboardPlaceholders/miniChartPurple.png";
import TempMiniDoughnutBlue from "assets/tempDashboardPlaceholders/miniDoughnutBlue.png";
import TempMiniDoughnutPurple from "assets/tempDashboardPlaceholders/miniDoughnutPurple.png";
import TempMiniDoughnutTurquoise from "assets/tempDashboardPlaceholders/miniDoughnutTurquoise.png";
import BigDoughnut from "assets/tempDashboardPlaceholders/bigDoughnut.png";
import SkinnyBarChart from "assets/tempDashboardPlaceholders/skinnyBarChart.png";
import LineGraph from "assets/tempDashboardPlaceholders/lineGraph.png";

const Dashboard = () => (
  <div className="dashboard">
    <WidgetRow condition>
      <Widget condition className="w-50 barChart-placeholder">
        <h2>Widget One</h2>
        <div className="barChart-filters">
          <FormBuilder
            renderForm={(formProps) => (
              <Form {...formProps}>
                <Form.Dropdown
                  field="field1"
                  placeholder="Monthly"
                  options={[
                    {
                      value: "0",
                      text: "Monthly",
                    },
                    {
                      value: "1",
                      text: "Weekly",
                    },
                    {
                      value: "2",
                      text: "Daily",
                    },
                  ]}
                />
                <Form.Dropdown
                  field="field2"
                  placeholder="Last Year"
                  options={[
                    {
                      value: "0",
                      text: "Last Year",
                    },
                    {
                      value: "1",
                      text: "This Year",
                    },
                  ]}
                />
              </Form>
            )}
          />
        </div>
        <div className="barChart-average">
          Avg. {process.env.MAJOR_CURRENCY}5,309
        </div>
        <Image src={TempBarChart} />
      </Widget>
      <WidgetColumn condition className="w-25">
        <Widget condition className="new-agreements-placeholder">
          <div className="split">
            <div>13</div>
            <div>New Agreements</div>
          </div>
          <div className="split">
            <Image src={TempMiniGreenChart} className="mini-barChart" />
          </div>
        </Widget>
        <Widget condition className="customer-invoice-placeholder">
          <div className="split">
            <div>
              100
              <i>{process.env.MAJOR_CURRENCY}100,000.00</i>
            </div>
            <div>Customers ready to invoice</div>
          </div>
          <div className="split">
            <Image src={TempMiniPurpleChart} className="mini-barChart" />
          </div>
        </Widget>
        <Widget condition className="mini-doughnut-placeholder">
          <div className="row">
            <div className="split">
              <Image src={TempMiniDoughnutTurquoise} />
            </div>
            <div className="split">
              <div>Graph 1</div>
              <div>XX of total XX</div>
            </div>
          </div>
          <div className="row">
            {" "}
            <div className="split">
              <Image src={TempMiniDoughnutBlue} />
            </div>
            <div className="split">
              <div>Graph 2</div>
              <div>XX of total XX</div>
            </div>
          </div>
          <div className="row">
            {" "}
            <div className="split">
              <Image src={TempMiniDoughnutPurple} />
            </div>
            <div className="split">
              <div>Graph 3</div>
              <div>XX of total XX</div>
            </div>
          </div>
        </Widget>
      </WidgetColumn>
      <Widget
        condition
        title="Widget Three"
        className="w-25 big-doughnut-placeholder"
      >
        <Image src={BigDoughnut} />
        <ul>
          <li>
            <span>Long category label 01</span>
          </li>
          <li>
            <span>Long category label 02</span>
          </li>
          <li>
            <span>Long category label 03</span>
          </li>
        </ul>
      </Widget>
    </WidgetRow>
    <WidgetRow condition>
      <Widget
        condition
        title="Widget Two"
        className="w-25 skinny-barChart-placeholder"
      >
        <Image src={SkinnyBarChart} />
        <ul className="horizontal">
          <li>Point 01</li>
          <li>Point 02</li>
        </ul>
      </Widget>
      <Widget
        condition
        title="Widget Four"
        className="w-75 line-graph-placeholder"
      >
        <Image src={LineGraph} />
        <ul className="horizontal">
          <li>Point 01</li>
          <li>Point 02</li>
        </ul>
      </Widget>
    </WidgetRow>
  </div>
);

export default Dashboard;
