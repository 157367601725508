import React, { useEffect, useState } from "react";
import { ListBuilder, Form, usePreferences } from "@redriver/cinnamon";
import { getFunderDocuments, uploadFunderDocuments } from "../actions";
import {
  ContentWrapper,
  ContentHeader,
} from "features/Companies/Funders/Helper";
import { FileUploadModalWrapper } from "components/modals/";
import { TextSearch } from "features/../../../shared/components/search";
import { TablePagination } from "components/pagination";
import { DealersTypeAheadLookup } from "features/../../../shared/features/Dealers/lookups";
import {
  TableConfigurator,
  ConfigurableTable,
} from "features/CustomConfig/TableConfig";
import {
  dateFormatter,
  blankDataFormatter,
} from "components/displays/DisplayHelper";

const getFunderDocumentsActionBarItems = (
  state,
  events,
  config,
  columns,
  pageSizeConfig,
  funderId
) => {
  return [
    {
      item: (
        <TextSearch
          filters={state.filters}
          onChange={events.onChangeFilters}
        ></TextSearch>
      ),
    },
    {
      type: "space",
    },
    {
      type: "noItem",
      isFluid: true,
    },
    {
      type: "space",
    },
    {
      item: (
        <FileUploadModalWrapper
          primary
          icon="upload"
          title="Upload New Document"
          modalClassName="modal outline-grid yellow"
          buttonClassName="yellow"
          submitAction={uploadFunderDocuments}
          submitParams={funderId}
          reloadDocumentList={events.onRefresh}
        >
          <Form.FileUpload field="file" fluid required />
          <div className="flex-items">
            <Form.Input
              field="documentNumber"
              label="Document Number"
              fluid
              inline
              required
            />
            <Form.Input field="type" label="Type" fluid inline />
          </div>
          <div className="margin">
            <Form.Input field="description" label="Description" fluid inline />
          </div>
          <div className="margin">
            <DealersTypeAheadLookup
              field="supplierId"
              fluid
              inline
              clearable
              label="Associated Supplier/SPV"
              placeholder="Select supplier/spv..."
              required
              lookupParams={{
                showSPV: true,
              }}
            />
          </div>
        </FileUploadModalWrapper>
      ),
    },
    {
      type: "space",
    },
    {
      item: (
        <TableConfigurator
          config={config}
          columns={columns}
          configId="funderDocumentsTableConfig"
          titlePreFix="Documents"
          initialPageSize={25}
          pageSizeConfig={pageSizeConfig}
        ></TableConfigurator>
      ),
    },
  ];
};

const FunderDocuments = ({ funderId }) => {
  const { preferences } = usePreferences();
  const [config, setConfig] = useState(preferences.funderDocumentsTableConfig);
  const [pageSizeConfig, setPageSizeConfig] = useState(
    preferences.funderDocumentsTableConfigPageSize ?? 25
  );

  useEffect(() => {
    setConfig(preferences.funderDocumentsTableConfig);
  }, [preferences.funderDocumentsTableConfig]);

  useEffect(() => {
    preferences.funderDocumentsTableConfigPageSize &&
      setPageSizeConfig(preferences.funderDocumentsTableConfigPageSize);
  }, [preferences.funderDocumentsTableConfigPageSize]);

  const columns = [
    {
      field: "documentNumber",
      title: "Document Number",
    },
    {
      field: "type",
      title: "Type",
      render: (item) => blankDataFormatter(item.type, "-"),
    },
    {
      field: "description",
      title: "Description",
      render: (item) => blankDataFormatter(item.description, "-"),
    },
    { field: "supplier", title: "Associated Supplier" },
    { field: "spv", title: "Associated SPV" },
    {
      field: "uploadedDate",
      title: "Uploaded Date",
      render: (item) =>
        blankDataFormatter(dateFormatter(item.uploadedDate), "-"),
    },
    { render: () => <a>View</a> },
  ];

  return (
    <ListBuilder
      withTable
      initialPagination={{ pageSize: pageSizeConfig, pageNumber: 1 }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      loadAction={getFunderDocuments}
      loadParams={funderId}
      renderList={(tableProps, state, events) => (
        <div className="funder-documents">
          <ContentWrapper
            title="Documents"
            contentHeader={
              <ContentHeader
                items={getFunderDocumentsActionBarItems(
                  state,
                  events,
                  config,
                  columns,
                  pageSizeConfig,
                  funderId
                )}
                headerItemsClassName="search-filter-display"
              />
            }
          >
            <div className="spaced">
              <ConfigurableTable
                tableProps={tableProps}
                emptyMessage="No Documents Found"
                columns={columns}
                config={config}
                state={state}
                events={events}
              />
              <div className="padded">
                <TablePagination
                  pageNumber={state.pagination.pageNumber}
                  onChange={events.onChangePagination}
                  pageSize={pageSizeConfig}
                  totalItems={state.total}
                />
              </div>
            </div>
          </ContentWrapper>
        </div>
      )}
    ></ListBuilder>
  );
};

export default FunderDocuments;
