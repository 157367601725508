import React from "react";
import {
  numericFormatter,
  yesNoFormatter,
  Header,
} from "components/displays/DisplayHelper";
import { ContentPanel } from "components/displays";
import {
  getFmvTotals,
  getUnconvertedFMV,
  getSFAccountsRemaining,
  getAmountsCollected,
  getAdditionalUnitsCharge,
} from "./actions";

import { Table } from "semantic-ui-react";
import { ListBuilder, Loadable, Format } from "@redriver/cinnamon";

const CFASummary = () => (
  <div className="padded">
    <ContentPanel
      title="CFA Summary"
      wrapperCssClass="outline-grid"
      headerCssClass="defaults-page-title"
    >
      <div className="padded">
        <div className="flex">
          <AmountsCollected />
          <AdditionalUnitsCharge />
        </div>
        <FinanceAccountsRemaining />
      </div>
    </ContentPanel>
    <br />
    <br />
    <ContentPanel
      title="FMV"
      wrapperCssClass="outline-grid"
      headerCssClass="defaults-page-title"
    >
      <div className="padded flex fmv-container">
        <FMVTotals />
        <div className="vertical-scrollbar small-medium">
          <UnconvertedFMV />
        </div>
      </div>
    </ContentPanel>
  </div>
);

const AmountsCollected = () => {
  return (
    <ListBuilder
      withTable
      loadAction={getAmountsCollected}
      renderList={(tableProps, state) => (
        <Table className="amounts-collected-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={6}>
                Schedule Finance Amounts Collected {process.env.MAJOR_CURRENCY}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell
                className="no-bottom-border"
                rowSpan={2}
              ></Table.HeaderCell>
              <Table.HeaderCell colSpan={4}>CFA Payment</Table.HeaderCell>
              <Table.HeaderCell className="no-bottom-border" rowSpan={2}>
                Total {process.env.MAJOR_CURRENCY}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                Minimum {process.env.MAJOR_CURRENCY}
              </Table.HeaderCell>
              <Table.HeaderCell>
                Additional Units {process.env.MAJOR_CURRENCY}
              </Table.HeaderCell>
              <Table.HeaderCell>
                CP Minimum {process.env.MAJOR_CURRENCY}
              </Table.HeaderCell>
              <Table.HeaderCell>
                CP Additional Units {process.env.MAJOR_CURRENCY}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.loading && (
              <Loadable loading={state.loading || state.submitting}></Loadable>
            )}
            {tableProps.data.map((d) => (
              <Table.Row
                key={d.id}
                className={
                  d.title === "Schedule Total"
                    ? "total-row"
                    : d.title === "Total"
                    ? "sub-total-row"
                    : ""
                }
              >
                <Table.Cell>{d.title}</Table.Cell>
                <Table.Cell>{numericFormatter(d.minimum, "0.00")}</Table.Cell>
                <Table.Cell>
                  {numericFormatter(d.additionalUnits, "0.00")}
                </Table.Cell>
                <Table.Cell>{numericFormatter(d.cpMinimum, "0.00")}</Table.Cell>
                <Table.Cell>
                  {numericFormatter(d.cpAdditionalUnits, "0.00")}
                </Table.Cell>
                <Table.Cell>{numericFormatter(d.total, "0.00")}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    />
  );
};

const AdditionalUnitsCharge = () => {
  const columns = [
    { field: "title", title: "" },
    { field: "total", title: `Total ${process.env.MAJOR_CURRENCY}` },
  ];

  return (
    <ListBuilder
      withTable
      loadAction={getAdditionalUnitsCharge}
      renderList={(tableProps, state) => (
        <Table className="additional-charge-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={5}>
                CFA Additional Units Charge
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Header columns={columns} />
          <Table.Body>
            {state.loading && (
              <Loadable loading={state.loading || state.submitting}></Loadable>
            )}
            {tableProps.data.map((d) => (
              <Table.Row
                key={d.id}
                className={
                  d.title === "Available Amount"
                    ? "total-row"
                    : d.title === "Total"
                    ? "sub-total-row"
                    : ""
                }
              >
                <Table.Cell>{d.title}</Table.Cell>
                <Table.Cell>{numericFormatter(d.total, "0.00")}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    />
  );
};

const FinanceAccountsRemaining = () => {
  const columns = [
    { field: "title", title: "" },
    {
      field: "cfa",
      title: `CFA ${process.env.MAJOR_CURRENCY}`,
      render: (x) => numericFormatter(x.cfa),
    },
    {
      field: "cpMin",
      title: `CP Min ${process.env.MAJOR_CURRENCY}`,
      render: (x) => numericFormatter(x.cpMin),
    },
    {
      field: "total",
      title: `Total ${process.env.MAJOR_CURRENCY}`,
      render: (x) => numericFormatter(x.total),
    },
  ];

  return (
    <ListBuilder
      withTable
      loadAction={getSFAccountsRemaining}
      renderList={(tableProps, state) => (
        <Table
          className="accounts-remaining-table"
          emptyMessage="No records found"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={4}>
                Schedule Finance Accounts Remaining
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Header columns={columns} />
          <Table.Body>
            {state.loading && (
              <Loadable loading={state.loading || state.submitting}></Loadable>
            )}
            {tableProps.data.map((d) => (
              <Table.Row
                key={d.id}
                className={d.title === "Total" ? "total-row" : ""}
              >
                <Table.Cell>{d.title}</Table.Cell>
                <Table.Cell>{numericFormatter(d.cfa)}</Table.Cell>
                <Table.Cell>{numericFormatter(d.cpMin)}</Table.Cell>
                <Table.Cell>{numericFormatter(d.total)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    />
  );
};

const FMVTotals = () => {
  const columns = [
    {
      field: "title",
      title: `FMV ${process.env.MAJOR_CURRENCY}`,
      header: `FMV ${process.env.MAJOR_CURRENCY}`,
      colSpan: 2,
    },
  ];

  return (
    <ListBuilder
      withTable
      loadAction={getFmvTotals}
      renderList={(tableProps, state) => (
        <Table className="fmv-table">
          <Header columns={columns} />
          <Table.Body>
            {state.loading && (
              <Loadable loading={state.loading || state.submitting}></Loadable>
            )}
            {tableProps.data.map((d) => (
              <Table.Row
                key={d.id}
                className={
                  d.title === "Converted-Sum to Collect"
                    ? "total-row"
                    : d.title === "Total"
                    ? "bold"
                    : ""
                }
              >
                <Table.Cell>{d.title}</Table.Cell>
                <Table.Cell>{numericFormatter(d.value, "0.00")}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    />
  );
};

const UnconvertedFMV = () => {
  const columns = [
    { field: "cfaReference", title: "CFA Reference" },
    {
      field: "dueToFunder",
      title: `Value Due to Funder ${process.env.MAJOR_CURRENCY}`,
    },
    { field: "dueDt", title: "Due Date" },
    { field: "paid", title: "Paid to Funder" },
    {
      field: "unconvertedAmount",
      title: `Unconverted Amount C/F ${process.env.MAJOR_CURRENCY}`,
    },
  ];

  return (
    <ListBuilder
      withTable
      loadAction={getUnconvertedFMV}
      renderList={(tableProps, state) => (
        <Table className="unconverted-fmv-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={5}>Unconverted FMV</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              {columns.map((c) => (
                <Table.HeaderCell key={c.id}>{c.title}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.loading && (
              <Loadable loading={state.loading || state.submitting}></Loadable>
            )}
            {tableProps.data.map((d) => (
              <Table.Row
                key={d.id}
                className={d.cfaReference === "Total" ? "total-row" : ""}
              >
                <Table.Cell>{d.cfaReference}</Table.Cell>
                <Table.Cell>
                  {numericFormatter(d.dueToFunder, "0.00")}
                </Table.Cell>
                <Table.Cell>
                  <Format.Date value={d.dueDt} format="DD MMM YYYY" />
                </Table.Cell>
                <Table.Cell>
                  {d.paid === undefined ? "" : yesNoFormatter(d.paid)}
                </Table.Cell>
                <Table.Cell>
                  {numericFormatter(d.unconvertedAmount, "0.00")}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    />
  );
};

export default CFASummary;
