import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { SchedulesList } from "features/Schedules/SchedulesList";
import { BreadcrumbBar } from "pages/nav";

const SchedulesListPage = () => (
  <PageContent fluid className="schedules-container">
    <BreadcrumbBar />
    <SchedulesList />
  </PageContent>
);

export default SchedulesListPage;
