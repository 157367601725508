import { AppRoutes, CFARoutes } from "constants/routes";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CFAList from "pages/CFA/CFAList";
import CFADetails from "pages/CFA/CFADetails";

const CFARouter = () => (
  <Switch>
    <Route exact path={AppRoutes.CFA} component={CFAList} />
    <Route exact path={CFARoutes.CFA()} component={CFADetails} />
    <Route render={() => <Redirect to={AppRoutes.CFA} />} />
  </Switch>
);

export default CFARouter;
