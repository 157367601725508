import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "PRODUCT/METER_READINGS";

const ActionTypes = {
  get: `${NAMESPACE}/GET`,
  getAll: `${NAMESPACE}/GET_ALL`,
};

const readings = {
  blackTotal: [
    {
      id: 1,
      type: "U",
      periodStartDate: "30-Dec-2020",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "Invoice Pending",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "29-Mar-2021",
      meterCode: "BLACKTOTAL",
      meterReading: "267624",
      meterUsage: "24520",
      notes: "",
    },
    {
      id: 2,
      type: "U",
      periodStartDate: "30-Sep-2020",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "V602623",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "29-Dec-2020",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 3,
      type: "U",
      periodStartDate: "30-Jun-2020",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "V599889",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "28-Sep-2020",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "https://somenoteslink",
    },
    {
      id: 4,
      type: "U",
      periodStartDate: "30-Mar-2020",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "V597228",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "30-Jun-2020",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 5,
      type: "U",
      periodStartDate: "30-Dec-2019",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "V594332",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "30-Mar-2020",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 6,
      type: "CN",
      periodStartDate: "30-Dec-2019",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "Y24125",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "30-Mar-2020",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "-3239",
      notes: "",
    },
    {
      id: 7,
      type: "U",
      periodStartDate: "30-Dec-2019",
      creditNote: "Y",
      schedule: "A1B41510/001",
      invoiceNumber: "V584221",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "30-Mar-2020",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 8,
      type: "U",
      periodStartDate: "30-Sep-2019",
      creditNote: "",
      schedule: "Z9854125/005",
      invoiceNumber: "V582147",
      unit: "B2",
      managedMeter: "Y",
      readingDate: "29-Dec-2019",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 9,
      type: "U",
      periodStartDate: "30-Jun-2019",
      creditNote: "",
      schedule: "Z9854125/005",
      invoiceNumber: "V580689",
      unit: "B2",
      managedMeter: "Y",
      readingDate: "01-Oct-2019",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 10,
      type: "U",
      periodStartDate: "30-Mar-2019",
      creditNote: "",
      schedule: "Z9854125/005",
      invoiceNumber: "V579856",
      unit: "B2",
      managedMeter: "Y",
      readingDate: "29-Jun-2019",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 11,
      type: "ADJ",
      periodStartDate: "",
      creditNote: "",
      schedule: "",
      invoiceNumber: "",
      unit: "",
      managedMeter: "",
      readingDate: "15-Jun-2019",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 12,
      type: "U",
      periodStartDate: "30-Dec-2018",
      creditNote: "",
      schedule: "Z9854125/005",
      invoiceNumber: "V578452",
      unit: "B2",
      managedMeter: "Y",
      readingDate: "29-Dec-2020",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 13,
      type: "CN",
      periodStartDate: "30-Dec-2018",
      creditNote: "",
      schedule: "Z9854125/004",
      invoiceNumber: "Y23141",
      unit: "B2",
      managedMeter: "Y",
      readingDate: "29-Dec-2020",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "-72758",
      notes: "",
    },
    {
      id: 14,
      type: "U",
      periodStartDate: "30-Dec-2018",
      creditNote: "Y",
      schedule: "Z9854125/004",
      invoiceNumber: "V578023",
      unit: "B2",
      managedMeter: "Y",
      readingDate: "29-Dec-2020",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 15,
      type: "U",
      periodStartDate: "30-Sep-2018",
      creditNote: "",
      schedule: "Z9854125/004",
      invoiceNumber: "V577177",
      unit: "B2",
      managedMeter: "Y",
      readingDate: "29-Dec-2020",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 16,
      type: "SR",
      periodStartDate: "",
      creditNote: "",
      schedule: "",
      invoiceNumber: "",
      unit: "B2",
      managedMeter: "Y",
      readingDate: "29-Dec-2020",
      meterCode: "BLACKTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
  ],
  colourTotal: [
    {
      id: 1,
      type: "U",
      periodStartDate: "30-Dec-2020",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "Invoice Pending",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "29-Mar-2021",
      meterCode: "COLOURTOTAL",
      meterReading: "267624",
      meterUsage: "24520",
      notes: "",
    },
    {
      id: 2,
      type: "U",
      periodStartDate: "30-Sep-2020",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "V602623",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "29-Dec-2020",
      meterCode: "COLOURTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 3,
      type: "U",
      periodStartDate: "30-Jun-2020",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "V599889",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "28-Sep-2020",
      meterCode: "COLOURTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 4,
      type: "U",
      periodStartDate: "30-Mar-2020",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "V597228",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "30-Jun-2020",
      meterCode: "COLOURTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 5,
      type: "U",
      periodStartDate: "30-Dec-2019",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "V594332",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "30-Mar-2020",
      meterCode: "COLOURTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 6,
      type: "CN",
      periodStartDate: "30-Dec-2019",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "Y24125",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "30-Mar-2020",
      meterCode: "COLOURTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 7,
      type: "U",
      periodStartDate: "30-Dec-2019",
      creditNote: "Y",
      schedule: "A1B41510/001",
      invoiceNumber: "V584221",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "30-Mar-2020",
      meterCode: "COLOURTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 8,
      type: "U",
      periodStartDate: "30-Sep-2019",
      creditNote: "",
      schedule: "Z9854125/005",
      invoiceNumber: "V582147",
      unit: "B2",
      managedMeter: "Y",
      readingDate: "29-Dec-2019",
      meterCode: "COLOURTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
  ],
  scanTotal: [
    {
      id: 1,
      type: "U",
      periodStartDate: "30-Dec-2020",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "Invoice Pending",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "29-Mar-2021",
      meterCode: "SCANTOTAL",
      meterReading: "267624",
      meterUsage: "24520",
      notes: "",
    },
    {
      id: 2,
      type: "U",
      periodStartDate: "30-Sep-2020",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "V602623",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "29-Dec-2020",
      meterCode: "SCANTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 3,
      type: "U",
      periodStartDate: "30-Jun-2020",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "V599889",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "28-Sep-2020",
      meterCode: "SCANTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
    {
      id: 4,
      type: "U",
      periodStartDate: "30-Mar-2020",
      creditNote: "",
      schedule: "A1B41510/001",
      invoiceNumber: "V597228",
      unit: "B1",
      managedMeter: "Y",
      readingDate: "30-Jun-2020",
      meterCode: "SCANTOTAL",
      meterReading: "243104",
      meterUsage: "4895",
      notes: "",
    },
  ],
};

export const getProductMeterReadings = (productId, meterReadingType) => {
  let result = readings;

  if (meterReadingType === 0) {
    result = readings.blackTotal;
  } else if (meterReadingType === 1) {
    result = readings.colourTotal;
  } else if (meterReadingType === 2) {
    result = readings.scanTotal;
  }

  return mockSuccess(ActionTypes.getAll, {
    delay: 200,
    response: result,
  });
};

const reading = {
  items: [
    {
      id: 1,
      unit: "B1",
      meterCode: "BLACKTOTAL",
      currentMeterReading: 267624,
      manualMeterReading: 297444,
      newMeterUsage: 29820,
      editMeterReading: 257624,
      uploadedMeterReading: 243104,
      adjustment: -10000,
    },
    {
      id: 2,
      unit: "C1",
      meterCode: "COLOURTOTAL",
      currentMeterReading: 211444,
      manualMeterReading: 213867,
      newMeterUsage: 2423,
      uploadedMeterReading: 195117,
      adjustment: 0,
    },
    {
      id: 3,
      unit: "S1",
      meterCode: "SCANTOTAL",
      currentMeterReading: 12547,
      manualMeterReading: 14111,
      newMeterUsage: 1564,
      uploadedMeterReading: 10441,
      adjustment: 0,
    },
    {
      id: 34,
      unit: "",
      meterCode: "Total",
      currentMeterReading: 491615,
      manualMeterReading: 525422,
      newMeterUsage: 33807,
      editMeterReading: 257624,
      uploadedMeterReading: 448662,
      adjustment: -10000,
    },
  ],
  notes: "",
};

export const getProductMeter = () => {
  return mockSuccess(ActionTypes.get, {
    delay: 100,
    response: reading,
  });
};
