import React from "react";
import { Menu } from "semantic-ui-react";

const NavMenu = ({ selectedNoteType, setSelectedNoteType }) => {
  const handleItemClick = (e, { value }) => setSelectedNoteType(value);
  return (
    <Menu>
      <Menu.Item
        value={0}
        active={selectedNoteType === 0}
        onClick={handleItemClick}
      >
        All Notes
      </Menu.Item>

      <Menu.Item
        value={1}
        active={selectedNoteType === 1}
        onClick={handleItemClick}
      >
        Agreement Notes
      </Menu.Item>

      <Menu.Item
        value={2}
        active={selectedNoteType === 2}
        onClick={handleItemClick}
      >
        Sheet Notes
      </Menu.Item>
    </Menu>
  );
};

export default NavMenu;
