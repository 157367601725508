import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import { Form, FormBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { useTranslation } from "react-i18next";
import { navigateGridForm } from "features/../../../shared/components/forms/formHelper";
import { getSPVBankAccount, updateSPVBankAccount } from "./actions";
import { ContentPanel } from "components/displays";

const SPVBankAccount = ({ spvId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [originalData, setOriginalData] = useState();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getSPVBankAccount(spvId)).then((result) => {
      setOriginalData(result.response);
      setLoading(false);
    });
  }, [spvId, dispatch]);

  useEffect(() => {
    resetData();
  }, [originalData, resetData]);

  const resetData = useCallback(() => {
    setFormData(originalData);
  }, [originalData]);

  const updateOriginalData = (formData) => {
    setOriginalData(formData);
  };

  return (
    <FormBuilder
      value={formData}
      onChange={(fd) => {
        setFormData(fd);
      }}
      initialReadOnly
      loading={loading}
      submitAction={updateSPVBankAccount}
      submitParams={spvId}
      onSubmitted={() => {
        updateOriginalData(formData);
      }}
      renderForm={(formProps, state, events) => {
        if (!formProps.value) return null;

        return (
          <Form
            {...formProps}
            onKeyDown={(e) => {
              navigateGridForm(e, 2, 1, false);
            }}
          >
            <ContentPanel
              title="SPV Bank Account"
              wrapperCssClass="outline-grid violet"
              headerCssClass="defaults-page-title violet"
              headerContent={
                <PermissionCheck target={Targets.SPVs} action={Actions.Edit}>
                  <div className="flex">
                    <IconButton
                      primary
                      icon={state.readOnly ? Pen : Save}
                      content={
                        state.readOnly
                          ? t("shared:buttons:edit")
                          : t("shared:buttons:save")
                      }
                      floated="right"
                      onClick={() =>
                        state.readOnly
                          ? events.onToggleReadOnly()
                          : events.onSubmit({ reset: ["readOnly"] })
                      }
                      className={
                        !state.readOnly ? "positive-title-action" : null
                      }
                    />
                    {!state.readOnly && (
                      <Button
                        className="cancel-title-action"
                        floated="right"
                        content={t("shared:buttons:cancel")}
                        onClick={() => {
                          resetData();
                          events.onReset();
                        }}
                      />
                    )}
                  </div>
                </PermissionCheck>
              }
            >
              <Form {...formProps} className="">
                <div className="bank-account-box">
                  <div className="bank-account-labels">
                    <div>
                      <div className="label-margin">
                        <label>Account Name</label>
                      </div>
                    </div>
                    <div>
                      <div className="label-margin">
                        <label>Account Number</label>
                      </div>
                    </div>
                    <div>
                      <div className="label-margin">
                        <label>Sort Code</label>
                      </div>
                    </div>
                    <div>
                      <div className="label-margin">
                        <label>IBAN Number</label>
                      </div>
                    </div>
                    <div>
                      <div className="label-margin">
                        <label>BIC Number</label>
                      </div>
                    </div>
                    <div>
                      <div className="label-margin">
                        <label>Roll Number</label>
                      </div>
                    </div>
                    <div>
                      <div className="label-margin">
                        <label>Bank Country Code</label>
                      </div>
                    </div>
                    <div>
                      <div className="label-margin">
                        <label>
                          Currency Account <span className="required">*</span>
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="label-margin">
                        <label>
                          Currency Invoice <span className="required">*</span>
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="label-margin">
                        <label>
                          Currency Payment <span className="required">*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="bank-account-fields">
                    <div>
                      <div className={state.readOnly ? "label-margin" : ""}>
                        <Form.Input field="accountName" />
                      </div>
                    </div>
                    <div>
                      <div className={state.readOnly ? "label-margin" : ""}>
                        <Form.Input field="accountNumber" />
                      </div>
                    </div>
                    <div>
                      <div className={state.readOnly ? "label-margin" : ""}>
                        <Form.Input field="sortCode" />
                      </div>
                    </div>
                    <div>
                      <div className={state.readOnly ? "label-margin" : ""}>
                        <Form.Input field="ibanNumber" />
                      </div>
                    </div>
                    <div>
                      <div className={state.readOnly ? "label-margin" : ""}>
                        <Form.Input field="bicNumber" />
                      </div>
                    </div>
                    <div>
                      <div className={state.readOnly ? "label-margin" : ""}>
                        <Form.Input field="rollNumber" />
                      </div>
                    </div>
                    <div>
                      <div className={state.readOnly ? "label-margin" : ""}>
                        <Form.Input field="bankCountryCode" />
                      </div>
                    </div>
                    <div>
                      <div className={state.readOnly ? "label-margin" : ""}>
                        <Form.Input field="currencyAccount" required />
                      </div>
                    </div>
                    <div>
                      <div className={state.readOnly ? "label-margin" : ""}>
                        <Form.Input field="currencyInvoice" required />
                      </div>
                    </div>
                    <div>
                      <div className={state.readOnly ? "label-margin" : ""}>
                        <Form.Input field="currencyPayment" required />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </ContentPanel>
          </Form>
        );
      }}
    />
  );
};

export default SPVBankAccount;
