import React from "react";
import { ListRoles } from "features/../../../shared/features/Settings";
import { PageContent } from "@redriver/cinnamon";

const RoleListPage = () => (
  <PageContent>
    <ListRoles />
  </PageContent>
);

export default RoleListPage;
