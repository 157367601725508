import React from "react";
import { Grid } from "semantic-ui-react";
import { SidePanel, Form } from "@redriver/cinnamon";
import { FilterButton } from "features/../../../shared/components/buttons";

export const FieldNames = {
  RequiresApproval: "requiresApproval",
};

const booleanOptions = [
  { text: "Yes", value: true },
  { text: "No", value: false },
];

const UploadTemplateFilters = ({ title, formProps, onReset }) => {
  return (
    <SidePanel
      closeIcon
      trigger={<FilterButton />}
      className="price-increase-filter-side-panel"
    >
      <div>
        <div className="ui header filters-title">
          <div className="title-text">{title}</div>
          <div className="clear-button" onClick={onReset}>
            (clear)
          </div>
        </div>
        <Form {...formProps} className="highlighted-inputs">
          <div className="ui form highlighted-inputs">
            <Grid columns={2}>
              <Grid.Column width={16}>
                <Form.Checkbox
                  field={FieldNames.RequiresApproval}
                  label="Requires approval"
                  options={booleanOptions}
                />
              </Grid.Column>
            </Grid>
          </div>
        </Form>
      </div>
    </SidePanel>
  );
};

export default UploadTemplateFilters;
