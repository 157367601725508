import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { setupApp } from "./setup";
import Shell from "./pages/Shell";
import { OnlineCheck } from "features/../../../shared/components/navigator";

const { store } = setupApp();

const App = () => (
  <Provider store={store}>
    <OnlineCheck />
    <BrowserRouter>
      <Shell />
    </BrowserRouter>
  </Provider>
);

createRoot(document.getElementById("root")).render(<App />);
