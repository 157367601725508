import React, { useState } from "react";
import DealerDetailsTabs from "./DealerDetailsTabs";
import { getDealerHeader, DealerStatusDropdown } from "./actions";
import { ContentPanel } from "components/displays";
import { Form, PageContent, ContentBuilder } from "@redriver/cinnamon";
import DealerRequestEmails from "./DealerRequestEmails";
import DealerAddendumDocumentNumbers from "./DealerAddendumDocumentNumbers";

const DealerDetails = ({ dealerId }) => {
  const [headerDetailsTimeStamp, setHeaderDetailsTimeStamp] = useState(
    Date.now(),
  );

  return (
    <div>
      <ContentBuilder
        loadAction={getDealerHeader}
        loadParams={{ id: dealerId, headerDetailsTimeStamp }}
        dataTransform={(data) => data._data}
        renderContent={(data) => (
          <React.Fragment>
            <div>
              <label className="header-text">
                Supplier Legal Entity: {data.name}
              </label>
            </div>
            <ContentPanel
              title="Supplier Details"
              wrapperCssClass="outline-grid supplier-header"
              headerCssClass="defaults-page-title"
            >
              <div className="padded">
                <DealerHeader data={data}></DealerHeader>
              </div>
            </ContentPanel>
          </React.Fragment>
        )}
      ></ContentBuilder>

      <DealerDetailsTabs
        id={dealerId}
        onSubmitted={() => {
          setHeaderDetailsTimeStamp(Date.now());
        }}
      />
      <DealerRequestEmails id={dealerId} />

      <DealerAddendumDocumentNumbers id={dealerId} />
    </div>
  );
};

const DealerHeader = ({ data }) => {
  return (
    <PageContent fluid className="dealer-header">
      <Form value={data} disabled className="dealer-header">
        <div className="dealer-form-header">
          <div className="flex">
            <label>Legal Entity Name:</label>
            <Form.Input field="name" />
          </div>
          <div className="flex">
            <label>Code:</label>
            <Form.Input field="code" />
          </div>
          <div className="flex">
            <label>Timezone:</label>
            <Form.Input field="timezone" />
          </div>
          <div className="flex">
            <label>Status:</label>
            <DealerStatusDropdown field="status" />
          </div>
        </div>

        <div className="dealer-form-header">
          <div className="hq-flex">
            <label>HQ Address:</label>
            <div className="hq">
              <Form.Input field="hqAddress" />
            </div>
          </div>
        </div>
      </Form>
    </PageContent>
  );
};

export default DealerDetails;
