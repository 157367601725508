import { apiPut } from "@redriver/cinnamon";
import { PaymentMethodAvailability } from "features/../../../shared/constants/enums";

const NAMESPACE = "SETTINGS/RESTRICTIONS/PAYMENT_METHOD";

const ActionTypes = {
  UpdatePaymentMethodRestrictions: `${NAMESPACE}/UPDATE`,
};

export const updatePaymentMethodRestrictions = (formData, { settingsId }) =>
  apiPut(
    ActionTypes.UpdatePaymentMethodRestrictions,
    `defaults-restrictions/${settingsId}`,
    {
      paymentMethod: {
        ...formData,
        availableToDealer:
          formData.availableToDealer === PaymentMethodAvailability.IpsAndDealer,
      },
    }
  );
