import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";

import { editDealerStructure } from "./actions";
import DealerStructureForm from "./DealerStructureForm";

const EditDealerStructure = ({ data, onRefresh }) => (
  <ModalBuilder
    withForm
    submitAction={editDealerStructure}
    submitParams={data.id}
    initialData={data}
    onSubmitted={onRefresh}
    renderTrigger={(showModal) => (
      <Button
        icon="pencil alternate"
        className="delete-icon-btn"
        color="blue"
        onClick={showModal}
      />
    )}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit {...modalProps} header="Update Territory" submitLabel="Save">
        <DealerStructureForm {...formProps} fullCrmCode={data.fullCrmCode} />
      </Modal.Edit>
    )}
  />
);

export default EditDealerStructure;
