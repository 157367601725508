import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "PRODUCTS";

export const ActionTypes = {
  GetProductDetails: `${NAMESPACE}/GET_PRODUCT_DETAILS`,
  ClearProductDetails: `${NAMESPACE}/CLEAR_PRODUCT_DETAILS`,
};

const mockProduct = {
  productInformation: {
    systemId: "4567",
    identifierNumber: "LW84411952",
    manufacturer: "KYOCERA",
    model: "P6026CDN",
    type: "SYSTEM",
    status: "Live",
    currentContractRef: "A1B73749/001",
    taxCode: "UK",
    rrp: 3500.0,
    installDate: "29-Jan-2015",
    expiryDate: "",
    initialContractRef: "A1B73749/001",
    titleHolder: "PRINCIPAL",
    securityHolder: "",
    cfaReference: "N/A",
    funder: "PRINCIPAL",
    ipsInsurance: "N/A",
    notes: "",
    inStorage: "Yes",
  },
  physicalLocation: {
    accountId: "12345",
    company: "Test Company LLP",
    department: "",
    address1: "Big Red House",
    address2: "Personage Business Park",
    address3: "",
    townCity: "Horsham",
    county: "West Sussex",
    postalCode: "RH12 4AL",
    country: "UK",
    phone: "01403 280280",
    email: "products@principal.co.uk",
  },
  meterReadings: {
    blackTotal: 267624,
    colourTotal: 295473,
    scanTotal: 61535,
    total: 624632,
  },
};

export const getProductDetails = () => async () => {
  return mockSuccess(ActionTypes.GetProductDetails, {
    delay: 100,
    response: mockProduct,
  });

  // const details = await dispatch(
  //   apiGet(ActionTypes.GetProductDetails, `products/${id}`)
  // );

  // return details.response;
};

export const clearProductDetails = () => ({
  type: ActionTypes.ClearProductDetails,
});
