import React from "react";
import { ListBuilder, Table, ActionBar } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { getTargetMarket } from "./actions";
import AddTargetMarket from "./AddTargetMarket";
import Add from "features/../../../shared/assets/white-add.svg";
import TargetMarketActions from "./TargetMarketActions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { useTranslation } from "react-i18next";

const ListTargetMarkets = ({ dealerOfficeId }) => {
  const { t } = useTranslation("supplierOffices", {
    keyPrefix: "largeFormat.targetMarket",
  });
  return (
    <PermissionCheck target={Targets.LargeFormat} action={Actions.View}>
      <React.Fragment>
        <h4 className="settings-title">{t("title")}</h4>
        <ListBuilder
          withTable
          initialPagination={{ pageSize: 25, pageNumber: 1 }}
          loadAction={getTargetMarket}
          loadParams={{ dealerOfficeId }}
          dataTransform={data => data.results}
          totalTransform={data => data.totalResults}
          renderList={(tableProps, state, events) => (
            <div className="content-container principal-list">
              <div className="action-bar-div">
                <ActionBar.Item>
                  <AddTargetMarket
                    primary
                    dealerOfficeId={{ dealerOfficeId }}
                    icon={Add}
                    content={t("addbtn")}
                    onAdded={events.onRefresh}
                  />
                </ActionBar.Item>
              </div>
              <Table {...tableProps} dataKey="id">
                <Table.Column field="name" title={t("name")} width="" />

                <Table.Column
                  hoverable
                  width=""
                  align="right"
                  render={targetMarket => (
                    <TargetMarketActions
                      dealerOfficeId={dealerOfficeId}
                      targetMarket={targetMarket}
                      onRefresh={events.onRefresh}
                    />
                  )}
                />
              </Table>
              <TablePagination
                pageNumber={state.pagination.pageNumber}
                onChange={events.onChangePagination}
                pageSize={state.pagination.pageSize}
                totalItems={state.total}
              />
            </div>
          )}
        />
      </React.Fragment>
    </PermissionCheck>
  );
};

export default ListTargetMarkets;
