import React from "react";
import { Table, TableRow } from "semantic-ui-react";
import { getFinances } from ".";
import { ListBuilder } from "@redriver/cinnamon";
import { numericFormatter } from "components/displays/DisplayHelper";
import { SortableHeader } from "../../../../../../shared/components/tables/sortableTable";

export const Finances = ({ isSummary }) => {
  const headers = [
    { field: "cfaRef", title: "CFA Reference" },
    { field: "payableTo", title: "Payable To" },
    { field: "funderType", title: "Funder Type", display: !isSummary },
    {
      field: "cfaPayment",
      title: `CFA Payment (${process.env.MAJOR_CURRENCY})`,
    },
    {
      field: "cfaOutstanding",
      title: `Total CFA Outstanding (${process.env.MAJOR_CURRENCY})`,
      display: isSummary,
    },
    {
      title: "",
      className: "highlight",
      key: 1,
      display: isSummary,
      sortable: false,
    },
    {
      field: "supplier",
      title: `Supplier (${process.env.MAJOR_CURRENCY})`,
      display: isSummary,
    },
    {
      field: "funder",
      title: `Funder (${process.env.MAJOR_CURRENCY})`,
      display: isSummary,
    },
    {
      field: "ips",
      title: `IPS (${process.env.MAJOR_CURRENCY})`,
      display: isSummary,
    },
    {
      title: "",
      className: "highlight",
      key: 2,
      display: isSummary,
      sortable: false,
    },
    { title: "", className: "empty", key: 3, sortable: false },
    {
      field: "liability",
      title: `Total Liability Values (${process.env.MAJOR_CURRENCY})`,
      display: isSummary,
    },
    {
      title: "",
      className: "empty",
      key: 4,
      display: isSummary,
      sortable: false,
    },
    {
      field: "settlement",
      title: `Custom Settlement Values (${process.env.MAJOR_CURRENCY})`,
      className: "yellow-highlight",
      display: isSummary,
    },
    {
      field: "scheduleSettlementPercent",
      title: "%",
      className: "red-highlight",
      display: !isSummary,
    },
    {
      field: "scheduleSettlement",
      title: `${process.env.MAJOR_CURRENCY}`,
      className: "red-highlight square",
      display: !isSummary,
    },
  ];

  return (
    <ListBuilder
      loadAction={getFinances}
      renderList={(data, state, events) =>
        data.fa && (
          <Table className={"right-align liability-finances"}>
            <Table.Header>
              {isSummary ? (
                <React.Fragment>
                  <TableRow>
                    <Table.HeaderCell
                      className="empty"
                      colSpan={12}
                    ></Table.HeaderCell>
                    <Table.HeaderCell
                      className="yellow-highlight ap-dt"
                      colSpan={1}
                    >
                      Applicable from: <br />
                      <b>03-Mar-21</b>
                    </Table.HeaderCell>
                  </TableRow>
                  <TableRow>
                    <Table.Cell
                      className="empty no-top-border"
                      colSpan={4}
                    ></Table.Cell>
                    <Table.Cell className="highlight" colSpan={5}>
                      <span>Continuing Payment Outstanding</span>
                    </Table.Cell>
                    <Table.Cell
                      className="empty  no-top-border"
                      colSpan={2}
                    ></Table.Cell>
                  </TableRow>
                  <Table.Row></Table.Row>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <TableRow>
                    <Table.Cell
                      className="empty border-right"
                      colSpan={5}
                    ></Table.Cell>
                    <Table.HeaderCell
                      className="red-highlight settlement-title"
                      colSpan={2}
                    >
                      Schedule Settlement
                    </Table.HeaderCell>
                  </TableRow>
                </React.Fragment>
              )}
            </Table.Header>

            <SortableHeader columns={headers} state={state} events={events} />
            <Table.Body>
              {data.fa.map((d) => (
                <TableRowFinances key={d.id} data={d} isSummary={isSummary} />
              ))}
              {!isSummary && (
                <React.Fragment>
                  <EmptyTableRow />
                  <TableRowFinances
                    isSummary={isSummary}
                    data={{
                      cfaRef: "Calc Rounding",
                      payableTo: "IP Syndicate",
                      funderType: "",
                      cfaPayment: data.calcRoundingCFAPayment,
                      scheduleSettlementPercent:
                        data.calcRoundingScheduleSettlementPercent,
                      scheduleSettlement: data.calcRoundingScheduleSettlement,
                    }}
                  />
                </React.Fragment>
              )}
              <Table.Row className="slim">
                <Table.Cell className="empty" colSpan={4}></Table.Cell>
                {isSummary && (
                  <React.Fragment>
                    <Table.Cell className="highlight" colSpan={5}></Table.Cell>
                    <Table.Cell className="empty" colSpan={2}></Table.Cell>
                  </React.Fragment>
                )}
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  className="empty"
                  colSpan={isSummary ? 2 : 3}
                ></Table.Cell>
                <Table.Cell className="">
                  {numericFormatter(data.cfaPaymentTotal, "-")}
                </Table.Cell>

                {isSummary && (
                  <React.Fragment>
                    <Table.Cell>
                      {numericFormatter(data.cfaOutstandingTotal, "-")}
                    </Table.Cell>
                    <Table.Cell className="highlight"></Table.Cell>
                    <Table.Cell>
                      {numericFormatter(data.supplierTotal, "-")}
                    </Table.Cell>
                    <Table.Cell>
                      {numericFormatter(data.funderTotal, "-")}
                    </Table.Cell>
                    <Table.Cell>
                      {numericFormatter(data.ipsTotal, "-")}
                    </Table.Cell>
                    <Table.Cell className="highlight"></Table.Cell>
                    <Table.Cell className="empty"></Table.Cell>
                    <Table.Cell>
                      {numericFormatter(data.liabilityTotal, "-")}
                    </Table.Cell>
                    <Table.Cell className="empty"></Table.Cell>
                    <Table.Cell>
                      {numericFormatter(data.settlementTotal, "-")}
                    </Table.Cell>
                  </React.Fragment>
                )}
                {!isSummary && (
                  <React.Fragment>
                    <Table.Cell className="empty" colSpan={2}></Table.Cell>
                    <Table.Cell>
                      {numericFormatter(data.scheduleSettlementTotal, "-")}
                    </Table.Cell>
                  </React.Fragment>
                )}
              </Table.Row>
            </Table.Body>
          </Table>
        )
      }
    ></ListBuilder>
  );
};

const TableRowFinances = ({ data, isSummary }) => (
  <Table.Row>
    <Table.Cell style={{ width: "13%" }} className="left-align">
      {data.cfaRef}
    </Table.Cell>
    <Table.Cell style={{ width: "13%" }} className="left-align">
      {data.payableTo}
    </Table.Cell>
    {!isSummary && (
      <Table.Cell style={{ width: "13%" }} className="left-align">
        {data.funderType}
      </Table.Cell>
    )}
    <Table.Cell style={{ width: "10%" }}>
      {numericFormatter(data.cfaPayment, "-")}
    </Table.Cell>
    {isSummary ? (
      <React.Fragment>
        <Table.Cell style={{ width: "10%" }}>
          {numericFormatter(data.cfaOutstanding, "-")}
        </Table.Cell>
        <Table.Cell style={{ width: "1px" }} className="highlight"></Table.Cell>
        <Table.Cell style={{ width: "8%" }}>
          {numericFormatter(data.supplier, "-")}
        </Table.Cell>
        <Table.Cell style={{ width: "8%" }}>
          {numericFormatter(data.funder, "-")}
        </Table.Cell>
        <Table.Cell style={{ width: "8%" }}>
          {numericFormatter(data.ips, "-")}
        </Table.Cell>
        <Table.Cell style={{ width: "1px" }} className="highlight"></Table.Cell>
        <Table.Cell style={{ width: "5%" }} className="empty"></Table.Cell>
        <Table.Cell style={{ width: "11%" }}>
          {numericFormatter(data.liability, "-")}
        </Table.Cell>
        <Table.Cell style={{ width: "2%" }} className="empty"></Table.Cell>
        <Table.Cell style={{ width: "11%" }}>
          {numericFormatter(data.settlement, "-")}
        </Table.Cell>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Table.Cell className="empty" style={{ width: "20%" }}></Table.Cell>
        <Table.Cell style={{ width: "10%" }}>
          {numericFormatter(data.scheduleSettlementPercent, 0, 0)}
        </Table.Cell>
        <Table.Cell style={{ width: "10%" }}>
          {numericFormatter(data.scheduleSettlement, "-")}
        </Table.Cell>
      </React.Fragment>
    )}
  </Table.Row>
);

const EmptyTableRow = () => (
  <Table.Row>
    <Table.Cell>
      <br />
    </Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell className="empty"></Table.Cell>
    <Table.Cell></Table.Cell>
    <Table.Cell></Table.Cell>
  </Table.Row>
);
