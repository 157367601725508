import React from "react";
import { Customers } from ".";
import { PageContent } from "@redriver/cinnamon";
import { BreadcrumbBar } from "pages/nav";

const CustomersListPage = () => (
  <PageContent fluid className="companies-container">
    <BreadcrumbBar />
    <Customers />
  </PageContent>
);

export default CustomersListPage;
