import React from "react";
import ListRollWidths from "features/../../../shared/features/Settings/LargeFormat/RollWidth/ListRollWidths";

const RollWidthSettings = ({ dealerOfficeId }) => {
  return (
    <React.Fragment>
      <ListRollWidths dealerOfficeId={dealerOfficeId} />
    </React.Fragment>
  );
};

export default RollWidthSettings;
