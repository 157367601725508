import React from "react";
import { ContentBuilder, Format } from "@redriver/cinnamon";
import { connect } from "react-redux";
import {
  getScheduleDataMasterAgreementState,
  getScheduleDataState,
} from "features/Schedules/selectors";
import { getScheduleSummaryHeader } from "./actions";
import { numericFormatter } from "components/displays/DisplayHelper";

const SummaryHeader = ({ scheduleNumber }) => {
  return (
    <ContentBuilder
      loadAction={getScheduleSummaryHeader}
      renderContent={(data) => (
        <div className="schedule-summary-container">
          <h3>{"Schedule " + scheduleNumber} </h3>
          <div className="content-panel schedules-list">
            <div className="schedule-summary-primary">
              <div className="summary-primary-top-row">
                <div className="schedule-summary-primary-data">
                  <div className="bold large-text">CUSTOMER:</div>
                  <div className="schedule-summary-primary-value">
                    {data.customerName}
                  </div>
                </div>
                <div className="schedule-summary-primary-data">
                  <div className="bold large-text">Co Reg Number:</div>
                  <div className="schedule-summary-primary-value">
                    {data.companyRegistrationNumber}
                  </div>
                </div>
                <div className="schedule-summary-primary-data">
                  <div className="bold large-text">COMPANY ID:</div>
                  <div className="schedule-summary-primary-value">
                    {data.companyId}
                  </div>
                </div>
              </div>
              <div className="schedule-summary-primary-data">
                <div className="bold large-text">Trading Address:</div>
                <div className="schedule-summary-primary-value large">
                  {data.tradingAddress}
                </div>
              </div>
            </div>
            <div className="schedule-summary-secondary-info-container">
              <div className="secondary-data-container">
                <div className="header bold">Supplier ID:</div>
                <div className="secondary-value">{data.supplierId}</div>
              </div>
              <div className="secondary-data-container">
                <div className="header bold">Supplier:</div>
                <div className="secondary-value">{data.supplierName}</div>
              </div>
              <div className="secondary-data-container">
                <div className="header bold">Account Manager:</div>
                <div className="secondary-value">{data.accountManager}</div>
              </div>
              <div className="secondary-data-container">
                <div className="header bold">SPV:</div>
                <div className="secondary-value">{data.spv}</div>
              </div>
            </div>
            <div className="schedule-summary-tertiary-info-container">
              <div className="schedule-summary-block-header">
                <div className="large-width">Schedule Reference</div>
                <div className="medium-width">Status</div>
                <div className="medium-width">Schedule Start Date</div>
                <div className="medium-width">Total Managed Volume</div>
                <div className="medium-width">Remaining Managed Volume</div>
                <div className="medium-width">Quarterly Managed Volume</div>
                <div className="medium-width">Volume Remaining (%)</div>
                <div className="medium-width">
                  Schedule Liability Value ({process.env.MAJOR_CURRENCY})
                </div>
                <div className="medium-width">Schedule Discount (%)</div>
                <div className="medium-width">Next Minimum Due Date</div>
                <div className="medium-width">
                  Next Minimum Invoice Value ({process.env.MAJOR_CURRENCY})
                </div>
                <div className="medium-width">Next Meter Due Date</div>
              </div>
              <div className="schedule-summary-block-values">
                <div className="large-width">{data.scheduleReference}</div>
                <div className="medium-width">{data.status}</div>
                <div className="medium-width">
                  <Format.Date
                    value={data.scheduleStartDate}
                    format="DD MMM YYYY"
                  />
                </div>
                <div className="medium-width">
                  {numericFormatter(data.totalManagedVolume, 0, 0)}
                </div>
                <div className="medium-width">
                  {numericFormatter(data.remainingManagedVolume, 0, 0)}
                </div>
                <div className="medium-width">
                  {numericFormatter(data.quarterlyManagedVolume, 0, 0)}
                </div>
                <div className="medium-width">
                  {numericFormatter(data.volumeRemainingPercentage, 0, 2)}
                </div>
                <div className="medium-width">
                  {numericFormatter(data.scheduleLiabilityValue, 0, 2)}
                </div>
                <div className="medium-width">
                  {numericFormatter(data.scheduleDiscountPercentage, 0, 2)}
                </div>
                <div className="medium-width">
                  <Format.Date
                    value={data.nextMinimumDueDate}
                    format="DD MMM YYYY"
                  />
                </div>
                <div className="medium-width">
                  {numericFormatter(data.nextMinimumInvoiceValue, 0, 2)}
                </div>
                <div className="medium-width">
                  <Format.Date
                    value={data.nextMeterDueDate}
                    format="DD MMM YYYY"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  const {
    scheduleNumber,
    clientName,
    dealerOfficeName,
    totalManagedVolume,
    volumeUsed,
  } = getScheduleDataState(state);
  const { agreementNumber } = getScheduleDataMasterAgreementState(state) || {};
  return {
    scheduleNumber,
    clientName,
    dealerOfficeName,
    totalManagedVolume,
    volumeUsed,
    agreementNumber,
  };
};

export default connect(mapStateToProps, null)(SummaryHeader);
