import React, { useState } from "react";
import { ListBuilder, Table, FormBuilder, ActionBar } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { getPriceIncrease } from "./actions";
import { numericFormatter } from "components/displays/DisplayHelper";
import { ContentPanel } from "components/displays";
import { TablePagination } from "components/pagination";
import { PriceIncreaseFilters, PriceIncreaseFilterDisplay } from ".";
import { TextSearch } from "features/../../../shared/components/search";
import { SortHeader } from "../../../../../shared/components/tables/sortableTable";

const PriceIncrease = () => {
  const [selectedItems, setSelected] = useState({});

  return (
    <ListBuilder
      withTable
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      loadAction={getPriceIncrease}
      loadParams={{}}
      renderList={(tableProps, state, events) => (
        <ContentPanel
          title="Price Increase"
          wrapperCssClass="outline-grid schedule-price-increase"
          headerCssClass="defaults-page-title"
          headerContent={<FilterBar state={state} events={events}></FilterBar>}
        >
          <div className="spaced">
            <React.Fragment>
              <div className="horizontal-scrollbar">
                <Table
                  {...tableProps}
                  dataKey="id"
                  emptyMessage="No price increases found"
                  selectable
                >
                  <Table.SelectableColumn
                    value={selectedItems}
                    width="2%"
                    header="page"
                    onChange={setSelected}
                    totalItems={state.total}
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="customerId"
                        title="Customer ID"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="customerId"
                    width="3%"
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="customer"
                        title="Customer"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="customer"
                    width="5%"
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="supplier"
                        title="Supplier"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="supplier"
                    width="5%"
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="supplierOffice"
                        title="Supplier Office"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="supplierOffice"
                    width="5%"
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="schedule"
                        title="Schedule"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="schedule"
                    width="5%"
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="applicableDate"
                        title="Applicable Date"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="applicableDate"
                    width="5%"
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="option"
                        title="Option"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="option"
                    width="5%"
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="methodology"
                        title="Methodology"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="methodology"
                    width="5%"
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="methodology"
                        title="Methodology"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="increase"
                    width="5%"
                    render={(item) => numericFormatter(item.increase, 0, 1)}
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="currentValue"
                        title={`Current Value (${process.env.MAJOR_CURRENCY})`}
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="currentValue"
                    width="5%"
                    render={(item) =>
                      numericFormatter(item.currentValue, "-", 2)
                    }
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="futureValue"
                        title={`Future Value (${process.env.MAJOR_CURRENCY})`}
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="futureValue"
                    width="5%"
                    render={(item) =>
                      numericFormatter(item.futureValue, "-", 2)
                    }
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="increaseValue"
                        title={`Increase Value (${process.env.MAJOR_CURRENCY})`}
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="increaseValue"
                    width="5%"
                    render={(item) =>
                      numericFormatter(item.increaseValue, "-", 2)
                    }
                  />
                </Table>
              </div>

              <TablePagination
                pageNumber={state.pagination.pageNumber}
                onChange={events.onChangePagination}
                pageSize={state.pagination.pageSize}
                totalItems={state.total}
              />
            </React.Fragment>

            <div className="price-increase-buttons">
              <Button className="ui primary button">
                Generate Price Increase Letter
              </Button>
              <Button className="ui primary button">
                Move Price Increase Date
              </Button>
              <Button className="ui primary button">
                Apply Price Increase
              </Button>
            </div>
          </div>
        </ContentPanel>
      )}
    />
  );
};

const FilterBar = ({ state, events }) => (
  <FormBuilder
    value={state.filters}
    onChange={events.onChangeFilters}
    renderForm={(formProps) => (
      <ActionBar className="filter-display">
        <ActionBar.Item>
          <TextSearch
            filters={state.filters}
            onChange={events.onChangeFilters}
          ></TextSearch>
        </ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item className="search-filter-display">
          <PriceIncreaseFilterDisplay
            filters={state.filters}
            onChange={events.onChangeFilters}
          />
        </ActionBar.Item>
        <ActionBar.Item fluid></ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Space />
        <ActionBar.Item>
          <PriceIncreaseFilters
            title="Filters"
            filters={state.filters}
            onChange={events.onChangeFilters}
            formProps={formProps}
            onReset={events.onReset}
          />
        </ActionBar.Item>
      </ActionBar>
    )}
  />
);

export default PriceIncrease;
