import React from "react";
import { ContentPanel } from "components/displays";
import { FunderSettings, FundersSummary, FunderDocuments, FunderCFA } from ".";
import FundersSteps from "constants/enums/FundersSteps";
import {
  ContentBuilder,
  PageContent,
  Wizard,
  Form,
  Format,
} from "@redriver/cinnamon";
import { Grid, GridColumn, GridRow, Menu } from "semantic-ui-react";
import { getFunderDetails } from "./actions";
import { RedirectBreadcrumbBar } from "pages/nav";
import { AppRoutes } from "constants/routes";
import { funderStatusDropdownValues } from "constants/enums";
import { ListFunderSignatories } from "./Signatories";

const FunderDetails = ({ funderId }) => {
  const NavigationControls = ({ steps, activeStep, onGoToStep }) => {
    return steps.map((s) => (
      <Menu.Item
        value={0}
        key={s.key}
        active={s === activeStep}
        onClick={() => onGoToStep(s.key)}
      >
        {s.title}
      </Menu.Item>
    ));
  };

  const stepData = [
    {
      key: FundersSteps.Summary,
      title: FundersSteps.Summary,
    },
    {
      key: FundersSteps.CFA,
      title: FundersSteps.CFA,
    },
    {
      key: FundersSteps.Documents,
      title: FundersSteps.Documents,
    },
    {
      key: FundersSteps.Settings,
      title: FundersSteps.Settings,
    },
    {
      key: FundersSteps.Signatories,
      title: FundersSteps.Signatories,
    },
  ];

  return (
    <PageContent fluid className="funder-details">
      <RedirectBreadcrumbBar to={AppRoutes.Funders} text="Back to Funders" />
      <ContentBuilder
        loadAction={getFunderDetails}
        loadParams={funderId}
        dataTransform={(data) => data._data}
        renderContent={(data, events) => {
          return (
            <React.Fragment>
              <ContentPanel
                title="Funder Details"
                wrapperCssClass="outline-grid yellow"
                headerCssClass="defaults-page-title yellow"
              >
                <div className="padded">
                  <DetailsForm data={data}></DetailsForm>
                </div>
              </ContentPanel>
              <div className="red-highlight right">
                {" "}
                Active Funder Since:{" "}
                <Format.Date value={data.startDate} format="DD MMM YYYY" />
              </div>
              <div className="clear"></div>
              <Wizard steps={stepData}>
                <Wizard.Nav
                  render={(wizardContext) => (
                    <Menu>
                      <NavigationControls {...wizardContext} />
                    </Menu>
                  )}
                />
                <Wizard.Step
                  step={FundersSteps.Summary}
                  render={() => (
                    <FundersSummary
                      funderId={funderId}
                      contentRefresh={events.onRefresh}
                    />
                  )}
                />
                <Wizard.Step
                  step={FundersSteps.CFA}
                  render={() => <FunderCFA />}
                />
                <Wizard.Step
                  step={FundersSteps.Documents}
                  render={() => <FunderDocuments funderId={funderId} />}
                />
                <Wizard.Step
                  step={FundersSteps.Settings}
                  render={() => <FunderSettings funderId={funderId} />}
                />
                <Wizard.Step
                  step={FundersSteps.Signatories}
                  render={() => <ListFunderSignatories funderId={funderId} />}
                />
              </Wizard>
            </React.Fragment>
          );
        }}
      ></ContentBuilder>
    </PageContent>
  );
};

const DetailsForm = ({ data }) => {
  return (
    <Form value={data} readOnly className="funder-details">
      <Grid columns={4}>
        <GridColumn
          mobile={16}
          tablet={16}
          computer={6}
          largeScreen={6}
          widescreen={6}
        >
          <Grid>
            <GridRow>
              <GridColumn
                mobile={7}
                tablet={6}
                computer={7}
                largeScreen={7}
                widescreen={5}
              >
                Funder Legal Entity Name:
              </GridColumn>
              <GridColumn
                mobile={9}
                tablet={10}
                computer={9}
                largeScreen={9}
                widescreen={10}
              >
                {" "}
                <Form.Input field="name" />
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
        <GridColumn
          mobile={16}
          tablet={16}
          computer={3}
          largeScreen={3}
          widescreen={3}
        >
          <Grid>
            <GridRow>
              <GridColumn
                mobile={7}
                tablet={7}
                computer={7}
                largeScreen={7}
                widescreen={7}
              >
                Funder Code:
              </GridColumn>
              <GridColumn
                mobile={5}
                tablet={3}
                computer={8}
                largeScreen={6}
                widescreen={5}
              >
                {" "}
                <Form.Input field="funderCode" />
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
        <GridColumn
          mobile={16}
          tablet={16}
          computer={3}
          largeScreen={3}
          widescreen={3}
        >
          <Grid>
            <GridRow>
              <GridColumn
                mobile={7}
                tablet={6}
                computer={7}
                largeScreen={11}
                widescreen={8}
              >
                Associated Suppliers:
              </GridColumn>
              <GridColumn
                mobile={5}
                tablet={3}
                computer={6}
                largeScreen={5}
                widescreen={4}
              >
                {" "}
                <Form.Input field="associatedSuppliers" />
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
        <GridColumn
          mobile={16}
          tablet={16}
          computer={4}
          largeScreen={4}
          widescreen={4}
        >
          <Grid>
            <GridRow>
              <GridColumn
                mobile={7}
                tablet={6}
                computer={7}
                largeScreen={9}
                widescreen={8}
              >
                Supplier Status:
              </GridColumn>
              <GridColumn
                mobile={5}
                tablet={3}
                computer={9}
                largeScreen={7}
                widescreen={7}
              >
                <Form.Dropdown
                  field="status"
                  options={funderStatusDropdownValues}
                />
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
      </Grid>
    </Form>
  );
};

export default FunderDetails;
