import { apiGet, apiPost, apiPut, apiDelete } from "@redriver/cinnamon";
import { BoilerPlateStatus } from "features/../../../shared/constants/enums";

const NAMESPACE = "ADMIN/LIST_DEALER_OFFICE_AUTH_SIGNATORIES",
  ActionTypes = {
    ListBoilerPlateTandCs: `${NAMESPACE}/GET_BOILERPLATE_TANCS`,
    GetBoilerPlateTandCs: `${NAMESPACE}/GET_BOILERPLATES_TANCS`,
    CreateBoilerPlateTandCs: `${NAMESPACE}/ADD_BOILERPLATE_TANCS`,
    UpdateBoilerPlateTandCs: `${NAMESPACE}/UPDATE_BOILERPLATE_TANCS`,
    DeleteBoilerPlateTandCs: `${NAMESPACE}/DELETE_BOILERPLATE_TANCS`,
    DownloadBoilerPlateTandCs: `${NAMESPACE}/DOWNLOAD_BOILERPLATES_TANCS`,
    ApproveBoilerPlateTandCs: `${NAMESPACE}/APPROVE_BOILERPLATES_TANCS`,
    MakeBoilerPlateTandCsLive: `${NAMESPACE}/MAKE_BOILERPLATES_TANCS_LIVE`,
  };

export const ListBoilerPlateTandCs = ({ filters, pagination }, { dealerId }) =>
  apiGet(
    ActionTypes.ListBoilerPlateTandCs,
    `dealers/${dealerId}/boiler-plate`,
    { dealerId, ...filters, ...pagination }
  );

export const getBoilerPlateTandCs = ({ dealerId, itemId }) =>
  apiGet(
    ActionTypes.GetBoilerPlateTandCs,
    `dealers/${dealerId}/boiler-plate/${itemId}`
  );

export const createBoilerPlateTandCs = (
  { templateUpload, ...data },
  { dealerId, uploadDoc }
) => {
  const templateUploadObject = !uploadDoc
    ? null
    : {
        name: templateUpload.name,
        content: uploadDoc.split(",")[1],
        size: templateUpload.size,
        contentType: templateUpload.type,
      };
  return apiPost(
    ActionTypes.CreateBoilerPlateTandCs,
    `dealers/${dealerId}/boiler-plate`,
    { ...data, dealerId, templateUpload: templateUploadObject }
  );
};

export const updateBoilerPlateTandCs = (
  { templateUpload, ...data },
  { dealerId, itemId, uploadDoc }
) => {
  const templateUploadObject = !uploadDoc
    ? null
    : {
        name: templateUpload.name,
        content: uploadDoc.split(",")[1],
        size: templateUpload.size,
        contentType: templateUpload.type,
      };
  return apiPut(
    ActionTypes.UpdateBoilerPlateTandCs,
    `dealers/${dealerId}/boiler-plate/${itemId}`,
    { ...data, dealerId, itemId, templateUpload: templateUploadObject }
  );
};

export const deleteBoilerPlateTandCs = ({ dealerId, itemId }) =>
  apiDelete(
    ActionTypes.DeleteBoilerPlateTandCs,
    `dealers/${dealerId}/boiler-plate/${itemId}`
  );

export const downloadBoilerPlateTandCs = ({ id }) =>
  apiGet(ActionTypes.DownloadBoilerPlateTandCs, `boiler-plate/${id}/download`);

export const approveBoilerPlateTandCs = ({ id }) =>
  apiPut(ActionTypes.ApproveBoilerPlateTandCs, `boiler-plate/${id}/approve`);

export const makeBoilerPlateTandCsLive = ({ id }) => {
  const status = BoilerPlateStatus.Live;
  return apiPut(
    ActionTypes.MakeBoilerPlateTandCsLive,
    `boiler-plate/${id}/status`,
    { status }
  );
};
