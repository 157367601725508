import React from "react";
import { ListBuilder, ActionBar, Table } from "@redriver/cinnamon";
import { getUsageTypes } from "./actions";
import EditUsageType from "./EditUsageType";
import RemoveUsageType from "./RemoveUsageType";
import { TablePagination } from "components/pagination";
import { Targets, Actions } from "constants/permissions";
import { PermissionCheck } from "../../../../components/auth";
import { useTranslation } from "react-i18next";

const ListUsageTypes = ({ dealerOfficeId }) => {
  const { t } = useTranslation("supplierOffices", {
    keyPrefix: "largeFormat.usageType",
  });

  return (
    <PermissionCheck target={Targets.LargeFormat} action={Actions.View}>
      <h4 className="settings-title">{t("title")}</h4>
      <ListBuilder
        withTable
        loadAction={getUsageTypes}
        loadParams={{ dealerOfficeId }}
        dataTransform={data => data.results}
        totalTransform={data => data.totalResults}
        renderList={(tableProps, state, events) => (
          <div className="content-container principal-list">
            <Table {...tableProps} dataKey="id">
              <Table.Column field="name" title={t("name")} />
              <Table.Column
                width=""
                hoverable
                align="right"
                render={usageTypeData => (
                  <Table.Menu>
                    <PermissionCheck
                      target={Targets.LargeFormat}
                      action={Actions.Edit}
                    >
                      <EditUsageType
                        data={usageTypeData}
                        onSubmitted={events.onRefresh}
                      />
                      <RemoveUsageType
                        data={usageTypeData}
                        onSubmitted={events.onRefresh}
                      />
                    </PermissionCheck>
                  </Table.Menu>
                )}
              />
            </Table>
            {state.pagination && (
              <TablePagination
                pageSize={state.pagination.pageSize}
                pageNumber={state.pagination.pageNumber}
                totalItems={state.total}
                onChange={events.onChangePagination}
              />
            )}
          </div>
        )}
      />
    </PermissionCheck>
  );
};

export default ListUsageTypes;
