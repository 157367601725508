import React, { useState } from "react";
import { Format } from "@redriver/cinnamon";
import { Icon, Table } from "semantic-ui-react";

export const yesNoFormatter = (value, singleDigit = false) => {
  return value === null
    ? "N/A"
    : value
    ? singleDigit
      ? "Y"
      : "Yes"
    : singleDigit
    ? "N"
    : "No";
};

export const trueFalseFormatter = (value) => {
  return value === null ? "N/A" : value ? "True" : "False";
};

export const blankDataFormatter = (value, nullValue) => {
  return value === null || value === "" ? nullValue : value;
};

export const ExpandableText = ({ text, charLimit }) => {
  const [expanded, setExpanded] = useState(false);
  const displayText =
    text.length > charLimit && expanded
      ? text
      : text.length > charLimit
      ? text.substring(0, charLimit) + "..."
      : text;
  return (
    <React.Fragment>
      {displayText}
      <br />
      {text.length > charLimit ? (
        <div className="center">
          <Icon
            name="dropdown"
            size="small"
            className="center"
            flipped={expanded ? "vertically" : null}
            onClick={() => setExpanded(!expanded)}
          />{" "}
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
};

export const SplitCellDisplay = ({ items, startNode, count }) => {
  let pos = 0;
  let tableCells = [];
  for (let i = 0; i < count; i++) {
    pos = i + startNode;
    tableCells.push(
      <Table.Cell key={i + "highlight"} className="highlight">
        {items && items.length > pos ? items[pos].item.toString() : <br />}
      </Table.Cell>
    );
  }
  return tableCells;
};

export const Header = ({ columns }) => (
  <Table.Header>
    <Table.Row>
      {columns.map(
        (c) =>
          (c.display == null || c.display) && (
            <Table.HeaderCell
              key={c.key || c.title}
              colSpan={c.colSpan}
              width={c.width}
              className={c.className}
            >
              {c.title}
            </Table.HeaderCell>
          )
      )}
    </Table.Row>
  </Table.Header>
);

export const numericFormatter = (
  value,
  zeroOverride = 0,
  decimalPlaces = 2,
  nullOverride = ""
) => {
  return value == 0 ? (
    zeroOverride == 0 ? (
      (0).toFixed(decimalPlaces)
    ) : (
      zeroOverride
    )
  ) : value == null ? (
    nullOverride
  ) : (
    <span className={value < 0 ? "red" : ""}>
      {value < 0 ? "(" : ""}
      <Format.Number
        value={Math.abs(value)}
        decimalPlaces={decimalPlaces}
        thousandSeparator
      />
      {value < 0 ? ")" : ""}
    </span>
  );
};

export const fractionalCurrencyFormatter = (
  value,
  type = process.env.MINOR_CURRENCY,
  decimalPlaces
) => {
  return (
    <span className={value < 0 ? "red" : ""}>
      {value < 0 ? "(" : ""}
      <Format.Number
        decimalPlaces={decimalPlaces ? decimalPlaces : { min: 0, max: 3 }}
        value={Math.abs(value)}
      />
      {type}
      {value < 0 ? ")" : ""}
    </span>
  );
};
export const currencyFormatter = (
  value,
  format = process.env.CURRENCY_CODE
) => {
  return (
    <span className={value < 0 ? "red" : ""}>
      {value < 0 ? "(" : ""}
      <Format.Number value={Math.abs(value)} format={format} />
      {value < 0 ? ")" : ""}
    </span>
  );
};

//Standard date format for Opal is (DD MMM YYYY)
export const dateFormatter = (value) => (
  <Format.Date value={value} format="DD MMM YYYY" />
);

export const TableDivider = () => (
  <Table.Row className="table-divider">
    <Table.Cell></Table.Cell>
    <Table.Cell></Table.Cell>
  </Table.Row>
);
