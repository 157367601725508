import React from "react";
import { ContentPanel } from "components/displays";

const AuditTrail = () => (
  <ContentPanel
    title="Audit Trail"
    wrapperCssClass="outline-grid "
    headerCssClass="defaults-page-title"
  >
    <div className="padded">
      <p>Content to go here</p>
    </div>
  </ContentPanel>
);

export default AuditTrail;
