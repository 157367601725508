import React from "react";
import { connect } from "react-redux";
import SummaryTableRows from "./SummaryTableRows";
import { getScheduleDataState } from "features/Schedules/selectors";
import { ContentPanel } from "components/displays";
import { numericFormatter } from "components/displays/DisplayHelper";

const ScheduleSummaryTable = ({
  totalManagedVolume,
  volumeUsed,
  creditVolume,
  financeApplicableVolume,
  warningVolume,
  earlyTerminationDiscount,
  principalPoundIncentive,
  poundsUsed,
}) => {
  return (
    <ContentPanel
      title="Schedule Summary"
      wrapperCssClass=" "
      headerCssClass="defaults-page-title"
    >
      <div className="spaced">
        {SummaryTableRows(
          "Total Managed Volume",
          numericFormatter(totalManagedVolume, 0, 0),
          "Finance Volume Remaining",
          "TBC",
          "",
          "SS"
        )}
        {SummaryTableRows(
          "Volume Used",
          numericFormatter(volumeUsed, 0, 0),
          "Warning Volume",
          numericFormatter(warningVolume, 0, 0),
          "",
          "SS"
        )}
        {SummaryTableRows(
          "Remaining Volume",
          numericFormatter(totalManagedVolume - volumeUsed, 0, 0),
          "Early Termination Discount",
          numericFormatter(earlyTerminationDiscount, 0, 0),
          "",
          "SS"
        )}
        {SummaryTableRows(
          "Credit Volume",
          numericFormatter(creditVolume, 0, 0),
          "Principal/Pounds/Incentive",
          numericFormatter(principalPoundIncentive, 0, 0),
          "",
          "SS"
        )}
        {SummaryTableRows(
          "Finance Applicable Volume",
          numericFormatter(financeApplicableVolume, 0, 0),
          "Pounds Used",
          numericFormatter(poundsUsed, 0, 0),
          "",
          "SS"
        )}
      </div>
    </ContentPanel>
  );
};

const mapStateToProps = (state) => {
  const {
    totalManagedVolume,
    volumeUsed,
    creditVolume,
    financeApplicableVolume,
    warningVolume,
    earlyTerminationDiscount,
    principalPoundIncentive,
    poundsUsed,
  } = getScheduleDataState(state);
  return {
    totalManagedVolume,
    volumeUsed,
    creditVolume,
    financeApplicableVolume,
    warningVolume,
    earlyTerminationDiscount,
    principalPoundIncentive,
    poundsUsed,
  };
};

export default connect(mapStateToProps, null)(ScheduleSummaryTable);
