import React from "react";
import { Wizard } from "@redriver/cinnamon";
import { Menu } from "semantic-ui-react";
import { MissingReads, Held, ToBeBilled } from "./AdditionalUnits";
import { ContentPanel } from "components/displays";

const AdditionalUnitSteps = {
  ToBeBilled: "To Be Billed",
  MissingReads: "Missing Reads",
  Held: "Held",
};

const AdditionalUnitsBilling = () => {
  const NavigationControls = ({ steps, activeStep, onGoToStep }) => {
    return steps.map((s) => (
      <Menu.Item
        value={0}
        key={s.key}
        active={s === activeStep}
        onClick={() => onGoToStep(s.key)}
      >
        {s.title}
      </Menu.Item>
    ));
  };

  const stepData = [
    {
      key: AdditionalUnitSteps.ToBeBilled,
      title: "To Be Billed",
    },
    {
      key: AdditionalUnitSteps.MissingReads,
      title: "Additional Units - Missing Reads",
    },
    {
      key: AdditionalUnitSteps.Held,
      title: "Additional Units - Held",
    },
  ];

  return (
    <ContentPanel
      title="Additional Units Billing"
      wrapperCssClass="outline-grid"
      headerCssClass="defaults-page-title"
    >
      <div className="customer-finance padded">
        <Wizard steps={stepData}>
          <div className="wizard-navigation additional-units horizontal-scrollbar">
            <Wizard.Nav
              render={(wizardContext) => (
                <Menu>
                  <NavigationControls {...wizardContext} />
                </Menu>
              )}
            />
          </div>
          <Wizard.Step
            step={AdditionalUnitSteps.ToBeBilled}
            render={() => <ToBeBilled />}
          />
          <Wizard.Step
            step={AdditionalUnitSteps.MissingReads}
            render={() => <MissingReads />}
          />
          <Wizard.Step
            step={AdditionalUnitSteps.Held}
            render={() => <Held />}
          />
        </Wizard>
      </div>
    </ContentPanel>
  );
};

export default AdditionalUnitsBilling;
