import { apiPost, apiGet, notifySuccess } from "@redriver/cinnamon";

const NAMESPACE = "DEALERS";
const ActionTypes = {
  getDealers: `${NAMESPACE}/GET_DEALERS`,
  createDealer: `${NAMESPACE}/CREATE_DEALER`,
};

export const getDealers = ({ filters, pagination }) => {
  let data = {
    ...filters,
    ...pagination,
  };
  return apiGet(ActionTypes.getDealers, "dealers", data);
};

export const createDealer = (request) => async (dispatch) => {
  let response = await dispatch(
    apiPost(ActionTypes.createDealer, "dealers", request)
  );
  response &&
    response.success &&
    dispatch(notifySuccess("Supplier created successfully"));
  return response;
};
