import React from "react";
import { getCfaDetails } from "./actions";
import { FormBuilder, Form } from "@redriver/cinnamon";
import { Grid, GridColumn } from "semantic-ui-react";

const Header = ({ showStatus = false }) => {
  return (
    <FormBuilder
      loadAction={getCfaDetails}
      renderForm={(formProps) => (
        <Form {...formProps}>
          <Grid>
            <GridColumn computer={5} largeScreen={3} widescreen={3}>
              <h3>CFA Reference</h3>
            </GridColumn>
            <GridColumn computer={10} largeScreen={4} widescreen={4}>
              <Form.Input field="reference" disabled />
            </GridColumn>
            <GridColumn computer={5} largeScreen={1} widescreen={1}>
              <h3>Funder</h3>
            </GridColumn>
            <GridColumn computer={10} largeScreen={4} widescreen={4}>
              <Form.Input field="funder" disabled />
            </GridColumn>
            {showStatus && (
              <React.Fragment>
                <GridColumn computer={5} largeScreen={1} widescreen={1}>
                  <h3>Status</h3>
                </GridColumn>
                <GridColumn computer={10} largeScreen={3} widescreen={3}>
                  <Form.Input field="status" disabled />
                </GridColumn>
              </React.Fragment>
            )}
          </Grid>
        </Form>
      )}
    />
  );
};

export default Header;
