import React from "react";
import { getProductMeter } from "./actions";
import { Table, FormBuilder, Form } from "@redriver/cinnamon";
import { numericFormatter } from "components/displays/DisplayHelper";

const RejectMeterReading = () => {
  return (
    <div className="reject-meter-reading center">
      <FormBuilder
        loadAction={getProductMeter}
        renderForm={(formProps) => (
          <Form {...formProps}>
            <Table data={formProps.value.items} Key="id">
              <Table.Column field="unit" title="UNIT" width="10%" />
              <Table.Column field="meterCode" title="Meter Code" width="30%" />
              <Table.Column
                render={(item) =>
                  numericFormatter(item.currentMeterReading, "-", 0)
                }
                title="Current Meter Reading"
                width="30%"
              />
              <Table.Column
                render={(item) => numericFormatter(item.newMeterUsage, "-", 0)}
                title="Meter Usage"
                width="30%"
              />
            </Table>
            <Form.TextArea className="flex" field="notes" label="Notes" />
          </Form>
        )}
      ></FormBuilder>
    </div>
  );
};

export default RejectMeterReading;
