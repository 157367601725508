import React from "react";
import { getProductMeter } from "./actions";
import { Table, FormBuilder, Form } from "@redriver/cinnamon";
import { numericFormatter } from "components/displays/DisplayHelper";

const AddAdjustment = () => {
  const onFormChange = (field, data, applyChanges, formProps) => {
    const { value, onChange } = formProps;

    let newFormData = applyChanges(value);
    const formInput = data[0];

    //update adjustment values
    newFormData.items = newFormData.items.map((el) =>
      el.id === formInput.id && el.meterCode !== "Total"
        ? { ...el, adjustment: +formInput.data - el.currentMeterReading }
        : el
    );

    //update total
    const index = newFormData.items.findIndex((el) => el.meterCode === "Total");
    newFormData.items[index] = {
      ...newFormData.items[index],
      adjustment: newFormData.items
        .filter((x) => x.meterCode != "Total")
        .reduce((a, v) => (a = a + v.adjustment), 0),
    };

    onChange(field, newFormData, () => applyChanges(newFormData));
  };

  return (
    <div className="meter-reading-form center">
      <FormBuilder
        loadAction={getProductMeter}
        renderForm={(formProps) => (
          <Form
            {...formProps}
            onChange={(field, data, applyChanges) =>
              onFormChange(field, data, applyChanges, formProps)
            }
          >
            <Form.ArrayTable
              field="items"
              arrayKey="id"
              propagateUpdates="always"
            >
              <Table.Column field="unit" title="UNIT" width="12%" />
              <Table.Column field="meterCode" title="Meter Code" width="22%" />
              <Table.Column
                render={(item) =>
                  numericFormatter(item.currentMeterReading, "-", 0)
                }
                title="Current Meter Reading"
                width="22%"
              />
              <Table.Column
                render={(item) =>
                  item.meterCode != "Total" ? (
                    <Form.Numeric formatValue field="editMeterReading" />
                  ) : (
                    numericFormatter(item.editMeterReading, 0, 0)
                  )
                }
                title="NEW Meter Reading"
                width="22%"
              />
              <Table.Column
                render={(item) => numericFormatter(item.adjustment, 0, 0)}
                title="Adjustment"
                width="22%"
              />
            </Form.ArrayTable>
            <Form.TextArea className="flex" field="notes" label="Notes" />
          </Form>
        )}
      ></FormBuilder>
    </div>
  );
};

export default AddAdjustment;
