import React from "react";
import LargeFormatPrinterSettings from "../../LargeFormatSettings/LargeFormatPrinterSettings";
import RollWidthSettings from "../../LargeFormatSettings/RollWidthSettings";
import TargetMarketSettings from "../../LargeFormatSettings/TargetMarketSettings";

const LargePaperFormatSettingsMain = ({ dealerOfficeId }) => (
  <React.Fragment>
    <h4 className="settings-title">Large Format Paper</h4>
    <div className="extra-settings-panel-top">
      <div className="extra-settings-panel-columns printer">
        <div className="content-panel">
          <div className="office-general-settings">
            <LargeFormatPrinterSettings dealerOfficeId={dealerOfficeId} />
          </div>
        </div>
      </div>
    </div>
    <div className="extra-settings-panel-top">
      <div className="extra-settings-panel-columns roll-width">
        <div className="content-panel">
          <div className="office-general-settings">
            <RollWidthSettings dealerOfficeId={dealerOfficeId} />
          </div>
        </div>
      </div>
      <div className="lf-column-splitter"></div>
      <div className="extra-settings-panel-columns">
        <div className="content-panel">
          <div className="office-general-settings">
            <TargetMarketSettings dealerOfficeId={dealerOfficeId} />
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default LargePaperFormatSettingsMain;
