import React from "react";
import { Table } from "@redriver/cinnamon";
import EditRollWidth from "./EditRollWidth";
import DeleteRollWidth from "./DeleteRollWidth";

const RollWidthActions = ({ rollWidth, dealerOfficeId, onRefresh }) => {
  return (
    <Table.Menu>
      <EditRollWidth
        dealerOfficeId={dealerOfficeId}
        rollWidth={rollWidth}
        onSubmitted={onRefresh}
        as={Table.MenuItem}
      />
      <DeleteRollWidth
        rollWidth={rollWidth}
        onSubmitted={onRefresh}
        as={Table.MenuItem}
      />
    </Table.Menu>
  );
};

export default RollWidthActions;
