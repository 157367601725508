import {
  apiGet,
  apiPut,
  apiPost,
  apiDelete,
  lookupReset,
} from "@redriver/cinnamon";
import { getRatesState } from "../selectors";
import { RateTypeDiscriminator } from "features/../../../shared/constants/enums";
import { debounce } from "lodash";
import {
  getLiquidityChargesByRateGroupId,
  getRates,
  getYieldRates,
} from "features/../../../shared/features/Settings";

const NAMESPACE = "SETTINGS/FINANCIAL/RATES";

export const ActionTypes = {
  CloneRateGroup: `${NAMESPACE}/CLONE_RATE_GROUP`,
  CanDeleteRateGroup: `${NAMESPACE}/CAN_DELETE_RATE_GROUP`,
  DeleteRateGroup: `${NAMESPACE}/DELETE_RATE_GROUP`,
  SetActiveTab: `${NAMESPACE}/SET_ACTIVE_TAB`,
  UpdateFilters: `${NAMESPACE}/UPDATE_FILTERS`,
  LockYields: `${NAMESPACE}/LOCK_YIELDS`,
  UnlockYields: `${NAMESPACE}/UNLOCK_YIELDS`,
  ClearRates: `${NAMESPACE}/CLEAR_RATES`,
};

const mapLiquidityCharges = charges =>
  Object.entries(charges).map(e => ({ name: e[0], values: e[1] }));

export const cloneRateGroup = (data, { rateGroupId }) =>
  apiPost(
    ActionTypes.CloneRateGroup,
    `defaults/rates/${rateGroupId}/clone`,
    data
  );

export const deleteRateGroup = ({ rateGroupId }) =>
  apiDelete(ActionTypes.DeleteRateGroup, `defaults/rates/${rateGroupId}`);

export const setActiveTab = tab => dispatch =>
  dispatch({ type: ActionTypes.SetActiveTab, tab });

export const updateFilters = (filters, refreshRates) => async (
  dispatch,
  getState
) => {
  const existingRates = getRatesState(getState());
  await dispatch({ type: ActionTypes.UpdateFilters, filters });

  if (refreshRates) {
    // called from Rates page
    await dispatch(getLiquidityChargesByRateGroupId(getRatesState));
    await dispatch(getRates(getRatesState));
  } else {
    // called from Yields page
    await dispatch(getYieldRates(getRatesState));
  }

  // Reset default table if chaning rate group;
  if (existingRates.filters.rateGroupId !== filters.rateGroupId) {
    dispatch({
      type: ActionTypes.SetActiveTab,
      tab: RateTypeDiscriminator.GrossSalesRate,
    });
  }
};

export const canDeleteRateGroup = ({ rateGroupId }) =>
  apiGet(
    ActionTypes.CanDeleteRateGroup,
    `defaults/rates/${rateGroupId}/can-delete`
  );

export const lockYields = rateGroupId =>
  apiPut(ActionTypes.LockYields, `defaults/yield-rates/${rateGroupId}/lock`);
export const unlockYields = rateGroupId =>
  apiPut(
    ActionTypes.UnlockYields,
    `defaults/yield-rates/${rateGroupId}/unlock`
  );

export const clearRates = () => dispatch =>
  dispatch({ type: ActionTypes.ClearRates });
