import React, { useState } from "react";
import ScheduleServicesList from "./ScheduleServicesList";
import {
  getScheduleAdditionalQuarterlyServices,
  getScheduleQuarterlyServices,
} from "./actions";
import { Button } from "semantic-ui-react";
import { ContentPanel } from "components/displays";

const ScheduleServices = () => {
  const [quarterly, setQuarterly] = useState(true);

  return (
    <React.Fragment>
      <ContentPanel
        title="Quarterly Services"
        wrapperCssClass="schedule-services-container outline-grid"
        headerCssClass="defaults-page-title"
        headerContent={
          <BtnNav quarterly={quarterly} setQuarterly={setQuarterly} />
        }
      >
        <div className="alternating-turquoise spaced">
          <ScheduleServicesList
            title="Quarterly Services"
            loadAction={getScheduleQuarterlyServices}
            needsHeader={true}
          />
        </div>
      </ContentPanel>

      <ContentPanel
        title="Additional Quarterly Services"
        wrapperCssClass="schedule-services-container outline-grid"
        headerCssClass="defaults-page-title"
      >
        <div className="alternating-purple spaced">
          <ScheduleServicesList
            title="Additional Quarterly Services"
            loadAction={getScheduleAdditionalQuarterlyServices}
            needsHeader={false}
          />
        </div>
      </ContentPanel>
    </React.Fragment>
  );
};

const BtnNav = ({ quarterly, setQuarterly }) => {
  return (
    <Button.Group floated="right">
      <Button positive={quarterly} onClick={() => setQuarterly(true)}>
        Quarterly
      </Button>
      <Button positive={!quarterly} onClick={() => setQuarterly(false)}>
        Monthly
      </Button>
    </Button.Group>
  );
};

export default ScheduleServices;
