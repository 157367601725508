// actions stuff here
import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/RESTRICTIONS/SETTLEMENT_PARAMETERS";

const ActionTypes = {
  GetSettlementParametersDefaults: `${NAMESPACE}/GET`,
  UpdateSettlementParametersDefaults: `${NAMESPACE}/UPDATE`,
};

export const getSettlementParametersDefaults = ({ settingsId }) =>
  apiGet(
    ActionTypes.GetSettlementParametersDefaults,
    `defaults-restrictions/settlement-parameters`,
    {
      settingsId,
    }
  );

export const updateSettlementParametersDefaults = (formData, { settingsId }) =>
  apiPut(
    ActionTypes.UpdateSettlementParametersDefaults,
    `defaults-restrictions/settlement-parameters/${settingsId}`,
    formData
  );
