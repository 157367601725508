import React, { useState } from "react";
import { ContentPanel } from "components/displays";
import { Button, Icon } from "semantic-ui-react";
import { ListBuilder } from "@redriver/cinnamon";
import { getProductMovement } from "./actions";
import classNames from "classnames";
import { SortableTable } from "../../../../../shared/components/tables/sortableTable";

const Movement = ({ productId }) => {
  const [tableExtended, setTableExtended] = useState(false);

  const columns = [
    { field: "transactionType", title: "Transaction Type", width: "15%" },
    { field: "quarterStartDate", title: "Quarter Start Date", width: "10%" },
    { field: "account", title: "Account", width: "15%" },
    { field: "companyName", title: "Company Name", width: "25%" },
    { field: "schedule", title: "Schedule", width: "25%" },
    { field: "transactionDate", title: "Transaction Date", width: "10%" },
  ];

  return (
    <ContentPanel
      title="Movement"
      wrapperCssClass="outline-grid "
      headerCssClass="defaults-page-title"
    >
      <div className="padded">
        <ListBuilder
          withTable
          loadAction={getProductMovement}
          loadParams={productId}
          renderList={(tableProps, state, events) => (
            <div
              className={classNames(
                "vertical-scrollbar",
                tableExtended ? "extended" : "retracted"
              )}
            >
              <SortableTable
                state={state}
                events={events}
                tableProps={tableProps}
                emptyMessage="No items found"
                columns={columns}
              />
            </div>
          )}
        />

        <div className="extension-icon">
          <Button onClick={() => setTableExtended(!tableExtended)}>
            {tableExtended ? (
              <Icon name="angle up" />
            ) : (
              <Icon name="angle down" />
            )}
          </Button>
        </div>
      </div>
    </ContentPanel>
  );
};

export default Movement;
