import React from "react";
import { ContentPanel } from "components/displays";

const Held = () => {
return(
        <ContentPanel title="Held"   wrapperCssClass="outline-grid" headerCssClass="defaults-page-title">
            <div className="padded">
                Content to go here
                </div>
        </ContentPanel>
)};

export default Held;