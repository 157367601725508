import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Grid, GridColumn, GridRow, Button } from "semantic-ui-react";
import { Form, FormBuilder, Format } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { useTranslation } from "react-i18next";
import { navigateGridForm } from "features/../../../shared/components/forms/formHelper";
import { getSPVSummary, updateSPVSummary } from "./actions";
import { SpvStatusDropdownLookup } from "./lookups";
import { ContentPanel } from "components/displays";
import { SpvStatusDescription } from "features/../../../shared/constants/enums/SpvStatus";

const SPVSummary = ({ spvId, onSubmitted }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [originalData, setOriginalData] = useState();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { success, response } = await dispatch(getSPVSummary(spvId));

      success && setOriginalData(response);
      setLoading(false);
    })();
  }, [spvId, dispatch]);

  useEffect(() => {
    resetData();
  }, [originalData, resetData]);

  const resetData = useCallback(() => {
    setFormData(originalData);
  }, [originalData]);

  const updateOriginalData = (formData) => {
    setOriginalData(formData);
  };

  return (
    <FormBuilder
      value={formData}
      onChange={(fd) => {
        setFormData(fd);
      }}
      initialReadOnly
      loading={loading}
      submitAction={updateSPVSummary}
      submitParams={spvId}
      onSubmitted={() => {
        updateOriginalData(formData);
        onSubmitted();
      }}
      renderForm={(formProps, state, events) => {
        if (!formProps.value) return null;

        return (
          <Form
            {...formProps}
            onKeyDown={(e) => {
              navigateGridForm(e, 2, 1, false);
            }}
          >
            <ContentPanel
              title="Summary"
              wrapperCssClass="outline-grid violet"
              headerCssClass="defaults-page-title violet"
              headerContent={
                <PermissionCheck target={Targets.SPVs} action={Actions.Edit}>
                  <div className="flex">
                    <IconButton
                      primary
                      icon={state.readOnly ? Pen : Save}
                      content={
                        state.readOnly
                          ? t("shared:buttons:edit")
                          : t("shared:buttons:save")
                      }
                      floated="right"
                      onClick={() =>
                        state.readOnly
                          ? events.onToggleReadOnly()
                          : events.onSubmit({ reset: ["readOnly"] })
                      }
                      className={
                        !state.readOnly ? "positive-title-action" : null
                      }
                    />
                    {!state.readOnly && (
                      <Button
                        className="cancel-title-action"
                        floated="right"
                        content={t("shared:buttons:cancel")}
                        onClick={() => {
                          resetData();
                          events.onReset();
                        }}
                      />
                    )}
                  </div>
                </PermissionCheck>
              }
            >
              <Grid className="spv-summary">
                <GridColumn
                  mobile={16}
                  tablet={16}
                  computer={10}
                  largeScreen={7}
                  widescreen={7}
                  floated="left"
                >
                  <Grid>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        <h3>Company Information</h3>
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        SPV Legal Entity Name:{" "}
                        <span className="required">*</span>
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {" "}
                        <Form.Input field="name" required />
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        SPV Short Name: <span className="required">*</span>
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {" "}
                        <Form.Input field="shortName" required />
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        SPV Code: <span className="required">*</span>
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {" "}
                        <Form.Input
                          field="spvId"
                          disabled={originalData?.spvId}
                          required
                        />
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        Jurisdiction: <span className="required">*</span>
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {" "}
                        <Form.Input field="jurisdiction" required />
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        Company Registration Number:
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {" "}
                        <Form.Input field="companyRegistrationNumber" />
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        VAT Registration Number:
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {" "}
                        <Form.Input field="vatRegistrationNumber" />
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        EIN Number:
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {" "}
                        <Form.Input field="einNumber" />
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        DUNS Number:
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {" "}
                        <Form.Input field="dunsNumber" />
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        Status: <span className="required">*</span>
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {" "}
                        {state.readOnly ? (
                          <span>
                            {SpvStatusDescription[formProps.value.status]}
                          </span>
                        ) : (
                          <SpvStatusDropdownLookup field="status" required />
                        )}
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        Start Date:
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {state.readOnly ? (
                          <Format.Date
                            value={formProps.value?.startDate}
                            format="DD MMM YYYY"
                          />
                        ) : (
                          <Form.Date local field="startDate" />
                        )}
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn
                        mobile={7}
                        tablet={6}
                        computer={7}
                        largeScreen={7}
                        widescreen={5}
                      >
                        End Date:
                      </GridColumn>
                      <GridColumn
                        mobile={9}
                        tablet={10}
                        computer={8}
                        largeScreen={9}
                        widescreen={10}
                      >
                        {state.readOnly ? (
                          <Format.Date
                            value={formProps.value?.endDate}
                            format="DD MMM YYYY"
                          />
                        ) : (
                          <Form.Date local field="endDate" />
                        )}
                      </GridColumn>
                    </GridRow>
                  </Grid>
                </GridColumn>
                <GridColumn
                  mobile={16}
                  tablet={16}
                  computer={10}
                  largeScreen={7}
                  widescreen={7}
                  floated="left"
                >
                  <Form.Object field="registeredAddress">
                    <Grid>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          <h3>Registered Address</h3>
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          Address Line 1:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="line1" />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          Address Line 2:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="line2" />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          Address Line 3:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="line3" />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          Town/City:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="town" />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          County:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="county" />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          Post Code:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="postcode" />
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <GridColumn
                          mobile={7}
                          tablet={6}
                          computer={7}
                          largeScreen={7}
                          widescreen={5}
                        >
                          Country:
                        </GridColumn>
                        <GridColumn
                          mobile={9}
                          tablet={10}
                          computer={8}
                          largeScreen={9}
                          widescreen={10}
                        >
                          {" "}
                          <Form.Input field="country" />
                        </GridColumn>
                      </GridRow>
                    </Grid>
                  </Form.Object>
                </GridColumn>
              </Grid>
            </ContentPanel>
          </Form>
        );
      }}
    />
  );
};

export default SPVSummary;
