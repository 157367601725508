import React from "react";
import {
  ActionBar,
  FormBuilder,
  ListBuilder,
  Format,
} from "@redriver/cinnamon";
import { getInvoices } from "../Invoices/actions";
import { ContentPanel } from "components/displays";
import { TextSearch } from "features/../../../shared/components/search";
import { currencyFormatter } from "components/displays/DisplayHelper";
import { SortableTable } from "../../../../../../shared/components/tables/sortableTable";

const CompanyInvoices = () => {
  const columns = [
    { field: "InvoiceNumber", title: "Invoice Number" },
    {
      field: "Date",
      title: "Date",
      render: (item) => <Format.Date value={item.Date} format="DD MMM YYYY" />,
    },
    { field: "ScheduleReference", title: "Schedule Reference" },
    { field: "Type", title: "Type", className: "has-total-row" },
    {
      field: "NetAmount",
      title: "Net Amount",
      className: "has-total-row",
      render: (item) =>
        currencyFormatter(item.NetAmount, process.env.CURRENCY_CODE),
    },
    {
      field: "GrossAmount",
      title: "Gross Amount",
      className: "has-total-row",
      render: (item) =>
        currencyFormatter(item.GrossAmount, process.env.CURRENCY_CODE),
    },
    {
      field: "OutstandingBalance",
      title: "Outstanding Balance",
      className: "has-total-row",
      render: (item) =>
        currencyFormatter(item.OutstandingBalance, process.env.CURRENCY_CODE),
    },
    { field: "DueDate", title: "Due Date" },
    { field: "Status", title: "Status" },
    { field: "DelinquentDays", title: "Delinquent Days" },
  ];

  return (
    <ListBuilder
      withTable
      loadAction={getInvoices}
      renderList={(tableProps, state, events) => (
        <ContentPanel
          title="Invoices"
          wrapperCssClass="outline-grid invoices-list"
          headerCssClass="defaults-page-title"
          headerContent={<SectionBar state={state} events={events} />}
        >
          <div className="padded horizontal-scrollbar invoices-list">
            <SortableTable
              state={state}
              events={events}
              tableProps={tableProps}
              emptyMessage="No invoices found"
              columns={columns}
              className="invoice-details-table"
            />
          </div>
        </ContentPanel>
      )}
    ></ListBuilder>
  );
};

const SectionBar = ({ state, events }) => (
  <FormBuilder
    renderForm={() => (
      <ActionBar className="search-filter-display">
        <ActionBar.Item>
          <TextSearch
            filters={state.filters}
            onChange={events.onChangeFilters}
          />
        </ActionBar.Item>
        <ActionBar.Item fluid></ActionBar.Item>
      </ActionBar>
    )}
  />
);

export default CompanyInvoices;
