import React from "react";
import { isNil } from "lodash";
import { Format } from "@redriver/cinnamon";
import { FieldNames } from "./PriceIncreaseFilters";

const Fields = [
  {
    value: FieldNames.Supplier,
    text: "Supplier",
    order: 1,
  },
  {
    value: FieldNames.SupplierOffice,
    text: "Supplier Office",
    order: 2,
  },
  {
    value: FieldNames.StartDate,
    text: "From",
    order: 3,
  },
  {
    value: FieldNames.EndDate,
    text: "To",
    order: 4,
  },
];

const FormatDisplay = (item) => {
  let value = item.value;

  switch (item.label) {
    case FieldNames.StartDate:
      return (
        <span>
          From: <Format.Date value={value} format="DD MMM YYYY" />
        </span>
      );
    case FieldNames.EndDate:
      return (
        <span>
          To: <Format.Date value={value} format="DD MMM YYYY" />
        </span>
      );
    default:
      return Fields.find((x) => x.value === item.label).text;
  }
};

const PriceIncreaseFilterDisplay = ({ filters, onChange }) => {
  const items = Fields.map((f) => ({
    label: f.value,
    value: filters[f.value],
  })).filter((x) => x.value != null);

  if (!items.length) return null;

  return (
    <div className="filter-display">
      {items
        .filter((i) => !isNil(i.value) && i.value != "")
        .map((item) => (
          <div className="filter-display-item" key={item.label}>
            {FormatDisplay(item)}
          </div>
        ))}
      <div
        onClick={() => {
          const nextFilters = {
            ...filters,
          };

          onChange(nextFilters);
        }}
        className="clear-button"
      >
        (clear)
      </div>
    </div>
  );
};

export default PriceIncreaseFilterDisplay;
