import React from "react";
import { ContentPanel } from "components/displays";
import {
  getCustomerSignatories,
  getHistoricCustomerSignatories,
} from "./actions";
import { Table, Format } from "@redriver/cinnamon";
import { Checkbox } from "semantic-ui-react";
import { InfoModalWrapper, AddModalWrapper } from "components/modals/";
import { SimpleTableBuilder } from "features/../../../shared/components/tables/sortableTable";
import { blankDataFormatter } from "components/displays/DisplayHelper";

const CustomerSignatories = () => {
  const customerSignatoriesColumns = [
    { field: "firstName", title: "First Name", width: "13%" },
    { field: "lastName", title: "Last Name", width: "13%" },
    {
      field: "prefix",
      title: "Prefix",
      width: "6%",
      render: (item) => blankDataFormatter(item.prefix, "-"),
    },
    { field: "position", title: "Position", width: "11%" },
    { field: "authorisedStatus", title: "Authorised Status", width: "11%" },
    { field: "telephone", title: "Telephone", width: "10%" },
    {
      field: "email",
      title: "Email",
      render: (item) => <a href={"mailto:" + item.email}>{item.email}</a>,
      width: "12%",
    },
    {
      field: "dtAdded",
      title: "Date Added",
      render: (item) => (
        <Format.Date value={item.dtAdded} format="DD MMM YYYY" />
      ),
      width: "8%",
    },
    {
      field: "master",
      title: "Master",
      render: (item) => <Checkbox defaultChecked={item.master} disabled />,
      width: "4%",
    },
    {
      field: "schedule",
      title: "Schedule",
      render: (item) => <Checkbox defaultChecked={item.schedule} disabled />,
      width: "4%",
    },
    {
      field: "capital",
      title: "Capital",
      render: (item) => <Checkbox defaultChecked={item.capital} disabled />,
      width: "4%",
    },
    {
      field: "directDebit",
      title: "Direct Debit",
      render: (item) => <Checkbox defaultChecked={item.directDebit} disabled />,
      width: "4%",
    },
    {
      field: "",
      title: "",
      render: (item) => <Menu id={item.id}></Menu>,
      width: "5%",
      sortable: false,
    },
  ];

  const historicCustomerSignatoriesColumns = [
    { field: "status", title: "Status", width: "8%" },
    { field: "firstName", title: "First Name", width: "12%" },
    { field: "lastName", title: "Last Name", width: "12%" },
    {
      field: "prefix",
      title: "Prefix",
      width: "7%",
      render: (item) => blankDataFormatter(item.prefix, "-"),
    },
    { field: "position", title: "Position", width: "10%" },
    { field: "authorisedStatus", title: "Authorised Status", width: "10%" },
    { field: "telephone", title: "Telephone", width: "11%" },
    {
      field: "email",
      title: "Email",
      render: (item) => <a href={"mailto:" + item.email}>{item.email}</a>,
      width: "13%",
    },
    {
      field: "dtAdded",
      title: "Date Added",
      render: (item) => (
        <Format.Date value={item.dtAdded} format="DD MMM YYYY" />
      ),
      width: "9%",
    },
    {
      field: "dtArchived",
      title: "Date Archived",
      render: (item) => (
        <Format.Date value={item.dtArchived} format="DD MMM YYYY" />
      ),
      width: "9%",
    },
  ];

  return (
    <ContentPanel
      title="Signatories"
      wrapperCssClass="outline-grid signatories-container"
      headerCssClass="defaults-page-title"
    >
      <div className="padded">
        <ContentPanel
          title="Authorised Signatories"
          wrapperCssClass="outline-grid"
          headerCssClass="defaults-page-title"
          headerContent={
            <AddModalWrapper title="Add New Signatory">
              Not Implemented
            </AddModalWrapper>
          }
        >
          <div className="padded">
            <SimpleTableBuilder
              loadAction={getCustomerSignatories}
              emptyMessage="No signatories found"
              columns={customerSignatoriesColumns}
            />
          </div>
        </ContentPanel>
        <div className="historic-btn-container">
          <InfoModalWrapper title="Historic">
            {" "}
            Not implemented.{" "}
          </InfoModalWrapper>
        </div>
        <ContentPanel
          title="Historic Signatories"
          wrapperCssClass="outline-grid"
          headerCssClass="defaults-page-title"
        >
          <div className="padded">
            <SimpleTableBuilder
              loadAction={getHistoricCustomerSignatories}
              emptyMessage="No signatories found"
              columns={historicCustomerSignatoriesColumns}
            />
          </div>
        </ContentPanel>
      </div>
    </ContentPanel>
  );
};

const Menu = () => (
  <Table.Menu>
    <Table.MenuItem text="Edit" />
    <Table.MenuItem text="Archive" />
  </Table.Menu>
);

export default CustomerSignatories;
