import React from "react";
import { Link } from "react-router-dom";
import {
  ActionBar,
  ListBuilder,
  FormBuilder,
  Format,
} from "@redriver/cinnamon";
import { getSPVList } from "./actions";
import { Table, TableCell } from "semantic-ui-react";
import { ContentPanel } from "components/displays";
import { TextSearch } from "features/../../../shared/components/search";
import { TablePagination } from "components/pagination";
import { PermissionCheck } from "features/../../../shared/components/auth";
import FilterSidePanel from "./FilterSidePanel";
import { SortHeader } from "../../../../shared/components/tables/sortableTable";
import AddSPV from "./AddSPV";
import { Targets, Actions } from "constants/permissions";
import { SpvRoutes } from "constants/routes";

const SPVsList = () => {
  return (
    <div className="spv-list">
      <ListBuilder
        debounceFilters={600}
        loadAction={getSPVList}
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        initialFilters={{
          search: "",
        }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        renderList={(data, state, events) => (
          <React.Fragment>
            <ContentPanel
              title="SPVs"
              wrapperCssClass="outline-grid spvs-list violet"
              headerCssClass="defaults-page-title violet"
              headerContent={
                <FilterBar
                  state={state}
                  events={events}
                  initialPageSize={25}
                  onAdded={events.onRefresh}
                ></FilterBar>
              }
            >
              <div className="spaced">
                <SPVTable state={state} events={events} data={data} />
                <br />
                <TablePagination
                  pageNumber={state.pagination.pageNumber}
                  onChange={events.onChangePagination}
                  pageSize={state.pagination.pageSize}
                  totalItems={state.total}
                />
              </div>
            </ContentPanel>
          </React.Fragment>
        )}
      />
    </div>
  );
};

const SPVTable = ({ data, state, events }) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <SortHeader
              field="name"
              title="Name"
              value={state.filters}
              onChange={events.onChangeFilters}
            />
          </Table.HeaderCell>
          <Table.HeaderCell>SPV Code</Table.HeaderCell>
          <Table.HeaderCell>Jurisdiction</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Start Date</Table.HeaderCell>
          <Table.HeaderCell>End Date</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data &&
          data.map((item) => (
            <Table.Row key={item.id}>
              <Table.Cell>
                <Link to={SpvRoutes.Details(item.id)}>{item.name} </Link>
              </Table.Cell>
              <Table.Cell>{item.spvId}</Table.Cell>
              <TableCell>{item.jurisdiction}</TableCell>
              <TableCell>{item.statusText}</TableCell>
              <TableCell>
                {item.startDate == null ? (
                  "-"
                ) : (
                  <Format.Date value={item.startDate} format="DD MMM YYYY" />
                )}
              </TableCell>
              <TableCell>
                {item.endDate == null ? (
                  "-"
                ) : (
                  <Format.Date value={item.endDate} format="DD MMM YYYY" />
                )}
              </TableCell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};

const FilterBar = ({ state, events }) => (
  <FormBuilder
    renderForm={(formProps) => (
      <ActionBar className="filter-display">
        <ActionBar.Item>
          <TextSearch
            filters={state.filters}
            onChange={events.onChangeFilters}
          ></TextSearch>
        </ActionBar.Item>
        <ActionBar.Space />
        <PermissionCheck target={Targets.SPVs} action={Actions.Create}>
          <ActionBar.Item align="right">
            <AddSPV onCreated={events.onRefresh} />
          </ActionBar.Item>
        </PermissionCheck>
        <ActionBar.Space />
        <ActionBar.Item>
          <FilterSidePanel formProps={formProps} />
        </ActionBar.Item>
      </ActionBar>
    )}
  />
);

export default SPVsList;
