import { apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/RESTRICTIONS/TIERED_TONER";

const ActionTypes = {
  UpdateTieredTonerRestrictions: `${NAMESPACE}/UPDATE`,
};

export const updateTieredTonerRestrictions = (formData, { settingsId }) =>
  apiPut(
    ActionTypes.UpdateTieredTonerRestrictions,
    `defaults-restrictions/${settingsId}`,
    {
      TieredToner: formData,
    }
  );
