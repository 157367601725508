import React, { useState } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { NavMenu, MenuBar } from "@redriver/cinnamon";
import { Popup } from "semantic-ui-react";
import {
  AppRoutes,
  AmethystRoute,
  FinanceRoutes,
} from "../../constants/routes";
import AccountItem from "./AccountItem";
import { withPermissions } from "features/../../../shared/components/auth";
import CompanyLogo from "./CompanyLogo";

/**
 * NOTE: Please keep options here in sync with ./MobileMainMenu.
 * This menu is rendered on non-mobile viewports
 */
const MainNav = ({ hideMenu }) => {
  const [companiesOpen, setCompaniesOpen] = useState(false);
  const [schedulesOpen, setSchedulesOpen] = useState(false);
  const [billingAndCollectionOpen, setBillingAndCollectionOpen] =
    useState(false);

  return (
    <NavMenu vertical={false} className="main-menu">
      {!hideMenu && (
        <React.Fragment>
          <NavMenu.Item exact link={AppRoutes.Root} className="logo">
            <CompanyLogo />
          </NavMenu.Item>

          <Popup
            flowing
            className="main-nav-popup"
            position="bottom center"
            on="click"
            open={companiesOpen}
            onClose={() => setCompaniesOpen(false)}
            trigger={
              <NavMenu.Item onClick={() => setCompaniesOpen(true)}>
                Companies
              </NavMenu.Item>
            }
            content={
              <div className="popup-menu-content main-nav-submenu">
                <NavMenu.Item
                  link={AppRoutes.Customers}
                  onClick={() => setCompaniesOpen(false)}
                >
                  Customers
                </NavMenu.Item>
                <NavMenu.Item
                  link={AppRoutes.Dealers}
                  onClick={() => setCompaniesOpen(false)}
                >
                  Suppliers
                </NavMenu.Item>
                <NavMenu.Item
                  link={AppRoutes.Funders}
                  onClick={() => setCompaniesOpen(false)}
                >
                  Funders
                </NavMenu.Item>
                <NavMenu.Item
                  link={AppRoutes.SPVs}
                  onClick={() => setCompaniesOpen(false)}
                >
                  SPVs
                </NavMenu.Item>
              </div>
            }
          />
          <Popup
            flowing
            className="main-nav-popup"
            position="bottom center"
            on="click"
            open={schedulesOpen}
            onClose={() => setSchedulesOpen(false)}
            trigger={
              <NavMenu.Item onClick={() => setSchedulesOpen(true)}>
                Schedules
              </NavMenu.Item>
            }
            content={
              <div className="popup-menu-content main-nav-submenu">
                <NavMenu.Item
                  link={AppRoutes.Schedules}
                  onClick={() => setSchedulesOpen(false)}
                >
                  Schedules
                </NavMenu.Item>
                <NavMenu.Item
                  link={AppRoutes.PriceIncrease}
                  onClick={() => setSchedulesOpen(false)}
                >
                  Price Increase
                </NavMenu.Item>
              </div>
            }
          />
          <NavMenu.Item link={AppRoutes.CFA}>CFA</NavMenu.Item>
          <NavMenu.Item link={AppRoutes.Products}>Products</NavMenu.Item>
          <NavMenu.Item link={AppRoutes.Meters}>Meters</NavMenu.Item>
          <Popup
            flowing
            className="main-nav-popup"
            position="bottom center"
            on="click"
            open={billingAndCollectionOpen}
            onClose={() => setBillingAndCollectionOpen(false)}
            trigger={
              <NavMenu.Item onClick={() => setBillingAndCollectionOpen(true)}>
                Billing & Collection
              </NavMenu.Item>
            }
            content={
              <div className="popup-menu-content main-nav-submenu">
                <NavMenu.Item
                  link={FinanceRoutes.Customer}
                  onClick={() => setBillingAndCollectionOpen(false)}
                >
                  Customer
                </NavMenu.Item>
                <NavMenu.Item
                  link={FinanceRoutes.Supplier}
                  onClick={() => setBillingAndCollectionOpen(false)}
                >
                  Supplier
                </NavMenu.Item>
                <NavMenu.Item
                  link={FinanceRoutes.Funder}
                  onClick={() => setBillingAndCollectionOpen(false)}
                >
                  Funder
                </NavMenu.Item>
              </div>
            }
          />
          <NavMenu.Item link={AppRoutes.Zircon}>Zircon</NavMenu.Item>
          <NavMenu.Item target="_blank" href={AmethystRoute.Root}>
            Calculator
          </NavMenu.Item>
          <NavMenu.Item link={AppRoutes.Dashboard}>Reports</NavMenu.Item>
          <NavMenu.Item link={AppRoutes.Settings}>Settings</NavMenu.Item>
          <NavMenu.Item link={AppRoutes.DataUploads}>Data Uploads</NavMenu.Item>
        </React.Fragment>
      )}
      <MenuBar.Space />
      <AccountItem hideMenu={hideMenu} />
    </NavMenu>
  );
};

export default compose(withRouter, withPermissions)(MainNav);
