import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "COMPANIES/PRODUCTS";

const ActionTypes = {
  GetProducts: `${NAMESPACE}/GET_PRODUCTS`,
};

const products = [
  {
    id: 1,
    scheduleReference: "SC101",
    cfaReference: "12598",
    manufacturerModelDescription: "Konica Minolta C456",
    type: "System",
    idNumber: "A2M123456848",
    status: "R",
    locationPostcode: "KT18 2LS",
    consumablesExclusive: false,
    remoteServices: true,
    rrp: 5000,
    ipsCode: "BC",
    ipsInsurance: true,
  },
  {
    id: 2,
    scheduleReference: "SC101",
    cfaReference: "15984",
    manufacturerModelDescription: "Konica Minolta C300i",
    type: "System",
    idNumber: "A7B4826866816",
    status: "R",
    locationPostcode: "KT18 2LS",
    consumablesExclusive: false,
    remoteServices: true,
    rrp: 27000,
    ipsCode: "BC",
    ipsInsurance: true,
  },
  {
    id: 3,
    scheduleReference: "SC101",
    cfaReference: "15984",
    manufacturerModelDescription: "Konica Minolta C456",
    type: "System",
    idNumber: "C8D4538865151",
    status: "R",
    locationPostcode: "KT18 2LS",
    consumablesExclusive: false,
    remoteServices: true,
    rrp: 5000,
    ipsCode: "BC",
    ipsInsurance: true,
  },
  {
    id: 4,
    scheduleReference: "SC102",
    cfaReference: "15684",
    manufacturerModelDescription: "Konica Minolta C300i",
    type: "System",
    idNumber: "S1W58462349498",
    status: "S",
    locationPostcode: "KT18 2LS",
    consumablesExclusive: false,
    remoteServices: true,
    rrp: 27000,
    ipsCode: "IP",
    ipsInsurance: true,
  },
  {
    id: 5,
    scheduleReference: "SC102",
    cfaReference: "56842",
    manufacturerModelDescription: "Konica Minolta C456",
    type: "System",
    idNumber: "OD485165123548",
    status: "R",
    locationPostcode: "KT18 2LS",
    consumablesExclusive: false,
    remoteServices: true,
    rrp: 5000,
    ipsCode: "IP",
    ipsInsurance: true,
  },
  {
    id: 6,
    scheduleReference: "SC103",
    cfaReference: "45953",
    manufacturerModelDescription: "Konica Minolta C300i",
    type: "System",
    idNumber: "D8F478D5651686",
    status: "R",
    locationPostcode: "KT18 2LS",
    consumablesExclusive: false,
    remoteServices: true,
    rrp: 27000,
    ipsCode: "BC",
    ipsInsurance: true,
  },
  {
    id: 7,
    scheduleReference: "SC103",
    cfaReference: "36972",
    manufacturerModelDescription: "Konica Minolta C456",
    type: "System",
    idNumber: "U8S48546581532",
    status: "R",
    locationPostcode: "KT18 2LS",
    consumablesExclusive: false,
    remoteServices: true,
    rrp: 5000,
    ipsCode: "CC",
    ipsInsurance: true,
  },
  {
    id: 8,
    scheduleReference: "SC103",
    cfaReference: "59816",
    manufacturerModelDescription: "Konica Minolta C300i",
    type: "System",
    idNumber: "B9S59846524668",
    status: "C",
    locationPostcode: "KT18 2LS",
    consumablesExclusive: false,
    remoteServices: true,
    rrp: 27000,
    ipsCode: "BC",
    ipsInsurance: true,
  },
  {
    id: 9,
    scheduleReference: "SC103",
    cfaReference: "15681",
    manufacturerModelDescription: "Konica Minolta C456",
    type: "System",
    idNumber: "L9J484546852654",
    status: "C",
    locationPostcode: "KT18 2LS",
    consumablesExclusive: false,
    remoteServices: true,
    rrp: 5000,
    ipsCode: "BC",
    ipsInsurance: true,
  },
  {
    id: 10,
    scheduleReference: "SC104",
    cfaReference: "58485",
    manufacturerModelDescription: "Konica Minolta C300i",
    type: "System",
    idNumber: "P7F484863548561",
    status: "R",
    locationPostcode: "KT18 2LS",
    consumablesExclusive: false,
    remoteServices: true,
    rrp: 27000,
    ipsCode: "BC",
    ipsInsurance: true,
  },
  {
    id: 11,
    scheduleReference: "SC105",
    cfaReference: "98765",
    manufacturerModelDescription: "Konica Minolta C456",
    type: "System",
    idNumber: "A1B1234567890111",
    status: "R",
    locationPostcode: "KT18 2LS",
    consumablesExclusive: false,
    remoteServices: true,
    rrp: 5000,
    ipsCode: "BC",
    ipsInsurance: true,
  },
];

export const getProducts = () => {
  return mockSuccess(ActionTypes.GetProducts, {
    delay: 500,
    response: products,
  });
};
