import { AppRoutes } from "constants/routes";
import React from "react";
import { Link } from "react-router-dom";
import { Grid, Image } from "semantic-ui-react";
import ipsLogo from "assets/logos/ipsLogo-442x442.png";
import wave from "assets/login/wave.svg";
import principalLogo from "features/../../../shared/assets/principal-logo.png";

const SystemPageContent = ({ children }) => (
  <div className="system-page-content">
    <Grid>
      <Grid.Column
        computer={8}
        mobile={16}
        tablet={16}
        largeScreen={6}
        widescreen={6}
      >
        <section className="content-foreground">
          <div className="content">
            <Link to={AppRoutes.Root}>
              {process.env.TRADE_AS_IPS ? (
                <Image
                  centered
                  src={ipsLogo}
                  className="logo"
                  alt="IP Syndicate - Opal"
                  title="IP Syndicate - Opal"
                />
              ) : (
                <Image
                  centered
                  src={principalLogo}
                  alt="Principal - Opal"
                  title="Principal - Opal"
                />
              )}
            </Link>
            {children}
            <div className="flex-spacer" />
            <div className="sys-actions">
              <Link to={AppRoutes.Root}>Privacy Policy</Link>
              <span>and</span>
              <Link to={AppRoutes.Root}>Terms of Service</Link>
            </div>
            <div className="standoff" />
          </div>
        </section>
      </Grid.Column>
    </Grid>
    <div className="system-page-backdrop">
      <Image src={wave} />
      <Image src={wave} />
      <Image src={wave} />
      <Image src={wave} />
      <div className="orb" />
      <div className="orb" />
      <div className="orb" />
      <div className="orb" />
      <div className="orb" />
      <div className="orb" />
    </div>
  </div>
);

export default SystemPageContent;
