import React from "react";
import { Segment, Header, Divider } from "semantic-ui-react";
import { PageContent, PageHeader } from "@redriver/cinnamon";
import { EditUser } from "features/../../../shared/features/Settings";
import { PasswordChange } from "features/System";
import { withCurrentUser } from "../../../../shared/components/auth";

const AccountPage = currentUser => (
  <PageContent>
    <PageHeader>Your Account</PageHeader>
    <Segment padded>
      <Header as="h4">Account Details</Header>
      <Divider hidden />
      <EditUser user={currentUser} />
      <Divider section />
      <Header as="h4">Change Password</Header>
      <Divider hidden />
      <PasswordChange />
    </Segment>
  </PageContent>
);

export default withCurrentUser(AccountPage);
