import React from "react";
import { ContentBuilder, DataList } from "@redriver/cinnamon";
import { ContentPanel } from "components/displays";
import { getProductDetails } from "./actions";
import { TextArea } from "semantic-ui-react";
import { currencyFormatter } from "components/displays/DisplayHelper";

const ProductInformationTable = ({ productId }) => {
  return (
    <ContentBuilder
      loadAction={getProductDetails}
      loadParams={productId}
      loadTransform={(data) => data.productInformation}
      renderContent={(productInformation) => (
        <ContentPanel
          title="Product Information"
          wrapperCssClass=" "
          headerCssClass="defaults-page-title"
        >
          <div className="spaced">
            <DataList>
              <DataList.Item title="Product System ID" width="100%">
                {productInformation.systemId}
              </DataList.Item>
              <DataList.Item title="Identifier Number" width="100%">
                {productInformation.identifierNumber}
              </DataList.Item>
              <DataList.Item title="Manufacturer & Model" width="100%">
                {productInformation.manufacturer} {productInformation.model}
              </DataList.Item>
              <DataList.Item title="Type" width="100%">
                {productInformation.type}
              </DataList.Item>
              <DataList.Item title="Product Status" width="100%">
                {productInformation.status}
              </DataList.Item>
              <DataList.Item title="Current Contract Ref" width="100%">
                {productInformation.currentContractRef}
              </DataList.Item>
              <DataList.Item title="Tax Code" width="100%">
                {productInformation.taxCode}
              </DataList.Item>
              <DataList.Item title="RRP" width="100%">
                {currencyFormatter(productInformation.rrp)}
              </DataList.Item>
              <DataList.Item title="Install Date" width="100%">
                {productInformation.installDate}
              </DataList.Item>
              <DataList.Item title="Expiry Date" width="100%">
                {productInformation.expiryDate}
              </DataList.Item>
              <DataList.Item title="Initial Contract Ref" width="100%">
                {productInformation.initialContractRef}
              </DataList.Item>
              <DataList.Item title="Title Holder" width="100%">
                {productInformation.titleHolder}
              </DataList.Item>
              <DataList.Item title="Security Holder" width="100%">
                {productInformation.securityHolder}
              </DataList.Item>
              <DataList.Item title="CFA Reference" width="100%">
                {productInformation.cfaReference}
              </DataList.Item>
              <DataList.Item title="Funder" width="100%">
                {productInformation.funder}
              </DataList.Item>
              <DataList.Item title="IPS Insurance" width="100%">
                {productInformation.ipsInsurance}
              </DataList.Item>
            </DataList>
            <TextArea
              label="Notes"
              field={productInformation.notes}
              readonly
              width="100%"
            />
          </div>
        </ContentPanel>
      )}
    />
  );
};

export default ProductInformationTable;
