import React from "react";
import {
  getCommunicationInfo,
  getContacts,
  getSupplierContacts,
} from "./actions";
import { Grid, GridColumn, GridRow, Table as Tbl } from "semantic-ui-react";
import { ContentBuilder, Form, FormBuilder } from "@redriver/cinnamon";
import { ContentPanel } from "components/displays";
import { SimpleTableBuilder } from "../../../../../../shared/components/tables/sortableTable";
import { blankDataFormatter } from "components/displays/DisplayHelper";

const Communication = () => {
  const addressTypes = [
    { value: 1, text: "Main" },
    { value: 2, text: "Fareham" },
    { value: 3, text: "Other" },
  ];

  const deliveryTypes = [
    { value: 1, text: "Email" },
    { value: 2, text: "Fax" },
    { value: 3, text: "Post" },
  ];

  const contactColumns = [
    { field: "name", title: "Name", width: "20%" },
    {
      field: "prefix",
      title: "Prefix",
      width: "16%",
      render: (item) => blankDataFormatter(item.prefix, "-"),
    },
    { field: "email", title: "Email Address", width: "16%" },
    { field: "phone", title: "Phone", width: "16%" },
    { field: "role", title: "Role", width: "16%" },
    { field: "territory", title: "Territory", width: "16%" },
  ];

  const supplierContactColumns = [
    { field: "name", title: "Name", width: "26%" },
    { field: "email", title: "Email Address", width: "26%" },
    { field: "phone", title: "Phone", width: "22%" },
    { field: "role", title: "Role", width: "26%" },
  ];

  return (
    <ContentBuilder
      loadAction={getCommunicationInfo}
      renderContent={(data) =>
        data &&
        data.id && (
          <ContentPanel
            title="Communication"
            wrapperCssClass="outline-grid"
            headerCssClass="defaults-page-title"
          >
            <div className="spaced">
              <FormBuilder
                initialData={data}
                renderForm={(formProps) => (
                  <Form {...formProps}>
                    <Grid columns={2} doubling>
                      <GridRow>
                        <Form.Array field="addresses" arrayKey="id">
                          {({ fields }) => (
                            <GridColumn>
                              <ContentPanel
                                title={fields.typeName}
                                wrapperCssClass="outline-grid "
                                headerCssClass="defaults-page-title"
                              >
                                <div className="spaced">
                                  <div className="ui labeled input custom-sublabel">
                                    <div className="ui label label">Name</div>
                                    <Form.Dropdown
                                      field="type"
                                      options={addressTypes}
                                    />
                                  </div>
                                  <Form.Input
                                    field="addressLine1"
                                    subLabel="Address 1"
                                  />
                                  <Form.Input
                                    field="addressLine2"
                                    subLabel="Address 2"
                                  />
                                  <Form.Input
                                    field="addressLine3"
                                    subLabel="Address 3"
                                  />
                                  <Form.Input field="city" subLabel="City" />
                                  <Form.Input
                                    field="county"
                                    subLabel="County"
                                  />
                                  <Form.Input
                                    field="postcode"
                                    subLabel="Post Code"
                                  />
                                  <Form.Input
                                    field="country"
                                    subLabel="Country"
                                  />
                                </div>
                              </ContentPanel>
                            </GridColumn>
                          )}
                        </Form.Array>
                      </GridRow>
                    </Grid>
                  </Form>
                )}
              />

              <br />
              <br />
              <ContentPanel
                title="Customer Contact Details"
                wrapperCssClass="outline-grid"
                headerCssClass="defaults-page-title"
              >
                <div className="spaced">
                  <div className="vertical-scrollbar medium">
                    <SimpleTableBuilder
                      emptyMessage="No contact details found"
                      columns={contactColumns}
                      className="left-align"
                      dataKey="id"
                      loadAction={getContacts}
                    />
                  </div>
                </div>
              </ContentPanel>
              <br />
              <br />

              <Grid columns={2} doubling>
                <GridRow>
                  <GridColumn>
                    <ContentPanel
                      title="Invoice Delivery Method"
                      wrapperCssClass="outline-grid"
                      headerCssClass="defaults-page-title"
                    >
                      <div className="spaced">
                        <FormBuilder
                          initialData={data.invoiceDeliveryDetails}
                          renderForm={(formProps) => (
                            <Form {...formProps}>
                              <div className="ui labeled input custom-sublabel">
                                <div className="ui label label">Type</div>
                                <Form.Dropdown
                                  field="type"
                                  options={deliveryTypes}
                                />
                              </div>
                              <Form.Input
                                field="email"
                                subLabel="Email Address"
                              />
                              <div className="ui labeled input custom-sublabel">
                                <div className="ui label label">cc</div>
                                <Form.TextArea field="cc" />
                              </div>
                            </Form>
                          )}
                        />
                      </div>
                    </ContentPanel>
                  </GridColumn>
                  <GridColumn>
                    <ContentPanel
                      title="Supplier Contact Details"
                      wrapperCssClass="outline-grid"
                      headerCssClass="defaults-page-title"
                    >
                      <div className="spaced">
                        <Tbl className="custom-fc-table">
                          <Tbl.Header>
                            <Tbl.Row>
                              <Tbl.HeaderCell></Tbl.HeaderCell>
                              <Tbl.HeaderCell>Email</Tbl.HeaderCell>
                              <Tbl.HeaderCell>Phone</Tbl.HeaderCell>
                            </Tbl.Row>
                          </Tbl.Header>
                          <Tbl.Body>
                            <Tbl.Row>
                              <Tbl.Cell className="grey">Main</Tbl.Cell>
                              <Tbl.Cell>
                                {data.supplierContactDetails.main.email}
                              </Tbl.Cell>
                              <Tbl.Cell>
                                {data.supplierContactDetails.main.phone}
                              </Tbl.Cell>
                            </Tbl.Row>
                            <Tbl.Row>
                              <Tbl.Cell className="grey">Admin</Tbl.Cell>
                              <Tbl.Cell>
                                {data.supplierContactDetails.admin.email}
                              </Tbl.Cell>
                              <Tbl.Cell>
                                {data.supplierContactDetails.admin.phone}
                              </Tbl.Cell>
                            </Tbl.Row>
                          </Tbl.Body>
                        </Tbl>
                      </div>
                      <div className="spaced">
                        <div className="vertical-scrollbar small">
                          <SimpleTableBuilder
                            emptyMessage="No contact details found"
                            columns={supplierContactColumns}
                            className="left-align-header"
                            dataKey="id"
                            loadAction={getSupplierContacts}
                          />
                        </div>
                      </div>
                    </ContentPanel>
                  </GridColumn>
                </GridRow>
              </Grid>
            </div>
          </ContentPanel>
        )
      }
    />
  );
};

export default Communication;
