import { mockSuccess, MockArrayData } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULES/LARGEFORMAT";

const ActionTypes = {
  GetLargeFormatPrinters: `${NAMESPACE}/GET_LARGE_FORMAT_PRINTERS`,
};

const mockLargeFormatPrinters = new MockArrayData([
  {
    id: 1,
    name: "TX - 3000 MFP",
    scannerIncluded: true,
    maxRollWidth: 914,
    maxRollWidthInches: 36,
    noOfColours: 5,
    priceOption: "B",
    serialNumber: "AB345645723",
    configs: [
      {
        id: 1,
        unit: "M1",
        description: "ml of ink used in cycle",
        cpi: 44000,
        media: "Poster Images/ Glossy Photo Paper/ Standard",
        sku: {
          supplier: "Art Systems",
          paperSku: "XCIJP80-23-50",
          no: "XTS-002624",
          mediaWeight: 80,
          paperWidthMm: 841,
          paperWidthIn: 33,
          totalLengthM: 200,
          m2Total: 168.0,
          actualCostPrice: 41.5,
          scheduleCostPrice: 39.92,
          actualCostPerM2: 25.028,
          scheduleCostPerM2: 24.0,
        },
        billingCode: "MLINKCYCLE",
      },
      {
        id: 2,
        unit: "L1",
        description: "ml of ink used to print",
        cpi: 38500,
        media: "Poster Images/ Glossy Photo Paper/ Standard",
        sku: {
          supplier: "Art Supplies Ltd",
          paperSku: "XCIJP80-23-51",
          no: "XTS-002625",
          mediaWeight: 60,
          paperWidthMm: 900,
          paperWidthIn: 50,
          totalLengthM: 400,
          m2Total: 180.5,
          actualCostPrice: 45,
          scheduleCostPrice: 65.24,
          actualCostPerM2: 30.123,
          scheduleCostPerM2: 27.6,
        },
        billingCode: "MLINKPRINT",
      },
      {
        id: 3,
        unit: "P1",
        description: "wide media per M2",
        cpi: 24000,
        media: "Premium Plotter Paper (4 x 50m rolls)",
        sku: {
          supplier: "Art Systems",
          paperSku: "XCIJP80-23-50",
          no: "XTS-002624",
          mediaWeight: 80,
          paperWidthMm: 841,
          paperWidthIn: 33,
          totalLengthM: 200,
          m2Total: 168.0,
          actualCostPrice: 41.5,
          scheduleCostPrice: 39.92,
          actualCostPerM2: 25.028,
          scheduleCostPerM2: 24.0,
        },
        billingCode: "M2WIDEMEDIA",
      },
      {
        id: 4,
        unit: "SS1",
        description: "LFP scans",
        cpi: 4200,
        media: "",
        sku: {
          supplier: "Art Supplies Ltd",
          paperSku: "XCIJP80-23-51",
          no: "XTS-002625",
          mediaWeight: 60,
          paperWidthMm: 900,
          paperWidthIn: 50,
          totalLengthM: 400,
          m2Total: 180.5,
          actualCostPrice: 45,
          scheduleCostPrice: 65.24,
          actualCostPerM2: 30.123,
          scheduleCostPerM2: 27.6,
        },
        billingCode: "LFP",
      },
    ],
  },
  {
    id: 2,
    name: "Pro 6000 S",
    scannerIncluded: false,
    maxRollWidth: 1524,
    maxRollWidthInches: 60,
    noOfColours: 14,
    priceOption: "A",
    serialNumber: "FRT5427665Y",
    configs: [
      {
        id: 1,
        unit: "M2",
        description: "ml of ink used in cycle",
        cpi: 52000,
        media: "Canvass, Standard",
        sku: {
          supplier: "Art Systems",
          paperSku: "XCIJP80-23-50",
          no: "XTS-002624",
          mediaWeight: 80,
          paperWidthMm: 841,
          paperWidthIn: 33,
          totalLengthM: 200,
          m2Total: 168.0,
          actualCostPrice: 41.5,
          scheduleCostPrice: 39.92,
          actualCostPerM2: 25.028,
          scheduleCostPerM2: 24.0,
        },
        billingCode: "MLINKCYCLE",
      },
      {
        id: 2,
        unit: "L2",
        description: "ml of ink used to print",
        cpi: 42900,
        media: "Canvass, Standard",
        sku: {
          supplier: "Art Supplies Ltd",
          paperSku: "XCIJP80-23-51",
          no: "XTS-002625",
          mediaWeight: 60,
          paperWidthMm: 900,
          paperWidthIn: 50,
          totalLengthM: 400,
          m2Total: 180.5,
          actualCostPrice: 45,
          scheduleCostPrice: 65.24,
          actualCostPerM2: 30.123,
          scheduleCostPerM2: 27.6,
        },
        billingCode: "MLINKCYCLE",
      },
      {
        id: 3,
        unit: "P2",
        description: "wide media per M2",
        cpi: 80000,
        media: "Premium Matt Coated Paper",
        sku: {
          supplier: "Art Supplies Ltd",
          paperSku: "XXPMC180-42-45",
          no: "XTS-002625",
          mediaWeight: 60,
          paperWidthMm: 900,
          paperWidthIn: 50,
          totalLengthM: 400,
          m2Total: 180.5,
          actualCostPrice: 45,
          scheduleCostPrice: 65.24,
          actualCostPerM2: 30.123,
          scheduleCostPerM2: 27.6,
        },
        billingCode: "M2WIDEMEDIA",
      },
    ],
  },
]);

export const getLargeFormatPrinters = () => {
  return mockSuccess(ActionTypes.GetLargeFormatPrinters, {
    response: mockLargeFormatPrinters,
  });
};

export const getPrintersForUnitType = (printers, unitType) => {
  const unitPrinters = [];

  if (printers == null) return unitPrinters;

  printers.forEach((printer) => {
    if (printer.configs == null) return;
    printer.configs.forEach((config) => {
      if (config.unit === unitType) {
        unitPrinters.push(printer);
      }
    });
  });
  return unitPrinters;
};
