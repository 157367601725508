import { apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/RESTRICTIONS/PAPER_INCLUSIVE";

const ActionTypes = {
  UpdatePaperInclusiveRestrictions: `${NAMESPACE}/UPDATE`,
};

export const setPaperInclusiveRestrictions = (formData, { settingsId }) =>
  apiPut(
    ActionTypes.UpdatePaperInclusiveRestrictions,
    `defaults-restrictions/${settingsId}`,
    {
      paperInclusive: {
        ...formData,
      },
    }
  );
