import React, { useState } from "react";
import { ListBuilder, DateTimeFormatter } from "@redriver/cinnamon";
import { getAttachments, FilterBar } from ".";
import { Button, Icon } from "semantic-ui-react";

import { ContentPanel } from "components/displays";
import classNames from "classnames";
import { SortableTable } from "../../../../../../shared/components/tables/sortableTable";

const Attachments = () => {
  const [tableExtended, setTableExtended] = useState(false);

  const columns = [
    {
      field: "name",
      title: "Name",
      width: "27%",
      className: "left-align",
      render: (item) => (
        <a href="#" target="_blank">
          {item.name}
        </a>
      ),
    },
    {
      field: "type",
      title: "Attachment Type",
      width: "21%",
      className: "left-align",
      render: (item) => types.find((e) => e.value === item.type).text,
    },
    {
      field: "filename",
      title: "File Name",
      width: "27%",
      className: "left-align",
    },
    {
      field: "memberId",
      title: "Uploaded By",
      width: "10%",
      className: "left-align",
      render: (item) => users.find((e) => e.value === item.memberId).text,
    },
    {
      field: "dt",
      title: "Date Uploaded",
      width: "11%",
      className: "left-align",
      render: (item) => <DateTimeFormatter value={item.dt} />,
    },
  ];

  return (
    <ContentPanel
      title="Attachments"
      wrapperCssClass="outline-grid filtered-grid "
      headerCssClass="defaults-page-title"
    >
      <div className="spaced">
        <ListBuilder
          withTable
          loadAction={getAttachments}
          renderList={(tableProps, state, events) => (
            <React.Fragment>
              <div className="content-panel filters">
                <div className="spaced">
                  <FilterBar
                    value={state.filters}
                    onChange={events.onChangeFilters}
                    users={users}
                    types={types}
                  />
                </div>
              </div>
              <br />

              <div
                className={classNames(
                  "vertical-scrollbar",
                  tableExtended ? "extended" : "retracted"
                )}
              >
                <SortableTable
                  state={state}
                  events={events}
                  tableProps={tableProps}
                  emptyMessage="No attachments found"
                  columns={columns}
                />
              </div>
            </React.Fragment>
          )}
        />

        <div className="extension-icon">
          <Button onClick={() => setTableExtended(!tableExtended)}>
            {tableExtended ? (
              <Icon name="angle up" />
            ) : (
              <Icon name="angle down" />
            )}
          </Button>
        </div>
      </div>
    </ContentPanel>
  );
};

const users = [
  {
    value: 0,
    text: "Show all",
  },
  {
    value: 1,
    text: "Suzy Bristow",
  },
  {
    value: 2,
    text: "Sally Booker",
  },
  {
    value: 3,
    text: "Lauren Brown",
  },
];

const types = [
  {
    value: 0,
    text: "Show all",
  },
  {
    value: 1,
    text: "Schedule",
  },
  {
    value: 2,
    text: "Product",
  },
  {
    value: 3,
    text: "Addition",
  },
];

export default Attachments;
