import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_DEALER_OFFICE_AUTH_SIGNATORIES",
  ActionTypes = {
    GetDocumentNumbering: `${NAMESPACE}/GET_DOCUMENT_NUMBERING`,
    UpdateDocumentNumbering: `${NAMESPACE}/UPDATE_DOCUMENT_NUMBERING`,
  };

export const getDocumentNumbering = ({ dealerId }) =>
  apiGet(
    ActionTypes.GetDocumentNumbering,
    `dealers/${dealerId}/document-numbering`
  );

export const updateDocumentNumbering = (data, { dealerId }) => {
  return apiPut(
    ActionTypes.UpdateDocumentNumbering,
    `dealers/${dealerId}/document-numbering`,
    data
  );
};
