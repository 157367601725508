import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULE/PRICEINCREASE";

const ActionTypes = {
  GetPriceIncrease: `${NAMESPACE}/GET_PRICE_INCREASE`,
};

const priceIncrease = {
  totalResults: 8,
  results: [
    {
      id: 1,
      customerId: "ABC",
      customer: "ABC Limited",
      supplier: "Konica Minolta",
      supplierOffice: "Konica Minolta Headquarters",
      schedule: 1234,
      applicableDate: "28/12/2020",
      option: "All",
      methodology: "Standard",
      increase: 5.0,
      currentValue: 540.15,
      futureValue: 567.16,
      increaseValue: 27.01,
    },
    {
      id: 2,
      customerId: "BSD",
      customer: "BSD Ltd",
      supplier: "Konica Minolta",
      supplierOffice: "Konica Minolta Headquarters",
      schedule: 7548,
      applicableDate: "20/12/2020",
      option: "Custom Service",
      methodology: "RPI",
      increase: 7.0,
      currentValue: 1456.02,
      futureValue: 1557.94,
      increaseValue: 101.94,
    },
    {
      id: 3,
      customerId: "PLU",
      customer: "Pluto's Yard",
      supplier: "Konica Minolta",
      supplierOffice: "Konica Minolta Headquarters",
      schedule: 2154,
      applicableDate: "02/01/2021",
      option: "All",
      methodology: "Fixed",
      increase: 5.0,
      currentValue: 2496.08,
      futureValue: 2620.88,
      increaseValue: 124.8,
    },
    {
      id: 4,
      customerId: "JYT",
      customer: "JYT Plc",
      supplier: "Principal",
      supplierOffice: "Principal Office",
      schedule: 9877,
      applicableDate: "06/01/2021",
      option: "Meter Only",
      methodology: "Standard",
      increase: 5.0,
      currentValue: 746.21,
      futureValue: 783.52,
      increaseValue: 37.31,
    },
    {
      id: 5,
      customerId: "HYT",
      customer: "Harvard Year Team Ltd",
      supplier: "Principal",
      supplierOffice: "Principal Office",
      schedule: 2187,
      applicableDate: "22/12/2020",
      option: "All",
      methodology: "Fixed",
      increase: 9.0,
      currentValue: 2386.27,
      futureValue: 2601.03,
      increaseValue: 214.76,
    },
    {
      id: 6,
      customerId: "QQW",
      customer: "Quality Quiet World Plc",
      supplier: "Konica Minolta",
      supplierOffice: "Konica Minolta Headquarters",
      schedule: 5741,
      applicableDate: "19/12/2020",
      option: "All",
      methodology: "RPI",
      increase: 5.0,
      currentValue: 4226273,
      futureValue: 44164.55,
      increaseValue: 1901.82,
    },
    {
      id: 7,
      customerId: "REA",
      customer: "Realized Potential Limited",
      supplier: "Principal",
      supplierOffice: "Principal Office",
      schedule: 6871,
      applicableDate: "10/01/2021",
      option: "Custom Service",
      methodology: "RPI",
      increase: 2.0,
      currentValue: 1589.45,
      futureValue: 1621.24,
      increaseValue: 31.79,
    },
    {
      id: 8,
      customerId: "ETH",
      customer: "Etherial Time House Ltd",
      supplier: "Konica Minolta",
      supplierOffice: "Konica Minolta Headquarters",
      schedule: 8792,
      applicableDate: "11/04/2021",
      option: "All",
      methodology: "Standard",
      increase: 4.0,
      currentValue: 1782.69,
      futureValue: 1818.34,
      increaseValue: 35.65,
    },
  ],
};

// export const getPriceIncrease = ({ filters, pagination }, { scheduleId }) =>
//   apiGet(
//     ActionTypes.GetPriceIncrease,
//     `schedules/${scheduleId}/price-increases`,
//     {
//       ...pagination,
//       ...filters,
//     }
//   );

export const getPriceIncrease = () => {
  return mockSuccess(ActionTypes.GetPriceIncrease, {
    delay: 100,
    response: priceIncrease,
  });
};
