import { ActionBar, FormBuilder, ListBuilder } from "@redriver/cinnamon";
import { ContentPanel } from "components/displays";
import { TextSearch } from "features/../../../shared/components/search";
import React from "react";
import { getProducts } from "./actions";
import { SortableTable } from "../../../../../../shared/components/tables/sortableTable";
import { currencyFormatter } from "components/displays/DisplayHelper";

const Products = () => {
  const columns = [
    { field: "scheduleReference", title: "Schedule Reference" },
    { field: "cfaReference", title: "CFA Reference" },
    {
      field: "manufacturerModelDescription",
      title: "Manufacturer Modal Description",
    },
    { field: "type", title: "Type" },
    { field: "idNumber", title: "Identifier Number" },
    { field: "status", title: "Status" },
    { field: "locationPostcode", title: "Location Postcode" },
    {
      field: "consumablesExclusive",
      title: "Consumables Exclusive?",
      render: (item) => (
        <React.Fragment>
          {item.consumablesExclusive ? "Yes" : "No"}
        </React.Fragment>
      ),
    },
    {
      field: "remoteServices",
      title: "Remote Services",
      render: (item) => (
        <React.Fragment>{item.remoteServices ? "Yes" : "No"}</React.Fragment>
      ),
    },
    {
      field: "rrp",
      title: "RRP",
      render: (item) => currencyFormatter(item.rrp, process.env.CURRENCY_CODE),
    },
    { field: "ipsCode", title: "IPS Code" },
    {
      field: "ipsInsurance",
      title: "IPS Insurance",
      render: (item) => (
        <React.Fragment>{item.ipsInsurance ? "Yes" : "No"}</React.Fragment>
      ),
    },
  ];

  return (
    <ListBuilder
      withTable
      loadAction={getProducts}
      renderList={(tableProps, state, events) => (
        <ContentPanel
          title="Products"
          wrapperCssClass="outline-grid products-list"
          headerCssClass="defaults-page-title"
          headerContent={<SectionBar state={state} events={events} />}
        >
          <div className="companies-products">
            <div className="companies-products-status">
              {"STATUS:  N - New Products,  U-Not New,  B - Owned by Supplier,  O - Owned by " +
                "Another Party,  R - Retained Products,  C - Customer Owned"}
            </div>
            <div className="vertical-scrollbar">
              <SortableTable
                state={state}
                events={events}
                tableProps={tableProps}
                emptyMessage="No products found"
                columns={columns}
              />
            </div>
          </div>
        </ContentPanel>
      )}
    />
  );
};

const SectionBar = ({ state, events }) => (
  <FormBuilder
    renderForm={() => (
      <ActionBar className="search-filter-display">
        <ActionBar.Item>
          <TextSearch
            filters={state.filters}
            onChange={events.onChangeFilters}
          />
        </ActionBar.Item>
        <ActionBar.Item fluid></ActionBar.Item>
      </ActionBar>
    )}
  />
);

export default Products;
