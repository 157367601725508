import { ActionTypes } from "./actions";
import { requestResponse } from "@redriver/cinnamon";
import { produce } from "immer";

const initialState = {
  scheduleData: {
    totalManagedVolume: "-",
    volumeUsed: "-",
    creditVolume: "-",
    financeApplicableVolume: "-",
    warningVolume: "-",
    earlyTerminationDiscount: "-",
    principalPoundIncentive: "-",
    poundsUsed: "-",
    clientName: "-",
    masterAgreement: {
      agreementNumber: "-",
      finance: "-",
      contractReference: "-",
      startDate: "-",
      salesPerson: "-",
    },
    scheduleItems: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ClearScheduleDetails:
      return {
        ...state,
        scheduleData: initialState.scheduleData,
      };

    case ActionTypes.GetScheduleDetails: {
      const response = requestResponse(action);

      const newState = produce(state, (draftState) => {
        const d = draftState;
        d.scheduleData = { ...response };
      });

      return newState;
    }

    default:
      return state;
  }
};
