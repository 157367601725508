import React, { useEffect, useState } from "react";
import { ListBuilder, usePreferences, Format } from "@redriver/cinnamon";
import { getFunderCFADetails } from "../actions";
import { ContentWrapper, ContentHeader } from "../Helper";
import {
  TableConfigurator,
  ConfigurableTable,
} from "features/CustomConfig/TableConfig";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "assets/pen.svg";
import { TablePagination } from "components/pagination";
import { currencyFormatter } from "components/displays/DisplayHelper";
import { Link } from "react-router-dom";
import { CFARoutes } from "constants/routes";

const FunderCFAHeaderItems = (pageSizeConfig, config, columns) => {
  return [
    {
      type: "space",
    },
    {
      type: "noItem",
      isFluid: true,
    },
    {
      type: "space",
    },
    {
      alignment: "right",
      isFluid: false,
      headerClass: "",
      item: (
        <TableConfigurator
          config={config}
          columns={columns}
          configId="funderCfaTableConfig"
          titlePreFix="CFA"
          pageSizeConfig={pageSizeConfig}
          initialPageSize={pageSizeConfig}
        ></TableConfigurator>
      ),
    },
    {
      type: "space",
    },
    {
      alignment: "right",
      isFluid: false,
      headerClass: "",
      item: <IconButton primary icon={Pen} content="Export" className={null} />,
    },
  ];
};

const FunderCFA = () => {
  const { preferences } = usePreferences();
  const [config, setConfig] = useState(preferences.funderCfaTableConfig);
  const [pageSizeConfig, setPageSizeConfig] = useState(
    preferences.funderCfaTableConfigPageSize ?? 50
  );

  useEffect(() => {
    setConfig(preferences.funderCfaTableConfig);
  }, [preferences.funderCfaTableConfig]);

  useEffect(() => {
    preferences.funderCfaTableConfigPageSize &&
      setPageSizeConfig(preferences.funderCfaTableConfigPageSize);
  }, [preferences.funderCfaTableConfigPageSize]);

  const columns = [
    {
      field: "ref",
      title: "CFA Reference",
      render: (cfa) => (
        <Link to={CFARoutes.CFA(cfa.ref)} className="cfa-link">
          {cfa.ref}
        </Link>
      ),
    },
    { field: "status", title: "Status" },
    { field: "associatedSupplier", title: "Associated Supplier" },
    { field: "associatedSpv", title: "Associated SPV" },
    {
      field: "startDate",
      title: "Start Date",
      render: (x) => <Format.Date value={x.startDate} format="DD MMM YYYY" />,
    },
    {
      field: "endDate",
      title: "End Date",
      render: (x) => <Format.Date value={x.endDate} format="DD MMM YYYY" />,
    },
    { field: "currency", title: "Currency" },
    {
      field: "payment",
      title: "Current CFA Payment",
      render: (x) => currencyFormatter(x.payment),
    },
    { field: "term", title: "Term" },
    { field: "periodsRemaining", title: "Periods Remaining" },
    { field: "initialReference", title: "Reference" },
    { field: "initialCustomer", title: "Customer" },
    { field: "currentReference", title: "Reference" },
    { field: "currentStatus", title: "Status" },
  ];

  return (
    <ListBuilder
      withTable
      initialPagination={{ pageSize: pageSizeConfig, pageNumber: 1 }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      loadParams={{ pageSize: pageSizeConfig }}
      loadAction={getFunderCFADetails}
      renderList={(tableProps, state, events) => (
        <div className="funders-summary cfa-list">
          <ContentWrapper
            title="CFA"
            contentHeader={
              <ContentHeader
                items={FunderCFAHeaderItems(pageSizeConfig, config, columns)}
              />
            }
          >
            <div className="spaced">
              <React.Fragment>
                <ConfigurableTable
                  tableProps={tableProps}
                  emptyMessage="No CFA data found"
                  columns={columns}
                  config={config}
                  state={state}
                  events={events}
                />

                <div className="padded">
                  <TablePagination
                    pageNumber={state.pagination.pageNumber}
                    onChange={events.onChangePagination}
                    pageSize={pageSizeConfig}
                    totalItems={state.total}
                  />
                </div>
              </React.Fragment>
            </div>
          </ContentWrapper>
        </div>
      )}
    ></ListBuilder>
  );
};

export default FunderCFA;
