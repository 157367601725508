import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "FORMS/LOOKUPS";

const ActionTypes = {
  GetServiceStatuses: `${NAMESPACE}/GET_SERVICE_STATUSES`,
};

export const getServiceStatuses = (params) =>
  apiGet(ActionTypes.GetServiceStatuses, `lookups/service-statuses`, params);
