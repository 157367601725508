import React, { useState } from "react";
import { Menu, Grid, MenuItem } from "semantic-ui-react";
import ScheduleSettings from "./GeneralSettings/ScheduleSettings";
import CapitalSettings from "./GeneralSettings/CapitalSettings";
import ServicesSettings from "./GeneralSettings/ServicesSettings";
import DocumentSettings from "./GeneralSettings/DocumentSettings/DocumentSettings";
import TemporarySettings from "./GeneralSettings/TemporarySettings";
const GeneralSettings = ({ settingsId, dealerOfficeId }) => {
  const [activeSettingsMenuItem, setActiveSettingsMenuItem] = useState(0);
  return (
    <React.Fragment>
      <div className="content-panel">
        <div className="office-general-settings">
          <Grid>
            <Grid.Column width={2} className="general-settings-menu-column">
              <Menu vertical className="general-settings-tabs">
                <MenuItem
                  name="Schedule Settings"
                  onClick={() => {
                    setActiveSettingsMenuItem(0);
                  }}
                  className={activeSettingsMenuItem === 0 ? "active" : ""}
                />
                <MenuItem
                  name="Capital Settings"
                  onClick={() => {
                    setActiveSettingsMenuItem(1);
                  }}
                  className={activeSettingsMenuItem === 1 ? "active" : ""}
                />
                <MenuItem
                  name="Services Settings"
                  onClick={() => {
                    setActiveSettingsMenuItem(2);
                  }}
                  className={activeSettingsMenuItem === 2 ? "active" : ""}
                />
                <MenuItem
                  name="Document Settings"
                  onClick={() => {
                    setActiveSettingsMenuItem(3);
                  }}
                  className={activeSettingsMenuItem === 3 ? "active" : ""}
                />
                <MenuItem
                  name="Temporary Settings"
                  onClick={() => {
                    setActiveSettingsMenuItem(4);
                  }}
                  className={activeSettingsMenuItem === 4 ? "active" : ""}
                />
              </Menu>
            </Grid.Column>
            <Grid.Column width={14}>
              {(activeSettingsMenuItem === 0 && (
                <ScheduleSettings settingsId={settingsId} />
              )) ||
                (activeSettingsMenuItem === 1 && (
                  <CapitalSettings settingsId={settingsId} />
                )) ||
                (activeSettingsMenuItem === 2 && (
                  <ServicesSettings
                    settingsId={settingsId}
                    dealerOfficeId={dealerOfficeId}
                  />
                )) ||
                (activeSettingsMenuItem === 3 && (
                  <div className="defaults-page-title flex">
                    <div className="flex title-bar settings-grid">
                      <DocumentSettings
                        settingsId={settingsId}
                        dealerOfficeId={dealerOfficeId}
                      />
                    </div>
                  </div>
                )) ||
                (activeSettingsMenuItem === 4 && (
                  <TemporarySettings
                    settingsId={settingsId}
                    dealerOfficeId={dealerOfficeId}
                  />
                ))}
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GeneralSettings;
