import React from "react";
import { SidePanel, Form } from "@redriver/cinnamon";
import { FilterButton } from "features/../../../shared/components/buttons";

const ProductFilters = ({ title, formProps, onReset }) => {
  return (
    <SidePanel
      closeIcon
      trigger={<FilterButton />}
      className="products-list-filter-side-panel"
    >
      <div>
        <div className="ui header filters-title">
          <div className="title-text">{title}</div>
          <div className="clear-button" onClick={onReset}>
            (clear)
          </div>
        </div>
        <Form {...formProps} className="highlighted-inputs">
          <div className="ui form highlighted-inputs"></div>
        </Form>
      </div>
    </SidePanel>
  );
};

export default ProductFilters;
