import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { makeBoilerPlateTandCsLive } from "./actions";

const MakeBoilerPlateLiveModal = ({
  itemId,
  onSubmitted,
  reference,
  ...props
}) => {
  return (
    <ModalBuilder
      submitAction={makeBoilerPlateTandCsLive}
      submitParams={{ id: itemId }}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <div {...props} onClick={showModal} className="item">
          Live
        </div>
      )}
      renderModal={modalProps => (
        <Modal.Confirmation {...modalProps} header={"Make Live: " + reference}>
          {reference + ": Make Boiler Plate T&C's Live?"}
        </Modal.Confirmation>
      )}
    />
  );
};

export default MakeBoilerPlateLiveModal;
