import React from "react";
import { Form } from "@redriver/cinnamon";
import { Grid } from "semantic-ui-react";
import { MeteredServiceDropdown } from "features/../../../shared/components/forms";
import { ServiceTypesRadioGroup } from "features/../../../shared/features/Settings";
import { ServiceStatusDropdown } from "components/forms";

const MeteredServiceForm = ({
  loading,
  formProps,
  contractTypeResponse,
  dealerOfficeId,
}) => (
  <Form {...formProps} className="metered-service-form">
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Form.Input
            fluid
            required
            field="name"
            label="Name"
            className="highlighted-input"
            placeholder="Name..."
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Input
            fluid
            required
            field="code"
            label="Code"
            maxLength={4}
            className="highlighted-input"
            placeholder="Code..."
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Form.Input
            fluid
            required
            field="legalName"
            label="Legal Name"
            className="highlighted-input"
            placeholder="Legal Name..."
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Input
            fluid
            required
            field="legalCode"
            label="Legal Code"
            maxLength={4}
            className="highlighted-input"
            placeholder="Legal Code..."
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Form.Input
            fluid
            field="termsRef"
            label="Terms and Conditions Reference"
            className="highlighted-input"
            placeholder="Terms and Conditions Reference..."
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Input
            fluid
            field="supplierBillingCode"
            label="Supplier Billing Code"
            className="highlighted-input"
            placeholder="Supplier Billing Code..."
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Form.Input
            fluid
            field="ipsBillingCode"
            label="IPS Billing Code"
            className="highlighted-input"
            placeholder="Billing Code..."
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Input
            fluid
            field="ipsSku"
            label="IPS SKU"
            className="highlighted-input"
            placeholder="IPS SKU..."
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <ServiceStatusDropdown
            field="status"
            label="Service Status"
            fluid
            required
            className="highlighted-input"
          />
        </Grid.Column>
        <Grid.Column width={6} style={{ paddingLeft: "6em" }}>
          <Form.CheckboxGroup
            field="contractTypes"
            label="Contract Type(s)"
            options={contractTypeResponse != null ? contractTypeResponse : []}
          />
        </Grid.Column>
        <Grid.Column width={5}>
          <ServiceTypesRadioGroup
            field="serviceType"
            label="Service Type"
            fluid
            required
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Form.Checkbox field="alwaysUnmanaged" label="Always Unmanaged" />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Checkbox field="alwaysManaged" label="Always Managed" />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Checkbox field="overwritable" label="Overwritable" />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Checkbox
            field="mandatoryOverwritable"
            label="Mandatory Overwritable"
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Checkbox
            field="adminMinPriceOverwritable"
            label="Admin Min Price Overwritable"
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Checkbox field="isLargeFormat" label="Is Large Format" />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Checkbox
            field="allowedTieredToner"
            label="Allowed Tiered Toner"
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Checkbox
            field="tonerExclusiveOptionAvailable"
            label="Toner Exclusive Option Available"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <Form.Numeric
            fluid
            field="defaultMinVolume"
            label="Default Min Volume"
            className="highlighted-input"
            placeholder="Default Min Volume..."
            decimalPlaces={0}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <Form.Numeric
            fluid
            field="vMinVolume"
            label="V Min Volume"
            className="highlighted-input"
            placeholder="V Min volume..."
          />
        </Grid.Column>
        <Grid.Column width={5}></Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {
          /* The Default Cost per Click only applies to standard Schedule Items. 
                      Large Format items Cost per click comes from partly the Printer settings and calculated based on image type */
          formProps.value && !formProps.value.isLargeFormat && (
            <Grid.Column width={5}>
              <Form.Numeric
                fluid
                field="defaultMinSalePrice"
                label={`Default Min Sale Price (${
                  process.env.USE_MINOR_CURRENCY
                    ? process.env.MINOR_CURRENCY
                    : process.env.MAJOR_CURRENCY
                })`}
                className="highlighted-input"
                placeholder="Default Min Sale Price..."
                decimalPlaces={6}
              />
            </Grid.Column>
          )
        }
        <Grid.Column width={6}>
          <Form.Numeric
            fluid
            field="minSalePrice"
            label={`Min Sale Price (${
              process.env.USE_MINOR_CURRENCY
                ? process.env.MINOR_CURRENCY
                : process.env.MAJOR_CURRENCY
            })`}
            className="highlighted-input"
            placeholder="Min Sale Price..."
            decimalPlaces={6}
          />
        </Grid.Column>
        <Grid.Column width={5}>
          <Form.Numeric
            fluid
            field="vMinSalePrice"
            label={`V Min Sale Price (${
              process.env.USE_MINOR_CURRENCY
                ? process.env.MINOR_CURRENCY
                : process.env.MAJOR_CURRENCY
            })`}
            className="highlighted-input"
            placeholder="V Min Sale Price..."
            decimalPlaces={6}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <Form.Input
            fluid
            field="vatCode"
            label="VAT Code"
            className="highlighted-input"
            placeholder="VAT Code..."
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <Form.Numeric
            fluid
            field="settlementDiscountPercentage"
            label="Settlement Discount %"
            className="highlighted-input"
            placeholder="Settlement Discount %..."
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Form.Input
            fluid
            field="excessInvoiceNominalCode"
            label="Excess Invoice Nominal Code"
            className="highlighted-input"
            placeholder="Excess Invoice Nominal Code..."
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Input
            fluid
            field="minimumInvoiceNominalCode"
            label="Minimum Invoice Nominal Code"
            className="highlighted-input"
            placeholder="Minimum Invoice Nominal Code..."
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <MeteredServiceDropdown
            field="dependencies"
            label="Dependants"
            multiple
            fluid
            className="highlighted-input"
            dealerOfficeId={dealerOfficeId}
            isLargeFormat={!!(formProps.value && formProps.value.isLargeFormat)}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Input
            fluid
            field="note"
            label="Note"
            className="highlighted-input"
            placeholder="Note..."
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <Form.Numeric
            fluid
            field="vMinPercentageTier1"
            label="V Min % Tier 1"
            className="highlighted-input"
            placeholder="V Min % Tier 1..."
            decimalPlaces={2}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <Form.Numeric
            fluid
            field="vMaxPercentageTier1"
            label="V Max % Tier 1"
            className="highlighted-input"
            placeholder="V Max % Tier 1..."
            decimalPlaces={2}
          />
        </Grid.Column>
        <Grid.Column width={5}>
          <Form.Numeric
            fluid
            field="tier1DefaultPercentage"
            label="Tier 1 Default %"
            className="highlighted-input"
            placeholder="Tier 1 Default %..."
            decimalPlaces={2}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4}>
          <Form.Numeric
            fluid
            field="vMaxPercentageTier2"
            label="V Max % Tier 2"
            className="highlighted-input"
            placeholder="V Max % Tier 2..."
            decimalPlaces={2}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <Form.Numeric
            fluid
            field="tier2DefaultPercentage"
            label="Tier 2 Default %"
            className="highlighted-input"
            placeholder="Tier 2 Default %..."
            decimalPlaces={2}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <Form.Numeric
            fluid
            field="vMinValueTier2"
            label="V Min Value Tier 2"
            className="highlighted-input"
            placeholder="V Min Value Tier 2..."
            decimalPlaces={2}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <Form.Numeric
            fluid
            field="tier2DefaultValue"
            label="Tier 2 Default Value"
            className="highlighted-input"
            placeholder="Tier 2 Default Value..."
            decimalPlaces={2}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <Form.Numeric
            fluid
            field="vMaxPercentageTier3"
            label="V Max % Tier 3"
            className="highlighted-input"
            placeholder="V Max % Tier 3..."
            decimalPlaces={2}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <Form.Numeric
            fluid
            field="vMinValueTier3"
            label="V Min Value Tier 3"
            className="highlighted-input"
            placeholder="V Min Value Tier 3..."
            decimalPlaces={2}
          />
        </Grid.Column>
        <Grid.Column width={5}>
          <Form.Numeric
            fluid
            field="tier3DefaultValue"
            label="Tier 3 Default Value"
            className="highlighted-input"
            placeholder="Tier 3 Default Value..."
            decimalPlaces={2}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    {!loading && (
      <Form.Trigger
        field="isLargeFormat"
        target="dependencies"
        action="clear"
      />
    )}
  </Form>
);

export default MeteredServiceForm;
