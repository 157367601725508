import React from "react";
import { Table } from "semantic-ui-react";
import { getCSD } from ".";
import { ListBuilder } from "@redriver/cinnamon";
import { Header, numericFormatter } from "components/displays/DisplayHelper";

export const ContractSettlement = () => {
  const headers = [
    { title: "" },
    { title: "Volume" },
    { title: `Customer Minimum Payments (${process.env.MAJOR_CURRENCY})` },
    { title: `Schedule Settlement Values (${process.env.MAJOR_CURRENCY})` },
  ];

  return (
    <React.Fragment>
      <ListBuilder
        loadAction={getCSD}
        renderList={(data) =>
          data.csda && (
            <Table className="details csd">
              <Header columns={headers} />
              <Table.Body>
                {data.csda.map((d) => (
                  <TableRow key={d.id} data={d} />
                ))}
                <TableRow
                  data={{
                    title: "Quarterly Total",
                    volume: data.totalVolume,
                    minimumPayment: data.totalMinimumPayment,
                    settlementValues: data.totalSettlementValue,
                  }}
                />
                <EmptyRow></EmptyRow>
                <FooterRow
                  data={{
                    title: "Schedule Discount %",
                    discount: data.scheduleDiscount,
                  }}
                />
              </Table.Body>
            </Table>
          )
        }
      ></ListBuilder>
      <br />
      <p>
        Schedule Discount % Calculation = (1 - Schedule Settlement Value
        Quarterly Total / Customer Minimum Quarterly Total Payment)
      </p>
    </React.Fragment>
  );
};

const EmptyRow = () => (
  <Table.Row className="no-border">
    <Table.Cell colSpan={4} className="no-border">
      <br />
    </Table.Cell>
  </Table.Row>
);

const TableRow = ({ data }) => (
  <Table.Row>
    <Table.Cell style={{ width: "25%" }}>{data.title}</Table.Cell>
    <Table.Cell style={{ width: "25%" }}>
      {numericFormatter(data.volume, 0, 0)}
    </Table.Cell>
    <Table.Cell style={{ width: "25%" }}>
      {numericFormatter(data.minimumPayment)}
    </Table.Cell>
    <Table.Cell style={{ width: "25%" }}>
      {numericFormatter(data.settlementValues)}
    </Table.Cell>
  </Table.Row>
);

const FooterRow = ({ data }) => (
  <Table.Row className="footer">
    <Table.Cell>
      <b>{data.title}</b>
    </Table.Cell>
    <Table.Cell>
      <b>{numericFormatter(data.discount)}</b>
    </Table.Cell>
    <Table.Cell className="empty"></Table.Cell>
    <Table.Cell className="empty"></Table.Cell>
  </Table.Row>
);
