import React from "react";
import { IconButton } from "features/../../../shared/components/buttons";
import { ModalBuilder } from "@redriver/cinnamon";
import { deletePrinter } from "./actions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import { TranslatedModal } from "../../../../components/modals";

const DeletePrinter = ({
  as: As = IconButton,
  onSubmitted,
  printer,
  ...props
}) => (
    <PermissionCheck target={Targets.LargeFormat} action={Actions.Delete}>
      <ModalBuilder
        submitAction={deletePrinter}
        submitParams={{ printerId: printer.id }}
        onSubmitted={onSubmitted}
        renderTrigger={showModal => (
          <As onClick={showModal} {...props}>
            Delete
        </As>
        )}
        renderModal={modalProps => (
          <TranslatedModal.Confirmation
            {...modalProps}
            header="Delete Printer"
            submitLabel="Delete Printer"
          >
            <p>
              Are you sure that you wish to remove the printer '{printer.model}
              '?
          </p>
          </TranslatedModal.Confirmation>
        )}
      />
    </PermissionCheck>
  );

export default DeletePrinter;
