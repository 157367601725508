import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";

const FileUploadModalWrapper = ({
  children,
  icon,
  floated,
  linkText,
  title,
  submitLabel,
  submitIcon,
  modalClassName,
  buttonClassName,
  size,
  submitAction,
  submitParams,
  reloadDocumentList,
}) => {
  return (
    <ModalBuilder
      withForm
      submitAction={submitAction}
      submitParams={submitParams}
      onSubmitted={() => reloadDocumentList()}
      renderTrigger={(showModal) => (
        <Button
          primary
          icon={icon}
          floated={floated}
          content={linkText || title}
          onClick={showModal}
          className={buttonClassName}
        />
      )}
      renderModal={(modalProps, formProps) => (
        <Modal.Upload
          submitLabel={submitLabel}
          submitIcon={submitIcon}
          header={title}
          className={modalClassName}
          size={size}
          {...modalProps}
        >
          <Form {...formProps}>{children}</Form>
        </Modal.Upload>
      )}
    />
  );
};

export default FileUploadModalWrapper;
