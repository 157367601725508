import React, { forwardRef } from "react";
import PaperInclusiveRestrictions from "../../RestrictionSettings/PaperInclusiveRestrictions";
import AccordionHelper from "components/displays/AccordionHelper";

const PaperDefaultSettings = forwardRef(
  ({ settingsId, toggleVisibility, collapsed }) => (
    <React.Fragment>
      <AccordionHelper
        title="Paper Inclusive Settings"
        toggleVisibility={toggleVisibility}
        collapsed={collapsed}
      >
        <PaperInclusiveRestrictions settingsId={settingsId} />
      </AccordionHelper>
    </React.Fragment>
  )
);

export default PaperDefaultSettings;
