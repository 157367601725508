import React from "react";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import {
  getRateTable,
  updateRateTable,
} from "features/../../../shared/features/Settings/Rates/actions.js";
import { Button } from "semantic-ui-react";
import { Form, FormBuilder } from "@redriver/cinnamon";
import { IconButton } from "features/../../../shared/components/buttons";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";

const getLabel = (name) => {
  return (
    <div className="default-grid-field-label-container">
      <div className="defaults-grid-title">
        <p>{name}</p>
        <span className="required-star">*</span>
      </div>
    </div>
  );
};
const RRPFieldTable = ({ rateGroupId, filters }) => {
  return (
    <React.Fragment>
      <FormBuilder
        initialReadOnly
        loadAction={getRateTable}
        loadParams={{
          rateGroupId: rateGroupId,
          funderType: filters.funderType,
          financeType: filters.financeType,
          paymentMethod: filters.paymentMethod,
        }}
        submitAction={updateRateTable}
        submitParams={{
          rateGroupId: rateGroupId,
          funderType: filters.funderType,
          financeType: filters.financeType,
          paymentMethod: filters.paymentMethod,
        }}
        renderLoading={false}
        renderSubmitting={false}
        renderForm={(formProps, state, events) => {
          return (
            <React.Fragment>
              <div className="rrp-input">
                <Form {...formProps}>
                  <Form.Numeric
                    field="rrpPercentage"
                    label={getLabel("RRP Allowance")}
                    inline={true}
                    placeholder="RRP Allowance..."
                    required
                    decimalPlaces={2}
                    minValue={0}
                    maxValue={999.0}
                    formatValue
                    width={12}
                    fluid
                  />
                </Form>
              </div>
              <div className="rrp-buttons">
                <PermissionCheck target={Targets.Rates} action={Actions.Edit}>
                  <div>
                    <IconButton
                      primary
                      icon={state.readOnly ? Pen : Save}
                      content={state.readOnly ? "Edit" : "Save"}
                      floated="right"
                      onClick={async () => {
                        if (state.readOnly) {
                          await events.onRefresh();
                          events.onToggleReadOnly();
                        } else {
                          events.onSubmit({ reset: ["readOnly"] });
                        }
                      }}
                      className={
                        !state.readOnly ? "positive-title-action" : null
                      }
                      loading={state.loading || state.submitting}
                      disabled={state.loading || state.submitting}
                    />
                    {!state.readOnly && (
                      <Button
                        primary
                        className="cancel-title-action"
                        floated="right"
                        content="Cancel"
                        onClick={events.onReset}
                      />
                    )}
                  </div>
                </PermissionCheck>
              </div>
            </React.Fragment>
          );
        }}
      />
    </React.Fragment>
  );
};

export default RRPFieldTable;
