import React, { useEffect, useState } from "react";
import { getDealerOffice } from "./actions";
import OfficeHeader from "./OfficeHeader";
import DealerOfficeTabs from "./DealerOfficeTabs";
import { useApiAction, PageLoader } from "@redriver/cinnamon";

const DealerOffice = ({ officeId }) => {
  const [officeInfo, setOfficeInfo] = useState(null);
  const [{ busy, success, response }] = useApiAction(
    () => getDealerOffice(officeId),
    { lazy: false },
  );
  useEffect(() => {
    if (busy || !success || !response) return;
    setOfficeInfo(response);
  }, [response, busy, success]);

  if (!officeInfo) return <PageLoader />;

  return (
    <div className="dealer-office-content">
      <h2>Office {officeInfo.name}</h2>
      <OfficeHeader data={officeInfo} />
      <DealerOfficeTabs officeInfo={officeInfo} />
    </div>
  );
};

export default DealerOffice;
