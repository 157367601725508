import React from "react";
import moment from "moment";
import { Grid } from "semantic-ui-react";
import { SidePanel, Form } from "@redriver/cinnamon";
import { FilterButton } from "features/../../../shared/components/buttons";
import { UploadEntityTypeDropdown } from "features/../../../shared/components/forms";
import { DealerOfficesTypeAheadLookup } from "features/../../../shared/features/Settings";

export const FieldNames = {
  Type: "type",
  Supplier: "supplier",
  StartDate: "startDate",
  EndDate: "endDate",
  Processed: "processed",
  Valid: "valid",
};

const DataUploadFilters = ({
  title,
  filters,
  onChange,
  formProps,
  onReset,
}) => {
  const booleanOptions = [
    { text: "Yes", value: true },
    { text: "No", value: false },
  ];
  return (
    <SidePanel
      closeIcon
      trigger={<FilterButton />}
      className="price-increase-filter-side-panel"
    >
      <div>
        <div className="ui header filters-title">
          <div className="title-text">{title}</div>
          <div className="clear-button" onClick={onReset}>
            (clear)
          </div>
        </div>
        <Form {...formProps} className="highlighted-inputs">
          <div className="ui form highlighted-inputs">
            <Grid columns={2}>
              <Grid.Column width={16}>
                <UploadEntityTypeDropdown
                  field={FieldNames.Type}
                  fluid
                  clearable
                  multiple
                  label="Type"
                  placeholder="Select Type..."
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <DealerOfficesTypeAheadLookup
                  field={FieldNames.Supplier}
                  fluid
                  clearable
                  multiple
                  label="Office"
                  placeholder="Select office..."
                />
              </Grid.Column>
              <Grid.Column>
                <div className="flex align-center date-container">
                  <Form.Date
                    dateFormat="DD MMM YYYY"
                    local
                    field={FieldNames.StartDate}
                    label="From"
                    className="date-input highlighted-input"
                    maxDate={
                      formProps.value && formProps.value[FieldNames.EndDate]
                        ? moment(formProps.value[FieldNames.EndDate])
                        : null
                    }
                  />
                  <Form.Clearer field={FieldNames.StartDate} />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="flex align-center date-container">
                  <Form.Date
                    dateFormat="DD MMM YYYY"
                    local
                    field={FieldNames.EndDate}
                    label="To"
                    className="date-input highlighted-input"
                    minDate={
                      formProps.value && formProps.value[FieldNames.StartDate]
                        ? moment(formProps.value[FieldNames.StartDate])
                        : null
                    }
                  />
                  <Form.Clearer field={FieldNames.EndDate} />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="field">
                  <label>
                    Processed
                    <a
                      onClick={() => {
                        const nextFilters = {
                          ...filters,
                        };
                        nextFilters.processed = [true, false];
                        onChange(nextFilters);
                      }}
                      className="select-all-button"
                    >
                      (Select All)
                    </a>
                  </label>
                  <div>
                    <Form.CheckboxGroup
                      field={FieldNames.Processed}
                      options={booleanOptions}
                    />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="field">
                  <label>
                    Valid
                    <a
                      onClick={() => {
                        const nextFilters = {
                          ...filters,
                        };
                        nextFilters.valid = [true, false];
                        onChange(nextFilters);
                      }}
                      className="select-all-button"
                    >
                      (Select All)
                    </a>
                  </label>
                  <div>
                    <Form.CheckboxGroup
                      field={FieldNames.Valid}
                      options={booleanOptions}
                    />
                  </div>
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </Form>
      </div>
    </SidePanel>
  );
};

export default DataUploadFilters;
