import React from "react";
import { Form } from "@redriver/cinnamon";
import { LargeFormatInkUsageDropdownLookup } from "./lookup";
import FormArrayDeleteModal from "features/../../../shared/components/forms/FormArrayDeleteModal";
import { useTranslation } from "react-i18next";

const PrinterUsageTypeTable = ({ dealerOfficeId }) => {
  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'largeFormat.printer.PrinterUsageTypeTable'});
  return (
    <div className="large-format-modal-list">
      <div className="large-format-list-item">
        <div className="lf-col lf-col-0" />
        <div className="lf-col lf-col-large">{t("inkUsage")}</div>
        <div className="lf-col lf-col-2">{t("a1MlOfInkUsed")}</div>
      </div>
      <Form.Array
        field="inkUsageTypes"
        className="paper-widths-table"
        arrayKey="id"
        propagateUpdates="always"
        fluid
      >
        <div className="large-format-list-item">
          <FormArrayDeleteModal
            nameOfRemoval={t("usageType")}
            className="lf-col lf-col-0"
          />
          <LargeFormatInkUsageDropdownLookup
            field="inkUsageId"
            placeholder={t("UsageTypePlaceholder")}
            className="lf-col lf-col-large"
            dealerOfficeId={dealerOfficeId}
          />
          <Form.Numeric
            field="value"
            required
            className="lf-col lf-col-2"
            requiredError={t("requiredError")}
          />
        </div>
      </Form.Array>
    </div>
  );
};

export default PrinterUsageTypeTable;
