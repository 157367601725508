import React from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";

const InfoModalWrapper = ({
  children,
  icon,
  floated,
  linkText,
  title,
  submitLabel,
  submitIcon,
  modalClassName,
  buttonClassName,
  size,
}) => {
  return (
    <React.Fragment>
      <ModalBuilder
        renderTrigger={(showModal) => (
          <Button
            primary
            icon={icon}
            floated={floated}
            content={linkText || title}
            onClick={showModal}
            className={buttonClassName}
          />
        )}
        renderModal={(modalProps) => (
          <Modal
            submitLabel={submitLabel}
            submitIcon={submitIcon}
            header={title}
            className={modalClassName}
            size={size}
            {...modalProps}
          >
            {children}
          </Modal>
        )}
      />
    </React.Fragment>
  );
};

export default InfoModalWrapper;
