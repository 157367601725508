import React from "react";

const Widget = ({ children, className, condition, title }) =>
  !condition ? null : (
    <div className={`widget ${className || ""}`}>
      {title && <h2>{title}</h2>}
      {children}
    </div>
  );
export default Widget;
