import React from "react";
import { Wizard } from "@redriver/cinnamon";
import { Menu } from "semantic-ui-react";
import { ContentPanel } from "components/displays";
import { CFASummary, CFADetails, CFAHeader } from "./";

const CFASteps = {
  Summary: "summary",
  Detail: "detail",
};

const CFA = ({ scheduleId }) => {
  const NavigationControls = ({ steps, activeStep, onGoToStep }) => {
    return steps.map((s) => (
      <Menu.Item
        value={0}
        key={s.key}
        active={s === activeStep}
        onClick={() => onGoToStep(s.key)}
      >
        {s.title}
      </Menu.Item>
    ));
  };

  const stepData = [
    {
      key: CFASteps.Summary,
      title: "CFA Summary",
    },
    {
      key: CFASteps.Detail,
      title: "CFA Detail",
    },
  ];

  return (
    <div className="schedules-cfa-container">
      <br />
      <ContentPanel
        title="CFA"
        wrapperCssClass="outline-grid "
        headerCssClass="defaults-page-title header"
      >
        <div className="padded">
          <Wizard steps={stepData}>
            <div className="products-wizard-navigation">
              <Wizard.Nav
                render={(wizardContext) => (
                  <Menu>
                    <NavigationControls {...wizardContext} />
                  </Menu>
                )}
              />
            </div>
            <CFAHeader />
            <Wizard.Step
              step={CFASteps.Summary}
              render={() => <CFASummary scheduleId={scheduleId} />}
            />
            <Wizard.Step
              step={CFASteps.Detail}
              render={() => <CFADetails scheduleId={scheduleId} />}
            />
          </Wizard>
        </div>
      </ContentPanel>
    </div>
  );
};

export default CFA;
