import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { ContentPanel } from "components/displays";
import {
  getDataUploads,
  approveSettingsUpload,
  DataUploadFilters,
  UploadTemplate,
  DownloadFile,
  DownloadTemplate,
} from "./";
import {
  dateFormatter,
  yesNoFormatter,
  blankDataFormatter,
} from "components/displays/DisplayHelper";
import { Button } from "semantic-ui-react";
import UploadEntityType from "features/../../../shared/constants/enums/UploadEntityType";
import {
  ModalBuilder,
  Modal,
  ListBuilder,
  usePreferences,
  ActionBar,
  FormBuilder,
} from "@redriver/cinnamon";
import {
  TableConfigurator,
  ConfigurableTable,
} from "features/CustomConfig/TableConfig";
import { TablePagination } from "components/pagination";

const DataUploadsList = () => {
  const { preferences } = usePreferences();
  const [pageSizeConfig, setPageSizeConfig] = useState(
    preferences.dataUploadsTableConfigPageSize
      ? preferences.dataUploadsTableConfigPageSize
      : 25,
  );
  return (
    <ListBuilder
      withTable
      initialPagination={{ pageSize: pageSizeConfig, pageNumber: 1 }}
      loadAction={getDataUploads}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      loadParams={{ pageSize: pageSizeConfig }}
      renderList={(tableProps, state, events) => (
        <DataUploadsListContent
          tableProps={tableProps}
          state={state}
          events={events}
          pageSizeConfig={pageSizeConfig}
          setPageSizeConfig={setPageSizeConfig}
          preferences={preferences}
        />
      )}
    />
  );
};

const DataUploadsListContent = ({
  tableProps,
  state,
  events,
  pageSizeConfig,
  setPageSizeConfig,
  preferences,
}) => {
  const [config, setConfig] = useState(preferences.dataUploadsTableConfig);

  useEffect(() => {
    setConfig(preferences.dataUploadsTableConfig);
  }, [preferences.dataUploadsTableConfig]);

  useEffect(() => {
    preferences.dataUploadsTableConfigPageSize &&
      setPageSizeConfig(preferences.dataUploadsTableConfigPageSize);
  }, [preferences.dataUploadsTableConfigPageSize, setPageSizeConfig]);

  const columns = [
    {
      field: "settingsUploadEntityType",
      title: "Type",
      render: (item) => UploadEntityType[item.type],
      sortable: false,
    },
    { field: "createdBy", title: "Uploaded By", sortable: false },
    {
      field: "createdUtc",
      title: "Uploaded Date",
      render: (item) => dateFormatter(item.createdUtc),
    },
    { field: "dealerOffice", title: "Supplier Office" },
    {
      field: "requiresApproval",
      title: "Requires Approval",
      render: (item) => yesNoFormatter(item.requiresApproval),
    },
    {
      field: "approvedBy1",
      title: "First Approver",
      sortable: false,
    },
    {
      field: "approvedBy1Utc",
      title: "First Approver Date",
      render: (item) =>
        blankDataFormatter(dateFormatter(item.approvedBy1Utc), "-"),
    },
    {
      field: "approvedBy2",
      title: "Second Approver",
      sortable: false,
    },
    {
      field: "approvedBy2Utc",
      title: "Second Approver Date",
      render: (item) =>
        blankDataFormatter(dateFormatter(item.approvedBy2Utc), "-"),
    },
    {
      field: "processedUtc",
      title: "Processed Date",
      render: (item) =>
        blankDataFormatter(dateFormatter(item.processedUtc), "-"),
    },
    {
      field: "isValid",
      title: "Valid",
      render: (item) => yesNoFormatter(item.isValid),
    },
    {
      field: "id",
      sortable: false,
      render: (item) => (
        <Button.Group basic size="medium">
          <Approve
            templateName={UploadEntityType[item.settingsUploadEntityType]}
            disabled={!item.requiresApproval}
            lastApproval={item.ApprovedBy1 && item.requiresApproval}
            id={item.id}
            reloadTable={events.onRefresh}
          />
          <DownloadFile id={item.id} />
        </Button.Group>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="uploads-panel-container">
        <ContentPanel
          title="Data Uploads"
          wrapperCssClass="outline-grid data-uploads-list"
          headerCssClass="defaults-page-title"
          headerContent={
            <FilterBar
              state={state}
              events={events}
              config={config}
              columns={columns}
              initialPageSize={25}
              pageSizeConfig={pageSizeConfig}
            ></FilterBar>
          }
        >
          <div className="spaced">
            <ConfigurableTable
              state={state}
              events={events}
              tableProps={{
                ...tableProps,
                className: classNames(tableProps.className, "data-uploads"),
              }}
              emptyMessage="No data uploads found"
              columns={columns}
              config={config}
              tableClassName="data-uploads"
            />
            <div className="padded">
              <TablePagination
                pageSize={pageSizeConfig}
                pageNumber={state.pagination.pageNumber}
                onChange={events.onChangePagination}
                totalItems={state.total}
              />
            </div>
          </div>
        </ContentPanel>
      </div>
    </React.Fragment>
  );
};

const FilterBar = ({
  state,
  events,
  config,
  columns,
  initialPageSize,
  pageSizeConfig,
}) => (
  <FormBuilder
    value={state.filters}
    onChange={events.onChangeFilters}
    renderForm={(formProps) => (
      <ActionBar className="filter-display">
        <ActionBar.Item fluid></ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <DownloadTemplate />
        </ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <UploadTemplate onSubmitted={events.onRefresh} />
        </ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <TableConfigurator
            config={config}
            columns={columns}
            configId="dataUploadsTableConfig"
            titlePreFix="Data Uploads"
            initialPageSize={initialPageSize}
            pageSizeConfig={pageSizeConfig}
          ></TableConfigurator>
        </ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <DataUploadFilters
            title="Filters"
            filters={state.filters}
            onChange={events.onChangeFilters}
            formProps={formProps}
            onReset={events.onReset}
          />
        </ActionBar.Item>
      </ActionBar>
    )}
  />
);

const Approve = ({ templateName, disabled, lastApproval, id, reloadTable }) => {
  return (
    <ModalBuilder
      submitAction={approveSettingsUpload}
      submitParams={id}
      onSubmitted={() => reloadTable()}
      renderTrigger={(showModal) => (
        <Button
          title="Approve"
          primary
          onClick={showModal}
          icon="check"
          disabled={disabled}
        />
      )}
      renderModal={(modalProps) => (
        <Modal.Confirmation {...modalProps} header="Approve">
          Are you sure you wish to approve {templateName}
          {lastApproval
            ? ", approving this will trigger the data import and all associated data could change"
            : ""}
          ?
        </Modal.Confirmation>
      )}
    />
  );
};

export default DataUploadsList;
