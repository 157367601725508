import { mockSuccess, MockArrayData } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULES/ITEM";

const ActionTypes = {
  GetItems: `${NAMESPACE}/GET_ITEMS`,
};

const items = new MockArrayData([
  {
    id: 1,
    unit: "B1",
    managed: true,
    minVol: 150000,
    cpi: {
      capital: 1.2,
      services: 3.524,
      cpi: 0.356,
      cpu: 5.08,
    },
    total: {
      capital: 1800,
      services: 5286,
      cpi: 534,
      cpu: 7620,
    },
    tonerExclusive: true,
    tieredToner: true,
  },
  {
    id: 2,
    unit: "C1",
    managed: true,
    minVol: 360000,
    cpi: {
      capital: 3.0,
      services: 3.0,
      cpi: 3.0,
      cpu: 7.724,
    },
    total: {
      capital: 1800,
      services: 5286,
      cpi: 534,
      cpu: 7620,
    },
    tonerExclusive: false,
    tieredToner: false,
  },
  {
    id: 3,
    unit: "S1",
    managed: false,
    minVol: 1500,
    cpi: {
      capital: 0.15,
      services: 0.15,
      cpi: 0.15,
      cpu: 4.874,
    },
    total: {
      capital: 1800,
      services: 5286,
      cpi: 534,
      cpu: 7620,
    },
    tonerExclusive: false,
    tieredToner: false,
  },
  {
    id: 4,
    unit: "L1",
    managed: true,
    minVol: 100,
    cpi: {
      capital: 46,
      services: 46,
      cpi: 46,
      cpu: 47.393,
    },
    total: {
      capital: 1800,
      services: 5286,
      cpi: 534,
      cpu: 7620,
    },
    tonerExclusive: false,
    tieredToner: false,
  },
  {
    id: 5,
    unit: "P1",
    managed: false,
    minVol: 100,
    cpi: {
      capital: 46,
      services: 46,
      cpi: 46,
      cpu: 47.393,
    },
    total: {
      capital: 1800,
      services: 5286,
      cpi: 534,
      cpu: 7620,
    },
    tonerExclusive: false,
  },
  {
    id: 6,
    unit: "SS",
    managed: false,
    minVol: 100,
    cpi: {
      capital: 46,
      services: 46,
      cpi: 46,
      cpu: 47.393,
    },
    total: {
      capital: 1800,
      services: 5286,
      cpi: 534,
      cpu: 7620,
    },
    tonerExclusive: false,
  },
]);

export const getItems = () => {
  return mockSuccess(ActionTypes.GetItems, {
    delay: 400,
    response: items,
  });
};
