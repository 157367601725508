import React from "react";
import { AccordionContentPanel } from "components/displays";
import { ListBuilder } from "@redriver/cinnamon";
import { Table, TableCell, TableRow } from "semantic-ui-react";
import {
  getHistoricalCutSheetPaperInclusive,
  getHistoricalMeterServices,
  getHistoricalQuarterlyServices,
  getHistoricalTotals,
} from "./actions";
import { SortableHeader } from "../../../../../../shared/components/tables/sortableTable";
import {
  numericFormatter,
  yesNoFormatter,
  dateFormatter,
} from "components/displays/DisplayHelper";

const TotalHeaders = [
  { field: "unit", title: "Unit", className: "rounded-left-border" },
  { field: "managed", title: "Managed" },
  { field: "volume", title: "Volume" },
];

const QuarterlyServiceHeaders = [
  { field: "service", title: "Service", className: "rounded-left-border" },
  { field: "priceIncrease", title: "Price Increase" },
];

const CutSheetHeader = [
  {
    field: "service",
    title: "",
    sortable: false,
    className: "rounded-left-border",
  },
];

const ValueHeader = () => {
  return [
    {
      field: "value",
      title: "Value (£)",
      className: "strong-bdr-left",
    },
  ];
};

const CustomValueHeader = () => {
  return ValueHeader().concat([
    {
      field: "customValue",
      title: "Custom Value (£)",
    },
    {
      field: "change",
      title: "Change (%)",
    },
  ]);
};

const ChargedCPIHeaders = () => {
  return [
    { field: "cpu", title: "CPU (p)", className: "strong-bdr-left" },
    { field: "value", title: "Value (£)" },
  ];
};

const ChargedCPIWithChangeHeader = () => {
  return ChargedCPIHeaders().concat([
    {
      field: "change",
      title: "Change (%)",
    },
  ]);
};

const HistoricIncreases = () => {
  return (
    <React.Fragment>
      <AccordionContentPanel
        title="Totals"
        hideTitle={false}
        wrapperCssClass="outline-grid"
        headerCssClass="defaults-page-title"
      >
        <div className="padded">
          <TotalCharges loadAction={getHistoricalTotals} />
        </div>
      </AccordionContentPanel>
      <br />
      <AccordionContentPanel
        title="Meter Services"
        hideTitle={false}
        wrapperCssClass="outline-grid"
        headerCssClass="defaults-page-title"
      >
        <div className="padded">
          <MeterServices loadAction={getHistoricalMeterServices} />
        </div>
      </AccordionContentPanel>
      <br />
      <AccordionContentPanel
        title="Quarterly Services"
        hideTitle={false}
        wrapperCssClass="outline-grid"
        headerCssClass="defaults-page-title"
      >
        <div className="padded">
          <QuarterlyServices loadAction={getHistoricalQuarterlyServices} />
        </div>
      </AccordionContentPanel>
      <br />
      <AccordionContentPanel
        title="Cut Sheet Paper Inclusive"
        hideTitle={false}
        wrapperCssClass="outline-grid"
        headerCssClass="defaults-page-title"
      >
        <div className="padded">
          <CutSheetPaperInclusive
            loadAction={getHistoricalCutSheetPaperInclusive}
          />
        </div>
      </AccordionContentPanel>
      <br />
    </React.Fragment>
  );
};

const TotalCharges = ({ loadAction }) => {
  return (
    <div className="padded">
      <div className="schedule-services-body-container">
        <ListBuilder
          withTable
          loadAction={loadAction}
          renderList={(tableProps, state, events) => (
            <div className="horizontal-scrollbar">
              <Table className="schedule-price-increase  total-charges-table">
                <CustomTableHeader
                  date1={tableProps.data.date1}
                  date2={tableProps.data.date2}
                  date3={tableProps.data.date3}
                  date4={tableProps.data.date4}
                />
                <SortableHeader
                  columns={TotalHeaders.concat(ChargedCPIHeaders())
                    .concat(ChargedCPIWithChangeHeader())
                    .concat(ChargedCPIWithChangeHeader())
                    .concat(ChargedCPIWithChangeHeader())}
                  state={state}
                  events={events}
                />
                <Table.Body>
                  {tableProps.data.totals &&
                    tableProps.data.totals.map((d, index) => (
                      <Table.Row key={index}>
                        <Table.Cell className="border-left empty-footer">
                          {d.unit}
                        </Table.Cell>
                        <Table.Cell className="empty-footer">
                          {yesNoFormatter(d.managed, true)}
                        </Table.Cell>
                        <Table.Cell className="empty-footer">
                          {d.volume}
                        </Table.Cell>
                        <Table.Cell className="empty-footer strong-bdr-left right-align footer-strong-bdr-top">
                          {numericFormatter(d.charge._data[0].cpu, "-", 3)}
                        </Table.Cell>
                        <MinimumCells key={d.id} data={d} />
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </div>
          )}
        />
      </div>
    </div>
  );
};

const MeterServices = ({ loadAction }) => {
  return (
    <div className="padded">
      <div className="schedule-services-body-container">
        <ListBuilder
          withTable
          loadAction={loadAction}
          renderList={(tableProps, state, events) => (
            <div className="horizontal-scrollbar">
              <Table className="schedule-price-increase metered-services-table">
                <CustomTableHeader
                  date1={tableProps.data.date1}
                  date2={tableProps.data.date2}
                  date3={tableProps.data.date3}
                  date4={tableProps.data.date4}
                />
                <SortableHeader
                  columns={TotalHeaders.concat(ChargedCPIHeaders())
                    .concat(ChargedCPIWithChangeHeader())
                    .concat(ChargedCPIWithChangeHeader())
                    .concat(ChargedCPIWithChangeHeader())}
                  state={state}
                  events={events}
                />
                <Table.Body>
                  {tableProps.data.totals &&
                    tableProps.data.totals.map((d, index) => (
                      <Table.Row key={index}>
                        <Table.Cell className="empty-footer border-left">
                          {d.unit}
                        </Table.Cell>
                        <Table.Cell className="empty-footer">
                          {yesNoFormatter(d.managed, true)}
                        </Table.Cell>
                        <Table.Cell className="empty-footer">
                          {d.volume}
                        </Table.Cell>
                        <Table.Cell className="empty-footer strong-bdr-left right-align footer-strong-bdr-top">
                          {numericFormatter(d.charge._data[0].cpu, "-", 3)}
                        </Table.Cell>
                        <MinimumCells key={d.id} data={d} />
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </div>
          )}
        />
      </div>
    </div>
  );
};

const QuarterlyServices = ({ loadAction }) => {
  return (
    <div className="padded">
      <div className="schedule-services-body-container">
        <ListBuilder
          withTable
          loadAction={loadAction}
          renderList={(tableProps, state, events) => (
            <div className="horizontal-scrollbar">
              <Table className="schedule-price-increase historic-qtr-services-table">
                <CustomTableHeader
                  date1={tableProps.data.date1}
                  date2={tableProps.data.date2}
                  date3={tableProps.data.date3}
                  date4={tableProps.data.date4}
                  width1={2}
                  width2={1}
                />
                <SortableHeader
                  columns={QuarterlyServiceHeaders.concat(ValueHeader())
                    .concat(CustomValueHeader())
                    .concat(CustomValueHeader())
                    .concat(CustomValueHeader())}
                  state={state}
                  events={events}
                />
                <Table.Body>
                  {tableProps.data.services &&
                    tableProps.data.services.map((d, index) => (
                      <Table.Row key={index}>
                        <Table.Cell
                          style={{ width: "20%" }}
                          className="empty-footer border-left "
                        >
                          <span>{d.service}</span>
                        </Table.Cell>
                        <Table.Cell
                          style={{ width: "10%" }}
                          className="empty-footer"
                        >
                          {yesNoFormatter(d.priceIncrease, true)}
                        </Table.Cell>
                        <MinimumCellsAlt data={d} />
                      </Table.Row>
                    ))}
                </Table.Body>
                <Table.Footer>
                  <TableRow>
                    <TableCell></TableCell>
                  </TableRow>
                  {tableProps.data.cpi && (
                    <TableRow>
                      <Table.Cell
                        colSpan={2}
                        className="strong-bdr-left strong-bdr-top strong-bdr-bottom"
                      >
                        <span>Service CPI</span>
                      </Table.Cell>
                      <MinimumCellsAlt data={tableProps.data.cpi} />
                    </TableRow>
                  )}
                </Table.Footer>
              </Table>
            </div>
          )}
        />
      </div>
    </div>
  );
};

const CutSheetPaperInclusive = ({ loadAction }) => {
  return (
    <div className="padded">
      <div className="schedule-services-body-container">
        <ListBuilder
          withTable
          loadAction={loadAction}
          renderList={(tableProps, state, events) => (
            <div className="horizontal-scrollbar">
              <Table className="schedule-price-increase historic-cut-sheet-table">
                <CustomTableHeader
                  date1={tableProps.data.date1}
                  date2={tableProps.data.date2}
                  date3={tableProps.data.date3}
                  date4={tableProps.data.date4}
                  width1={1}
                  width2={1}
                />
                <SortableHeader
                  columns={CutSheetHeader.concat(ValueHeader())
                    .concat(CustomValueHeader())
                    .concat(CustomValueHeader())
                    .concat(CustomValueHeader())}
                  state={state}
                  events={events}
                />
                <Table.Body>
                  {tableProps.data.totals &&
                    tableProps.data.totals.map((d, index) => (
                      <Table.Row key={index}>
                        <Table.Cell
                          style={{ width: "10%" }}
                          className="empty-footer border-left "
                        >
                          <span>{d.service}</span>
                        </Table.Cell>
                        <MinimumCellsAlt data={d} />
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </div>
          )}
        />
      </div>
    </div>
  );
};

const MinimumCells = ({ data }) => {
  return (
    <React.Fragment>
      <Table.Cell className="right-align highlight-footer footer-strong-bdr-top">
        {numericFormatter(data.charge._data[0].value, "-", 2)}
      </Table.Cell>
      <Table.Cell className="strong-bdr-left right-align empty-footer footer-strong-bdr-top">
        {numericFormatter(data.charge._data[1].cpu, "-", 3)}
      </Table.Cell>
      <Table.Cell className="right-align highlight-footer footer-strong-bdr-top">
        {numericFormatter(data.charge._data[1].value, "-", 2)}
      </Table.Cell>
      <Table.Cell className="highlight-footer footer-strong-bdr-top">
        {numericFormatter(data.charge._data[1].change, null, 1)}
      </Table.Cell>
      <Table.Cell className="strong-bdr-left right-align empty-footer footer-strong-bdr-top">
        {numericFormatter(data.charge._data[2].cpu, "-", 3)}
      </Table.Cell>
      <Table.Cell className="right-align  highlight-footer footer-strong-bdr-top">
        {numericFormatter(data.charge._data[2].value, "-", 2)}
      </Table.Cell>
      <Table.Cell className="highlight-footer footer-strong-bdr-top">
        {numericFormatter(data.charge._data[2].change, null, 1)}
      </Table.Cell>
      <Table.Cell className="strong-bdr-left right-align empty-footer footer-strong-bdr-top">
        {numericFormatter(data.charge._data[3].cpu, "-", 3)}
      </Table.Cell>
      <Table.Cell className="right-align highlight-footer footer-strong-bdr-top">
        {numericFormatter(data.charge._data[3].value, "-", 2)}
      </Table.Cell>
      <Table.Cell className="strong-bdr-right highlight-footer footer-strong-bdr-top">
        {numericFormatter(data.charge._data[3].change, null, 1)}
      </Table.Cell>
    </React.Fragment>
  );
};

const MinimumCellsAlt = ({ data }) => {
  return (
    <React.Fragment>
      <Table.Cell
        className="strong-bdr-left right-align"
        style={{ width: "9%" }}
      >
        {numericFormatter(data.charge[0].value, "-", data.noDp ? 0 : 2)}
      </Table.Cell>
      <Table.Cell
        className="strong-bdr-left right-align"
        style={{ width: "9%" }}
      >
        {numericFormatter(data.charge[1].value, "-", data.noDp ? 0 : 2)}
      </Table.Cell>
      <Table.Cell className="right-align" style={{ width: "9%" }}>
        {numericFormatter(data.charge[1].customValue, "-", data.noDp ? 0 : 2)}
      </Table.Cell>
      <Table.Cell style={{ width: "9%" }}>
        {numericFormatter(data.charge[1].change, "-", data.noDp ? 0 : 1)}
      </Table.Cell>
      <Table.Cell
        className="strong-bdr-left right-align"
        style={{ width: "9%" }}
      >
        {numericFormatter(data.charge[2].value, "-", data.noDp ? 0 : 2)}
      </Table.Cell>
      <Table.Cell className="right-align" style={{ width: "9%" }}>
        {numericFormatter(data.charge[2].customValue, "-", data.noDp ? 0 : 2)}
      </Table.Cell>
      <Table.Cell style={{ width: "9%" }}>
        {numericFormatter(data.charge[2].change, "-", data.noDp ? 0 : 1)}
      </Table.Cell>
      <Table.Cell
        className="strong-bdr-left right-align"
        style={{ width: "9%" }}
      >
        {numericFormatter(data.charge[3].value, "-", data.noDp ? 0 : 2)}
      </Table.Cell>
      <Table.Cell className="right-align" style={{ width: "9%" }}>
        {numericFormatter(data.charge[3].customValue, "-", data.noDp ? 0 : 2)}
      </Table.Cell>
      <Table.Cell className="strong-bdr-right" style={{ width: "9%" }}>
        {numericFormatter(data.charge[3].change, "-", data.noDp ? 0 : 1)}
      </Table.Cell>
    </React.Fragment>
  );
};

const CustomTableHeader = ({
  date1,
  date2,
  date3,
  date4,
  width1 = 3,
  width2 = 2,
}) => {
  return (
    <Table.Header>
      <React.Fragment>
        <TableRow>
          <Table.HeaderCell className="empty" colSpan={width1} />
          <Table.HeaderCell
            className="grey-highlight strong-bdr-left strong-bdr-top"
            colSpan={width2}
          >
            Charges - valid from {dateFormatter(date1)}
          </Table.HeaderCell>
          <Table.HeaderCell
            className="grey-highlight strong-bdr-top strong-bdr-left"
            colSpan={3}
          >
            Charges - valid from {dateFormatter(date2)}
          </Table.HeaderCell>
          <Table.HeaderCell
            className="grey-highlight strong-bdr-top strong-bdr-left"
            colSpan={3}
          >
            Charges - valid from {dateFormatter(date3)}
          </Table.HeaderCell>
          <Table.HeaderCell
            className="grey-highlight strong-bdr-top strong-bdr-left strong-bdr-right"
            colSpan={3}
          >
            Charges - valid from {dateFormatter(date4)}
          </Table.HeaderCell>
        </TableRow>
        <TableRow>
          <Table.Cell className="empty" colSpan={width1}></Table.Cell>
          <Table.Cell
            className="highlight strong-bdr-left"
            colSpan={width2}
          ></Table.Cell>
          <Table.Cell colSpan={3} className="highlight strong-bdr-left">
            <span>Minimum - Custom Quarter Service</span>
          </Table.Cell>
          <Table.Cell colSpan={3} className="highlight strong-bdr-left">
            <span>Minimum - Meter Only</span>
          </Table.Cell>
          <Table.Cell
            colSpan={3}
            className="highlight strong-bdr-left strong-bdr-right"
          >
            <span>Minimum - All Available</span>
          </Table.Cell>
        </TableRow>
      </React.Fragment>
    </Table.Header>
  );
};

export default HistoricIncreases;
