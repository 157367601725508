import React from "react";
import { Grid, Segment } from "semantic-ui-react";
import { Form } from "@redriver/cinnamon";

const AddProductSchedule = () => {
  return (
    <Form>
      <Form.Group className="group">
        <Form.Input
          field="identifier"
          label="Identifier Number"
          placeholder="identifier"
          required
        />
        <Form.Input
          field="model"
          label="Manufacturer & Model"
          placeholder="Manufacturer & Model"
          required
        />
      </Form.Group>
      <Form.Group className="group">
        <Form.Dropdown field="type" label="Type" placeholder="Type" required />
        <Form.Dropdown
          field="status"
          label="Status"
          placeholder="Status"
          required
        />
      </Form.Group>
      <Form.Group className="group">
        <Form.Input
          field="postcode"
          label="Location Postcode"
          placeholder="Location Postcode"
          required
        />
        <Form.Dropdown
          field="consumablesExclusive"
          label="Consumables Exclusive"
          placeholder="Consumables Exclusive"
          required
        />
      </Form.Group>
      <Form.Group className="group">
        <Form.Dropdown
          field="remoteServices"
          label="Remote Services"
          placeholder="Remote Services"
          required
        />
        <Form.Input
          field="cfaRef"
          label="CFA Reference"
          placeholder="CFA Reference"
          required
        />
      </Form.Group>
      <Form.Group className="group">
        <Form.Dropdown
          field="ipsCode"
          label="IPS Code"
          placeholder="IPS Code"
          required
        />
        <Form.Dropdown
          field="ipsInsurance"
          label="IPS Insurance"
          placeholder="IPS Insurance"
          required
        />
      </Form.Group>
      <Form.Group className="group">
        <Form.Dropdown
          field="vatCode"
          label="Tax Code"
          placeholder="Tax Code"
          required
        />
        <Form.Input
          field="rrpCash"
          label={`RRP (${process.env.MAJOR_CURRENCY})`}
          placeholder={`RRP (${process.env.MAJOR_CURRENCY})`}
          required
        />
      </Form.Group>
      <Form.Group className="group">
        <Form.Date
          local
          field="meterCollectionFromQtr"
          label="Installation Date"
          placeholder="Installation Date"
          required
        />
        <Form.Date
          local
          field="meterCollectionFinalQtr"
          label="M/R Collection Final Quarter Start Date"
          placeholder="M/R Collection Final Quarter Start Date"
          required
        />
      </Form.Group>
      <Form.TextArea fluid field="productNotes" placeholder="Product Notes" />
      <Grid columns={3} divided className="highlight-background">
        <Grid.Row>
          <Grid.Column>
            <Segment>Applicable Item</Segment>
            <Segment>
              <MultipleTextBoxes count={6} />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>Meter Reading Code</Segment>
            <Segment>
              <MultipleTextBoxes count={6} />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>Start Meter Reading</Segment>
            <Segment>
              <MultipleTextBoxes count={6} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const MultipleTextBoxes = ({ count }) => {
  let formItems = [];
  for (let i = 0; i < count; i++) {
    formItems.push(<Form.Input field="" fluid />);
  }
  return formItems;
};

export default AddProductSchedule;
