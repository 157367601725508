import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { SPVDetails } from "features/SPVs";
import { AppRoutes } from "constants/routes";
import { RedirectBreadcrumbBar } from "pages/nav";

const SPVDetailsPage = ({ match }) => (
  <PageContent fluid>
    <RedirectBreadcrumbBar to={AppRoutes.SPVs} text="Back to SPVs" />
    <SPVDetails id={match.params.id} />
  </PageContent>
);

export default SPVDetailsPage;
