import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "CFA";
const ActionTypes = {
  GetLiveProductDetails: `${NAMESPACE}/GET_LIVE_PRODUCTS`,
  GetSettledProductDetails: `${NAMESPACE}/GET_SETTLED_PRODUCTS`,
};

export const getLiveProductDetails = () => {
  return mockSuccess(ActionTypes.GetLiveProductDetails, {
    delay: 200,
    response: liveProductDetails,
  });
};

export const getSettledProductDetails = () => {
  return mockSuccess(ActionTypes.GetSettledProductDetails, {
    delay: 200,
    response: settledProductDetails,
  });
};

const liveProductDetails = [
  {
    identifierNumber: "ABCD12345",
    manModel: "KM BH123",
    type: "",
    postcode: "RH12 4AL",
    status: "Live",
    RRP: 0,
    settledRRP: 0,
    remainingRRP: 0,
    title: "BNP",
  },
  {
    identifierNumber: "EFGH09876",
    manModel: "KM BH123",
    type: "",
    postcode: "RH12 4AL",
    status: "Live",
    RRP: 0,
    settledRRP: 0,
    remainingRRP: 0,
    title: "BNP",
  },
];

const settledProductDetails = [
  {
    identifierNumber: "ABCD12345",
    manModel: "KM BH123",
    type: "",
    postcode: "RH12 4AL",
    status: "Settled",
    RRP: 0,
    settledRRP: 0,
    remainingRRP: 0,
    title: "Supplier",
  },
  {
    identifierNumber: "EFGH09876",
    manModel: "KM BH123",
    type: "",
    postcode: "RH12 4AL",
    status: "Settled",
    RRP: 0,
    settledRRP: 0,
    remainingRRP: 0,
    title: "Supplier",
  },
];
