import React from "react";
import { first } from "lodash";
import { ListBuilder, Table } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { getScheduleItems } from "./actions";
import { ContentPanel } from "components/displays";
import { SortHeader } from "../../../../../../shared/components/tables/sortableTable";
import {
  numericFormatter,
  fractionalCurrencyFormatter,
} from "components/displays/DisplayHelper";

const ScheduleItemsTable = ({ scheduleId }) => {
  return (
    <React.Fragment>
      <ListBuilder
        withTable
        initialPagination={{
          pageSize: 10,
          pageNumber: 1,
        }}
        loadParams={{ scheduleId }}
        loadAction={getScheduleItems}
        dataTransform={(data) => [data]}
        totalTransform={(data) => data.items.totalResults}
        renderList={(tableProps, state, events) => {
          const data =
            Array.isArray(tableProps.data) &&
            tableProps.data.length &&
            first(tableProps.data);
          const tableData = (data && data.items && data.items.results) || [];

          return (
            <ContentPanel
              title="Schedule Items"
              wrapperCssClass=" "
              headerCssClass="defaults-page-title"
            >
              <div className="spaced">
                <Table
                  {...tableProps}
                  dataKey="id"
                  emptyMessage="No Schedules to display"
                  data={tableData}
                >
                  <Table.Column
                    header={
                      <SortHeader
                        field="itemUnit"
                        title="Item Code"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="itemUnit"
                    title="Item Code"
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="managed"
                        title="Managed"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="managed"
                    title="Managed"
                    render={(x) => (x.managed ? "Yes" : "No")}
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="quarterlyContractedVol"
                        title="Quarterly Contracted Vol"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="quarterlyContractedVol"
                    title="Quarterly Contracted Vol"
                    render={(x) => numericFormatter(x.quarterlyContractedVol)}
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="totalCostPerUnit"
                        title="Total"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="totalCostPerUnit"
                    title="Total"
                    render={(x) =>
                      fractionalCurrencyFormatter(
                        (x.totalCostPerUnit || 0) * 100
                      )
                    }
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="financeCostPerUnit"
                        title="Finance"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="financeCostPerUnit"
                    title="Finance"
                    render={(x) =>
                      x.managed
                        ? fractionalCurrencyFormatter(
                            (x.financeCostPerUnit || 0) * 100
                          )
                        : "-"
                    }
                  />
                  <Table.Column
                    header={
                      <SortHeader
                        field="serviceUnitCharges"
                        title="Service Unit Charges"
                        value={state.filters}
                        onChange={events.onChangeFilters}
                      />
                    }
                    field="serviceUnitCharges"
                    title="Service Unit Charges"
                    render={(x) =>
                      fractionalCurrencyFormatter(
                        (x.serviceUnitCharges || 0) * 100
                      )
                    }
                  />
                </Table>
                <div className="schedule-item-table-footer">
                  <div className="schedule-item-extra-fields">
                    <div className="extra-fields-row">
                      <div className="col-1">
                        Quarterly Contracted Volume Contributing to Managed
                        Volume
                      </div>
                      <div className="col-2">
                        {numericFormatter(data.managedVolume)}
                      </div>
                    </div>
                    <div className="extra-fields-row">
                      <div className="col-1">
                        Additional Minimum Volume NOT included in Managed Volume
                      </div>
                      <div className="col-2">
                        {numericFormatter(data.nonManagedVolume)}
                      </div>
                    </div>
                    <div className="extra-fields-row">
                      <div className="col-1">Finance Element Remaining</div>
                      <div className="col-2">
                        {numericFormatter(data.financeElementRemaining)}
                      </div>
                    </div>
                  </div>
                  <div className="schedule-items-footer-pagination">
                    <TablePagination
                      pageSize={state.pagination.pageSize}
                      pageNumber={state.pagination.pageNumber}
                      onChange={events.onChangePagination}
                      totalItems={state.total}
                    />
                  </div>
                </div>
              </div>
            </ContentPanel>
          );
        }}
      />
    </React.Fragment>
  );
};

export default ScheduleItemsTable;
