import React from "react";
import { Table } from "@redriver/cinnamon";
import EditBoilerPlateModal from "./EditBoilerPlateModal";
import RemoveBoilerPlateModal from "./RemoveBoilerPlateModal";
import MakeBoilerPlateLiveModal from "./MakeBoilerPlateLiveModal";

const BoilerPlateActions = ({ dealerId, item, onSubmitted }) => {
  return (
    <Table.Menu openOnHover>
      <EditBoilerPlateModal
        dealerId={dealerId}
        itemId={item.id}
        onUpdated={onSubmitted}
        reference={item.reference}
      >
        Edit
      </EditBoilerPlateModal>
      <RemoveBoilerPlateModal
        dealerId={dealerId}
        itemId={item.id}
        onDeleted={onSubmitted}
        reference={item.reference}
      >
        Delete
      </RemoveBoilerPlateModal>
      {item.isApproved && !item.isLive && (
        <MakeBoilerPlateLiveModal
          itemId={item.id}
          onSubmitted={onSubmitted}
          reference={item.reference}
        >
          Make Live
        </MakeBoilerPlateLiveModal>
      )}
    </Table.Menu>
  );
};

export default BoilerPlateActions;
