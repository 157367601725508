import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { BreadcrumbBar } from "pages/nav";
import { CustomerFinance } from "features/Finance";
import { ContentPanel } from "components/displays";

const CustomerPage = () => (
  <PageContent fluid className="cfa-container">
      <BreadcrumbBar />
      <ContentPanel
        title="Customer Billing"
        wrapperCssClass="outline-grid"
        headerCssClass="defaults-page-title"
      >
        <div className="padded">
          <CustomerFinance/>
        </div>
      </ContentPanel>
    </PageContent>
);

export default CustomerPage;
