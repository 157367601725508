import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import sharedTranslationsEN from "features/../../../shared/translation/en-GB/Shared.t.json";
import supplierOfficesTranslationsEN from "features/../../../shared/translation/en-GB/SupplierOffices.t.json";
import dataUploadsTranslationsEN from "features/../../../shared/translation/en-GB/DataUploads.t.json";

const resources = {
  en: {
    // Namespaces
    shared: sharedTranslationsEN,
    supplierOffices: supplierOfficesTranslationsEN,
    dataUploads: dataUploadsTranslationsEN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  defaultNS: "system",
  lng: "en",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
