import { apiGet, apiRequest, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "DATA-UPLOADS";

export const ActionTypes = {
  GetDataUploads: `${NAMESPACE}/GET`,
  ApproveSettingsUpload: `${NAMESPACE}/APPROVE`,
  DownloadFile: `${NAMESPACE}/DOWNLOAD_FILE`,
  GetTemplates: `${NAMESPACE}/GET_TEMPLATES`,
  GetTemplate: `${NAMESPACE}/GET_TEMPLATE`,
  UploadTemplate: `${NAMESPACE}/UPLOAD_TEMPLATE`,
  DownloadTemplate: `${NAMESPACE}/DOWNLOAD_TEMPLATE`,
  ApproveTemplate: `${NAMESPACE}/APPROVE_TEMPLATE`,
};

export const getDataUploads = ({ filters, pagination }) =>
  apiGet(ActionTypes.GetDataUploads, "settings-upload", {
    ...pagination,
    ...filters,
  });

export const uploadTemplate = (formData) => {
  const data = new FormData();
  data.append("file", formData.file);
  data.append("type", formData.type);

  return apiRequest({
    type: ActionTypes.UploadTemplate,
    method: "PUT",
    path: "settings-upload/upload-template",
    contentType: null,
    body: data,
  });
};

export const approveSettingsUpload = (id) =>
  apiPost(ActionTypes.ApproveSettingsUpload, `settings-upload/approve/${id}`);

export const downloadFile = ({ id }) =>
  apiGet(ActionTypes.GetDataUploads, `settings-upload/${id}/download`);

export const downloadTemplate = (formData, params) =>
  apiGet(ActionTypes.DownloadTemplate, `settings-upload/download-template`, {
    ...params,
    ...formData,
  });

export const getTemplates = ({ filters, pagination }) =>
  apiGet(ActionTypes.GetTemplates, "settings-upload/templates", {
    ...pagination,
    ...filters,
  });

export const approveTemplate = (id) =>
  apiPost(
    ActionTypes.ApproveTemplate,
    `settings-upload/templates/${id}/approve`
  );
