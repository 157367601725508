import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ListBuilder,
  ActionBar,
  usePreferences,
  Form,
  FormBuilder,
} from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { getLargeFormatServices } from "./actions";
import {
  DynamicConfigurableTable,
  TableConfigurator,
} from "features/CustomConfig/TableConfig";
import { Image } from "semantic-ui-react";
import Search from "features/../../../shared/assets/purple-search.svg";
import { InfoModalWrapper } from "components/modals";
import {
  numericFormatter,
  trueFalseFormatter,
} from "components/displays/DisplayHelper";
import { getAllUsageTypes } from "features/../../../shared/features/Settings/LargeFormat/UsageType";

const ListLargeFormatServices = ({ dealerOfficeId }) => {
  const dispatch = useDispatch();
  const { preferences } = usePreferences();
  const [config, setConfig] = useState(preferences.lfpServicesTableConfig);
  const [pageSizeConfig, setPageSizeConfig] = useState(
    preferences.lfpServicesTableConfigPageSize
      ? preferences.lfpServicesTableConfigPageSize
      : 25,
  );

  const columns = [
    {
      field: "meterType",
      title: "Meter Type",
      render: (item) => (item == 0 ? "N/A" : item),
      className: "lf-service-list short",
    },
    { field: "model", title: "Model", className: "lf-service-list long" },
    {
      field: "colours",
      title: "Number of colour inks",
      className: "lf-service-list",
    },
    {
      field: "scanner",
      title: "Scanner incl",
      render: (item) => trueFalseFormatter(item),
      className: "lf-service-list short",
    },
    {
      field: "cleaningMeter",
      title: "Cleaning Meter",
      render: (item) => trueFalseFormatter(item),
      className: "lf-service-list short",
    },
    {
      field: "alwaysUnmanaged",
      title: "Always not included in managed volume",
      render: (item) => trueFalseFormatter(item),
      className: "lf-service-list long",
    },
    {
      field: "alwaysManaged",
      title: "Always included in managed volume",
      render: (item) => trueFalseFormatter(item),
      className: "lf-service-list long",
    },
    {
      field: "availableForProductScheduleDropdown",
      title: "Available for product schedule dropdown",
      render: (item) => trueFalseFormatter(item),
      className: "lf-service-list long",
    },
    {
      field: "legalName",
      title: "Metered Item (legal)",
      className: "lf-service-list long",
    },
    {
      field: "legalCode",
      title: "Item ID (legal)",
      className: "lf-service-list short ",
    },
    {
      field: "mandatory",
      title: "Meter Mandatory",
      render: (item) => trueFalseFormatter(item),
      sortable: false,
      className: "lf-service-list",
    },
    {
      field: "mandatoryOverwritable",
      title: "Meter Mandatory Overwritable",
      render: (item) => trueFalseFormatter(item),
      sortable: false,
      className: "lf-service-list ",
    },
    {
      field: "description",
      title: "Description (APP for user information)",
      className: "lf-service-list long",
    },
    { field: "note", title: "Note", className: "lf-service-list " },
    {
      field: "ipsBillingCode",
      title: "IPS Billing Code",
      className: "lf-service-list ",
    },
    { field: "ipsSku", title: "IPS SKU", className: "lf-service-list " },
    {
      field: "supplierBillingCode",
      title: "Supplier Billing Code",
      className: "lf-service-list ",
    },

    {
      field: "priceADefaultMinVolume",
      title: "Default APP Min Volume",
      sortable: false,
      className: "lf-service-list ",
    },
    {
      field: "priceAVMinVolume",
      title: "Price A VMIN Volume",
      sortable: false,
      className: "lf-service-list ",
    },
    {
      field: "priceA",
      title: "Default APP price A",
      sortable: false,
      render: (item) => numericFormatter(item, 0, 3),
      className: "lf-service-list ",
    },
    {
      field: "unit1",
      title: "Unit",
      sortable: false,
      className: "lf-service-list short",
    },
    {
      field: "priceAAdminOverwriteable",
      title: "Admin Overwriteable in APP",
      render: (item) => trueFalseFormatter(item),
      sortable: false,
      className: "lf-service-list ",
    },
    {
      field: "priceAVMinSalePrice",
      title: "Price A VMin value",
      sortable: false,
      render: (item) => numericFormatter(item, 0, 3),
      className: "lf-service-list ",
    },
    {
      field: "unit2",
      title: "Unit",
      sortable: false,
      className: "lf-service-list short",
    },

    {
      field: "priceBVisible",
      title: "Price B Visible",
      render: (item) => trueFalseFormatter(item),
      sortable: false,
      className: "lf-service-list short",
    },

    {
      field: "priceBDefaultMinVolume",
      title: "Default APP Min Volume",
      sortable: false,
      className: "lf-service-list ",
    },
    {
      field: "priceBVMinVolume",
      title: "Price B VMIN Volume",
      sortable: false,
      className: "lf-service-list ",
    },
    {
      field: "priceB",
      title: "Default APP price B",
      sortable: false,
      render: (item) => numericFormatter(item, 0, 3),
      className: "lf-service-list ",
    },
    {
      field: "unit3",
      title: "Unit",
      sortable: false,
      className: "lf-service-list short",
    },
    {
      field: "priceBAdminOverwriteable",
      title: "Admin Overwriteable in APP",
      render: (item) => trueFalseFormatter(item),
      sortable: false,
      className: "lf-service-list ",
    },
    {
      field: "priceBVMinSalePrice",
      title: "Price B VMin value",
      sortable: false,
      render: (item) => numericFormatter(item, 0, 3),
      className: "lf-service-list ",
    },
    {
      field: "unit4",
      title: "Unit",
      sortable: false,
      className: "lf-service-list short",
    },

    {
      field: "targetMarket",
      title: "Market",
      className: "lf-service-list long",
    },
    {
      field: "meterServiceNote",
      title: "Note 2",
      sortable: false,
      className: "lf-service-list ",
    },
    {
      field: "widthMM",
      title: "print width (mm)",
      sortable: false,
      className: "lf-service-list ",
    },
    {
      field: "widthInches",
      title: "print width (inches)",
      sortable: false,
      className: "lf-service-list ",
    },
  ];

  const [tableColumns, setTableColumns] = useState(columns);

  useEffect(() => {
    setConfig(preferences.lfpServicesTableConfig);
  }, [preferences.lfpServicesTableConfig]);

  useEffect(() => {
    preferences.lfpServicesTableConfigPageSize &&
      setPageSizeConfig(preferences.lfpServicesTableConfigPageSize);
  }, [preferences.lfpServicesTableConfigPageSize]);

  //hook to get dynamic columns - Usage types.
  useEffect(() => {
    (async () => {
      const { success, response } = await dispatch(
        getAllUsageTypes({ dealerOfficeId }),
      );

      let tempColumns = tableColumns;
      success &&
        response.map((k) => {
          tempColumns.push({
            field: k.id,
            title: k.name,
            dynamic: true,
            parentField: "inkUsages",
            valueField: "inkUsedMl",
            sortable: false,
            render: (item) => numericFormatter(item, 0, 3, "N/A"),
          });
        });

      //append usage types to existing columns.
      setTableColumns(tempColumns);
    })();
  }, [dealerOfficeId, dispatch, tableColumns]);

  const [selectedItems, setSelected] = useState({});

  return (
    <React.Fragment>
      <h4>Large Format</h4>
      <ListBuilder
        withTable
        initialPagination={{ pageSize: pageSizeConfig, pageNumber: 1 }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        loadAction={dealerOfficeId ? getLargeFormatServices : null}
        loadParams={{ dealerOfficeId }}
        renderList={(tableProps, state, events) =>
          events && (
            <div className="content-container cut-sheet-paper-list ">
              <FormBuilder
                value={state.filters}
                onChange={events.onChangeFilters}
                renderForm={(formProps) => (
                  <Form {...formProps}>
                    <ActionBar>
                      <ActionBar.Item fluid>
                        <Form.Input
                          field="search"
                          placeholder="Search"
                          icon={<Image src={Search} />}
                          className="search-input highlighted-input"
                        />
                      </ActionBar.Item>
                      <ActionBar.Item align="right"></ActionBar.Item>
                      <ActionBar.Item align="right">
                        <InfoModalWrapper title="Import Data">
                          Not implemented.
                        </InfoModalWrapper>
                        <InfoModalWrapper title="Export Data">
                          Not implemented.
                        </InfoModalWrapper>
                        <TableConfigurator
                          config={config}
                          columns={tableColumns}
                          configId="lfpServicesTableConfig"
                          titlePreFix="Cut Sheet Paper"
                          initialPageSize={25}
                          pageSizeConfig={pageSizeConfig}
                        />
                      </ActionBar.Item>
                    </ActionBar>
                  </Form>
                )}
              />

              <div className="horizontal-scrollbar centre-align">
                <DynamicConfigurableTable
                  state={state}
                  events={events}
                  tableProps={tableProps}
                  emptyMessage="No items found"
                  columns={tableColumns}
                  config={config}
                  selectedItems={selectedItems}
                  setSelected={setSelected}
                />
              </div>

              <TablePagination
                pageNumber={state.pagination.pageNumber}
                onChange={events.onChangePagination}
                pageSize={state.pagination.pageSize}
                totalItems={state.total}
              />
            </div>
          )
        }
      />
    </React.Fragment>
  );
};

export default ListLargeFormatServices;
