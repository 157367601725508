import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "CFA";

const ActionTypes = {
  GetCFAScheduleDetails: `${NAMESPACE}/GET_SCHEDULE_DETAILS`,
};

const MockCFAScheduleDetails = {
  id: 1,
  customer: "Joe Bloggs Ltd",
  supplier: "Konica Molita",
  scheduleReference: "KM1420-MA2441-SC6120",
  scheduleStartDate: "22-Mar-2018",
  scheduleStatus: "DEAD",
  nextPeriodToBeBilled: "N/A",
  scheduleFinanceCpi: "5.817",
  scheduleCfaChargeTotal: 6583.6,
  continuingPaymentSplit: "Edit",
  continuingPaymentSplitTable: {
    funderPercentageSplit: 10,
    supplierPercentageSplit: 70,
    ipSyndicatePercentageSplit: 20,
    totalPercentageSplit: 100,
    funderMinimumTotal: 111.59,
    supplierMinimumTotal: 781.14,
    ipSyndicateMinimumTotal: 223.19,
    totalMinimumTotal: 1115.92,
  },
  cfaPayment: {
    value: 1115.92,
    cfaSchedulePercentageSplit: 16.95,
  },
  tmv: {
    scheduleTmvVolume: 1460009,
    scheduleTmvPeriods: 12.9,
    scheduleTmvTotals: 14395.37,
    tmvUsedVolume: 603407,
    tmvUsedPeriods: 5.331,
    tmvUsedTotals: 5949.47,
    tmvRemainingVolume: 856602,
    tmvRemainingPeriods: 7.569,
    tmvRemainingTotals: 8445.9,
  },
  quarterlyManagedVolume: 113179,
  scheduleFinanceAmountsCollected: {
    funderMinimum: 4463.68,
    funderAdditionalUnits: 1485.79,
    funderCpMinimum: 0,
    funderCpAdditionalUnits: 0,
    funderTotal: 5949.47,
    supplierCpMinimum: 0,
    supplierCpAdditionalUnits: 0,
    supplierTotal: 0,
    ipSyndicateCpMinimum: 0,
    ipSyndicateCpAdditionalUnits: 0,
    ipSyndicateTotal: 0,
    totalCollectedMinimum: 4463.68,
    totalCollectedAdditionalUnits: 1485.79,
    totalCollectedCpMinimum: 0,
    totalCollectedCpAdditionalUnits: 0,
    totalCollectedTotal: 5949.47,
    adjustmentsFunderMinimum: 0,
    adjustmentsFunderAdditionalUnits: 0,
    adjustmentsFunderCpMinimum: 0,
    adjustmentsFunderCpAdditionalUnits: 0,
    adjustmentsFunderTotal: 0,
    adjustmentsSupplierCpMinimum: 0,
    adjustmentsSupplierCpAdditionalUnits: 0,
    adjustmentsSupplierTotal: 0,
    adjustmentsIpSyndicateCpMinimum: 0,
    adjustmentsIpSyndicateCpAdditionalUnits: 0,
    adjustmentsIpSyndicateTotal: 0,
    scheduleTotalMinimum: 4463.68,
    scheduleTotalAdditionalUnits: 1485.79,
    scheduleTotalCpMinimum: 0,
    scheduleTotalCpAdditionalUnits: 0,
    scheduleTotalTotal: 5949.47,
  },
  scheduleFinanceAmountsRemaining: {
    funderMinimum: 7441.57,
    funderCpMinimum: 100.43,
    funderTotal: 7542,
    supplierCpMinimum: 703.03,
    supplierTotal: 703.03,
    ipSyndicateCpMinimum: 200.87,
    ipSyndicateTotal: 200.87,
    scheduleTotalMinimum: 7441.57,
    scheduleTotalCpMinimum: 1004.33,
    scheduleTotalTotal: 8445.9,
  },
  scheduleAdditionalUnitsAllocation: {
    allocated: 0,
    unallocated: 0,
    total: 0,
  },
};

export const getCFAScheduleDetails = scheduleRef => {
  //Set demo data id to the selected CFA id
  //Remove when backend completed
  const response = MockCFAScheduleDetails;
  response.scheduleReference = scheduleRef;

  return mockSuccess(ActionTypes.GetCFAScheduleDetails, {
    delay: 200,
    response: response,
  });
};
