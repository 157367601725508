import React from "react";
import {
  ListBuilder,
  FormBuilder,
  Form,
  ModalBuilder,
  Modal,
  PageLoader,
} from "@redriver/cinnamon";
import { Popup, Icon, Button } from "semantic-ui-react";
import {
  withPermissions,
  PermissionCheck,
} from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { getSystemConfigs, updateSystemConfigs } from "./actions";

const ListSystemConfig = ({ type, hasPermission, updatedDateTime }) => {
  let canEdit = hasPermission(Targets.SystemConfig, Actions.Edit);

  return (
    <PermissionCheck target={Targets.SystemConfig} action={Actions.View}>
      <ListBuilder
        loadAction={getSystemConfigs}
        loadParams={{ type, updatedDateTime }}
        renderList={(listData, state, listEvents) => (
          <div className="system-configs-list">
            {state.loading && <PageLoader />}
            {!state.loading &&
              Array.isArray(listData) &&
              listData.map((item) => (
                <ConfigField
                  key={`${item.key}_${updatedDateTime}`}
                  item={item}
                  type={type}
                  canEdit={canEdit}
                  onSubmitted={listEvents.onReset}
                  onCancel={listEvents.onReset}
                  updatedDateTime={updatedDateTime}
                />
              ))}
          </div>
        )}
      />
    </PermissionCheck>
  );
};

const ConfigField = ({ item, type, canEdit, onSubmitted, updatedDateTime }) => (
  <FormBuilder
    initialReadOnly={!canEdit}
    key={`${item.key}${updatedDateTime}`}
    onLoad={() => ({ ...item })}
    onSubmitted={onSubmitted}
    renderForm={(formProps, formState, formEvents) => (
      <Form {...formProps}>
        <div className="system-config-item">
          <Form.Input
            field="value"
            label={
              <div className="label">
                <label>{formProps.value.label}</label>
              </div>
            }
            width={28}
            disabled={formProps.disabled || item.preventManualUpdate}
          />
          <Popup
            content={formProps.value.description}
            trigger={<Icon name="help circle" />}
          />

          {canEdit && (
            <React.Fragment>
              <Button
                compact
                content="Cancel"
                onClick={() => formEvents.onRefresh()}
                disabled={!formState.dirty || formState.submitting}
                loading={formState.submitting}
              />
              <ModalBuilder
                submitAction={updateSystemConfigs}
                submitParams={{
                  item: formProps.value,
                  type,
                }}
                onSubmitted={onSubmitted}
                renderTrigger={(showModal) => (
                  <Button
                    primary
                    compact
                    content="Save"
                    onClick={showModal}
                    disabled={!formState.dirty || formState.submitting}
                    loading={formState.submitting}
                  />
                )}
                renderModal={(modalProps) => (
                  <Modal.Confirmation {...modalProps}>
                    <p>Are you sure you want to change this setting?</p>
                  </Modal.Confirmation>
                )}
              />
            </React.Fragment>
          )}
        </div>
      </Form>
    )}
  />
);

export default withPermissions(ListSystemConfig);
