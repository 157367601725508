import { apiGet, apiPut, apiPost, apiDelete } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/SERVICES";

export const ActionTypes = {
  GetMeteredServices: `${NAMESPACE}/GET_METERED_SERVICES`,
  GetMeteredServiceForEdit: `${NAMESPACE}/GET_METERED_SERVICE_EDIT`,
  AddMeteredService: `${NAMESPACE}/ADD_METERED_SERVICE`,
  UpdateMeteredService: `${NAMESPACE}/UPDATE_SMETERED_SERVICE`,
  RemoveMeteredServiceById: `${NAMESPACE}/REMOVE_METERED_SERVICE_BY_ID`,
};

export const getMeteredServices = (
  { filters, pagination },
  { dealerOfficeId }
) => {
  const { pageSize, pageNumber } = pagination;
  const { search, sortName, sortDirection } = filters;

  const data = apiGet(ActionTypes.GetMeteredServices, `metered-services`, {
    dealerOfficeId,
    pageSize,
    pageNumber,
    search,
    includeMandatory: true,
    sortName,
    sortDirection,
  });
  return data;
};

export const getMeteredServiceForEdit = ({ id }) =>
  apiGet(ActionTypes.GetMeteredServiceForEdit, `metered-services/${id}/edit`);

export const addMeteredService = (service, { dealerOfficeId }) =>
  apiPost(ActionTypes.AddMeteredService, `metered-services`, {
    ...service,
    dealerOfficeId,
  });

export const removeMeteredService = (id) =>
  apiDelete(ActionTypes.RemoveMeteredServiceById, `metered-services/${id}`);

export const editMeteredService = (service, id) =>
  apiPut(ActionTypes.UpdateMeteredService, `metered-services/${id}`, service);
