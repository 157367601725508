import React from "react";
import { Grid, Table } from "semantic-ui-react";
import { Form, FormBuilder, Tile, ContentBuilder } from "@redriver/cinnamon";

import { getPaperSchedule } from "./actions";
import {
  yesNoFormatter,
  numericFormatter,
  currencyFormatter,
  fractionalCurrencyFormatter,
} from "components/displays/DisplayHelper";
import { ContentPanel } from "components/displays";

const Paper = () => {
  return (
    <div className="paper-schedule">
      <ContentBuilder
        loadAction={getPaperSchedule}
        renderContent={(data) =>
          data.isPaperInclusive && (
            <React.Fragment>
              <FormBuilder
                initialData={data}
                renderForm={(formProps) => (
                  <Form {...formProps}>
                    <ContentPanel
                      title="Cut Sheet Paper Inclusive"
                      headerCssClass="highlight-page-title"
                    >
                      <div className="spaced">
                        <Grid doubling columns={10}>
                          <Grid.Row>
                            <Grid.Column>
                              <Tile
                                animate={700}
                                className="widget-tile center"
                              >
                                <h4>Charge Based On</h4>
                                <span>{data.paperInclusiveChargeType}</span>
                              </Tile>
                            </Grid.Column>
                            <Grid.Column>
                              <Tile
                                animate={700}
                                className="widget-tile center"
                              >
                                <h4>Single Click A3</h4>
                                <span>
                                  {yesNoFormatter(data.isCostEffectiveA3)}
                                </span>
                              </Tile>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>

                      <div className="spaced highlight">
                        <div className="contracted-managed-pi">
                          <h5>
                            Contracted Managed Paper Inclusive (Cost Per Ream)
                          </h5>

                          <div className="customer-current-spend horizontal-scrollbar">
                            <div className="paper-inclusive-subgrid">
                              <div className="headers no-justify">
                                <div className="xtr-wide">
                                  Product Description
                                </div>
                                <div className="wide">SKU</div>
                                <div>Weight</div>
                                <div>Type</div>
                                <div>Qty per ream</div>
                                <div>Price per ream</div>
                                <div>Reams per box</div>
                              </div>
                              <ManagedInclusiveSpendColumn
                                col="A4"
                                field="a4ManagedPaperInclusiveSpend"
                              />
                              <ManagedInclusiveSpendColumn
                                col="A3"
                                field="a3ManagedPaperInclusiveSpend"
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div>
                          <div className="medium-left">
                            <span className="indent">
                              Delivery charge per Qtr
                            </span>
                          </div>
                          <div className="small-left">
                            <Form.Numeric
                              fluid
                              decimalPlaces={2}
                              formatValue
                              field="contractedQtrDeliveryCharge"
                              subLabel={process.env.MAJOR_CURRENCY}
                              disabled
                              width={200}
                            />
                          </div>
                        </div>
                        <div>
                          <div className="clear"></div>
                          <br />
                          <p> Additional cost per quarter (if required)</p>
                          <div className="horizontal-scrollbar">
                            <PaperStepAdditionalCosts />
                          </div>
                        </div>

                        <br />
                        <br />

                        <div className="large-left">
                          <b>
                            <span>Total COST of Special Paper</span>
                          </b>
                        </div>
                        <div className="small-left">
                          <Form.Numeric
                            fluid
                            decimalPlaces={2}
                            formatValue
                            field="specialPaperCost"
                            subLabel={process.env.MAJOR_CURRENCY}
                            disabled
                          />
                        </div>
                        <div className="clear"></div>
                        <div className="large-left">
                          <b>
                            <span>Total Included Minimum Delivery Charge</span>
                          </b>
                        </div>
                        <div className="small-left">
                          <Form.Numeric
                            fluid
                            decimalPlaces={2}
                            formatValue
                            field="minDeliveryCost"
                            subLabel={process.env.MAJOR_CURRENCY}
                            disabled
                          />
                        </div>
                        <div className="clear"></div>
                      </div>
                    </ContentPanel>

                    <br />
                  </Form>
                )}
              ></FormBuilder>

              <AdministrationSection
                data={data.summary}
              ></AdministrationSection>
              <RewritesSection
                data={data.qtrServiceAdjustment}
              ></RewritesSection>
            </React.Fragment>
          )
        }
      ></ContentBuilder>
    </div>
  );
};

const AdministrationSection = ({ data }) => {
  return (
    <ContentPanel
      title="Administration Section"
      headerCssClass="highlight-page-title"
    >
      <div className="admin-width">
        <div className="horizontal-scrollbar-1000">
          <div className="admin-min-width">
            <Table className="spaced">
              <Table.Row>
                <Table.Cell className="right-border" style={{ width: "44%" }}>
                  <div>
                    <div className="left">
                      <b>Calculator Using</b>{" "}
                    </div>
                    <div className="left-centered">
                      {" "}
                      {data.calculator.description1}
                      <br />
                      {data.calculator.description2}
                    </div>
                    <div className="right">
                      <b>{currencyFormatter(data.calculator.total)}</b>
                    </div>
                  </div>
                  <hr className="highlight wide" />
                  <div>
                    <span className="left">
                      <b>Special Paper Calc Pricing </b>{" "}
                    </span>{" "}
                    <span className="right">
                      <b>{currencyFormatter(data.specialPaperCalcPricing)}</b>
                    </span>
                  </div>
                  <hr className="highlight wide" />
                  <div>
                    <span className="left">
                      <b>Minimum Delivery Cost </b>{" "}
                    </span>{" "}
                    <span className="right">
                      <b>{currencyFormatter(data.minDeliveryCost)}</b>
                    </span>
                  </div>
                  <hr className="highlight wide" />
                  <div>
                    <span>
                      <b>Price Per Quarter </b>{" "}
                    </span>{" "}
                    <span className="right">
                      <b>{currencyFormatter(data.pricePerQtr)}</b>
                    </span>
                  </div>
                  <div>
                    <span>
                      <b>Single Click A3 Reduction</b>{" "}
                    </span>{" "}
                    <span className="right">
                      <b>{currencyFormatter(data.singleClickA3Reduction)}</b>
                    </span>
                  </div>
                  <div>
                    <span>
                      <b>TMVSA Amount </b>{" "}
                    </span>{" "}
                    <span className="right">
                      <b>{currencyFormatter(data.tmvsa)}</b>
                    </span>
                  </div>
                  <div>
                    <span>
                      <b>Cost Per Unit </b>{" "}
                    </span>{" "}
                    <span className="right">
                      <b>
                        {fractionalCurrencyFormatter(
                          data.costPerUnit,
                          process.env.MINOR_CURRENCY,
                          3
                        )}
                      </b>
                    </span>
                  </div>
                </Table.Cell>
                <Table.Cell className="right-border" style={{ width: "28%" }}>
                  <div>
                    <span className="left">Inclusive Paper </span>{" "}
                    <span className="right">
                      {" "}
                      {fractionalCurrencyFormatter(
                        data.inclusivePaper,
                        process.env.MINOR_CURRENCY,
                        6
                      )}
                    </span>
                  </div>
                  <br />
                  <br />
                  <div>
                    <span className="left">Special Paper Price </span>{" "}
                    <span className="right">
                      {" "}
                      {fractionalCurrencyFormatter(
                        data.specialPaperPrice,
                        process.env.MINOR_CURRENCY,
                        6
                      )}
                    </span>
                  </div>
                  <br />
                  <br />
                  <div>
                    <span className="left">Delivery Allowance </span>{" "}
                    <span className="right">
                      {" "}
                      {fractionalCurrencyFormatter(
                        data.deliveryAllowance,
                        process.env.MINOR_CURRENCY,
                        6
                      )}
                    </span>
                  </div>
                  <br />
                  <br />
                  <div>
                    <span className="left">Total </span>{" "}
                    <span className="right">
                      {fractionalCurrencyFormatter(
                        data.inclusivePaper +
                          data.specialPaperPrice +
                          data.deliveryAllowance,
                        process.env.MINOR_CURRENCY,
                        6
                      )}
                      -
                    </span>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div>
                    <span className="left">Per Unit </span>{" "}
                    <span className="right">
                      {data.costPerUnit}
                      {process.env.MINOR_CURRENCY}
                    </span>
                  </div>
                </Table.Cell>
                <Table.Cell style={{ width: "28%" }}>
                  <div className="centered-content">
                    <b>
                      <span>{numericFormatter(data.reams, 0, 0)}</span> Reams{" "}
                      <br />
                      <span>{numericFormatter(data.boxes, 0, 0)}</span> Full
                      boxes <br />
                      <span>
                        {numericFormatter(data.reamsRoundedUp, 0, 0)}
                      </span>{" "}
                      Reams Rounded up to make Full Box <br />
                    </b>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table>
          </div>
        </div>

        <hr className="highlight" />
        <Grid columns={4} doubling>
          <Grid.Row>
            <Grid.Column className="center">
              <b>
                Quarterly Contracted Volume
                <hr className="highlight" />
                {numericFormatter(data.quarterlyContractVol, 0, 0)}
              </b>
            </Grid.Column>
            <Grid.Column className="center">
              <b>
                Cost Per Unit
                <hr className="highlight" />
                {fractionalCurrencyFormatter(
                  data.costPerUnit,
                  process.env.MINOR_CURRENCY,
                  3
                )}
              </b>
            </Grid.Column>
            <Grid.Column className="center">
              <b>
                Equates to Enough Boxed Reams to Deliver A3 or A4
                <hr className="highlight" />
                {numericFormatter(data.reamsToDeliver, 0, 0)}
              </b>
            </Grid.Column>
            <Grid.Column className="center ">
              <b>
                TMVSA Amount
                <hr className="highlight" />
                {currencyFormatter(data.tmvsa)}
              </b>
              <br />
              <br />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </ContentPanel>
  );
};

const RewritesSection = ({ data }) => {
  return (
    <ContentPanel
      title="For rewrites backdated a quarter where Cut Sheet Paper income already billed in advance"
      headerCssClass="highlight-page-title"
    >
      <div className="large-centered">
        <div className="spaced rewrite-width">
          <span className="left">Quarterly service Adjustment Applied? </span>{" "}
          <span className="right">
            <b>{yesNoFormatter(data.applied)}</b>
          </span>
          <br />
          <span className="left">
            Paper Inclusive amount at Comparison Snapshot
          </span>{" "}
          <span className="right">
            <b>{currencyFormatter(data.amountAtComparisonSnapshot)}</b>
          </span>
          <br />
          <span className="left">
            Paper Price adjusted with new settings
          </span>{" "}
          <span className="right">
            <b>{currencyFormatter(data.adjustedWithNewSettings)}</b>
          </span>
          <br />
          <span className="left">Quarterly service Adjustment</span>{" "}
          <span className="right">
            <b>{currencyFormatter(data.value)}</b>
          </span>{" "}
          <br />
        </div>
      </div>
    </ContentPanel>
  );
};

const ManagedInclusiveSpendColumn = ({ col, field }) => {
  return (
    <Form.Object field={field}>
      <div className="current-spend-container">
        <div className="paper-size">{col}</div>
        <div className="current-spend-columns">
          <div className="xtr-wide value">
            <Form.Input field="description" disabled fluid />
          </div>
          <div className="wide value">
            <Form.Input field="sku" disabled fluid />
          </div>
          <div className="value">
            <Form.Input field="paperWeight" disabled fluid />
          </div>
          <div className="value">
            <Form.Input field="specialType" disabled fluid />
          </div>
          <div className="value">
            <Form.Input field="quantityPerReam" disabled fluid />
          </div>
          <div className="value">
            <Form.Input field="pricePerReam" disabled fluid />
          </div>
          <div className="value">
            <Form.Numeric
              field="reamsPerBox"
              disabled
              fluid
              decimalPlaces={0}
            />
          </div>
        </div>
      </div>
    </Form.Object>
  );
};

const PaperStepAdditionalCosts = () => {
  return (
    <div className="additional-costs">
      <div className="add-cost-headers">
        <div>Value</div>
        <div className="xtr-wide">Description</div>
        <div className="wide">SKU</div>
        <div className="wide">Customer Reference</div>
        <div className="xtr-xtr-wide">Additional Terms</div>
      </div>
      <div className="add-cost-all-fields">
        <div className="array">
          <Form.Array arrayKey="id" field="additionalCosts" minLength={2}>
            {() => (
              <div className="add-cost-fields">
                <Form.Numeric
                  fluid
                  decimalPlaces={2}
                  formatValue
                  field="value"
                  subLabel={process.env.MAJOR_CURRENCY}
                  disabled
                />
                <Form.Input className="xtr-wide" disabled field="description" />
                <Form.Input disabled field="sku" className="wide" />
                <Form.Input
                  disabled
                  field="customerReference"
                  className="wide"
                />
              </div>
            )}
          </Form.Array>
        </div>
        <Form.TextArea
          disabled
          field="paperInclusiveAdditionalTerms"
          placeholder="Additional Terms..."
          className="additional-terms"
          rows={2}
        />
      </div>
    </div>
  );
};

export default Paper;
