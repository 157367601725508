import React from "react";
import { FormBuilder, Form } from "@redriver/cinnamon";
import { Image } from "semantic-ui-react";

import Search from "features/../../../shared/assets/purple-search.svg";

const SearchRoles = ({ value, onChange }) => (
  <FormBuilder
    value={value}
    onChange={onChange}
    renderForm={formProps => (
      <Form {...formProps}>
        <Form.Input
          field="search"
          placeholder="Search Templates"
          icon={<Image src={Search} />}
          className="search-input highlighted-input"
        />
      </Form>
    )}
  />
);

export default SearchRoles;
