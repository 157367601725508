import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import { validateResetToken, requestPasswordReset } from "./actions";
import PasswordForgotten from "../PasswordForgotten";

const PasswordReset = ({ token }) => {
  const [tokenValid, setTokenValid] = useState(null);
  const [resetSuccess, setResetSuccess] = useState(false);

  if (!token) return <p>No reset token supplied</p>;

  if (tokenValid === false) {
    return (
      <div>
        <p>The reset token supplied is invalid, please request another</p>
        <PasswordForgotten />
      </div>
    );
  }

  if (resetSuccess) {
    return (
      <React.Fragment>
        <p>Your password has been reset</p>
        <Button as={Link} to={SystemRoutes.Login} className="action-button">
          Continue
        </Button>
      </React.Fragment>
    );
  }

  return (
    <FormBuilder
      loadAction={validateResetToken}
      loadParams={token}
      onLoaded={(response) => setTokenValid(!!response && response.ok)}
      submitAction={requestPasswordReset}
      submitParams={token}
      onSubmitted={() => setResetSuccess(true)}
      renderLoading={false}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { loading, slowLoading, submitting, slowSubmitting, error },
        events
      ) => {
        if (loading && slowLoading) return <p>Validating reset token...</p>;
        if (!tokenValid) return null;

        return (
          <Form {...formProps}>
            <Form.Password
              field="newPassword"
              label="New Password"
              placeholder="Enter new password"
              disabled={submitting && slowSubmitting}
              autoFocus
              required
              minLength={8}
            >
              <input autoComplete="new-password" />
            </Form.Password>
            <Form.Password
              field="confirmPassword"
              label="Confirm Password"
              placeholder="Confirm new password"
              disabled={submitting && slowSubmitting}
              required
              confirmField="newPassword"
            >
              <input autoComplete="new-password" />
            </Form.Password>
            <Button
              onClick={events.onSubmit}
              disabled={submitting}
              loading={submitting && slowSubmitting}
              className="action-button"
            >
              Reset
            </Button>
            <ErrorMessage error={error} />
          </Form>
        );
      }}
    />
  );
};

PasswordReset.propTypes = {
  token: PropTypes.string.isRequired,
};

export default PasswordReset;
