import React from "react";
import { ContentPanel } from "components/displays";

const PeriodView = () => {
  return (
    <ContentPanel
      title="Period View"
      wrapperCssClass="outline-grid"
      headerCssClass="defaults-page-title"
    >
      <div className="padded">Content to go here</div>
    </ContentPanel>
  );
};

export default PeriodView;
