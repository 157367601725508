import React from "react";
import { ContentPanel } from "components/displays";
import { PageContent } from "@redriver/cinnamon";
import { getLiveProductDetails, getSettledProductDetails } from "./actions";
import { SimpleTableBuilder } from "../../../../../shared/components/tables/sortableTable";
import { currencyFormatter } from "components/displays/DisplayHelper";

const columns = [
  { field: "identifierNumber", title: "Identifier Number" },
  { field: "manModel", title: "Manufacturer/Model" },
  { field: "type", title: "Type" },
  { field: "postcode", title: "Postcode" },
  { field: "status", title: "Status" },
  { field: "RRP", title: "RRP", render: (item) => currencyFormatter(item.RRP) },
  {
    field: "settledRRP",
    title: "Settled RRP",
    render: (item) => currencyFormatter(item.settledRRP),
  },
  {
    field: "remainingRRP",
    title: "Remaining RRP",
    render: (item) => currencyFormatter(item.remainingRRP),
  },
  { field: "title", title: "Title" },
];

const Products = () => {
  return (
    <PageContent fluid className="funders-container">
      <ContentPanel
        title="Products"
        wrapperCssClass="outline-grid"
        headerCssClass="defaults-page-title"
      >
        <div className="padded">
          <ContentPanel
            title="Live"
            wrapperCssClass="outline-grid"
            headerCssClass="defaults-page-title"
          >
            <div className="spaced">
              <div className="vertical-scrollbar small">
                <SimpleTableBuilder
                  withTable
                  loadAction={getLiveProductDetails}
                  emptyMessage="No products found"
                  columns={columns}
                  className="center-align"
                />
              </div>
            </div>
          </ContentPanel>
          <div className="top-spacer"></div>
          <ContentPanel
            title="Settled"
            wrapperCssClass="outline-grid"
            headerCssClass="defaults-page-title"
          >
            <div className="spaced">
              <div className="vertical-scrollbar small">
                <SimpleTableBuilder
                  withTable
                  loadAction={getSettledProductDetails}
                  emptyMessage="No products found"
                  columns={columns}
                  className="center-align"
                />
              </div>
            </div>
          </ContentPanel>
        </div>
      </ContentPanel>
    </PageContent>
  );
};

export default Products;
