import { apiGet, mockSuccess, MockArrayData } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULES/CHARGES";

const ActionTypes = {
  GetScheduleCharges: `${NAMESPACE}/GET_SCHEDULE_CHARGES`,
  GetScheduleChargeTotalSplit: `${NAMESPACE}/GET_SCHEDULE_CHARGE_TOTAL_SPLIT`,
  GetAdditionalCharges: `${NAMESPACE}/GET_ADDITIONAL_CHARGES`,
};

const scheduleChargeTotalSplitMockData = new MockArrayData({
  id: 0,
  totalTitle: "Total",
  totalSplitAmount: 16498.39,
  totalFinanceSplit: 3917.17,
  scheduleChargeTotalSplit: [
    {
      id: 1,
      allocation: "IPS",
      splitAmount: 6290.61,
      financeSplit: 0.0,
    },
    {
      id: 2,
      allocation: "Supplier",
      splitAmount: 6290.61,
      financeSplit: 0.0,
    },
    {
      id: 3,
      allocation: "Funder 1",
      splitAmount: 2900.0,
      financeSplit: 2900.0,
    },
    {
      id: 4,
      allocation: "Funder 2",
      splitAmount: 1017.17,
      financeSplit: 1017.17,
    },
    {
      id: 5,
      allocation: "Rounding",
      splitAmount: 0.0,
      financeSplit: 0.0,
    },
  ],
});

const mockAdditionalCharges = [
  {
    seq: 3,
    chargeCode: "ADHOC",
    description: "Any Other AdHoc Charges",
    dueDate: "31-Dec-2020",
    value: 30,
    chargeType: "Annual",
    invoiceType: "Minimum",
    invoiceTo: "Customer",
    paidTo: "IP",
    includeOnRewrite: "Yes",
    edit: true,
  },
  {
    seq: 4,
    chargeCode: "ASFEE",
    description: "Annual Service Fee (Admin)",
    dueDate: "31-Dec-2020",
    value: 50,
    chargeType: "One Time",
    invoiceType: "Minimum",
    invoiceTo: "Supplier",
    paidTo: "Funder",
    includeOnRewrite: "Yes",
    edit: true,
  },
  {
    edit: false,
  },
  {
    edit: false,
  },
  {
    edit: false,
  },
  {
    edit: false,
  },
];

export const getScheduleCharges = (_, scheduleId) => {
  return apiGet(
    ActionTypes.GetScheduleCharges,
    `schedules/${scheduleId}/charges`
  );
};

export const getScheduleChargesTotalSplit = () => {
  return mockSuccess(ActionTypes.GetScheduleChargeTotalSplit, {
    response: scheduleChargeTotalSplitMockData._data,
  });
};

export const getAdditionalCharges = () => {
  return mockSuccess(ActionTypes.GetAdditionalCharges, {
    response: mockAdditionalCharges,
  });
};
