import React from "react";
import { ListBuilder, Table, ActionBar } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { getServices, addService, editService, removeService } from "./actions";
import SearchServices from "./SearchServices";
import ServiceActions from "./ServiceActions";
import AddServiceModal from "./AddServiceModal";
import { SortHeader } from "../../../components/tables/sortableTable";

const ListServices = ({ dealerOfficeId, searchImage }) => {
  return (
    <React.Fragment>
      <h4>Quarterly Services</h4>
      <ListBuilder
        withTable
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        loadAction={dealerOfficeId ? getServices : null}
        loadParams={{ dealerOfficeId }}
        renderList={(tableProps, state, events) => (
          <div className="content-container principal-list services-list">
            <ActionBar>
              <ActionBar.Item fluid>
                <SearchServices
                  searchImage={searchImage}
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </ActionBar.Item>
              <ActionBar.Item align="right">
                {dealerOfficeId && (
                  <AddServiceModal
                    dealerOfficeId={dealerOfficeId}
                    submitAction={addService}
                    onSubmitted={events.onRefresh}
                  />
                )}
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              compact
              dataKey="id"
              emptyMessage="No Services found"
              selectable
            >
              <Table.Column
                field="name"
                title="Name"
                header={
                  <SortHeader
                    field="name"
                    title="Name"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                field="description"
                title="Description"
                header={
                  <SortHeader
                    field="description"
                    title="Description"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                field="termsAndConditionsReference"
                title="Terms and Conditions Ref"
                width="15%"
                header={
                  <SortHeader
                    field="termsAndConditionsReference"
                    title="Terms and Conditions Ref"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                field="billingCode"
                title="Billing Code"
                header={
                  <SortHeader
                    field="billingCode"
                    title="Billing Code"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                field="type"
                title="Service Type"
                header={
                  <SortHeader
                    field="type"
                    title="Service Type"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                field="code"
                title="Code"
                header={
                  <SortHeader
                    field="code"
                    title="Code"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                hoverable
                align="right"
                render={(item) => (
                  <ServiceActions
                    service={item}
                    deleteAction={removeService}
                    editAction={editService}
                    onRefresh={events.onRefresh}
                  />
                )}
              />
            </Table>
            <TablePagination
              pageNumber={state.pagination.pageNumber}
              onChange={events.onChangePagination}
              pageSize={state.pagination.pageSize}
              totalItems={state.total}
            />
          </div>
        )}
      />
    </React.Fragment>
  );
};

export default ListServices;
