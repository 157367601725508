import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULES/CFA";

const ActionTypes = {
  getSummary: `${NAMESPACE}/GET_SUMMARY`,
  fmvTotals: `${NAMESPACE}/GET_FMV_TOTALS`,
  unconvertedFMV: `${NAMESPACE}/GET_UNCONVERTED_FMV`,
  getSfAccountsRemaining: `${NAMESPACE}/GET_SF_ACCOUNTS_REMAINING`,
  getAmountsCollected: `${NAMESPACE}/GET_AMOUNTS_COLLECTED`,
  getAdditionalUnitsCharge: `${NAMESPACE}/GET_ADDITIONAL_UNITS_CHARGE`,
  getFunderDetails: `${NAMESPACE}/GET_FUNDER`,
  getSupplierDetails: `${NAMESPACE}/GET_SUPPLIER`,
  getCoFundedDetails: `${NAMESPACE}/GET_CO_FUNDED`,
  getGrandTotals: `${NAMESPACE}/GET_GRAND_Totals`,
};

const summary = [
  {
    id: 1,
    paymentMethod: "Quarterly in advance by Direct Debit",
    paymentTotal: 6416.2,
    scheduleFinanceTotal: 6416.76,
    roundingTotal: 0.56,
    schedulePeriods: 5.578,
    scheduleOutstandingPeriods: 2.429,
    scheduleTotalOutstanding: 15584.94,
    scheduleMaxFinanceVolRemaining: 135214,
    financeCPI: 7.639,
  },
];

const amountsCollected = [
  {
    id: 1,
    title: "Funder 1",
    minimum: 2138.01,
    additionalUnits: 106.35,
    cpMinimum: 123.65,
    cpAdditionalUnits: 6.15,
    total: 4287.6,
  },
  {
    id: 2,
    title: "Funder 2",
    minimum: 3765.33,
    additionalUnits: 187.3,
    cpMinimum: 0,
    cpAdditionalUnits: 0,
    total: 3952.63,
  },
  {
    id: 3,
    title: "Konica Minolta",
    minimum: 8721.87,
    additionalUnits: 433.85,
    cpMinimum: 865.58,
    cpAdditionalUnits: 43.06,
    total: 11254.52,
  },
  {
    id: 4,
    title: "IP Syndicate",
    minimum: 430.32,
    additionalUnits: 21.41,
    cpMinimum: 247.31,
    cpAdditionalUnits: 12.3,
    total: 711.34,
  },
  {
    id: 5,
    title: "Schedule Total",
    minimum: 15055.53,
    additionalUnits: 748.91,
    cpMinimum: 1236.54,
    cpAdditionalUnits: 61.51,
    total: 20206.09,
  },
  {
    id: 6,
    title: "Rounding",
    minimum: -0.1,
    total: -0.1,
  },
  {
    id: 7,
    title: "Adjustments",
  },
  {
    id: 8,
    title: "Funder 1",
    total: 0,
  },
  {
    id: 9,
    title: "Funder 2",
    total: 0,
  },
  {
    id: 10,
    title: "Konica Minolta",
    total: 0,
  },
  {
    id: 11,
    title: "IP Syndicate",
    total: 0,
  },
  {
    id: 12,
    title: "Schedule Total",
    minimum: 15055.43,
    additionalUnits: 748.91,
    cpMinimum: 1236.54,
    cpAdditionalUnits: 61.51,
    total: 20205.99,
  },
];

const additionalUnitsCharge = [
  {
    id: 1,
    title: "Allocated",
    total: 1500,
  },
  {
    id: 2,
    title: "Unallocated",
    total: 1128.75,
  },
  {
    id: 3,
    title: "Prior Schedule",
    total: 2628.75,
  },
  {
    id: 4,
    title: "Rewrite Forward Additional Amount",
    total: 0,
  },
  {
    id: 5,
    title: "Current Schedule",
    total: 748.91,
  },
  {
    id: 6,
    title: "Total",
    total: 3377.66,
  },
  {
    id: 7,
    title: "Settled via Invoice",
    total: 0,
  },
  {
    id: 8,
    title: "Reallocated to CP",
    total: 0,
  },
  {
    id: 9,
    title: "Available Amount",
    total: 3377.66,
  },
];

const fmvTotals = [
  {
    id: 1,
    title: "Converted",
    value: 1800,
  },
  {
    id: 2,
    title: "Unconverted",
    value: 1500,
  },
  {
    id: 3,
    title: "Total",
    value: 3300,
  },
  {
    id: 4,
    title: "Converted-Sum to Collect",
    value: 3377.66,
  },
];

const unconvertedFMV = [
  {
    id: 1,
    cfaReference: "CD1223E",
    dueToFunder: 500,
    dueDt: "06/14/2020",
    paid: true,
    unconvertedAmount: 0,
  },
  {
    id: 2,
    cfaReference: "KM621400",
    dueToFunder: 1000,
    dueDt: "12/14/2020",
    paid: false,
    unconvertedAmount: 1000,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
  {
    id: 8,
  },
  {
    id: 9,
    cfaReference: "Total",
    dueToFunder: 1500,
    unconvertedAmount: 1000,
  },
];

const sfAccountsRemaining = [
  {
    id: 1,
    title: "Funder 1",
    cfa: 681.36,
    cpMin: 352.64,
    total: 1034,
  },
  {
    id: 2,
    title: "Funder 2",
    cfa: 1515.62,
    cpMin: 153.28,
    total: 1668.9,
  },
  {
    id: 3,
    title: "Konica Minolta",
    cfa: 2728.09,
    cpMin: 7824.61,
    total: 10552.7,
  },
  {
    id: 4,
    title: "IP Syndicate",
    cfa: 230.63,
    cpMin: 2097.34,
    total: 2327.97,
  },
  {
    id: 5,
    title: "Total",
    cfa: 5155.7,
    cpMin: 10427.87,
    total: 15583.57,
  },
];

const funderDetails = [
  {
    id: 1,
    cfaReference: "ABC444",
    funder: "IP Syndicate",
    startDate: "08/05/2014",
    status: "Continuing Payments",
    rateType: "EFA",
    cfaPayment: 412.18,
    periodsOutstanding: 0,
    minimumOutstanding: 0,
    scheduleAdditionalUnits: 0,
    totalOutstanding: 0,
    financeVolume: 0,
    scheduleTotalOutstanding: 1001.1,
    continuingPaymentOutstanding: 1001.1,
    accruedAdditionalUnitsNotIncluded: 0,
    funderOutstanding: 0,
  },
  {
    id: 2,
    cfaReference: "ABC9110",
    funder: "Funder 1",
    startDate: "11/05/2016",
    status: "Live",
    rateType: "FMV - Converted",
    cfaPayment: 1320.26,
    periodsOutstanding: 1.578,
    minimumOutstanding: 3025.2,
    scheduleAdditionalUnits: 521.15,
    totalOutstanding: 681.36,
    financeVolume: 43347,
    scheduleTotalOutstanding: 3206.63,
    continuingPaymentOutstanding: 2525.27,
    accruedAdditionalUnitsNotIncluded: 0,
    funderOutstanding: 681.36,
  },
  {
    id: 3,
    cfaReference: "CD1223E",
    funder: "Funder 2",
    startDate: "02/05/2017",
    status: "Live",
    rateType: "FMV - Part Converted",
    cfaPayment: 1255.11,
    periodsOutstanding: 2.0,
    minimumOutstanding: 2510.22,
    scheduleAdditionalUnits: 994.6,
    totalOutstanding: 1515.62,
    financeVolume: 101426,
    scheduleTotalOutstanding: 3048.39,
    continuingPaymentOutstanding: 1532.77,
    accruedAdditionalUnitsNotIncluded: 0,
    funderOutstanding: 1515.62,
  },
  {},
  {
    cfaPayment: 2987.55,
    minimumOutstanding: 5535.5,
    scheduleAdditionalUnits: 1515.75,
    totalOutstanding: 2196.98,
    financeVolume: 101426,
    scheduleTotalOutstanding: 7256.12,
    continuingPaymentOutstanding: 5059.14,
    accruedAdditionalUnitsNotIncluded: 0,
    funderOutstanding: 2196.98,
  },
];

const supplierDetails = [
  {
    id: 1,
    cfaReference: "KM577553",
    funder: "KM",
    startDate: "08/05/2017",
    status: "Live",
    rateType: "EFA",
    cfaPayment: 1341.11,
    periodsOutstanding: 2,
    minimumOutstanding: 2682.22,
    scheduleAdditionalUnits: 712.1,
    totalOutstanding: 1970.12,
    financeVolume: 0,
    scheduleTotalOutstanding: 3257.27,
    continuingPaymentOutstanding: 1287.15,
    accruedAdditionalUnitsNotIncluded: 0,
    funderOutstanding: 1970.12,
  },
  {
    id: 2,
    cfaReference: "IPS992",
    funder: "IP Syndicate",
    startDate: "08/05/2017",
    status: "Live",
    rateType: "EFA",
    cfaPayment: 56.77,
    periodsOutstanding: 2,
    minimumOutstanding: 113.54,
    scheduleAdditionalUnits: 22.15,
    totalOutstanding: 91.39,
    financeVolume: 135214,
    scheduleTotalOutstanding: 137.88,
    continuingPaymentOutstanding: 46.49,
    accruedAdditionalUnitsNotIncluded: 0,
    funderOutstanding: 91.39,
  },
  {
    id: 3,
    cfaReference: "KM621400",
    funder: "KM",
    startDate: "05/05/2018",
    status: "Live",
    rateType: "FM - Unconverted",
    cfaPayment: 1944.1,
    periodsOutstanding: 0.851,
    minimumOutstanding: 3132.36,
    scheduleAdditionalUnits: 1240.63,
    totalOutstanding: 757.97,
    financeVolume: 32747,
    scheduleTotalOutstanding: 4721.8,
    continuingPaymentOutstanding: 3963.83,
    accruedAdditionalUnitsNotIncluded: 0,
    funderOutstanding: 757.97,
  },
  {
    id: 4,
    cfaReference: "IPS1491",
    funder: "IP Syndicate",
    startDate: "05/05/2018",
    status: "Live",
    rateType: "FMV - N/A",
    cfaPayment: 86.67,
    periodsOutstanding: 2,
    minimumOutstanding: 173.34,
    scheduleAdditionalUnits: 34.1,
    totalOutstanding: 139.24,
    financeVolume: 134939,
    scheduleTotalOutstanding: 210.5,
    continuingPaymentOutstanding: 71.26,
    accruedAdditionalUnitsNotIncluded: 0,
    funderOutstanding: 139.24,
  },
  {},
  {
    cfaPayment: 3428.65,
    minimumOutstanding: 6101.46,
    scheduleAdditionalUnits: 2008.98,
    totalOutstanding: 2958.72,
    financeVolume: 135214,
    scheduleTotalOutstanding: 8327.45,
    continuingPaymentOutstanding: 5368.73,
    accruedAdditionalUnitsNotIncluded: 0,
    funderOutstanding: 2958.72,
  },
];

const coFunderDetails = [
  {},
  {},
  {
    cfaPayment: 0,
    minimumOutstanding: 0,
    scheduleAdditionalUnits: 0,
    totalOutstanding: 0,
    financeVolume: 0,
    scheduleTotalOutstanding: 0,
    continuingPaymentOutstanding: 0,
    accruedAdditionalUnitsNotIncluded: 0,
    funderOutstanding: 0,
  },
];

const grandTotals = [
  {
    cfaPayment: 6416.2,
    minimumOutstanding: 11636.96,
    scheduleAdditionalUnits: 3524.73,
    totalOutstanding: 5155.7,
    financeVolume: 135214,
    scheduleTotalOutstanding: 15583.57,
    continuingPaymentOutstanding: 10427.87,
    accruedAdditionalUnitsNotIncluded: 0,
    funderOutstanding: 5155.7,
  },
];

export const getSummary = () => {
  return mockSuccess(ActionTypes.getSummary, {
    delay: 500,
    response: summary,
  });
};

export const getAmountsCollected = () => {
  return mockSuccess(ActionTypes.getAmountsCollected, {
    delay: 500,
    response: amountsCollected,
  });
};

export const getAdditionalUnitsCharge = () => {
  return mockSuccess(ActionTypes.getAdditionalUnitsCharge, {
    delay: 500,
    response: additionalUnitsCharge,
  });
};

export const getFmvTotals = () => {
  return mockSuccess(ActionTypes.fmvTotals, {
    delay: 500,
    response: fmvTotals,
  });
};

export const getUnconvertedFMV = () => {
  return mockSuccess(ActionTypes.unconvertedFMV, {
    default: 500,
    response: unconvertedFMV,
  });
};

export const getSFAccountsRemaining = () => {
  return mockSuccess(ActionTypes.getSfAccountsRemaining, {
    default: 500,
    response: sfAccountsRemaining,
  });
};

export const getFunderDetails = () => {
  return mockSuccess(ActionTypes.getFunderDetails, {
    delay: 100,
    response: funderDetails,
  });
};

export const getSupplierDetails = () => {
  return mockSuccess(ActionTypes.getSupplierDetails, {
    delay: 100,
    response: supplierDetails,
  });
};

export const getCoFunderDetails = () => {
  return mockSuccess(ActionTypes.getCoFundedDetails, {
    delay: 100,
    response: coFunderDetails,
  });
};

export const getGrandTotals = () => {
  return mockSuccess(ActionTypes.getGrandTotals, {
    delay: 100,
    response: grandTotals,
  });
};
