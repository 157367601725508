import React from "react";
import { SidePanel, Form } from "@redriver/cinnamon";
import { FilterButton } from "features/../../../shared/components/buttons";
import { DealerOfficesTypeAheadLookup } from "../../../../shared/features/Settings";
import { ClientsDropdown } from "../../../../shared/components/forms";
import { Grid } from "semantic-ui-react";

const statusList = [
  { value: "Live", text: "Live" },
  { value: "dead", text: "Dead" },
  { value: "continuingPayments", text: "Continuing Payments" },
];

const spvList = [{ value: "IPS SPV I LTD", text: "IPS SPV I LTD" }];

const funderList = [
  { value: "BNP Paribas", text: "BNP Paribas" },
  { value: "Macquarie", text: "Macquarie" },
];

export const FieldNames = {
  Status: "status",
  Client: "client",
  Supplier: "supplier",
  SPV: "spv",
  StartDate: "startDate",
  EndDate: "endDate",
  Funder: "funder",
};

const CFAFilters = ({ title, formProps, onReset }) => {
  return (
    <SidePanel
      closeIcon
      trigger={<FilterButton />}
      className="cfa-list-filter-side-panel"
    >
      <div>
        <div className="ui header filters-title">
          <div className="title-text">{title}</div>
          <div className="clear-button" onClick={onReset}>
            (clear)
          </div>
        </div>
        <Form {...formProps} className="highlighted-inputs">
          <div className="ui form highlighted-inputs">
            <Grid columns={2}>
              <Grid.Column width={16}>
                <Form.Dropdown
                  field={FieldNames.Status}
                  label="Status"
                  placeholder="Select a status"
                  options={statusList}
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <ClientsDropdown
                  field={FieldNames.Client}
                  label="Customer"
                  placeholder="Select a customer"
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <DealerOfficesTypeAheadLookup
                  field={FieldNames.Supplier}
                  fluid
                  placeholder="Select a supplier"
                  label="Supplier"
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <div className="field">
                  <Form.Dropdown
                    field={FieldNames.SPV}
                    label="SPV"
                    placeholder="Select a SPV"
                    options={spvList}
                  />
                </div>
              </Grid.Column>
              <Grid.Row>
                <Grid.Column width={8}>
                  <div className="field">
                    <Form.Date
                      local
                      field={FieldNames.StartDate}
                      label="Start Date"
                      placeholder="Select a Start Date"
                    />
                  </div>
                </Grid.Column>
                <Grid.Column width={8}>
                  <div className="field">
                    <Form.Date
                      local
                      field={FieldNames.EndDate}
                      label="End Date"
                      placeholder="Select a End Date"
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Column width={16}>
                <div className="field">
                  <Form.Dropdown
                    field={FieldNames.Funder}
                    label="Funder"
                    placeholder="Select a Funder"
                    options={funderList}
                  />
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </Form>
      </div>
    </SidePanel>
  );
};

export default CFAFilters;
