import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { UploadEntityTypeDropdown } from "features/../../../shared/components/forms";
import { uploadTemplate } from "./actions";
import { SettingsUploadErrorMessages } from "features/../../../shared/components/errors";

const UploadTemplate = ({ onSubmitted }) => (
  <ModalBuilder
    withForm
    submitAction={uploadTemplate}
    onSubmitted={onSubmitted}
    renderTrigger={(showModal) => (
      <Button
        primary
        icon="upload"
        content="Upload Template"
        onClick={showModal}
      />
    )}
    renderModal={({ error, ...modalProps }, formProps) => (
      <Modal.Upload {...modalProps} header="Update Template">
        <Form {...formProps}>
          <UploadEntityTypeDropdown
            required
            field="type"
            placeholder="Select a template"
            label="Template"
          />
          <Form.FileUpload
            field="file"
            fluid
            required
            fileExtensionTypes={["xlsx"]}
          />
          <SettingsUploadErrorMessages error={error} />
        </Form>
      </Modal.Upload>
    )}
  />
);

export default UploadTemplate;
