import React, { useEffect, useState } from "react";
const { ContentPanel } = require("components/displays");
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Menu,
  TextArea,
} from "semantic-ui-react";
import {
  PageContent,
  Form,
  ContentBuilder,
  Wizard,
  Format,
  FormBuilder,
  ModalBuilder,
  Modal,
  ErrorMessage,
} from "@redriver/cinnamon";
import {
  getCustomerCrmRestriction,
  getCustomerCrmUrl,
  getCustomerDetails,
  putCustomerCrmRestriction,
} from "./actions";
import CompaniesSteps from "constants/enums/CompaniesSteps";
import { CompanySummary } from "./Summary";
import { CompanySchedules } from "./Schedules";
import { Attachments } from "./Attachments";
import { Products } from "./Products";
import { CompanyInvoices } from "./Invoices";
import { CustomerBanking } from "./Banking";
import { CustomerSignatories } from "./Signatories";
import { RedirectBreadcrumbBar } from "pages/nav";
import { AppRoutes } from "constants/routes";
import { useDispatch } from "react-redux";
import { UserTypeAhead } from "../../../../../shared/features/Settings";
import { WriteTextToClipboardButton } from "../../../../../shared/components/buttons";
import { getClient } from "features/../../../shared/features/Clients/actions";

const CustomerDetails = ({ match }) => {
  const clientId = match.params.id;

  const NavigationControls = ({ steps, activeStep, onGoToStep }) => {
    return steps.map((s) => (
      <Menu.Item
        value={0}
        key={s.key}
        active={s === activeStep}
        onClick={() => onGoToStep(s.key)}
      >
        {s.title}
      </Menu.Item>
    ));
  };

  const stepData = [
    {
      key: CompaniesSteps.Summary,
      title: "Summary",
    },
    {
      key: CompaniesSteps.Signatories,
      title: "Signatories",
    },
    {
      key: CompaniesSteps.Attachments,
      title: "Attachments",
    },
    {
      key: CompaniesSteps.Schedules,
      title: "Schedules",
    },
    {
      key: CompaniesSteps.Products,
      title: "Products",
    },
    {
      key: CompaniesSteps.Invoices,
      title: "Invoices",
    },
    {
      key: CompaniesSteps.Banking,
      title: "Banking",
    },
  ];

  return (
    <PageContent fluid className="companies-container">
      <RedirectBreadcrumbBar
        to={AppRoutes.Customers}
        text="Back to Customers"
      />
      <ContentBuilder
        loadAction={getCustomerDetails}
        dataTransform={(data) => data._data}
        renderContent={(data) => (
          <React.Fragment>
            <ContentPanel
              title="Customer Details"
              headerContent={
                <div className="flex">
                  <InlineHeaderForm clientId={clientId} />
                  {process.env.ENABLE_CLIENT_CRM_URL_GENERATION && (
                    <CrmLinkModal clientId={clientId} />
                  )}
                </div>
              }
              wrapperCssClass="outline-grid customers-list"
              headerCssClass="defaults-page-title"
            >
              <div className="padded">
                <DetailsForm data={data}></DetailsForm>
              </div>
            </ContentPanel>
            <div className="red-highlight right">
              {" "}
              Active Customer Since:{" "}
              <Format.Date value={data.activeSinceDt} format="DD MMM YYYY" />
            </div>
          </React.Fragment>
        )}
      ></ContentBuilder>
      <div className="clear"></div>
      <Wizard steps={stepData}>
        <Wizard.Nav
          render={(wizardContext) => (
            <Menu>
              <NavigationControls {...wizardContext} />
            </Menu>
          )}
        />
        <Wizard.Step
          step={CompaniesSteps.Summary}
          render={() => <CompanySummary />}
        />
        <Wizard.Step
          step={CompaniesSteps.Signatories}
          render={() => <CustomerSignatories />}
        />
        <Wizard.Step
          step={CompaniesSteps.Attachments}
          render={() => <Attachments />}
        />
        <Wizard.Step
          step={CompaniesSteps.Schedules}
          render={() => <CompanySchedules />}
        />
        <Wizard.Step
          step={CompaniesSteps.Products}
          render={() => <Products />}
        />
        <Wizard.Step
          step={CompaniesSteps.Invoices}
          render={() => <CompanyInvoices />}
        />
        <Wizard.Step
          step={CompaniesSteps.Banking}
          render={() => <CustomerBanking />}
        />
      </Wizard>
    </PageContent>
  );
};

const InlineHeaderForm = ({ clientId }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(undefined);
  const [submitTimeout, setSubmitTimeout] = useState(undefined);

  useEffect(() => {
    (async () => {
      const { success, response } = await dispatch(
        getCustomerCrmRestriction(clientId),
      );

      success && setFormData(response);
    })();
  }, [clientId, dispatch]);

  return (
    <React.Fragment>
      <FormBuilder
        submitAction={() => {
          const { restrictCrmUrlUpdate } = formData;
          return putCustomerCrmRestriction({ clientId, restrictCrmUrlUpdate });
        }}
        renderForm={(formProps, state, events) => (
          <Form
            {...formProps}
            value={formData}
            readOnly={false}
            disabled={!formData}
            onChange={(field, data, applyChanges) => {
              setFormData(applyChanges(formProps.value));
              clearTimeout(submitTimeout);
              setSubmitTimeout(setTimeout(events.onSubmit, 500)); //suppress rapid toggling
            }}
          >
            <Form.Checkbox
              field="restrictCrmUrlUpdate"
              label={
                <label>
                  <span>Stop CRM Updating Record</span>
                  <Icon name="edit" />
                </label>
              }
            />
          </Form>
        )}
      />
    </React.Fragment>
  );
};

const CrmLinkModal = ({ clientId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [output, setOutput] = useState(undefined);
  const [error, setError] = useState(undefined);

  return (
    <ModalBuilder
      withForm
      renderTrigger={(onShowModal) => (
        <Button primary icon="code" content="CRM Link" onClick={onShowModal} />
      )}
      renderModal={(modalProps, formProps) => (
        <Modal
          {...modalProps}
          header="Generate CRM URL"
          cancelLabel="Close"
          submitLabel="Generate"
          loading={loading}
          slowLoading={loading}
          onSubmit={() => {
            setLoading(true);
            const { userId } = formProps.value;

            (async () => {
              const { response, error } = await dispatch(
                getCustomerCrmUrl({ clientId, userId }),
              );
              setError(error);
              setOutput(response?.urlEncoded);
              setLoading(false);
            })();
          }}
        >
          <Form {...formProps}>
            <ContentBuilder
              loadAction={getClient}
              loadParams={clientId}
              renderContent={(data) => (
                <UserTypeAhead
                  required
                  placeholder="Search Users"
                  field="userId"
                  label="Member User"
                  lookupParams={{
                    dealerId: data.dealerId,
                    includeInternalUsers: true,
                  }}
                />
              )}
            />

            <ErrorMessage error={error} />
            {output && (
              <React.Fragment>
                <TextArea readOnly value={output} rows={5} />
                <div className="flex end">
                  <WriteTextToClipboardButton valueToWrite={output} />
                </div>
              </React.Fragment>
            )}
          </Form>
        </Modal>
      )}
    />
  );
};

const DetailsForm = ({ data }) => {
  const statusList = [
    { value: 1, text: "Live" },
    { value: 2, text: "Active" },
  ];

  return (
    <Form value={data} disabled className="customer-details">
      <Grid columns={16}>
        <GridColumn
          mobile={16}
          tablet={16}
          computer={6}
          largeScreen={6}
          widescreen={6}
        >
          <Grid>
            <GridRow>
              <GridColumn
                mobile={5}
                tablet={5}
                computer={6}
                largeScreen={6}
                widescreen={5}
              >
                Customer Name:
              </GridColumn>
              <GridColumn
                mobile={11}
                tablet={11}
                computer={10}
                largeScreen={10}
                widescreen={11}
              >
                {" "}
                <Form.Input field="name" />
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn
                mobile={5}
                tablet={5}
                computer={6}
                largeScreen={6}
                widescreen={5}
              >
                Customer Registration Number:
              </GridColumn>
              <GridColumn
                className="regNo"
                mobile={11}
                tablet={11}
                computer={10}
                largeScreen={10}
                widescreen={11}
              >
                <Form.Input field="regNo" />
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
        <GridColumn
          mobile={16}
          tablet={16}
          computer={6}
          largeScreen={6}
          widescreen={6}
        >
          <Grid>
            <GridRow>
              <GridColumn
                mobile={5}
                tablet={5}
                computer={4}
                largeScreen={4}
                widescreen={4}
              >
                Opal ID:
              </GridColumn>
              <GridColumn
                mobile={11}
                tablet={11}
                computer={12}
                largeScreen={12}
                widescreen={12}
              >
                {" "}
                <Form.Input field="opalID" />
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn
                mobile={5}
                tablet={5}
                computer={4}
                largeScreen={4}
                widescreen={4}
              >
                CRM ID:
              </GridColumn>
              <GridColumn
                mobile={11}
                tablet={11}
                computer={12}
                largeScreen={12}
                widescreen={12}
              >
                <Form.Input field="crmID" />
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn
                mobile={5}
                tablet={5}
                computer={4}
                largeScreen={4}
                widescreen={4}
              >
                Account Manager:
              </GridColumn>
              <GridColumn
                mobile={11}
                tablet={11}
                computer={12}
                largeScreen={12}
                widescreen={12}
              >
                <Form.Input field="acctManager" />
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
        <GridColumn
          mobile={16}
          tablet={16}
          computer={4}
          largeScreen={4}
          widescreen={4}
        >
          <Grid>
            <GridRow>
              <GridColumn
                mobile={5}
                tablet={5}
                computer={8}
                largeScreen={8}
                widescreen={8}
              >
                Customer Status:
              </GridColumn>
              <GridColumn
                mobile={11}
                tablet={11}
                computer={8}
                largeScreen={8}
                widescreen={8}
              >
                <Form.Dropdown field="status" options={statusList} />
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn
                mobile={5}
                tablet={5}
                computer={8}
                largeScreen={8}
                widescreen={8}
              >
                Billing Status:
              </GridColumn>
              <GridColumn
                mobile={11}
                tablet={11}
                computer={8}
                largeScreen={8}
                widescreen={8}
              >
                <Form.Dropdown field="billingStatus" options={statusList} />
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn
                mobile={5}
                tablet={5}
                computer={8}
                largeScreen={8}
                widescreen={8}
              >
                Document Production:
              </GridColumn>
              <GridColumn
                mobile={11}
                tablet={11}
                computer={8}
                largeScreen={8}
                widescreen={8}
              >
                <Form.Dropdown
                  field="documentProduction"
                  options={statusList}
                />
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
      </Grid>
    </Form>
  );
};

export default CustomerDetails;
