import React, { useEffect, useState } from "react";
import { ContentPanel } from "components/displays";
import {
  ListBuilder,
  ActionBar,
  FormBuilder,
  usePreferences,
  Form,
} from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import {
  TableConfigurator,
  ConfigurableTable,
} from "features/CustomConfig/TableConfig";
import { getMiscInvoicing } from "../actions";
import { AddModalWrapper } from "components/modals";
import {
  numericFormatter,
  dateFormatter,
} from "components/displays/DisplayHelper";
import { FilterGrid } from ".";
import { Image } from "semantic-ui-react";
import Search from "features/../../../shared/assets/purple-search.svg";

const MiscellaneousInvoicing = () => {
  const { preferences } = usePreferences();
  const [config, setConfig] = useState(preferences.miscinvoicingTableConfig);
  const [pageSizeConfig, setPageSizeConfig] = useState(
    preferences.miscinvoicingTableConfigPageSize
      ? preferences.miscinvoicingTableConfigPageSize
      : 25
  );

  const [selectedItems, setSelected] = useState({});

  useEffect(() => {
    setConfig(preferences.miscinvoicingTableConfig);
  }, [preferences.miscinvoicingTableConfig]);

  useEffect(() => {
    preferences.miscinvoicingTableConfigPageSize &&
      setPageSizeConfig(preferences.miscinvoicingTableConfigPageSize);
  }, [preferences.miscinvoicingTableConfigPageSize]);

  const columns = [
    {
      field: "id",
      title: "Selectable Column",
      selectableColumn: true,
      sortable: false,
    },
    { field: "customer", title: "Customer", width: "20em" },
    { field: "customerIdentifier", title: "Customer ID", width: "10em" },
    { field: "supplier", title: "Supplier" },
    { field: "supplierOffice", title: "Supplier Office" },
    { field: "spv", title: "SPV" },
    { field: "scheduleNumber", title: "Schedule Number" },
    {
      field: "taxDate",
      title: "Tax Date",
      render: (item) => dateFormatter(item.taxDate),
    },
    { field: "documentSubtype", title: "Document Subtype" },
    { field: "currency", title: "Currency" },
    {
      field: "netValue",
      title: "Net Value (£)",
      render: (item) => numericFormatter(item.netValue, 0, 2),
    },
  ];

  return (
    <ListBuilder
      withTable
      initialPagination={{ pageSize: pageSizeConfig, pageNumber: 1 }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      loadAction={getMiscInvoicing}
      loadParams={{ pageSize: pageSizeConfig }}
      renderList={(tableProps, state, events) => (
        <ContentPanel
          title=""
          wrapperCssClass="outline-grid customer-billing-list misc-invoicing"
          headerCssClass="defaults-page-title"
          headerContent={
            <FilterBar
              state={state}
              events={events}
              config={config}
              columns={columns}
              initialPageSize={25}
              pageSizeConfig={pageSizeConfig}
            ></FilterBar>
          }
        >
          <div className="spaced">
            <div className="vertical-scrollbar large">
              <ConfigurableTable
                state={state}
                events={events}
                tableProps={tableProps}
                emptyMessage="No invoices found"
                columns={columns}
                config={config}
                selectedItems={selectedItems}
                setSelected={setSelected}
              />
            </div>
            <div className="padded">
              <TablePagination
                pageSize={pageSizeConfig}
                pageNumber={state.pagination.pageNumber}
                onChange={events.onChangePagination}
                totalItems={state.total}
              />
            </div>
            <div className="footer">
              <AddModalWrapper title="Create Selected Invoice/s">
                Not Implemented
              </AddModalWrapper>
            </div>
          </div>
        </ContentPanel>
      )}
    />
  );
};

const FilterBar = ({
  state,
  events,
  config,
  columns,
  initialPageSize,
  pageSizeConfig,
}) => (
  <FormBuilder
    value={state.filters}
    onChange={events.onChangeFilters}
    renderForm={(formProps) => (
      <Form {...formProps}>
        <ActionBar className="filter-display">
          <ActionBar.Item>
            <h4 className="finance-title">Miscellaneous Invoicing</h4>
            <div className="sml-screen">
              <TableConfigurator
                config={config}
                columns={columns}
                configId="miscinvoicingTableConfig"
                titlePreFix="Miscellaneous Invoicing"
                initialPageSize={initialPageSize}
                pageSizeConfig={pageSizeConfig}
              />
            </div>
            <AddModalWrapper title="Add Miscellaneous Invoice">
              Not Implemented
            </AddModalWrapper>
            <div className="text-search-wrapper">
              <Form.Input
                field="search"
                placeholder="Search"
                icon={<Image src={Search} />}
                className="search-input highlighted-input"
              />
            </div>
          </ActionBar.Item>
          <ActionBar.Item fluid></ActionBar.Item>
          <ActionBar.Item className="right-header-col">
            <FilterGrid
              config={config}
              columns={columns}
              configId="miscinvoicingTableConfig"
              titlePreFix="Miscellaneous Invoicing"
              initialPageSize={initialPageSize}
              pageSizeConfig={pageSizeConfig}
            />
          </ActionBar.Item>
        </ActionBar>
      </Form>
    )}
  />
);

export default MiscellaneousInvoicing;
