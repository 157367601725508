import React from "react";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import { DealerOfficesTypeAheadLookup } from "..";
import { cloneRateGroup } from "./actions";
import { UserTypeCheck } from "../../../components/auth";
import { UserType } from "../../../constants/enums";
import { TranslatedModal } from "../../../components/modals";
import { useTranslation } from "react-i18next";

const CloneRateGroupModal = ({ open, setModalVisibility, onCloned, id }) => {
  const { t } = useTranslation("supplierOffices", {
    keyPrefix: "rates.cloneRateGroupModal",
  });
  return (
    <ModalBuilder
      withForm
      submitAction={cloneRateGroup}
      submitParams={{ rateGroupId: id }}
      onSubmitted={res => {
        setModalVisibility(false);
        onCloned(res);
      }}
      visible={open}
      onCancel={() => setModalVisibility(false)}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Edit
          {...modalProps}
          header={t("title")}
          submitLabel={t("submitLabel")}
        >
          <Form {...formProps}>
            <Form.Group widths="equal">
              <Form.Input
                field="name"
                label={t("name")}
                required
                placeholder={t("namePlaceholder")}
              />
              <UserTypeCheck restrictTo={UserType.HQ}>
                <Form.RadioGroup
                  field="appliesToType"
                  label={t("applyTo")}
                  options={[
                    { value: "global", text: t("global") },
                    { value: "dealer", text: t("supplier") },
                  ]}
                  required
                />
              </UserTypeCheck>
            </Form.Group>
            <UserTypeCheck restrictTo={UserType.HQ}>
              <Form.Group widths={2}>
                <Form.If
                  condition={({ fields }) => fields.appliesToType === "dealer"}
                >
                  <DealerOfficesTypeAheadLookup
                    field="dealerId"
                    label={t("supplier")}
                    fluid
                    required
                    placeholder={t("supplierPlaceholder")}
                  />
                </Form.If>
              </Form.Group>
            </UserTypeCheck>
          </Form>
        </TranslatedModal.Edit>
      )}
    />
  );
};

export default CloneRateGroupModal;
