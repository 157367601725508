import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "PRODUCTS/MOVEMENT";

const ActionTypes = {
  GetProductMovement: `${NAMESPACE}/GET_PRODUCT_MOVEMENT`,
};

const mockMovement = [
  {
    id: 1,
    transactionType: "Re-instate",
    quarterStartDate: "16-Feb-2020",
    account: "12345",
    companyName: "Test Company LLP",
    schedule: "KM4005-MA9441-SC24411",
    transactionDate: "18-Jan-2020",
  },
  {
    id: 2,
    transactionType: "Dead",
    quarterStartDate: "16-Feb-2020",
    account: "12345",
    companyName: "Test Company LLP",
    schedule: "KM4005-MA9441-SC24411",
    transactionDate: "14-Jan-2020",
  },
  {
    id: 3,
    transactionType: "Schedule Change",
    quarterStartDate: "16-Nov-2019",
    account: "12345",
    companyName: "Test Company LLP",
    schedule: "KM4005-MA9441-SC24411",
    transactionDate: "12-Nov-2019",
  },
  {
    id: 4,
    transactionType: "Live",
    quarterStartDate: "16-Feb-2019",
    account: "12345",
    companyName: "Test Company LLP",
    schedule: "KM4005-MA9441-SC20319",
    transactionDate: "16-Feb-2019",
  },
  {
    id: 5,
    transactionType: "Dead",
    quarterStartDate: "30-Oct-2018",
    account: "51511",
    companyName: "J Bloggs Co",
    schedule: "KM4005-MA7235-SC19888",
    transactionDate: "05-Nov-2018",
  },
  {
    id: 6,
    transactionType: "Schedule Change",
    quarterStartDate: "30-Jan-2018",
    account: "51511",
    companyName: "J Bloggs Co",
    schedule: "KM4005-MA7235-SC19888",
    transactionDate: "14-Jan-2018",
  },
  {
    id: 7,
    transactionType: "Schedule Change",
    quarterStartDate: "30-Jul-2017",
    account: "51511",
    companyName: "J Bloggs Co",
    schedule: "KM4005-MA7235-SC18716",
    transactionDate: "14-Aug-2017",
  },
  {
    id: 8,
    transactionType: "Live",
    quarterStartDate: "30-Jan-2017",
    account: "51511",
    companyName: "J Bloggs Co",
    schedule: "KM4005-MA7235-SC17230",
    transactionDate: "30-Jan-2017",
  },
];

export const getProductMovement = () => {
  return mockSuccess(ActionTypes.GetProductMovement, {
    delay: 500,
    response: mockMovement,
  });
};
