import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";

import { updateFunderAuthorisedSignatory } from "./actions";
import SignatoryForm from "./SignatoryForm";

const EditFunderSignatory = ({ signatory, funderId, onRefresh }) => (
  <ModalBuilder
    withForm
    submitAction={updateFunderAuthorisedSignatory}
    submitParams={{ funderId: funderId, itemId: signatory.id }}
    initialData={signatory}
    onSubmitted={onRefresh}
    renderTrigger={(showModal) => (
      <Button
        icon="pencil alternate"
        className="delete-icon-btn"
        color="blue"
        onClick={showModal}
      />
    )}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit
        {...modalProps}
        header="Update Funder Signatory"
        submitLabel="Save"
        headerClassName="yellow"
        className="add-modal yellow"
      >
        <SignatoryForm {...formProps} />
      </Modal.Edit>
    )}
  />
);

export default EditFunderSignatory;
