export const FunderStatus = {
  Pending: "Pending",
  Live: "Live",
  NoNewDeals: "No New Deals",
  Dead: "Dead",
};

export const FunderStatusEnum = {
  Pending: "Pending",
  Live: "Live",
  NoNewDeals: "NoNewDeals",
  Dead: "Dead",
};
