import React from "react";
import { getCFASummary } from "./actions";
import { ContentBuilder } from "@redriver/cinnamon";
import CFACharge from "./CFACharge";
import CFAPayment from "./CFAPayment";
import Term from "./Term";
import Totals from "./Totals";
import FMV from "./FMV";
import AdditionalUnitsCharge from "./AdditionalUnitsCharge";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";

const Summary = ({ cfaId }) => (
  <ContentBuilder
    loadAction={getCFASummary}
    loadParams={cfaId}
    dataTransform={data => data}
    renderContent={data => (
      <div className="cfa-summary">
        <CFACharge cfaCharge={data.cfaCharge} />
        <Grid columns={2}>
          <GridRow>
            <GridColumn>
              <CFAPayment cfaPayment={data.cfaPayment} />
              <Term term={data.term} />
              <Totals totals={data.totals} />
            </GridColumn>
            <GridColumn>
              <FMV fmv={data.fmv} />
              <AdditionalUnitsCharge
                additionalUnitsCharge={data.additionalUnitsCharge}
              />
            </GridColumn>
          </GridRow>
        </Grid>
      </div>
    )}
  />
);

export default Summary;
