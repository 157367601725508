import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "CUT_SHEET_PAPER";

const ActionTypes = {
  GetCutSheetPaperList: `${NAMESPACE}/GET_LIST`,
};

export const getCutSheetPaper = (
  { filters, pagination },
  { dealerOfficeId }
) => {
  return apiGet(ActionTypes.GetCutSheetPaperList, `paper`, {
    ...pagination,
    ...filters,
    dealerOfficeId,
  });
};
