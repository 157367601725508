import React from "react";
import { matchPath, withRouter } from "react-router-dom";
import { Dropdown, Icon, Segment, Sidebar } from "semantic-ui-react";
import { NavMenu } from "@redriver/cinnamon";
import {
  AppRoutes,
  SystemRoutes,
  AmethystRoute,
  FinanceRoutes,
} from "../../constants/routes";
import { DropdownSubmenuItem } from "components/menus";

const anyPathMatchesCurrent = (currentPath, specifiedPaths) =>
  specifiedPaths.some((x) => !!matchPath(currentPath, x));

/**
 * NOTE: Please keep options here in sync with ./MainNav.
 * This menu is rendered on menu viewports
 */
const MobileMainMenu = ({ animation, direction, visible }) => (
  <Sidebar
    as={Segment}
    animation={animation}
    direction={direction}
    visible={visible}
    className="mobile-main-menu-segment"
  >
    <NavMenu className="mobile-main-menu">
      <Dropdown
        item
        closeOnBlur={false}
        className="cin nav-menu-item nav-menu-submenu"
        text={
          <NavMenu.Item>
            Companies
            <Icon name="angle down" />
          </NavMenu.Item>
        }
      >
        <Dropdown.Menu>
          <DropdownSubmenuItem link={AppRoutes.Customers}>
            Customers
          </DropdownSubmenuItem>
          <DropdownSubmenuItem link={AppRoutes.Dealers}>
            Suppliers
          </DropdownSubmenuItem>
          <DropdownSubmenuItem link={AppRoutes.Funders}>
            Funders
          </DropdownSubmenuItem>
          <DropdownSubmenuItem link={AppRoutes.SPVs}>SPVs</DropdownSubmenuItem>
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown
        item
        closeOnBlur={false}
        className="cin nav-menu-item nav-menu-submenu"
        text={
          <React.Fragment>
            Schedules
            <Icon name="angle down" />
          </React.Fragment>
        }
        defaultOpen={anyPathMatchesCurrent(location.pathname, [
          AppRoutes.Schedules,
        ])}
      >
        <Dropdown.Menu>
          <DropdownSubmenuItem link={AppRoutes.Schedules}>
            Schedules
          </DropdownSubmenuItem>
          <DropdownSubmenuItem link={AppRoutes.PriceIncrease}>
            Price Increase
          </DropdownSubmenuItem>
        </Dropdown.Menu>
      </Dropdown>
      <NavMenu.Item link={AppRoutes.CFA}>CFA</NavMenu.Item>
      <NavMenu.Item link={AppRoutes.Products}>Products</NavMenu.Item>
      <NavMenu.Item link={AppRoutes.Meters}>Meters</NavMenu.Item>
      <Dropdown
        item
        closeOnBlur={false}
        className="cin nav-menu-item nav-menu-submenu"
        text={
          <React.Fragment>
            Billing & Collection
            <Icon name="angle down" />
          </React.Fragment>
        }
        defaultOpen={anyPathMatchesCurrent(location.pathname, [
          AppRoutes.Finance,
        ])}
      >
        <Dropdown.Menu>
          <DropdownSubmenuItem link={FinanceRoutes.Customer}>
            Customer
          </DropdownSubmenuItem>
          <DropdownSubmenuItem link={FinanceRoutes.Supplier}>
            Supplier
          </DropdownSubmenuItem>
          <DropdownSubmenuItem link={FinanceRoutes.Funder}>
            Funder
          </DropdownSubmenuItem>
        </Dropdown.Menu>
      </Dropdown>
      <NavMenu.Item link={AppRoutes.Zircon}>Zircon</NavMenu.Item>
      <NavMenu.Item target="_blank" href={AmethystRoute.Root}>
        Calculator
      </NavMenu.Item>
      <NavMenu.Item link={AppRoutes.Dashboard}>Reports</NavMenu.Item>
      <NavMenu.Item link={AppRoutes.Settings}>Settings</NavMenu.Item>
      <NavMenu.Item link={AppRoutes.DataUploads}>Data Uploads</NavMenu.Item>
      <NavMenu.Item link={SystemRoutes.Account}>My Account</NavMenu.Item>
      <NavMenu.Item link={SystemRoutes.Logout}>Log Out</NavMenu.Item>
    </NavMenu>
  </Sidebar>
);

export default withRouter(MobileMainMenu);
