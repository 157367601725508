import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/RESTRICTIONS/AGREEMENT_STABILITY";

const ActionTypes = {
  GetAgreementStability: `${NAMESPACE}/GET`,
  UpdateAgreementStability: `${NAMESPACE}/UPDATE`,
};

export const getAgreementStability = ({ settingsId }) =>
  apiGet(
    ActionTypes.GetAgreementStability,
    `defaults-restrictions/agreement-stability`,
    {
      settingsId,
    }
  );

export const updateAgreementStability = (formData, { settingsId }) =>
  apiPut(
    ActionTypes.UpdateAgreementStability,
    `defaults-restrictions/agreement-stability/${settingsId}`,
    {
      ...formData,
    }
  );
