import React, { forwardRef } from "react";
import { Button } from "semantic-ui-react";
import { FormBuilder, Form, Loadable } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { Targets, Actions } from "constants/permissions";
import { updateVolumeSelectionRestrictions } from "./actions";
import { getDefaultsRestrictions } from "..";
import AccordionHelper from "components/displays/AccordionHelper";

const VolumeSelectionRestrictions = forwardRef(
  ({ settingsId, toggleVisibility, collapsed }, ref) => {
    return (
      <div ref={ref} className="defaults-grid volume-selection-restrictions">
        <FormBuilder
          initialReadOnly
          loadAction={getDefaultsRestrictions}
          loadParams={{ settingsId }}
          submitAction={updateVolumeSelectionRestrictions}
          submitParams={{ settingsId }}
          renderLoading={false}
          renderSubmitting={false}
          renderError={false}
          loadTransform={(data) => ({
            defaultMaximum: data.volumeSelectionDefaults.maximum,
            defaultValue: data.volumeSelectionDefaults.default,
            defaultMinimum: data.volumeSelectionDefaults.minimum,
            ...data.volumeSelection,
          })}
          renderForm={(formProps, state, events) => {
            return (
              <React.Fragment>
                <AccordionHelper
                  collapsed={collapsed}
                  toggleVisibility={toggleVisibility}
                  title="Volume Selection"
                >
                  <React.Fragment>
                    <div className="defaults-page-title volume-selection-restrictions flex">
                      <div className="flex title-bar"></div>
                      <PermissionCheck
                        target={Targets.DefaultsRestrictions}
                        action={Actions.Edit}
                      >
                        <div className="volume-selection-restriction-edit-buttons">
                          <IconButton
                            primary
                            icon={state.readOnly ? Pen : Save}
                            content={state.readOnly ? "Edit" : "Save"}
                            floated="right"
                            onClick={() =>
                              state.readOnly
                                ? events.onToggleReadOnly()
                                : events.onSubmit({ reset: ["readOnly"] })
                            }
                            className={
                              !state.readOnly ? "positive-title-action" : null
                            }
                            loading={state.loading || state.submitting}
                            disabled={state.loading || state.submitting}
                          />
                          {!state.readOnly && (
                            <Button
                              primary
                              className="cancel-title-action"
                              floated="right"
                              content="Cancel"
                              onClick={events.onReset}
                            />
                          )}
                        </div>
                      </PermissionCheck>
                    </div>

                    <div className="principal-list">
                      <div className="dealer-office-volume-selection">
                        <Loadable loading={state.loading || state.submitting}>
                          <Form {...formProps} className="highlighted-inputs ">
                            {/*state.readOnly ? (
                      DataField(
                        "Available To Supplier",
                        formProps.value.availableToDealer ? "Yes" : "No"
                      )
                    ) : (
                      <Form.Checkbox
                        field="availableToDealer"
                        label="Available To Supplier"
                        readOnly={state.readOnly}
                        renderReadOnly={x => "-"}
                      />
                    )*/}
                            <div className="max-test vol-restriction-field">
                              <Form.Numeric
                                field="maximum"
                                label="V Max"
                                inline
                                className="volume-selection-field"
                                width={10}
                              />
                            </div>
                            <div className="background-blue">
                              <div>
                                <Form.Numeric
                                  field="minimum"
                                  label="V Min"
                                  inline
                                  width={10}
                                  className="volume-selection-field"
                                />
                              </div>
                            </div>
                            <Form.Numeric
                              field="defaultMaximum"
                              label="Max"
                              width={10}
                              inline
                              className="volume-selection-field"
                            />
                            <div className="background-blue">
                              <Form.Numeric
                                field="defaultValue"
                                label="Default Volume Selection Value"
                                width={10}
                                inline
                                className="volume-selection-field"
                              />
                            </div>
                            <div>
                              <Form.Numeric
                                field="defaultMinimum"
                                label="Minimum"
                                width={10}
                                inline
                                className="volume-selection-field"
                              />
                            </div>
                          </Form>
                        </Loadable>
                      </div>
                    </div>
                  </React.Fragment>
                </AccordionHelper>
              </React.Fragment>
            );
          }}
        />
      </div>
    );
  }
);

export default VolumeSelectionRestrictions;
