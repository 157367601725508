import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import FileSaver from "file-saver";
import { UploadEntityTypeDropdown } from "features/../../../shared/components/forms";
import { DealerOfficesTypeAheadLookup } from "features/../../../shared/features/Settings";
import { downloadTemplate } from "./actions";
import { SettingsUploadErrorMessages } from "features/../../../shared/components/errors";

const DownloadTemplate = ({
  id,
  templateType,
  templateName,
  includeButtonText = true,
}) => {
  const [showDealerOffices, setShowDealerOffices] = useState(false);
  const [formDataStore, setFormDataStore] = useState();

  const onFormChange = (field, data, applyChanges, formProps) => {
    const { value, onChange } = formProps;

    if (field == "populate") {
      setShowDealerOffices(data);
    }

    let newFormData = applyChanges(value);

    onChange(field, newFormData, () => applyChanges(newFormData));
    setFormDataStore(newFormData);
  };

  return (
    <ModalBuilder
      withForm
      submitAction={downloadTemplate}
      submitParams={{ id, type: templateType }}
      initialData={{ populate: false }}
      onSubmitted={(result) => {
        result &&
          FileSaver.saveAs(
            result,
            `${formDataStore?.type ?? templateType}.xlsx`
          );
      }}
      renderTrigger={(showModal) => (
        <Button
          primary
          onClick={showModal}
          title="Download Template"
          content={includeButtonText && "Download Template"}
          icon="download"
          className="download-btn"
        />
      )}
      renderModal={({ error, ...modalProps }, formProps) => (
        <Modal.Confirmation
          {...modalProps}
          header="Download Template"
          className="download-template-modal"
        >
          {!id && (
            <Form
              {...formProps}
              onChange={(field, data, applyChanges) =>
                onFormChange(field, data, applyChanges, formProps)
              }
            >
              <UploadEntityTypeDropdown
                required
                field="type"
                placeholder="Select a template"
                label="Template"
              />
              <label>Populate</label>
              <Form.Checkbox field="populate" />
              {showDealerOffices && (
                <DealerOfficesTypeAheadLookup
                  field="dealerOfficeId"
                  fluid
                  clearable
                  label="Office"
                  placeholder="Select office..."
                  required
                />
              )}
            </Form>
          )}

          {id && (
            <p>
              Are you sure you want to download the{" "}
              <strong>{templateName}</strong> template?
            </p>
          )}

          <SettingsUploadErrorMessages error={error} />
        </Modal.Confirmation>
      )}
    />
  );
};

export default DownloadTemplate;
