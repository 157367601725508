import React, { useState } from "react";
import { Form, ModalBuilder, Modal } from "@redriver/cinnamon";
import { addSupplierToFunder } from "./actions";
import { AddButton } from "features/../../../shared/components/buttons";
import { DealersTypeAheadLookup } from "features/../../../shared/features/Dealers/lookups";

const AddAssociatedSupplierModal = ({
  funderId,
  modalClassName,
  onSubmitted,
}) => {
  const [timeStamp, setTimeStamp] = useState(Date.now());
  return (
    <ModalBuilder
      withForm
      submitAction={addSupplierToFunder}
      submitParams={funderId}
      onSubmitted={onSubmitted}
      renderTrigger={(onShowModal) => (
        <AddButton
          primary
          content="Add New Associated Supplier/SPV"
          onClick={() => {
            onShowModal();
            setTimeStamp(Date.now());
          }}
        />
      )}
      renderModal={(modalProps, formProps) => (
        <Modal
          className={modalClassName}
          {...modalProps}
          header="Add New Associated Supplier/SPV"
          cancelLabel="Close"
          submitLabel="Save"
        >
          <Form {...formProps}>
            <div className="step details">
              <DealersTypeAheadLookup
                field="supplierIds"
                fluid
                inline
                clearable
                multiple
                label="Associated Supplier/SPV"
                placeholder="Select supplier/spv..."
                required
                lookupParams={{
                  showSPV: true,
                  funderId: funderId,
                  includeFunderSuppliersOnly: true,
                  timeStamp: timeStamp,
                }}
              />
            </div>
          </Form>
        </Modal>
      )}
    />
  );
};

export default AddAssociatedSupplierModal;
