import React from "react";
import { ModalBuilder } from "@redriver/cinnamon";
import { IconButton } from "features/../../../shared/components/buttons";
import { editTargetMarket } from "./actions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import TargetMarketModalForm from "./TargetMarketModalForm";
import { TranslatedModal } from "../../../../components/modals";

const EditTargetMarket = ({
  as: As = IconButton,
  onSubmitted,
  dealerOfficeId,
  targetMarket,
  ...props
}) => (
  <PermissionCheck target={Targets.LargeFormat} action={Actions.Edit}>
    <ModalBuilder
      withForm
      initialData={targetMarket}
      submitAction={editTargetMarket}
      submitParams={{ id: targetMarket.id, dealerOfficeId }}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <As onClick={showModal} {...props}>
          Edit
        </As>
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Edit
          {...modalProps}
          header="Edit Target Market"
        >
          <TargetMarketModalForm
            formProps={formProps}
            dealerOfficeId={dealerOfficeId}
          />
        </TranslatedModal.Edit>
      )}
    />
  </PermissionCheck>
);

export default EditTargetMarket;
