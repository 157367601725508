import React, { useEffect, useState } from "react";
import {
  Format,
  ListBuilder,
  ActionBar,
  FormBuilder,
  usePreferences,
} from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { ContentPanel } from "components/displays";
import {
  TableConfigurator,
  ConfigurableTable,
} from "features/CustomConfig/TableConfig";
import { getProducts } from "./actions";
import { Link } from "react-router-dom";
import ProductFilters from "./ProductsFilters";
import { ProductRoutes, CustomerRoutes } from "constants/routes";
import { TextSearch } from "features/../../../shared/components/search";

const ProductList = () => {
  const { preferences } = usePreferences();
  const [config, setConfig] = useState(preferences.productListTableConfig);
  const [pageSizeConfig, setPageSizeConfig] = useState(
    preferences.productListTableConfigPageSize
      ? preferences.productListTableConfigPageSize
      : 25
  );

  useEffect(() => {
    setConfig(preferences.productListTableConfig);
  }, [preferences.productListTableConfig]);

  useEffect(() => {
    preferences.productListTableConfigPageSize &&
      setPageSizeConfig(preferences.productListTableConfigPageSize);
  }, [preferences.productListTableConfigPageSize]);

  const columns = [
    {
      field: "systemID",
      title: "Product System ID",
      render: (item) => (
        <Link to={ProductRoutes.Product(item.systemID)}>{item.systemID}</Link>
      ),
    },
    {
      field: "identifierNumber",
      title: "Identifier Number",
      render: (item) => (
        <Link to={ProductRoutes.Product(item.systemID)}>
          {item.identifierNumber}
        </Link>
      ),
    },
    { field: "manufacturerAndModel", title: "Manufacturer & Model" },
    {
      field: "company",
      title: "Company",
      render: (item) => (
        <Link
          to={CustomerRoutes.Details("4830d855-7dd1-4b7d-b1b6-912eb9b57b6f")}
        >
          {item.company}
        </Link>
      ),
    },
    { field: "productLocation", title: "Product Location" },
    { field: "status", title: "Status" },
    {
      field: "installDate",
      title: "Install Date",
      render: (x) => <Format.Date value={x.installDate} format="DD-MMM-YYYY" />,
    },
    { field: "titleHolder", title: "Title Holder" },
    { field: "securityHolder", title: "Security Holder" },
    { field: "funder", title: "Funder" },
    { field: "currentContractRef", title: "Current Contract Ref" },
  ];

  return (
    <React.Fragment>
      <ListBuilder
        withTable
        initialPagination={{ pageSize: pageSizeConfig, pageNumber: 1 }}
        loadAction={getProducts}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        loadParams={{ pageSize: pageSizeConfig }}
        renderList={(tableProps, state, events) => (
          <div>
            <ContentPanel
              title="Products"
              wrapperCssClass="outline-grid products-list"
              headerCssClass="defaults-page-title"
              headerContent={
                <FilterBar
                  state={state}
                  events={events}
                  config={config}
                  columns={columns}
                  initialPageSize={25}
                  pageSizeConfig={pageSizeConfig}
                ></FilterBar>
              }
            >
              <div className="spaced">
                <ConfigurableTable
                  state={state}
                  events={events}
                  tableProps={tableProps}
                  emptyMessage="No products found"
                  columns={columns}
                  config={config}
                />
                <div className="padded">
                  <TablePagination
                    pageSize={pageSizeConfig}
                    pageNumber={state.pagination.pageNumber}
                    onChange={events.onChangePagination}
                    totalItems={state.total}
                  />
                </div>
              </div>
            </ContentPanel>
          </div>
        )}
      />
    </React.Fragment>
  );
};

const FilterBar = ({
  state,
  events,
  config,
  columns,
  initialPageSize,
  pageSizeConfig,
}) => (
  <FormBuilder
    renderForm={(formProps) => (
      <ActionBar className="search-filter-display">
        <ActionBar.Item>
          <TextSearch
            filters={state.filters}
            onChange={events.onChangeFilters}
          ></TextSearch>
        </ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item fluid></ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <TableConfigurator
            config={config}
            columns={columns}
            configId="productListTableConfig"
            titlePreFix="products"
            initialPageSize={initialPageSize}
            pageSizeConfig={pageSizeConfig}
          ></TableConfigurator>
        </ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <ProductFilters
            title="Filters"
            filters={state.filters}
            onChange={events.onChangeFilters}
            formProps={formProps}
            onReset={events.onReset}
          />
        </ActionBar.Item>
      </ActionBar>
    )}
  />
);

export default ProductList;
