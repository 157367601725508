import React from "react";
import { Icon } from "semantic-ui-react";
import { ListBuilder, Table, ActionBar } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import {
  getMeteredServices,
  addMeteredService,
  editMeteredService,
  removeMeteredService,
} from "./actions";
import SearchMeteredServices from "./SearchMeteredServices";
import MeteredServiceActions from "./MeteredServiceActions";
import AddMeteredServiceModal from "./AddMeteredServiceModal";
import { SortHeader } from "../../../../../../../shared/components/tables/sortableTable";

const ListMeteredServices = ({ dealerOfficeId, searchImage }) => (
  <React.Fragment>
    <h4>Metered Services</h4>
    <ListBuilder
      withTable
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      loadAction={dealerOfficeId ? getMeteredServices : null}
      loadParams={{ dealerOfficeId }}
      renderList={(tableProps, state, events) =>
        events && (
          <div className="content-container principal-list services-list">
            <ActionBar>
              <ActionBar.Item fluid>
                <SearchMeteredServices
                  searchImage={searchImage}
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </ActionBar.Item>
              <ActionBar.Item align="right">
                {dealerOfficeId && (
                  <AddMeteredServiceModal
                    dealerOfficeId={dealerOfficeId}
                    submitAction={addMeteredService}
                    onSubmitted={events.onRefresh}
                  />
                )}
              </ActionBar.Item>
            </ActionBar>

            <Table
              {...tableProps}
              compact
              dataKey="id"
              emptyMessage="No metered services found"
              selectable
              className="metered-services-table"
            >
              <Table.Column
                field="name"
                title="Name"
                header={
                  <SortHeader
                    field="name"
                    title="Name"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                field="code"
                title="Item Code"
                header={
                  <SortHeader
                    field="code"
                    title="Item Code"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column field="dependents" title="Dependents" />
              <Table.Column
                field="termsRef"
                title="Terms and Conditions Ref"
                header={
                  <SortHeader
                    field="termsRef"
                    title="Terms and Conditions Ref"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                field="billingCode"
                title="Billing Code"
                header={
                  <SortHeader
                    field="billingCode"
                    title="Billing Code"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                field="sku"
                title="SKU"
                header={
                  <SortHeader
                    field="sku"
                    title="SKU"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                field="equipmentSchedule"
                title="Equipment Schedule"
                render={(x) => (x.equipmentSchedule ? Check : "")}
                align="center"
              />
              <Table.Column
                field="alwaysUnmanaged"
                title="Always Unmanaged"
                render={(x) => (x.alwaysUnmanaged ? Check : "")}
                align="center"
                header={
                  <SortHeader
                    field="alwaysUnmanaged"
                    title="Always Unmanaged"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                field="isLfpDefault"
                title="Is LFP Default"
                render={(x) => (x.isLfpDefault ? Check : "")}
                align="center"
                header={
                  <SortHeader
                    field="isLfpDefault"
                    title="Is LFP Default"
                    value={state.filters}
                    onChange={events.onChangeFilters}
                  />
                }
              />
              <Table.Column
                hoverable
                align="right"
                render={(item) => (
                  <MeteredServiceActions
                    service={item}
                    deleteAction={removeMeteredService}
                    editAction={editMeteredService}
                    onRefresh={events.onRefresh}
                    dealerOfficeId={dealerOfficeId}
                  />
                )}
              />
            </Table>

            <TablePagination
              pageNumber={state.pagination.pageNumber}
              onChange={events.onChangePagination}
              pageSize={state.pagination.pageSize}
              totalItems={state.total}
            />
          </div>
        )
      }
    />
  </React.Fragment>
);

const Check = <Icon name="check" />;

export default ListMeteredServices;
