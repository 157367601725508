import React from "react";
import { Lookup, registerLookup, apiGet, Form } from "@redriver/cinnamon";
import { useTranslation } from "react-i18next";

const NAMESPACE = "LARGEFORMATPRINTER/LOOKUPS";

const largeFormatPrinterWidthsLookup = "largeFormatPrinterWidthsLookup";
const targetMarketLookup = "targetMarketLookup";
const largeFormatInkUsageLookup = "largeFormatInkUsageLookup";

registerLookup(
  targetMarketLookup,
  ({ dealerOfficeId }) =>
    apiGet(`${NAMESPACE}/GET_TARGET_MARKETS`, `lookups/target-market`, {
      dealerOfficeId,
    }),
  {
    transform: data =>
      Array.isArray(data)
        ? data.map(x => {
          return {
            value: x.id,
            text: x.name,
          };
        })
        : [],
    cache: false,
  }
);

export const LargeFormatTargetMarketDropdownLookup = ({
  className,
  dealerOfficeId,
  ...props
}) => {
  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'largeFormat.targetMarket'});
  return(
    <Lookup
      lookup={targetMarketLookup}
      lookupParams={{ dealerOfficeId }}
      render={({ response }) => {
        let options = [];
        options = response && response.map(m => ({
          text: m.text,
          value: m.value,
        }));
        return (
          <div className={className}>
            <Form.Dropdown
              {...props}
              label={t("title")}
              field="targetMarketId"
              placeholder={t("modalFormPlaceholder")}
              options={options}
              required
            />
          </div>
        );
      }}
    />
  )};

registerLookup(
  largeFormatPrinterWidthsLookup,
  ({ dealerOfficeId }) =>
    apiGet(
      `${NAMESPACE}/GET_LARGE_FORMAT_PRINTER_WIDTHS`,
      `lookups/large-format/printer-widths`,
      { dealerOfficeId }
    ),
  {
    transform: data =>
      Array.isArray(data)
        ? data.map(x => {
          return {
            value: x.id,
            text: x.name,
          };
        })
        : [],
    cache: false,
  }
);

export const LargeFormatPrinterWidthsDropdownLookup = ({
  className,
  dealerOfficeId,
  ...props
}) => (
    <Lookup
      lookup={largeFormatPrinterWidthsLookup}
      lookupParams={{ dealerOfficeId }}
      render={({ response }) => {
        let options = [];
        options = response && response.map(m => ({
          text: m.text,
          value: m.value,
        }));
        return (
          <div className={className}>
            <Form.Dropdown
              {...props}
              options={options}
              required
            />
          </div>
        );
      }}
    />
  );

registerLookup(
  largeFormatInkUsageLookup,
  ({ dealerOfficeId }) =>
    apiGet(
      `${NAMESPACE}/GET_LARGE_FORMAT_INK_USAGE`,
      `lookups/large-format/ink-usage`,
      { dealerOfficeId }
    ),
  {
    transform: data =>
      Array.isArray(data)
        ? data.map(x => {
          return {
            value: x.id,
            text: x.name,
          };
        })
        : [],
    cache: false,
  }
);

export const LargeFormatInkUsageDropdownLookup = ({
  className,
  label,
  dealerOfficeId,
  ...props
}) => { 
  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'largeFormat.printer.PrinterUsageTypeTable.inkUsageDrp'});
  return(
    <Lookup
      lookup={largeFormatInkUsageLookup}
      lookupParams={{ dealerOfficeId }}
      render={({ response }) => {
        if (!response) {
          return <Form.Dropdown {...props} field="inkUsageId" options={[]} />;
        }
        let options = [];
        options = response.map(m => ({
          text: m.text,
          value: m.value,
        }));
        return (
          <div className={className}>
            <Form.Dropdown
              {...props}
              label={label}
              field="inkUsageId"
              placeholder={t("placeholder")}
              requiredError={t("requiredError")}
              options={options}
              required
            />
          </div>
        );
      }}
    />
  )};
