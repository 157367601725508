import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "CFA_SUMMARY";

const ActionTypes = {
  GetCFASummary: `${NAMESPACE}/GET_SUMMARY`,
};

export const getCFASummary = (id) => {
  //Set demo data id to the selected CFA id
  //Remove when backend completed
  const response = MockCFASummaryData;
  response.cfaCharge.cfaReference = id;

  return mockSuccess(ActionTypes.GetCFASummary, {
    delay: 200,
    response: response,
  });
};

const MockCFASummaryData = {
  id: 1,
  cfaCharge: {
    cfaReference: "A1A04575",
    funder: "BNP",
    ipsCode: "BC",
    funderType: "Funder",
    associatedCfa: "N/A",
    paymentMethod: "Quarterly",
    paymentProfile: "In Advance",
    rateType: "FMV",
    cfaStatus: "Live",
    cfaStartDate: "22-Mar-2018",
    cfaEndDate: "22-Jun-2021",
    currency: process.env.CURRENCY_CODE,
    paymentDate: "Schedule Due Date Plus 4 Days",
    continuingPayment: "N",
    ipsPropNumber: "",
    initialScheduleRef: "KM1420-MA2441-SC6120",
    currentScheduleRef: "KM1420-MA2441-SC8564",
    notes:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  cfaPayment: {
    initialCfaPayment: 1115.92,
    settled: -403.25,
    adjustment: 0.0,
    currentCfaPayment: 1800.0,
  },
  fmv: {
    converted: 1200.0,
    unconverted: 600.0,
    total: 1800.0,
    convertedSumToCollect: 1204.64,
    fmvSettled: 0.0,
    cfAmount: 1204.64,
    valueDueToFunder: 600.0,
    dueDate: "22-Jun-2021",
    invoicedToSupplier: "Yes",
    amountInvoiced: 300.0,
    paidToFunder: "Yes",
    amountPaid: "300.00",
    unconvertedAmountSettled: 0.0,
    unconvertedAmountCf: 0.0,
  },
  term: {
    initialTerm: 12.0,
    adjustment: 0.0,
    fmvConversionAdditionalPeriods: 1.691,
    funderTerm: 13.691,
    periodsCollected: -8.0,
    reallocation: 0.0,
    periodsSettled: -5.691,
    termOutstanding: 0.0,
  },
  totals: {
    initialTotal: 13391.04,
    adjustment: 0.0,
    fmvConversion: 1204.64,
    funderTotal: 14595.68,
    settlementViaRewrite: -3226.0,
    fmvSettlementViaRewrite: 0.0,
    amountToCollect: 11369.68,
    periodsCollected: -7314.36,
    reallocatedToContinuingPayment: 0.0,
    periodsSettledViaInvoice: -4055.32,
    minimumOutstanding: 0.0,
    additionalUnitsCharge: 0.0,
    totalOutstanding: 0.0,
  },
  additionalUnitsCharge: {
    cfaAmountCollected: 2514.99,
    settledViaRewrite: -908.82,
    settledViaInvoice: -1557.64,
    transferToSeparateCfa: 0.0,
    transferFromSeparateCfa: 0.0,
    reallocatedToContinuingPayment: -48.53,
    adjustment: 0.0,
    availableAmount: 0.0,
    additionalUnitsReceivedByFunder: 1641.98,
    additionalUnitsNotYetReceivedByFunder: 0.0,
    totalAdditionalUnits: 1641.98,
    allocated: 150.0,
    unallocated: 477.09,
    totalAvailable: 627.09,
  },
};
