import React, { useState } from "react";
import {
  ActionBar,
  Form,
  FormBuilder,
  ModalBuilder,
  Wizard,
  Modal,
} from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import { Button, Grid, Icon } from "semantic-ui-react";
import { DealerStructureRole } from "constants/enums";
import { createDealer } from "./actions";
import { sortBy, remove } from "lodash";
import { AddButton } from "features/../../../shared/components/buttons";

const CreateDealer = ({ onAdded }) => {
  let onFormRefresh, submitProps;
  const [structureKeys, setStructureKeys] = useState([]);
  const WizardSteps = [
    { key: "details" },
    { key: "structure" },
    {
      key: "confirmation",
      canSubmit: true, //displays 'Submit' action instead of 'Next'
    },
  ];
  const roleOptions = Object.values(DealerStructureRole)
    .filter((x) => x !== DealerStructureRole.None)
    .map((x) => ({
      value: x,
    }));
  const DetailsForm = () => (
    <div className="step details">
      <Form.Input field="name" label="Supplier Name" required />
      <Form.Input field="website" label="Website" />
      <Form.Input field="telephone" label="Telephone Number" />
      <Form.Trigger
        field="name"
        action={(formState) => {
          let { name, structures } = formState.fields;
          structures.find((x) => x.isRoot).name = name;
        }}
      />
    </div>
  );
  const StructureEditor = ({ readOnly }) => {
    function debounceScrollModalToEnd(debounceMs = 180) {
      setTimeout(() => {
        document.getElementsByClassName("create-dealer").forEach((x) =>
          x.scrollTo({
            top: x.scrollHeight,
            left: 0,
            behavior: "smooth",
          })
        );
      }, debounceMs);
    }
    //indent factor determines how far to indent subsequent rows
    let indentFactor =
      structureKeys.length > 20 ? 0 : structureKeys.length > 15 ? 1 : 1.8;
    const RolePicker = ({ formState }) => {
      let { fields, formFields } = formState,
        { structures } = formFields;
      const RoleOption = ({ checked, disabled, hidden }) => {
        let checkboxClasses = [];
        checked && checkboxClasses.push("checked");
        readOnly && checkboxClasses.push("read-only");
        !readOnly && disabled && checkboxClasses.push("disabled");
        hidden && checkboxClasses.push("hidden");
        return (
          <div
            className={`role-option ${checkboxClasses.join(" ")}`}
            onClick={() => {
              if (disabled || hidden) return;
              structures.forEach((x) =>
                remove(x.roles, (x) => x === DealerStructureRole.Finance)
              );
              fields.roles.push(DealerStructureRole.Finance);
              onFormRefresh && onFormRefresh();
            }}
          >
            <div className={`role-checkbox ${checkboxClasses.join(" ")}`}>
              {checked && <Icon name="check" />}
            </div>
          </div>
        );
      };
      return (
        <React.Fragment>
          {roleOptions.map((x) => {
            let isChecked = fields.roles.includes(x.value),
              isDisabled = x.value !== DealerStructureRole.Finance;
            return (
              <RoleOption
                key={x.value}
                checked={isChecked}
                disabled={isDisabled}
                hidden={isDisabled && !isChecked}
              />
            );
          })}
        </React.Fragment>
      );
    };
    return (
      <div className="step structure">
        <Form.Array
          label={
            <div className="structure-header">
              <label>Company Structure</label>
              <label>Head Office</label>
              <label>Financial</label>
              <label>Settings/Rates</label>
            </div>
          }
          propagateUpdates="always"
          field="structures"
          arrayKey="key"
        >
          {({ fields, formFields, arrayIndex }) => (
            <div className="structure-row">
              <div
                className="structure-name"
                style={{ paddingLeft: `${indentFactor * arrayIndex}%` }} //progressively indent
              >
                <Icon name="dropdown" />
                <Form.Input
                  required
                  placeholder="Structure Name..."
                  field="name"
                  readOnly={readOnly}
                  debounce={200}
                />
              </div>
              <RolePicker formState={{ fields, formFields }} />
              {!readOnly && !fields.isRoot && (
                <Form.ArrayRemover className="inline">Remove</Form.ArrayRemover>
              )}
            </div>
          )}
        </Form.Array>
        {readOnly || (
          <Form.ArrayAdder
            field="structures"
            arrayKey="key"
            className="inline adder"
            style={{ marginLeft: `${indentFactor * structureKeys.length}%` }}
            as={Button}
            populate={(arrayKey) => {
              //find parent by ordering desc and taking first
              let parentKey = sortBy(structureKeys, (x) => x).reverse()[0],
                newKey = parentKey + 1;
              debounceScrollModalToEnd();
              return {
                [arrayKey]: newKey || 1,
                parentKey: parentKey || 0,
                name: undefined,
                roles: [],
              };
            }}
          >
            New Entity
          </Form.ArrayAdder>
        )}
        <Form.Trigger
          field="structures"
          action={(formState) => {
            let { structures } = formState.fields;
            if (!structures) return;

            //if we have added/removed Structures, recalc parents/children
            if (structures.length !== structureKeys.length) {
              let newKeyset = structures.map((x) => x.key);
              setStructureKeys(newKeyset);
              structures.forEach((x) => {
                while (x.parentKey > 0 && !newKeyset.includes(x.parentKey)) {
                  --x.parentKey;
                }
              });
            }

            //assert that top level is always HeadOffice, and bottom is Rates
            let parentRefs = structures.map((x) => x.parentKey),
              topLevel = structures.find((x) => x.isRoot),
              bottomLevel = structures.find((x) => !parentRefs.includes(x.key));
            structures.forEach((x) =>
              remove(x.roles, (x) =>
                [
                  DealerStructureRole.HeadOffice,
                  DealerStructureRole.SettingsAndRates,
                ].includes(x)
              )
            );
            topLevel.roles.push(DealerStructureRole.HeadOffice);
            bottomLevel.roles.push(DealerStructureRole.SettingsAndRates);

            //if we have just removed the Finance option, default root back to Finance
            if (
              !structures.find((x) =>
                x.roles.includes(DealerStructureRole.Finance)
              )
            ) {
              topLevel.roles.push(DealerStructureRole.Finance);
            }
          }}
        />
      </div>
    );
  };
  const ConfirmationView = () => (
    <div className="step confirm">
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>Supplier Name</Grid.Column>
          <Grid.Column>
            <Form.Input field="name" readOnly />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>Website</Grid.Column>
          <Grid.Column>
            <Form.Input
              field="website"
              readOnly
              renderReadOnly={(props) => (
                <p>{props.value || "No website specified"}</p>
              )}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>Telephone Number</Grid.Column>
          <Grid.Column>
            <Form.Input
              field="telephone"
              readOnly
              renderReadOnly={(props) => (
                <p>{props.value || "No telephone specified"}</p>
              )}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <StructureEditor readOnly={true} />
    </div>
  );
  return (
    <PermissionCheck target={Targets.Dealer} action={Actions.Create}>
      <ModalBuilder
        withForm
        submitAction={() => createDealer(submitProps)}
        onSubmitted={onAdded}
        renderTrigger={(show) => (
          <AddButton primary content="Add Supplier" onClick={show} />
        )}
        renderModal={(modalProps) => {
          return (
            <Modal.Edit
              {...modalProps}
              size="large"
              className="custom-actions create-dealer"
              header="Add Supplier"
            >
              <div className="custom-action-content">
                <FormBuilder
                  initialData={{
                    structures: [
                      {
                        key: 0,
                        isRoot: true,
                        roles: roleOptions.map((x) => x.value),
                      },
                    ],
                  }}
                  onSubmit={(formData) => {
                    submitProps = formData;
                    modalProps.onSubmit();
                  }}
                  renderForm={(formProps, state, events) => {
                    //keep ref to refresh event to propagate changes from ArrayAdder
                    onFormRefresh = events.onRefresh;
                    return (
                      <Form {...formProps} disabled={modalProps.submitting}>
                        <Wizard steps={WizardSteps}>
                          <Wizard.Step step="details" component={DetailsForm} />
                          <Wizard.Step
                            step="structure"
                            component={StructureEditor}
                          />
                          <Wizard.Step
                            step="confirmation"
                            component={ConfirmationView}
                          />
                          <Wizard.Nav
                            component={({
                              activeStep,
                              hasNextStep,
                              hasPreviousStep,
                              onGoToNextStep,
                              onGoToPreviousStep,
                            }) => (
                              <div className="custom-action-footer">
                                <ActionBar>
                                  <ActionBar.Item>
                                    <Button
                                      className="cancel"
                                      content="Cancel"
                                      disabled={modalProps.submitting}
                                      onClick={modalProps.onCancel}
                                    />
                                  </ActionBar.Item>
                                  <ActionBar.Space />
                                  {hasPreviousStep && (
                                    <ActionBar.Item fluid align="right">
                                      <Button
                                        className="previous"
                                        content="Back"
                                        disabled={modalProps.submitting}
                                        onClick={onGoToPreviousStep}
                                      />
                                    </ActionBar.Item>
                                  )}
                                  {hasNextStep && (
                                    <ActionBar.Item
                                      fluid={!hasPreviousStep} //prevent random whitespace
                                      align="right"
                                    >
                                      <Button
                                        primary
                                        className="next"
                                        content="Next"
                                        loading={state.submitting}
                                        disabled={state.submitting}
                                        onClick={() => {
                                          let { formValid } = state;
                                          if (formValid) {
                                            onFormRefresh();
                                            onGoToNextStep();
                                            return;
                                          }
                                          events.onSubmit();
                                        }}
                                      />
                                    </ActionBar.Item>
                                  )}
                                  {activeStep.canSubmit && (
                                    <ActionBar.Item>
                                      <Button
                                        className="positive submit"
                                        content="Confirm"
                                        loading={modalProps.submitting}
                                        disabled={modalProps.submitting}
                                        onClick={() => {
                                          modalProps.submitting = true;
                                          events.onSubmit();
                                        }}
                                      />
                                    </ActionBar.Item>
                                  )}
                                </ActionBar>
                              </div>
                            )}
                          />
                        </Wizard>
                      </Form>
                    );
                  }}
                />
              </div>
            </Modal.Edit>
          );
        }}
      />
    </PermissionCheck>
  );
};

export default CreateDealer;
