import React from "react";
import { ContentBuilder, DataList } from "@redriver/cinnamon";
import { ContentPanel } from "components/displays";
import { getProductDetails } from "./actions";

const PhysicalLocationTable = ({ productId }) => {
  return (
    <ContentBuilder
      loadAction={getProductDetails}
      loadParams={productId}
      loadTransform={(data) => data.physicalLocation}
      renderContent={(physicalLocation) => (
        <ContentPanel
          title="Physical Location"
          wrapperCssClass=" "
          headerCssClass="defaults-page-title"
        >
          <div className="spaced">
            <DataList>
              <DataList.Item title="Account ID" width="100%">
                {physicalLocation.accountId}
              </DataList.Item>
              <DataList.Item title="Company" width="100%">
                {physicalLocation.company}
              </DataList.Item>
              <DataList.Item title="Department" width="100%">
                {physicalLocation.department}
              </DataList.Item>
              <DataList.Item title="Address 1" width="100%">
                {physicalLocation.address1}
              </DataList.Item>
              <DataList.Item title="Address 2" width="100%">
                {physicalLocation.address2}
              </DataList.Item>
              <DataList.Item title="Address 3" width="100%">
                {physicalLocation.address3}
              </DataList.Item>
              <DataList.Item title="Town/City" width="100%">
                {physicalLocation.townCity}
              </DataList.Item>
              <DataList.Item title="County" width="100%">
                {physicalLocation.county}
              </DataList.Item>
              <DataList.Item title="Postal Code" width="100%">
                {physicalLocation.postalCode}
              </DataList.Item>
              <DataList.Item title="Country" width="100%">
                {physicalLocation.country}
              </DataList.Item>
              <DataList.Item title="Phone" width="100%">
                {physicalLocation.phone}
              </DataList.Item>
              <DataList.Item title="Email" width="100%">
                {physicalLocation.email}
              </DataList.Item>
            </DataList>
          </div>
        </ContentPanel>
      )}
    />
  );
};

export default PhysicalLocationTable;
