import React, { forwardRef } from "react";
import { FormBuilder, Form } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import AccordionHelper from "components/displays/AccordionHelper";
import PenSvg from "features/../../../shared/assets/pen.svg";
import SaveSvg from "features/../../../shared/assets/save.svg";
import { IconButton } from "features/../../../shared/components/buttons";
import { DocusignRecipientType } from "features/../../../shared/constants/enums";
import {
  getDocusignAdminRecipients,
  setDocusignAdminRecipients,
} from "../../actions";

const ipsTypeDescriptions = {
  [DocusignRecipientType.None]: "-",
  [DocusignRecipientType.SupplierCheck]: "Supplier Check",
  [DocusignRecipientType.SupplierSignatory]: "Supplier Signatory",
  [DocusignRecipientType.IpsCheck]: "IPS Check",
  [DocusignRecipientType.IpsSignatory]: "IPS Signatory",
};

const principalTypeDescriptions = {
  [DocusignRecipientType.None]: "-",
  [DocusignRecipientType.SupplierCheck]: "Principal Check",
  [DocusignRecipientType.SupplierSignatory]: "Principal Signatory",
  [DocusignRecipientType.IpsCheck]: "Principal Funder Pack Check",
  [DocusignRecipientType.IpsSignatory]: "Funder Signatory",
};

const EDocumentSettings = forwardRef(
  ({ dealerOfficeId, toggleVisibility, collapsed }, ref) => {
    const renderReadOnly = (props) => props.value ?? "-";

    return (
      <div ref={ref} className="edocument-settings">
        <AccordionHelper
          collapsed={collapsed}
          toggleVisibility={toggleVisibility}
          title="E-Documents"
        >
          <FormBuilder
            initialReadOnly
            loadAction={getDocusignAdminRecipients}
            loadParams={dealerOfficeId}
            loadTransform={(data) => ({ recipients: data })}
            submitAction={setDocusignAdminRecipients}
            submitParams={{ dealerOfficeId }}
            renderForm={(formProps, state, events) => (
              <Form {...formProps}>
                <div className="header">
                  <div className="actions form-header">
                    {!state.readOnly && (
                      <Button
                        primary
                        className="cancel-title-action"
                        content="Cancel"
                        onClick={events.onReset}
                      />
                    )}
                    <IconButton
                      primary
                      icon={state.readOnly ? PenSvg : SaveSvg}
                      content={state.readOnly ? "Edit" : "Save"}
                      onClick={() =>
                        state.readOnly
                          ? events.onToggleReadOnly()
                          : events.onSubmit({ reset: ["readOnly"] })
                      }
                      className={
                        !state.readOnly ? "positive-title-action" : null
                      }
                    />
                  </div>
                </div>
                <Form.Array
                  field="recipients"
                  arrayKey="id"
                  propagateUpdates="always"
                >
                  {({ arrayIndex, fields }) => (
                    <React.Fragment>
                      {arrayIndex == 0 && <hr />}
                      <Form.Input
                        field="type"
                        label="Type"
                        inline
                        readOnly
                        renderReadOnly={(props) => {
                          return (
                            (process.env.TRADE_AS_IPS
                              ? ipsTypeDescriptions[props?.value]
                              : principalTypeDescriptions[props?.value]) ?? "-"
                          );
                        }}
                        disabled
                      />
                      <Form.Input
                        required
                        field="name"
                        label="Name"
                        inline
                        renderReadOnly={renderReadOnly}
                      />
                      <Form.Email
                        required
                        field="email"
                        label="Email"
                        inline
                        renderReadOnly={renderReadOnly}
                        className="email"
                      />
                      {/* Workaround to get label on the left of checkbox */}
                      <div className="checkbox-container">
                        <label className="checkbox-label">
                          <span className="checkbox-text">
                            Telephone Authorisation required?
                          </span>
                          <Form.Checkbox
                            field="telephoneAuthRequired"
                            disabled={formProps.readOnly}
                          />
                        </label>
                      </div>
                      <Form.Input
                        required={fields.telephoneAuthRequired}
                        field="phoneNumber"
                        label="Phone Number"
                        inline
                        disabled={!fields.telephoneAuthRequired}
                      />
                      <Form.Input
                        required={fields.telephoneAuthRequired}
                        field="phoneCountryCode"
                        label="Phone Country Code"
                        inline
                        renderReadOnly={renderReadOnly}
                        disabled={!fields.telephoneAuthRequired}
                      />
                      <Form.Input
                        required
                        field="position"
                        label="Position"
                        inline
                        renderReadOnly={renderReadOnly}
                      />
                      <Form.Trigger
                        field="telephoneAuthRequired"
                        target="phoneNumber"
                        action="clear"
                      />
                      <Form.Trigger
                        field="telephoneAuthRequired"
                        target="phoneCountryCode"
                        action="clear"
                      />
                      <hr />
                    </React.Fragment>
                  )}
                </Form.Array>
              </Form>
            )}
          />
        </AccordionHelper>
      </div>
    );
  },
);

export default EDocumentSettings;
