import React from "react";
import { Loadable } from "@redriver/cinnamon";
import { merge, values, keyBy } from "lodash";
import { SortableHeader } from "features/../../../shared/components/tables/sortableTable";
import { Table } from "semantic-ui-react";

const DynamicConfigurableTable = ({
  tableProps,
  emptyMessage,
  columns,
  config,
  events,
  state,
}) => {
  //remove expired columns from config
  if (config) {
    config = config.filter((itemA) => {
      return columns.find((itemB) => {
        return itemA.field === itemB.field;
      });
    });
  }

  //sort and filter items
  let merged = merge(keyBy(columns, "field"), keyBy(config, "field"));
  let mergedValues = values(merged);
  mergedValues.sort((a, b) => a.order - b.order);
  let filteredColumns = mergedValues.filter(
    (x) => x.hidden !== null && x.hidden !== true
  );

  //get count for all filtered columns that have a title
  const columnCount = filteredColumns.filter(
    (item) => item.title !== ""
  ).length;

  //if all columns are hidden revert to default.
  if (columnCount === 0) {
    filteredColumns = mergedValues;
  }

  return (
    tableProps && (
      <Loadable loading={tableProps.loading}>
        <Table>
          <Table.Header className="top-bar-large-format">
            <Table.Cell colSpan={17} />
            <Table.Cell colspan={7} className="highlight blue">
              Price A
            </Table.Cell>
            <Table.Cell colspan={1} />
            <Table.Cell colspan={7} className="highlight blue">
              Price B
            </Table.Cell>
            <Table.Cell colspan={4} />
            <Table.Cell colspan={columnCount - 34} className="highlight grey">
              Usage Types
            </Table.Cell>
          </Table.Header>
          <SortableHeader
            events={events}
            state={state}
            columns={filteredColumns}
          />
          <Table.Body>
            {state.loading && (
              <Loadable loading={state.loading || state.submitting}></Loadable>
            )}

            {!state.loading && tableProps.data.length == 0 && (
              <Table.Row>
                <Table.Cell colSpan={filteredColumns.length}>
                  <b>{emptyMessage}</b>
                </Table.Cell>
              </Table.Row>
            )}

            {tableProps.data.map((d) => (
              <Table.Row key={d.id}>
                {filteredColumns.map((c) => {
                  const value = c.dynamic
                    ? d[c.parentField].filter((x) => x.id == c.field)[0][
                        c.valueField
                      ]
                    : d[c.field];
                  return (
                    (c.display == null || c.display) && (
                      <Table.Cell className={c.className}>
                        {c.render ? c.render(value) : value}
                      </Table.Cell>
                    )
                  );
                })}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Loadable>
    )
  );
};

export default DynamicConfigurableTable;
