import React, { useState } from "react";
import { ListBuilder } from "@redriver/cinnamon";
import { Button, Table, Form } from "semantic-ui-react";
import { SortableHeader } from "../../../../../../shared/components/tables/sortableTable";
import { numericFormatter } from "components/displays/DisplayHelper";

const headers = [
  { field: "services", title: "Service" },
  { field: "value", title: `Value (${process.env.MAJOR_CURRENCY})` },
  { field: "skuCode", title: "SKU Code" },
  { field: "billingCode", title: "Billing Code" },
  { field: "type", title: "Type" },
  { field: "percentageOfTotal", title: "% of Total" },
  { field: "priceIncreaseApplicable", title: "Price Increase Applicable" },
  { field: "capitalisedService", title: "Capitalised Service" },
  { field: "notionalCPU", title: "Notional CPU" },
  { title: "Edit", sortable: false },
];

const ScheduleServicesList = ({ loadAction, needsHeader }) => {
  return (
    <div>
      <ListBuilder
        id="key"
        loadAction={loadAction}
        renderList={(tableProps, state, events) => {
          return (
            <React.Fragment>
              {tableProps && tableProps._data && tableProps._data.length > 0 && (
                <Table {...tableProps}>
                  {needsHeader && (
                    <SortableHeader
                      columns={headers}
                      events={events}
                      state={state}
                    />
                  )}
                  <Table.Body>
                    {tableProps._data.map((d, index) => (
                      <React.Fragment key={index}>
                        <TableRow data={d} />
                      </React.Fragment>
                    ))}
                    <Table.Row className="schedule-service-totals">
                      <Table.Cell width={2} className="empty" />
                      <Table.Cell
                        width={2}
                        className="schedule-service-total right-aligned highlight"
                      >
                        {needsHeader
                          ? `${process.env.MAJOR_CURRENCY} 319.84`
                          : `${process.env.MAJOR_CURRENCY} 98.53`}
                      </Table.Cell>
                      <Table.Cell width={2} className="empty"></Table.Cell>
                    </Table.Row>
                    {!needsHeader && (
                      <Table.Row className="empty">
                        <Table.Cell width={2} className="highlight">
                          Quarterly Total
                        </Table.Cell>
                        <Table.Cell width={2} className="highlight">
                          {process.env.MAJOR_CURRENCY} 418.37
                        </Table.Cell>
                        <Table.Cell className="empty" />
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              )}
            </React.Fragment>
          );
        }}
      />
    </div>
  );
};

const TableRow = ({ data }) => {
  const [isLocked, setIsLocked] = useState(true);

  return (
    <Table.Row className="schedule-services-table-body">
      <Table.Cell width={2}>{data.services}</Table.Cell>
      <Table.Cell width={2} className="right-aligned">
        {numericFormatter(data.value)}
      </Table.Cell>
      <Table.Cell width={2}>{data.skuCode}</Table.Cell>
      <Table.Cell width={2}>{data.billingCode}</Table.Cell>
      <Table.Cell width={2}>{data.type}</Table.Cell>
      <Table.Cell width={1}>
        {numericFormatter(data.percentageOfTotal, 0, 1)}%
      </Table.Cell>
      <Table.Cell width={2}>
        <Form.Checkbox
          field="priceIncreaseApplicable"
          toggle
          disabled={isLocked}
        />
      </Table.Cell>
      <Table.Cell width={1}>
        <Form.Checkbox field="capitalisedService" toggle disabled={isLocked} />
      </Table.Cell>
      <Table.Cell width={1}>
        {numericFormatter(data.notionalCPU, 0, 3)}
      </Table.Cell>
      <Table.Cell width={1}>
        {isLocked ? (
          <Button
            className="locked-button"
            icon="lock"
            onClick={() => {
              setIsLocked(false);
            }}
          />
        ) : (
          <Button
            className="unlocked-button"
            icon="save"
            onClick={() => {
              setIsLocked(true);
            }}
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default ScheduleServicesList;
