import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { ContentPanel } from "components/displays";
import { BreadcrumbBar } from "pages/nav";

const Meters = () => {

return(

    <PageContent fluid className="meters-container">
        <BreadcrumbBar />
        <ContentPanel title="Meters"   wrapperCssClass="outline-grid" headerCssClass="defaults-page-title">
            <div className="padded">
                Content to go here
            </div>
        </ContentPanel>
    </PageContent>
)};

export default Meters;