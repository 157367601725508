import React from "react";
import ScheduleSettingsGrid from "features/../../../shared/features/Settings/Schedule";
import LargePaperFormatSettingsMain from "./ServiceSettings/LargePaperFormatSettings";
import RatesActions from "features/../../../shared/features/Settings/RatesActions/RatesActions";

const TemporarySettings = ({ settingsId, dealerOfficeId }) => {
  return (
    <React.Fragment>
      <div className="extra-settings-panel-top">
        <div className="extra-settings-panel-columns paper">
          <div className="content-panel">
            <div className="office-general-settings">
              <ScheduleSettingsGrid settingsId={settingsId} />
            </div>
          </div>
        </div>
      </div>

      <div className="extra-settings-panel-top">
        <div className="extra-settings-panel-columns paper">
          <div className="content-panel">
            <div className="office-general-settings">
              <LargePaperFormatSettingsMain
                settingsId={settingsId}
                dealerOfficeId={dealerOfficeId}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="extra-settings-panel-top">
        <div className="extra-settings-panel-columns paper">
          <div className="content-panel">
            <div className="office-general-settings">
              <RatesActions
                headerText={"Rates for"}
                dealerOfficeId={dealerOfficeId}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TemporarySettings;
