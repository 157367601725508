import { mockSuccess, MockArrayData } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULE/PRICE_INCREASES";

const ActionTypes = {
  GetPriceIncreaseBody: `${NAMESPACE}/GET_PRICE_INCREASE_BODY`,
  GetPriceIncreases: `${NAMESPACE}/GET_PRICE_INCREASES`,
  GetCurrentCharges: `${NAMESPACE}/GET_CURRENT_CHARGES`,
  GetFutureCharges: `${NAMESPACE}/GET_FUTURE_CHARGES`,
  GetQuarterlyServices: `${NAMESPACE}/GET_QUARTERLY_SERVICES`,
  GetCutSheetPaper: `${NAMESPACE}/GET_CUT_SHEET_PAPER`,
  GetHistoricalTotals: `${NAMESPACE}/GET_HISTORICAL_TOTALS`,
  GetHistoricalMeterServices: `${NAMESPACE}/GET_HISTORICAL_METER_SERVICES`,
  GetHistoricalQuarterlyServices: `${NAMESPACE}/GET_HISTORICAL_QUARTERLY_SERVICES`,
  GetHistoricalCutSheetPaperInclusive: `${NAMESPACE}/GET_HISTORICAL_CUT_PAPER_INCLUSIVE`,
};

const mockPriceIncreaseBodyData = {
  nextPriceIncrease: {
    option: 1,
    readOnlyOptionText: "Specific Meters",
    lastPriceIncrease: "12-28-2019",
    nextPriceIncrease: "12-28-2022",
    applyPercentage: 3.15,
    serviceIncrease: "",
    totalPaymentIncrease: 701.57,
    totalPercentageIncrease: 5.0,
    adminFeeRequired: true,
    adminFeePercentageIncrease: 10.0,
    adminFeeIncrease: 2.21,
    serviceIncreaseAllocationSupplierValue: 578.48,
    serviceIncreaseAllocationSupplierPercentage: 5.0,
    serviceIncreaseAllocationIPSValue: 17.94,
    serviceIncreaseAllocationIPSPercentage: 0.16,
    serviceIncreaseAllocationTotalValue: 596.42,
    serviceIncreaseAllocationTotalPercentage: 5.16,
  },
  settings: {
    methodology: "Standard (per T&C)/RPI/Fixed % -(any others?)",
    default: 5,
    max: 9,
    neverApplyIncrease: false,
    customerNotificationMethod: "Letter / or on Invoice",
    notificationMethod: 1,
    notificationMethodDescription: "Email",
    lastPriceIncreaseDate: "12-28-2019",
  },
};

const mockFutureCharges = new MockArrayData([
  {
    key: 1,
    unit: "B1",
    managed: true,
    volume: 45240,
    cpi: 0.025,
    financeCPI: 2.708,
    serviceCPI: 1.688,
    cpu: 4.421,
    cpiTotal: 11.31,
    financeTotal: 1225.1,
    serviceTotal: 763.65,
    value: 2000.06,
    changeTotal: 62.88,
    changeTotalPercentage: 3.14,
    changeCPI: 0.061,
    changeCPIPercentage: 244.0,
  },
  {
    key: 2,
    unit: "C1",
    managed: false,
    volume: 14323,
    cpi: 4.353,
    financeCPI: 0,
    serviceCPI: 1.688,
    cpu: 6.041,
    cpiTotal: 623.48,
    financeTotal: 0,
    serviceTotal: 241.77,
    value: 865.25,
    changeTotal: 27.22,
    changeTotalPercentage: 3.15,
    changeCPI: 0.112,
    changeCPIPercentage: 2.57,
  },
  {
    key: 3,
    unit: "B2",
    managed: true,
    volume: 85450,
    cpi: 0.429,
    financeCPI: 2.708,
    serviceCPI: 1.688,
    cpu: 4.825,
    cpiTotal: 366.58,
    financeTotal: 2313.99,
    serviceTotal: 1442.4,
    value: 4122.96,
    changeTotal: 129.89,
    changeTotalPercentage: 3.15,
    changeCPI: 0.074,
    changeCPIPercentage: 17.25,
  },
  {
    key: 4,
    unit: "S1",
    managed: false,
    volume: 24802,
    cpi: 0.257,
    financeCPI: 0,
    serviceCPI: 1.688,
    cpu: 1.945,
    cpiTotal: 63.74,
    financeTotal: 0,
    serviceTotal: 418.66,
    value: 482.4,
    changeTotal: 15.13,
    changeTotalPercentage: 3.14,
    changeCPI: -0.017,
    changeCPIPercentage: 6.61,
  },
  {
    key: 5,
    unit: "C2",
    managed: true,
    volume: 76134,
    cpi: 4.469,
    financeCPI: 2.708,
    serviceCPI: 1.688,
    cpu: 8.865,
    cpiTotal: 3402.43,
    financeTotal: 2061.71,
    serviceTotal: 1285.14,
    value: 6749.28,
    changeTotal: 212.41,
    changeTotalPercentage: 3.15,
    changeCPI: 0.201,
    changeCPIPercentage: 4.5,
  },
  {
    key: 6,
    unit: "B3",
    managed: true,
    volume: 47972,
    cpi: 0.834,
    financeCPI: 2.708,
    serviceCPI: 1.688,
    cpu: 5.23,
    cpiTotal: 400.09,
    financeTotal: 1299.08,
    serviceTotal: 809.77,
    value: 2508.94,
    changeTotal: 79.15,
    changeTotalPercentage: 3.15,
    changeCPI: 0.087,
    changeCPIPercentage: 10.43,
  },
  {
    key: 7,
    unit: "C3",
    managed: true,
    volume: 50026,
    cpi: 4.425,
    financeCPI: 2.708,
    serviceCPI: 1.688,
    cpu: 8.821,
    cpiTotal: 2213.65,
    financeTotal: 1354.7,
    serviceTotal: 844.44,
    value: 4412.79,
    changeTotal: 139.08,
    changeTotalPercentage: 3.15,
    changeCPI: 0.2,
    changeCPIPercentage: 4.52,
  },
  {
    key: 8,
    unit: "B4",
    managed: false,
    volume: 66305,
    cpi: 0.025,
    financeCPI: 0,
    serviceCPI: 1.688,
    cpu: 1.713,
    cpiTotal: 16.58,
    financeTotal: 0,
    serviceTotal: 1119.23,
    value: 1135.8,
    changeTotal: 35.81,
    changeTotalPercentage: 3.15,
    changeCPI: -0.024,
    changeCPIPercentage: 96.0,
  },
  {
    key: 9,
    unit: "",
    managed: "e",
    volume: "",
    cpi: "",
    financeCPI: "",
    serviceCPI: "",
    cpu: "",
    cpiTotal: 7097.86,
    financeTotal: 8254.58,
    serviceTotal: 6925.06,
    value: 22277.48,
    changeTotal: 701.57,
    changeTotalPercentage: 3.15,
    changeCPI: "",
    changeCPIPercentage: "",
  },
]);

const mockCurrentCharges = new MockArrayData([
  {
    key: 1,
    unit: "B1",
    managed: true,
    volume: 45240,
    cpi: 0.025,
    financeCPI: 2.708,
    serviceCPI: 1.688,
    cpu: 4.421,
    cpiTotal: 11.31,
    financeTotal: 1225.1,
    serviceTotal: 763.65,
    value: 2000.06,
  },
  {
    key: 2,
    unit: "C1",
    managed: false,
    volume: 14323,
    cpi: 4.353,
    financeCPI: 0,
    serviceCPI: 1.688,
    cpu: 6.041,
    cpiTotal: 623.48,
    financeTotal: 0,
    serviceTotal: 241.77,
    value: 865.25,
  },
  {
    key: 3,
    unit: "B2",
    managed: true,
    volume: 85450,
    cpi: 0.429,
    financeCPI: 2.708,
    serviceCPI: 1.688,
    cpu: 4.825,
    cpiTotal: 366.58,
    financeTotal: 2313.99,
    serviceTotal: 1442.4,
    value: 4122.96,
  },
  {
    key: 4,
    unit: "S1",
    managed: false,
    volume: 24802,
    cpi: 0.257,
    financeCPI: 0,
    serviceCPI: 1.688,
    cpu: 1.945,
    cpiTotal: 63.74,
    financeTotal: 0,
    serviceTotal: 418.66,
    value: 482.4,
  },
  {
    key: 5,
    unit: "C2",
    managed: true,
    volume: 76134,
    cpi: 4.469,
    financeCPI: 2.708,
    serviceCPI: 1.688,
    cpu: 8.865,
    cpiTotal: 3402.43,
    financeTotal: 2061.71,
    serviceTotal: 1285.14,
    value: 6749.28,
  },
  {
    key: 6,
    unit: "B3",
    managed: true,
    volume: 47972,
    cpi: 0.834,
    financeCPI: 2.708,
    serviceCPI: 1.688,
    cpu: 5.23,
    cpiTotal: 400.09,
    financeTotal: 1299.08,
    serviceTotal: 809.77,
    value: 2508.94,
  },
  {
    key: 7,
    unit: "C3",
    managed: true,
    volume: 50026,
    cpi: 4.425,
    financeCPI: 2.708,
    serviceCPI: 1.688,
    cpu: 8.821,
    cpiTotal: 2213.65,
    financeTotal: 1354.7,
    serviceTotal: 844.44,
    value: 4412.79,
  },
  {
    key: 8,
    unit: "B4",
    managed: false,
    volume: 66305,
    cpi: 0.025,
    financeCPI: 0,
    serviceCPI: 1.688,
    cpu: 1.713,
    cpiTotal: 16.58,
    financeTotal: 0,
    serviceTotal: 1119.23,
    value: 1135.8,
  },
  {
    key: 9,
    unit: "",
    managed: "e",
    volume: "",
    cpi: "",
    financeCPI: "",
    serviceCPI: "",
    cpu: "",
    cpiTotal: 7097.86,
    financeTotal: 8254.58,
    serviceTotal: 6925.06,
    value: 22277.48,
  },
]);

export const mockQuarterlyServices = {
  services: [
    {
      key: 1,
      service: "Excel",
      priceIncrease: true,
      currentValue: 290.69,
      futureValue: 299.85,
      customValue: null,
      changePercentage: 3.15,
    },
    {
      key: 2,
      service: "Papercut Support",
      priceIncrease: true,
      currentValue: 1104.87,
      futureValue: 1139.67,
      customValue: null,
      changePercentage: 3.15,
    },
    {
      key: 3,
      service: "SCR",
      priceIncrease: true,
      currentValue: 766.03,
      futureValue: 790.16,
      customValue: null,
      changePercentage: 3.15,
    },
    {
      key: 4,
      service: "Service Reserve",
      priceIncrease: false,
      currentValue: 731.34,
      futureValue: 731.34,
      customValue: null,
      changePercentage: 0,
    },
    {
      key: 5,
      service: "Device",
      priceIncrease: false,
      currentValue: 3.0,
      futureValue: 3.0,
      customValue: null,
      changePercentage: 0,
    },
    {
      key: 6,
      service: "Cut Sheet Paper Inclusive",
      priceIncrease: true,
      currentValue: 4026.0,
      futureValue: 4158.0,
      customValue: null,
      changePercentage: 3.28,
    },
    {
      key: 7,
      service: "IPS Admin Fee",
      priceIncrease: true,
      currentValue: 0,
      futureValue: 2.21,
      customValue: null,
      changePercentage: null,
    },
    {
      key: 8,
      service: "",
      priceIncrease: "",
      currentValue: 6921.93,
      futureValue: 7124.23,
      customValue: null,
      changePercentage: 2.92,
    },
  ],
  serviceCpi: {
    currentValue: 1.688,
    futureValue: 1.766,
    changePercentage: 4.62,
  },
  maxServiceTotal: 302.07,
};

export const mockCutSheetPaper = {
  services: [
    {
      key: 1,
      name: "Cost (£)",
      currentValue: 4.07,
      futureValue: 4.2,
      customValue: null,
      changePercentage: null,
    },
    {
      key: 2,
      markup: true,
      name: "Markup (%)",
      currentValue: 20,
      futureValue: 20,
      customValue: null,
      changePercentage: null,
    },
    {
      key: 3,
      name: "Sales Price (£)",
      currentValue: 4.88,
      futureValue: 5.04,
      customValue: null,
      changePercentage: null,
    },
    {
      key: 4,
      name: "Total no. of boxes",
      currentValue: 825.0,
      futureValue: 825.0,
      customValue: null,
      changePercentage: null,
    },
    {
      key: 5,
      name: "",
      currentValue: 6921.93,
      futureValue: 7124.23,
      customValue: null,
      changePercentage: 2.92,
    },
  ],
  customCodeIncreaseMax: 0.25,
};

export const mockHistoricalTotals = {
  id: 1,
  date1: "12-28-2017",
  date2: "12-28-2018",
  date3: "12-28-2019",
  date4: "12-28-2020",
  totals: [
    {
      key: 1,
      unit: "B1",
      managed: true,
      volume: 45240,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 4.272,
          value: 1932.65,
          change: 0,
        },
        {
          key: 2,
          cpu: 4.422,
          value: 2000.51,
          change: 3.5,
        },
        {
          key: 3,
          cpu: 4.599,
          value: 2080.59,
          change: 4,
        },
        {
          key: 4,
          cpu: 4.829,
          value: 2184.64,
          change: 5,
        },
      ]),
    },
    {
      key: 2,
      unit: "C1",
      managed: false,
      volume: 14323,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 5.778,
          value: 827.58,
          change: 0,
        },
        {
          key: 2,
          cpu: 5.98,
          value: 856.52,
          change: 3.5,
        },
        {
          key: 3,
          cpu: 6.219,
          value: 890.75,
          change: 4,
        },
        {
          key: 4,
          cpu: 6.53,
          value: 935.29,
          change: 5,
        },
      ]),
    },
    {
      key: 3,
      unit: "B2",
      managed: true,
      volume: 85450,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 4.648,
          value: 3971.72,
          change: 0,
        },
        {
          key: 2,
          cpu: 4.811,
          value: 4111.0,
          change: 3.5,
        },
        {
          key: 3,
          cpu: 5.003,
          value: 4275.06,
          change: 4,
        },
        {
          key: 4,
          cpu: 5.253,
          value: 4488.69,
          change: 5,
        },
      ]),
    },
    {
      key: 4,
      unit: "S1",
      managed: false,
      volume: 24802,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 1.972,
          value: 489.11,
          change: 0,
        },
        {
          key: 2,
          cpu: 2.041,
          value: 506.21,
          change: 3.5,
        },
        {
          key: 3,
          cpu: 2.123,
          value: 526.55,
          change: 4,
        },
        {
          key: 4,
          cpu: 2.229,
          value: 552.84,
          change: 5,
        },
      ]),
    },
    {
      key: 5,
      unit: "C2",
      managed: true,
      volume: 76134,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 8.401,
          value: 6396.02,
          change: 0,
        },
        {
          key: 2,
          cpu: 8.695,
          value: 6619.85,
          change: 3.5,
        },
        {
          key: 3,
          cpu: 9.043,
          value: 6884.8,
          change: 4,
        },
        {
          key: 4,
          cpu: 9.495,
          value: 7228.92,
          change: 5,
        },
      ]),
    },
    {
      key: 6,
      unit: "B3",
      managed: true,
      volume: 47972,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 5.024,
          value: 2410.11,
          change: 0,
        },
        {
          key: 2,
          cpu: 5.2,
          value: 2494.54,
          change: 3.5,
        },
        {
          key: 3,
          cpu: 5.408,
          value: 2594.33,
          change: 4,
        },
        {
          key: 4,
          cpu: 5.678,
          value: 2723.85,
          change: 5,
        },
      ]),
    },
    {
      key: 7,
      unit: "C3",
      managed: true,
      volume: 50026,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 8.36,
          value: 4182.17,
          change: 0,
        },
        {
          key: 2,
          cpu: 8.653,
          value: 4328.75,
          change: 3.5,
        },
        {
          key: 3,
          cpu: 8.999,
          value: 4501.84,
          change: 4,
        },
        {
          key: 4,
          cpu: 9.449,
          value: 4726.96,
          change: 5,
        },
      ]),
    },
    {
      key: 8,
      unit: "B4",
      managed: false,
      volume: 66305,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 1.757,
          value: 1164.98,
          change: 0,
        },
        {
          key: 2,
          cpu: 1.818,
          value: 1205.42,
          change: 3.5,
        },
        {
          key: 3,
          cpu: 1.891,
          value: 1253.83,
          change: 4,
        },
        {
          key: 4,
          cpu: 1.986,
          value: 1316.82,
          change: 5,
        },
      ]),
    },
    {
      key: 9,
      unit: null,
      managed: null,
      volume: null,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: null,
          value: 21374.33,
          change: null,
        },
        {
          key: 2,
          cpu: null,
          value: 22122.8,
          change: 3.5,
        },
        {
          key: 3,
          cpu: null,
          value: 23007.75,
          change: 4,
        },
        {
          key: 4,
          cpu: null,
          value: 24158.01,
          change: 5,
        },
      ]),
    },
  ],
};

export const mockHistoricalMeterServices = {
  id: 1,
  date1: "12-28-2017",
  date2: "12-28-2018",
  date3: "12-28-2019",
  date4: "12-28-2020",
  totals: [
    {
      key: 1,
      unit: "B1",
      managed: true,
      volume: 45240,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 0.423,
          value: 191.37,
          change: 0,
        },
        {
          key: 2,
          cpu: 0.512,
          value: 231.63,
          change: 21,
        },
        {
          key: 3,
          cpu: 0.741,
          value: 335.23,
          change: 44.7,
        },
        {
          key: 4,
          cpu: 0.845,
          value: 382.28,
          change: 14,
        },
      ]),
    },
    {
      key: 2,
      unit: "C1",
      managed: false,
      volume: 14323,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 3.412,
          value: 488.7,
          change: 0,
        },
        {
          key: 2,
          cpu: 3.745,
          value: 536.4,
          change: 9.8,
        },
        {
          key: 3,
          cpu: 4.461,
          value: 638.95,
          change: 19.1,
        },
        {
          key: 4,
          cpu: 4.847,
          value: 694.24,
          change: 8.7,
        },
      ]),
    },
    {
      key: 3,
      unit: "B2",
      managed: true,
      volume: 85450,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 0.641,
          value: 547.73,
          change: 0,
        },
        {
          key: 2,
          cpu: 0.832,
          value: 710.94,
          change: 29.8,
        },
        {
          key: 3,
          cpu: 1.145,
          value: 978.4,
          change: 37.6,
        },
        {
          key: 4,
          cpu: 1.231,
          value: 1051.89,
          change: 7.5,
        },
      ]),
    },
    {
      key: 4,
      unit: "S1",
      managed: false,
      volume: 24802,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 0.15,
          value: 37.2,
          change: 0,
        },
        {
          key: 2,
          cpu: 0.21,
          value: 52.08,
          change: 40,
        },
        {
          key: 3,
          cpu: 0.365,
          value: 90.53,
          change: 73.8,
        },
        {
          key: 4,
          cpu: 0.417,
          value: 103.42,
          change: 14.2,
        },
      ]),
    },
    {
      key: 5,
      unit: "C2",
      managed: true,
      volume: 76134,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 3.174,
          value: 2416.49,
          change: 0,
        },
        {
          key: 2,
          cpu: 3.897,
          value: 2966.94,
          change: 22.8,
        },
        {
          key: 3,
          cpu: 5.185,
          value: 3947.55,
          change: 33.1,
        },
        {
          key: 4,
          cpu: 5.611,
          value: 4271.88,
          change: 8.2,
        },
      ]),
    },
    {
      key: 6,
      unit: "B3",
      managed: true,
      volume: 47972,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 1.07,
          value: 513.3,
          change: 0,
        },
        {
          key: 2,
          cpu: 1.211,
          value: 580.94,
          change: 13.2,
        },
        {
          key: 3,
          cpu: 1.55,
          value: 743.57,
          change: 28,
        },
        {
          key: 4,
          cpu: 1.17,
          value: 820.32,
          change: 10.3,
        },
      ]),
    },
    {
      key: 7,
      unit: "C3",
      managed: true,
      volume: 50026,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 3.975,
          value: 1988.53,
          change: 0,
        },
        {
          key: 2,
          cpu: 4.347,
          value: 2174.63,
          change: 9.4,
        },
        {
          key: 3,
          cpu: 5.141,
          value: 2571.84,
          change: 18.3,
        },
        {
          key: 4,
          cpu: 5.488,
          value: 2745.43,
          change: 6.7,
        },
      ]),
    },
    {
      key: 8,
      unit: "B4",
      managed: false,
      volume: 66305,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: 0.088,
          value: 58.35,
          change: 0,
        },
        {
          key: 2,
          cpu: 0.103,
          value: 68.29,
          change: 17,
        },
        {
          key: 3,
          cpu: 0.133,
          value: 88.19,
          change: 29.1,
        },
        {
          key: 4,
          cpu: 0.16,
          value: 106.09,
          change: 20.3,
        },
      ]),
    },
    {
      key: 9,
      unit: null,
      managed: null,
      volume: null,
      charge: new MockArrayData([
        {
          key: 1,
          cpu: null,
          value: 6241.67,
          change: 0,
        },
        {
          key: 2,
          cpu: null,
          value: 7321.85,
          change: 0,
        },
        {
          key: 3,
          cpu: null,
          value: 9394.26,
          change: 0,
        },
        {
          key: 4,
          cpu: null,
          value: 10175.55,
          change: 0,
        },
      ]),
    },
  ],
};

export const mockHistoricalQuarterlyServices = {
  id: 1,
  date1: "12-28-2017",
  date2: "12-28-2018",
  date3: "12-28-2019",
  date4: "12-28-2020",
  services: [
    {
      key: 1,
      service: "Excel",
      priceIncrease: true,
      charge: [
        {
          key: 1,
          value: 66,
          customValue: 0,
          change: 0,
        },
        {
          key: 2,
          value: 66,
          customValue: 0,
          change: 0,
        },
        {
          key: 3,
          value: 66,
          customValue: 0,
          change: 0,
        },
        {
          key: 4,
          value: 69.3,
          customValue: 0,
          change: 5,
        },
      ],
    },
    {
      key: 2,
      service: "Papercut Support",
      priceIncrease: true,
      charge: [
        {
          key: 1,
          value: 65.17,
          customValue: 0,
          change: 0,
        },
        {
          key: 2,
          value: 65.17,
          customValue: 0,
          change: 0,
        },
        {
          key: 3,
          value: 65.17,
          customValue: 0,
          change: 0,
        },
        {
          key: 4,
          value: 68.43,
          customValue: 0,
          change: 5,
        },
      ],
    },
    {
      key: 3,
      service: "SCR",
      priceIncrease: true,
      charge: [
        {
          key: 1,
          value: 100,
          customValue: 0,
          change: 0,
        },
        {
          key: 2,
          value: 100,
          customValue: 0,
          change: 0,
        },
        {
          key: 3,
          value: 100,
          customValue: 0,
          change: 0,
        },
        {
          key: 4,
          value: 105,
          customValue: 0,
          change: 5,
        },
      ],
    },
    {
      key: 4,
      service: "Service Reference",
      priceIncrease: false,
      charge: [
        {
          key: 1,
          value: 23.75,
          customValue: 0,
          change: 0,
        },
        {
          key: 2,
          value: 23.75,
          customValue: 0,
          change: 0,
        },
        {
          key: 3,
          value: 23.75,
          customValue: 0,
          change: 0,
        },
        {
          key: 4,
          value: 24.94,
          customValue: 0,
          change: 5,
        },
      ],
    },
    {
      key: 5,
      service: "Device",
      priceIncrease: false,
      charge: [
        {
          key: 1,
          value: 3,
          customValue: 0,
          change: 0,
        },
        {
          key: 2,
          value: 3,
          customValue: 0,
          change: 0,
        },
        {
          key: 3,
          value: 3,
          customValue: 0,
          change: 0,
        },
        {
          key: 4,
          value: 3.15,
          customValue: 0,
          change: 5,
        },
      ],
    },
    {
      key: 6,
      service: "Admin Fee",
      priceIncrease: true,
      charge: [
        {
          key: 1,
          value: 0,
          customValue: 0,
          change: 0,
        },
        {
          key: 2,
          value: 5.07,
          customValue: 5.07,
          change: "-",
        },
        {
          key: 3,
          value: 5.07,
          customValue: 0,
          change: 0,
        },
        {
          key: 4,
          value: 5.32,
          customValue: 0,
          change: 4.9,
        },
      ],
    },
    {
      key: 7,
      service: "Cut Sheet Paper Inclusive",
      priceIncrease: true,
      charge: [
        {
          key: 1,
          value: 4026,
          customValue: 0,
          change: 0,
        },
        {
          key: 2,
          value: 4323,
          customValue: 297,
          change: 7.4,
        },
        {
          key: 3,
          value: 4323,
          customValue: 0,
          change: 0,
        },
        {
          key: 4,
          value: 4539.15,
          customValue: 0,
          change: 5,
        },
      ],
    },
    {
      key: 8,
      service: "IPS Admin Fee",
      priceIncrease: true,
      charge: [
        {
          key: 1,
          value: 0,
          customValue: 0,
          change: 0,
        },
        {
          key: 2,
          value: 3.68,
          customValue: 0,
          change: 0,
        },
        {
          key: 3,
          value: 3.68,
          customValue: 0,
          change: 0,
        },
        {
          key: 4,
          value: 3.86,
          customValue: 0,
          change: 4.9,
        },
      ],
    },
    {
      key: 9,
      service: "IPS Admin Fee",
      priceIncrease: null,
      charge: [
        {
          key: 1,
          value: 4283.92,
          customValue: 0,
          change: 0,
        },
        {
          key: 2,
          value: 4589.67,
          customValue: 302.07,
          change: 7.1,
        },
        {
          key: 3,
          value: 4589.67,
          customValue: 0,
          change: 0,
        },
        {
          key: 4,
          value: 4819.15,
          customValue: null,
          change: 5,
        },
      ],
    },
  ],
  cpi: {
    id: 1,
    charge: [
      {
        key: 1,
        value: 1.698,
        customValue: 0,
        change: 0,
      },
      {
        key: 2,
        value: 1.758,
        customValue: 0,
        change: 3.5,
      },
      {
        key: 3,
        value: 1.758,
        customValue: 0,
        change: 0,
      },
      {
        key: 4,
        value: 1.841,
        customValue: 0,
        change: 4.7,
      },
    ],
  },
};

export const mockHistoricalCutSheetPaperInclusive = {
  id: 1,
  date1: "12-28-2017",
  date2: "12-28-2018",
  date3: "12-28-2019",
  date4: "12-28-2020",
  totals: [
    {
      key: 1,
      service: "Cost (£)",
      charge: [
        {
          key: 1,
          value: 4.07,
          customValue: 0,
          change: 0,
        },
        {
          key: 2,
          value: 4.37,
          customValue: 0.3,
          change: 0,
        },
        {
          key: 3,
          value: 4.54,
          customValue: 0,
          change: 0,
        },
        {
          key: 4,
          value: 4.72,
          customValue: 0,
          change: 0,
        },
      ],
    },
    {
      key: 2,
      service: "Markup (%)",
      priceIncrease: true,
      noDp: true,
      charge: [
        {
          key: 1,
          value: 20,
          customValue: 0,
          change: 0,
        },
        {
          key: 2,
          value: 20,
          customValue: 0,
          change: 0,
        },
        {
          key: 3,
          value: 20,
          customValue: 0,
          change: 0,
        },
        {
          key: 4,
          value: 20,
          customValue: 0,
          change: 0,
        },
      ],
    },
    {
      key: 3,
      service: "Sales Price (£)",
      priceIncrease: true,
      charge: [
        {
          key: 1,
          value: 4.88,
          customValue: 0,
          change: 0,
        },
        {
          key: 2,
          value: 5.24,
          customValue: 0,
          change: 0,
        },
        {
          key: 3,
          value: 5.45,
          customValue: 0,
          change: 0,
        },
        {
          key: 4,
          value: 5.66,
          customValue: 0,
          change: 0,
        },
      ],
    },
    {
      key: 4,
      service: "Total no. of boxes",
      priceIncrease: true,
      charge: [
        {
          key: 1,
          value: 825,
          customValue: 0,
          change: 0,
        },
        {
          key: 2,
          value: 825,
          customValue: 0,
          change: 0,
        },
        {
          key: 3,
          value: 825,
          customValue: 0,
          change: 0,
        },
        {
          key: 4,
          value: 825,
          customValue: 0,
          change: 0,
        },
      ],
    },
    {
      key: 5,
      service: "",
      priceIncrease: "",
      charge: [
        {
          key: 1,
          value: 4026,
          customValue: 0,
          change: 0,
        },
        {
          key: 2,
          value: 4323,
          customValue: 0,
          change: 7.4,
        },
        {
          key: 3,
          value: 4496,
          customValue: 0,
          change: "0.0",
        },
        {
          key: 4,
          value: 4669.5,
          customValue: 0,
          change: 3.9,
        },
      ],
    },
  ],
};

export const getCurrentCharges = () => {
  return mockSuccess(ActionTypes.GetCurrentCharges, {
    delay: 200,
    response: mockCurrentCharges._data,
  });
};

export const getFutureCharges = () => {
  return mockSuccess(ActionTypes.GetFutureCharges, {
    delay: 200,
    response: mockFutureCharges._data,
  });
};

export const getQuarterlyServices = () => {
  return mockSuccess(ActionTypes.GetQuarterlyServices, {
    delay: 200,
    response: mockQuarterlyServices,
  });
};

export const getCutSheetPaper = () => {
  return mockSuccess(ActionTypes.GetCutSheetPaper, {
    delay: 200,
    response: mockCutSheetPaper,
  });
};

export const getHistoricalTotals = () => {
  return mockSuccess(ActionTypes.GetHistoricalTotals, {
    delay: 200,
    response: mockHistoricalTotals,
  });
};

export const getHistoricalMeterServices = () => {
  return mockSuccess(ActionTypes.GetHistoricalMeterServices, {
    delay: 200,
    response: mockHistoricalMeterServices,
  });
};

export const getHistoricalQuarterlyServices = () => {
  return mockSuccess(ActionTypes.GetHistoricalQuarterlyServices, {
    delay: 200,
    response: mockHistoricalQuarterlyServices,
  });
};

export const getHistoricalCutSheetPaperInclusive = () => {
  return mockSuccess(ActionTypes.GetHistoricalCutSheetPaperInclusive, {
    delay: 200,
    response: mockHistoricalCutSheetPaperInclusive,
  });
};

export const getPriceIncreaseBody = () => {
  return mockSuccess(ActionTypes.GetPriceIncreaseBody, {
    delay: 200,
    response: mockPriceIncreaseBodyData,
  });
};
