import React from "react";
import {
  ModalBuilder,
  Modal,
  Table,
  Lookup,
  PageLoader,
} from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import MeteredServiceForm from "./MeteredServiceForm";
import { LookupTypes } from "features/../../../shared/features/Settings";
import { Targets, Actions } from "constants/permissions";
import { getMeteredServiceForEdit } from "./actions";

const EditMeteredServiceModal = ({
  submitAction,
  onSubmitted,
  serviceId,
  dealerOfficeId,
}) => (
  <PermissionCheck target={Targets.Service} action={Actions.Edit}>
    <Lookup
      lookup={LookupTypes.ContractTypes}
      render={({ loading, response: contractTypeResponse }) => (
        <ModalBuilder
          withForm
          loadAction={getMeteredServiceForEdit}
          loadParams={{ id: serviceId }}
          submitAction={submitAction}
          submitParams={serviceId}
          onSubmitted={onSubmitted}
          renderTrigger={(showModal) => (
            <Table.MenuItem onClick={showModal}>Edit</Table.MenuItem>
          )}
          renderModal={(modalProps, formProps, state) => (
            <Modal.Edit
              {...modalProps}
              header="Edit Metered Service"
              submitLabel="Update"
            >
              {loading ? (
                <PageLoader />
              ) : (
                <MeteredServiceForm
                  loading={state.loading}
                  formProps={formProps}
                  contractTypeResponse={contractTypeResponse}
                  dealerOfficeId={dealerOfficeId}
                />
              )}
            </Modal.Edit>
          )}
        />
      )}
    />
  </PermissionCheck>
);

export default EditMeteredServiceModal;
