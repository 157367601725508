import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { PageHeader, getAuthState } from "@redriver/cinnamon";
import { Logout } from "features/System";
import { AppRoutes } from "constants/routes";
import { SystemPageContent } from "components/pages";

const LogoutPage = ({ loggedIn, location }) => {
  const nextRoute =
    location.state && location.state.nextPath
      ? location.state.nextPath
      : AppRoutes.Root;

  return (
    <SystemPageContent>
      <div className="sys-form">
        <PageHeader>Log Out</PageHeader>
        <Logout />
        {!loggedIn && (
          <Button primary as={Link} to={nextRoute} className="action-button">
            Continue
          </Button>
        )}
      </div>
    </SystemPageContent>
  );
};

const mapStateToProps = state => ({
  loggedIn: getAuthState(state).loggedIn,
});

export default connect(mapStateToProps)(LogoutPage);
