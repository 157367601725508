import React from "react";
import { connect } from "react-redux";
import { getAuthClaim, NavMenu } from "@redriver/cinnamon";
import { Popup, Icon } from "semantic-ui-react";
import { SystemRoutes } from "../../constants/routes";
import { UserArea } from "features/../../../shared/constants/enums";

class AccountItem extends React.Component {
  render() {
    const { avatar, name, hideMenu, userArea } = this.props;
    const description =
      userArea == UserArea.IpsSuperUser ? "IPS Super User" : "IP Syndicate";

    return (
      <div className="popup-menu account">
        <Popup
          flowing
          hoverable
          position="bottom right"
          content={
            <div className="popup-menu-content">
              {hideMenu ?? (
                <NavMenu.Item link={SystemRoutes.Account}>Account</NavMenu.Item>
              )}
              <NavMenu.Item link={SystemRoutes.Logout}>Log out</NavMenu.Item>
            </div>
          }
          trigger={
            <div className="account-icon-trigger">
              <div className="user-info">
                <label>{name}</label>
                <label>{description}</label>
              </div>
              {avatar ? (
                <div
                  className="account-icon"
                  style={{
                    backgroundImage: `url('${
                      process.env.STORAGE_CONTAINER_URL + avatar
                    }')`,
                  }}
                ></div>
              ) : (
                <Icon className="account-icon" name="user circle" />
              )}
            </div>
          }
        ></Popup>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  name: getAuthClaim(state, "userName"),
  userType: getAuthClaim(state, "userType"),
  avatar: getAuthClaim(state, "userAvatar"),
  userArea: getAuthClaim(state, "userArea"),
});

export default connect(mapStateToProps)(AccountItem);
