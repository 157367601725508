import React, { useState } from "react";
import { ContentPanel } from "components/displays";
import { getCFAScheduleDetails } from "./actions";
import { Form, ContentBuilder, Lookup } from "@redriver/cinnamon";

import { Grid, GridColumn, GridRow, Table } from "semantic-ui-react";
import ScheduleInformation from "./ScheduleInformation";
import {
  numericFormatter,
  currencyFormatter,
} from "components/displays/DisplayHelper";
import {
  LookupTypes,
  CFASchedulesLookupDropdown,
} from "features/../../../shared/features/Settings";

const ScheduleDetails = ({ cfaId }) => {
  const [selectedSchedule, setSelectedSchedule] = useState("");

  return (
    <Lookup
      lookup={LookupTypes.GetCFASchedules}
      render={({ response }) => (
        <React.Fragment>
          <div className="schedule-dropdown-wrapper">
            <h3>Schedule Ref:</h3>
            <CFASchedulesLookupDropdown
              lookupParams={{ cfaId }}
              onChange={(_event, data) => {
                const schedule =
                  response.find((x) => x.value === data.value) || {};
                setSelectedSchedule(schedule);
              }}
            />
          </div>
          {selectedSchedule && (
            <ContentBuilder
              loadAction={getCFAScheduleDetails}
              loadParams={selectedSchedule?.value}
              renderContent={(data) => (
                <div className="cfa-schedule-details">
                  <ScheduleDetailsPanel cfaScheduleDetails={data} />
                  <ScheduleInformation cfaScheduleDetails={data} />
                </div>
              )}
            />
          )}
        </React.Fragment>
      )}
    ></Lookup>
  );
};

const ScheduleDetailsPanel = ({ cfaScheduleDetails }) => (
  <ContentPanel
    title="Schedule Details"
    wrapperCssClass="outline-grid"
    headerCssClass="defaults-page-title"
  >
    <Grid columns={2}>
      <GridRow>
        <GridColumn>
          <div className="padded">
            <Form
              value={cfaScheduleDetails}
              className="cfa-schedule-details-form"
            >
              <Form.Input
                field="customer"
                label="Customer"
                fluid
                readOnly
                inline
              />
              <Form.Input
                field="supplier"
                label="Supplier"
                fluid
                readOnly
                inline
              />
              <Form.Input
                field="scheduleReference"
                label="Schedule Reference"
                fluid
                readOnly
                inline
              />
              <Form.Input
                field="scheduleStartDate"
                label="Schedule Start Date"
                fluid
                readOnly
                inline
              />
              <Form.Input
                field="scheduleStatus"
                label="Schedule Status"
                fluid
                readOnly
                renderReadOnly={({ value }) => (
                  <span className="red">{value}</span>
                )}
                inline
              />
              <Form.Input
                field="nextPeriodToBeBilled"
                label="Next Period to be Billed"
                fluid
                readOnly
                inline
              />
              <Form.Input
                field="scheduleFinanceCpi"
                label="Schedule Finance CPI"
                fluid
                readOnly
                inline
              />
              <Form.Input
                field="scheduleCfaChargeTotal"
                label="Schedule CFA Charge Total"
                fluid
                readOnly
                inline
                renderReadOnly={() =>
                  currencyFormatter(cfaScheduleDetails?.scheduleCfaChargeTotal)
                }
              />
              <Form.Input
                field="continuingPaymentSplit"
                label="Continuing Payment Split"
                fluid
                readOnly
                inline
              />
              {/* If this field is actually supposed to be a button... */}
              {/* <strong>Continuing Payment Split</strong> */}
              {/* <Button content="Edit"/> */}
            </Form>
          </div>
        </GridColumn>
        <GridColumn>
          <div className="padded">
            <ContinuingPaymentSplitTable
              cfaScheduleDetails={cfaScheduleDetails}
            />
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  </ContentPanel>
);

const ContinuingPaymentSplitTable = ({ cfaScheduleDetails }) => (
  <ContentPanel
    title="Continuing Payment Split"
    wrapperCssClass="outline-grid"
    headerCssClass="defaults-page-title"
  >
    <div className="padded">
      <Table className="cfa-continuing-payment-split-table">
        <Table.Header className="teal">
          <Table.Row>
            <Table.Cell className="no-border" />
            <Table.HeaderCell colSpan={2}>CFA</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row className="strong grey">
            <Table.Cell className="no-border" />
            <Table.Cell>% Split</Table.Cell>
            <Table.Cell>Minimum Total {process.env.MAJOR_CURRENCY}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Funder</Table.Cell>
            <Table.Cell>
              {numericFormatter(
                cfaScheduleDetails?.continuingPaymentSplitTable
                  ?.funderPercentageSplit,
                0,
                0
              )}
              {"%"}
            </Table.Cell>
            <Table.Cell>
              {currencyFormatter(
                cfaScheduleDetails?.continuingPaymentSplitTable
                  ?.funderMinimumTotal
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Supplier</Table.Cell>
            <Table.Cell>
              {numericFormatter(
                cfaScheduleDetails?.continuingPaymentSplitTable
                  ?.supplierPercentageSplit,
                0,
                0
              )}
              {"%"}
            </Table.Cell>
            <Table.Cell>
              {currencyFormatter(
                cfaScheduleDetails?.continuingPaymentSplitTable
                  ?.supplierMinimumTotal
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>IP Syndicate</Table.Cell>
            <Table.Cell>
              {numericFormatter(
                cfaScheduleDetails?.continuingPaymentSplitTable
                  ?.ipSyndicatePercentageSplit,
                0,
                0
              )}
              {"%"}
            </Table.Cell>
            <Table.Cell>
              {currencyFormatter(
                cfaScheduleDetails?.continuingPaymentSplitTable
                  ?.ipSyndicateMinimumTotal
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row className="strong grey">
            <Table.Cell className="no-border" />
            <Table.Cell>
              {numericFormatter(
                cfaScheduleDetails?.continuingPaymentSplitTable
                  ?.totalPercentageSplit,
                0,
                0
              )}
              {"%"}
            </Table.Cell>
            <Table.Cell>
              {currencyFormatter(
                cfaScheduleDetails?.continuingPaymentSplitTable
                  ?.totalMinimumTotal
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  </ContentPanel>
);

export default ScheduleDetails;
