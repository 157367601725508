import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/RESTRICTIONS/COST_PER_DEVICE";

const ActionTypes = {
  GetCostPerDeviceDefaults: `${NAMESPACE}/GET`,
  UpdateCostPerDeviceDefaults: `${NAMESPACE}/UPDATE`,
};

export const getCostPerDeviceDefaults = ({ settingsId }) =>
  apiGet(
    ActionTypes.GetCostPerDeviceDefaults,
    `defaults-restrictions/cost-per-device`,
    {
      settingsId,
    }
  );

export const updateCostPerDeviceDefaults = (formData, { settingsId }) =>
  apiPut(
    ActionTypes.UpdateCostPerDeviceDefaults,
    `defaults-restrictions/cost-per-device/${settingsId}`,
    formData
  );
