import React, { useRef, useState } from "react";
import { Grid } from "semantic-ui-react";
import PaperDefaultSettings from "./ServiceSettings/PaperDefaultSettings";
import LFPSettings from "../LargeFormatSettings/LargeFormatPrinting";
import TieredTonerSettings from "./ServiceSettings/TieredTonerSettings";

const ServicesSettings = ({ settingsId, dealerOfficeId }) => {
  const tieredTonerRef = useRef();
  const cutSheetPaperRef = useRef();
  const lfpRef = useRef();

  const refs = [
    { ref: tieredTonerRef },
    { ref: cutSheetPaperRef },
    { ref: lfpRef },
  ];

  const [tieredTonerCollapsed, setTieredTonerCollapsed] = useState(true);
  const [cutSheetPaperCollapsed, setCutSheetPaperCollapsed] = useState(true);
  const [lfpCollapsed, setLfpCollapsed] = useState(true);

  const focusOnTarget = (reference) => {
    const focus = refs[reference];
    focus.ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <React.Fragment>
      {settingsId && (
        <Grid className="settings-grid">
          <Grid.Row className="settings-grid-nav-header">
            <Grid.Column>
              <div
                onClick={() => {
                  setTieredTonerCollapsed(!tieredTonerCollapsed);
                  setTimeout(function () {
                    focusOnTarget(0);
                  }, 1);
                }}
              >
                Tiered Toner Availability
              </div>
            </Grid.Column>
            <Grid.Column>
              <div
                onClick={() => {
                  setCutSheetPaperCollapsed(!cutSheetPaperCollapsed);
                  setTimeout(function () {
                    focusOnTarget(1);
                  }, 1);
                }}
              >
                Cut Sheet Paper Inclusive
              </div>
            </Grid.Column>
            <Grid.Column className="last-col">
              <div
                onClick={() => {
                  setLfpCollapsed(!lfpCollapsed);
                  setTimeout(function () {
                    focusOnTarget(2);
                  }, 1);
                }}
              >
                LFP
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div className="flex title-bar">
              <h4 className="settings-title">Service Settings</h4>
            </div>
          </Grid.Row>
          <Grid.Row className="settings-grid-row">
            <TieredTonerSettings
              settingsId={settingsId}
              ref={tieredTonerRef}
              collapsed={tieredTonerCollapsed}
              toggleVisibility={() =>
                setTieredTonerCollapsed(!tieredTonerCollapsed)
              }
            />
          </Grid.Row>
          <Grid.Row className="settings-grid-row">
            <PaperDefaultSettings
              settingsId={settingsId}
              ref={cutSheetPaperRef}
              collapsed={cutSheetPaperCollapsed}
              toggleVisibility={() =>
                setCutSheetPaperCollapsed(!cutSheetPaperCollapsed)
              }
            />
          </Grid.Row>
          <Grid.Row className="settings-grid-row">
            <LFPSettings
              settingsId={settingsId}
              dealerOfficeId={dealerOfficeId}
              ref={lfpRef}
              collapsed={lfpCollapsed}
              toggleVisibility={() => setLfpCollapsed(!lfpCollapsed)}
            />
          </Grid.Row>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ServicesSettings;
