import React from "react";
import { Form } from "@redriver/cinnamon";
import { Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const PaperModalForm = ({ formProps }) => {
  const { t } = useTranslation("supplierOffices", {
    keyPrefix: "largeFormat.printer.viewPaper",
  });

  const majorCurrency = process.env.MAJOR_CURRENCY;
  const minorCurrency = process.env.MINOR_CURRENCY;

  return (
    <Form {...formProps} disabled>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form.Input field="description" label={t("description")} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Input field="lengthM" label={t("lengthM")} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form.Input field="paperWidthMm" label={t("paperWidthMm")} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Input
              field="paperWidthInches"
              label={t("paperWidthInches")}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form.Input field="weight" label={t("weight")} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Input field="weightUnit" label={t("weightUnit")} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form.Input
              field="costPence"
              label={
                process.env.USE_MINOR_CURRENCY
                  ? t("costMinor", { minorCurrency })
                  : t("costMajor", { majorCurrency })
              }
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Input field="markup" label={t("markup")} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form.Input field="productSku" label={t("productSku")} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Input field="ipsBillingCode" label={t("ipsBillingCode")} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form.Input field="ipsSku" label={t("ipsSku")} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Input
              field="supplierBillingCode"
              label={t("supplierBillingCode")}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form.Checkbox
              field="alwaysUnmanaged"
              label={t("alwaysUnmanaged")}
            />
            <Form.Checkbox field="alwaysManaged" label={t("alwaysManaged")} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Form.Input field="code" label={t("code")} />
            <br />
            <Form.Input
              field="defaultMinVolume"
              label={t("defaultMinVolume")}
            />
            <br />
            <Form.Input field="vMinVolume" label={t("vMinVolume")} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form.TextArea field="note" label={t("note")} rows />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default PaperModalForm;
