import React from "react";
import { Table } from "@redriver/cinnamon";
import { withPermissions } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";

import RemoveMeteredServiceModal from "./RemoveMeteredServiceModal";
import EditMeteredServiceModal from "./EditMeteredServiceModal";

const MeteredServiceActions = ({
  service,
  onRefresh,
  deleteAction,
  editAction,
  hasPermission,
  dealerOfficeId,
}) => {
  const canDelete = hasPermission(Targets.Service, Actions.Delete);
  const canEdit = hasPermission(Targets.Service, Actions.Edit);

  if (!canDelete) return null;

  return (
    <Table.Menu>
      {canEdit && (
        <EditMeteredServiceModal
          serviceId={service.id}
          onSubmitted={onRefresh}
          submitAction={editAction}
          dealerOfficeId={dealerOfficeId}
        />
      )}
      {canDelete && (
        <RemoveMeteredServiceModal
          service={service}
          onSubmitted={onRefresh}
          submitAction={deleteAction}
        />
      )}
    </Table.Menu>
  );
};

export default withPermissions(MeteredServiceActions);
