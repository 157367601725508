import React from "react";
import { Header } from "./";
import { getProducts } from "./actions";
import { SimpleTableBuilder } from "../../../../../../../shared/components/tables/sortableTable";

const Products = () => {
  const columns = [
    { field: "identifierNumber", title: "Identifier Number" },
    { field: "manModel", title: "Manufacturer/Model" },
    { field: "status", title: "Status" },
  ];
  return (
    <React.Fragment>
      <Header />
      <br />
      <br />
      <div className="vertical-scrollbar medium">
        <SimpleTableBuilder
          withTable
          loadAction={getProducts}
          emptyMessage="No products found"
          columns={columns}
        />
      </div>
    </React.Fragment>
  );
};

export default Products;
