import React from "react";
import { ContentPanel } from "components/displays";
import { Format, ListBuilder } from "@redriver/cinnamon";
import { Icon } from "semantic-ui-react";
import { numericFormatter } from "components/displays/DisplayHelper";
import { InfoModalWrapper } from "components/modals/";
import { getCompanySchedules, getCompanySchedulesItems } from ".";
import { SimpleTableBuilder } from "../../../../../../shared/components/tables/sortableTable";

const CompanySchedules = () => {
  const columns = [
    { field: "id", title: "Schedule", width: "16%" },
    {
      field: "managedVol",
      title: "Total Managed Volume",
      width: "8%",
      render: (item) => numericFormatter(item.managedVol, 0, 0),
    },
    {
      field: "remainingVol",
      title: "Remaining Volume",
      width: "8%",
      render: (item) => numericFormatter(item.remainingVol, 0, 0),
    },
    { field: "sheetType", title: "Sheet Type", width: "21%" },
    {
      field: "qtyMinimum",
      title: `Quarterly Minimum  (${process.env.MAJOR_CURRENCY})`,
      width: "10%",
      render: (item) => numericFormatter(item.qtyMinimum),
    },
    {
      field: "lastMinDate",
      title: "Last Minimum Date",
      width: "10%",
      render: (item) => (
        <Format.Date value={item.lastMinDate} format="DD MMM YYYY" />
      ),
    },
    {
      field: "qtyImplemented",
      title: "Quarter Implemented",
      width: "8%",
      render: (item) => (
        <Format.Date value={item.qtyImplemented} format="DD MMM YYYY" />
      ),
    },
    {
      field: "dateActivated",
      title: "Date Activated",
      width: "8%",
      render: (item) => (
        <Format.Date value={item.dateActivated} format="DD MMM YYYY" />
      ),
    },
    { field: "status", title: "Status", width: "6%" },
    {
      field: "attachment",
      title: "Attachment",
      className: "centre-align attachment-link",
      width: "5%",
      render: () => (
        <a href="#">
          <Icon name="paperclip" />
        </a>
      ),
      sortable: false,
    },
  ];

  return (
    <ContentPanel
      title="Schedules"
      wrapperCssClass={"outline-grid company-schedules"}
      headerCssClass="defaults-page-title"
    >
      <div className="padded">
        <ListBuilder
          loadAction={getCompanySchedules}
          renderList={(data) => (
            <React.Fragment>
              {data.map((x) => (
                <React.Fragment key={x.id}>
                  <ContentPanel
                    title={"SCHEDULE: " + x.id}
                    wrapperCssClass={"outline-grid"}
                    headerCssClass="defaults-page-title"
                    headerContent={
                      <React.Fragment>
                        <span className="contract-ref">
                          CONTRACT REF: {x.ref}
                        </span>
                        <span className="contract-master-date">
                          {" "}
                          MASTER DATE: {x.masterDate}
                        </span>
                      </React.Fragment>
                    }
                  >
                    <div className="padded">
                      <SimpleTableBuilder
                        emptyMessage="No products found"
                        columns={columns}
                        loadAction={getCompanySchedulesItems}
                        loadParams={{ id: x.id }}
                        className="left-align"
                      />

                      <br />
                      <InfoModalWrapper floated="right" title="Historic">
                        Not implemented.
                      </InfoModalWrapper>
                    </div>
                    <div className="clear"></div>
                  </ContentPanel>
                  <br />
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        ></ListBuilder>
      </div>
    </ContentPanel>
  );
};

export default CompanySchedules;
