import React, { useState } from "react";
import { ListSystemConfig } from "features/SystemConfig";
import { PageContent } from "@redriver/cinnamon";
import { SystemConfigType } from "features/../../../shared/constants/enums";
import { StartAndStopMaintenancePeriod } from "features/System/Maintenance";

const MaintenanceConfigPage = () => {
  const [updatedDateTime, setUpdatedDateTime] = useState(new Date().getTime());

  return (
    <PageContent>
      <ListSystemConfig
        type={SystemConfigType.Maintenance}
        title="Maintenance configuration"
        updatedDateTime={updatedDateTime}
      />

      <StartAndStopMaintenancePeriod
        onSubmitted={() => setUpdatedDateTime(new Date().getTime())}
      />
    </PageContent>
  );
};

export default MaintenanceConfigPage;
