import React, { useState } from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import BoilerPlateForm from "./BoilerPlateForm";
import { createBoilerPlateTandCs } from "./actions";

const AddBoilerPlateModal = ({
  as: As = Button,
  onSubmitted,
  dealerId,
  ...props
}) => {
  const [uploadDoc, setUploadDoc] = useState(null);

  const onChangeUpload = (file) => {
    let fileReader = new FileReader();
    fileReader.onload = () => {
      const res = fileReader.result;
      setUploadDoc(res);
    };
    fileReader.readAsDataURL(file);
  };

  return (
    <ModalBuilder
      withForm
      submitAction={createBoilerPlateTandCs}
      submitParams={{ dealerId, uploadDoc }}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => (
        <As {...props} primary onClick={showModal}>
          Upload New Master
        </As>
      )}
      renderModal={(modalProps, formProps) => (
        <Modal.Add {...modalProps} header="New Boiler Plate T&C's">
          <BoilerPlateForm
            {...formProps}
            onChangeUpload={onChangeUpload}
            editMode={false}
          />
        </Modal.Add>
      )}
    />
  );
};

export default AddBoilerPlateModal;
