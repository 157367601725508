import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DealerRoutes } from "constants/routes";
import { Link } from "react-router-dom";
import {
  ActionBar,
  ListBuilder,
  FormBuilder,
  Format,
} from "@redriver/cinnamon";
import { getDealers } from "./actions";
import { Icon, Image, Table, TableCell } from "semantic-ui-react";
import DefaultPrincipalLogo from "assets/logos/principal-logo.svg";
import CreateDealer from "./CreateDealer";
import { clearRatesState } from "../DealerOffice/Settings/actions";
import { ContentPanel } from "components/displays";
import { blankDataFormatter } from "components/displays/DisplayHelper";
import { TextSearch } from "features/../../../shared/components/search";
import { TablePagination } from "components/pagination";
import { SortHeader } from "../../../../../shared/components/tables/sortableTable";

const DealersList = () => {
  const [tablesToShow, setTablesToShow] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearRatesState());
  }, [dispatch]);

  return (
    <div className="dealer-list">
      <ListBuilder
        debounceFilters={600}
        loadAction={getDealers}
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        initialFilters={{
          search: "",
        }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        renderList={(data, state, events) => (
          <React.Fragment>
            <ContentPanel
              title="Suppliers"
              wrapperCssClass="outline-grid suppliers-list"
              headerCssClass="defaults-page-title"
              headerContent={
                <FilterBar
                  state={state}
                  events={events}
                  initialPageSize={25}
                  onAdded={events.onRefresh}
                ></FilterBar>
              }
            >
              <div className="spaced">
                <DealerTable
                  data={data}
                  isLegalEntity={true}
                  tablesToShow={tablesToShow}
                  setTablesToShow={setTablesToShow}
                  state={state}
                  events={events}
                ></DealerTable>
                <br />
                <TablePagination
                  pageNumber={state.pagination.pageNumber}
                  onChange={events.onChangePagination}
                  pageSize={state.pagination.pageSize}
                  totalItems={state.total}
                />
              </div>
            </ContentPanel>
          </React.Fragment>
        )}
      />
    </div>
  );
};

const DealerTable = ({
  data,
  tablesToShow,
  setTablesToShow,
  state,
  events,
}) => {
  function toggleRow(id) {
    if (tablesToShow.indexOf(id) > -1) {
      let index = tablesToShow.indexOf(id);
      if (index !== -1) {
        const arrNew = [...tablesToShow];
        arrNew.splice(index, 1);
        setTablesToShow(arrNew);
      }
    } else {
      setTablesToShow(tablesToShow.concat(id));
    }
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ width: "2%" }}></Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>
            <SortHeader
              field="name"
              title="Legal Entity Name "
              value={state.filters}
              onChange={events.onChangeFilters}
            />
          </Table.HeaderCell>
          <Table.HeaderCell>Short Name</Table.HeaderCell>
          <Table.HeaderCell>Jurisdiction </Table.HeaderCell>
          <Table.HeaderCell>Address </Table.HeaderCell>
          <Table.HeaderCell>Start Date </Table.HeaderCell>
          <Table.HeaderCell>End Date</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data &&
          data.map((item) => (
            <React.Fragment key={item.id}>
              <Table.Row>
                <Table.Cell onClick={() => toggleRow(item.id)}>
                  <Icon
                    name="dropdown"
                    rotated={
                      !(tablesToShow.indexOf(item.id) > -1)
                        ? "counterclockwise"
                        : null
                    }
                  />
                </Table.Cell>
                <Table.Cell onClick={() => toggleRow(item.id)}>
                  <Image
                    className="logo"
                    src={item.logoUri || DefaultPrincipalLogo}
                    alt="Supplier Logo"
                    title="Supplier Logo"
                    width="80px"
                  />
                </Table.Cell>

                <Table.Cell>
                  <Link to={DealerRoutes.Details(item.id)}>{item.name} </Link>
                </Table.Cell>
                <Table.Cell>{item.shortName}</Table.Cell>
                <TableCell>{item.jurisdiction}</TableCell>
                <TableCell>
                  {blankDataFormatter(item.hqAddress, "Not provided")}
                </TableCell>
                <TableCell>
                  <Format.Date value={item.startDate} format="DD MMM YYYY" />
                </TableCell>
                <TableCell>
                  <Format.Date value={item.endDate} format="DD MMM YYYY" />
                </TableCell>
              </Table.Row>
              <Table.Row hidden={!(tablesToShow.indexOf(item.id) > -1)}>
                <Table.Cell colSpan={8}>
                  {item.dealerOfficeByStructure &&
                    item.dealerOfficeByStructure.map((offices, index) => (
                      <div className="padded" key={index}>
                        <h3>
                          {offices[0].parentStructureName == undefined
                            ? "Supplier Offices"
                            : offices[0].parentStructureName}
                        </h3>
                        <Table>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell style={{ width: "12%" }}>
                                Supplier
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "12%" }}>
                                Supplier Office Code
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "30%" }}>
                                Address
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "12%" }}>
                                Phone Number
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "12%" }}>
                                Start Date
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "12%" }}>
                                End Date
                              </Table.HeaderCell>
                              <Table.HeaderCell style={{ width: "12%" }}>
                                Status
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {offices.map((item) => (
                              <Table.Row key={item.id}>
                                <Table.Cell>
                                  <Link to={DealerRoutes.Office(item.id)}>
                                    {item.name}
                                  </Link>
                                </Table.Cell>
                                <Table.Cell>-</Table.Cell>
                                <TableCell>
                                  {blankDataFormatter(
                                    item.address,
                                    "Not provided",
                                  )}
                                </TableCell>
                                <TableCell>
                                  {blankDataFormatter(
                                    item.phone,
                                    "Not provided",
                                  )}
                                </TableCell>
                                <TableCell>20-May-14</TableCell>
                                <TableCell>-</TableCell>
                                <TableCell>-</TableCell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      </div>
                    ))}
                </Table.Cell>
              </Table.Row>
            </React.Fragment>
          ))}
      </Table.Body>
    </Table>
  );
};

const FilterBar = ({ state, events, onAdded }) => (
  <FormBuilder
    renderForm={() => (
      <ActionBar className="filter-display">
        <ActionBar.Item>
          <TextSearch
            filters={state.filters}
            onChange={events.onChangeFilters}
          ></TextSearch>
        </ActionBar.Item>
        <ActionBar.Item fluid></ActionBar.Item>
        <ActionBar.Item>
          <CreateDealer onAdded={onAdded} />
        </ActionBar.Item>
      </ActionBar>
    )}
  />
);

export default DealersList;
