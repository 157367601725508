import React from "react";
import { Image } from "semantic-ui-react";
import { DataField } from "components/displays";
import DefaultPrincipalLogo from "assets/logos/principal-logo.svg";

const OfficeHeader = ({ data }) => {
  return (
    <div className="content-panel">
      <div className="office-header">
        <div className="office-header-group group1">
          {DataField("Name", data.name)}
          {DataField("Head Office", data.dealerName)}
        </div>
        <div className="office-header-group group2">
          {DataField("Contact Phone", data.contactPhone)}
          {DataField("Contact Email", data.contactEmail)}
        </div>
        <div className="office-header-group group3">
          <Image
            className="office-logo"
            src={data.logoUri || DefaultPrincipalLogo}
            alt="Supplier Logo"
            title="Supplier Logo"
          />
        </div>
      </div>
    </div>
  );
};

export default OfficeHeader;
