import { apiPost, apiGet, mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "FUNDERS_SETTINGS";

const ActionTypes = {
  getSupplierDetails: `${NAMESPACE}/GET_SUPPLIER_DETAILS`,
  getCfaReference: `${NAMESPACE}/GET_CFA_REFERENCE`,
  getBilling: `${NAMESPACE}/GET_BILLING`,
  getBillingCodes: `${NAMESPACE}/GET_BILLING_CODES`,
  addSupplierToFunder: `${NAMESPACE}/ADD_SUPPLIER_TO_FUNDER`,
  getFunderSupplierSettings: `${NAMESPACE}/GET_FUNDER_SUPPLIER_SETTINGS`,
};

const supplier = {
  supplier: 1,
  name: "Konica Minolta",
  spv: "IPS SPV I Ltd",
  currency: process.env.CURRENCY_CODE,
  status: true,
  startDt: "07/13/2013",
};

const cfaReference = {
  type: 1,
  prefix: "IPS",
  number: 1241,
};

const billingCodes = {
  cfaPayment: "BNPFIN",
  funder: "BNPFINCP",
  supplier: "KMFINCP",
  ipsSpv: "IPSFINCP",
};

const billing = {
  additionalUnits: true,
  dueDatePlus: 4,
  selfBillingMinimum: true,
  selfBillingExcess: true,
  purchaseLedger: {
    description: "Purchase Ledger",
    ipsCompanyID: 7744,
    companyName: "BNP Paribas Lease Group (Rentals) Limited",
    address1: "Northern Cross",
    address2: "Basing View",
    address3: "",
    town: "Basingstoke",
    county: "Hampshire",
    country: "United Kingdom",
    postcode: "RH21 4HL",
    selfBillInvoices: true,
    sendType: 1,
    emailAddress: "tvp@bnp.com",
    cc: "invoice@bnp.com",
  },
  salesLedger: {
    description: "Sales Ledger",
    ipsCompanyID: 5623,
    companyName: "BNP Paribas Lease Group (Rentals) Limited",
    address1: "St James Court",
    address2: "St James Parade",
    address3: "",
    town: "Bristol",
    county: "",
    country: "United Kingdom",
    postcode: "BS1 3LH",
    selfBillInvoices: true,
    sendType: 1,
    emailAddress: "invoices@bnp.com",
    cc: "accountmanager@bnp.com",
  },
};

export const getSupplier = () => {
  return mockSuccess(ActionTypes.getSupplierDetails, {
    delay: 500,
    response: supplier,
  });
};

export const getCfaReference = () => {
  return mockSuccess(ActionTypes.getCfaReference, {
    delay: 500,
    response: cfaReference,
  });
};

export const getBillingCodes = () => {
  return mockSuccess(ActionTypes.getBillingCodes, {
    delay: 500,
    response: billingCodes,
  });
};

export const getBilling = () => {
  return mockSuccess(ActionTypes.getBilling, {
    delay: 500,
    response: billing,
  });
};

export const addSupplierToFunder = (formData, id) => {
  return apiPost(
    ActionTypes.addSupplierToFunder,
    `funders/${id}/link-supplier`,
    formData
  );
};

export const getFunderSupplierSettings = ({ id }) =>
  apiGet(
    ActionTypes.getFunderSupplierSettings,
    `funders/${id}/supplier-settings`
  );
