export default {
  User: "u",
  RoleTemplate: "ro",
  UserRole: "uro",
  Sheet: "sh",
  Dealer: "d",
  DealerOffice: "do",
  Client: "c",
  Service: "s",
  Defaults: "df",
  SheetType: "st",
  SheetAdmin: "sa",
  SheetFinance: "sf",
  Territory: "t",
  LargeFormat: "lf",
  SystemConfig: "cfg",
  MaintenancePeriodNotifications: "mpn",
  Invoice: "i",
  DefaultsRestrictions: "dfr",
  YieldBands: "yb",
  Rates: "ra",
  CFA: "cfa",
  UploadSettings: "ups",
  UploadSettingsTemplates: "ust",
  BoilerPlateTAndCs: "bptc",
  UserMfa: "umfa",
  SPVs: "spv",
  Funder: "fu",
};
