import React, { useState } from "react";
import { Tab } from "semantic-ui-react";
import { GeneralSettings, YieldTables, RateTables } from "./Settings";
import {
  ListServices,
  ListRateGroups,
} from "features/../../../shared/features/Settings";
import Search from "features/../../../shared/assets/turquoise-search.svg";
import ListMeteredServices from "./Services/MeteredServices";
import { ListCutSheetPaper } from "./Services/CutSheetPaper";
import { withPermissions } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import ListLargeFormatPaper from "features/../../../shared/features/Settings/LargeFormat/Paper/ListLargeFormatPaper";
import { ListLargeFormatServices } from "./LFP";
import UsageTypeSettings from "./UsageTypes/UsageTypeSettings";

const DealerOfficeTabs = ({ officeInfo, hasPermission }) => {
  const [activeTab, setActiveTab] = useState(1);
  const panes = [
    {
      menuItem: "Summary",
      render: () => <div></div>,
    },
    {
      menuItem: "General Settings",
      render: () => (
        <GeneralSettings
          settingsId={officeInfo.settingsId}
          dealerOfficeId={officeInfo.id}
        />
      ),
      enabled: true,
    },
    {
      menuItem: "Rate Group Settings",
      render: () => (
        <div>
          <ListRateGroups
            searchImage={Search}
            dealerOfficeId={officeInfo?.id}
            timezone={officeInfo.timezone}
          />
        </div>
      ),
      enabled: true,
    },
    {
      menuItem: "Yield Tables",
      render: () => <YieldTables data={officeInfo} />,
      enabled: hasPermission(Targets.Rates, Actions.View),
    },
    {
      menuItem: "Rate Tables",
      render: () => <RateTables data={officeInfo} />,
      enabled: hasPermission(Targets.Rates, Actions.View),
    },
    {
      menuItem: "Quarterly Services",
      render: () => (
        <div>
          <ListServices
            searchImage={Search}
            dealerOfficeId={officeInfo ? officeInfo.id : null}
          />
        </div>
      ),
      enabled: true,
    },
    {
      menuItem: "Cut Sheet Paper",
      render: () => (
        <ListCutSheetPaper
          searchImage={Search}
          dealerOfficeId={officeInfo ? officeInfo.id : null}
        />
      ),
      enabled: true,
    },
    {
      menuItem: "Metered Services",
      render: () => (
        <div>
          <ListMeteredServices
            searchImage={Search}
            dealerOfficeId={officeInfo ? officeInfo.id : null}
          />
        </div>
      ),
      enabled: true,
    },
    {
      menuItem: "Large Format",
      render: () => (
        <ListLargeFormatServices
          searchImage={Search}
          dealerOfficeId={officeInfo ? officeInfo.id : null}
        />
      ),
      enabled: true,
    },
    {
      menuItem: "Large Format Paper",
      render: () => (
        <div>
          <ListLargeFormatPaper
            searchImage={Search}
            dealerOfficeId={officeInfo ? officeInfo.id : null}
          />
        </div>
      ),
      enabled: true,
    },
    {
      menuItem: "Usage Types",
      render: () => (
        <div>
          <UsageTypeSettings
            dealerOfficeId={officeInfo ? officeInfo.id : null}
          />
        </div>
      ),
      enabled: true,
    },
  ];
  return (
    <React.Fragment>
      <Tab
        menu={{ text: true }}
        panes={panes.filter((x) => x.enabled)}
        id="tabs"
        activeIndex={activeTab}
        onTabChange={(event, data) => {
          setActiveTab(data.activeIndex);
        }}
      />
    </React.Fragment>
  );
};

export default withPermissions(DealerOfficeTabs);
