import React from "react";
import { Form } from "@redriver/cinnamon";

const FunderForm = ({ ...formProps }) => {
  return (
    <React.Fragment>
      <div>
        <Form {...formProps}>
          <Form.Group>
            <Form.Input
              field="name"
              label="Name"
              fluid
              required
              placeholder="Name..."
              maxLength={50}
            />
            <Form.Input
              field="shortName"
              label="Short Name"
              fluid
              required
              placeholder="Short Name..."
              maxLength={20}
            />
            <Form.Date field="startDate" label="Start Date" local required />
          </Form.Group>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default FunderForm;
