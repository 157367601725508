import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "CFA";

const ActionTypes = {
  GetCFAList: `${NAMESPACE}/GET_LIST`,
  GetCFAs: `${NAMESPACE}/GET`,
};

export const getCFAListData = () => {
  return mockSuccess(ActionTypes.GetCFAList, {
    delay: 200,
    response: MockCFAListData,
  });
};

export const getCFAData = (id) => {
  //Set demo data id to the selected CFA id
  //Remove when backend completed
  const response = MockCFAData;
  response.cfaReference = id;

  return mockSuccess(ActionTypes.GetCFAs, {
    delay: 200,
    response: response,
  });
};

const MockCFAData = {
  id: 1,
  cfaReference: "A1A04575",
  funderType: "Funder",
  funder: "BNP",
  paymentMethod: "Quarterly",
  paymentProfile: "In Advance",
  rateType: "FMV",
  cfaStatus: "Live",
  currentScheduleStatus: "Live",
};

const MockCFAListData = {
  totalResults: 25,
  results: [
    {
      id: 1,
      ref: "A1C80655",
      status: "Live",
      customer: "Abacus Employment Services Ltd",
      supplier: "Principal UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "09/05/2019",
      endDate: "",
      payment: 3727.61,
      term: "Monthly",
      periodsRemaining: 12,
      funder: "Macquarie",
    },
    {
      id: 2,
      ref: "A1B57569",
      status: "Live",
      customer: "Abacus Employment Services Ltd",
      supplier: "Principal UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "12/05/2020",
      endDate: "",
      payment: 435.94,
      term: "Monthly",
      periodsRemaining: 15,
      funder: "Macquarie",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 3,
      ref: "A1C91140",
      status: "Live",
      customer: "Adam Architecture Ltd",
      supplier: "Principal UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "11/21/2016",
      endDate: "",
      payment: 5160.85,
      term: "Monthly",
      periodsRemaining: 16,
      funder: "Macquarie",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 4,
      ref: "A1C23805",
      status: "Live",
      customer: "Adam Architecture Ltd",
      supplier: "Principal UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "08/21/2017",
      endDate: "",
      payment: 12031.61,
      term: "Quarterly",
      periodsRemaining: 17,
      funder: "BNP Paribas",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 5,
      ref: "Y0046540",
      status: "Continuing Payments",
      customer: "Adams & Remers Llp",
      supplier: "Principal UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "09/19/2016",
      endDate: "09/19/2020",
      payment: 636.12,
      term: "Quarterly",
      periodsRemaining: 0,
      funder: "BNP Paribas",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 6,
      ref: "Z0032638",
      status: "Continuing Payments",
      customer: "Adams & Remers Llp",
      supplier: "Principal UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "09/19/2018",
      endDate: "09/19/2020",
      payment: 149.52,
      term: "Quarterly",
      periodsRemaining: 0,
      funder: "Macquarie",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 7,
      ref: "A1B32831",
      status: "Live",
      customer: "Adams & Remers Llp",
      supplier: "Konica Minolta UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "03/19/2019",
      endDate: "",
      payment: 1788.02,
      term: "Quarterly",
      periodsRemaining: 3,
      funder: "Macquarie",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 8,
      ref: "A1b12940",
      status: "Live",
      customer: "Africa Collection UK Ltd",
      supplier: "Konica Minolta UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "07/03/2019",
      endDate: "",
      payment: 110.9,
      term: "Quarterly",
      periodsRemaining: 7,
      funder: "Macquarie",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 9,
      ref: "A1C39669",
      status: "Live",
      customer: "Aksward Collection UK Ltd",
      supplier: "Konica Minolta UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "05/07/2019",
      endDate: "",
      payment: 2943.56,
      term: "Quarterly",
      periodsRemaining: 4,
      funder: "Macquarie",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 10,
      ref: "Y0002621",
      status: "Live",
      customer: "DTP Hospitality UK Ltd",
      supplier: "Konica Minolta UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "08/23/2018",
      endDate: "",
      payment: 1655.36,
      term: "Monthly",
      periodsRemaining: 12,
      funder: "Macquarie",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 11,
      ref: "A1A64944",
      status: "Live",
      customer: "DTP Hospitality UK Ltd",
      supplier: "Konica Minolta UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "11/23/2019",
      endDate: "",
      payment: 460.13,
      term: "Monthly",
      periodsRemaining: 9,
      funder: "Macquarie",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 12,
      ref: "Z00027276",
      status: "Dead",
      customer: "E J Coombs Solicitors Ltd",
      supplier: "Principal UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "09/19/2018",
      endDate: "09/19/2020",
      payment: 358.97,
      term: "Monthly",
      periodsRemaining: 0,
      funder: "BNP Paribas",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 13,
      ref: "Z0073501",
      status: "Dead",
      customer: "Expresspace Limited",
      supplier: "Principal UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "10/20/2016",
      endDate: "10/20/2019",
      payment: 146.57,
      term: "Quarterly",
      periodsRemaining: 0,
      funder: "BNP Paribas",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 14,
      ref: "A1A43534",
      status: "Continuing Payments",
      customer: "Francesco Group (Holdings) Ltd",
      supplier: "Principal UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "12/10/2014",
      endDate: "12/10/2019",
      payment: 358.84,
      term: "Quarterly",
      periodsRemaining: 0,
      funder: "BNP Paribas",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 15,
      ref: "A1C65954",
      status: "Live",
      customer: "Francesco Group (Holdings) Ltd",
      supplier: "Principal UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "04/09/2016",
      endDate: "",
      payment: 518.04,
      term: "Quarterly",
      periodsRemaining: 7,
      funder: "BNP Paribas",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 16,
      ref: "A1A18668",
      status: "Dead",
      customer: "Ings Engineering Ltd",
      supplier: "Konica Minolta UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "05/25/2018",
      endDate: "02/25/2020",
      payment: 616.38,
      term: "Monthly",
      periodsRemaining: 0,
      funder: "Macquarie",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 17,
      ref: "A1C80655",
      status: "Live",
      customer: "Abacus Employment Services Ltd",
      supplier: "Konica Minolta UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "09/05/2019",
      endDate: "",
      payment: 3727.61,
      term: "Quarterly",
      periodsRemaining: 12,
      funder: "Macquarie",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 18,
      ref: "A1B57569",
      status: "Live",
      customer: "Abacus Employment Services Ltd",
      supplier: "Konica Minolta UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "12/05/2020",
      endDate: "",
      payment: 435.94,
      term: "Quarterly",
      periodsRemaining: 15,
      funder: "Macquarie",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 19,
      ref: "A1c91140",
      status: "Live",
      customer: "Adam Architecture Ltd",
      supplier: "Principal UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "11/21/2016",
      endDate: "",
      payment: 5160.85,
      term: "Quarterly",
      periodsRemaining: 16,
      funder: "Macquarie",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 20,
      ref: "A1c23801",
      status: "Live",
      customer: "Adam Architecture Ltd",
      supplier: "Principal UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "08/21/2017",
      endDate: "",
      payment: 12031.61,
      term: "Quarterly",
      periodsRemaining: 17,
      funder: "BNP Paribas",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 21,
      ref: "Y0046540",
      status: "Continuing Payments",
      customer: "Adams & Remers Llp",
      supplier: "Principal UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "09/19/2016",
      endDate: "09/19/2020",
      payment: 636.12,
      term: "Monthly",
      periodsRemaining: 0,
      funder: "BNP Paribas",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 22,
      ref: "Z0032636",
      status: "Continuing Payments",
      customer: "Adams & Remers Llp",
      supplier: "Principal UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "09/19/2018",
      endDate: "09/19/2020",
      payment: 149.52,
      term: "Monthly",
      periodsRemaining: 0,
      funder: "BNP Paribas",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 23,
      ref: "A1B32831",
      status: "Live",
      customer: "Adams & Remers Llp",
      supplier: "Konica Minolta UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "05/19/2019",
      endDate: "",
      payment: 1788.02,
      term: "Monthly",
      periodsRemaining: 3,
      funder: "BNP Paribas",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 24,
      ref: "A1B12940",
      status: "Live",
      customer: "Africa Collection UK Ltd",
      supplier: "Konica Minolta UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "07/03/2019",
      endDate: "",
      payment: 110.9,
      term: "Monthly",
      periodsRemaining: 7,
      funder: "Macquarie",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
    {
      id: 25,
      ref: "A1C39669",
      status: "Live",
      customer: "Aksward Limited",
      supplier: "Konica Minolta UK Limited",
      spv: "IPS SPV I LTD",
      startDate: "05/07/2019",
      endDate: "",
      payment: 2943.56,
      term: "Monthly",
      periodsRemaining: 4,
      funder: "Macquarie",
      funderType: "Funder",
      paymentProfile: "In Advance",
      rateType: "FMV",
      currentScheduleStatus: "Live",
    },
  ],
};
