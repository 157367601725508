import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { Button, Icon } from "semantic-ui-react";

import { createDealerStructure } from "./actions";
import DealerStructureForm from "./DealerStructureForm";

const AddDealerStructure = ({
  data,
  onRefresh,
  dealerStructureId,
  parentId,
  dealerId,
  fullCrmCode,
}) => (
  <ModalBuilder
    withForm
    submitAction={createDealerStructure}
    submitParams={{ dealerStructureId, parentId, dealerId }}
    initialData={data}
    onSubmitted={onRefresh}
    renderTrigger={(showModal) => (
      <Button className="new-btn blue" onClick={showModal}>
        <Icon name="add" />
        {"New Territory"}
      </Button>
    )}
    renderModal={(modalProps, formProps) => (
      <Modal.Add {...modalProps} header="Create Territory" submitLabel="Save">
        <DealerStructureForm
          {...formProps}
          fullCrmCode={`${fullCrmCode}-${data.crmId}`}
        />
      </Modal.Add>
    )}
  />
);

export default AddDealerStructure;
