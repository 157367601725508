import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { BreadcrumbBar } from "pages/nav";
import { PriceIncrease } from "features/Schedules/PriceIncrease";

const PriceIncreasePage = () => (
  <PageContent fluid className="schedules-container">
    <BreadcrumbBar />
    <PriceIncrease />
  </PageContent>
);

export default PriceIncreasePage;
