import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";

import { editDealerOffice } from "./actions";
import DealerOfficeForm from "./DealerOfficeForm";

const EditDealerOffice = ({ data, onRefresh }) => (
  <ModalBuilder
    withForm
    submitAction={editDealerOffice}
    submitParams={data.id}
    initialData={data}
    onSubmitted={onRefresh}
    renderTrigger={(showModal) => (
      <Button
        icon="pencil alternate"
        className="delete-icon-btn"
        color="blue"
        onClick={showModal}
      />
    )}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit
        {...modalProps}
        header="Edit Supplier Office"
        submitLabel="Save"
        size="large"
      >
        <DealerOfficeForm
          {...formProps}
          fullCrmCode={data.fullCrmCode}
          className="supplier-office-form-modal"
        />
      </Modal.Edit>
    )}
  />
);

export default EditDealerOffice;
