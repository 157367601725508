import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "LARGE_FORMAT_SERVICES";

const ActionTypes = {
  GetLargeFormatServiceList: `${NAMESPACE}/GET_LIST`,
};

export const getLargeFormatServices = (
  { filters, pagination },
  { dealerOfficeId }
) => {
  return apiGet(
    ActionTypes.GetLargeFormatServiceList,
    `large-format/services`,
    {
      ...pagination,
      ...filters,
      dealerOfficeId,
    }
  );
};
