import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { deleteBoilerPlateTandCs } from "./actions";

const RemoveBoilerPlateModal = ({
  itemId,
  dealerId,
  onDeleted,
  reference,
  ...props
}) => {
  return (
    <ModalBuilder
      submitAction={deleteBoilerPlateTandCs}
      submitParams={{
        itemId: itemId,
        dealerId: dealerId,
      }}
      onSubmitted={onDeleted}
      renderTrigger={showModal => (
        <div {...props} onClick={showModal} className="item">
          Delete
        </div>
      )}
      renderModal={modalProps => (
        <Modal.Confirmation {...modalProps} header={"Delete: " + reference}>
          {reference + ": Delete Boiler Plate T&C's?"}
        </Modal.Confirmation>
      )}
    />
  );
};

export default RemoveBoilerPlateModal;
