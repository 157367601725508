import React from "react";
import { Grid, Button, Table } from "semantic-ui-react";
import { FormBuilder, Form, Loadable } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { Targets, Actions } from "constants/permissions";
import { setPaperInclusiveRestrictions } from "./actions";
import { getDefaultsRestrictions } from "..";

const PaperInclusiveRestrictions = ({ settingsId }) => {
  return (
    <FormBuilder
      initialReadOnly
      loadAction={getDefaultsRestrictions}
      loadParams={{ settingsId }}
      submitAction={setPaperInclusiveRestrictions}
      submitParams={{ settingsId }}
      loadTransform={(data) => ({
        ...data.paperInclusive,
        ...data.paperInclusiveDefaults,
      })}
      renderForm={(formProps, state, events) => {
        return (
          <React.Fragment>
            {!state.error && (
              <Loadable
                loading={state.loading || state.submitting}
                className="cspi"
              >
                <Form
                  {...formProps}
                  disabled={state.submitting || state.readOnly}
                >
                  <div className="horizontal-scrollbar-padded">
                    <div className="extra-settings-panel-top">
                      <div className="extra-settings-panel-columns paper">
                        <div className="content-panel outline-grid">
                          <div className="office-general-settings">
                            <h4 className="settings-title">
                              Cut Sheet Paper Inclusive
                            </h4>
                            <div className="content-container principal-list ">
                              <div className="action-bar-div">
                                <div className="cin action-bar-item">
                                  <PermissionCheck
                                    target={Targets.DefaultsRestrictions}
                                    action={Actions.Edit}
                                  >
                                    <div className="flex">
                                      {!state.readOnly && (
                                        <Button
                                          primary
                                          className="cancel-title-action"
                                          floated="right"
                                          content="Cancel"
                                          onClick={events.onReset}
                                        />
                                      )}
                                      <IconButton
                                        primary
                                        icon={state.readOnly ? Pen : Save}
                                        content={
                                          state.readOnly ? "Edit" : "Save"
                                        }
                                        floated="right"
                                        onClick={() =>
                                          state.readOnly
                                            ? events.onToggleReadOnly()
                                            : events.onSubmit({
                                                reset: ["readOnly"],
                                              })
                                        }
                                        className={
                                          !state.readOnly
                                            ? "positive-title-action"
                                            : null
                                        }
                                      />
                                    </div>
                                  </PermissionCheck>
                                </div>
                              </div>
                            </div>
                            <div className="defaults-grid fee-struct-table">
                              <Grid columns={14}>
                                <Grid.Row className="row">
                                  <Grid.Column width={6}>
                                    <Form.Checkbox
                                      field="availableToDealer"
                                      label="Available To Supplier"
                                      readOnly={state.readOnly}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column width={2}></Grid.Column>
                                  <Grid.Column width={10}>
                                    <Form.RadioGroup
                                      field="availableToAll"
                                      readOnly={state.readOnly}
                                      options={[
                                        {
                                          value: false,
                                          text: "Selectable by IPS/Supplier Admin view Only as an option)",
                                        },
                                        {
                                          value: true,
                                          text: "Selectable by All Views as an option)",
                                        },
                                      ]}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="row">
                                  <Grid.Column width={2}>Defaults</Grid.Column>
                                  <Grid.Column width={3}>
                                    <Form.RadioGroup
                                      field="singleClickA3"
                                      readOnly={state.readOnly}
                                      options={[
                                        {
                                          value: false,
                                          text: "Double Click A3",
                                        },
                                        {
                                          value: true,
                                          text: "Single Click A3",
                                        },
                                      ]}
                                    />
                                  </Grid.Column>
                                  <Grid.Column width={9}>
                                    <Form.Checkbox
                                      field="singleClickA3AvailableToDealerOnly"
                                      label="Selectable by IPS/Supplier Admin VIew Only"
                                      readOnly={state.readOnly}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                              <br />
                              <br />
                            </div>

                            <div className="pis-billing">
                              <Form.Checkbox
                                field="priceIncreaseApplicable"
                                label="Price Increase Applicable"
                              />

                              <Form.Input
                                field="billingCode"
                                label="Billing Code:"
                                maxLength={20}
                                width={20}
                                inline
                              />
                            </div>

                            <div className="pir-markups-dynamic-terms-container">
                              <div className="defaults-grid fee-struct-table pir-markups">
                                <Table unstackable>
                                  <Table.Header className="left-align">
                                    <Table.Row>
                                      <Table.HeaderCell
                                        style={{ width: "60%" }}
                                      >
                                        <b>Markups</b>
                                      </Table.HeaderCell>
                                      <Table.HeaderCell
                                        style={{ width: "40%" }}
                                      ></Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  <Table.Body>
                                    <Table.Row>
                                      <Table.Cell className="left-align">
                                        A4 Only Markup (%)
                                      </Table.Cell>
                                      <Table.Cell className="left-align">
                                        <Form.Numeric
                                          field="standardMarkup"
                                          readOnly={state.readOnly}
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                      <Table.Cell className="left-align">
                                        A3 Only Markup (%)
                                      </Table.Cell>
                                      <Table.Cell className="left-align">
                                        <Form.Numeric
                                          field="a3Markup"
                                          readOnly={state.readOnly}
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                      <Table.Cell className="left-align">
                                        <br />
                                      </Table.Cell>
                                      <Table.Cell className="left-align"></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                      <Table.Cell className="left-align">
                                        <b>Delivery</b>
                                      </Table.Cell>
                                      <Table.Cell className="left-align"></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                      <Table.Cell className="left-align">
                                        Minimum monthly boxes without delivery
                                        charge
                                      </Table.Cell>
                                      <Table.Cell className="left-align">
                                        <Form.Numeric
                                          field="minMonthlyBoxesWithoutDeliveryCharge"
                                          readOnly={state.readOnly}
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                      <Table.Cell className="left-align">
                                        Quarterly delivery charge for below min
                                        boxes {process.env.MAJOR_CURRENCY}
                                      </Table.Cell>
                                      <Table.Cell className="left-align">
                                        <Form.Numeric
                                          field="quarterlyDeliveryChargeForBelowMinBoxes"
                                          readOnly={state.readOnly}
                                          decimalPlaces={2}
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                      <Table.Cell className="left-align">
                                        Quarterly delivery charge over min boxes{" "}
                                        {process.env.MAJOR_CURRENCY}
                                      </Table.Cell>
                                      <Table.Cell className="left-align">
                                        <Form.Numeric
                                          field="quarterlyMinDeliveryChargeOverMinBoxes"
                                          readOnly={state.readOnly}
                                          decimalPlaces={2}
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                  </Table.Body>
                                </Table>
                              </div>
                              <div className="pir-dynamic-terms">
                                <Form.TextArea
                                  field="dynamicTerms"
                                  label="Dynamic Terms"
                                  width="100%"
                                  placeholder="Dynamic Terms..."
                                ></Form.TextArea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </Loadable>
            )}
          </React.Fragment>
        );
      }}
    />
  );
};

export default PaperInclusiveRestrictions;
