import React from "react";
import { apiDelete, apiGet, apiPut, Form } from "@redriver/cinnamon";

const NAMESPACE = "DEALERS";
const ActionTypes = {
  GetDealer: `${NAMESPACE}/GET_DEALER`,
  GetDealerHeader: `${NAMESPACE}/GET_DEALER_HEADER`,
  DeleteDealer: `${NAMESPACE}/DELETE_DEALER`,
  UpdateDealer: `${NAMESPACE}/UPDATE_DEALER`,
  DealerStatus: `${NAMESPACE}/DEALER_STATUS`,
  GetDealerRequestEmails: `${NAMESPACE}/GET_DEALER_REQUEST_EMAILS`,
  DeleteDealerRequestEmails: `${NAMESPACE}/DELETE_DEALER_REQUEST_EMAILS`,
  UpdateDealerRequestEmails: `${NAMESPACE}/UPDATE_DEALER_REQUEST_EMAILS`,
  GetDealerAddendumDocumentNumbers: `${NAMESPACE}/GET_DEALER_ADDENDUM_DOC_NUMBERS`,
  UpdateDealerAddendumDocumentNumbers: `${NAMESPACE}/UPDATE_DEALER_ADDENDUM_DOC_NUMBERS`,
};

export const dealerStatusLookup = (params) =>
  apiGet(ActionTypes.DealerStatus, `lookups/dealer-status`, params);

export const DealerStatusDropdown = ({ className, ...props }) => (
  <div className={className}>
    <Form.Dropdown {...props} lookupAction={dealerStatusLookup} />
  </div>
);

export const deleteDealer = (id) =>
  apiDelete(ActionTypes.DeleteDealer, `dealers/${id}`);

export const getDealer = ({ id }) =>
  apiGet(ActionTypes.GetDealer, `dealers/${id}`);
export const getDealerHeader = ({ id }) =>
  apiGet(ActionTypes.GetDealerHeader, `dealers/${id}/header`);
export const getDealerRequestEmails = ({ id }) =>
  apiGet(ActionTypes.GetDealerRequestEmails, `dealers/${id}/request-emails`);

export const updateDealer = (formData, id) => {
  return apiPut(ActionTypes.UpdateDealer, `dealers/${id}`, formData);
};
export const updateDealerRequestEmails = (formData, id) => {
  return apiPut(
    ActionTypes.UpdateDealerRequestEmails,
    `dealers/${id}/request-emails`,
    formData,
  );
};

export const getDealerAddendumDocumentNumbers = ({ id }) =>
  apiGet(
    ActionTypes.GetDealerAddendumDocumentNumbers,
    `dealers/${id}/addendum-doc-numbers`,
  );

export const updateDealerAddendumDocumentNumbers = (formData, id) =>
  apiPut(
    ActionTypes.GetDealerAddendumDocumentNumbers,
    `dealers/${id}/addendum-doc-numbers`,
    formData,
  );
