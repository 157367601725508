import React from "react";
import { registerLookup, apiGet } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULES/LOOKUPS";

const ActionTypes = {
  GetScheduleStatus: `${NAMESPACE}/GET_SCHEDULE_STATUS`,
};

export const scheduleStatusLookup = "scheduleStatusLookup";
registerLookup(
  scheduleStatusLookup,
  () => apiGet(ActionTypes.GetScheduleStatus, "lookups/schedule-status"),
  {
    transform: (data) =>
      data.map((x) => {
        return {
          value: x.value,
          text: x.text,
        };
      }),
  }
);
