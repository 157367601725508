import { apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM";

const ActionTypes = {
  StartMaintenancePeriod: `${NAMESPACE}/START_MAINTENANCE_PERIOD`,
  StopMaintenancePeriod: `${NAMESPACE}/STOP_MAINTENANCE_PERIOD`,
};

export const startMaintenancePeriod = () =>
  apiPut(ActionTypes.StartMaintenancePeriod, "system/start-maintenance-period");

export const stopMaintenancePeriod = () =>
  apiPut(ActionTypes.StopMaintenancePeriod, "system/stop-maintenance-period");
