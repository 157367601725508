import React, { useState } from "react";
import { Table, ModalBuilder, Form } from "@redriver/cinnamon";
import { Targets, Actions } from "constants/permissions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { updateUsageType } from "./actions";
import { TranslatedModal } from "../../../../components/modals";
import { useTranslation } from "react-i18next";
import { Image } from "semantic-ui-react";

const EditUsageType = ({ data, onSubmitted }) => {
  const [imageData, setImageData] = useState(null);
  const { t } = useTranslation("supplierOffices", {
    keyPrefix: "largeFormat.usageType",
  });

  const onChangeImage = file => {
    let fileReader = new FileReader();
    fileReader.onload = e => {
      const res = fileReader.result;
      setImageData(res);
    };
    fileReader.readAsDataURL(file);
  };

  return (
    <PermissionCheck target={Targets.LargeFormat} action={Actions.Edit}>
      <ModalBuilder
        withForm
        initialData={data}
        submitAction={updateUsageType}
        submitParams={{
          id: data.id,
          imageData: imageData,
        }}
        onSubmitted={onSubmitted}
        renderTrigger={open => (
          <Table.MenuItem onClick={open}>Edit</Table.MenuItem>
        )}
        renderModal={(modalProps, formProps) => (
          <TranslatedModal.Edit
            {...modalProps}
            header="Edit Usage Type"
            onClick={event => event && event.stopPropagation()}
          >
            <Form {...formProps}>
              <div className="usage-type-edit-modal">
                <div>
                  <Form.FileUpload
                    field="image"
                    label={t("image")}
                    replaceText={t("uploadReplaceText")}
                    uploadText={t("uploadText")}
                    fileExtensionsTypes={["png", "jpg", "jpeg", "svg"]}
                    fileTypesError={t("image") + " " + t("uploadError")}
                    customValidator={(_, __, ___, { value }) => {
                      !!value.image && onChangeImage(value.image);
                    }}
                  />
                </div>
                <div className="usage-type-image">
                  <Image src={data.imageUri} />
                </div>
              </div>
            </Form>
          </TranslatedModal.Edit>
        )}
      />
    </PermissionCheck>
  );
};

export default EditUsageType;
