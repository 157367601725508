import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Button } from "semantic-ui-react";
import {
  getAuthState,
  getAuthClaims,
  getAuthOptions,
  FormBuilder,
  Form,
  ErrorMessage,
} from "@redriver/cinnamon";
import { requestLogin } from "./actions";
import { EmailVerificationResender } from "../EmailVerification";
import { SystemRoutes } from "constants/routes";

const loginErrors = {
  915004: "Incorrect email address or password",
  915005: "Account is inactive",
};

const Login = ({ loggedIn, emailVerified, useEmailVerification }) => {
  const history = useHistory();
  const location = useLocation();
  const [userEmail, setUserEmail] = useState("");

  if (loggedIn) {
    if (emailVerified || !useEmailVerification) {
      return <p>You have been successfully signed in</p>;
    }

    return (
      <div>
        <p>Your email address has not been verified</p>
        <EmailVerificationResender />
      </div>
    );
  }

  return (
    <div className="login-form">
      <FormBuilder
        submitAction={requestLogin}
        onSubmitted={({ secondFactor, tokens }) => {
          if ((secondFactor?.availableTypes ?? []).length > 0) {
            const { registeredTypes, availableTypes, recoveryTypes, token } =
              secondFactor;
            const nextPath = location.state?.nextPath;
            history.push(SystemRoutes.Authenticate, {
              registeredTypes,
              availableTypes,
              recoveryTypes,
              token,
              loginTokens: tokens,
              nextPath,
              userEmail,
            });
          }
        }}
        onChange={(value) => setUserEmail(value.email)}
        renderSubmitting={false}
        renderError={false}
        renderForm={(
          formProps,
          { submitting, slowSubmitting, error },
          events
        ) => (
          <React.Fragment>
            <h3>Welcome Back</h3>
            <h2>Log In</h2>
            <Form {...formProps} className="login-form">
              <Form.Email
                field="email"
                label="Email"
                placeholder="Enter email..."
                disabled={submitting && slowSubmitting}
                autoFocus
                required
                fluid
              />
              <Form.Password
                field="password"
                label="Password"
                placeholder="Enter password..."
                disabled={submitting && slowSubmitting}
                required
                fluid
              >
                <input autoComplete="current-password" />
              </Form.Password>
              <Button
                className="action-button"
                onClick={events.onSubmit}
                disabled={submitting}
                loading={submitting && slowSubmitting}
                fluid
              >
                Sign In
              </Button>
              <ErrorMessage
                header="Sign In Error"
                error={error}
                overrideMessages={loginErrors}
                omitCodes
              />
              <div className="forgot-password">
                <span>Forgotten password?</span>
                <Link to={SystemRoutes.ForgottenPassword}>Reset it</Link>
              </div>
            </Form>
          </React.Fragment>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loggedIn } = getAuthState(state);
  const { emailVerified } = getAuthClaims(state);
  const { useEmailVerification } = getAuthOptions();
  return {
    loggedIn,
    emailVerified,
    useEmailVerification,
  };
};

export default connect(mapStateToProps)(Login);
