import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/DEFAULTS";

export const ActionTypes = {
  GetDefaults: `${NAMESPACE}/GET`,
  SetDefaults: `${NAMESPACE}/SET`,
};

export const getDefaults = ({ settingsId }) => {
  return settingsId
    ? apiGet(ActionTypes.GetDefaults, `defaults/${settingsId}`)
    : {};
};

export const setDefaults = (defaults, settingsId) => {
  const settings = {
    capital: {
      funderType: defaults.funderType,
      paymentMethod: defaults.paymentMethod,
      financeType: defaults.financeType,
      maximumFinanceSelectionValue: defaults.maximumFinanceSelectionValue,
      minimumFinanceSelectionValue: defaults.minimumFinanceSelectionValue,
      defaultFinanceSelectionValue: defaults.defaultFinanceSelectionValue,
      defaultProductCapitalCostValue: defaults.defaultProductCapitalCostValue,
      funderDefaultRRPValue: defaults.funderDefaultRRPValue,
      funderDefaultProductCapital: defaults.funderDefaultProductCapital,
      cofundedDefaultRRPValue: defaults.cofundedDefaultRRPValue,
      cofundedDefaultProductCapital: defaults.cofundedDefaultProductCapital,
      supplierDefaultRRPValue: defaults.supplierDefaultRRPValue,
      supplierDefaultProductCapital: defaults.supplierDefaultProductCapital,
      defaultSupplierNetPercentage: defaults.defaultSupplierNetPercentage,
    },
  };

  return apiPut(ActionTypes.SetDefaults, `defaults/${settingsId}`, settings);
};
