import React, { forwardRef } from "react";
import AccordionHelper from "components/displays/AccordionHelper";
import { Grid, Button, Table } from "semantic-ui-react";
import { FormBuilder, Form, Loadable } from "@redriver/cinnamon";
import { PermissionCheck } from "../../../../../../../shared/components/auth";
import { IconButton } from "../../../../../../../shared/components/buttons";
import Pen from "../../../../../../../shared/assets/pen.svg";
import Save from "../../../../../../..//shared/assets/save.svg";
import { Targets, Actions } from "constants/permissions";
import { getDefaultsRestrictions } from "../RestrictionSettings";
import { setLFPRestrictions } from "./actions";

const LFPSettings = forwardRef(
  ({ settingsId, toggleVisibility, collapsed }) => (
    <React.Fragment>
      <AccordionHelper
        title="Large Format Printing"
        toggleVisibility={toggleVisibility}
        collapsed={collapsed}
      >
        <LFPRestrictions settingsId={settingsId} />
      </AccordionHelper>
    </React.Fragment>
  )
);

const LFPRestrictions = ({ settingsId }) => {
  return (
    <FormBuilder
      initialReadOnly
      loadAction={getDefaultsRestrictions}
      loadParams={{ settingsId }}
      submitAction={setLFPRestrictions}
      submitParams={{ settingsId }}
      loadTransform={(data) => ({
        ...data.largeFormat,
        optionsSelectableByAdmins:
          data.largeFormat.optionsSelectableByAdmins.filter(
            (x) => x.name !== "None"
          ),
        largeFormatPriceSettings: data.largeFormatPriceSettings,
      })}
      renderForm={(formProps, state, events) => {
        return (
          <React.Fragment>
            {!state.error && (
              <Loadable
                loading={state.loading || state.submitting}
                className="lfp "
              >
                <Form
                  {...formProps}
                  disabled={state.submitting || state.readOnly}
                >
                  <div className="horizontal-scrollbar-padded">
                    <div className="extra-settings-panel-top">
                      <div className="extra-settings-panel-columns paper">
                        <div className="content-panel outline-grid">
                          <div className="office-general-settings">
                            <h4 className="settings-title">
                              Large Format Printing
                            </h4>
                            <div className="content-container principal-list ">
                              <div className="action-bar-div">
                                <div className="cin action-bar-item">
                                  <PermissionCheck
                                    target={Targets.DefaultsRestrictions}
                                    action={Actions.Edit}
                                  >
                                    <div className="flex">
                                      {!state.readOnly && (
                                        <Button
                                          primary
                                          className="cancel-title-action"
                                          floated="right"
                                          content="Cancel"
                                          onClick={events.onReset}
                                        />
                                      )}
                                      <IconButton
                                        primary
                                        icon={state.readOnly ? Pen : Save}
                                        content={
                                          state.readOnly ? "Edit" : "Save"
                                        }
                                        floated="right"
                                        onClick={() =>
                                          state.readOnly
                                            ? events.onToggleReadOnly()
                                            : events.onSubmit({
                                                reset: ["readOnly"],
                                              })
                                        }
                                        className={
                                          !state.readOnly
                                            ? "positive-title-action"
                                            : null
                                        }
                                      />
                                    </div>
                                  </PermissionCheck>
                                </div>
                              </div>
                            </div>
                            <div className="defaults-grid fee-struct-table">
                              <Grid columns={14}>
                                <Grid.Row className="row">
                                  <Grid.Column width={6}>
                                    <Form.Checkbox
                                      field="availableToDealer"
                                      label="Available To Supplier"
                                      readOnly={state.readOnly}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Column width={2}></Grid.Column>
                                  <Grid.Column width={10}>
                                    <Form.RadioGroup
                                      field="availableToAll"
                                      readOnly={state.readOnly}
                                      options={[
                                        {
                                          value: false,
                                          text: "Selectable by IPS/Supplier Admin View Only as an option",
                                        },
                                        {
                                          value: true,
                                          text: "Selectable by All Views as an option",
                                        },
                                      ]}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row className="row">
                                  <Grid.Column width={2}>Defaults</Grid.Column>
                                  <Grid.Column width={3}>
                                    <Form.RadioGroup
                                      field="defaultValue"
                                      readOnly={state.readOnly}
                                      options={[
                                        { value: "PriceA", text: "Price A" },
                                        { value: "PriceB", text: "Price B" },
                                      ]}
                                    />
                                  </Grid.Column>
                                  <Grid.Column width={9}>
                                    <Form.Array
                                      field="optionsSelectableByAdmins"
                                      arrayKey="id"
                                    >
                                      <Form.Checkbox
                                        field="selected"
                                        label="Selectable by IPS/Supplier Admin View Only"
                                        readOnly={state.readOnly}
                                      />
                                    </Form.Array>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </div>

                            <br />
                            <br />
                            <div
                              className="defaults-grid fee-struct-table"
                              style={{ maxWidth: "600px" }}
                            >
                              <Table unstackable>
                                <Form.Array
                                  field="largeFormatPriceSettings"
                                  arrayKey="id"
                                >
                                  {({ fields }) => (
                                    <React.Fragment>
                                      <Table.Header className="left-align">
                                        <Table.Row>
                                          <Table.HeaderCell width={10}>
                                            <b>
                                              {fields.largeFormatPriceType.replace(
                                                "Price",
                                                "Price "
                                              )}
                                            </b>
                                          </Table.HeaderCell>
                                          <Table.HeaderCell width={6}>
                                            %
                                          </Table.HeaderCell>
                                        </Table.Row>
                                      </Table.Header>
                                      <Table.Body>
                                        <Table.Row>
                                          <Table.Cell className="left-align">
                                            LF Ink & Maintenance
                                          </Table.Cell>
                                          <Table.Cell className="left-align">
                                            <Form.Input field="inkPercentage" />
                                          </Table.Cell>
                                        </Table.Row>
                                        <Table.Row className="left-align">
                                          <Table.Cell className="left-align">
                                            LF Scan
                                          </Table.Cell>
                                          <Table.Cell className="left-align">
                                            <Form.Input field="scanPercentage" />
                                          </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                          <Table.Cell className="left-align">
                                            LF Cleaning
                                          </Table.Cell>
                                          <Table.Cell className="left-align">
                                            <Form.Input field="cleaningPercentage" />
                                          </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                          <Table.Cell></Table.Cell>
                                          <Table.Cell>
                                            <br />
                                          </Table.Cell>
                                        </Table.Row>
                                      </Table.Body>
                                    </React.Fragment>
                                  )}
                                </Form.Array>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </Loadable>
            )}
          </React.Fragment>
        );
      }}
    />
  );
};

export default LFPSettings;
