import React from "react";
import { Pagination, Image } from "semantic-ui-react";
import Arrow from "assets/arrowLeft.svg";

class PageNumbers extends React.Component {
  onPageChange = (e, { activePage }) =>
    this.props.onChange({
      pageNumber: activePage,
      pageSize: this.props.pageSize,
    });

  render() {
    const { activePage, pageSize, totalItems } = this.props;
    const totalPages = totalItems > 0 ? Math.ceil(totalItems / pageSize) : 0;

    if (totalPages === 0) return null;

    return (
      <Pagination
        activePage={activePage}
        onPageChange={this.onPageChange}
        totalPages={totalPages}
        size="tiny"
        firstItem={null}
        lastItem={null}
        prevItem={{
          content: <Image src={Arrow} alt="PREV" aria-hidden="previous page" />,
          icon: true,
          disabled: activePage <= 1,
        }}
        nextItem={{
          content: <Image src={Arrow} alt="NEXT" aria-hidden="next page" />,
          icon: true,
          disabled: activePage >= totalPages,
        }}
      />
    );
  }
}

export default PageNumbers;
