import React from "react";
import { ContentPanel } from "components/displays";
import { Form } from "@redriver/cinnamon";
import { Button, Grid, GridColumn, GridRow } from "semantic-ui-react";

const CFACharge = ({ cfaCharge }) => {
  return (
    <ContentPanel
      title="CFA Charge"
      wrapperCssClass="outline-grid"
      headerCssClass="defaults-page-title"
      headerContent={  <Button positive content="Actions" />}
    >
      <div className="spaced cfa-charge">
        <br/>
        <Form value={cfaCharge}>
          <Grid columns={2} doubling>
            <GridRow>
              <GridColumn>
   
                  <Form.Input
                    field="cfaReference"
                     subLabel="CFA Reference:"
                     readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="funder"
                     subLabel="Funder:"
                     readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="ipsCode"
                     subLabel="IPS Code:"
                    readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="funderType"
                     subLabel="Funder Type:"
                    readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="associatedCfa"
                     subLabel="Associated CFA:"
                    readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="paymentMethod"
                     subLabel="Payment Method:"
                    readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="paymentProfile"
                     subLabel="Payment Profile:"
                    readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="rateType"
                     subLabel="Rate Type:"
                    readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="cfaStatus"
                     subLabel="CFA Status:"
                    readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="cfaStartDate"
                     subLabel="CFA Start Date:"
                    readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="cfaEndDate"
                     subLabel="CFA End Date (last quarter start date):"
                    readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="currency"
                     subLabel="Currency:"
                    readOnly={false} 
                     disabled={true}
                  />

       
              </GridColumn>
              <GridColumn>
        
                <Form.Input
                    field="paymentDate"
                     subLabel="Payment Date:"
                    readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="continuingPayment"
                     subLabel="Continuing Payment:"
                    readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="ipsPropNumber"
                     subLabel="IPS Prop Number:"
                    readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="initialScheduleRef"
                     subLabel="Initial Schedule Ref:"
                    readOnly={false} 
                     disabled={true}
                  />
                  <Form.Input
                    field="currentScheduleRef"
                     subLabel="Current Schedule Ref:"
                    readOnly={false} 
                     disabled={true}
                  />
                  <div className="ui labeled input custom-sublabel">
                                            <div className="ui label label">Notes</div>
                  <Form.TextArea
                    field="notes"
                     subLabel="Notes:"
                     readOnly={false} 
                     disabled={true}
                  />
                  
                </div>
                <br/><br/>
              </GridColumn>
            </GridRow>
          </Grid>
        </Form>
      </div>
    </ContentPanel>
  );
};

export default CFACharge;
