import React from "react";
import ProductsSteps from "constants/enums/ProductsSteps";
import { ContentBuilder, Form, Wizard } from "@redriver/cinnamon";
import { Menu } from "semantic-ui-react";
import { ContentPanel } from "components/displays";

import Summary from "./Summary/Summary";
import Movement from "./Movement/Movement";
import AuditTrail from "./AuditTrail/AuditTrail";
import MeterReading from "./MeterReading/MeterReading";
import Header from "./Header";

import { getProductSummary } from "./actions";

const ManageProduct = ({ productId }) => {
  const NavigationControls = ({ steps, activeStep, onGoToStep }) => {
    return steps.map((s) => (
      <Menu.Item
        value={0}
        key={s.key}
        active={s === activeStep}
        onClick={() => onGoToStep(s.key)}
      >
        {s.title}
      </Menu.Item>
    ));
  };

  const stepData = [
    {
      key: ProductsSteps.Summary,
      title: "Summary",
    },
    {
      key: ProductsSteps.Movement,
      title: "Movement",
    },
    {
      key: ProductsSteps.MeterReading,
      title: "Meter Reading",
    },
    {
      key: ProductsSteps.AuditTrail,
      title: "Audit Trail",
    },
  ];

  return (
    <div className="padded">
      <ContentBuilder
        loadAction={getProductSummary}
        renderContent={(data) =>
          data && (
            <div className="products">
              <Header data={data} />
              <br />
              <ContentPanel
                wrapperCssClass="outline-grid "
                headerCssClass="defaults-page-title header"
                headerContent={
                  <React.Fragment>
                    <Form value={data} disabled>
                      <h2>Identifier Number</h2>
                      <Form.Input field="identifier" />
                      <h2>Company</h2>
                      <Form.Input field="companyName" />
                    </Form>
                  </React.Fragment>
                }
              >
                <div className="padded">
                  <Wizard steps={stepData}>
                    <div className="products-wizard-navigation">
                      <Wizard.Nav
                        render={(wizardContext) => (
                          <Menu>
                            <NavigationControls {...wizardContext} />
                          </Menu>
                        )}
                      />
                    </div>
                    <Wizard.Step
                      step={ProductsSteps.Summary}
                      render={() => <Summary productId={productId} />}
                    />
                    <Wizard.Step
                      step={ProductsSteps.Movement}
                      render={() => <Movement productId={productId} />}
                    />
                    <Wizard.Step
                      step={ProductsSteps.MeterReading}
                      render={() => <MeterReading productId={productId} />}
                    />
                    <Wizard.Step
                      step={ProductsSteps.AuditTrail}
                      render={() => <AuditTrail />}
                    />
                  </Wizard>
                </div>
              </ContentPanel>
            </div>
          )
        }
      />
    </div>
  );
};

export default ManageProduct;
