import React from "react";
import { ModalBuilder, Modal, Lookup } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { IconButton } from "features/../../../shared/components/buttons";
import Add from "features/../../../shared/assets/white-add.svg";
import { LookupTypes } from "features/../../../shared/features/Settings";
import MeteredServiceForm from "./MeteredServiceForm";

const AddMeteredServiceModal = ({
  submitAction,
  onSubmitted,
  dealerOfficeId,
}) => (
  <PermissionCheck target={Targets.Service} action={Actions.Create}>
    <Lookup
      lookup={LookupTypes.ContractTypes}
      render={({ response: contractTypeResponse }) => (
        <ModalBuilder
          withForm
          submitAction={submitAction}
          submitParams={{ dealerOfficeId }}
          onSubmitted={onSubmitted}
          renderTrigger={(showModal) => (
            <IconButton
              icon={Add}
              primary
              content="New Metered Service"
              onClick={showModal}
            />
          )}
          renderModal={(modalProps, formProps) => (
            <Modal.Add
              {...modalProps}
              size="small"
              header="Add Metered Service"
              submitLabel="Create Metered Service"
            >
              <MeteredServiceForm
                formProps={formProps}
                contractTypeResponse={contractTypeResponse}
                dealerOfficeId={dealerOfficeId}
              />
            </Modal.Add>
          )}
        />
      )}
    />
  </PermissionCheck>
);

export default AddMeteredServiceModal;
