import React from "react";
import moment from "moment";
import { Grid } from "semantic-ui-react";
import { SidePanel, Form } from "@redriver/cinnamon";
import { FilterButton } from "features/../../../shared/components/buttons";
import { DealersTypeAheadLookup } from "features/../../../shared/features/Dealers/lookups";
import { DealerOfficesTypeAheadLookup } from "features/../../../shared/features/Settings";

export const FieldNames = {
  Supplier: "supplier",
  SupplierOffice: "supplierOffice",
  StartDate: "startDate",
  EndDate: "endDate",
};

const PriceIncreaseFilters = ({ title, formProps, onReset }) => {
  return (
    <SidePanel
      closeIcon
      trigger={<FilterButton />}
      className="price-increase-filter-side-panel"
    >
      <div>
        <div className="ui header filters-title">
          <div className="title-text">{title}</div>
          <div className="clear-button" onClick={onReset}>
            (clear)
          </div>
        </div>
        <Form {...formProps} className="highlighted-inputs">
          <div className="ui form highlighted-inputs">
            <Grid columns={2}>
              <Grid.Column width={16}>
                <DealersTypeAheadLookup
                  field={FieldNames.Supplier}
                  fluid
                  clearable
                  multiple
                  label="Supplier"
                  placeholder="Select suppliers..."
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <DealerOfficesTypeAheadLookup
                  field={FieldNames.SupplierOffice}
                  fluid
                  clearable
                  multiple
                  label="Supplier Office"
                  placeholder="Select supplier offices..."
                />
              </Grid.Column>
              <Grid.Column>
                <div className="flex align-center date-container">
                  <Form.Date
                    dateFormat="DD MMM YYYY"
                    local
                    field={FieldNames.StartDate}
                    label="From"
                    className="date-input highlighted-input"
                    maxDate={
                      formProps.value && formProps.value[FieldNames.EndDate]
                        ? moment(formProps.value[FieldNames.EndDate])
                        : null
                    }
                  />
                  <Form.Clearer field={FieldNames.StartDate} />
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="flex align-center date-container">
                  <Form.Date
                    dateFormat="DD MMM YYYY"
                    local
                    field={FieldNames.EndDate}
                    label="To"
                    className="date-input highlighted-input"
                    minDate={
                      formProps.value && formProps.value[FieldNames.StartDate]
                        ? moment(formProps.value[FieldNames.StartDate])
                        : null
                    }
                  />
                  <Form.Clearer field={FieldNames.EndDate} />
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </Form>
      </div>
    </SidePanel>
  );
};

export default PriceIncreaseFilters;
