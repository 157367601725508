import React, { useState } from "react";
import { Button, Icon, Table } from "semantic-ui-react";
import { Format, Form, ListBuilder } from "@redriver/cinnamon";
import { getScheduleCharges, getAdditionalCharges } from "./actions";
import { numericFormatter } from "components/displays/DisplayHelper";
import { InfoModalWrapper } from "components/modals/";
import { ContentPanel } from "components/displays";
import { getScheduleChargesTotalSplit } from ".";
import {
  SortableHeader,
  SimpleTableBuilder,
} from "features/../../../shared/components/tables/sortableTable";
import { AddButton } from "features/../../../shared/components/buttons";

const chargesHeaders = [
  { field: "seq", title: "Seq", width: null },
  { field: "billingCode", title: "Invoice Code", width: null },
  { field: "description", title: "Description", width: null },
  { field: "dueDate", title: "Due Date", width: null },
  {
    field: "value",
    title: `Value (${process.env.MAJOR_CURRENCY})`,
    width: null,
  },
  {
    field: "capital",
    title: `Capital (${process.env.MAJOR_CURRENCY})`,
    width: null,
  },
];

const additionalChargesColumns = [
  { title: "Seq", field: "seq", width: null },
  { title: "Billing Code", field: "chargeCode", width: null },
  { title: "Description", field: "description", width: null },
  { title: "Due Date", field: "dueDate", width: null },
  {
    title: `Value (${process.env.MAJOR_CURRENCY})`,
    field: "value",
    render: (item) => numericFormatter(item.value, 2),
  },
  { title: "Charge Type", field: "chargeType", width: null },
  { title: "Invoice Type", field: "invoiceType", width: null },
  { title: "Invoice To", field: "invoiceTo", width: null },
  { title: "Paid To", field: "paidTo", width: null },
  { title: "Include on Rewrite", field: "includeOnRewrite", width: null },
  {
    title: "Edit",
    render: (item) =>
      item.edit && (
        <React.Fragment>
          <Icon name="pencil" />
        </React.Fragment>
      ),
    sortable: false,
  },
];

const scheduleChargesTotalSplitHeaders = [
  { field: "allocation", title: "Allocation", width: null },
  {
    field: "splitAmount",
    title: `Split Amount (${process.env.MAJOR_CURRENCY})`,
    width: null,
  },
  {
    field: "totalFinanceSplit",
    title: `Finance Split (${process.env.MAJOR_CURRENCY})`,
    width: null,
  },
];

const ScheduleCharges = ({ scheduleId }) => {
  const [poReferenceDisabled, setPOReferenceDisabled] = useState(true);
  const [quarterly, setQuarterly] = useState(true);

  return (
    <div>
      <ListBuilder
        loadAction={getScheduleCharges}
        loadParams={scheduleId}
        renderList={(data, state, events) => (
          <React.Fragment>
            <ContentPanel
              title="Charges"
              wrapperCssClass="outline-grid schedule-charges"
              headerCssClass="defaults-page-title"
              headerContent={
                <BtnNav quarterly={quarterly} setQuarterly={setQuarterly} />
              }
            >
              <div className="spaced">
                <Form.Input
                  field="poRef"
                  className="poRef"
                  subLabel="PO Reference"
                  disabled={poReferenceDisabled}
                  subLabelPosition="left"
                  placeholder="PO Reference..."
                />
                {poReferenceDisabled ? (
                  <Button
                    className="po-lock-btn"
                    icon="unlock"
                    onClick={() => {
                      setPOReferenceDisabled(false);
                    }}
                  />
                ) : (
                  <Button
                    className="po-lock-btn"
                    icon="save"
                    onClick={() => {
                      setPOReferenceDisabled(true);
                    }}
                  />
                )}

                <br />
                <br />

                <Table className={"schedule-charges"}>
                  <SortableHeader
                    events={events}
                    state={state}
                    columns={chargesHeaders}
                  />
                  {data &&
                    data.quarterlyVolumeMinimumPayment &&
                    data.quarterlyNonVolumeMinimumPayment && (
                      <React.Fragment>
                        <Table.Body>
                          <ChargeTableRow
                            seq={1}
                            billingCode="QMPV"
                            description="Quarterly Minimum Payment Contributing to Managed Volume"
                            dueDate={data.dueDate}
                            value={data.quarterlyVolumeMinimumPayment.value}
                            capital={data.quarterlyVolumeMinimumPayment.capital}
                          />
                          <ChargeTableRow
                            seq={2}
                            billingCode="QMPNV"
                            description={
                              <span>
                                Quarterly Minimum Payment <strong>NOT</strong>{" "}
                                Contributing to Managed Volume
                              </span>
                            }
                            dueDate={data.dueDate}
                            value={data.quarterlyNonVolumeMinimumPayment.value}
                            capital={
                              data.quarterlyNonVolumeMinimumPayment.capital
                            }
                            className="bottom-border"
                          />
                        </Table.Body>
                        <Table.Body>
                          <BottomHeaderRow />
                          <BottomValueRow value={data.total} />
                        </Table.Body>
                      </React.Fragment>
                    )}
                </Table>
              </div>
            </ContentPanel>
          </React.Fragment>
        )}
      />
      <br />
      <ContentPanel
        title="Additional Charges"
        wrapperCssClass="outline-grid charges"
        headerCssClass="highlight-page-title"
      >
        <div className="spaced">
          <SimpleTableBuilder
            emptyMessage="No charges found"
            columns={additionalChargesColumns}
            loadAction={getAdditionalCharges}
          />

          <br />
          <AddButton primary content="Add New Additional Charge" />
        </div>
      </ContentPanel>
    </div>
  );
};

const BtnNav = ({ quarterly, setQuarterly }) => {
  return (
    <Button.Group floated="right">
      <Button positive={quarterly} onClick={() => setQuarterly(true)}>
        Quarterly
      </Button>
      <Button positive={!quarterly} onClick={() => setQuarterly(false)}>
        Monthly
      </Button>
    </Button.Group>
  );
};

const ChargeTableRow = ({
  seq,
  billingCode,
  description,
  dueDate,
  value,
  className,
}) => (
  <Table.Row className={className}>
    <Table.Cell>{seq}</Table.Cell>
    <Table.Cell>{billingCode}</Table.Cell>
    <Table.Cell>{description}</Table.Cell>
    <Table.Cell>
      <Format.Date value={dueDate} format="DD MMM YYYY" />
    </Table.Cell>
    <Table.Cell>{numericFormatter(value, "0.00")}</Table.Cell>
    <Table.Cell>{numericFormatter(value, "0.00")}</Table.Cell>
  </Table.Row>
);

const BottomHeaderRow = () => (
  <Table.Row className="no-border">
    <Table.Cell className="empty"></Table.Cell>
    <Table.Cell className="empty"></Table.Cell>
    <Table.Cell className="empty"></Table.Cell>
    <Table.Cell className="empty"></Table.Cell>
    <Table.Cell className="highlight">{`Total (${process.env.MAJOR_CURRENCY})`}</Table.Cell>
    <Table.Cell className="empty"></Table.Cell>
  </Table.Row>
);

const BottomValueRow = ({ value }) => (
  <Table.Row className="no-border">
    <Table.Cell className="empty"></Table.Cell>
    <Table.Cell className="empty"></Table.Cell>
    <Table.Cell className="empty"></Table.Cell>
    <Table.Cell className="empty"></Table.Cell>
    <Table.Cell className="border-left total">
      <ScheduleChargesTotalSplitModal value={value} />
    </Table.Cell>
    <Table.Cell className="empty"></Table.Cell>
  </Table.Row>
);

const ScheduleChargesTotalRow = ({ items }) => {
  if (!items) return null;

  return items.map((item) => (
    <Table.Row key={item.id}>
      <Table.Cell>{item.allocation}</Table.Cell>
      <Table.Cell>{numericFormatter(item.value, "0.00")}</Table.Cell>
      <Table.Cell>{numericFormatter(item.value, "0.00")}</Table.Cell>
    </Table.Row>
  ));
};

const EmptyRow = () => (
  <Table.Row className="no-border">
    <Table.Cell colSpan={3}></Table.Cell>
  </Table.Row>
);

const TotalRow = ({ item }) => {
  return (
    <Table.Row key={item.id}>
      <Table.Cell>{item.totalTitle}</Table.Cell>
      <Table.Cell>{numericFormatter(item.value, "0.00")}</Table.Cell>
      <Table.Cell>{numericFormatter(item.value, "0.00")}</Table.Cell>
    </Table.Row>
  );
};

const ScheduleChargesTotalSplitModal = ({ value }) => {
  return (
    <InfoModalWrapper
      modalClassName="scts-modal"
      title="Schedule Charges Total Split"
      linkText={numericFormatter(value)}
      submitLabel="Close"
      submitIcon=""
    >
      <div className="scts">
        <ListBuilder
          withTable
          loadAction={getScheduleChargesTotalSplit}
          renderList={(tableProps, state, events) => (
            <Table className={"left-align"}>
              <SortableHeader
                events={events}
                state={state}
                columns={scheduleChargesTotalSplitHeaders}
              />
              {
                <React.Fragment>
                  <Table.Body>
                    {tableProps.data.scheduleChargeTotalSplit && (
                      <ScheduleChargesTotalRow
                        items={tableProps.data.scheduleChargeTotalSplit}
                      />
                    )}
                    <EmptyRow />
                    <TotalRow item={tableProps.data} />
                  </Table.Body>
                </React.Fragment>
              }
            </Table>
          )}
        ></ListBuilder>
      </div>
    </InfoModalWrapper>
  );
};

export default ScheduleCharges;
