import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  getResponsiveState,
  NotificationStack,
  PreferenceLoader,
  AuthActions,
} from "@redriver/cinnamon";
import { AppRoutes, SystemRoutes } from "constants/routes";
import { MainNav } from "./nav";
import { withCurrentUser } from "features/../../../shared/components/auth";
import preventDragHandler from "modules/dragEvent/preventDragHandler";
import MobileWrapper from "./nav/MobileWrapper";
import { connect, useDispatch } from "react-redux";
import DashboardPage from "./dashboard/DashboardPage";
import AccountPage from "./system/AccountPage";
import SchedulesRouter from "./schedules/SchedulesRouter";
import CFARouter from "features/CFA/CFARouter";
import DealersRouter from "./dealers/DealersRouter";
import SettingsRouter from "./settings/SettingsRouter";
import FinanceRouter from "./finance/FinanceRouter";
import CompaniesRouter from "./Companies/CompaniesRouter";
import SPVsRouter from "./spvs/SPVsRouter";
import DataUploadsRouter from "./data-uploads/DataUploadsRouter";
import FundersRouter from "./funders/FundersRouter";
import PriceIncreasePage from "./schedules/PriceIncreasePage";
import ProductRouter from "./Products/ProductRouter";
import Meters from "./Meters/Meters";
import Zircon from "./Zircon/Zircon";
import AccessDenied from "./system/AccessDenied";
import { compose } from "redux";

const OpalApp = ({ isNarrowViewport, currentUser }) => {
  const dispatch = useDispatch();

  const { isInternalUser } = currentUser;

  window.onpopstate = function () {
    history.go(1);
  };

  // auto logout on tab or window close, as well as page refresh
  useEffect(() => {
    if (!process.env.DISABLE_LOGOUT_ON_BROWSER_UNLOAD_EVENT) {
      const handleTabClose = (event) => {
        event.preventDefault();
        return (event.returnValue = "Are you sure you want to exit?");
      };

      const logOut = () => {
        dispatch(AuthActions.deauthenticate());
        window.removeEventListener("unload", logOut);
        window.removeEventListener("beforeunload", handleTabClose);
      };

      window.addEventListener("beforeunload", handleTabClose);
      window.addEventListener("unload", logOut);
    }
  }, [dispatch]);

  const blockAccess = !isInternalUser;

  return (
    <section onDragStart={preventDragHandler}>
      {!isNarrowViewport && <MainNav hideMenu={blockAccess} />}
      <MobileWrapper isNarrowViewport={isNarrowViewport}>
        {!blockAccess ? (
          <Switch>
            <Route path={AppRoutes.Dashboard} component={DashboardPage} />
            <Route path={AppRoutes.Customers} component={CompaniesRouter} />
            <Route path={AppRoutes.DataUploads} component={DataUploadsRouter} />

            <Route path={SystemRoutes.Account} component={AccountPage} />
            <Route path={AppRoutes.Schedules} component={SchedulesRouter} />
            <Route
              exact
              path={AppRoutes.PriceIncrease}
              component={PriceIncreasePage}
            />
            <Route path={AppRoutes.Funders} component={FundersRouter} />
            <Route path={AppRoutes.SPVs} component={SPVsRouter} />
            <Route path={AppRoutes.Dealers} component={DealersRouter} />
            <Route path={AppRoutes.Settings} component={SettingsRouter} />
            <Route path={AppRoutes.Finance} component={FinanceRouter} />
            <Route path={AppRoutes.Products} component={ProductRouter} />
            <Route path={AppRoutes.CFA} component={CFARouter} />
            <Route path={AppRoutes.Meters} component={Meters} />
            <Route path={AppRoutes.Zircon} component={Zircon} />
            <Route render={() => <Redirect to={AppRoutes.Dashboard} />} />
          </Switch>
        ) : (
          <Switch>
            <Route
              render={() => (
                <AccessDenied message="You do not have permission to view this page" />
              )}
            />
            <Route render={() => <Redirect to={AppRoutes.Dashboard} />} />
          </Switch>
        )}
      </MobileWrapper>
      <NotificationStack position="bottomLeft" />
      <PreferenceLoader />
    </section>
  );
};
const mapState = (state) => {
  return {
    isNarrowViewport: ["tablet", "mobile"].includes(
      getResponsiveState(state).screenSize,
    ),
  };
};
export default compose(withCurrentUser, connect(mapState))(OpalApp);
