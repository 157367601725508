import React from "react";
import { ContentPanel } from "components/displays";
import {
  yesNoFormatter,
  TableDivider,
  numericFormatter,
} from "components/displays/DisplayHelper";
import { Table } from "semantic-ui-react";

const FMV = ({ fmv }) => {
  return (
    <ContentPanel
      title="FMV"
      wrapperCssClass="outline-grid"
      headerCssClass="defaults-page-title"
    >
      <div className="padded fmv">
        <FMVTable fmv={fmv} />
      </div>
    </ContentPanel>
  );
};

const FMVTable = ({ fmv }) => (
  <Table className="fmv-table">
    <Table.Body>
      <Table.Row className="strong grey">
        <Table.Cell>Status</Table.Cell>
        <Table.Cell>Part Converted</Table.Cell>
      </Table.Row>
      <TableDivider />
      <Table.Row>
        <Table.Cell>Converted</Table.Cell>
        <Table.Cell> {numericFormatter(fmv?.converted)}</Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>Unconverted</Table.Cell>
        <Table.Cell> {numericFormatter(fmv?.unconverted)}</Table.Cell>
      </Table.Row>
      <Table.Row className="strong grey">
        <Table.Cell>Total {process.env.MAJOR_CURRENCY}</Table.Cell>
        <Table.Cell> {numericFormatter(fmv?.total)}</Table.Cell>
      </Table.Row>
      <TableDivider />
      <Table.Row className="bold">
        <Table.Cell>Converted - Sum to Collect</Table.Cell>
        <Table.Cell> {numericFormatter(fmv?.convertedSumToCollect)}</Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>FMV Settled</Table.Cell>
        <Table.Cell> {numericFormatter(fmv?.fmvSettled)}</Table.Cell>
      </Table.Row>
      <Table.Row className="strong grey">
        <Table.Cell>C/F Amount {process.env.MAJOR_CURRENCY}</Table.Cell>
        <Table.Cell> {numericFormatter(fmv?.cfAmount)}</Table.Cell>
      </Table.Row>
      <TableDivider />
      <Table.Row className="strong grey">
        <Table.Cell colSpan={2}>Unconverted</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>
          Value Due to Funder {process.env.MAJOR_CURRENCY}
        </Table.Cell>
        <Table.Cell> {numericFormatter(fmv?.valueDueToFunder)}</Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>Due Date</Table.Cell>
        <Table.Cell>{fmv?.dueDate}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Invoiced To Supplier?</Table.Cell>
        <Table.Cell>{yesNoFormatter(fmv?.invoicedToSupplier)}</Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>Amount invoiced {process.env.MAJOR_CURRENCY}</Table.Cell>
        <Table.Cell> {numericFormatter(fmv?.amountInvoiced)}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Paid To Funder?</Table.Cell>
        <Table.Cell>{yesNoFormatter(fmv?.paidToFunder)}</Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>Amount paid {process.env.MAJOR_CURRENCY}</Table.Cell>
        <Table.Cell> {numericFormatter(fmv?.amountPaid)}</Table.Cell>
      </Table.Row>
      <TableDivider />
      <Table.Row>
        <Table.Cell>Unconverted amount settled</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(fmv?.unconvertedAmountSettled)}
        </Table.Cell>
      </Table.Row>
      <TableDivider />
      <Table.Row>
        <Table.Cell>Unconverted amount C/F</Table.Cell>
        <Table.Cell> {numericFormatter(fmv?.unconvertedAmountCf)}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export default FMV;
