import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import { Table, Modal, ModalBuilder } from "@redriver/cinnamon";
import { getProductMeterReadings } from "./actions";
import classNames from "classnames";
import { numericFormatter } from "components/displays/DisplayHelper";
import { EditMeterReading, RejectMeterReading } from ".";
import { SimpleTableBuilder } from "../../../../../shared/components/tables/sortableTable";

const MeterReadingTable = ({ productId, meterReadingType }) => {
  const [tableExtended, setTableExtended] = useState(false);

  const columns = [
    {
      field: "type",
      width: "8%",
      title: "Type",
      render: (x) => (
        <span
          className={x.meterUsage < 0 ? "red" : x.type == "SR" ? "blue" : ""}
        >
          {x.type}
        </span>
      ),
    },
    {
      field: "periodStartDate",
      width: "8%",
      title: "Period Start Date",
      render: (x) => (
        <span
          className={x.meterUsage < 0 ? "red" : x.type == "SR" ? "blue" : ""}
        >
          {x.periodStartDate}
        </span>
      ),
    },
    {
      field: "creditNote",
      width: "8%",
      title: "Credit Note",
      render: (x) => (
        <span
          className={x.meterUsage < 0 ? "red" : x.type == "SR" ? "blue" : ""}
        >
          {x.creditNote}
        </span>
      ),
    },
    {
      field: "schedule",
      width: "8%",
      title: "Schedule",
      render: (x) => (
        <span
          className={x.meterUsage < 0 ? "red" : x.type == "SR" ? "blue" : ""}
        >
          {x.schedule}
        </span>
      ),
    },
    {
      field: "invoiceNumber",
      width: "8%",
      title: "Invoice Number",
      render: (x) => (
        <span
          className={x.meterUsage < 0 ? "red" : x.type == "SR" ? "blue" : ""}
        >
          {x.invoiceNumber}
        </span>
      ),
    },
    {
      field: "unit",
      width: "8%",
      title: "Unit",
      render: (x) => (
        <span
          className={x.meterUsage < 0 ? "red" : x.type == "SR" ? "blue" : ""}
        >
          {x.unit}
        </span>
      ),
    },
    {
      field: "managedMeter",
      width: "8%",
      title: "Managed Meter",
      render: (x) => (
        <span
          className={x.meterUsage < 0 ? "red" : x.type == "SR" ? "blue" : ""}
        >
          {x.managedMeter}
        </span>
      ),
    },
    {
      field: "readingDate",
      width: "8%",
      title: "Reading Date",
      render: (x) => (
        <span
          className={x.meterUsage < 0 ? "red" : x.type == "SR" ? "blue" : ""}
        >
          {x.readingDate}
        </span>
      ),
    },
    {
      field: "meterCode",
      width: "8%",
      title: "Meter Code",
      render: (x) => (
        <span
          className={x.meterUsage < 0 ? "red" : x.type == "SR" ? "blue" : ""}
        >
          {x.meterCode}
        </span>
      ),
    },
    {
      field: "meterReading",
      width: "8%",
      title: "Meter Reading",
      render: (x) => (
        <span
          className={x.meterUsage < 0 ? "red" : x.type == "SR" ? "blue" : ""}
        >
          {numericFormatter(x.meterReading, "-", 0)}
        </span>
      ),
    },
    {
      field: "meterUsage",
      width: "8%",
      title: "Meter Usage",
      render: (x) => (
        <span
          className={x.meterUsage < 0 ? "red" : x.type == "SR" ? "blue" : ""}
        >
          {numericFormatter(x.meterUsage, "-", 0)}
        </span>
      ),
    },
    {
      field: "notes",
      width: "8%",
      title: "Notes",
      className: "hyperlink blue",
      render: (item) => (item.notes != null && item.notes != "" ? "View" : ""),
    },
    {
      width: "8%",
      title: "",
      render: () => <TableMenu />,
      sortable: false,
    },
  ];

  return (
    <React.Fragment>
      <div
        className={classNames(
          "vertical-scrollbar",
          tableExtended ? "extended" : "retracted"
        )}
      >
        <SimpleTableBuilder
          emptyMessage="No products found"
          columns={columns}
          loadAction={getProductMeterReadings}
          loadParams={(productId, meterReadingType)}
        />
      </div>
      <div className="extension-icon">
        <Button onClick={() => setTableExtended(!tableExtended)}>
          {tableExtended ? (
            <Icon name="angle up" />
          ) : (
            <Icon name="angle down" />
          )}
        </Button>
      </div>
      <div className="key">
        <span>
          <strong>SR</strong> - Start Reading,&nbsp;
        </span>
        <span>
          <strong>U</strong> - Upload,&nbsp;
        </span>
        <span>
          <strong>E</strong> - Edited,&nbsp;
        </span>
        <span>
          <strong>CN</strong> - Credit Note,&nbsp;
        </span>
        <span>
          <strong>ADJ</strong> - Adjustment,&nbsp;
        </span>
        <span>
          <strong>REJ</strong> - Rejected,&nbsp;
        </span>
        <span>
          <strong>M</strong> - Manual,&nbsp;
        </span>
        <span>
          <strong>F</strong> - Final
        </span>
      </div>
    </React.Fragment>
  );
};

export default MeterReadingTable;

const TableMenu = () => {
  return (
    <Table.Menu>
      <ModalBuilder
        renderTrigger={(trigger) => (
          <Table.MenuItem onClick={trigger}>
            <React.Fragment>
              <Icon name="close"></Icon>
              <span>Reject Reading</span>
            </React.Fragment>
          </Table.MenuItem>
        )}
        renderModal={(modalProps) => (
          <Modal.Edit
            header="Reject Meter Reading"
            size="small"
            className="modal outline-grid"
            submitLabel="Save & Close"
            {...modalProps}
          >
            <RejectMeterReading></RejectMeterReading>
          </Modal.Edit>
        )}
      />
      <ModalBuilder
        renderTrigger={(trigger) => (
          <Table.MenuItem onClick={trigger}>
            <React.Fragment>
              <Icon name="edit"></Icon>
              <span>Edit Reading</span>
            </React.Fragment>
          </Table.MenuItem>
        )}
        renderModal={(modalProps) => (
          <Modal.Edit
            size="large"
            header="Edit Meter Reading"
            className="modal outline-grid"
            submitLabel="Save & Close"
            {...modalProps}
          >
            <EditMeterReading></EditMeterReading>
          </Modal.Edit>
        )}
      />
    </Table.Menu>
  );
};
