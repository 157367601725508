import React, { useEffect, useState } from "react";
import {
  Format,
  ListBuilder,
  ActionBar,
  FormBuilder,
  usePreferences,
} from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { Link } from "react-router-dom";
import { CFARoutes } from "constants/routes";
import { ContentPanel } from "components/displays";
import {
  TableConfigurator,
  ConfigurableTable,
} from "features/CustomConfig/TableConfig";
import { TextSearch } from "features/../../../shared/components/search";
import { getCFAListData } from "./actions";
import CFAFilterDisplay from "./CFAFilterDisplay";
import CFAFilters from "./CFAFilters";
import { currencyFormatter } from "components/displays/DisplayHelper";

const CFAList = () => {
  const { preferences } = usePreferences();
  const [config, setConfig] = useState(preferences.cfaTableConfig);
  const [pageSizeConfig, setPageSizeConfig] = useState(
    preferences.cfaTableConfigPageSize ? preferences.cfaTableConfigPageSize : 25
  );

  useEffect(() => {
    setConfig(preferences.cfaTableConfig);
  }, [preferences.cfaTableConfig]);

  useEffect(() => {
    preferences.cfaTableConfigPageSize &&
      setPageSizeConfig(preferences.cfaTableConfigPageSize);
  }, [preferences.cfaTableConfigPageSize]);

  const columns = [
    {
      field: "ref",
      title: "CFA Reference",
      render: (cfa) => (
        <Link to={CFARoutes.CFA(cfa.ref)} className="cfa-link">
          {cfa.ref}
        </Link>
      ),
    },
    { field: "status", title: "Status" },
    { field: "customer", title: "Customer" },
    { field: "supplier", title: "Supplier" },
    { field: "spv", title: "SPV" },
    {
      field: "x.startDate",
      title: "Start Date",
      render: (x) => <Format.Date value={x.startDate} format="DD MMM YYYY" />,
    },
    {
      field: "x.endDate",
      title: "End Date",
      render: (x) => <Format.Date value={x.endDate} format="DD MMM YYYY" />,
    },
    {
      field: "payment",
      title: `Current CFA Payment (${process.env.MAJOR_CURRENCY})`,
      render: (x) => currencyFormatter(x.payment),
    },
    { field: "term", title: "Term" },
    { field: "periodsRemaining", title: "Periods Remaining" },
    { field: "funder", title: "Funder" },
  ];

  return (
    <React.Fragment>
      <ListBuilder
        withTable
        initialPagination={{ pageSize: pageSizeConfig, pageNumber: 1 }}
        loadAction={getCFAListData}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        loadParams={{ pageSize: pageSizeConfig }}
        renderList={(tableProps, state, events) => (
          <div>
            <ContentPanel
              title="CFA"
              wrapperCssClass="outline-grid cfa-list"
              headerCssClass="defaults-page-title"
              headerContent={
                <FilterBar
                  state={state}
                  events={events}
                  config={config}
                  columns={columns}
                  initialPageSize={25}
                  pageSizeConfig={pageSizeConfig}
                ></FilterBar>
              }
            >
              <div className="spaced">
                <ConfigurableTable
                  state={state}
                  events={events}
                  tableProps={tableProps}
                  emptyMessage="No items found"
                  columns={columns}
                  config={config}
                />
                <div className="padded">
                  <TablePagination
                    pageSize={pageSizeConfig}
                    pageNumber={state.pagination.pageNumber}
                    onChange={events.onChangePagination}
                    totalItems={state.total}
                  />
                </div>
              </div>
            </ContentPanel>
          </div>
        )}
      />
    </React.Fragment>
  );
};

const FilterBar = ({
  state,
  events,
  config,
  columns,
  initialPageSize,
  pageSizeConfig,
}) => (
  <FormBuilder
    value={state.filters}
    onChange={events.onChangeFilters}
    renderForm={(formProps) => (
      <ActionBar className="filter-display">
        <ActionBar.Item>
          <TextSearch
            filters={state.filters}
            onChange={events.onChangeFilters}
          ></TextSearch>
        </ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item className="search-filter-display">
          <CFAFilterDisplay
            filters={state.filters}
            onChange={events.onChangeFilters}
          />
        </ActionBar.Item>
        <ActionBar.Item fluid></ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <TableConfigurator
            config={config}
            columns={columns}
            configId="cfaTableConfig"
            titlePreFix="CFA"
            initialPageSize={initialPageSize}
            pageSizeConfig={pageSizeConfig}
          ></TableConfigurator>
        </ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <CFAFilters
            title="Filters"
            filters={state.filters}
            onChange={events.onChangeFilters}
            formProps={formProps}
            onReset={events.onReset}
          />
        </ActionBar.Item>
      </ActionBar>
    )}
  />
);

export default CFAList;
