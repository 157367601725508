import { Form, FormBuilder } from "@redriver/cinnamon";
import React, { forwardRef } from "react";
import { Button } from "semantic-ui-react";
import { IconButton } from "../../../../../../../../shared/components/buttons";
import PenSvg from "../../../../../../../../shared/assets/pen.svg";
import SaveSvg from "../../../../../../../../shared/assets/save.svg";
import { getDocumentFees, setDocumentFees } from "../../actions";
import AccordionHelper from "components/displays/AccordionHelper";
import { currencyFormatter } from "components/displays/DisplayHelper";

const DocumentFeeStructure = forwardRef(
  ({ settingsId, toggleVisibility, collapsed }, ref) => {
    let requestParams = { settingsId };
    return (
      <div ref={ref}>
        <AccordionHelper
          collapsed={collapsed}
          toggleVisibility={toggleVisibility}
          title="Fee's"
        >
          <div className="defaults-grid fee-struct-table">
            {settingsId && (
              <FormBuilder
                initialReadOnly
                loadAction={getDocumentFees}
                loadParams={requestParams}
                loadTransform={data => ({
                  documentFees: data.documentFees,
                  availableToDealer: data.availableToDealer,
                })}
                submitAction={setDocumentFees}
                submitParams={requestParams}
                renderForm={(formProps, state, events) => (
                  <Form {...formProps} disabled={state.submitting || state.readOnly}>
                    <div className="header">
                      <Form.Checkbox
                        label="Read-Only Available to Supplier Admin"
                        field="availableToDealer"
                        readOnly={state.readOnly}
                      />

                      <div className="actions">
                        <div>
                          <IconButton
                            primary
                            icon={state.readOnly ? PenSvg : SaveSvg}
                            content={state.readOnly ? "Edit" : "Save"}
                            floated="right"
                            onClick={() =>
                              state.readOnly
                                ? events.onToggleReadOnly()
                                : events.onSubmit({ reset: ["readOnly"] })
                            }
                            className={
                              !state.readOnly ? "positive-title-action" : null
                            }
                          />
                          {!state.readOnly && (
                            <Button
                              primary
                              className="cancel-title-action"
                              floated="right"
                              content="Cancel"
                              onClick={events.onReset}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="table">
                      <Form.Array field="documentFees" arrayKey="feeType">
                        <div className="row">
                          <Form.Input
                            className="fee-desc"
                            readOnly
                            field="feeDescription"
                          />
                          <Form.Numeric
                            className="fee-price"
                            required
                            formatValue
                            field="price"
                            minValue={0}
                            decimalPlaces={2}
                            renderReadOnly={props =>
                              currencyFormatter(props.value)
                            }
                          />
                        </div>
                      </Form.Array>
                    </div>
                  </Form>
                )}
              />
            )}
          </div>
        </AccordionHelper>
      </div>
    );
  }
);

export default DocumentFeeStructure;
