import React from "react";
import { Form } from "@redriver/cinnamon";
import { TableConfigurator } from "features/CustomConfig/TableConfig";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";

const FilterGrid = ({
  columns,
  config,
  configId,
  titlePreFix,
  initialPageSize,
  pageSizeConfig,
}) => {
  return (
    <Grid doubling>
      <GridRow>
        <GridColumn
          width={16}
          only="computer"
          className="table-config-container"
        >
          <TableConfigurator
            config={config}
            columns={columns}
            configId={configId}
            titlePreFix={titlePreFix}
            initialPageSize={initialPageSize}
            pageSizeConfig={pageSizeConfig}
          />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width={6} only="computer">
          SPV Selected:
        </GridColumn>
        <GridColumn width={10}>
          <Form.Dropdown
            className="highlighted-input"
            field="spv"
            placeholder="Select SPV"
            options={[
              { value: "ipsSpv1", text: "IPS SPV 1" },
              { value: "ipsSpv2", text: "IPS SPV 2" },
              { value: "ipsSpv3", text: "IPS SPV 3" },
            ]}
            search
          />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn width={6} only="computer">
          SPV Global Invoice Date:
        </GridColumn>
        <GridColumn width={10}>
          <Form.Date
            className="highlighted-input"
            local
            field="startDate"
            placeholder="SPV Global Invoice Date"
          />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default FilterGrid;
