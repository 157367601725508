import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { approveTemplate } from "./actions";

const ApproveTemplate = ({
  templateName,
  disabled,
  lastApproval,
  id,
  reloadTable,
}) => {
  return (
    <ModalBuilder
      submitAction={approveTemplate}
      submitParams={id}
      onSubmitted={() => reloadTable()}
      renderTrigger={(showModal) => (
        <Button
          title="Approve"
          primary
          onClick={showModal}
          icon="check"
          disabled={disabled}
        />
      )}
      renderModal={(modalProps) => (
        <Modal.Confirmation {...modalProps} header="Approve">
          Are you sure you wish to approve {templateName}
          {lastApproval
            ? ", approving will cause this new template to replace the current one"
            : ""}
          ?
        </Modal.Confirmation>
      )}
    />
  );
};

export default ApproveTemplate;
