import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { deleteFunderAuthorisedSignatory } from "./actions";

const DeleteFunderSignatory = ({
  item,
  header,
  onDeleted,
  funderId,
  ...props
}) => (
  <ModalBuilder
    submitAction={deleteFunderAuthorisedSignatory}
    submitParams={{ funderId: funderId, itemId: item.id }}
    onSubmitted={onDeleted}
    renderTrigger={(showModal) => (
      <Button
        icon="delete"
        className="delete-icon-btn"
        color="red"
        onClick={showModal}
        {...props}
      />
    )}
    renderModal={(modalProps) => (
      <Modal.Delete {...modalProps} header={header}>
        Are you sure you wish to delete {item.name}?
      </Modal.Delete>
    )}
  />
);

export default DeleteFunderSignatory;
