import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULES";
const ActionTypes = {
  getSchedules: `${NAMESPACE}/GET_SCHEDULES`,
};
export const getSchedules = ({ filters, pagination }) => {
  return  apiGet(ActionTypes.getSchedules, "schedules", { ...pagination, ...filters });
  
}
 
