import { apiGet, apiPost, apiDelete, apiPut } from "@redriver/cinnamon";
const NAMESPACE = "DEALERS";
const ActionTypes = {
  GetDealerOffice: `${NAMESPACE}/GET_DEALER_OFFICE`,
  CreateDealerOffice: `${NAMESPACE}/CREATE_DEALER_OFFICE`,
  DeleteDealerOffice: `${NAMESPACE}/DELETE_DEALER_OFFICE`,
  EditDealerOffice: `${NAMESPACE}/EDIT_DEALER_OFFICE`,
};

export const getDealerOffice = id =>
  apiGet(ActionTypes.GetDealerOffice, `dealer-offices/${id}`);

export const createDealerOffice = (data, submitParams) => {
  return apiPost(ActionTypes.CreateDealerOffice, `dealer-offices/`, {
    ...data,
    ...submitParams,
  });
};
export const deleteDealerOffice = id =>
  apiDelete(ActionTypes.DeleteDealerOffice, `dealer-offices/${id}`);

export const editDealerOffice = (data, id) =>
  apiPut(ActionTypes.EditDealerOffice, `dealer-offices/${id}`, data);
