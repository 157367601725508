import React, { useState } from "react";
import { Button, TextArea } from "semantic-ui-react";
import { Form, ModalBuilder, Modal, ErrorMessage } from "@redriver/cinnamon";
import { getNewCustomerCrmUrl } from "./actions";
import { useDispatch } from "react-redux";
import {
  UserTypeAhead,
  DealerOfficesTypeAheadLookup,
} from "../../../../../shared/features/Settings";
import { WriteTextToClipboardButton } from "../../../../../shared/components/buttons";

const CrmNewCustomerLinkModal = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [output, setOutput] = useState(undefined);
  const [error, setError] = useState(undefined);

  return (
    <ModalBuilder
      withForm
      onLoaded={() => {
        setError(undefined);
        setOutput(undefined);
      }}
      renderTrigger={(onShowModal) => (
        <Button
          primary
          icon="code"
          content="New Customer CRM Link"
          onClick={onShowModal}
        />
      )}
      renderModal={(modalProps, formProps) => (
        <Modal
          className="edit-dealer"
          {...modalProps}
          header="Generate New Customer CRM URL"
          cancelLabel="Close"
          submitLabel="Generate"
          loading={loading}
          slowLoading={loading}
          onSubmit={() => {
            setLoading(true);
            (async () => {
              const { response, error } = await dispatch(
                getNewCustomerCrmUrl({ data: formProps.value }),
              );
              setError(error);
              setOutput(response?.urlEncoded);
              setLoading(false);
            })();
          }}
        >
          <Form {...formProps}>
            <div className="step details">
              <Form.Input
                field="name"
                label="Client Name"
                fluid
                inline
                required
              />
              <Form.Input field="code" label="Code" fluid inline required />
            </div>
            <div className="step details">
              <DealerOfficesTypeAheadLookup
                field="dealerOfficeId"
                fluid
                label="Supplier Office"
                required
                placeholder="Supplier office"
              />
              <UserTypeAhead
                fluid
                required
                placeholder="Search Users"
                field="userId"
                label="Member User"
                lookupParams={{
                  dealerOfficeId: formProps.value.dealerOfficeId,
                  includeInternalUsers: true,
                }}
              />
            </div>
            <div className="step details">
              <Form.Input
                field="businessType"
                label="Business Type"
                fluid
                inline
                required
              />
              <Form.Input
                field="ContactName"
                label="Contact Name"
                fluid
                inline
              />
            </div>
            <div className="step details">
              <Form.Input field="phone" label="Phone" fluid inline />
              <Form.Input field="email" label="Email" fluid inline />
            </div>
            <div className="step details">
              <Form.Input
                field="addressLine1"
                label="Address Line 1"
                fluid
                inline
              />
              <Form.Input
                field="addressLine2"
                label="Address Line 2"
                fluid
                inline
              />
            </div>
            <div className="step details">
              <Form.Input field="town" label="Town" fluid inline />
              <Form.Input field="county" label="County" fluid inline />
            </div>
            <div className="step details">
              <Form.Input
                field="postcode"
                label="Postcode"
                fluid
                inline
                required
              />
              <Form.Input field="country" label="Country" fluid inline />
            </div>
            <ErrorMessage error={error} />
            {output && (
              <React.Fragment>
                <TextArea readOnly value={output} rows={5} />
                <div className="flex end">
                  <WriteTextToClipboardButton valueToWrite={output} />
                </div>
              </React.Fragment>
            )}
          </Form>
        </Modal>
      )}
    />
  );
};

export default CrmNewCustomerLinkModal;
