import React from "react";
import { ModalBuilder } from "@redriver/cinnamon";
import { IconButton } from "features/../../../shared/components/buttons";
import { editPaper, getPaper } from "./actions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import PaperModalForm from "./PaperModalForm";
import { TranslatedModal } from "../../../../components/modals";
import { useTranslation } from "react-i18next";

const ViewPaper = ({
  as: As = IconButton,
  onSubmitted,
  dealerOfficeId,
  paper,
  ...props
}) => {
  const { t } = useTranslation("supplierOffices", {
    keyPrefix: "largeFormat.paper",
  });
  return (
    <PermissionCheck target={Targets.LargeFormat} action={Actions.Edit}>
      <ModalBuilder
        withForm
        loadAction={getPaper}
        loadParams={{ id: paper.id }}
        renderTrigger={showModal => (
          <As onClick={showModal} {...props}>
            {t("view")}
          </As>
        )}
        loadTransform={result => {
          return result;
        }}
        renderModal={(modalProps, formProps) => (
          <TranslatedModal
            className="large-format-view-modal"
            {...modalProps}
            header={t("viewPaper")}
            cancelLabel={t("close")}
          >
            <PaperModalForm formProps={formProps} />
          </TranslatedModal>
        )}
      />
    </PermissionCheck>
  );
};

export default ViewPaper;
