export default {
  Dormant: "Dormant",
  Live: "Live",
  NoNewDeals: "NoNewDeals",
  Dead: "Dead"
};

export const SpvStatusDescription = {
  Dormant: "Dormant",
  Live: "Live",
  NoNewDeals: "No New Deals",
  Dead: "Dead"
};
