import { apiGet, apiPost, apiPut, apiDelete } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/TARGET_MARKET";

const ActionTypes = {
  AddTargetMarket: `${NAMESPACE}/ADD_TARGET_MARKET`,
  GetTargetMarkets: `${NAMESPACE}/GET_TARGET_MARKETS`,
  UpdateTargetMarket: `${NAMESPACE}/UPDATE_TARGET_MARKET`,
  DeleteTargetMarket: `${NAMESPACE}/DELETE_TARGET_MARKET`,
};

export const addTargetMarket = (data, { dealerOfficeId }) => {
  let obj = {
    ...data,
    dealerOfficeId: dealerOfficeId.dealerOfficeId,
  };
  return apiPost(ActionTypes.AddTargetMarket, `target-market`, obj);
};

export const getTargetMarket = ({ pagination }, { dealerOfficeId }) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetTargetMarkets, "target-market", {
    pageNumber,
    pageSize,
    dealerOfficeId,
  });
};

export const editTargetMarket = (data, { id, dealerOfficeId }) => {
  return apiPut(ActionTypes.UpdateTargetMarket, `target-market/${id}`, {
    ...data,
    dealerOfficeId,
  });
};
export const deleteTargetMarket = targetMarketId =>
  apiDelete(ActionTypes.DeleteTargetMarket, `target-market/${targetMarketId}`);
