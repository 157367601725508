import React from "react";
import {  Grid, GridColumn } from "semantic-ui-react";
import { FormBuilder, Form } from "@redriver/cinnamon";

const FilterBar = ({ value, onChange, users, types}) => (

    <FormBuilder 
    value={value}
    onChange={onChange}
    renderForm={formProps => (
    <Form {...formProps}>
            <Grid columns={16}>
                <GridColumn  mobile={3} tablet={2} computer={2} largeScreen={2} widescreen={1} className="right-align" >
                    <b>Name</b>
                </GridColumn>
                <GridColumn  mobile={13} tablet={14} computer={6} largeScreen={6} widescreen={3}>
                    <Form.Input field="name"  />
                </GridColumn>
                <GridColumn  mobile={3} tablet={2}  computer={2} largeScreen={2} widescreen={1} className="right-align" >
                    <b>Uploaded by</b>
                </GridColumn>
                <GridColumn mobile={13} tablet={14} computer={6} largeScreen={6} widescreen={2}>
                    <Form.Dropdown
                    field="userDrp"
                    placeholder="Uploaded By"
                    options={users}
                    />
                </GridColumn>
                <GridColumn mobile={3} tablet={2} largeScreen={2} computer={2} widescreen={1} className="right-align" >
                    <b>Type</b>
                </GridColumn>
                <GridColumn mobile={13} tablet={14} columns={14} computer={6} largeScreen={6} widescreen={2}>
                    <Form.Dropdown
                    field="typeDrp"
                    placeholder="Type"
                    options={types}
                    />
                </GridColumn>
                <GridColumn  mobile={3} tablet={2}  computer={2} largeScreen={2}  widescreen={2} className="right-align" >
                    <b>Date Uploaded</b>
                </GridColumn>
                <GridColumn  mobile={13} tablet={14}   computer={6} largeScreen={6} widescreen={4} >
                    <Form.DateRange local={true}  field="dates" />
                </GridColumn>
            </Grid>
        </Form>
    )}/>
);



export default FilterBar;

