import React from "react";
import {
  registerLookup,
  apiGet,
  Form,
  LookupDropdown,
} from "@redriver/cinnamon";

const NAMESPACE = "FUNDERS_SETTINGS";

const ActionTypes = {
  getFunderSuppliers: `${NAMESPACE}/GET_FUNDER_SUPPLIERS`,
};

const LookupTypes = {
  FunderSuppliersLookup: "funderSuppliersLookup",
};

registerLookup(
  LookupTypes.FunderSuppliersLookup,
  ({ search, funderId }) =>
    apiGet(ActionTypes.getFunderSuppliers, "lookups/funder-suppliers", {
      search,
      funderId,
    }),
  { cache: false }
);

export const FunderSuppliersTypeAhead = props => {
  return (
    <Form.TypeAhead
      {...props}
      searchSuggestions
      alwaysShowSuggestionsOnBlur
      lookup={LookupTypes.FunderSuppliersLookup}
    />
  );
};

export const FunderSuppliersLookupDropdown = props => {
  return (
    <LookupDropdown
      {...props}
      searchSuggestions
      alwaysShowSuggestionsOnBlur
      lookup={LookupTypes.FunderSuppliersLookup}
    />
  );
};
