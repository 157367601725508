import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { SystemRoutes } from "constants/routes";
import { requestAuthentication } from "./actions";
import UseRecoveryCode from "./UseRecoveryCode";

const authenticatorErrors = {
  923001: "Incorrect authenticator code, please try again",
};

const AuthenticatorCheck = ({ token, recoveryTypes, onRecovered }) => {
  const [showRecoveryCode, setShowRecoveryCode] = useState(false);

  if (showRecoveryCode) {
    return (
      <UseRecoveryCode
        token={token}
        onDismiss={() => setShowRecoveryCode(false)}
        onRecovered={onRecovered}
      />
    );
  }

  return (
    <div>
      <FormBuilder
        submitAction={requestAuthentication}
        submitParams={token}
        renderSubmitting={false}
        renderError={false}
        renderForm={(
          formProps,
          { submitting, slowSubmitting, error },
          events
        ) => (
          <Form {...formProps}>
            <p className="align-text-left">
              Your login is protected with an authenticator app, please enter
              your authentication code below:
            </p>
            <Form.Input
              field="totp"
              label="Authentication Code"
              placeholder="Enter authenticator app code"
              disabled={submitting}
              autoFocus
              required
              fluid
            />
            {recoveryTypes?.includes("RecoveryCodes") && (
              <p className="align-text-left">
                Use a{" "}
                <a onClick={() => setShowRecoveryCode(true)}>recovery code</a>
              </p>
            )}
            <Button
              primary
              onClick={events.onSubmit}
              disabled={submitting}
              loading={submitting && slowSubmitting}
              fluid
              size="large"
              className="authenticator-button"
            >
              Continue
            </Button>
            <ErrorMessage
              header="Authentication Error"
              error={error}
              overrideMessages={authenticatorErrors}
              omitCodes
              className="align-text-left"
            />
            <p className="align-text-left">
              Return to <Link to={SystemRoutes.Login}>Log In</Link>
            </p>
          </Form>
        )}
      />
    </div>
  );
};

export default AuthenticatorCheck;
