import { PaymentMethod, FunderStatus } from ".";

const mapEnumToDropdown = item => ({ text: item[1], value: item[0] });

export const paymentMethods =
  Object.entries(PaymentMethod).map(mapEnumToDropdown);

export const funderTypes = Object.entries({
  Funder: "Funder",
  DealerFunded: "Supplier",
  CoFunded: "Co-Funded",
}).map(mapEnumToDropdown);

export const financeTypes = Object.entries({
  EFA: "EFA",
  FMV: "FMV",
}).map(mapEnumToDropdown);

export const funderStatusDropdownValues =
  Object.entries(FunderStatus).map(mapEnumToDropdown);
