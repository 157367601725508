import { apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/RESTRICTIONS/LFP";

const ActionTypes = {
  UpdateLFPRestrictions: `${NAMESPACE}/UPDATE`,
};

export const setLFPRestrictions = (formData, { settingsId }) =>
  apiPut(
    ActionTypes.UpdateLFPRestrictions,
    `defaults-restrictions/${settingsId}`,
    {
      largeFormat: {
        ...formData,
      },
    }
  );
