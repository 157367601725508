import React from "react";
import { Link } from "react-router-dom";
import BreadcrumbBar from "./BreadcrumbBar";
import { Icon } from "semantic-ui-react";

const RedirectBreadcrumbBar = ({ to, text }) => (
  <BreadcrumbBar>
    <Link to={to}>
      <Icon name="triangle left" />
      <span>{text}</span>
    </Link>
  </BreadcrumbBar>
);

export default RedirectBreadcrumbBar;
