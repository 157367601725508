import { apiGet, apiPost, apiPut, apiDelete } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_PAPER";

const ActionTypes = {
  GetPapers: `${NAMESPACE}/GET_PAPERS`,
  GetPaper: `${NAMESPACE}/GET_PAPER`,
  AddPaper: `${NAMESPACE}/ADD_PAPER`,
  UpdatePaper: `${NAMESPACE}/UPDATE_PAPER`,
  DeletePaper: `${NAMESPACE}/DELETE_PAPER`,
};

export const getPapers = ({ filters, pagination }, { dealerOfficeId }) => {
  const { sortName, sortDirection, search } = filters;
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetPapers, "large-format/papers", {
    pageNumber,
    pageSize,
    dealerOfficeId,
    sortName,
    sortDirection,
    search,
  });
};

export const getPaper = ({ id }) =>
  apiGet(ActionTypes.GetPaper, `large-format/papers/${id}`);
