import React from "react";
import { Form } from "@redriver/cinnamon";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";
import { funderStatusDropdownValues } from "constants/enums";

export const FundersSummaryDetails = ({
  formProps,
  readOnly,
  disableFunderCode,
}) => {
  return (
    <div className="top-spacer">
      <Form {...formProps} readOnly={readOnly} className="funder-details">
        <Grid>
          <GridColumn
            mobile={16}
            tablet={16}
            computer={6}
            largeScreen={7}
            widescreen={8}
          >
            <Grid>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  Legal Entity:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={8}
                  largeScreen={9}
                  widescreen={10}
                >
                  {" "}
                  <Form.Input field="name" required />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  Funder Short Name:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={8}
                  largeScreen={9}
                  widescreen={10}
                >
                  {" "}
                  <Form.Input field="shortName" required />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  Funder Code:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={8}
                  largeScreen={9}
                  widescreen={10}
                >
                  {" "}
                  <Form.Input
                    field="funderCode"
                    readOnly={readOnly || disableFunderCode}
                    required
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  Jurisdiction:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={8}
                  largeScreen={9}
                  widescreen={10}
                >
                  {" "}
                  <Form.Input field="jurisdiction" required />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  Company Registration Number:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={8}
                  largeScreen={9}
                  widescreen={10}
                >
                  {" "}
                  <Form.Input field="companyRegistrationNumber" />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  VAT Registration Number:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={8}
                  largeScreen={9}
                  widescreen={10}
                >
                  {" "}
                  <Form.Input field="vatRegistrationNumber" />
                </GridColumn>
              </GridRow>{" "}
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  EIN Number:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={9}
                  largeScreen={8}
                  widescreen={8}
                  textAlign="left"
                >
                  {" "}
                  <Form.Input field="einNumber" />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  DUNS Number:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={9}
                  largeScreen={8}
                  widescreen={8}
                  textAlign="left"
                >
                  {" "}
                  <Form.Input field="dunsNumber" />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  Status:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={9}
                  largeScreen={8}
                  widescreen={8}
                  textAlign="left"
                >
                  {" "}
                  <Form.Dropdown
                    field="status"
                    options={funderStatusDropdownValues}
                    required
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  Start Date:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={9}
                  largeScreen={8}
                  widescreen={8}
                  textAlign="left"
                >
                  {" "}
                  <Form.Date
                    dateFormat="DD MMM YYYY"
                    local
                    field="startDate"
                    placeholder="Start Date"
                    required
                    className="date-input"
                    disabled={readOnly}
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  End Date:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={9}
                  largeScreen={8}
                  widescreen={8}
                  textAlign="left"
                >
                  {" "}
                  <Form.Date
                    local
                    field="endDate"
                    placeholder="End Date"
                    dateFormat="DD MMM YYYY"
                    className="date-input"
                    disabled={readOnly}
                  />
                </GridColumn>
              </GridRow>
            </Grid>
          </GridColumn>
          <GridColumn
            mobile={16}
            tablet={16}
            computer={6}
            largeScreen={7}
            widescreen={8}
          >
            <Grid>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  Address Line 1:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={8}
                  largeScreen={9}
                  widescreen={10}
                >
                  {" "}
                  <Form.Input field="addressLine1" required />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  Address Line 2:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={8}
                  largeScreen={9}
                  widescreen={10}
                >
                  {" "}
                  <Form.Input field="addressLine2" />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  Address Line 3:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={8}
                  largeScreen={9}
                  widescreen={10}
                >
                  {" "}
                  <Form.Input field="addressLine3" />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  Town/City:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={8}
                  largeScreen={9}
                  widescreen={10}
                >
                  {" "}
                  <Form.Input field="townCity" />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  County:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={8}
                  largeScreen={9}
                  widescreen={10}
                >
                  {" "}
                  <Form.Input field="county" />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  Postcode:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={8}
                  largeScreen={9}
                  widescreen={10}
                >
                  {" "}
                  <Form.Input field="postCode" required />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn
                  mobile={7}
                  tablet={6}
                  computer={7}
                  largeScreen={7}
                  widescreen={5}
                >
                  Country:
                </GridColumn>
                <GridColumn
                  mobile={9}
                  tablet={10}
                  computer={8}
                  largeScreen={9}
                  widescreen={10}
                >
                  {" "}
                  <Form.Input field="country" required />
                </GridColumn>
              </GridRow>
            </Grid>
          </GridColumn>
        </Grid>
      </Form>
    </div>
  );
};
