import React from "react";
import { Segment, Sidebar, Icon } from "semantic-ui-react";
import { AppRoutes } from "constants/routes";
import { Link, withRouter } from "react-router-dom";
import CompanyLogo from "./CompanyLogo";
import MobileMainMenu from "./MobileMainMenu";

class MobileTopBar extends React.Component {
  state = {
    visible: true,
    location: "",
  };

  componentDidMount = () => {
    this.setState({ location: this.props.history.location });
  };

  showMenu = () => {
    this.setState({ visible: true });
  };

  hideMenu = () => {
    if (!this.props.active) return;
    this.setState({ visible: false });
  };

  static getDerivedStateFromProps = (props, state) => {
    if (props.history.location !== state.location) {
      return {
        location: props.history.location,
        visible: false,
      };
    }
    return state;
  };

  render() {
    const { children, active } = this.props;
    const { visible } = this.state;

    return (
      <React.Fragment>
        {active && (
          <div className="top-menu-container">
            <Link to={AppRoutes.Root} className="logo">
              <CompanyLogo />
            </Link>
            <div className="right-container">
              {visible ? (
                <Icon name="close" onClick={this.hideMenu} className="m-l-05" />
              ) : (
                <Icon
                  name="sidebar"
                  onClick={this.showMenu}
                  className="m-l-05"
                />
              )}
            </div>
          </div>
        )}
        <Sidebar.Pushable
          as={Segment}
          style={{ marginTop: "0" }}
          className={visible ? "visible" : ""}
        >
          {active && (
            <MobileMainMenu
              animation="overlay"
              direction="top"
              visible={visible}
              hideMenu={this.hideMenu}
            />
          )}
          <Sidebar.Pusher onClick={this.hideMenu}>{children}</Sidebar.Pusher>
        </Sidebar.Pushable>
      </React.Fragment>
    );
  }
}

export default withRouter(MobileTopBar);
