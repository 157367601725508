import React from "react";
import { Header } from "./";
import { getFMVInfo } from "./actions";
import { ContentBuilder } from "@redriver/cinnamon";
import { Table, Grid, TableBody } from "semantic-ui-react";
import {
  numericFormatter,
  dateFormatter,
} from "components/displays/DisplayHelper";

const FmvInfo = () => {
  return (
    <ContentBuilder
      loadAction={getFMVInfo}
      renderContent={(data) => (
        <React.Fragment>
          <Header showStatus={true} />
          <br />
          <br />
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Table className="fmv-table">
                  {TableHeader(`FMV Values (${process.env.MAJOR_CURRENCY})`)}
                  <TableBody>
                    {TwoColTableRow(
                      "Converted",
                      numericFormatter(data.converted)
                    )}
                    {TwoColTableRow(
                      "Unconverted",
                      numericFormatter(data.unconverted)
                    )}
                    {TwoColTableRow("", "", "empty")}
                    {TwoColTableRow(
                      "Total",
                      numericFormatter(data.total),
                      "total"
                    )}
                  </TableBody>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Table className="fmv-table">
                  {TableHeader(`Converted FMV (${process.env.MAJOR_CURRENCY})`)}
                  <TableBody>
                    {TwoColTableRow(
                      "Sum to Collect",
                      numericFormatter(data.sumToCollect)
                    )}
                    {TwoColTableRow(
                      "FMV Settled",
                      numericFormatter(data.fmvSettled)
                    )}
                    {TwoColTableRow("", "", "empty")}
                    {TwoColTableRow(
                      "C/F Amount",
                      numericFormatter(data.cfAmount),
                      "total"
                    )}
                  </TableBody>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Table className="fmv-table">
                  {TableHeader("FMV Values")}
                  <TableBody>
                    {TwoColTableRow(
                      `Value Due to Funder (${process.env.MAJOR_CURRENCY})`,
                      numericFormatter(data.valueDueToFunder)
                    )}
                    {TwoColTableRow("Due Date", dateFormatter(data.dueDate))}
                    {TwoColTableRow(
                      "Invoiced to Supplier?",
                      data.invoicedToSupplier
                    )}
                    {TwoColTableRow(
                      `Amount Invoiced (${process.env.MAJOR_CURRENCY})`,
                      numericFormatter(data.amountInvoiced)
                    )}
                    {TwoColTableRow("Paid to Funder?", data.paidToFunder)}
                    {TwoColTableRow(
                      `Amount Paid (${process.env.MAJOR_CURRENCY})`,
                      numericFormatter(data.amountPaid)
                    )}
                    {TwoColTableRow(
                      `Unconverted Amount Settled (${process.env.MAJOR_CURRENCY})`,
                      numericFormatter(data.unconvertedAmountSettled)
                    )}
                    {TwoColTableRow("", "", "empty")}
                    {TwoColTableRow(
                      `Unconverted Amount C/F (${process.env.MAJOR_CURRENCY})`,
                      numericFormatter(data.unconvertedAmountCF),
                      "total"
                    )}
                  </TableBody>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </React.Fragment>
      )}
    />
  );
};

const TwoColTableRow = (title, value, className = "") => (
  <Table.Row className={className}>
    <Table.Cell>{title}</Table.Cell>
    <Table.Cell>{value}</Table.Cell>
  </Table.Row>
);

const TableHeader = (title) => (
  <Table.Header>
    <Table.HeaderCell colSpan={2}>{title}</Table.HeaderCell>
  </Table.Header>
);

export default FmvInfo;
