import React from "react";
import { Form } from "@redriver/cinnamon";
import InchesInput from "../../../../components/forms/InchesInput";
import { useTranslation } from "react-i18next";

const RollWidthModalForm = ({ formProps }) => {
  const reqMessage = 'Width must be provided as mm, inch, or both';
  let {value} = formProps || {};
  value.widthInches && value.widthInches.friendlyString
    && (value.widthInches = value.widthInches.friendlyString);

  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'largeFormat.rollWidth.modalForm'});

  return (
    <Form {...formProps}>
      <Form.Input
        required
        field="name"
        label={t("name")}
        placeholder={t("namePlaceholder")}
      />
      <Form.Numeric
        field="widthMm"
        label={t("width")}
        placeholder={t("widthPlaceholder")}
        required={
          formProps.value
            && !(formProps.value.widthInches || '').toString().length
        }
        requiredError={t("reqMessage")}
      />
      <InchesInput
        field='widthInches'
        label={t("widthInches")}
        placeholder={t("widthPlaceholder")}
        required={
          formProps.value
            && !(formProps.value.widthMm || '').toString().length
        }
        requiredError={t("reqMessage")}
        showErrors={formProps.showErrors}
      />
    </Form>
  );
};

export default RollWidthModalForm;
