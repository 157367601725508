export default {
    Summary: "summary",
    Signatories: "signatories",
    Attachments: "attachments",
    Schedules: "schedules",
    Products: "products",
    Invoices: "invoices",
    Documents: "documents",
    Banking: "banking",
  };
  