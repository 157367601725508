import React from "react";
import { ActionBar, ListBuilder, FormBuilder } from "@redriver/cinnamon";
import { getFunderAuthorisedSignatories } from "./actions";
import { Table, TableCell } from "semantic-ui-react";
import { ContentPanel } from "components/displays";
import { TextSearch } from "features/../../../shared/components/search";
import { TablePagination } from "components/pagination";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { SortHeader } from "features/../../../shared/components/tables/sortableTable";
import CreateFunderSignatory from "./CreateFunderSignatory";
import { Targets, Actions } from "constants/permissions";
import EditFunderSignatory from "./EditFunderSignatory";
import DeleteFunderSignatory from "./DeleteFunderSignatory";

const ListFunderSignatories = ({ funderId }) => {
  return (
    <div className="spv-list">
      <ListBuilder
        debounceFilters={600}
        loadAction={getFunderAuthorisedSignatories}
        loadParams={{ funderId: funderId }}
        initialPagination={{ pageSize: 25, pageNumber: 1 }}
        initialFilters={{
          search: "",
        }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        renderList={(data, state, events) => (
          <React.Fragment>
            <ContentPanel
              title="Funder Signatories"
              wrapperCssClass="outline-grid spvs-list yellow"
              headerCssClass="defaults-page-title yellow"
              headerContent={
                <FilterBar
                  state={state}
                  events={events}
                  initialPageSize={25}
                  onAdded={events.onRefresh}
                  funderId={funderId}
                ></FilterBar>
              }
            >
              <div className="spaced">
                <SignatoryTable
                  state={state}
                  events={events}
                  data={data}
                  funderId={funderId}
                />
                <br />
                <TablePagination
                  pageNumber={state.pagination.pageNumber}
                  onChange={events.onChangePagination}
                  pageSize={state.pagination.pageSize}
                  totalItems={state.total}
                />
              </div>
            </ContentPanel>
          </React.Fragment>
        )}
      />
    </div>
  );
};

const SignatoryTable = ({ data, state, events, funderId }) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <SortHeader
              field="name"
              title="Name"
              value={state.filters}
              onChange={events.onChangeFilters}
            />
          </Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Position</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data &&
          data.map((item) => (
            <Table.Row key={item.id}>
              <Table.Cell>{item.name}</Table.Cell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{item.position}</TableCell>
              <TableCell>
                {" "}
                <React.Fragment>
                  <EditFunderSignatory
                    signatory={item}
                    funderId={funderId}
                    onRefresh={events.onRefresh}
                  />
                  {"  "}
                  <DeleteFunderSignatory
                    item={item}
                    funderId={funderId}
                    header="Delete Signatory"
                    onDeleted={events.onRefresh}
                  />
                </React.Fragment>
              </TableCell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};

const FilterBar = ({ state, events, funderId }) => (
  <FormBuilder
    renderForm={() => (
      <ActionBar className="filter-display">
        <ActionBar.Item>
          <TextSearch
            filters={state.filters}
            onChange={events.onChangeFilters}
          ></TextSearch>
        </ActionBar.Item>
        <ActionBar.Space />
        <PermissionCheck target={Targets.Funder} action={Actions.Edit}>
          <ActionBar.Item align="right">
            <CreateFunderSignatory
              onRefresh={events.onRefresh}
              funderId={funderId}
            />
          </ActionBar.Item>
        </PermissionCheck>
        <ActionBar.Space />
      </ActionBar>
    )}
  />
);

export default ListFunderSignatories;
