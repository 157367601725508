import React from "react";
import { Table } from "semantic-ui-react";
import { getMS } from ".";
import { ListBuilder } from "@redriver/cinnamon";
import {
  yesNoFormatter,
  numericFormatter,
} from "components/displays/DisplayHelper";
import { SortableHeader } from "../../../../../../shared/components/tables/sortableTable";

export const MeteredServices = ({ isSummary }) => {
  const headers = [
    { field: "unit", title: "Unit" },
    { field: "description", title: "Item Description" },
    { field: "payableTo", title: "Payable To" },
    { field: "managed", title: "Managed" },
    { field: "vol", title: "Volume" },
    { field: "cpi", title: `CPI (${process.env.MINOR_CURRENCY})` },
    { field: "value", title: `Value (${process.env.MAJOR_CURRENCY})` },
    { name: "", className: "empty", key: 1, sortable: false },
    {
      field: "totalLiability",
      title: `Total Liability Values (${process.env.MAJOR_CURRENCY})`,
      display: isSummary,
    },
    {
      name: "",
      className: "empty",
      key: 2,
      display: isSummary,
      sortable: false,
    },
    {
      field: "settlement",
      title: `Custom Settlement Values (${process.env.MAJOR_CURRENCY})`,
      className: "yellow-highlight",
      display: isSummary,
    },
    {
      field: "scheduleSettlementPercent",
      name: "%",
      className: "red-highlight",
      display: !isSummary,
    },
    {
      field: "scheduleSettlement",
      title: `(${process.env.MAJOR_CURRENCY})`,
      className: "red-highlight square",
      display: !isSummary,
    },
  ];

  return (
    <div>
      <ListBuilder
        loadAction={getMS}
        renderList={(data, state, events) =>
          data.ms && (
            <Table className="liability-meter-services right-align">
              {!isSummary && (
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      className="empty border-right"
                      colSpan={8}
                    ></Table.HeaderCell>
                    <Table.HeaderCell
                      className="red-highlight settlement-title"
                      colSpan={2}
                    >
                      Schedule Settlement
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              )}
              <SortableHeader columns={headers} state={state} events={events} />
              <Table.Body>
                {data.ms.map((d) => (
                  <TableRowMS key={d.id} data={d} isSummary={isSummary} />
                ))}

                <Table.Row>
                  <Table.Cell
                    className="empty no-right-border"
                    colSpan={11}
                  ></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="empty" colSpan={4}></Table.Cell>
                  <Table.Cell className="">
                    {numericFormatter(data.volTotal, "-", 0)}
                  </Table.Cell>
                  <Table.Cell className="empty"></Table.Cell>
                  <Table.Cell className="">
                    {numericFormatter(data.valueTotal, "-")}
                  </Table.Cell>
                  <Table.Cell
                    className={"empty " + (!isSummary ? "no-right-border" : "")}
                  ></Table.Cell>
                  {isSummary ? (
                    <React.Fragment>
                      <Table.Cell className="">
                        {numericFormatter(data.totalLiabilityTotal, "-")}
                      </Table.Cell>
                      <Table.Cell className="empty"></Table.Cell>
                      <Table.Cell className="">
                        {numericFormatter(data.settlementTotal, "-")}
                      </Table.Cell>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Table.Cell className="empty"></Table.Cell>
                      <Table.Cell className="">
                        {numericFormatter(data.scheduleSettlementTotal, "-")}
                      </Table.Cell>
                    </React.Fragment>
                  )}
                </Table.Row>
              </Table.Body>
            </Table>
          )
        }
      ></ListBuilder>
    </div>
  );
};

const TableRowMS = ({ data, isSummary }) => (
  <Table.Row>
    <Table.Cell style={{ width: "6%" }} className="center-align">
      {data.unit}
    </Table.Cell>
    <Table.Cell style={{ width: "15%" }} className="center-align">
      {data.description}
    </Table.Cell>
    <Table.Cell style={{ width: "11%" }} className="center-align">
      {data.payableTo}
    </Table.Cell>
    <Table.Cell style={{ width: "8%" }} className="center-align">
      {yesNoFormatter(data.managed, true)}
    </Table.Cell>
    <Table.Cell style={{ width: "11%" }}>{data.vol}</Table.Cell>
    <Table.Cell style={{ width: "11%" }}>
      {numericFormatter(data.cpi, "-", 3)}
    </Table.Cell>
    <Table.Cell style={{ width: "11%" }}>
      {numericFormatter(data.value, "-")}
    </Table.Cell>
    <Table.Cell style={{ width: "3%" }} className="empty"></Table.Cell>
    {isSummary ? (
      <React.Fragment>
        <Table.Cell style={{ width: "11%" }}>
          {numericFormatter(data.totalLiability, "-")}
        </Table.Cell>
        <Table.Cell style={{ width: "2%" }} className="empty"></Table.Cell>
        <Table.Cell style={{ width: "11%" }}>
          {numericFormatter(data.settlement, "-")}
        </Table.Cell>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Table.Cell style={{ width: "11%" }}>
          {numericFormatter(data.scheduleSettlementPercent, "-", 0)}
        </Table.Cell>
        <Table.Cell style={{ width: "11%" }}>
          {numericFormatter(data.scheduleSettlement, "-")}
        </Table.Cell>
      </React.Fragment>
    )}
  </Table.Row>
);
