import React from "react";
import { Table } from "@redriver/cinnamon";
import SortHeader from "./SortHeader";

const SortableTable = ({ tableProps, emptyMessage, columns, events, state, className, selectable=true }) => {

  return (
    <Table
      {...tableProps}
      dataKey="field"
      emptyMessage={emptyMessage}
      selectable={selectable}
      className={className}
    >
      {columns.map(c => (
        <Table.Column
            className={c.className}
            header={ c.sortable === undefined || c.sortable ?
                <SortHeader
                field={c.field}
                title={c.title}
                value={state.filters}
                onChange={events.onChangeFilters}
                />
                :
                c.title
            }
          key={c.field}
          field={c.field}
          title={c.title}
          width={c.width}
          render={c.render}
        />
      ))}
    </Table>
  );
};

export default SortableTable;
