import React from "react";
import MasterAgreementTable from "./MasterAgreementTable";
import ScheduleItemsTable from "./ScheduleItemsTable";
import ScheduleSummaryTable from "./ScheduleSummaryTable";
import { ContentPanel } from "components/displays";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";

const Summary = ({ scheduleId }) => {
  return (
    <ContentPanel
      title="Summary"
      wrapperCssClass="outline-grid"
      headerCssClass="defaults-page-title"
    >
      <div className="spaced">
        <Grid doubling>
          <GridRow>
            <GridColumn width={7}>
              <ScheduleSummaryTable />
              <br />
              <MasterAgreementTable />
            </GridColumn>
            <GridColumn width={9}>
              <ScheduleItemsTable scheduleId={scheduleId} />
            </GridColumn>
          </GridRow>
        </Grid>
        <br />
      </div>
    </ContentPanel>
  );
};

export default Summary;
