import { apiGet, mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULE_SUMMARY";
const ActionTypes = {
  GetScheduleItems: `${NAMESPACE}/GET_SCHEDULE_ITEMS`,
  GetScheduleSummaryHeader: `${NAMESPACE}/GET_SCHEDULE_SUMMARY_HEADER`,
};

const mockHeaderData = {
  customerName: "ETHELBERT SPECIALIST HOMES LTD",
  companyRegistrationNumber: "OC837838",
  companyId: "70013",
  tradingAddress: "123 Made Up Street. Neverland, GU66 6UL",
  supplierId: "58645",
  supplierName: "KONICA MINOLTA UK LIMITED",
  accountManager: "RICHARD CASHMAN",
  spv: "IP Syndicate SPV I Limited",
  scheduleReference: "PRIO1-MA2678-SC6231",
  status: "Live",
  scheduleStartDate: "2020-01-15 00:00:00.000Z",
  totalManagedVolume: 2478541,
  remainingManagedVolume: 1475212,
  quarterlyManagedVolume: 224712,
  volumeRemainingPercentage: 59.5,
  scheduleLiabilityValue: 64485.71,
  scheduleDiscountPercentage: 12.28,
  nextMinimumDueDate: "2021-04-15 00:00:00.000Z",
  nextMinimumInvoiceValue: 9822.8,
  nextMeterDueDate: "2021-01-15 00:00:00.000Z",
};

export const getScheduleItems = ({ filters, pagination }, { scheduleId }) => {
  return apiGet(ActionTypes.GetScheduleItems, "schedules/items", {
    ...pagination,
    ...filters,
    scheduleId,
  });
};

export const getScheduleSummaryHeader = () => {
  return mockSuccess(ActionTypes.GetScheduleSummaryHeader, {
    response: mockHeaderData,
  });
};
