import React from "react";
import { IconButton } from "features/../../../shared/components/buttons";
import { ModalBuilder } from "@redriver/cinnamon";
import { deleteTargetMarket } from "./actions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import { TranslatedModal } from "../../../../components/modals";

const DeleteTargetMarket = ({
  as: As = IconButton,
  onSubmitted,
  targetMarket,
  ...props
}) => (
  <PermissionCheck target={Targets.LargeFormat} action={Actions.Delete}>
    <ModalBuilder
      submitAction={deleteTargetMarket}
      submitParams={targetMarket.id}
      onSubmitted={onSubmitted}
      renderTrigger={showModal => (
        <As onClick={showModal} {...props}>
          Delete
        </As>
      )}
      renderModal={modalProps => (
        <TranslatedModal.Confirmation
          {...modalProps}
          header="Delete Target Market"
          submitLabel="Delete Target Market"
        >
          <p>Are you sure that you wish to remove this item?</p>
        </TranslatedModal.Confirmation>
      )}
    />
  </PermissionCheck>
);

export default DeleteTargetMarket;
