import { mockSuccess, MockArrayData } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULES/LiabilitySettlement";

const ActionTypes = {
  GetLsSummary: `${NAMESPACE}/GET_L_S_Summary`,
  GetOSB: `${NAMESPACE}/GET_OSB`,
  GetFinances: `${NAMESPACE}/GET_FINANCES`,
  GetMS: `${NAMESPACE}/GET_MS`,
  GetQS: `${NAMESPACE}/GET_QS`,
  GetCSB: `${NAMESPACE}/GET_CSB`,
  GetCSD: `${NAMESPACE}/GET_CSD`,
};

const lsSummary = {
  id: 1,
  applicableFromDt: "03/03/2021",
  scheduleTotalVol: 1293440,
  scheduleTotalVolUser: 264758,
  totalVolRemaining: 1028682,
  quarterlyManagedVol: 86000,
  quarterlyMinimumPayment: 9055.35,
  outstandingScheduleValue: 108314.83,
  applyDiscount: true,
  discountAmount: 5.8,
  discountValue: 6282.26,
  incEarlyTermFee: true,
  earlyTermFee: 200,
  settlementPayment: 102032.57,
};

const osb = new MockArrayData([
  {
    id: 1,
    dealer: "Konica Minolta",
    finance: 43502.23,
    meterService: 25907.23,
    quarterlyService: 5478.33,
    rounding: 0,
    total: 74887.79,
  },
  {
    id: 2,
    dealer: "IP Syndicate",
    finance: 9490.96,
    meterService: 0,
    quarterlyService: 182.41,
    rounding: 0.02,
    total: 9673.39,
  },
  {
    id: 3,
    dealer: "BNP",
    finance: 12587.29,
    meterService: 0,
    quarterlyService: 0,
    rounding: 0,
    total: 12587.29,
  },
  {
    id: 4,
    dealer: "PEAC",
    finance: 11166.36,
    meterService: 0,
    quarterlyService: 0,
    rounding: 0,
    total: 11166.36,
  },
  {
    id: 5,
    dealer: "Totals",
    finance: 76746.84,
    meterService: 25907.23,
    quarterlyService: 5660.23,
    rounding: 0.02,
    total: 108314.83,
  },
]);

const finances = {
  cfaPaymentTotal: 6416.2,
  cfaOutstandingTotal: 34517.91,
  supplierTotal: 29286.4,
  funderTotal: 1621.83,
  ipsTotal: 9601.34,
  liabilityTotal: 76764.84,
  settlementTotal: 57394.12,
  calcRoundingPayaleTo: "IP Syndicate",
  calcRoundingCFAPayment: 0.26,
  calcRoundingScheduleSettlementPercent: 100,
  calcRoundingScheduleSettlement: 0.26,
  scheduleSettlementTotal: 6416.46,
  fa: [
    {
      id: 1,
      cfaRef: "Z8804575",
      payableTo: "BNP",
      funderType: "Funder",
      cfaPayment: 1511.11,
      cfaOutstanding: 0,
      supplier: 1807.5,
      funder: 12652.51,
      ips: 3615.01,
      settlement: 11568.01,
      liability: 18075.02,
      scheduleSettlementPercent: 100,
      scheduleSettlement: 18075.02,
    },
    {
      id: 2,
      cfaRef: "A1A02273",
      payableTo: "BNP",
      funderType: "Funder",
      cfaPayment: 377.78,
      cfaOutstanding: 0,
      supplier: 903.76,
      funder: 3163.15,
      ips: 451.87,
      settlement: 2756.45,
      liability: 4518.78,
      scheduleSettlementPercent: 100,
      scheduleSettlement: 4518.78,
    },
    {
      id: 3,
      cfaRef: "A1A04575",
      payableTo: "BNP",
      funderType: "Funder",
      cfaPayment: 712.67,
      cfaOutstanding: 997.74,
      supplier: 752.68,
      funder: 5268.76,
      ips: 1505.36,
      settlement: 5314.27,
      liability: 8524.54,
      scheduleSettlementPercent: 100,
      scheduleSettlement: 8524.54,
    },
    {
      id: 4,
      cfaRef: "A1AB5745",
      payableTo: "BNP",
      funderType: "Funder",
      cfaPayment: 857.48,
      cfaOutstanding: 7888.82,
      supplier: 236.79,
      funder: 1657.5,
      ips: 473.57,
      settlement: 7731.78,
      liability: 10256.68,
      scheduleSettlementPercent: 100,
      scheduleSettlement: 10256.68,
    },
    {
      id: 5,
      cfaRef: "AALF164381",
      payableTo: "PEAC",
      funderType: "Funder",
      cfaPayment: 502.48,
      cfaOutstanding: 4622.82,
      supplier: 277.51,
      funder: 639.78,
      ips: 416.26,
      settlement: 5552.48,
      liability: 5956.37,
      scheduleSettlementPercent: 100,
      scheduleSettlement: 6010.37,
    },
    {
      id: 6,
      cfaRef: "AALF274571",
      payableTo: "PEAC",
      funderType: "Funder",
      cfaPayment: 642.52,
      cfaOutstanding: 5911.18,
      funder: 887.14,
      supplier: 354.85,
      ips: 532.28,
      settlement: 7099.93,
      liability: 7685.45,
      scheduleSettlementPercent: 100,
      scheduleSettlement: 7685.45,
    },
    {
      id: 7,
      cfaRef: "KONAM454",
      payableTo: "Konica Minolta",
      funderType: "Supplier",
      cfaPayment: 745.11,
      cfaOutstanding: 5364.79,
      supplier: 0,
      funder: 2838.22,
      ips: 709.56,
      settlement: 6851.55,
      liability: 8912.57,
      scheduleSettlementPercent: 100,
      scheduleSettlement: 8912.57,
    },
    {
      id: 8,
      cfaRef: "IPSDF7584",
      payableTo: "IP Syndicate",
      funderType: "Supplier",
      cfaPayment: 42.15,
      cfaOutstanding: 303.48,
      supplier: 0,
      funder: 0,
      ips: 200.69,
      settlement: 464.03,
      liability: 504.17,
      scheduleSettlementPercent: 100,
      scheduleSettlement: 504.17,
    },
    {
      id: 9,
      cfaRef: "KONAM975",
      payableTo: "Konica Minolta",
      funderType: "Supplier",
      cfaPayment: 962.07,
      cfaOutstanding: 8851.04,
      supplier: 0,
      funder: 2125.34,
      ips: 531.34,
      settlement: 9338.78,
      liability: 11507.72,
      scheduleSettlementPercent: 100,
      scheduleSettlement: 11507.72,
    },
    {
      id: 10,
      cfaRef: "IPSDF9753",
      payableTo: "IP Syndicate",
      funderType: "Supplier",
      cfaPayment: 62.83,
      cfaOutstanding: 578.04,
      supplier: 0,
      funder: 0,
      ips: 173.5,
      settlement: 716.84,
      liability: 751.54,
      scheduleSettlementPercent: 100,
      scheduleSettlement: 751.54,
    },
  ],
};

const meteredServices = {
  volTotal: 142500,
  valueTotal: 2165.9,
  totalLiabilityTotal: 25907.23,
  settlementTotal: 20671.96,
  scheduleSettlementTotal: 1732.72,
  ms: [
    {
      id: 1,
      unit: "B1",
      description: "Black Unit",
      payableTo: "Konica Minolta",
      managed: true,
      vol: 50000,
      cpi: 0.356,
      value: 178,
      totalLiability: 2129.13,
      settlement: 1703.3,
      scheduleSettlementPercent: 80,
      scheduleSettlement: 142.4,
    },
    {
      id: 2,
      unit: "C1",
      description: "Colour Unit",
      payableTo: "Konica Minolta",
      managed: true,
      vol: 36000,
      cpi: 3.2,
      value: 1152,
      totalLiability: 13779.55,
      settlement: 11023.64,
      scheduleSettlementPercent: 80,
      scheduleSettlement: 921.6,
    },

    {
      id: 3,
      unit: "S1",
      description: "Scan Unit",
      payableTo: "Konica Minolta",
      managed: false,
      vol: 15000,
      cpi: 0.15,
      value: 22.5,
      totalLiability: 269.13,
      settlement: 161.48,
      scheduleSettlementPercent: 80,
      scheduleSettlement: 18.0,
    },

    {
      id: 4,
      unit: "B2",
      description: "Black Unit",
      payableTo: "Konica Minolta",
      managed: false,
      vol: 25000,
      cpi: 0.475,
      value: 118.75,
      totalLiability: 1420.42,
      settlement: 1136.34,
      scheduleSettlementPercent: 80,
      scheduleSettlement: 95.0,
    },

    {
      id: 5,
      unit: "C2",
      description: "Colour Unit",
      payableTo: "Konica Minolta",
      managed: false,
      vol: 16500,
      cpi: 4.21,
      value: 694.65,
      totalLiability: 8309,
      settlement: 6647.2,
      scheduleSettlementPercent: 80,
      scheduleSettlement: 555.72,
    },
  ],
};

const quarterlyServices = {
  valueTotal: 473.25,
  outstandingTotal: 5660.74,
  settlementValueTotal: 788.25,
  subTtotal: 108314.81,
  total: 108314.83,
  settlementSubTotal: 78854.33,
  settlementTotal: 78853.2,
  totalRounding: 0.02,
  totalSettlementRounding: -1.13,
  scheduleSettlement: 27.2,
  calcRoundingPayaleTo: "IP Syndicate",
  calcRoundingValue: 1.28,
  calcRoundingScheduleSettlementPercent: 80,
  calcRoundingScheduleSettlement: 1.02,
  calcRoundingManged: 0.77,
  calcRoundingNonManged: 0.51,
  calcRoundingScheduleSettlementManaged: 1.28,
  calcRoundingScheduleSettlementnonManaged: 1.28,
  scheduleSettlementManaged: 229.11,
  scheduleSettlementNonManaged: 150.51,
  scheduleSettlementSubTotal: 379.62,
  scheduleSettlementTotal: 8528.8,
  nonManagedTotal: 188.15,
  managedTotal: 286.38,

  qs: [
    {
      id: 1,
      service: "Device Charge",
      payableTo: "Konica Minolta",
      value: 37.5,
      outstanding: 448.55,
      settlement: 0,
      managed: 22.63,
      nonManaged: 14.87,
      scheduleSettlementPercent: 80,
      scheduleSettlement: 30,
      scheduleSettlementManaged: 37.5,
      scheduleSettlementNonManaged: 37.5,
    },
    {
      id: 2,
      service: "Excel",
      payableTo: "Konica Minolta",
      value: 150,
      outstanding: 1794.21,
      settlement: 0,
      managed: 90.53,
      nonManaged: 59.47,
      scheduleSettlementPercent: 80,
      scheduleSettlement: 120,
      scheduleSettlementManaged: 150,
      scheduleSettlementNonManaged: 150,
    },
    {
      id: 3,
      service: "Sales Capital Reserve",
      payableTo: "Konica Minolta",
      value: 175,
      outstanding: 2093.25,
      settlement: 0,
      managed: 105.61,
      nonManaged: 69.39,
      scheduleSettlementPercent: 80,
      scheduleSettlement: 140,
      scheduleSettlementManaged: 175,
      scheduleSettlementNonManaged: 175,
    },
    {
      id: 4,
      service: "Uniflow Support",
      payableTo: "Konica Minolta",
      value: 95.5,
      outstanding: 1142.32,
      settlement: 642.32,
      managed: 57.64,
      nonManaged: 37.86,
      scheduleSettlementPercent: 80,
      scheduleSettlement: 76.4,
      scheduleSettlementManaged: 95.5,
      scheduleSettlementNonManaged: 95.5,
    },
    {
      id: 5,
      service: "IPS Admin fee",
      payableTo: "IP Syndicate",
      value: 15.25,
      outstanding: 182.41,
      settlement: 145.93,
      managed: 9.2,
      nonManaged: 6.05,
      scheduleSettlementPercent: 80,
      scheduleSettlement: 12.2,
      scheduleSettlementManaged: 15.25,
      scheduleSettlementNonManaged: 15.25,
    },
  ],
};

const csb = new MockArrayData([
  {
    id: 1,
    finance: 29988.71,
    meterServices: 7982.02,
    quarterlyServices: 642.32,
    rounding: 0,
    total: 38613.05,
  },
  {
    id: 2,
    finance: 8130.99,
    meterServices: 0,
    quarterlyServices: 145.93,
    rounding: 2.62,
    total: 8279.54,
  },
  {
    id: 3,
    finance: 7603.98,
    meterServices: 0,
    quarterlyServices: 0,
    rounding: 0,
    total: 7603.98,
  },
  {
    id: 4,
    finance: 10850.17,
    meterServices: 0,
    quarterlyServices: 0,
    rounding: 0,
    total: 10850.17,
  },
  {
    id: 5,
    finance: 56573.85,
    meterServices: 7982.02,
    quarterlyServices: 788.25,
    rounding: 2.62,
    total: 65346.74,
  },
]);

const csd = {
  totaltitle: "Quarterly Total",
  totalVolume: 1028682,
  totalMinimumPayment: 9056.89,
  totalSettlementValue: 8528.8,
  scheduleDiscount: 5.83,
  csda: [
    {
      id: 1,
      title: "Quarterly Managed",
      volume: 1293440,
      minimumPayment: 8032.84,
      settlementValues: 7709.57,
    },
    {
      id: 2,
      title: "Quarterly Non-Managed",
      volume: 264758,
      minimumPayment: 1024.05,
      settlementValues: 819.23,
    },
  ],
};

export const getLsSummary = () => {
  return mockSuccess(ActionTypes.GetLsSummary, {
    response: lsSummary,
  });
};

export const getOSB = () => {
  return mockSuccess(ActionTypes.GetOSB, {
    response: osb._data,
  });
};

export const getFinances = () => {
  return mockSuccess(ActionTypes.GetFinances, {
    response: finances,
  });
};

export const getMS = () => {
  return mockSuccess(ActionTypes.GetMS, {
    response: meteredServices,
  });
};

export const getQS = () => {
  return mockSuccess(ActionTypes.GetQS, {
    response: quarterlyServices,
  });
};

export const getCSB = () => {
  return mockSuccess(ActionTypes.GetCSB, {
    response: csb._data,
  });
};

export const getCSD = () => {
  return mockSuccess(ActionTypes.GetCSD, {
    response: csd,
  });
};
