import React from "react";
import { Form } from "@redriver/cinnamon";
import { getMeteredServices } from "./actions";

const MeteredServiceDropdown = ({ dealerOfficeId, isLargeFormat, ...props }) => {
    return (<Form.Dropdown
        {...props}
        lookupAction={getMeteredServices}
        lookupParams={{ dealerOfficeId, isLargeFormat }}
        lookupOptions={{
            transform: data => data.map(i => ({ value: i.id, text: `${i.code} - ${i.name}` }))
        }}
    />);
}

export default MeteredServiceDropdown;