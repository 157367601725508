import React from "react";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";
import { Form } from "@redriver/cinnamon";

const AddressForm = ({ isDealerOfficeForm = true }) => {
  return (
    <Grid>
      <Form.Object field="address">
        <GridRow>
          <GridColumn
            mobile={7}
            tablet={6}
            computer={7}
            largeScreen={7}
            widescreen={5}
          >
            <h3>Address Details</h3>
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn
            mobile={7}
            tablet={6}
            computer={7}
            largeScreen={7}
            widescreen={5}
          >
            Address Line 1:
          </GridColumn>
          <GridColumn
            mobile={9}
            tablet={10}
            computer={8}
            largeScreen={9}
            widescreen={10}
          >
            {" "}
            <Form.Input field="line1" />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn
            mobile={7}
            tablet={6}
            computer={7}
            largeScreen={7}
            widescreen={5}
          >
            Address Line 2:
          </GridColumn>
          <GridColumn
            mobile={9}
            tablet={10}
            computer={8}
            largeScreen={9}
            widescreen={10}
          >
            {" "}
            <Form.Input field="line2" />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn
            mobile={7}
            tablet={6}
            computer={7}
            largeScreen={7}
            widescreen={5}
          >
            Address Line 3:
          </GridColumn>
          <GridColumn
            mobile={9}
            tablet={10}
            computer={8}
            largeScreen={9}
            widescreen={10}
          >
            {" "}
            <Form.Input field="line3" />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn
            mobile={7}
            tablet={6}
            computer={7}
            largeScreen={7}
            widescreen={5}
          >
            Town/City:
          </GridColumn>
          <GridColumn
            mobile={9}
            tablet={10}
            computer={8}
            largeScreen={9}
            widescreen={10}
          >
            {" "}
            <Form.Input field="town" />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn
            mobile={7}
            tablet={6}
            computer={7}
            largeScreen={7}
            widescreen={5}
          >
            County:
          </GridColumn>
          <GridColumn
            mobile={9}
            tablet={10}
            computer={8}
            largeScreen={9}
            widescreen={10}
          >
            {" "}
            <Form.Input field="county" />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn
            mobile={7}
            tablet={6}
            computer={7}
            largeScreen={7}
            widescreen={5}
          >
            Post Code:
          </GridColumn>
          <GridColumn
            mobile={9}
            tablet={10}
            computer={8}
            largeScreen={9}
            widescreen={10}
          >
            {" "}
            <Form.Input field="postcode" />
          </GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn
            mobile={7}
            tablet={6}
            computer={7}
            largeScreen={7}
            widescreen={5}
          >
            Country:
          </GridColumn>
          <GridColumn
            mobile={9}
            tablet={10}
            computer={8}
            largeScreen={9}
            widescreen={10}
          >
            {" "}
            <Form.Input field="country" />
          </GridColumn>
        </GridRow>
      </Form.Object>
      {isDealerOfficeForm && (
        <>
          <GridRow>
            <GridColumn
              mobile={7}
              tablet={6}
              computer={7}
              largeScreen={7}
              widescreen={5}
            >
              Country Telecode:
            </GridColumn>
            <GridColumn
              mobile={9}
              tablet={10}
              computer={8}
              largeScreen={9}
              widescreen={10}
            >
              {" "}
              <Form.Numeric field="countryTelecode" />
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn
              mobile={7}
              tablet={6}
              computer={7}
              largeScreen={7}
              widescreen={5}
            >
              Telephone:
            </GridColumn>
            <GridColumn
              mobile={9}
              tablet={10}
              computer={8}
              largeScreen={9}
              widescreen={10}
            >
              {" "}
              <Form.Input field="telephone" />
            </GridColumn>
          </GridRow>
        </>
      )}
    </Grid>
  );
};

export default AddressForm;
