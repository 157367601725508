import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { ManageProduct } from "features/Products";
import { RedirectBreadcrumbBar } from "pages/nav";
import { AppRoutes } from "constants/routes";

const ProductPage = ({ match }) => {

return(

  <PageContent className="manage-schedule">
    <RedirectBreadcrumbBar to={AppRoutes.Products} text="Back to Products" />
    <ManageProduct productId={match.params.id} key={match.params.id} /> 
  </PageContent>
)};

export default ProductPage;
