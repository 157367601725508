import React from "react";
import { ModalBuilder, Lookup } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { IconButton } from "features/../../../shared/components/buttons";
import Add from "features/../../../shared/assets/white-add.svg";
import RateGroupForm from "./RateGroupForm";
import { TranslatedModal } from "../../../components/modals";
import {  addRateGroup } from "./actions";

const AddRateGroupModal = ({ onSubmitted, dealerOfficeId, timezone }) => (
  <PermissionCheck target={Targets.Defaults} action={Actions.Edit}>
    <ModalBuilder
      withForm
      submitAction={addRateGroup}
      submitParams={{ dealerOfficeId, timezone }}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => (
        <IconButton
          icon={Add}
          primary
          content="New Rate Group"
          onClick={showModal}
        />
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Add
          {...modalProps}
          size="small"
          header="Add Rate Group"
          submitLabel="Create Rate Group"
        >
          <RateGroupForm formProps={formProps} />
        </TranslatedModal.Add>
      )}
    />
  </PermissionCheck>
);

export default AddRateGroupModal;
