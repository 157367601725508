import { apiGet, apiPost, apiPut, apiDelete } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_PRINTER";

const ActionTypes = {
  GetPrinters: `${NAMESPACE}/GET_PRINTERS`,
  AddPrinter: `${NAMESPACE}/ADD_PRINTER`,
  UpdatePrinter: `${NAMESPACE}/UPDATE_PRINTER`,
  DeletePrinter: `${NAMESPACE}/DELETE_PRINTER`,
};

export const getPrinters = ({ pagination }, { dealerOfficeId }) =>
  apiGet(ActionTypes.GetPrinters, `printers`, {
    ...pagination,
    dealerOfficeId,
  });

export const addPrinter = (data, { dealerOfficeId }) =>
  apiPost(ActionTypes.AddPrinter, `printers`, { ...data, dealerOfficeId });

export const updatePrinter = (data, { printerId, dealerOfficeId }) =>
  apiPut(ActionTypes.UpdatePrinter, `printers/${printerId}`, {
    ...data,
    dealerOfficeId,
  });

export const deletePrinter = ({ printerId }) =>
  apiDelete(ActionTypes.DeletePrinter, `printers/${printerId}`);
