import React from "react";
import { ContentPanel } from "components/displays";
import { Table } from "semantic-ui-react";
import {
  numericFormatter,
  currencyFormatter,
} from "components/displays/DisplayHelper";

const ScheduleInformation = ({ cfaScheduleDetails }) => (
  <React.Fragment>
    <ContentPanel
      title="Schedule Information for CFA"
      wrapperCssClass="outline-grid"
      headerCssClass="defaults-page-title"
    >
      <div className="padded cfa-schedule-information">
        <CFAPaymentTable cfaScheduleDetails={cfaScheduleDetails} />
        <TMVTable cfaScheduleDetails={cfaScheduleDetails} />
        <QuarterlyManagedVolumeTable cfaScheduleDetails={cfaScheduleDetails} />
        <ScheduleFinanceAmountsCollectedTable
          cfaScheduleDetails={cfaScheduleDetails}
        />
        <ScheduleFinanceAmountsRemainingTable
          cfaScheduleDetails={cfaScheduleDetails}
        />
        <ScheduleAdditionalUnitsAllocationTable
          cfaScheduleDetails={cfaScheduleDetails}
        />
      </div>
    </ContentPanel>
  </React.Fragment>
);

const CFAPaymentTable = ({ cfaScheduleDetails }) => (
  <Table className="cfa-cfa-payment-table">
    <Table.Header>
      <Table.Row className="teal">
        <Table.Cell className="no-border" />
        <Table.HeaderCell>Value {process.env.MAJOR_CURRENCY}</Table.HeaderCell>
        <Table.HeaderCell>CFA Schedule % Split</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell>CFA Payment</Table.Cell>
        <Table.Cell>
          {currencyFormatter(cfaScheduleDetails?.cfaPayment?.value)}
        </Table.Cell>
        <Table.Cell>
          {numericFormatter(
            cfaScheduleDetails?.cfaPayment?.cfaSchedulePercentageSplit,
            0.0,
            2
          )}
          {"%"}
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

const TMVTable = ({ cfaScheduleDetails }) => (
  <Table className="cfa-tmv-table">
    <Table.Header>
      <Table.Row className="teal">
        <Table.Cell className="no-border" />
        <Table.HeaderCell>Volume</Table.HeaderCell>
        <Table.HeaderCell>Periods</Table.HeaderCell>
        <Table.HeaderCell>Totals {process.env.MAJOR_CURRENCY}</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Schedule TMV</Table.Cell>
        <Table.Cell>
          {numericFormatter(cfaScheduleDetails?.tmv?.scheduleTmvVolume, 0, 0)}
        </Table.Cell>
        <Table.Cell>
          {numericFormatter(
            cfaScheduleDetails?.tmv?.scheduleTmvPeriods,
            "0.000",
            3
          )}
        </Table.Cell>
        <Table.Cell>
          {currencyFormatter(cfaScheduleDetails?.tmv?.scheduleTmvTotals)}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>TMV Used</Table.Cell>
        <Table.Cell>
          {"("}
          {numericFormatter(cfaScheduleDetails?.tmv?.tmvUsedVolume, 0, 0)}
          {")"}
        </Table.Cell>
        <Table.Cell>
          {"("}
          {numericFormatter(
            cfaScheduleDetails?.tmv?.tmvUsedPeriods,
            "0.000",
            3
          )}
          {")"}
        </Table.Cell>
        <Table.Cell>
          {"("}
          {currencyFormatter(cfaScheduleDetails?.tmv?.tmvUsedTotals)}
          {")"}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="strong grey">
        <Table.Cell>TMV Remaining</Table.Cell>
        <Table.Cell>
          {numericFormatter(cfaScheduleDetails?.tmv?.tmvRemainingVolume, 0, 0)}
        </Table.Cell>
        <Table.Cell>
          {numericFormatter(
            cfaScheduleDetails?.tmv?.tmvRemainingPeriods,
            "0.000",
            3
          )}
        </Table.Cell>
        <Table.Cell>
          {currencyFormatter(cfaScheduleDetails?.tmv?.tmvRemainingTotals)}
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

const QuarterlyManagedVolumeTable = ({ cfaScheduleDetails }) => (
  <Table className="cfa-quarterly-managed-volume-table">
    <Table.Body>
      <Table.Row className="strong grey">
        <Table.Cell>Quarterly Managed Volume</Table.Cell>
        <Table.Cell>
          {numericFormatter(cfaScheduleDetails?.quarterlyManagedVolume, 0, 0)}
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

const ScheduleFinanceAmountsCollectedTable = ({ cfaScheduleDetails }) => (
  <React.Fragment>
    <h5>Schedule Finance Amounts Collected</h5>
    <Table className="cfa-schedule-finance-amounts-collected-table">
      <Table.Header>
        <Table.Row className="teal">
          <Table.HeaderCell rowSpan={2}>
            {process.env.MAJOR_CURRENCY}
          </Table.HeaderCell>
          <Table.HeaderCell colSpan={4}>CFA Payment</Table.HeaderCell>
          <Table.HeaderCell rowSpan={2}>Total</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Minimum</Table.HeaderCell>
          <Table.HeaderCell>Additional Units</Table.HeaderCell>
          <Table.HeaderCell>CP Minimum</Table.HeaderCell>
          <Table.HeaderCell>CP Additional Units</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Funder</Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected?.funderMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.funderAdditionalUnits
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.funderCpMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.funderCpAdditionalUnits
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected?.funderTotal
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Supplier</Table.Cell>
          <Table.Cell className="unused" />
          <Table.Cell className="unused" />
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.supplierCpMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.supplierCpAdditionalUnits
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected?.supplierTotal
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>IP Syndicate</Table.Cell>
          <Table.Cell className="unused" />
          <Table.Cell className="unused" />
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.ipSyndicateCpMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.ipSyndicateCpAdditionalUnits
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.ipSyndicateTotal
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row className="strong grey">
          <Table.Cell>Total Collected</Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.totalCollectedMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.totalCollectedAdditionalUnits
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.totalCollectedCpMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.totalCollectedCpAdditionalUnits
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.totalCollectedTotal
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Adjustments</Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="italics">Funder</Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.adjustmentsFunderMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.adjustmentsFunderAdditionalUnits
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.adjustmentsFunderCpMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.adjustmentsFunderCpAdditionalUnits
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.adjustmentsFunderTotal
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="italics">Supplier</Table.Cell>
          <Table.Cell className="unused" />
          <Table.Cell className="unused" />
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.adjustmentsSupplierCpMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.adjustmentsSupplierCpAdditionalUnits
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.adjustmentsSupplierTotal
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="italics">IP Syndicate</Table.Cell>
          <Table.Cell className="unused" />
          <Table.Cell className="unused" />
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.adjustmentsIpSyndicateCpMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.adjustmentsIpSyndicateCpAdditionalUnits
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.adjustmentsIpSyndicateTotal
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row className="strong grey">
          <Table.Cell>Schedule Total</Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.scheduleTotalMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.scheduleTotalAdditionalUnits
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.scheduleTotalCpMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.scheduleTotalCpAdditionalUnits
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsCollected
                ?.scheduleTotalTotal
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </React.Fragment>
);

const ScheduleFinanceAmountsRemainingTable = ({ cfaScheduleDetails }) => (
  <React.Fragment>
    <h5>Schedule Finance Amounts Remaining</h5>
    <Table className="cfa-schedule-finance-amounts-remaining-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="teal">
            {process.env.MAJOR_CURRENCY}
          </Table.HeaderCell>
          <Table.HeaderCell>Minimum</Table.HeaderCell>
          <Table.HeaderCell>CP Minimum</Table.HeaderCell>
          <Table.HeaderCell>Total</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Funder</Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsRemaining?.funderMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsRemaining
                ?.funderCpMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsRemaining?.funderTotal
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Supplier</Table.Cell>
          <Table.Cell />
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsRemaining
                ?.supplierCpMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsRemaining?.supplierTotal
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>IP Syndicate</Table.Cell>
          <Table.Cell />
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsRemaining
                ?.ipSyndicateCpMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsRemaining
                ?.ipSyndicateTotal
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row className="strong grey">
          <Table.Cell>Schedule Total</Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsRemaining
                ?.scheduleTotalMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsRemaining
                ?.scheduleTotalCpMinimum
            )}
          </Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleFinanceAmountsRemaining
                ?.scheduleTotalTotal
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </React.Fragment>
);

const ScheduleAdditionalUnitsAllocationTable = ({ cfaScheduleDetails }) => (
  <React.Fragment>
    <h5>Schedule Additional Units Allocation</h5>
    <Table className="cfa-schedule-additional-units-allocation-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="teal">
            {process.env.MAJOR_CURRENCY}
          </Table.HeaderCell>
          <Table.HeaderCell>Total</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Allocated</Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleAdditionalUnitsAllocation?.allocated
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Unallocated</Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleAdditionalUnitsAllocation?.unallocated
            )}
          </Table.Cell>
        </Table.Row>
        <Table.Row className="strong grey">
          <Table.Cell className="no-border"></Table.Cell>
          <Table.Cell>
            {currencyFormatter(
              cfaScheduleDetails?.scheduleAdditionalUnitsAllocation?.total
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </React.Fragment>
);

export default ScheduleInformation;
