import React from "react";
import { ContentPanel } from "components/displays";
import {
  ListBuilder,
  ModalBuilder,
  ActionBar,
  Modal,
  Table,
} from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import BoilerPlateActions from "./BoilerPlateActions";
import AddBoilerPlateModal from "./AddBoilerPlateModal";
import { ListBoilerPlateTandCs, approveBoilerPlateTandCs } from "./actions";
import {
  yesNoFormatter,
  dateFormatter,
  blankDataFormatter,
} from "components/displays/DisplayHelper";
import DownloadBoilerPlateTandCs from "./DownloadBoilerPlateTandCs";
import { Button } from "semantic-ui-react";
import { withPermissions } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";

const BoilerPlateDetails = ({ dealerId, hasPermission }) => {
  let canApprove = hasPermission(Targets.BoilerPlateTAndCs, Actions.Approve);
  let canDownload = hasPermission(Targets.BoilerPlateTAndCs, Actions.Download);
  return (
    <ContentPanel
      title="Boiler Plate T&C's Details"
      wrapperCssClass="outline-grid dealers-details"
      headerCssClass="defaults-page-title"
    >
      <ListBuilder
        withTable
        initialPagination={{
          pageSize: 25,
          pageNumber: 1,
        }}
        loadAction={ListBoilerPlateTandCs}
        loadParams={{ dealerId }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        renderList={(tableProps, state, events) => (
          <div className="boiler-plate-TandC-container">
            <ActionBar>
              <ActionBar.Item>
                <AddBoilerPlateModal
                  dealerId={dealerId}
                  onSubmitted={events.onRefresh}
                />
              </ActionBar.Item>
            </ActionBar>
            <Table
              {...tableProps}
              compact
              selectable
              dataKey="id"
              emptyMessage={"No T & C's Settings"}
            >
              <Table.Column
                field="reference"
                title="Reference"
                width="8%"
                header="Reference"
              />
              <Table.Column
                field="masterDescription"
                title="Master Description"
                width="8%"
                header="Master Description"
              />
              <Table.Column
                field="boilerPlateCode"
                title="Boiler Plate"
                width="8%"
                header="Boiler Plate"
              />
              <Table.Column
                field="variablesNumber"
                title="Variables"
                width="8%"
                header="Variables"
              />
              <Table.Column
                field="lastModified"
                title="Last Modified"
                width="8%"
                header="Last Modified"
                render={(item) => {
                  return blankDataFormatter(
                    dateFormatter(item.lastModified),
                    "-"
                  );
                }}
              />
              <Table.Column
                field="approvedBy1"
                title="First Approver"
                width="8%"
                header="First Approver"
              />
              <Table.Column
                field="approvedBy1Utc"
                title="First Approver Date"
                width="8%"
                header="First Approver Date"
                render={(item) => {
                  return blankDataFormatter(
                    dateFormatter(item.approvedBy1Utc),
                    "-"
                  );
                }}
              />
              <Table.Column
                field="approvedBy2"
                title="Second Approver"
                width="8%"
                header="Second Approver"
              />
              <Table.Column
                field="approvedBy2Utc"
                title="Second Approver Date"
                width="8%"
                header="Second Approver Date"
                render={(item) => {
                  return blankDataFormatter(
                    dateFormatter(item.approvedBy2Utc),
                    "-"
                  );
                }}
              />
              <Table.Column
                field="isLive"
                title="Live"
                width="4%"
                header="Live"
                render={(item) => {
                  return yesNoFormatter(item.isLive);
                }}
              />
              {canApprove && (
                <Table.Column
                  field="id"
                  width="4%"
                  render={(item) => {
                    return (
                      <Button.Group basic size="medium">
                        <Approve
                          templateName={item.reference}
                          id={item.id}
                          reloadTable={events.onRefresh}
                          disabled={!item.requiresApproval}
                        />
                        {canDownload && (
                          <DownloadBoilerPlateTandCs item={item} />
                        )}
                      </Button.Group>
                    );
                  }}
                />
              )}
              <Table.Column
                hoverable
                width="4%"
                render={(item) => (
                  <React.Fragment>
                    {!item.isLive && (
                      <BoilerPlateActions
                        dealerId={dealerId}
                        item={item}
                        onSubmitted={events.onRefresh}
                      />
                    )}
                  </React.Fragment>
                )}
              />
            </Table>
            <TablePagination
              pageNumber={state.pagination.pageNumber}
              onChange={events.onChangePagination}
              pageSize={state.pagination.pageSize}
              totalItems={state.total}
            />
          </div>
        )}
      />
    </ContentPanel>
  );
};

const Approve = ({ templateName, disabled, id, reloadTable }) => {
  return (
    <ModalBuilder
      submitAction={approveBoilerPlateTandCs}
      submitParams={{ id }}
      onSubmitted={() => reloadTable()}
      renderTrigger={(showModal) => (
        <Button
          title="Approve"
          primary
          onClick={showModal}
          icon="check"
          disabled={disabled}
        />
      )}
      renderModal={(modalProps) => (
        <Modal.Confirmation {...modalProps} header="Approve">
          Are you sure you wish to approve {templateName}?
        </Modal.Confirmation>
      )}
    />
  );
};

export default withPermissions(BoilerPlateDetails);
