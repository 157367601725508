import { apiGet, apiPost, apiPut, apiDelete } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_FUNDER_AUTH_SIGNATORIES";

const ActionTypes = {
  GetFunderAuthorisedSignatories: `${NAMESPACE}/GET_FUNDER_AUTH_SIGNATORIES`,
  GetFunderAuthorisedSignatory: `${NAMESPACE}/GET_FUNDER_AUTH_SIGNATORY`,
  AddFunderAuthorisedSignatory: `${NAMESPACE}/ADD_FUNDER_AUTH_SIGNATORY`,
  UpdateFunderAuthorisedSignatory: `${NAMESPACE}/UPDATE_FUNDER_AUTH_SIGNATORY`,
  DeleteFunderAuthorisedSignatory: `${NAMESPACE}/DELETE_FUNDER_AUTH_SIGNATORY`,
};

export const getFunderAuthorisedSignatories = (
  { filters, pagination },
  { funderId },
) => {
  return apiGet(
    ActionTypes.GetFunderAuthorisedSignatories,
    `funders/${funderId}/authorised-signatories`,
    { ...filters, ...pagination },
  );
};

export const getFunderAuthorisedSignatory = ({ funderId, itemId }) =>
  apiGet(
    ActionTypes.GetFunderAuthorisedSignatory,
    `funders/${funderId}/authorised-signatories/${itemId}`,
  );

export const addFunderAuthorisedSignatory = (data, { funderId }) =>
  apiPost(
    ActionTypes.AddFunderAuthorisedSignatory,
    `funders/${funderId}/authorised-signatories`,
    data,
  );

export const updateFunderAuthorisedSignatory = (data, { funderId, itemId }) =>
  apiPut(
    ActionTypes.UpdateFunderAuthorisedSignatory,
    `funders/${funderId}/authorised-signatories/${itemId}`,
    data,
  );

export const deleteFunderAuthorisedSignatory = ({ funderId, itemId }) =>
  apiDelete(
    ActionTypes.DeleteFunderAuthorisedSignatory,
    `funders/${funderId}/authorised-signatories/${itemId}`,
  );
