import React from "react";
import { AccordionContentPanel } from "components/displays";
import {
  Button,
  Table,
  TableCell,
  GridRow,
  GridColumn,
  Grid,
} from "semantic-ui-react";
import { FormBuilder, ListBuilder, Form, Loadable } from "@redriver/cinnamon";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import {
  getCurrentCharges,
  getFutureCharges,
  getQuarterlyServices,
  getCutSheetPaper,
  getPriceIncreaseBody,
} from "./actions";
import { SortableHeader } from "features/../../../shared/components/tables/sortableTable";
import {
  numericFormatter,
  yesNoFormatter,
  dateFormatter,
} from "components/displays/DisplayHelper";

const headers = [
  { field: "managed", title: "Managed" },
  { field: "volume", title: "Volume" },
  { field: "cpi", title: "CPI (p)" },
  { field: "financeCPI", title: "Finance CPI (p)" },
  { field: "serviceCPI", title: "Service CPI (p)" },
  { field: "cpu", title: "CPU (p)" },
  { field: "cpiTotal", title: "CPI Total (£)" },
  { field: "financeTotal", title: "Finance Total (£)" },
  { field: "serviceTotal", title: "Services Total (£)" },
  { field: "value", title: "Value (£)" },
];

const currentHeaders = [
  { field: "key", sortable: false, className: "empty" },
  { field: "unit", title: "Unit", className: "rounded-left-border" },
];

const futureHeaders = [
  {
    field: "key",
    title: "Select Unit",
    sortable: false,
    className: "rounded-left-border",
  },
  { field: "unit", title: "Unit" },
];

const futureHeadersEnd = [
  { field: "changeTotal", title: "Change Total (£)" },
  { field: "changeTotalPercentage", title: "% Change Total" },
  { field: "changeCPI", title: "Change CPI (p)" },
  { field: "changeCPIPercentage", title: "% Change CPI" },
];

const quarterlyServiceHeaders = [
  { field: "service", title: "Service" },
  { field: "priceIncrease", title: "Price Increase" },
  { field: "currentValue", title: "Current Value (£)" },
  { field: "futureValue", title: "Future Value (£)" },
  { field: "customValue", title: "Custom Value (£)" },
  { field: "changePercentage", title: "% Change" },
];

const cutSheetPaperHeaders = [
  { field: "name", title: "", sortable: false },
  { field: "currentValue", title: "Current Values (£)" },
  { field: "futureValue", title: "Future Value (£)" },
  { field: "customValue", title: "Custom Value (£)" },
  { field: "changePercentage", title: "% Change" },
];

const ACP = ({ title, children }) => (
  <AccordionContentPanel
    title={title}
    hideTitle={false}
    wrapperCssClass="outline-grid"
    headerCssClass="defaults-page-title"
  >
    <br />
    {children}
    <br />
  </AccordionContentPanel>
);

const NextPriceIncrease = () => (
  <React.Fragment>
    <FirstTopPanel loadAction={getPriceIncreaseBody} />
    <br />
    <br />
    <ACP title="Current Charges">
      <CurrentCharges loadAction={getCurrentCharges} />
    </ACP>
    <br />
    <ACP title="Future Charges">
      <FutureCharges loadAction={getFutureCharges} />
    </ACP>
    <br />
    <Grid>
      <GridRow columns={2}>
        <GridColumn>
          <ACP title="Quarterly Services">
            <QuarterlyServices loadAction={getQuarterlyServices} />
          </ACP>
        </GridColumn>
        <GridColumn>
          <ACP title="Cut Sheet Paper Inclusive">
            <CutSheetPaperInclusive loadAction={getCutSheetPaper} />
          </ACP>
        </GridColumn>
      </GridRow>
    </Grid>
  </React.Fragment>
);

const FirstTopPanel = ({ loadAction }) => {
  return (
    <React.Fragment>
      <div className="center">
        <Button primary>Apply Price Increase</Button>
        <Button primary>Generate Price Increase Letter</Button>
        <Button primary>Reset Applied Price Increase</Button>
      </div>
      <br />
      <Grid>
        <GridRow columns={2}>
          <GridColumn>
            <FormBuilder
              loadAction={loadAction}
              initialReadOnly
              renderForm={(formProps, state, events) => {
                return (
                  <AccordionContentPanel
                    title="Next Price Increase"
                    wrapperCssClass="outline-grid"
                    headerCssClass="defaults-page-title"
                    headerContent={
                      <div>
                        <IconButton
                          primary
                          icon={state.readOnly ? Pen : Save}
                          content={state.readOnly ? "Edit" : "Save"}
                          floated="right"
                          onClick={() => {
                            state.readOnly
                              ? events.onToggleReadOnly()
                              : events.onSubmit({ reset: ["readOnly"] });
                          }}
                          className={
                            !state.readOnly ? "positive-title-action" : null
                          }
                          loading={state.loading || state.submitting}
                          disabled={state.loading || state.submitting}
                        />
                        {!state.readOnly && (
                          <Button
                            primary
                            className="cancel-title-action"
                            floated="right"
                            content="Cancel"
                            onClick={events.onReset}
                          />
                        )}
                      </div>
                    }
                  >
                    <div className="principal-list padded">
                      <Loadable loading={state.loading || state.submitting}>
                        <Form {...formProps} className="highlighted-inputs">
                          <Form.Object field="nextPriceIncrease">
                            <Grid>
                              <GridRow columns={2}>
                                <GridColumn>Option:</GridColumn>
                                <GridColumn>
                                  {state.readOnly ? (
                                    <label>
                                      {formProps?.value?.nextPriceIncrease
                                        ?.readOnlyOptionText ?? ""}
                                    </label>
                                  ) : (
                                    <Form.Dropdown
                                      field="option"
                                      options={[
                                        {
                                          value: 1,
                                          text: "Specific Meters",
                                        },
                                        {
                                          value: 2,
                                          text: "Specific Meters",
                                        },
                                      ]}
                                    />
                                  )}
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>
                                  <label>Last Price Increase Date:</label>
                                </GridColumn>
                                <GridColumn>
                                  {state.readOnly ? (
                                    <label>
                                      {dateFormatter(
                                        formProps?.value?.nextPriceIncrease
                                          ?.lastPriceIncrease
                                      )}
                                    </label>
                                  ) : (
                                    <Form.Date
                                      local
                                      field="lastPriceIncrease"
                                    />
                                  )}
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>
                                  Next Price Increase Date:
                                </GridColumn>
                                <GridColumn>
                                  {state.readOnly ? (
                                    <label>
                                      {dateFormatter(
                                        formProps?.value?.nextPriceIncrease
                                          ?.nextPriceIncrease
                                      )}
                                    </label>
                                  ) : (
                                    <Form.Date
                                      local
                                      field="nextPriceIncrease"
                                    />
                                  )}
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>% to Apply:</GridColumn>
                                <GridColumn>
                                  <Form.Numeric field="applyPercentage" />
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>Service % increase:</GridColumn>
                                <GridColumn>
                                  <Form.Numeric field="serviceIncrease" />
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>
                                  Total Payment Increase (£):
                                </GridColumn>
                                <GridColumn>
                                  <Form.Numeric
                                    field="totalPaymentIncrease"
                                    decimalPlaces={2}
                                  />
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>
                                  Total Payment % Increase
                                </GridColumn>
                                <GridColumn>
                                  <Form.Numeric
                                    field="totalPercentageIncrease"
                                    decimalPlaces={2}
                                  />
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>
                                  New IP Admin Fee Required
                                </GridColumn>
                                <GridColumn>
                                  {state.readOnly ? (
                                    <label>
                                      {yesNoFormatter(
                                        formProps?.value?.nextPriceIncrease
                                          ?.adminFeeRequired ?? ""
                                      )}
                                    </label>
                                  ) : (
                                    <Form.Dropdown
                                      field="adminFeeRequired"
                                      options={[
                                        { value: true, text: "Yes" },
                                        { value: false, text: "No" },
                                      ]}
                                      inline
                                    />
                                  )}
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>
                                  Increase to be applied (%)
                                </GridColumn>
                                <GridColumn>
                                  <Form.Numeric
                                    field="adminFeePercentageIncrease"
                                    decimalPlaces={2}
                                  />
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>
                                  New IP Admin Fee included (£)
                                </GridColumn>
                                <GridColumn>
                                  <Form.Numeric
                                    field="adminFeeIncrease"
                                    decimalPlaces={2}
                                  />
                                </GridColumn>
                              </GridRow>
                            </Grid>
                            <br />
                            <br />
                            <Table>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell className="left-align">
                                    <b>Service Increase Allocation</b>
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    <b>£</b>
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    <b>%</b>
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                <Table.Row>
                                  <TableCell className="left-align">
                                    Supplier
                                  </TableCell>
                                  <TableCell className="right-align">
                                    <Form.Numeric
                                      field="serviceIncreaseAllocationSupplierValue"
                                      decimalPlaces={2}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Form.Numeric
                                      field="serviceIncreaseAllocationSupplierPercentage"
                                      decimalPlaces={2}
                                    />
                                  </TableCell>
                                </Table.Row>
                                <Table.Row>
                                  <TableCell className="left-align">
                                    IPS
                                  </TableCell>
                                  <TableCell className="right-align">
                                    <Form.Numeric
                                      field="serviceIncreaseAllocationIPSValue"
                                      decimalPlaces={2}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Form.Numeric
                                      field="serviceIncreaseAllocationIPSPercentage"
                                      decimalPlaces={2}
                                    />
                                  </TableCell>
                                </Table.Row>
                                <Table.Row>
                                  <TableCell className="left-align">
                                    <b>Total Payment</b>
                                  </TableCell>
                                  <TableCell className="right-align">
                                    <Form.Numeric
                                      field="serviceIncreaseAllocationTotalValue"
                                      decimalPlaces={2}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Form.Numeric
                                      field="serviceIncreaseAllocationTotalPercentage"
                                      decimalPlaces={2}
                                    />
                                  </TableCell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </Form.Object>
                        </Form>
                      </Loadable>
                    </div>
                  </AccordionContentPanel>
                );
              }}
            />
          </GridColumn>
          <GridColumn>
            <FormBuilder
              loadAction={loadAction}
              initialReadOnly
              renderForm={(formProps, state, events) => {
                return (
                  <AccordionContentPanel
                    title="Settings/Standard"
                    wrapperCssClass="outline-grid"
                    headerCssClass="defaults-page-title"
                    headerContent={
                      <div>
                        <IconButton
                          primary
                          icon={state.readOnly ? Pen : Save}
                          content={state.readOnly ? "Edit" : "Save"}
                          floated="right"
                          onClick={() => {
                            state.readOnly
                              ? events.onToggleReadOnly()
                              : events.onSubmit({ reset: ["readOnly"] });
                          }}
                          className={
                            !state.readOnly ? "positive-title-action" : null
                          }
                          loading={state.loading || state.submitting}
                          disabled={state.loading || state.submitting}
                        />
                        {!state.readOnly && (
                          <Button
                            primary
                            className="cancel-title-action"
                            floated="right"
                            content="Cancel"
                            onClick={events.onReset}
                          />
                        )}
                      </div>
                    }
                  >
                    <div className="principal-list padded">
                      <Loadable loading={state.loading || state.submitting}>
                        <Form {...formProps} className="highlighted-inputs">
                          <Form.Object field="settings">
                            <Grid>
                              <GridRow columns={2}>
                                <GridColumn>
                                  <label>Price Increase Methodology:</label>
                                </GridColumn>
                                <GridColumn>
                                  <Form.Input local field="methodology" />
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>
                                  Default Price Increase (%):
                                </GridColumn>
                                <GridColumn>
                                  <Form.Numeric local field="default" />
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>
                                  Max Available Increase (%):
                                </GridColumn>
                                <GridColumn>
                                  <Form.Numeric field="max" />
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>
                                  Never Apply Price Increases:
                                </GridColumn>
                                <GridColumn>
                                  {state.readOnly ? (
                                    <label>
                                      {yesNoFormatter(
                                        formProps?.value?.settings
                                          ?.neverApplyIncrease ?? ""
                                      )}
                                    </label>
                                  ) : (
                                    <Form.Dropdown
                                      field="neverApplyIncrease"
                                      options={[
                                        { value: true, text: "Yes" },
                                        { value: false, text: "No" },
                                      ]}
                                      inline
                                    />
                                  )}
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>
                                  Customer Notification Method:
                                </GridColumn>
                                <GridColumn>
                                  <Form.Input field="customerNotificationMethod" />
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>Notification Method</GridColumn>
                                <GridColumn>
                                  {state.readOnly ? (
                                    <label>
                                      {formProps?.value?.settings
                                        ?.notificationMethodDescription ?? ""}
                                    </label>
                                  ) : (
                                    <Form.Dropdown
                                      field="notificationMethod"
                                      options={[
                                        {
                                          value: 1,
                                          text: "Email",
                                        },
                                        {
                                          value: 2,
                                          text: "Post",
                                        },
                                      ]}
                                    />
                                  )}
                                </GridColumn>
                              </GridRow>
                              <GridRow>
                                <GridColumn>
                                  <br />
                                </GridColumn>
                              </GridRow>
                              <GridRow columns={2}>
                                <GridColumn>
                                  Historic/Current Schedule(s) Last Price
                                  Increase Date
                                </GridColumn>
                                <GridColumn>
                                  {state.readOnly ? (
                                    <label>
                                      {dateFormatter(
                                        formProps?.value?.settings
                                          ?.lastPriceIncreaseDate
                                      )}
                                    </label>
                                  ) : (
                                    <Form.Date
                                      local
                                      field="lastPriceIncreaseDate"
                                    />
                                  )}
                                </GridColumn>
                              </GridRow>
                            </Grid>
                          </Form.Object>
                        </Form>
                      </Loadable>
                    </div>
                  </AccordionContentPanel>
                );
              }}
            />
          </GridColumn>
        </GridRow>
      </Grid>
    </React.Fragment>
  );
};

const CurrentCharges = ({ loadAction }) => {
  return (
    <div className="padded">
      <div className="schedule-services-body-container">
        <ListBuilder
          withTable
          className="schedule-services-body"
          dataKey="id"
          loadAction={loadAction}
          renderList={(tableProps, state, events) => (
            <div className="horizontal-scrollbar">
              <Table className="schedule-price-increase current-charges">
                <Table.Header>
                  <React.Fragment>
                    <Table.Row>
                      <Table.Cell
                        className="empty border-right"
                        colSpan={4}
                      ></Table.Cell>
                      <Table.HeaderCell className="highlight" colSpan={8}>
                        Current Changes - valid from 28-Dec-2019
                      </Table.HeaderCell>
                    </Table.Row>
                  </React.Fragment>
                </Table.Header>
                <SortableHeader
                  columns={currentHeaders.concat(headers)}
                  state={state}
                  events={events}
                  tableProps={tableProps}
                  className="schedule-services-body"
                  dataKey="id"
                />
                <Table.Body>
                  {tableProps.data.map((item, index) => (
                    <Table.Row key={index}>
                      <ChargesTableCells
                        item={item}
                        hideAdditionalColumns={true}
                      />
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          )}
        />
      </div>
    </div>
  );
};

const FutureCharges = ({ loadAction }) => {
  return (
    <div className="padded">
      <div className="schedule-services-body-container">
        <ListBuilder
          withTable
          className="schedule-services-body"
          dataKey="id"
          loadAction={loadAction}
          renderList={(tableProps, state, events) => (
            <div className="horizontal-scrollbar">
              <Table className="schedule-price-increase future-charges">
                <Table.Header>
                  <Table.Row>
                    <Table.Cell
                      className="empty border-right "
                      colSpan={4}
                    ></Table.Cell>
                    <Table.HeaderCell className="highlight " colSpan={8}>
                      Future Changes - valid from 28-Dec-2020
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <SortableHeader
                  columns={futureHeaders.concat(
                    headers.concat(futureHeadersEnd)
                  )}
                  state={state}
                  events={events}
                  tableProps={tableProps}
                  className="schedule-services-body"
                  dataKey="id"
                />
                <Table.Body>
                  {tableProps.data.map((item, index) => (
                    <Table.Row key={index}>
                      <ChargesTableCells item={item} />
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          )}
        />
      </div>
    </div>
  );
};

const QuarterlyServices = ({ loadAction }) => {
  return (
    <div className="padded">
      <div className="schedule-services-body-container">
        <ListBuilder
          withTable
          className="schedule-services-body"
          dataKey="id"
          loadAction={loadAction}
          renderList={(tableProps, state, events) => (
            <div className="horizontal-scrollbar">
              <Table className="qtr-services schedule-price-increase">
                <SortableHeader
                  columns={quarterlyServiceHeaders}
                  state={state}
                  events={events}
                  tableProps={tableProps}
                  className="schedule-services-body"
                  dataKey="id"
                />
                <Table.Body>
                  {tableProps.data.services &&
                    tableProps.data.services.map((item, index) => (
                      <Table.Row key={index}>
                        <TableCell className="border-left empty-footer">
                          {item.service}
                        </TableCell>
                        <TableCell className="empty-footer">
                          {yesNoFormatter(item.priceIncrease, true)}
                        </TableCell>
                        <TableCell className="right-align highlight-footer">
                          {numericFormatter(item.currentValue, "-", 2)}
                        </TableCell>
                        <TableCell className="right-align highlight-footer">
                          {numericFormatter(item.futureValue, "-", 2)}
                        </TableCell>
                        <TableCell className="right-align highlight-footer">
                          {item.customValue}
                        </TableCell>
                        <TableCell className="highlight-footer">
                          {numericFormatter(item.changePercentage, "0", 2, "-")}
                        </TableCell>
                      </Table.Row>
                    ))}
                </Table.Body>
                {tableProps.data.serviceCpi && (
                  <Table.Footer>
                    <Table.Row className="no-left-border">
                      <TableCell colSpan={5} />
                    </Table.Row>
                    <Table.Row className="highlight">
                      <TableCell className="border-left" colSpan={2}>
                        Service CPI
                      </TableCell>
                      <TableCell className="right-align">
                        {tableProps.data.serviceCpi.currentValue}
                      </TableCell>
                      <TableCell className="right-align">
                        {tableProps.data.serviceCpi.futureValue}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        {tableProps.data.serviceCpi.changePercentage}
                      </TableCell>
                    </Table.Row>
                    <Table.Row className="no-left-border">
                      <TableCell colSpan={5} />
                    </Table.Row>
                    <Table.Row className="highlight">
                      <TableCell colSpan={1} className="border-left">
                        Custom Increase Max Service Total (£)
                      </TableCell>
                      <TableCell colSpan={1}>
                        <span className="highlight">
                          {tableProps.data.maxServiceTotal}
                        </span>
                      </TableCell>
                      <TableCell colSpan={4} className="empty"></TableCell>
                    </Table.Row>
                  </Table.Footer>
                )}
              </Table>
            </div>
          )}
        />
      </div>
    </div>
  );
};

const CutSheetPaperInclusive = ({ loadAction }) => {
  return (
    <div className="padded">
      <div className="schedule-services-body-container">
        <ListBuilder
          withTable
          className="schedule-services-body"
          dataKey="id"
          loadAction={loadAction}
          renderList={(tableProps, state, events) => (
            <div className="horizontal-scrollbar">
              <Table className={"schedule-price-increase cut-sheet-table"}>
                <SortableHeader
                  columns={cutSheetPaperHeaders}
                  state={state}
                  events={events}
                  tableProps={tableProps}
                  className="schedule-services-body"
                  dataKey="id"
                />
                <Table.Body>
                  {tableProps.data.services &&
                    tableProps.data.services.map((item, index) => (
                      <Table.Row key={index}>
                        <TableCell className="border-left">
                          {item.name}
                        </TableCell>
                        <TableCell className="right-align">
                          {item.markup
                            ? item.currentValue
                            : numericFormatter(item.currentValue, "-", 2)}
                        </TableCell>
                        <TableCell className="right-align">
                          {item.markup
                            ? item.futureValue
                            : numericFormatter(item.futureValue, "-", 2)}
                        </TableCell>
                        <TableCell className="right-align">
                          {item.customValue}
                        </TableCell>
                        <TableCell>{item.changePercentage}</TableCell>
                      </Table.Row>
                    ))}
                </Table.Body>
                <Table.Footer>
                  <Table.Row className="no-left-border">
                    <TableCell colSpan={5} />
                  </Table.Row>
                  <Table.Row className="highlight">
                    <TableCell colSpan={1} className="border-left">
                      Custom Cost Increase <br />
                      Max Possible (£)
                    </TableCell>
                    <TableCell colSpan={1}>
                      <span className="highlight">
                        {tableProps.data.customCodeIncreaseMax}
                      </span>
                    </TableCell>
                    <TableCell
                      colSpan={3}
                      className="empty no-left-border"
                    ></TableCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </div>
          )}
        />
      </div>
    </div>
  );
};

const ChargesTableCells = ({ item, hideAdditionalColumns }) => (
  <React.Fragment>
    <TableCell
      style={{ width: "3%" }}
      className={
        (hideAdditionalColumns ? "empty" : "border-left ") + " empty-footer"
      }
    >
      <Form.Checkbox field="field" label="" />
    </TableCell>
    <TableCell
      style={{ width: "4%" }}
      className={(hideAdditionalColumns ? "border-left" : "") + " empty-footer"}
    >
      <span>{item.unit}</span>
    </TableCell>
    <TableCell style={{ width: "6%" }} className="empty-footer">
      <span>{yesNoFormatter(item.managed, true)}</span>
    </TableCell>
    <TableCell style={{ width: "6%" }} className="empty-footer">
      <span>{item.volume}</span>
    </TableCell>
    <TableCell style={{ width: "6%" }} className="right-align empty-footer">
      <span>{numericFormatter(item.cpi, "-", 3)}</span>
    </TableCell>
    <TableCell style={{ width: "7%" }} className="right-align empty-footer">
      <span>{numericFormatter(item.financeCPI, "-", 3)}</span>
    </TableCell>
    <TableCell style={{ width: "7%" }} className="right-align empty-footer">
      <span>{numericFormatter(item.serviceCPI, "-", 3)}</span>
    </TableCell>
    <TableCell style={{ width: "7%" }} className="right-align empty-footer">
      <span>{numericFormatter(item.cpu, "-", 3)}</span>
    </TableCell>
    <TableCell style={{ width: "6%" }} className="right-align highlight-footer">
      <span>{numericFormatter(item.cpiTotal, "-", 2)}</span>
    </TableCell>
    <TableCell style={{ width: "8%" }} className="right-align highlight-footer">
      <span>{numericFormatter(item.financeTotal, "-", 2)}</span>
    </TableCell>
    <TableCell style={{ width: "8%" }} className="right-align highlight-footer">
      <span>{numericFormatter(item.serviceTotal, "-", 2)}</span>
    </TableCell>
    <TableCell style={{ width: "8%" }} className="right-align highlight-footer">
      <span>{numericFormatter(item.value, "-", 2)}</span>
    </TableCell>
    <TableCell
      style={{ width: "6%" }}
      className={
        hideAdditionalColumns
          ? "empty empty-footer"
          : "highlight-footer right-align"
      }
    >
      <span>{numericFormatter(item.changeTotal, "-", 2)}</span>
    </TableCell>
    <TableCell
      style={{ width: "6%" }}
      className={
        hideAdditionalColumns ? "empty empty-footer" : "highlight-footer"
      }
    >
      <span>{numericFormatter(item.changeTotalPercentage, "-", 2)}</span>
    </TableCell>
    <TableCell
      style={{ width: "6%" }}
      className={
        hideAdditionalColumns ? "empty" : "" + "empty-footer right-align"
      }
    >
      <span>{numericFormatter(item.changeCPI, "-", 3)}</span>
    </TableCell>
    <TableCell
      style={{ width: "6%" }}
      className={hideAdditionalColumns ? "empty" : "empty-footer"}
    >
      <span>{numericFormatter(item.changeCPIPercentage, "-", 2)}</span>
    </TableCell>
  </React.Fragment>
);

export default NextPriceIncrease;
