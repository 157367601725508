import React from "react";
import { Table } from "@redriver/cinnamon";
import EditPrinter from "./EditPrinter";
import DeletePrinter from "./DeletePrinter";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";

const PrinterActions = ({ printer, dealerOfficeId, onRefresh }) => {
  return (
    <Table.Menu>
      <EditPrinter
        printer={printer}
        dealerOfficeId={dealerOfficeId}
        onSubmitted={onRefresh}
        as={Table.MenuItem}
      />
      <DeletePrinter
        printer={printer}
        onSubmitted={onRefresh}
        as={Table.MenuItem}
      />
    </Table.Menu>
  );
};

export default PrinterActions;
