import React from "react";
import { ContentPanel } from "components/displays";
import {
  numericFormatter,
  TableDivider,
} from "components/displays/DisplayHelper";
import { Table } from "semantic-ui-react";

const AdditionalUnitsCharge = ({ additionalUnitsCharge }) => {
  return (
    <ContentPanel
      title="Additional Units Charge"
      wrapperCssClass="outline-grid"
      headerCssClass="defaults-page-title"
    >
      <div className="padded">
        <AdditionalUnitsChargeTable
          additionalUnitsCharge={additionalUnitsCharge}
        />
      </div>
    </ContentPanel>
  );
};

const AdditionalUnitsChargeTable = ({ additionalUnitsCharge }) => (
  <Table className="additional-units-charge-table">
    <Table.Body>
      <Table.Row className="strong grey">
        <Table.Cell>Capitalised</Table.Cell>
        <Table.Cell>Value {process.env.MAJOR_CURRENCY}</Table.Cell>
      </Table.Row>
      <TableDivider />
      <Table.Row className="strong grey">
        <Table.Cell>CFA Amount Collected</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(additionalUnitsCharge?.cfaAmountCollected)}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Settled via rewrite</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(additionalUnitsCharge?.settledViaRewrite)}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>Settled via invoice</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(additionalUnitsCharge?.settledViaInvoice)}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Transfer to separate CFA</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(additionalUnitsCharge?.transferToSeparateCfa)}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>Transfer from separate CFA</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(additionalUnitsCharge?.transferFromSeparateCfa)}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Reallocated to Continuing Payment</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(
            additionalUnitsCharge?.reallocatedToContinuingPayment
          )}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>Adjustment</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(additionalUnitsCharge?.adjustment)}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="strong grey">
        <Table.Cell>Available Amount</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(additionalUnitsCharge?.availableAmount)}
        </Table.Cell>
      </Table.Row>
      <TableDivider />
      <Table.Row>
        <Table.Cell>Additional Units received by Funder</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(
            additionalUnitsCharge?.additionalUnitsReceivedByFunder
          )}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>Additional Units not yet received by Funder</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(
            additionalUnitsCharge?.additionalUnitsNotYetReceivedByFunder
          )}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="grey">
        <Table.Cell></Table.Cell>
        <Table.Cell className="bold">
          {" "}
          {numericFormatter(additionalUnitsCharge?.totalAdditionalUnits)}
        </Table.Cell>
      </Table.Row>
      <TableDivider />
      <Table.Row className="strong grey">
        <Table.Cell colSpan={2}>Current Schedule Allocation</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Allocated</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(additionalUnitsCharge?.allocated)}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>Unallocated</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(additionalUnitsCharge?.unallocated)}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="strong grey">
        <Table.Cell>Total Available</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(additionalUnitsCharge?.totalAvailable)}
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export default AdditionalUnitsCharge;
