import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { FunderDetails } from "features/Companies/Funders";

const FunderDetailsPage = ({ match }) => (
  <PageContent fluid>
    <FunderDetails funderId={match.params.id} />
  </PageContent>
);

export default FunderDetailsPage;
