import { AppRoutes, ScheduleRoutes } from "constants/routes";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SchedulesListPage from "./SchedulesListPage";
import SchedulePage from "./SchedulePage";

const SchedulesRouter = () => (
  <Switch>
    <Route exact path={AppRoutes.Schedules} component={SchedulesListPage} />
    <Route exact path={ScheduleRoutes.Schedule()} component={SchedulePage} />
    <Route render={() => <Redirect to={AppRoutes.Schedules} />} />
  </Switch>
);

export default SchedulesRouter;
