import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/SIGNATORIES";

const ActionTypes = {
  getSignatories: `${NAMESPACE}/GET_SIGNATORIES`,
  getHistoricSignatories: `${NAMESPACE}/GET_HISTORIC_SIGNATORIES`,
};

const signatories = [
  {
    id: 1,
    prefix: "Dr",
    firstName: "Andrew",
    lastName: "Madden",
    position: "Finance Director",
    authorisedStatus: "Listed",
    telephone: "+44 (0) 1483 651852",
    email: "amadden@ethelbert.co.uk",
    dtAdded: "03/03/2022",
    master: true,
    schedule: true,
    capital: true,
    directDebit: false,
  },
  {
    id: 2,
    prefix: "Mrs",
    firstName: "Jane",
    lastName: "Sloan",
    position: "Secretary",
    authorisedStatus: "Listed",
    telephone: "+44 (0) 7596 548562",
    email: "jsloan@ethelbert.co.uk",
    dtAdded: "08/13/2019",
    master: true,
    schedule: false,
    capital: false,
    directDebit: true,
  },
  {
    id: 3,
    prefix: "Mr",
    firstName: "James",
    lastName: "Smith",
    position: "Site Manager",
    authorisedStatus: "LOA",
    telephone: "+44 (0) 1896 452658",
    email: "jsmith@ethelbert.co.uk",
    dtAdded: "01/20/2018",
    master: true,
    schedule: false,
    capital: true,
    directDebit: false,
  },
];

const inactiveSignatories = [
  {
    id: 1,
    prefix: "Dr",
    status: "Inactive",
    firstName: "David",
    lastName: "Lowman",
    position: "Director",
    authorisedStatus: "Listed",
    telephone: "+44 (0) 1903 253361",
    email: "dlowman@ethelbert.co.uk",
    dtAdded: "03/23/2012",
    dtArchived: "03/21/2021",
  },
  {
    id: 2,
    prefix: "Mr",
    status: "Inactive",
    firstName: "Peter",
    lastName: "Richardson",
    position: "Secretary",
    authorisedStatus: "LOA",
    telephone: "+44 (0) 7987 475246",
    email: "prichardson@ethelbert.co.uk",
    dtAdded: "02/10/2014",
    dtArchived: "04/18/2020",
  },
  {
    id: 3,
    prefix: "Mrs",
    status: "Inactive",
    firstName: "Sharon",
    lastName: "Parker",
    position: "Office Manager",
    authorisedStatus: "LOA",
    telephone: "+44 (0) 1403 879666",
    email: "dlowman@ethelbert.co.uk",
    dtAdded: "11/25/2014",
    dtArchived: "11/25/2015",
  },
];

export const getCustomerSignatories = () => {
  return mockSuccess(ActionTypes.getSignatories, {
    delay: 500,
    response: signatories,
  });
};

export const getHistoricCustomerSignatories = () => {
  return mockSuccess(ActionTypes.getHistoricSignatories, {
    delay: 500,
    response: inactiveSignatories,
  });
};
