import { AppRoutes, SpvRoutes } from "constants/routes";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SPVsListPage from "./SPVsListPage";
import SPVDetailsPage from "./SPVDetailsPage";

const SPVsRouter = () => (
  <Switch>
    <Route exact path={AppRoutes.SPVs} component={SPVsListPage} />
    <Route exact path={SpvRoutes.Details()} component={SPVDetailsPage} />
    <Route render={() => <Redirect to={AppRoutes.SPVs} />} />
  </Switch>
);

export default SPVsRouter;
