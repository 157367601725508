import React from "react";
import { ListBuilder, Table, ActionBar } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";

import { getRoles } from "./actions";
import SearchRoles from "./SearchRoles";
import AddRole from "./AddRole";
import RoleActions from "./RoleActions";
import Add from "features/../../../shared/assets/white-add.svg";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { SortHeader } from "features/../../../shared/components/tables/sortableTable";

const ListRoles = () => (
  <React.Fragment>
    <h4 className="settings-header">Templates</h4>
    <ListBuilder
      withTable
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      loadAction={getRoles}
      dataTransform={data => data.results}
      totalTransform={data => data.totalResults}
      renderList={(tableProps, state, events) => (
        <div className="content-container principal-list">
          <ActionBar>
            <ActionBar.Item fluid>
              <SearchRoles
                value={state.filters}
                onChange={events.onChangeFilters}
              />
            </ActionBar.Item>
            <PermissionCheck
              target={Targets.RoleTemplate}
              action={Actions.Create}
            >
              <ActionBar.Item align="right">
                <AddRole
                  primary
                  icon={Add}
                  content="New Template"
                  onAdded={events.onRefresh}
                />
              </ActionBar.Item>
            </PermissionCheck>
          </ActionBar>
          <Table
            {...tableProps}
            compact
            dataKey="id"
            emptyMessage="No Templates found"
            selectable
          >
            <Table.Column
              field="name"
              title="Name"
              width="30%"
              header={
                <SortHeader
                  field="name"
                  title="Name"
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              }
            />
            <Table.Column
              field="typeName"
              title="Type"
              width="10%"
              header={
                <SortHeader
                  field="typeName"
                  title="Type"
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              }
            />
            <Table.Column
              field="description"
              title="Description"
              width="66%"
              header={
                <SortHeader
                  field="description"
                  title="Description"
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              }
            />
            <Table.Column
              hoverable
              width="4%"
              align="right"
              render={role => (
                <RoleActions role={role} onRefresh={events.onRefresh} />
              )}
            />
          </Table>
          <TablePagination
            pageNumber={state.pagination.pageNumber}
            onChange={events.onChangePagination}
            pageSize={state.pagination.pageSize}
            totalItems={state.total}
          />
        </div>
      )}
    />
  </React.Fragment>
);

export default ListRoles;
