import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import {
  FormBuilder,
  Form,
  ErrorMessage,
  getAuthClaims,
} from "@redriver/cinnamon";
import { resendVerification } from "features/../../../shared/features/Settings/ListUsers";

const EmailVerificationResender = ({ userEmail, emailVerified }) => {
  const [requestSent, setRequestSent] = useState(false);
  const [requestEmail, setRequestEmail] = useState("");

  if (emailVerified) {
    return <p>Your email address has already been verified</p>;
  }

  if (requestSent) {
    return (
      <p>
        A verification link has been sent
        {requestEmail ? ` to ${requestEmail}` : ""}
      </p>
    );
  }

  return (
    <FormBuilder
      initialData={{ email: userEmail }}
      submitAction={resendVerification}
      onSubmit={({ email }) => setRequestEmail(email)}
      onSubmitted={() => setRequestSent(true)}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { submitting, slowSubmitting, error },
        events,
      ) => (
        <Form {...formProps}>
          {!userEmail && (
            <Form.Email
              field="email"
              label="Email"
              placeholder="Enter email address"
              disabled={submitting && slowSubmitting}
              autoFocus
              required
            />
          )}
          <Button
            primary
            onClick={events.onSubmit}
            disabled={submitting}
            loading={submitting && slowSubmitting}
          >
            Request Verification Email
          </Button>
          <ErrorMessage error={error} />
        </Form>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  const { userEmail, emailVerified } = getAuthClaims(state);
  return {
    userEmail,
    emailVerified,
  };
};

export default connect(mapStateToProps)(EmailVerificationResender);
