import { mockSuccess, MockArrayData } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULES/ATTACHMENTS";

const ActionTypes = {
  GetAttachments: `${NAMESPACE}/GET_ATTACHMENTS`,
};

const mockAttachments = new MockArrayData([
  {
    id: 1,
    name: "Rewrite - PRI01-MA2678-SC6231",
    memberId: 1,
    type: 1,
    filename: "RW-PRI01-MA2678-SC6231-01.pdf",
    dt: "2021-09-27 12:00",
  },
  {
    id: 2,
    name: "Amendment 1A",
    memberId: 1,
    type: 1,
    filename: "PRI01-MA2678-SC6231-Amendment.pdf",
    dt: "2021-09-27 11:52",
  },
  {
    id: 3,
    name: "Product Schedule",
    memberId: 2,
    type: 2,
    filename: "EthelbertSpecialistHomeLtdPSA1.pdf",
    dt: "2021-01-15 02:37",
  },
  {
    id: 4,
    name: "Customer Finance Agreement",
    memberId: 3,
    type: 1,
    filename: "CFABNPA2354T.pdf",
    dt: "2021-09-27 04:04",
  },
  {
    id: 5,
    name: "Delivery Confirmation",
    memberId: 3,
    type: 3,
    filename: "DELIVERY_CONFIRMATION1234.pdf",
    dt: "2021-09-27 04:04",
  },
  {
    id: 6,
    name: "TMVSA - PRI01-MA2678-SC6231",
    memberId: 3,
    type: 1,
    filename: "PRI01-MA2678-SC6231.pdf",
    dt: "2021-09-27 04:04",
  },
  {
    id: 7,
    name: "Rewrite - PRI01-MA2678-SC6231",
    memberId: 1,
    type: 1,
    filename: "RW-PRI01-MA2678-SC6231-01.pdf",
    dt: "2021-09-27 12:00",
  },
  {
    id: 8,
    name: "Amendment 1A",
    memberId: 1,
    type: 1,
    filename: "PRI01-MA2678-SC6231-Amendment.pdf",
    dt: "2021-09-27 11:52",
  },
  {
    id: 9,
    name: "Product Schedule",
    memberId: 2,
    type: 2,
    filename: "EthelbertSpecialistHomeLtdPSA1.pdf",
    dt: "2021-01-15 02:37",
  },
  {
    id: 10,
    name: "Customer Finance Agreement",
    memberId: 3,
    type: 1,
    filename: "CFABNPA2354T.pdf",
    dt: "2021-09-27 04:04",
  },
  {
    id: 11,
    name: "Delivery Confirmation",
    memberId: 3,
    type: 3,
    filename: "DELIVERY_CONFIRMATION1234.pdf",
    dt: "2021-09-27 04:04",
  },
  {
    id: 12,
    name: "TMVSA - PRI01-MA2678-SC6231",
    memberId: 3,
    type: 1,
    filename: "PRI01-MA2678-SC6231.pdf",
    dt: "2021-09-27 04:04",
  },
]);

export const getAttachments = ({ filters }) => {
  return mockSuccess(ActionTypes.GetAttachments, {
    delay: 500,
    response: mockAttachments._data.filter(
      (f) =>
        (f.memberId === filters.userDrp ||
          filters.userDrp === 0 ||
          filters.userDrp === undefined) &&
        (f.type === filters.typeDrp ||
          filters.typeDrp === 0 ||
          filters.typeDrp === undefined) &&
        ((filters.name &&
          f.name.toLowerCase().includes(filters.name.toLowerCase())) ||
          filters.name === undefined ||
          filters.name === "") &&
        ((filters.dates && f.dt >= filters.dates.start) ||
          filters.dates === undefined ||
          (filters.dates &&
            (filters.dates.start === null ||
              filters.dates.start == undefined))) &&
        ((filters.dates && f.dt <= filters.dates.end) ||
          filters.dates === undefined ||
          (filters.dates &&
            (filters.dates.end === null || filters.dates.end == undefined)))
    ),
  });
};
