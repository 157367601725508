import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "COMPANIES/ATTACHMENTS";

const ActionTypes = {
  GetAttachments: `${NAMESPACE}/GET_ATTACHMENTS`,
};

const attachments = [
  {
    id: 1,
    detail: "Change of Address",
    type: "Letter",
    from: "Customer",
    to: "IPS",
    dt: "02/27/2020",
  },
  {
    id: 2,
    detail: "Letter of Authority",
    type: "Docusign Letter",
    from: "Customer",
    to: "IPS",
    dt: "06/19/2019",
  },
  {
    id: 3,
    detail: "Payment Default Notification 1A",
    type: "Email",
    from: "IPS",
    to: "Customer",
    dt: "07/28/2021",
  },
];

export const getAttachments = () => {
  return mockSuccess(ActionTypes.GetAttachments, {
    delay: 500,
    response: attachments,
  });
};
