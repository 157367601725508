import { mockSuccess, MockArrayData } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULES/NOTES";

const ActionTypes = {
    GetNotes: `${NAMESPACE}/GET_NOTES`,
}

const mockNotes = new MockArrayData([
    
        
            {   id: 1, 
                dt: "2021-09-27 04:04", 
                memberId: 1,
                type: 1, 
                notes: "IPS Notes entered in sheet displayed here", 
                agreement: true,
                className: "purple-highlight"
            },
            {   id: 2, 
                dt: "2021-09-27 04:04", 
                memberId: 2,
                type: 2, 
                notes: "Supplier Admin Notes entered in sheet displayed here", 
                agreement: false,
                className: "green-highlight"
            },
            {   id: 3, 
                dt: "2021-09-27 04:04", 
                memberId: 3,
                type: 3, 
                notes: "Account Manager Notes entered in sheet displayed here", 
                agreement: true,
                className: "blue-highlight"
            },
            {   id: 4, 
                dt: "2021-09-27 04:04", 
                memberId: 4,
                type: 4, 
                notes: "Funder Notes entered in sheet displayed here", 
                agreement: false,
                className: "yellow-highlight"
            },
       
    
])


export const getNotes  = ({ filters }, {noteType}) => {

    return mockSuccess(ActionTypes.GetNotes, {
        delay: 500,
        response: mockNotes._data.filter(
          
            f => (f.memberId === filters.userDrp  || filters.userDrp === 0 || filters.userDrp === undefined)
            && (f.type === filters.typeDrp  || filters.typeDrp === 0 || filters.typeDrp === undefined)
            &&((filters.dates && f.dt >= filters.dates.start) || filters.dates === undefined || (filters.dates && (filters.dates.start === null || filters.dates.start == undefined)))
            &&((filters.dates && f.dt <= filters.dates.end) || filters.dates === undefined || (filters.dates && (filters.dates.end === null || filters.dates.end == undefined)))
            &&((noteType === 1 &&  f.agreement === true) || (noteType === 2 && f.agreement === false) || noteType == 0)
            )
        });
    }; 