import React, { useState, useEffect } from "react";
import {
  Button,
  Icon,
  Form,
  Grid,
  GridColumn,
  GridRow,
} from "semantic-ui-react";
import { Table, TableColumn, ModalBuilder } from "@redriver/cinnamon";
import { saveTsPreferences } from "./actions";
import { useDispatch } from "react-redux";
import { merge } from "lodash";
import { TranslatedModal } from "features/../../../shared/components/modals";

//columns must be an array and contain field, hidden & order properties
const TableConfigurator = ({
  config,
  columns,
  configId,
  titlePreFix,
  initialPageSize,
  pageSizeConfig,
}) => {
  const dispatch = useDispatch();
  const [refreshTable, setRefreshTable] = useState(false);
  const [tableColumns, setTableColumns] = useState();
  const [pageSize, setPageSize] = useState(initialPageSize);

  //on load merge columns and config
  useEffect(() => {
    //remove expired columns from config
    if (config) {
      config = config.filter((itemA) => {
        return columns.find((itemB) => {
          return itemA.field === itemB.field;
        });
      });
    }
    //merge configuration with column definition
    const mergedColumns = merge([], columns, config);
    mergedColumns.sort((a, b) => a.order - b.order);
    const filteredColumns = mergedColumns
      .map((item, index) => ({
        field: item.field,
        title: item.title,
        hidden: item.hidden ? item.hidden : false,
        order: item.order ? item.order : index + 1,
      }))
      .filter((item) => item.title !== "");
    setTableColumns(filteredColumns);
  }, []);

  useEffect(() => {
    setPageSize(pageSizeConfig);
  }, [pageSizeConfig]);

  const moveColumnUp = (field) => {
    //get items to re order
    const selectedItem = tableColumns.find((x) => x.field == field);
    const itemAbove = tableColumns.find(
      (x) => x.order == selectedItem.order - 1,
    );

    if (selectedItem.order > 1) {
      //update display orders
      selectedItem.order = selectedItem.order - 1;
      itemAbove.order = itemAbove.order + 1;
    }

    sortColumns();
  };

  const moveColumnDown = (field) => {
    //get items to re order
    const selectedItem = tableColumns.find((x) => x.field == field);
    const itemBelow = tableColumns.find(
      (x) => x.order == selectedItem.order + 1,
    );

    if (selectedItem.order < columns.length) {
      //update display orders
      selectedItem.order = selectedItem.order + 1;
      itemBelow.order = itemBelow.order - 1;
    }
    sortColumns();
  };

  const sortColumns = () => {
    //sort based on new order
    tableColumns.sort((a, b) => (a.order > b.order ? 1 : -1));
    reloadTable();
  };

  const reloadTable = () => {
    //reload table
    setRefreshTable(!refreshTable);
  };

  const toggleHidden = (field) => {
    const selectedItem = tableColumns.find((x) => x.field == field);
    selectedItem.hidden = !selectedItem.hidden;
    reloadTable();
  };

  const savePreferences = () => {
    saveTsPreferences(configId, tableColumns, dispatch);
    saveTsPreferences(configId + "PageSize", pageSize, dispatch);
  };

  const resetPreferences = () => {
    saveTsPreferences(configId, [], dispatch);
    saveTsPreferences(configId + "PageSize", initialPageSize, dispatch);
    setPageSize(initialPageSize);
  };

  const handleDropdownChange = (event, data) => {
    setPageSize(data.value);
  };

  return (
    <ModalBuilder
      onSubmit={savePreferences}
      renderTrigger={(showModal) => (
        <Button
          primary
          icon="cog"
          content="Table Configuration"
          onClick={showModal}
        />
      )}
      renderModal={(modalProps) => (
        <TranslatedModal.Add
          submitLabel="Save"
          {...modalProps}
          header={
            <div className="table-config-modal-header">
              {titlePreFix} Table Configuration
              <Button
                className="align-right"
                primary
                icon="redo"
                content="Reset"
                onClick={resetPreferences}
              />
            </div>
          }
        >
          <div className="pagesize-config">
            <Grid className="padded simple-border">
              <GridRow>
                <GridColumn mobile={6}>Page Size :</GridColumn>
                <GridColumn mobile={10}>
                  <Form.Dropdown
                    options={pageSizeOpt}
                    value={pageSize}
                    onChange={handleDropdownChange}
                  />
                </GridColumn>
              </GridRow>
            </Grid>
            <br />
          </div>
          <div className="table-order">
            <Table data={tableColumns}>
              <TableColumn title="Display Order" field="order"></TableColumn>
              <TableColumn title="Column Name" field="title"></TableColumn>
              <TableColumn
                title="Re-Order"
                render={(item) => (
                  <React.Fragment>
                    {item.order > 1 && (
                      <Button onClick={() => moveColumnUp(item.field)}>
                        <Icon name="angle up" />
                      </Button>
                    )}
                    {item.order < tableColumns.length && (
                      <Button onClick={() => moveColumnDown(item.field)}>
                        <Icon name="angle down" />
                      </Button>
                    )}
                  </React.Fragment>
                )}
              ></TableColumn>
              <TableColumn
                title="Hidden"
                render={(item) => (
                  <Form.Checkbox
                    checked={item.hidden}
                    toggle
                    onClick={() => toggleHidden(item.field)}
                  />
                )}
              ></TableColumn>
            </Table>
          </div>
        </TranslatedModal.Add>
      )}
    />
  );
};

const pageSizeOpt = [
  { value: 5, text: "5" },
  { value: 10, text: "10" },
  { value: 15, text: "15" },
  { value: 20, text: "20" },
  { value: 25, text: "25" },
  { value: 50, text: "50" },
  { value: 100, text: "100" },
];

export default TableConfigurator;
