import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { getAuthState, getAuthClaims } from "@redriver/cinnamon";
import { validateEmailToken } from "./actions";
import { AppRoutes, SystemRoutes, AmethystRoute } from "constants/routes";
import EmailVerificationResender from "./EmailVerificationResender";

const EmailVerification = ({
  token,
  loggedIn,
  emailVerified,
  validateEmailToken,
}) => {
  const [tokenValid, setTokenValid] = useState(null);

  useEffect(() => {
    const validate = async () => {
      const validationResult = await validateEmailToken(token);
      setTokenValid(validationResult);
    };
    validate();
  }, [token, validateEmailToken]);

  if (emailVerified) {
    return <p>Your email address has already been verified</p>;
  }

  if (!token) {
    return <p>No email token supplied</p>;
  }

  switch (tokenValid) {
    case true:
      return (
        <div>
          <p>Your email address has been verified</p>

          <div
            className="email-verification-button-container"
            style={{ width: "20em" }}
          >
            <div style={{ marginTop: 20 }}>
              <a
                className="ui primary button amethyst"
                role="button"
                href={AmethystRoute.Root}
              >
                Continue to the Amethyst calculator
              </a>
            </div>
            <div style={{ marginTop: 20 }}>
              <Button
                primary
                className="opal"
                as={Link}
                to={loggedIn ? AppRoutes.Root : SystemRoutes.Login}
              >
                Continue to Opal
              </Button>
            </div>
          </div>
        </div>
      );

    case false:
      return (
        <div>
          <p>The email token supplied is invalid or has expired</p>
          <EmailVerificationResender />
        </div>
      );

    default:
      return <p>Validating email token...</p>;
  }
};

EmailVerification.propTypes = {
  token: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { loggedIn } = getAuthState(state);
  const { emailVerified } = getAuthClaims(state);
  return {
    loggedIn,
    emailVerified,
  };
};

export default connect(mapStateToProps, { validateEmailToken })(
  EmailVerification,
);
