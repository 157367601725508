import React from "react";
import { registerLookup, apiGet, Form } from "@redriver/cinnamon";

const NAMESPACE = "SPVS/LOOKUPS";

const ActionTypes = {
  GetSPVStatus: `${NAMESPACE}/GET_SPV_STATUS`,
  GetAccountType: `${NAMESPACE}/GET_ACCOUNT_TYPE`,
};

export const spvStatusLookup = "spvStatusLookup";
export const accountTypeLookup = "accountTypeLookup";

registerLookup(
  spvStatusLookup,
  () => apiGet(ActionTypes.GetSPVStatus, "lookups/spv-status"),
  {
    transform: data =>
      data.map(x => {
        return {
          value: x.key,
          text: x.value,
        };
      }),
  }
);

export const SpvStatusDropdownLookup = ({ className, ...props }) => (
  <div className={className}>
    <Form.Dropdown {...props} lookup={spvStatusLookup} />
  </div>
);

registerLookup(
  accountTypeLookup,
  () => apiGet(ActionTypes.GetAccountType, "lookups/account-type"),
  {
    transform: data =>
      data.map(x => {
        return {
          value: x.key,
          text: x.value,
        };
      }),
  }
);

export const AccountTypeDropdownLookup = ({ className, ...props }) => (
  <div className={className}>
    <Form.Dropdown {...props} lookup={accountTypeLookup} />
  </div>
);
