import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "COMPANIES/SCHEDULES";

const ActionTypes = {
  getSchedules: `${NAMESPACE}/GET_SCHEDULES`,
  getScheduleItems: `${NAMESPACE}/GET_SCHEDULE_ITEMS`,
};

const mockSchedules = [
  {
    id: "U0857767",
    ref: "60 TOTAL MANAGED VOL - PRI18TMVA",
    masterDate: "31/03/2016",
    schedules: [
      {
        id: "U0857767/001",
        managedVol: 2402285,
        remainingVol: -1376070,
        sheetType: "Rewrite with No Additional Capital",
        qtyMinimum: 850.17,
        lastMinDate: "06/30/2022",
        qtyImplemented: "12/27/2021",
        dateActivated: "12/27/2021",
        status: "Live",
        attachment: "file.txt",
      },
    ],
  },
  {
    id: "A1B55723",
    ref: "63 340(2B) TMV & SA PR118TCMDB",
    masterDate: "31/12/2016",
    schedules: [
      {
        id: "A1B55723/001",
        managedVol: 524400,
        remainingVol: 253851,
        sheetType: "Rewrite with No Additional Capital",
        qtyMinimum: 5421.12,
        lastMinDate: "12/31/2021",
        qtyImplemented: "02/27/2020",
        dateActivated: "02/27/2020",
        status: "Live",
        attachment: "file.txt",
      },
    ],
  },
];

export const getCompanySchedules = () => {
  return mockSuccess(ActionTypes.getSchedules, {
    response: mockSchedules,
  });
};

export const getCompanySchedulesItems = (_, params) => {
  return mockSuccess(ActionTypes.getScheduleItems, {
    response: mockSchedules.find((x) => x.id === params.id).schedules,
  });
};
