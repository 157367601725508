import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/RESTRICTIONS";

const ActionTypes = {
  GetDefaultsRestrictions: `${NAMESPACE}/GET`,
};

export const getDefaultsRestrictions = ({ settingsId }) =>
  apiGet(ActionTypes.GetDefaultsRestrictions, `defaults-restrictions`, {
    settingsId,
  });
