import { apiGet, apiPut, mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "COMPANIES/CUSTOMERS";

const ActionTypes = {
  getCustomerDetails: `${NAMESPACE}/GET_CUSTOMER`,
  getCustomerAddresses: `${NAMESPACE}/GET_CUSTOMER_ADDRESSES`,
  getCustomerInvoices: `${NAMESPACE}/GET_CUSTOMER_INVOICES`,
  getCustomerContacts: `${NAMESPACE}/GET_CUSTOMER_CONTACTS`,
  getCustomerCrmUrl: `${NAMESPACE}/GET_CRM_URL`,
  getNewCustomerCrmUrl: `${NAMESPACE}/GET_NEW_CRM_URL`,
  getCustomerCrmRestriction: `${NAMESPACE}/GET_CRM_RESTRICTION`,
  setCustomerCrmRestriction: `${NAMESPACE}/SET_CRM_RESTRICTION`,
};

const customer = {
  id: 1,
  name: "Ethelbert Specialist Homes Limited",
  regNo: "OC8373838",
  opalID: 70013,
  crmID: "ETHEL546",
  acctManager: "Richard Cashman",
  status: 1,
  billingStatus: 2,
  documentProduction: 2,
  activeSinceDt: "02/07/2005",
  invoices: [
    {
      id: 1,
      no: "XSLINV/00609963",
      dt: "06/11/2021",
      scheduleRef: "MA1001-SC5125",
      net: 1500,
      gross: 1800,
      outstanding: 1800,
      due: "11/07/2021",
      status: "Overdue",
    },
    {
      id: 2,
      no: "XSLINV/00610759",
      dt: "06/15/2021",
      scheduleRef: "MA1001-SC5848",
      net: 800,
      gross: 960,
      outstanding: 960,
      due: "15/07/2021",
      status: "Overdue",
    },
    {
      id: 3,
      no: "XSLINV/00615982",
      dt: "06/21/2021",
      scheduleRef: "MA1001-SC5125",
      net: 2568,
      gross: 3081.6,
      outstanding: 3081.6,
      due: "21/07/2021",
      status: "Overdue",
    },
    {
      id: 4,
      scheduleRef: "Total",
      net: 4864,
      gross: 5841.6,
      outstanding: 5841.5,
    },
  ],
  addresses: [
    {
      id: 1,
      siteRef: "Registered",
      line1: "123 Made up street",
      line2: "",
      line3: "",
      town: "London",
      county: "London",
      postcode: "SW4 123",
      country: "UK",
      timeZone: "GMT",
      taxCode: "126",
      currency: process.env.CURRENCY_CODE,
      telephone: "01403 605848",
      email: "reception@eshi.co.uk",
      validated: true,
      primary: true,
      productLocation: true,
      invoice: false,
      territory: false,
    },
    {
      id: 2,
      siteRef: "Billing",
      line1: "Accounts Dept",
      line2: "123 Made up street",
      line3: "123 Made up street",
      town: "London",
      county: "London",
      postcode: "SW4 123",
      country: "UK",
      timeZone: "GMT",
      taxCode: "126",
      currency: process.env.CURRENCY_CODE,
      telephone: "01403 605848 - ext 458",
      email: "reception@eshi.co.uk",
      validated: true,
      primary: false,
      productLocation: false,
      invoice: true,
      territory: true,
    },
    {
      id: 3,
      siteRef: "Kit Location A",
      line1: "Floor 4",
      line2: "123 Made up street",
      line3: "123 Made up street",
      town: "London",
      county: "London",
      postcode: "SW4 123",
      country: "UK",
      timeZone: "GMT",
      taxCode: "126",
      currency: process.env.CURRENCY_CODE,
      telephone: "01403 605848",
      email: "",
      validated: true,
      primary: false,
      productLocation: true,
      invoice: false,
      territory: false,
    },
    {
      id: 4,
      siteRef: "Kit Location B",
      line1: "Unit 1A",
      line2: "123 Made up street",
      line3: "123 Made up street",
      town: "London",
      county: "London",
      postcode: "SW4 123",
      country: "UK",
      timeZone: "GMT",
      taxCode: "126",
      currency: process.env.CURRENCY_CODE,
      telephone: "01403 605848",
      email: "",
      validated: true,
      primary: false,
      productLocation: true,
      invoice: false,
      territory: false,
    },
  ],
  contacts: [
    {
      id: 1,
      prefix: "Dr",
      name: "Jack",
      surname: "Bloggs",
      email: "jbloggs@company.co.uk",
      phone: "01403 222 444",
      role: "IT Manager",
      territory: "Fareham",
    },
    {
      id: 2,
      prefix: "Mr",
      name: "Ian",
      surname: "Potter",
      email: "ipotter@company.co.uk",
      phone: "01403 222 485",
      role: "Finance Director",
      territory: "Fareham, London",
    },
    {
      is: 3,
      prefix: null,
      name: "Accounts Payable",
      surname: "",
      email: "ap@company.co.uk",
      phone: "01403 222 800",
      role: "Accounts Payable",
      territory: "All",
    },
    {
      id: 4,
      prefix: "Mrs",
      name: "Miranda",
      surname: "Bloggs",
      email: "Mbloggs@company.co.uk",
      phone: "01403 222 444",
      role: "IT Designer",
      territory: "Fareham",
    },
    {
      id: 5,
      prefix: "Mr",
      name: "Ian",
      surname: "Potter",
      email: "ipotter@company.co.uk",
      phone: "01403 222 485",
      role: "Finance Director",
      territory: "Fareham, London",
    },
    {
      is: 6,
      prefix: null,
      name: "Accounts Payable",
      surname: "",
      email: "ap@company.co.uk",
      phone: "01403 222 800",
      role: "Accounts Payable",
      territory: "All",
    },
    {
      id: 7,
      prefix: "Mr",
      name: "Jack",
      surname: "Bloggs",
      email: "jbloggs@company.co.uk",
      phone: "01403 222 444",
      role: "IT Manager",
      territory: "Fareham",
    },
    {
      id: 8,
      prefix: "Mr",
      name: "Ian",
      surname: "Potter",
      email: "ipotter@company.co.uk",
      phone: "01403 222 485",
      role: "Finance Director",
      territory: "Fareham, London",
    },
    {
      is: 9,
      prefix: null,
      name: "Accounts Payable",
      surname: "",
      email: "ap@company.co.uk",
      phone: "01403 222 800",
      role: "Accounts Payable",
      territory: "All",
    },
  ],
};

export const getCustomerDetails = () => {
  return mockSuccess(ActionTypes.getCustomerDetails, {
    delay: 100,
    response: customer,
  });
};

export const getCustomerAddresses = () => {
  return mockSuccess(ActionTypes.getCustomerAddresses, {
    delay: 500,
    response: customer.addresses,
  });
};

export const getCustomerInvoices = () => {
  return mockSuccess(ActionTypes.getCustomerInvoices, {
    delay: 500,
    response: customer.invoices,
  });
};

export const getCustomerContacts = () => {
  return mockSuccess(ActionTypes.getCustomerContacts, {
    delay: 500,
    response: customer.contacts,
  });
};

export const getCustomerCrmUrl = ({ clientId, userId }) =>
  apiGet(ActionTypes.getCustomerCrmUrl, `clients/${clientId}/crm-url`, {
    userId,
  });

export const getCustomerCrmRestriction = (clientId) =>
  apiGet(
    ActionTypes.getCustomerCrmRestriction,
    `clients/${clientId}/crm-restriction`
  );
export const putCustomerCrmRestriction = ({ clientId, restrictCrmUrlUpdate }) =>
  apiPut(
    ActionTypes.setCustomerCrmRestriction,
    `clients/${clientId}/crm-restriction`,
    { restrictCrmUrlUpdate }
  );
