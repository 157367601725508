import React from "react";
import { ListSystemConfig } from "features/SystemConfig";
import { PageContent } from "@redriver/cinnamon";
import { SystemConfigType } from "features/../../../shared/constants/enums";

const DocusignConfigPage = () => (
  <PageContent>
    <ListSystemConfig
      type={SystemConfigType.Docusign}
      title="Docusign configuration"
    />
  </PageContent>
);

export default DocusignConfigPage;
