import React, { useState } from "react";
import { ContentPanel } from "components/displays";
import MeterReadingTable from "./MeterReadingTable";
import { Button } from "semantic-ui-react";
import { AddModalWrapper } from "components/modals/";
import { AddManualReading, AddAdjustment } from ".";

const MeterReading = ({ productId }) => {
  const [selectedMeterReadingType, setSelectedMeterReadingType] = useState(0);

  const headers = ["BLACKTOTAL", "COLOURTOTAL", "SCANTOTAL"];

  return (
    <ContentPanel
      title="Meter Reading"
      wrapperCssClass="product-meter-reading outline-grid"
      headerCssClass="defaults-page-title"
      headerContent={
        <BtnNav
          selectedMeterReadingType={selectedMeterReadingType}
          setSelectedMeterReadingType={setSelectedMeterReadingType}
        />
      }
    >
      <div className="padded">
        <div className="flex">
          <div className="title">
            <h4>{headers[selectedMeterReadingType]}</h4>
          </div>
          <div className="align-right">
            <AddModalWrapper
              title="Manual Meter Entry"
              floated="right"
              linkText="Add Manual Reading"
              size="large"
              modalClassName="modal outline-grid"
              submitLabel="Save & Close"
            >
              <AddManualReading></AddManualReading>
            </AddModalWrapper>
            <AddModalWrapper
              title="Adjustment"
              floated="right"
              size="large"
              modalClassName="modal outline-grid"
              submitLabel="Save & Close"
            >
              <AddAdjustment></AddAdjustment>
            </AddModalWrapper>
          </div>
        </div>

        <MeterReadingTable
          productId={productId}
          meterReadingType={selectedMeterReadingType}
        />
      </div>
    </ContentPanel>
  );
};

const BtnNav = ({ selectedMeterReadingType, setSelectedMeterReadingType }) => {
  return (
    <Button.Group floated="right">
      <Button
        positive={selectedMeterReadingType === 0}
        onClick={() => setSelectedMeterReadingType(0)}
      >
        BLACKTOTAL
      </Button>
      <Button
        positive={selectedMeterReadingType === 1}
        onClick={() => setSelectedMeterReadingType(1)}
      >
        COLOURTOTAL
      </Button>
      <Button
        positive={selectedMeterReadingType === 2}
        onClick={() => setSelectedMeterReadingType(2)}
      >
        SCANTOTAL
      </Button>
    </Button.Group>
  );
};

export default MeterReading;
