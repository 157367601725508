import {
  requestIsBusy,
  requestError,
  requestResponse,
} from "@redriver/cinnamon";
import { ActionTypes } from "./actions";
import { RatesActionTypes as SharedActionTypes } from "features/../../../shared/features/Settings";
import { RateTypeDiscriminator } from "features/../../../shared/constants/enums";
import { produce } from "immer";

const getInitialRates = () => {
  let rates = {};
  rates[RateTypeDiscriminator.DealerNetRate] = { columns: [], rows: [] };
  rates[RateTypeDiscriminator.GrossSalesRate] = { columns: [], rows: [] };
  rates[RateTypeDiscriminator.Yield] = { columns: [], rows: [] };
  rates[RateTypeDiscriminator.IpsNetRate] = { columns: [], rows: [] };
  rates[RateTypeDiscriminator.Per1000DifferenceMinForIps] = {
    columns: [],
    rows: [],
  };
  rates[RateTypeDiscriminator.IpsYieldAtDealerNetRate] = {
    columns: [],
    rows: [],
  };
  rates.lockedById = null;
  rates.lockedByName = null;
  rates.ratesLoading = false;
  rates.yieldRates = null;
  return rates;
};

const initialState = {
  rates: {
    rates: getInitialRates(),
    ratesLoading: true,
    liquidityCharges: {
      liquidityRecords: [],
      includeIpSyndicateCharge: false,
    },
    liquidityLoading: true,
    filters: {
      rateGroupId: null,
      rateGroupName: "",
      funderType: null,
      paymentMethod: null,
      financeType: null,
      rateTableType: null,
      rateTableTypeName: "",
    },
  },
  rateType: RateTypeDiscriminator.GrossSalesRate,
  comparisonRateType: RateTypeDiscriminator.DealerNetRate,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SharedActionTypes.GetYieldRates: {
      if (requestIsBusy(action)) {
        return produce(state, (draftState) => {
          draftState.rates.ratesLoading = true;
        });
      }

      const response = requestResponse(action);

      if (!response) return state;

      const { yields, lockedById, lockedByName } = response;

      const newState = produce(state, (draftState) => {
        const rates = draftState.rates;
        rates.ratesLoading = false;
        rates.rates.yieldRates = yields;
        rates.rates.lockedById = lockedById;
        rates.rates.lockedByName = lockedByName;
        rates.rates.updated = Date.now();
      });

      return newState;
    }

    case SharedActionTypes.GetDefaultRateGroup: {
      if (requestIsBusy(action) || requestError(action)) return state;

      const { text, value } = requestResponse(action);
      const newState = produce(state, (draftState) => {
        const rates = draftState.rates;
        rates.filters.rateGroupId = value;
        rates.filters.rateGroupName = text;
      });
      return newState;
    }

    case ActionTypes.SetRateGroup: {
      const { text, value } = action.data;

      return produce(state, (draftState) => {
        const f = draftState.rates.filters;
        f.rateGroupId = value;
        f.rateGroupName = text;
      });
    }

    case ActionTypes.ClearRatesState: {
      return { ...initialState };
    }

    case SharedActionTypes.SetYieldRatesForm: {
      return produce(state, (draftState) => {
        const r = draftState.rates.rates;
        r.yieldRates = action.yieldRates;
      });
    }

    case SharedActionTypes.GetLiquidityCharges: {
      if (requestIsBusy(action) || requestError(action)) {
        return produce(state, (draftState) => {
          draftState.rates.liquidityLoading = true;
        });
      }

      const response = requestResponse(action);

      return produce(state, (draftState) => {
        const r = draftState.rates;
        r.liquidityCharges = response;
        r.liquidityLoading = false;
      });
    }

    case SharedActionTypes.UpdateLiquidityCharges: {
      const loading = requestIsBusy(action);
      return produce(state, (draftState) => {
        draftState.rates.liquidityLoading = loading;
      });
    }

    case ActionTypes.SetRateTableFilters: {
      const type = action.rateTableType;
      if (type) {
        return produce(state, (draftState) => {
          const f = draftState.rates.filters;
          f.funderType = type.funderType;
          f.paymentMethod = type.paymentMethod;
          f.financeType = type.financeType;
          f.rateTableType = type.value;
          f.rateTableTypeName = type.text;
        });
      }
      return state;
    }
    case SharedActionTypes.GetRates: {
      if (requestIsBusy(action) || requestError(action)) {
        return produce(state, (draftState) => {
          draftState.rates.ratesLoading = true;
        });
      }

      const res = requestResponse(action);

      let rates = {};
      rates[RateTypeDiscriminator.DealerNetRate] = res.find(
        (x) => x.rateType === RateTypeDiscriminator.DealerNetRate
      );
      rates[RateTypeDiscriminator.GrossSalesRate] = res.find(
        (x) => x.rateType === RateTypeDiscriminator.GrossSalesRate
      );

      if (!action.customData || !action.customData.preventYieldUpdates) {
        // Only update yield rates if getting on all-rate-page and yield rates are not passed in
        rates[RateTypeDiscriminator.Yield] = res.find(
          (x) => x.rateType === RateTypeDiscriminator.Yield
        );
      } else {
        rates[RateTypeDiscriminator.Yield] =
          state.rates[RateTypeDiscriminator.Yield];
      }
      rates[RateTypeDiscriminator.IpsNetRate] = res.find(
        (x) => x.rateType === RateTypeDiscriminator.IpsNetRate
      );
      rates[RateTypeDiscriminator.Per1000DifferenceMinForIps] = res.find(
        (x) => x.rateType === RateTypeDiscriminator.Per1000DifferenceMinForIps
      );
      rates[RateTypeDiscriminator.IpsYieldAtDealerNetRate] = res.find(
        (x) => x.rateType === RateTypeDiscriminator.IpsYieldAtDealerNetRate
      );

      const newState = produce(state, (draftState) => {
        const r = draftState.rates;
        r.rates = rates;
        r.ratesLoading = false;
      });

      return newState;
    }

    case ActionTypes.SetRateType: {
      const newState = produce(state, (draftState) => {
        const r = draftState;
        r.rateType = action.rateType;
      });

      return newState;
    }

    case ActionTypes.SetComparisonRateType: {
      const newState = produce(state, (draftState) => {
        const r = draftState;
        r.comparisonRateType = action.comparisonRateType;
      });

      return newState;
    }

    default:
      return state;
  }
};
