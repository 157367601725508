import React from "react";
import { ModalBuilder, Form } from "@redriver/cinnamon";
import { AddButton } from "features/../../../shared/components/buttons";
import { createSPV } from "./actions";
import { TranslatedModal } from "features/../../../shared/components/modals";

const AddSPV = ({ onCreated }) => {
  return (
    <ModalBuilder
      withForm
      submitAction={createSPV}
      onSubmitted={onCreated}
      renderTrigger={(showModal) => (
        <AddButton onClick={showModal} content="Add SPV" />
      )}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Add
          {...modalProps}
          className="add-modal"
          header="Add New SPV"
        >
          <Form {...formProps}>
            <div className="step details">
              <Form.Input field="name" label="Name" required />
              <Form.Input field="spvId" label="SPV Code*" maxLength={6} />
            </div>
            <div className="red-highlight left">
              *SPV Code cannot be edited after initial Save
            </div>
          </Form>
        </TranslatedModal.Add>
      )}
    />
  );
};

export default AddSPV;
