import React from "react";
import { ContentPanel } from "components/displays";
import { Grid, GridColumn, GridRow } from "semantic-ui-react";
import ProductInformationTable from "./ProductInformationTable";
import PhysicalLocationTable from "./PhysicalLocationTable";
import MeterReadingTable from "./MeterReadingTable";

const Summary = ({ productId }) => (
  <ContentPanel
    title="Summary"
    wrapperCssClass="outline-grid "
    headerCssClass="defaults-page-title"
  >
    <div className="padded">
      <Grid doubling>
        <GridRow>
          <GridColumn width={8}>
            <ProductInformationTable productId={productId} />
          </GridColumn>
          <GridColumn width={8}>
            <PhysicalLocationTable productId={productId} />
            <br />
            <MeterReadingTable productId={productId} />
          </GridColumn>
        </GridRow>
      </Grid>
    </div>
  </ContentPanel>
);

export default Summary;
