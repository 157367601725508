import React, { forwardRef } from "react";
import { Grid, Button } from "semantic-ui-react";
import { FormBuilder, Form } from "@redriver/cinnamon";
import { getAgreementStability, updateAgreementStability } from "./actions";
import {
  AgreementStabilityOptionsCheckboxGroup,
  AgreementStabilityOptionsRadioGroup,
  AgreementStabilityOptionsCheckboxGroupEmpty,
} from "features/../../../shared/features/Settings/lookups";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { Targets, Actions } from "constants/permissions";
import AccordionHelper from "components/displays/AccordionHelper";

const AgreementStability = forwardRef(
  ({ settingsId, toggleVisibility, collapsed }, ref) => {
    return (
      <div ref={ref}>
        <FormBuilder
          initialReadOnly
          loadAction={getAgreementStability}
          loadParams={{ settingsId }}
          submitAction={updateAgreementStability}
          submitParams={{ settingsId }}
          renderLoading={false}
          renderSubmitting={false}
          renderError={false}
          renderForm={(formProps, state, events) => {
            return (
              <AccordionHelper
                collapsed={collapsed}
                toggleVisibility={toggleVisibility}
                title="Agreement Stability"
                className="agreement-stability-settings"
              >
                <div className="defaults-page-title flex">
                  <div className="flex title-bar"></div>
                  <PermissionCheck
                    target={Targets.DefaultsRestrictions}
                    action={Actions.Edit}
                  >
                    <div className="agreement-stability-edit">
                      <IconButton
                        primary
                        icon={state.readOnly ? Pen : Save}
                        content={state.readOnly ? "Edit" : "Save"}
                        floated="right"
                        onClick={() =>
                          state.readOnly
                            ? events.onToggleReadOnly()
                            : events.onSubmit({ reset: ["readOnly"] })
                        }
                        className={
                          !state.readOnly ? "positive-title-action" : null
                        }
                        loading={state.loading || state.submitting}
                        disabled={state.loading || state.submitting}
                      />
                      {!state.readOnly && (
                        <Button
                          primary
                          className="cancel-title-action"
                          floated="right"
                          content="Cancel"
                          onClick={events.onReset}
                        />
                      )}
                    </div>
                  </PermissionCheck>
                </div>
                <Form
                  {...formProps}
                  disabled={state.submitting || state.readOnly}
                  className="agreement-stability-box"
                >
                  <Grid>
                    <Grid.Row className="row-padding-reducer">
                      <Form.Checkbox
                        label="Available as IPS Supplier Admin Settings"
                        field="supplierAdminSettings"
                        readOnly={state.readOnly}
                      />
                    </Grid.Row>
                    <Grid.Row className="agreement-stability-colored">
                      {"VMIN "}
                      <Form.Numeric
                        formatValue
                        subLabel="%"
                        subLabelPosition="right"
                        field="maxPercentage"
                        className=""
                      />
                      VMIN <Form.Numeric field="minQuarters" /> <p>Quarters</p>
                    </Grid.Row>

                    <Grid.Row className="boxed options-header">
                      <Grid.Column width={1}>Default</Grid.Column>
                      <Grid.Column width={6}>Available</Grid.Column>
                      <Grid.Column width={5}>Options</Grid.Column>
                      <Grid.Column width={2}>Visible to Supplier</Grid.Column>
                      <Grid.Column width={2}>Supplier Selection</Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="boxed no-margin-no-padding">
                      <Grid.Column width={1}>
                        <AgreementStabilityOptionsRadioGroup
                          field="default"
                          readOnly={state.readOnly}
                          className="checkbox-without-words fixed-height"
                        />
                      </Grid.Column>
                      <Grid.Column width={1}>
                        <AgreementStabilityOptionsCheckboxGroup
                          field="availableOptions"
                          readOnly={state.readOnly}
                          className="checkbox-with-words fixed-height"
                        />
                      </Grid.Column>
                      <Grid.Column
                        width={5}
                        className="apply-if-difference-col option-text"
                      >
                        <Grid.Row>Option 1 - No Stability Adjustment</Grid.Row>
                        <Grid.Row>
                          Option 2 - Retains Existing Schedule{" "}
                          <strong>Value </strong>(by TMV)
                        </Grid.Row>
                        <Grid.Row>
                          Option 3 - Retains Existing Schedule{" "}
                          <strong>TMV </strong>(by quarters)
                        </Grid.Row>
                        <Grid.Row>
                          Option 4 - Retains Existing Schedule{" "}
                          <strong>Value </strong>(by quarters)
                        </Grid.Row>
                      </Grid.Column>
                      <Grid.Column
                        width={5}
                        className="apply-if-difference-col"
                      >
                        <Grid.Row></Grid.Row>
                        <Grid.Row className="checkbox-row">
                          <Form.Checkbox
                            label="Apply if difference >"
                            field="applyIfDifference"
                            readOnly={state.readOnly}
                          />
                          <div className="apply-diff-text">
                            {formProps.value.maxPercentage}% or less than{" "}
                            {formProps.value.minQuarters} Quarters
                          </div>
                        </Grid.Row>
                        <Grid.Row></Grid.Row>
                        <Grid.Row></Grid.Row>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <div>
                          <AgreementStabilityOptionsCheckboxGroupEmpty
                            field="optionsVisibleToDealer"
                            readOnly={state.readOnly}
                            className="checkbox-without-words fixed-height"
                          />
                        </div>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <AgreementStabilityOptionsCheckboxGroupEmpty
                          field="dealerSelectionOptions"
                          readOnly={state.readOnly}
                          className="checkbox-without-words fixed-height"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>
              </AccordionHelper>
            );
          }}
        />
      </div>
    );
  }
);

export default AgreementStability;
