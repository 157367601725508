import React from "react";
import { numericFormatter } from "components/displays/DisplayHelper";
import { SimpleTableBuilder } from "../../../../../../shared/components/tables/sortableTable";
import { getSummary } from "./actions";

const CFAHeader = () => {
  const columns = [
    {
      field: "paymentMethod",
      title: "Payment Method",
      width: "15%",
      sortable: false,
    },
    {
      field: "paymentTotal",
      title: `CFA Payment Total ${process.env.MAJOR_CURRENCY}`,
      width: "10%",
      render: item => numericFormatter(item.paymentTotal, 2),
      sortable: false,
    },
    {
      field: "scheduleFinanceTotal",
      title: `Schedule Finance Total ${process.env.MAJOR_CURRENCY}`,
      width: "10%",
      render: item => numericFormatter(item.scheduleFinanceTotal, 2),
      sortable: false,
    },
    {
      field: "roundingTotal",
      title: `Finance Rounding Total ${process.env.MAJOR_CURRENCY}`,
      width: "10%",
      render: item => numericFormatter(item.roundingTotal, 2),
      sortable: false,
    },
    {
      field: "schedulePeriods",
      title: "Schedule Periods",
      width: "10%",
      sortable: false,
    },
    {
      field: "scheduleOutstandingPeriods",
      title: "Schedule Periods Outstanding",
      width: "10%",
      sortable: false,
    },
    {
      field: "scheduleTotalOutstanding",
      title: `Schedule Total Outstanding ${process.env.MAJOR_CURRENCY}`,
      width: "10%",
      sortable: false,
    },
    {
      field: "scheduleMaxFinanceVolRemaining",
      title: "Schedule - Max Finance Volume Remaining",
      width: "10%",
      sortable: false,
    },
    {
      field: "financeCPI",
      title: `Finance CPI (${process.env.MINOR_CURRENCY})`,
      width: "10%",
      sortable: false,
    },
  ];
  return (
    <SimpleTableBuilder
      emptyMessage=""
      className="cfa-summary-header"
      loadAction={getSummary}
      columns={columns}
    />
  );
};

export default CFAHeader;
