import { apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/RESTRICTIONS/DISABLE_MIN_LOGIC";

const ActionTypes = {
  UpdateDisableMinLogic: `${NAMESPACE}/UPDATE`,
};

export const updateDisableMinLogic = (formData, { settingsId }) =>
  apiPut(
    ActionTypes.UpdateDisableMinLogic,
    `defaults-restrictions/${settingsId}`,
    {
      disableMinLogic: {
        ...formData,
      },
    }
  );
