import React from "react";
import { Form } from "@redriver/cinnamon";



const Header = ({ data}) => {
  return (
    <Form value={data} disabled>
        <div className="product-summary-container">
          <h2>Products</h2>
          <div className="content-panel schedules-list">
            <div className="product-summary-primary">
                <div className="summary-primary-top-row">
                    <h3>Supplier:</h3>
                    <Form.Input field="supplierName"/>
                    <h3>Supplier ID:</h3>
                    <Form.Input field="supplierId"/>
                </div>
            </div>
            <div className="product-summary-secondary-info-container">
            <h4>Territory: </h4>
                <Form.Input field="territory"/>
                <h4>Office: </h4>
                <Form.Input field="office"/>
                <h4>Country: </h4>
                <Form.Input field="country"/>
            </div>
          </div>
        </div>
     </Form>
  );
};


export default Header;
