import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import classNames from "classnames";
import {
  ListBuilder,
  FormBuilder,
  ActionBar,
  usePreferences,
} from "@redriver/cinnamon";
import UploadEntityType from "features/../../../shared/constants/enums/UploadEntityType";
import { ContentPanel } from "components/displays";
import {
  UploadTemplateFilters,
  UploadTemplate,
  DownloadTemplate,
  ApproveTemplate,
} from "./";
import {
  dateFormatter,
  yesNoFormatter,
  blankDataFormatter,
} from "components/displays/DisplayHelper";
import {
  TableConfigurator,
  ConfigurableTable,
} from "features/CustomConfig/TableConfig";
import { TablePagination } from "components/pagination";
import { getTemplates } from "./actions";

const UploadTemplatesList = () => {
  const { preferences } = usePreferences();
  const [pageSizeConfig, setPageSizeConfig] = useState(
    preferences.settingsUploadTemplatesTableConfigPageSize
      ? preferences.settingsUploadTemplatesTableConfigPageSize
      : 25,
  );

  return (
    <ListBuilder
      withTable
      initialPagination={{ pageSize: pageSizeConfig, pageNumber: 1 }}
      loadAction={getTemplates}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      loadParams={{ pageSize: pageSizeConfig }}
      renderList={(tableProps, state, events) => (
        <TemplatesListContent
          tableProps={tableProps}
          state={state}
          events={events}
          pageSizeConfig={pageSizeConfig}
          setPageSizeConfig={setPageSizeConfig}
          preferences={preferences}
        />
      )}
    />
  );
};

const TemplatesListContent = ({
  tableProps,
  state,
  events,
  pageSizeConfig,
  setPageSizeConfig,
  preferences,
}) => {
  const [config, setConfig] = useState(
    preferences.settingsUploadTemplatesTableConfig,
  );

  useEffect(() => {
    setConfig(preferences.settingsUploadTemplatesTableConfig);
  }, [preferences.settingsUploadTemplatesTableConfig]);

  useEffect(() => {
    preferences.settingsUploadTemplatesTableConfigPageSize &&
      setPageSizeConfig(preferences.settingsUploadTemplatesTableConfigPageSize);
  }, [
    preferences.settingsUploadTemplatesTableConfigPageSize,
    setPageSizeConfig,
  ]);

  const columns = [
    {
      field: "settingsUploadEntityType",
      title: "Type",
      render: (item) => UploadEntityType[item.type],
      sortable: false,
    },
    { field: "modifiedBy", title: "Last Updated By", sortable: false },
    {
      field: "modifiedUtc",
      title: "Uploaded Date",
      render: (item) => dateFormatter(item.modifiedUtc),
    },
    {
      field: "requiresApproval",
      title: "Requires Approval",
      render: (item) => yesNoFormatter(item.requiresApproval),
      sortable: false,
    },
    {
      field: "proposedBy",
      title: "New Template Proposed By",
      sortable: false,
    },
    {
      field: "proposedUtc",
      title: "Proposed Date",
      render: (item) =>
        blankDataFormatter(dateFormatter(item.proposedUtc), "-"),
    },
    {
      field: "approvedBy1",
      title: "First Approver",
      sortable: false,
    },
    {
      field: "approvedBy1Utc",
      title: "First Approver Date",
      render: (item) =>
        blankDataFormatter(dateFormatter(item.approvedBy1Utc), "-"),
    },
    {
      field: "approvedBy2",
      title: "Second Approver",
      sortable: false,
    },
    {
      field: "approvedBy2Utc",
      title: "Second Approver Date",
      render: (item) =>
        blankDataFormatter(dateFormatter(item.approvedBy2Utc), "-"),
    },
    {
      field: "id",
      sortable: false,
      render: (item) => (
        <Button.Group basic size="medium">
          <ApproveTemplate
            templateName={UploadEntityType[item.type]}
            disabled={!item.requiresApproval}
            lastApproval={item.ApprovedBy1 && item.requiresApproval}
            id={item.id}
            reloadTable={events.onRefresh}
          />
          <DownloadTemplate
            id={item.id}
            includeButtonText={false}
            templateName={UploadEntityType[item.type]}
            templateType={item.type}
          />
        </Button.Group>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="templates-panel-container">
        <ContentPanel
          title="Settings Upload Templates"
          wrapperCssClass="outline-grid templates-list"
          headerCssClass="defaults-page-title"
          headerContent={
            <FilterBar
              state={state}
              events={events}
              config={config}
              columns={columns}
              initialPageSize={25}
              pageSizeConfig={pageSizeConfig}
            ></FilterBar>
          }
        >
          <div className="spaced">
            <ConfigurableTable
              state={state}
              events={events}
              tableProps={{
                ...tableProps,
                className: classNames(tableProps.className, "templates"),
              }}
              emptyMessage="No upload templates found"
              columns={columns}
              config={config}
            />
            <div className="padded">
              <TablePagination
                pageSize={pageSizeConfig}
                pageNumber={state.pagination.pageNumber}
                onChange={events.onChangePagination}
                totalItems={state.total}
              />
            </div>
          </div>
        </ContentPanel>
      </div>
    </React.Fragment>
  );
};

const FilterBar = ({
  state,
  events,
  config,
  columns,
  initialPageSize,
  pageSizeConfig,
}) => (
  <FormBuilder
    value={state.filters}
    onChange={events.onChangeFilters}
    renderForm={(formProps) => (
      <ActionBar className="filter-display">
        <ActionBar.Item fluid></ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <DownloadTemplate />
        </ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <UploadTemplate onSubmitted={events.onRefresh} />
        </ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <TableConfigurator
            config={config}
            columns={columns}
            configId="dataUploadsTableConfig"
            titlePreFix="Template Uploads"
            initialPageSize={initialPageSize}
            pageSizeConfig={pageSizeConfig}
          ></TableConfigurator>
        </ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <UploadTemplateFilters
            title="Filters"
            filters={state.filters}
            onChange={events.onChangeFilters}
            formProps={formProps}
            onReset={events.onReset}
          />
        </ActionBar.Item>
      </ActionBar>
    )}
  />
);

export default UploadTemplatesList;
