import React, { useState } from "react";
import { ContentPanel } from "components/displays";
import { Form, FormBuilder, ActionBar } from "@redriver/cinnamon";
import { Grid, Table } from "semantic-ui-react";
import {
  getFunderSupplierSettings,
  getCfaReference,
  getBillingCodes,
  getBilling,
} from "./actions";
import { FunderSuppliersLookupDropdown } from "./lookups";
import { EditableFormWrapper } from "components/forms";
import AddAssociatedSupplierModal from "./AddAssociatedSupplierModal";
import { funderStatusDropdownValues } from "constants/enums";

const FunderSettings = ({ funderId }) => {
  const [supplierId, setSupplierId] = useState();
  const [supplierChangedTimeStamp, setSupplierChangedTimeStamp] = useState(
    Date.now()
  );
  const [supplierAddedTimeStamp, setSupplierAddedTimeStamp] = useState(
    Date.now()
  );

  return (
    <div className="funders-settings">
      <ContentPanel
        title="Associated Supplier / SPV Settings"
        wrapperCssClass="outline-grid yellow"
        headerCssClass="defaults-page-title funders-header yellow"
        headerContent={
          <FormBuilder
            renderForm={(formProps) => (
              <Form {...formProps}>
                <ActionBar className="filter-display">
                  <ActionBar.Item>
                    <div>
                      <FunderSuppliersLookupDropdown
                        placeholder="Select a supplier association ..."
                        value={supplierId}
                        onChange={(_, data) => {
                          setSupplierChangedTimeStamp(Date.now());
                          setSupplierId(data.value);
                        }}
                        lookupParams={{
                          funderId: funderId,
                          timeStamp: supplierAddedTimeStamp,
                        }}
                      />
                    </div>
                  </ActionBar.Item>
                  <ActionBar.Space />
                  <ActionBar.Item>
                    <AddAssociatedSupplierModal
                      funderId={funderId}
                      modalClassName="modal outline-grid yellow"
                      onSubmitted={() => setSupplierAddedTimeStamp(Date.now())}
                    />
                  </ActionBar.Item>
                </ActionBar>
              </Form>
            )}
          />
        }
      >
        <div className="padded">
          <Grid columns={2} doubling>
            <Grid.Row>
              <Grid.Column>
                <SupplierForm
                  supplierId={supplierId}
                  supplierChangedTimeStamp={supplierChangedTimeStamp}
                />
              </Grid.Column>
              <Grid.Column>
                <CfaReferenceForm />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br />
          <br />
          <BillingForm />
          <br />
          <br />
          <BillingCodeForm />
        </div>
      </ContentPanel>
    </div>
  );
};

const SupplierForm = ({ supplierId, supplierChangedTimeStamp }) => (
  <FormBuilder
    initialReadOnly
    loadAction={supplierId ? getFunderSupplierSettings : null}
    loadParams={{ id: supplierId, supplierChangedTimeStamp }}
    renderForm={(formProps, state, events) => (
      <EditableFormWrapper
        formWrapperCssClass="spaced supplier-form"
        title="Supplier/SPV"
        wrapperCssClass="outline-grid yellow"
        headerCssClass="defaults-page-title yellow"
        state={state}
        events={events}
        formProps={formProps}
      >
        <SupplierFields formProps={formProps} state={state}></SupplierFields>
      </EditableFormWrapper>
    )}
  />
);

const CfaReferenceForm = () => (
  <FormBuilder
    initialReadOnly
    loadAction={getCfaReference}
    renderForm={(formProps, state, events) => (
      <EditableFormWrapper
        formWrapperCssClass="spaced supplier-form"
        title="CFA Reference"
        wrapperCssClass="outline-grid yellow"
        headerCssClass="defaults-page-title yellow"
        state={state}
        events={events}
        formProps={formProps}
      >
        <Form {...formProps}>
          <div className="ui labeled input custom-sublabel">
            <div className="ui label label">CFA Reference Generation Type</div>
            <Form.Dropdown
              field="type"
              disabled={state.readOnly}
              options={[
                { value: 1, text: "Automatic" },
                { value: 2, text: "Manual" },
              ]}
            />
          </div>
          <Form.Input
            field="prefix"
            subLabel="CFA Prefix"
            readOnly={false}
            disabled={state.readOnly}
          />
          <Form.Input
            field="number"
            subLabel="CFA Number - Next in Sequence"
            readOnly={false}
            disabled={state.readOnly}
          />
        </Form>
      </EditableFormWrapper>
    )}
  />
);

const BillingForm = () => (
  <FormBuilder
    initialReadOnly
    loadAction={getBilling}
    renderForm={(formProps, state, events) => (
      <EditableFormWrapper
        formWrapperCssClass="spaced "
        title="Billing"
        wrapperCssClass="outline-grid yellow"
        headerCssClass="defaults-page-title yellow"
        state={state}
        events={events}
        formProps={formProps}
      >
        <React.Fragment>
          <div>
            <Grid columns={2} doubling>
              <Grid.Row className="row">
                <Grid.Column>
                  <div className="padded">
                    <label>
                      <b>Additional Units</b>
                    </label>
                    <Form.RadioGroup
                      field="additionalUnits"
                      readOnly={state.readOnly}
                      options={[
                        {
                          value: false,
                          text: "Collect & Hold Additional Units Charge",
                        },
                        {
                          value: true,
                          text: "Collect & Pay Additional Units Charge",
                        },
                      ]}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <label>
                    <b>Payment Date</b>
                  </label>
                  <div className="padded flex">
                    <div>
                      <label>Schedule Due Date plus&nbsp;</label>{" "}
                    </div>
                    <div className="due-date-container">
                      <Form.Input
                        field="dueDatePlus"
                        disabled={formProps.readOnly}
                        readOnly={false}
                      />
                    </div>
                    <div>
                      <label>&nbsp;days</label>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>

          <div className="padded flex">
            <div>
              <label>
                <b>
                  Self-Bill Invoicing Option
                  <br />
                  <br />
                </b>
              </label>
              <Table className="self-bill-invoicing">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell className="left-align" style={{ width: "30%" }}>
                      <b>Minimum</b>
                    </Table.Cell>
                    <Table.Cell className="left-align" style={{ width: "20%" }}>
                      <Form.Checkbox
                        field="selfBillingMinimum"
                        readOnly={state.readOnly}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className="left-align">
                      <b>Excess</b>
                    </Table.Cell>
                    <Table.Cell className="left-align">
                      <Form.Checkbox
                        field="selfBillingExcess"
                        readOnly={state.readOnly}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>

          <div className="padded">
            <label>
              <b>
                Account Information
                <br />
                <br />
              </b>
            </label>
            <div className="defaults-grid">
              <Grid columns={2} doubling>
                <Grid.Row>
                  <Grid.Column className="defaults-grid">
                    <Form.Object field="purchaseLedger">
                      {({ parentFields }) => (
                        <BillingTable
                          state={state}
                          parentFields={parentFields}
                        ></BillingTable>
                      )}
                    </Form.Object>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Object field="salesLedger">
                      {({ parentFields }) => (
                        <BillingTable
                          state={state}
                          parentFields={parentFields}
                        ></BillingTable>
                      )}
                    </Form.Object>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        </React.Fragment>
      </EditableFormWrapper>
    )}
  />
);

const BillingCodeForm = () => (
  <FormBuilder
    initialReadOnly
    loadAction={getBillingCodes}
    renderForm={(formProps, state, events) => (
      <EditableFormWrapper
        formWrapperCssClass="spaced supplier-form"
        title="Billing Codes"
        wrapperCssClass="outline-grid yellow"
        headerCssClass="defaults-page-title yellow"
        state={state}
        events={events}
        formProps={formProps}
      >
        <Form.Input
          field="cfaPayment"
          subLabel="CFA Payment"
          readOnly={false}
          disabled={state.readOnly}
        />
        <p>Continuing Payment Billing Code Defaults</p>
        <Form.Input
          field="funder"
          subLabel="Funder"
          readOnly={false}
          disabled={state.readOnly}
        />
        <Form.Input
          field="supplier"
          subLabel="Supplier"
          readOnly={false}
          disabled={state.readOnly}
        />
        <Form.Input
          field="ipsSpv"
          subLabel="IPS SPV"
          readOnly={false}
          disabled={state.readOnly}
        />
      </EditableFormWrapper>
    )}
  />
);

const BillingTable = ({ state }) => {
  return (
    <Table className="account-information">
      <Table.Body>
        <Table.Row>
          <Table.Cell className="left-align" style={{ width: "30%" }}>
            <b>IPS Account Type</b>
          </Table.Cell>
          <Table.Cell className="left-align" style={{ width: "20%" }}>
            <Form.Input minValue={4} field="description" readOnly={true} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="left-align">
            <b>IPS Company ID</b>
          </Table.Cell>
          <Table.Cell className="left-align">
            <Form.Input field="ipsCompanyID" readOnly={state.readOnly} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="left-align">
            <b>Company Name</b>
          </Table.Cell>
          <Table.Cell className="left-align">
            <Form.Input field="companyName" readOnly={state.readOnly} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="left-align">
            <b>Address Line 1</b>
          </Table.Cell>
          <Table.Cell className="left-align">
            <Form.Input field="address1" readOnly={state.readOnly} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="left-align">
            <b>Address Line 2</b>
          </Table.Cell>
          <Table.Cell className="left-align">
            <Form.Input field="address2" readOnly={state.readOnly} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="left-align">
            <b>Address Line 3</b>
          </Table.Cell>
          <Table.Cell className="left-align">
            <Form.Input field="address3" readOnly={state.readOnly} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="left-align">
            <b>Town/City</b>
          </Table.Cell>
          <Table.Cell className="left-align">
            <Form.Input field="town" readOnly={state.readOnly} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="left-align">
            <b>County</b>
          </Table.Cell>
          <Table.Cell className="left-align">
            <Form.Input field="county" readOnly={state.readOnly} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="left-align">
            <b>Postcode</b>
          </Table.Cell>
          <Table.Cell className="left-align">
            <Form.Input field="postcode" readOnly={state.readOnly} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="left-align">
            <b>Country</b>
          </Table.Cell>
          <Table.Cell className="left-align">
            <Form.Input field="country" readOnly={state.readOnly} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="left-align">
            <b>Send Self-Bill Invoices</b>
          </Table.Cell>
          <Table.Cell className="left-align">
            <Form.Checkbox field="selfBillInvoices" readOnly={state.readOnly} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="left-align">
            <b>Send Via</b>
          </Table.Cell>
          <Table.Cell className="left-align">
            <Form.Dropdown
              field="sendType"
              disabled={state.readOnly}
              options={[
                { value: 1, text: "Email" },
                { value: 2, text: "Post" },
              ]}
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="left-align">
            <b>Email Address</b>
          </Table.Cell>
          <Table.Cell className="left-align">
            <Form.Input field="emailAddress" readOnly={state.readOnly} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell className="left-align">
            <b>CC</b>
          </Table.Cell>
          <Table.Cell className="left-align">
            <Form.Input field="cc" readOnly={state.readOnly} />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

const SupplierFields = ({ state, formProps }) => {
  return (
    <Form {...formProps}>
      <div className="ui labeled input custom-sublabel">
        <div className="ui label label">Supplier</div>
        <Form.Input field="supplierName" readOnly />
      </div>
      <div className="ui labeled input custom-sublabel">
        <div className="ui label label">SPV</div>
        <Form.Input field="spvName" readOnly />
      </div>
      <Form.Input
        field="currency"
        subLabel="Currency"
        readOnly={false}
        disabled={state.readOnly}
      />
      <Form.Input
        field="number"
        subLabel="Trading Agreement Number"
        readOnly={false}
        disabled={state.readOnly}
      />
      <Form.Input
        field="reference"
        subLabel="Terms Reference"
        readOnly={false}
        disabled={state.readOnly}
      />
      <div className="ui labeled input custom-sublabel">
        <div className="ui label label">Status</div>
        <Form.Dropdown
          field="status"
          options={funderStatusDropdownValues}
          required
        />
      </div>
      <div className="ui labeled input custom-sublabel">
        <div className="ui label label">Start Date</div>
        <Form.Date
          dateFormat="DD MMM YYYY"
          local
          field="startDt"
          placeholder="Start Date"
          readOnly={false}
          disabled={state.readOnly}
        />
      </div>
      <Form.Input
        field="endDt"
        subLabel="End Date"
        readOnly={false}
        disabled={state.readOnly}
      />
    </Form>
  );
};

export default FunderSettings;
