import React, { forwardRef } from "react";
import { Form, FormBuilder } from "@redriver/cinnamon";
import { AccordionHelper } from "components/displays";
import PenSvg from "features/../../../shared/assets/pen.svg";
import SaveSvg from "features/../../../shared/assets/save.svg";
import { IconButton } from "features/../../../shared/components/buttons";
import { Button, Grid } from "semantic-ui-react";
import { getContractPreApproval, setContractPreApproval } from "../../actions";

const ContractPreApprovalSettings = forwardRef(
  ({ settingsId, collapsed, toggleVisibility }, ref) => (
    <div ref={ref}>
      <AccordionHelper
        title="Contract Pre-approval"
        collapsed={collapsed}
        toggleVisibility={toggleVisibility}
      >
        {settingsId && (
          <FormBuilder
            initialReadOnly
            loadAction={getContractPreApproval}
            loadParams={settingsId}
            submitAction={setContractPreApproval}
            submitParams={{ settingsId }}
            renderForm={(formProps, state, events) => (
              <Form {...formProps}>
                <div className="header">
                  <div className="actions form-header">
                    {!state.readOnly && (
                      <Button
                        primary
                        className="cancel-title-action"
                        content="Cancel"
                        onClick={events.onReset}
                      />
                    )}
                    <IconButton
                      primary
                      icon={state.readOnly ? PenSvg : SaveSvg}
                      content={state.readOnly ? "Edit" : "Save"}
                      onClick={() =>
                        state.readOnly
                          ? events.onToggleReadOnly()
                          : events.onSubmit({ reset: ["readOnly"] })
                      }
                      className={
                        !state.readOnly ? "positive-title-action" : null
                      }
                    />
                  </div>
                </div>
                <Form
                  {...formProps}
                  disabled={state.submitting || state.readOnly}
                >
                  <Grid className="contract-pre-approval">
                    {process.env.TRADE_AS_IPS && (
                      <Grid.Row className="check-boxes">
                        <Form.Checkbox
                          field="supplierPreApprovalRequired"
                          label="Supplier pre-approval required"
                        />
                      </Grid.Row>
                    )}
                    <Grid.Row>
                      <Form.Email
                        field="supplierPreApprovalEmail"
                        label={
                          process.env.TRADE_AS_IPS
                            ? "Supplier pre-approval email:"
                            : "Principal pre-approval email"
                        }
                        fluid
                        className="fontSize"
                      />
                    </Grid.Row>
                    {process.env.TRADE_AS_IPS && (
                      <React.Fragment>
                        <Grid.Row className="check-boxes">
                          <Form.Checkbox
                            field="ipsPreApprovalRequired"
                            label="IPS pre-approval required"
                          />
                        </Grid.Row>
                        <Grid.Row>
                          <Form.Email
                            field="ipsPreApprovalEmail"
                            label="IPS pre-approval email:"
                            fluid
                          />
                        </Grid.Row>
                      </React.Fragment>
                    )}
                  </Grid>
                </Form>
              </Form>
            )}
          />
        )}
      </AccordionHelper>
    </div>
  ),
);

export default ContractPreApprovalSettings;
