import React from "react";
import { Form } from "@redriver/cinnamon";

const FunderForm = ({ ...formProps }) => {
  return (
    <React.Fragment>
      <div>
        <Form {...formProps}>
          <div className="step details">
            <Form.Input
              field="name"
              label="Name"
              fluid
              required
              placeholder="Name..."
              maxLength={50}
            />
            <Form.Email
              field="email"
              label="Email"
              fluid
              required
              placeholder="Email..."
            />
          </div>
          <div className="step details">
            <Form.Input
              field="position"
              label="Position"
              fluid
              required
              placeholder="Position..."
              maxLength={20}
            />
            <Form.Input
              field="phoneNumber"
              label="Phone Number"
              fluid
              required={formProps.value.requireTwoFactorAuthentication}
              placeholder="Phone number..."
              maxLength={20}
            />
          </div>
          <div className="step details">
            <Form.Input
              field="phoneCountryCode"
              label="Phone Country Code"
              fluid
              required={formProps.value.requireTwoFactorAuthentication}
              placeholder="Country Code..."
              maxLength={20}
            />
            <Form.Checkbox
              field="requireTwoFactorAuthentication"
              label="Require Two Factor Authentication"
              fluid
              maxLength={20}
            />
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default FunderForm;
