import React from "react";
import { ListBuilder, ActionBar, Table } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { getPrinters } from "./actions";
import AddPrinter from "./AddPrinter";
import Add from "features/../../../shared/assets/white-add.svg";
import PrinterActions from "./PrinterActions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import { useTranslation } from "react-i18next";

const ListPrinter = ({ dealerOfficeId }) => {
  const { t } = useTranslation("supplierOffices", {
    keyPrefix: "largeFormat.printer",
  });
  return (
    <PermissionCheck target={Targets.LargeFormat} action={Actions.View}>
      <React.Fragment>
        <h4 className="settings-title">{t("title")}</h4>
        <ListBuilder
          withTable
          initialPagination={{ pageSize: 25, pageNumber: 1 }}
          loadAction={getPrinters}
          loadParams={{ dealerOfficeId }}
          dataTransform={data => data.results}
          totalTransform={data => data.totalResults}
          renderList={(tableProps, state, events) => (
            <div className="content-container principal-list lf-list-columns">
              <ActionBar.Item align="right">
                <AddPrinter
                  primary
                  dealerOfficeId={dealerOfficeId}
                  icon={Add}
                  content={t("newPrinter")}
                  onAdded={events.onRefresh}
                />
              </ActionBar.Item>
              <Table {...tableProps} dataKey="id">
                <Table.Column field="model" title={t("name")} />
                <Table.Column
                  field="targetMarketName"
                  title={t("targetMarket")}
                  className="min-width-14"
                />
                <Table.Column
                  field="paperWidthName"
                  title={t("rollWidth")}
                  className="min-width-7"
                />
                <Table.Column field="colours" title={t("coloursInUnit")} />

                <Table.Column
                  field="scanner"
                  title={t("scannerIncluded")}
                  render={item =>
                    item.scanner ? (
                      <span>{t("yes")}</span>
                    ) : (
                      <span>{t("no")}</span>
                    )
                  }
                />
                <Table.Column
                  hoverable
                  align="right"
                  render={printer => (
                    <PrinterActions
                      dealerOfficeId={dealerOfficeId}
                      printer={printer}
                      onRefresh={events.onRefresh}
                    />
                  )}
                />
              </Table>
              <TablePagination
                pageNumber={state.pagination.pageNumber}
                onChange={events.onChangePagination}
                pageSize={state.pagination.pageSize}
                totalItems={state.total}
              />
            </div>
          )}
        />
      </React.Fragment>
    </PermissionCheck>
  );
};

export default ListPrinter;
