import React from "react";
import { Table, Loadable } from "@redriver/cinnamon";
import { merge, values, keyBy } from "lodash";
import { SortHeader } from "../../../../../shared/components/tables/sortableTable";

const ConfigurableTable = ({
  tableProps,
  emptyMessage,
  columns,
  config,
  events,
  state,
  selectedItems,
  setSelected,
  dataKey = "id",
}) => {
  //if using a selectable column you must only use 1
  //you will need to supply the selectedItems and setSelected useState props.
  //see MiscellaneousInvoicing.js for example.

  //remove expired columns from config
  if (config) {
    config = config.filter((itemA) => {
      return columns.find((itemB) => {
        return itemA.field === itemB.field;
      });
    });
  }

  //sort and filter items
  let merged = merge(keyBy(columns, "field"), keyBy(config, "field"));
  let mergedValues = values(merged);
  mergedValues.sort((a, b) => a.order - b.order);
  let filteredColumns = mergedValues.filter(
    (x) => x.hidden !== null && x.hidden !== true
  );

  //get count for all filtered columns that have a title
  const columnCount = filteredColumns.filter(
    (item) => item.title !== ""
  ).length;

  //if all columns are hidden revert to default.
  if (columnCount === 0) {
    filteredColumns = mergedValues;
  }

  return (
    tableProps && (
      <Loadable loading={tableProps.loading}>
        <Table
          {...tableProps}
          dataKey={dataKey}
          emptyMessage={emptyMessage}
          selectable
          loading={false}
        >
          {filteredColumns.map((c) =>
            c.selectableColumn ? (
              <Table.SelectableColumn
                key={c.field}
                value={selectedItems}
                width={c.width}
                onChange={setSelected}
                totalItems={state.total}
              />
            ) : c.dynamic ? (
              <Table.Column title={c.title} render={c.render} key={c.field} />
            ) : (
              <Table.Column
                header={
                  c.sortable === undefined || c.sortable ? (
                    <SortHeader
                      field={c.field}
                      title={c.title}
                      value={state.filters}
                      onChange={events.onChangeFilters}
                    />
                  ) : (
                    c.title
                  )
                }
                key={c.field}
                field={c.field}
                title={c.title}
                width={c.width}
                render={c.render}
              />
            )
          )}
        </Table>
      </Loadable>
    )
  );
};

export default ConfigurableTable;
