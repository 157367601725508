import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { ContentPanel } from "components/displays";
import React, { useState } from "react";
import { getDocumentNumbering, updateDocumentNumbering } from "./actions";
import { Actions, Targets } from "constants/permissions";
import { PermissionCheck } from "components/auth";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

const DocumentNumbering = (dealerId) => {
  const { t } = useTranslation();

  const [customErrorsMin, setCustomErrorsMin] = useState([]);
  const [customErrorsMax, setCustomErrorsMax] = useState([]);
  const [customErrorsMasterStart, setCustomErrorsMasterStart] = useState([]);
  const [customErrorsScheduleStart, setCustomErrorsScheduleStart] = useState(
    [],
  );

  const errors = [
    customErrorsMin,
    customErrorsMax,
    customErrorsMasterStart,
    customErrorsScheduleStart,
  ].filter((x) => x != null && x.length);

  return (
    <PermissionCheck target={Targets.Dealer} action={Actions.View}>
      <FormBuilder
        loadAction={getDocumentNumbering}
        loadParams={dealerId}
        submitAction={updateDocumentNumbering}
        submitParams={dealerId}
        initialReadOnly
        renderForm={(formProps, state, events) => {
          if (!formProps.value) return null;
          return (
            <Form {...formProps}>
              <ContentPanel
                title="Document Numbering"
                wrapperCssClass="outline-grid supplier-header"
                headerCssClass="defaults-page-title"
                headerContent={
                  <PermissionCheck
                    target={Targets.Dealer}
                    action={Actions.Edit}
                  >
                    <div className="flex">
                      <IconButton
                        primary
                        icon={state.readOnly ? Pen : Save}
                        content={
                          state.readOnly
                            ? t("shared:buttons:edit")
                            : t("shared:buttons:save")
                        }
                        floated="right"
                        onClick={() =>
                          state.readOnly
                            ? events.onToggleReadOnly()
                            : events.onSubmit({ reset: ["readOnly"] })
                        }
                        className={
                          !state.readOnly ? "positive-title-action" : null
                        }
                      />
                      {!state.readOnly && (
                        <Button
                          className="cancel-title-action"
                          floated="right"
                          content={t("shared:buttons:cancel")}
                          onClick={() => {
                            events.onReset();
                          }}
                        />
                      )}
                    </div>
                  </PermissionCheck>
                }
              >
                <div className="document-numbering-content">
                  <div className="character-range-content">
                    <span>Master Character Range</span>
                    <div className="character-range">
                      <Form.Input
                        field="masterIdCharacterRangeMin"
                        required
                        showErrors={false}
                        customErrors={customErrorsMin}
                        customValidator={(_, __, ___, { value }) => {
                          setCustomErrorsMin([]);
                          if (!(value.masterIdCharacterRangeMin || "").length) {
                            return;
                          }
                          if (value.masterIdCharacterRangeMin.length !== 2) {
                            setCustomErrorsMin([
                              "From must be 2 letters in length",
                            ]);
                          } else {
                            if (
                              !/^[a-zA-Z]+$/.test(
                                value.masterIdCharacterRangeMin,
                              )
                            ) {
                              setCustomErrorsMin(["From must be only letters"]);
                            }
                          }
                          return;
                        }}
                      />
                      <span>to</span>
                      <Form.Input
                        field="masterIdCharacterRangeMax"
                        required
                        showErrors={false}
                        customErrors={customErrorsMax}
                        customValidator={(_, __, ___, { value }) => {
                          setCustomErrorsMax([]);
                          if (!(value.masterIdCharacterRangeMax || "").length) {
                            return;
                          }
                          if (value.masterIdCharacterRangeMax.length !== 2) {
                            setCustomErrorsMax([
                              "To must be 2 letters in length",
                            ]);
                          } else {
                            if (
                              !/^[a-zA-Z]+$/.test(
                                value.masterIdCharacterRangeMax,
                              )
                            ) {
                              setCustomErrorsMax(["To must be only letters"]);
                            }
                          }
                          return;
                        }}
                      />
                    </div>
                    <ErrorMessage
                      header="Character Range invalid"
                      error={
                        Array.isArray(errors) && errors.length
                          ? errors.join("\n")
                          : null
                      }
                    />
                  </div>
                  <div className="starting-number-content">
                    <span>Master Starting Number</span>
                    <Form.Input
                      field="masterIdStartingNumber"
                      required
                      showErrors={
                        customErrorsMasterStart &&
                        customErrorsMasterStart.length
                      }
                      customErrors={customErrorsMasterStart}
                      customValidator={(_, __, ___, { value }) => {
                        setCustomErrorsMasterStart([]);
                        if (!(value.masterIdStartingNumber || "").length) {
                          return;
                        }
                        if (value.masterIdStartingNumber.length > 6) {
                          setCustomErrorsMasterStart([
                            "Must be 6 numbers or less in length",
                          ]);
                        } else {
                          if (!/^[0-9]+$/.test(value.masterIdStartingNumber)) {
                            setCustomErrorsMasterStart([
                              "Must be only numbers",
                            ]);
                          }
                        }
                        return;
                      }}
                    />
                    <span>Schedule Starting Number</span>
                    <Form.Input
                      field="scheduleStartingNumber"
                      required
                      showErrors={
                        customErrorsScheduleStart &&
                        customErrorsScheduleStart.length
                      }
                      customErrors={customErrorsScheduleStart}
                      customValidator={(_, __, ___, { value }) => {
                        setCustomErrorsScheduleStart([]);
                        if (!(value.scheduleStartingNumber || "").length) {
                          return;
                        }
                        if (value.scheduleStartingNumber.length > 5) {
                          setCustomErrorsScheduleStart([
                            "Must be 5 numbers or less in length",
                          ]);
                        } else {
                          if (!/^[0-9]+$/.test(value.scheduleStartingNumber)) {
                            setCustomErrorsScheduleStart([
                              "Must be only numbers",
                            ]);
                          }
                        }
                        return;
                      }}
                    />
                  </div>
                </div>
              </ContentPanel>
            </Form>
          );
        }}
      />
    </PermissionCheck>
  );
};

export default DocumentNumbering;
