import React, { useState } from "react";
import { ListBuilder, Table as CinTable } from "@redriver/cinnamon";
import { AccordionContentPanel } from "components/displays";
import { Table, Button } from "semantic-ui-react";
import {
  yesNoFormatter,
  blankDataFormatter,
  ExpandableText,
  SplitCellDisplay,
} from "components/displays/DisplayHelper";
import { AddProductSchedule } from ".";
import { SortableHeader } from "../../../../../../shared/components/tables/sortableTable";
import {
  InfoModalWrapper,
  InfoIconModalWrapper,
  AddModalWrapper,
} from "components/modals/";
const headers = [
  { title: "#", displayRemovedView: false, className: "tiny", sortable: false },
  {
    title: "Applicable Item ID's",
    colSpan: 3,
    displayRemovedView: true,
    width: "1",
    sortable: false,
  },
  {
    field: "model",
    title: "Manufacturer/Model and description",
    displayRemovedView: true,
  },
  { field: "type", title: "Type", displayRemovedView: true },
  { field: "identifier", title: "Identifier Number", displayRemovedView: true },
  {
    field: "status",
    title: "Status",
    displayRemovedView: true,
    className: "smallest",
  },
  {
    field: "postcode",
    title: "Location Postcode",
    displayRemovedView: true,
    className: "1",
  },
  {
    field: "consumablesExclusive",
    title: "Consumables Exclusive?",
    displayRemovedView: true,
    className: "smaller",
  },
  {
    field: "remoteServices",
    title: "Remote Services?",
    displayRemovedView: true,
    className: "smaller",
  },
  {
    title: "Meter Reading Code",
    displayRemovedView: true,
    className: "smaller",
    sortable: false,
  },
  {
    field: "cfaRef",
    title: "CFA Reference",
    displayRemovedView: true,
    width: "1",
  },
  {
    field: "ipsCode",
    title: "IPS Code",
    displayRemovedView: true,
    className: "small",
  },
  {
    field: "ipsInsurance",
    title: "IPS Insurance",
    displayRemovedView: true,
    className: "smaller",
  },
  {
    field: "vatCode",
    title: "Vat Code",
    displayRemovedView: true,
    className: "smallest",
  },
  {
    field: "rrpCash",
    title: `RRP (${process.env.MAJOR_CURRENCY})`,
    displayRemovedView: true,
    className: "smaller",
  },
  {
    field: "rrpPercent",
    title: "RRP (%)",
    displayRemovedView: true,
    className: "smaller",
  },
  {
    field: "meterCollectionFromQtr",
    title: "Meter Collection Commence From Quarter",
    displayRemovedView: true,
    width: "1",
  },
  {
    field: "",
    title: "M/R Collection Final Quarter Start Date",
    displayRemovedView: true,
    width: "1",
  },
  { title: "", displayRemovedView: true, sortable: false },
];

const ProductScheduleGrid = ({ title, loadAction, removedView = false }) => {
  const [displayProductNotes, setDisplayProductNotes] = useState(false);

  return (
    <AccordionContentPanel
      title={title}
      hideTitle={false}
      wrapperCssClass="outline-grid filtered-grid "
      headerCssClass="defaults-page-title"
      headerContent={
        <React.Fragment>
          <Button
            primary
            floated="right"
            content={
              (displayProductNotes ? "Hide " : "Show ") + "Product Notes"
            }
            onClick={() => setDisplayProductNotes(!displayProductNotes)}
          />
          {!removedView && (
            <React.Fragment>
              <InfoModalWrapper floated="right" title="Export">
                Not implemented.
              </InfoModalWrapper>
              <InfoModalWrapper floated="right" title="Change Product Sequence">
                Not implemented.
              </InfoModalWrapper>
            </React.Fragment>
          )}
        </React.Fragment>
      }
    >
      <div className="spaced">
        <ListBuilder
          loadAction={loadAction}
          dataTransform={(data) => {
            let newResults = [];
            data.results.forEach((x) => {
              x.showProductNotes = x.productNotes.length > 0;
              newResults.push(x);
            });
            return newResults;
          }}
          totalTransform={(data) => data.totalResults}
          renderList={(data, state, events) => (
            <div className="horizontal-scrollbar">
              {data && data.length > 0 && (
                <Table className="no-indent">
                  <SortableHeader
                    state={state}
                    events={events}
                    columns={headers.filter(
                      (h) => h.displayRemovedView || !removedView
                    )}
                    removedView={removedView}
                  />
                  <Table.Body>
                    {data.map((d, index) => (
                      <TableRows
                        d={d}
                        displayProductNotes={displayProductNotes}
                        removedView={removedView}
                        data={data}
                        key={index}
                      />
                    ))}
                  </Table.Body>
                </Table>
              )}
            </div>
          )}
        />
        {!removedView && (
          <div className="padded">
            <AddModalWrapper title="New Product">
              <AddProductSchedule></AddProductSchedule>
            </AddModalWrapper>
          </div>
        )}
      </div>
    </AccordionContentPanel>
  );
};

const TableRows = ({ d, displayProductNotes, removedView, data }) => (
  <React.Fragment>
    <TableRow
      data={d}
      displayProductNotes={displayProductNotes}
      removedView={removedView}
    />
    <Table.Row>
      <SplitCellDisplay items={data.applicableItems} startNode={3} count={3} />
    </Table.Row>
    {d.showProductNotes && displayProductNotes && (
      <ExpandTableRow uniqueKey={"3_" + d.id} data={d}></ExpandTableRow>
    )}
  </React.Fragment>
);

const TableRow = ({ data, displayProductNotes, removedView }) => (
  <Table.Row>
    <Table.Cell
      style={{ display: removedView ? "none" : "" }}
      rowSpan={data.showProductNotes && displayProductNotes ? 3 : 2}
    >
      {data.id}
    </Table.Cell>
    <SplitCellDisplay items={data.applicableItems} startNode={0} count={3} />
    <Table.Cell rowSpan="2">{data.model}</Table.Cell>
    <Table.Cell rowSpan="2">{data.type}</Table.Cell>
    <Table.Cell rowSpan="2">{data.identifier}</Table.Cell>
    <Table.Cell rowSpan="2">{data.status}</Table.Cell>
    <Table.Cell rowSpan="2">
      {data.postcode}
      <InfoIconModalWrapper title="Map View" icon="map marker alternate">
        Not implemented.
      </InfoIconModalWrapper>
    </Table.Cell>
    <Table.Cell rowSpan="2">
      {yesNoFormatter(data.consumablesExclusive)}
    </Table.Cell>
    <Table.Cell rowSpan="2">{yesNoFormatter(data.remoteServices)}</Table.Cell>
    <Table.Cell rowSpan="2" className="reduced-padding">
      {data.meterReadingCode.map((item) => (
        <span key={"green-highlight" + item.item} className="green">
          {item.item}
        </span>
      ))}
    </Table.Cell>
    <Table.Cell rowSpan="2">{data.cfaRef}</Table.Cell>
    <Table.Cell rowSpan="2">{blankDataFormatter(data.ipsCode, "-")}</Table.Cell>
    <Table.Cell rowSpan="2">{data.ipsInsurance}</Table.Cell>
    <Table.Cell rowSpan="2">{blankDataFormatter(data.vatCode, "-")}</Table.Cell>
    <Table.Cell rowSpan="2">{data.rrpCash}</Table.Cell>
    <Table.Cell rowSpan="2">{data.rrpPercent}</Table.Cell>
    <Table.Cell rowSpan="2">{data.meterCollectionFromQtr}</Table.Cell>
    <Table.Cell rowSpan="2">{data.meterCollectionFinalQtr}</Table.Cell>
    <Table.Cell rowSpan="2">
      {removedView ? <RemovedViewMenu></RemovedViewMenu> : <Menu></Menu>}
    </Table.Cell>
  </Table.Row>
);

const Menu = () => (
  <CinTable.Menu>
    <CinTable.MenuItem text="Remove Product" icon="delete" />
    <CinTable.MenuItem text="Add Notes" icon="file" />
    <CinTable.MenuItem text="Edit Product" icon="edit" />
    <CinTable.MenuItem text="Edit Location" icon="map marker alternate" />
  </CinTable.Menu>
);

const RemovedViewMenu = () => (
  <CinTable.Menu>
    <CinTable.MenuItem text="Reinstate Product" icon="check" />
    <CinTable.MenuItem text="Add Notes" icon="file" />
  </CinTable.Menu>
);

const ExpandTableRow = ({ data, uniqueKey }) => (
  <Table.Row key={uniqueKey}>
    <Table.Cell colSpan="3" className="highlight">
      Product Notes
    </Table.Cell>
    <Table.Cell colSpan="17" className="left-align">
      {" "}
      <ExpandableText text={data.productNotes} charLimit={500} />
    </Table.Cell>
  </Table.Row>
);

export default ProductScheduleGrid;
