import React, { useState } from "react";
import { ContentPanel } from "components/displays";
import {
  ListBuilder,
  Form,
  ActionButton,
  FormBuilder,
  Modal,
  ModalBuilder,
} from "@redriver/cinnamon";
import {
  createDealerStructure,
  getDealerHierarchy,
  deleteDealerStructure,
} from "./actions";
import { deleteDealerOffice } from "features/Dealers/DealerOffice";
import { Icon, Table, Button } from "semantic-ui-react";
import { Targets, Actions } from "constants/permissions";
import { PermissionCheck } from "components/auth";
import AddDealerOffice from "features/Dealers/DealerOffice/AddDealerOffice";
import Add from "features/../../../shared/assets/blue-add.svg";
import EditDealerOffice from "features/Dealers/DealerOffice/EditDealerOffice";
import EditDealerStructure from "./EditDealerStructure";
import AddDealerStructure from "./AddDealerStructure";

const DetailsStructure = ({ id }) => {
  const [structureData, setStructureData] = useState();
  const [tempStructureData, setTempStructureData] = useState([]);
  const [itemsToHide, setItemsToHide] = useState([]);
  const dealerId = id;

  return (
    dealerId && (
      <ContentPanel
        title="Company Structure"
        wrapperCssClass="outline-grid dealers-structure"
        headerCssClass="defaults-page-title"
      >
        <PermissionCheck
          target={Targets.Dealer}
          action={Actions.View}
          renderFailure={failure}
        >
          <ListBuilder
            loadAction={getDealerHierarchy}
            loadParams={dealerId}
            renderList={(data, state, events) => {
              setStructureData(data);
              return (
                structureData && (
                  <div className="padded">
                    <Table>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Level</Table.HeaderCell>
                          <Table.HeaderCell>Opal ID</Table.HeaderCell>
                          <Table.HeaderCell>CRM ID</Table.HeaderCell>
                          <Table.HeaderCell>Detail</Table.HeaderCell>
                          <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body className="left-align">
                        <Node
                          data={structureData}
                          structureData={structureData}
                          setStructureData={setStructureData}
                          tempStructureData={tempStructureData}
                          setTempStructureData={setTempStructureData}
                          itemsToHide={itemsToHide}
                          setItemsToHide={setItemsToHide}
                          dealerId={dealerId}
                          onRefresh={events.onReset}
                        />
                      </Table.Body>
                    </Table>
                  </div>
                )
              );
            }}
          />
        </PermissionCheck>
      </ContentPanel>
    )
  );
};

const failure = () => (
  <React.Fragment>
    <div className="padded">
      <p>You do not have permission to view this page.</p>
    </div>
  </React.Fragment>
);

//recursive function to generate nodes of the hierarchy (no limit on number of levels.)
const Node = ({
  data,
  structureData,
  setStructureData,
  tempStructureData,
  setTempStructureData,
  itemsToHide,
  setItemsToHide,
  dealerId,
  onRefresh,
}) => {
  //show/hide node and its children
  const toggleItem = (id) => {
    saveTempData();
    if (itemsToHide.indexOf(id) > -1) {
      let index = itemsToHide.indexOf(id);
      if (index !== -1) {
        const arrNew = [...itemsToHide];
        arrNew.splice(index, 1);
        setItemsToHide(arrNew);
      }
    } else {
      setItemsToHide(itemsToHide.concat(id));
    }
  };

  //update field changes in temp node for persistence when a new node is added
  const onChange = (field) => {
    let itemIndex = tempStructureData.findIndex(
      (x) =>
        x.parentId == field.parentId &&
        x.id == field.id &&
        x.dealerId == field.dealerId
    );
    if (itemIndex != -1) {
      tempStructureData[itemIndex] = field;
      setTempStructureData(tempStructureData);
    } else {
      tempStructureData.push(field);
      setTempStructureData(tempStructureData);
    }
  };

  //check for temp data and update the tree.
  const saveTempData = () => {
    if (tempStructureData != null) {
      for (let i = 0; i < tempStructureData.length; i++) {
        itemAdded(structureData, tempStructureData[i]);
      }
      setTempStructureData([]);
    }
    return true;
  };

  //when item is saved to the DB update the tree with returned result.
  const itemSubmitted = (structureData, item) => {
    saveTempData();
    itemAdded(structureData, item);
  };

  const officeItemAdded = (structureData, item) => {
    if (structureData.id == item.dealerStructureId) {
      item.isOffice = true;
      item.depth = structureData.depth + 1;
      //its a match generate a new node and push to the main data state
      setStructureData(structureData.children.splice(0, 0, item));
    } else if (structureData.children != null) {
      for (let i = 0; i < structureData.children.length; i++) {
        officeItemAdded(structureData.children[i], item, false);
      }
    }
  };

  const itemAdded = (structureData, item) => {
    //update id in tree when new node has been created
    if (
      !item.isOffice &&
      structureData.parentId === item.parentId &&
      structureData.id === ""
    ) {
      structureData.id = item.id;
      structureData.name = item.name;
      setStructureData(structureData);
    } else if (structureData.children != null) {
      for (let i = 0; i < structureData.children.length; i++) {
        itemAdded(structureData.children[i], item);
      }
    }
  };

  const removeItem = (structureData, office) => {
    if (structureData.id === office.id) {
      structureData.deleted = true;
      setStructureData(structureData);
    } else if (structureData.children != null) {
      for (let i = 0; i < structureData.children.length; i++) {
        removeItem(structureData.children[i], office);
      }
    }
  };

  let childNodes = null;

  // the Node component calls itself if there are children
  if (data && data.children) {
    childNodes = data.children.map(function (childNode) {
      return (
        <Node
          key={childNode.id}
          data={childNode}
          structureData={structureData}
          setStructureData={setStructureData}
          tempStructureData={tempStructureData}
          setTempStructureData={setTempStructureData}
          itemsToHide={itemsToHide}
          setItemsToHide={setItemsToHide}
          dealerId={dealerId}
          onRefresh={onRefresh}
        />
      );
    });
  }

  // return our list element
  // display children if there are any
  return (
    data &&
    !data.deleted && (
      <React.Fragment>
        <Table.Row key={data.id} columns={5}>
          <Table.Cell width={1} className="structure-level-width">
            {data.level}
          </Table.Cell>
          <Table.Cell width={1} className="structure-id-width">
            {data.opalId}
          </Table.Cell>
          <Table.Cell width={1} className="structure-id-width">
            {data.crmId}
          </Table.Cell>
          <Table.Cell className="inline-content">
            <div style={{ width: data.depth * 2 + "em" }}></div>
            <div style={{ width: "2em" }} onClick={() => toggleItem(data.id)}>
              {data.children && data.children.length > 0 && (
                <Icon
                  name="dropdown"
                  rotated={
                    !(itemsToHide.indexOf(data.id) > -1)
                      ? null
                      : "counterclockwise"
                  }
                />
              )}
            </div>
            {data.id && (
              <span onClick={() => toggleItem(data.id)}>
                {data.name}{" "}
                {data.children && data.children.length > 0
                  ? "(" + data.children.length + ")"
                  : ""}{" "}
              </span>
            )}

            {/* don't show add button when already adding a new item to node. */}
            <PermissionCheck target={Targets.Dealer} action={Actions.Create}>
              {data.id &&
                !data.isOffice &&
                !data.parentId &&
                (!data.children || !data.children.find((x) => x.id == "")) && (
                  <React.Fragment>
                    <AddDealerStructure
                      data={{
                        territoryId:
                          data.children?.length != 0
                            ? Math.max.apply(
                                Math,
                                data.children?.map((o) => o.opalIndividualId)
                              ) + 1
                            : 1,
                        legalEntityName: data.legalEntityName,
                        crmId:
                          data.children?.length != 0
                            ? Math.max.apply(
                                Math,
                                data.children?.map((o) => o.crmIndividualId)
                              ) + 1
                            : 1,
                        level: 2,
                      }}
                      parentId={data.id}
                      className="new-btn"
                      dealerId={dealerId}
                      onRefresh={onRefresh}
                      fullCrmCode={data.crmId}
                    />
                  </React.Fragment>
                )}
              {data.id &&
                data.parentId &&
                !data.isOffice &&
                (!data.children || !data.children.find((x) => x.id == "")) && (
                  <React.Fragment>
                    <AddDealerOffice
                      data={{
                        officeId:
                          data.children?.length != 0
                            ? Math.max.apply(
                                Math,
                                data.children?.map((o) => o.opalIndividualId)
                              ) + 1
                            : 1,
                        legalEntityName: data.legalEntityName,
                        territory: data.name,
                        crmId:
                          data.children?.length != 0
                            ? Math.max.apply(
                                Math,
                                data.children?.map((o) => o.crmIndividualId)
                              ) + 1
                            : 1,
                        level: 3,
                      }}
                      parentId={data.id}
                      dealerStructureId={data.id}
                      dealerId={dealerId}
                      depth={structureData.depth + 1}
                      structureData={structureData}
                      officeItemAdded={officeItemAdded}
                      icon={Add}
                      content="New Supplier Office"
                      className="new-btn"
                      fullCrmCode={data.crmId}
                      onRefresh={onRefresh}
                    />
                  </React.Fragment>
                )}
            </PermissionCheck>
            {!data.id && !data.isOffice && (
              <FormBuilder
                onChange={onChange}
                initialData={data}
                submitAction={createDealerStructure}
                onSubmitted={(res) => itemSubmitted(structureData, res)}
                renderForm={(formProps, state, formEvents) => (
                  <Form {...formProps}>
                    <div className="full-width">
                      <div className="float-left">
                        <Form.Input
                          field="name"
                          placeholder="Enter name here"
                          loading={state.submitting}
                          onChange={onChange}
                          required
                        />
                      </div>
                      <div className="float-left">
                        <PermissionCheck
                          target={Targets.Dealer}
                          action={Actions.Create}
                        >
                          <ActionButton
                            type="save"
                            onClick={() => formEvents.onSubmit()}
                            loading={state.submitting}
                          />
                        </PermissionCheck>
                      </div>
                    </div>
                  </Form>
                )}
              />
            )}
          </Table.Cell>
          <Table.Cell width={5}>
            {((data.parentId && data.id) || (data.isOffice && data.id)) &&
              (data.isOffice ? (
                <React.Fragment>
                  <EditDealerOffice
                    data={{
                      ...data,
                      officeId: data.opalIndividualId,
                      crmId: data.crmIndividualId,
                      fullCrmCode: data.crmId,
                    }}
                    onRefresh={onRefresh}
                  />
                  {"  "}
                  <DeleteItem
                    item={data}
                    removeItem={removeItem}
                    structureData={structureData}
                    header="Delete Office"
                    deleteAction={deleteDealerOffice}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <EditDealerStructure
                    data={{
                      id: data.id,
                      name: data.name,
                      territoryId: data.opalIndividualId,
                      legalEntityName: data.legalEntityName,
                      crmId: data.crmIndividualId,
                      fullCrmCode: data.crmId,
                    }}
                    onRefresh={onRefresh}
                  />
                  {"  "}
                  <DeleteItem
                    item={data}
                    removeItem={removeItem}
                    structureData={structureData}
                    header="Delete Entity"
                    deleteAction={deleteDealerStructure}
                  />
                </React.Fragment>
              ))}
          </Table.Cell>
        </Table.Row>
        {childNodes && itemsToHide.indexOf(data.id) === -1 ? childNodes : null}
      </React.Fragment>
    )
  );
};

const DeleteItem = ({
  item,
  removeItem,
  structureData,
  header,
  deleteAction,
  ...props
}) => {
  return (
    <ModalBuilder
      submitAction={deleteAction}
      submitParams={item.id}
      onSubmitted={() => removeItem(structureData, item)}
      renderTrigger={(showModal) => (
        <Button
          icon="delete"
          className="delete-icon-btn"
          color="red"
          onClick={showModal}
          {...props}
        />
      )}
      renderModal={(modalProps) => (
        <Modal.Delete {...modalProps} header={header}>
          Are you sure you wish to delete {item.name}?
        </Modal.Delete>
      )}
    />
  );
};

export default DetailsStructure;
