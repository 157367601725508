import React from "react";
import { IconButton } from "features/../../../shared/components/buttons";
import { Form } from "@redriver/cinnamon";
import {
  LargeFormatPrinterWidthsDropdownLookup,
  LargeFormatTargetMarketDropdownLookup,
} from "./lookup";
import PrinterUsageTypeTable from "./PrinterUsageTypeTable";
import Add from "features/../../../shared/assets/green-add.svg";
import { useTranslation } from "react-i18next";

const newUsageTypeItem = (key) => {
  return {
    [key]: Date.now(),
    inkUsageId: null,
    value: null,
  };
};

const PrinterModalForm = ({ formProps, dealerOfficeId }) => {
  const { t } = useTranslation('supplierOffices', { keyPrefix: 'largeFormat.printer.modalForm' });
  return (
    <Form {...formProps}>
      <div className="large-format-printer-modal">
        <div className="form-row">
          <Form.Input
            field="model"
            label={t("name")}
            className="input-small"
            required
            placeholder={t("namePlaceholder")}
          />
          <LargeFormatPrinterWidthsDropdownLookup
            dealerOfficeId={dealerOfficeId}
            label={t("rollWidth")}
            field="paperWidthId"
            placeholder={t("rollWidth")}
            className="printer-dropdown"
          />
        </div>
        <div className="form-row">
          <Form.Numeric
            field="rollsOnDevice"
            decimalPlaces={0}
            label={t("rollsOnDevice")}
            className="input-small"
            required
            placeholder={t("rollsOnDevicePlaceholder")}
          />
          <Form.Numeric
            field="colours"
            decimalPlaces={0}
            label={t("coloursInUnit")}
            className="input-small"
            required
            placeholder={t("coloursInUnitPlaceholder")}
          />
          <LargeFormatTargetMarketDropdownLookup
            dealerOfficeId={dealerOfficeId}
            className="printer-dropdown"
          />
        </div>
        <div className="form-row">
          <Form.Numeric
            field="costPerMl"
            label={t("costPerMl")}
            className="input-small"
            required
            placeholder={t("costPerMlPlaceholder")}
          />
          <Form.Numeric
            field="costPerScan"
            label={t("costPerScan")}
            className="input-small"
            required
            placeholder={t("costPerScanPlaceholder")}
          />
          <Form.Numeric
            field="costPerMlCleaning"
            label={t("costPerMlCleaning")}
            className="input-small"
            required
            placeholder={t("costPerMlCleaningPlaceholder")}
          />
        </div>
        <div className="form-row">
          <div className="extra-padding">
            <Form.RadioGroup
              field="cleaningMeter"
              inline
              className="radio-group"
              label={
                <div className="required-field radio-label">
                  <p>{t("cleaningMeterIncluded")}</p>
                </div>
              }
              options={[{ value: 1, text: t("yes") }, { value: 0, text: t("no") }]}
              required
            />
          </div>
          <Form.RadioGroup
            field="scanner"
            inline
            label={
              <div className="radio-label">
                <p>{t("scannerIncluded")}</p>
              </div>
            }
            className="radio-group"
            options={[{ value: 1, text: t("yes") }, { value: 0, text: t("no") }]}
            required
          />
        </div>

        <Form.ArrayAdder
          field="inkUsageTypes"
          arrayKey="id"
          as={IconButton}
          icon={Add}
          className="wizard-title-button large-format-add-button"
          content={t("addNewUsageType")}
          populate={(k) => newUsageTypeItem(k)}
        ></Form.ArrayAdder>
        <PrinterUsageTypeTable dealerOfficeId={dealerOfficeId} />
      </div>
    </Form>
  )
};

export default PrinterModalForm;
