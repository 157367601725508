import React, { useState } from "react";
import { FormBuilder, Loadable, ModalBuilder, Modal } from "@redriver/cinnamon";
import { IconButton } from "../../../../../../shared/components/buttons";
import Pen from "assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import Plus from "features/../../../shared/assets/white-add.svg";
import { FundersSummaryDetails } from "./FundersSummaryDetails";
import {
  getFunderSummaryDetails,
  getFunderContacts,
  UpdateFunderSummaryDetails,
} from "../actions";
import { ContentWrapper, ContentHeader } from "../Helper";
import { SimpleTableBuilder } from "../../../../../../shared/components/tables/sortableTable";
import { Button } from "semantic-ui-react";

const columns = [
  { field: "firstName", title: "First Name" },
  { field: "lastName", title: "Last Name" },
  { field: "status", title: "Status" },
  { field: "position", title: "Position" },
  { field: "relationship", title: "Relationship" },
  { field: "telephone", title: "Telephone" },
  { field: "email", title: "Email" },
  { field: "location", title: "Location" },
  { field: "timeZone", title: "Time Zone" },
];

const getFunderSummaryActionBarItems = (
  events,
  readOnly,
  disableFunderCode,
  setDisableFunderCode
) => {
  return [
    {
      key: 1,
      isFluid: true,
    },
    {
      key: 2,
      item: (
        <div className="flex">
          {!readOnly && (
            <Button
              primary
              className="cancel-title-action"
              floated="right"
              content="Cancel"
              onClick={events.onReset}
            />
          )}
          <ModalBuilder
            onSubmit={() => {
              setDisableFunderCode(true);
              events.onSubmit({ reset: ["readOnly"] });
            }}
            onCancel={() => {
              setDisableFunderCode(false);
            }}
            renderTrigger={(showModal) => (
              <IconButton
                primary
                icon={readOnly ? Pen : Save}
                content={readOnly ? "Edit" : "Save"}
                floated="right"
                onClick={() => {
                  readOnly
                    ? events.onToggleReadOnly()
                    : disableFunderCode
                    ? events.onSubmit({ reset: ["readOnly"] })
                    : showModal();
                }}
                className={!readOnly ? "positive-title-action" : null}
              />
            )}
            renderModal={(modalProps) => (
              <Modal.Confirmation
                {...modalProps}
                header="Funder Code Confirmation"
              >
                Are you sure you wish to save? The Funder Code cannot be edited
                after the initial Save.
              </Modal.Confirmation>
            )}
          />
        </div>
      ),
    },
  ];
};

const getFunderContactsActionBarItems = () => {
  return [
    {
      key: 1,
      isFluid: true,
    },
    {
      key: 2,
      item: (
        <IconButton
          key={2}
          primary
          icon={Plus}
          content="Add New Contact"
          floated="right"
          className={null}
        />
      ),
    },
  ];
};

const FundersSummary = ({ funderId, contentRefresh }) => {
  const [disableFunderCode, setDisableFunderCode] = useState(false);
  return (
    <React.Fragment>
      <FormBuilder
        initialReadOnly
        loadAction={getFunderSummaryDetails}
        loadParams={funderId}
        submitAction={UpdateFunderSummaryDetails}
        submitParams={funderId}
        onSubmitted={contentRefresh}
        dataTransform={(data) => data._data}
        onLoaded={(formData) =>
          formData.funderCode != null
            ? setDisableFunderCode(true)
            : setDisableFunderCode(false)
        }
        renderForm={(formProps, { readOnly, submitting, loading }, events) => (
          <div className="funders-summary">
            <Loadable loading={loading || submitting}>
              <ContentWrapper
                title="Summary"
                contentHeader={
                  <ContentHeader
                    items={getFunderSummaryActionBarItems(
                      events,
                      readOnly,
                      disableFunderCode,
                      setDisableFunderCode
                    )}
                  />
                }
              >
                <FundersSummaryDetails
                  formProps={formProps}
                  readOnly={readOnly}
                  disableFunderCode={disableFunderCode}
                />
              </ContentWrapper>
            </Loadable>
          </div>
        )}
      ></FormBuilder>
      <br></br>

      <div className="funders-summary">
        <ContentWrapper
          title="Contacts"
          contentHeader={
            <ContentHeader items={getFunderContactsActionBarItems()} />
          }
        >
          <SimpleTableBuilder
            loadAction={getFunderContacts}
            emptyMessage="No contacts found"
            columns={columns}
          />
        </ContentWrapper>
      </div>
    </React.Fragment>
  );
};

export default FundersSummary;
