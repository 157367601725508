import React from "react";
import { ModalBuilder, useAuthClaims } from "@redriver/cinnamon";
import { IconButton } from "features/../../../shared/components/buttons";
import { addRole } from "./actions";
import RoleForm from "./RoleForm";
import { TranslatedModal } from "../../../components/modals";

const AddRole = ({ as: As = IconButton, onAdded, ...props }) => {
  const { userArea } = useAuthClaims();
  return (
    <ModalBuilder
      withForm
      submitAction={addRole}
      onSubmitted={onAdded}
      renderTrigger={showModal => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Add
          {...modalProps}
          header="Add Template"
          submitLabel="Create Template"
        >
          <RoleForm formProps={formProps} userArea={userArea} />
        </TranslatedModal.Add>
      )}
    />
  );
};

export default AddRole;
