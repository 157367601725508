import { PageContent } from "@redriver/cinnamon";
import { DealerOffice } from "features/Dealers";
import React from "react";
import { AppRoutes } from "constants/routes";
import { RedirectBreadcrumbBar } from "pages/nav";

const DealerOfficePage = ({ match }) => (
  <PageContent fluid>
    <RedirectBreadcrumbBar to={AppRoutes.Dealers} text="Back to Suppliers" />
    <DealerOffice officeId={match.params.id} />
  </PageContent>
);

export default DealerOfficePage;
