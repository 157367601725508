import React, { useState } from "react";
import {
  ListBuilder,
  DateTimeFormatter,
  Table as CinTable,
} from "@redriver/cinnamon";
import { getNotes, NavMenu, FilterBar } from ".";
import { Table } from "semantic-ui-react";

import { ContentPanel } from "components/displays";
import { SortableHeader } from "../../../../../../shared/components/tables/sortableTable";

const Notes = () => {
  const [selectedNoteType, setSelectedNoteType] = useState(0);

  const headers = [
    { field: "dt", title: "Date & Time" },
    { field: "memberId", title: "Member" },
    { field: "type", title: "Note Type" },
    { field: "notes", title: "Notes" },
    { title: "Actions", sortable: false },
  ];

  return (
    <ContentPanel
      title="Notes"
      wrapperCssClass="outline-grid filtered-grid "
      headerCssClass="defaults-page-title"
    >
      <div className="spaced">
        <ListBuilder
          loadAction={getNotes}
          loadParams={{ noteType: selectedNoteType }}
          renderList={(data, state, events) => (
            <React.Fragment>
              <div className="content-panel filters slim">
                <div className="spaced">
                  <FilterBar
                    value={state.filters}
                    onChange={events.onChangeFilters}
                    users={users}
                    types={types}
                  />
                </div>
              </div>
              <NavMenu
                selectedNoteType={selectedNoteType}
                setSelectedNoteType={setSelectedNoteType}
              />
              <Table>
                <SortableHeader
                  columns={headers}
                  state={state}
                  events={events}
                />
                <Table.Body>
                  {data.map((d) => (
                    <TableRow
                      key={d.id}
                      data={d}
                      cssClass={types.find((e) => e.value == d.type).cssClass}
                    />
                  ))}
                </Table.Body>
              </Table>
            </React.Fragment>
          )}
        ></ListBuilder>
      </div>
    </ContentPanel>
  );
};

const TableRow = ({ data, cssClass }) => (
  <Table.Row>
    <Table.Cell style={{ width: "11%" }}>
      {" "}
      <DateTimeFormatter value={data.dt} />
    </Table.Cell>
    <Table.Cell style={{ width: "11%" }}>
      {users.find((e) => e.value === data.memberId).text}
    </Table.Cell>
    <Table.Cell style={{ width: "11%" }} className={cssClass}>
      {types.find((e) => e.value === data.type).text}
    </Table.Cell>
    <Table.Cell style={{ width: "62%" }} className={cssClass + " left-align"}>
      {data.notes}
    </Table.Cell>
    <Table.Cell style={{ width: "5%" }} className={cssClass}>
      {" "}
      <Menu></Menu>
    </Table.Cell>
  </Table.Row>
);

const Menu = () => (
  <CinTable.Menu>
    <CinTable.MenuItem text="Delete" icon="delete" />
    <CinTable.MenuItem text="Edit" icon="edit" />
    <CinTable.MenuItem text="Make Visible to Other Views" icon="eye" />
    <hr />
    <CinTable.MenuItem text="Make Historic" icon="folder" />
  </CinTable.Menu>
);

const users = [
  {
    value: 0,
    text: "Show all",
  },
  {
    value: 1,
    text: "Marc Cashman",
  },
  {
    value: 2,
    text: "Sally Booker",
  },
  {
    value: 3,
    text: "Richard Cashman",
  },
  {
    value: 4,
    text: "Suzy Bristow",
  },
];

const types = [
  {
    value: 0,
    text: "Show all",
    cssClass: "",
  },
  {
    value: 1,
    text: "IPS Admin",
    cssClass: "purple-highlight",
  },
  {
    value: 2,
    text: "Supplier Admin",
    cssClass: "green-highlight",
  },
  {
    value: 3,
    text: "Account Manager",
    cssClass: "blue-highlight",
  },
  {
    value: 4,
    text: "Funder",
    cssClass: "yellow-highlight",
  },
];

export default Notes;
