import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMER_BILLING";
const ActionTypes = {
  GetMiscInvoicing: `${NAMESPACE}/GET_MISC_INVOICING`,
  GetScheduleCreditNotes: `${NAMESPACE}/GET_SCHEDULE_CREDIT_NOTE`,
  GetMinimumBilling: `${NAMESPACE}/GET_MINIMUM_BILLING`,
  GetToBeBilled: `${NAMESPACE}/GET_TO_BE_BILLED`,
  GetInvoices: `${NAMESPACE}/GET_INVOICES`,
};

export const getMiscInvoicing = () => {
  return mockSuccess(ActionTypes.GetMiscInvoicing, {
    delay: 500,
    response: MockMiscInvoiceData,
  });
};

export const getScheduleCreditNotes = () => {
  return mockSuccess(ActionTypes.GetScheduleCreditNotes, {
    delay: 500,
    response: MockScheduleCreditNoteData,
  });
};

export const getMinimumBilling = () => {
  return mockSuccess(ActionTypes.GetMinimumBilling, {
    delay: 500,
    response: MockMinimumBillingData,
  });
};

export const getToBeBilled = () => {
  return mockSuccess(ActionTypes.GetToBeBilled, {
    delay: 500,
    response: MockToBeBilledData,
  });
};

export const getInvoices = () => {
  return mockSuccess(ActionTypes.GetInvoices, {
    delay: 500,
    response: MockInvoices,
  });
};

const MockMiscInvoiceData = {
  totalResults: 20,
  results: [
    {
      id: 1,
      customer: "ABC",
      customerIdentifier: 11,
      supplier: "Principal",
      supplierOffice: "South",
      spv: "IPS SPV 1",
      scheduleNumber: "N/A",
      taxDate: "12/31/2021",
      documentSubtype: "Miscellaneous Invoice",
      currency: "GBP",
      netValue: 500,
    },
    {
      id: 2,
      customer: "TTT",
      customerIdentifier: 75,
      supplier: "Principal",
      supplierOffice: "South",
      spv: "IPS SPV 1",
      scheduleNumber: "N/A",
      taxDate: "12/31/2021",
      documentSubtype: "Miscellaneous Credit",
      currency: "GBP",
      netValue: -200,
    },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
    { id: 12 },
    { id: 13 },
    { id: 14 },
    { id: 15 },
    { id: 16 },
    { id: 17 },
    { id: 18 },
    { id: 19 },
    { id: 20 },
  ],
};

const MockToBeBilledData = {
  totalResults: 20,
  results: [
    {
      id: 1,
      customer: "ABC",
      customerIdentifier: 11,
      scheduleNumber: "MA4999",
      supplier: "Principal",
      supplierOffice: "South",
      spv: "IPS SPV 1",
      scheduleStatus: "Pending TWV Reached",
      currentTmvRemaining: 4005,
      managedUsage: 560,
      taxDate: "12/31/2021",
      invoiceSubtype: "Quarterly Additional Units",
      invoicingPeriod: "Quarterly",
      monthInQtr: null,
      meterDueDate: "12/25/2021",
      currency: "GBP",
      netValue: 500,
    },
    {
      id: 2,
      customer: "TTT",
      customerIdentifier: 75,
      scheduleNumber: "MA5471",
      supplier: "Principal",
      supplierOffice: "South",
      spv: "IPS SPV 1",
      scheduleStatus: "Pending TWV Reached",
      currentTmvRemaining: 4005,
      managedUsage: 160,
      taxDate: "12/31/2021",
      invoiceSubtype: "Monthly Additional Units",
      invoicingPeriod: "Monthly",
      monthInQtr: 2,
      meterDueDate: "12/30/2021",
      currency: "GBP",
      netValue: 200,
    },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
    { id: 12 },
    { id: 13 },
    { id: 14 },
    { id: 15 },
    { id: 16 },
    { id: 17 },
    { id: 18 },
    { id: 19 },
    { id: 20 },
  ],
};

const MockScheduleCreditNoteData = {
  totalResults: 20,
  results: [
    {
      id: 1,
      customer: "ABC",
      customerIdentifier: 11,
      scheduleNumber: "MA4999",
      supplier: "Principal",
      supplierOffice: "South",
      spv: "IPS SPV 1",
      scheduleStatus: "Pending TMV Reached",
      taxDate: "12/31/2021",
      invoiceSubtype: "Credit Note Additional Units",
      invoicingPeriod: "Quarterly",
      monthInQtr: null,
      meterDueDate: "12/25/2021",
      currency: "GBP",
      netValue: -500,
    },
    {
      id: 2,
      customer: "TTT",
      customerIdentifier: 75,
      scheduleNumber: "MA5471",
      supplier: "Principal",
      supplierOffice: "South",
      spv: "IPS SPV 1",
      scheduleStatus: "Pending TMV Reached",
      taxDate: "12/31/2021",
      invoiceSubtype: "Credit Note Additional Units",
      invoicingPeriod: "Monthly",
      monthInQtr: 2,
      meterDueDate: "12/30/2021",
      currency: "GBP",
      netValue: -200,
    },
    {
      id: 3,
      customer: "QRE",
      customerIdentifier: 55,
      scheduleNumber: "MA1842",
      supplier: "Canon",
      supplierOffice: "London",
      spv: "IPS SPV 2",
      scheduleStatus: "Live",
      taxDate: "01/05/2022",
      invoiceSubtype: "Credit Note Minimum",
      invoicingPeriod: "Quarterly",
      monthInQtr: null,
      meterDueDate: "12/31/2021",
      currency: "GBP",
      netValue: -7544,
    },
    {
      id: 4,
      customer: "ATS",
      customerIdentifier: 744,
      scheduleNumber: "MA7411",
      supplier: "Canon",
      supplierOffice: "London",
      spv: "IPS SPV 2",
      scheduleStatus: "Live",
      taxDate: "01/05/2022",
      invoiceSubtype: "Credit Note Minimum",
      invoicingPeriod: "Quarterly",
      monthInQtr: null,
      meterDueDate: "01/12/2021",
      currency: "GBP",
      netValue: -5411,
    },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
    { id: 12 },
    { id: 13 },
    { id: 14 },
    { id: 15 },
    { id: 16 },
    { id: 17 },
    { id: 18 },
    { id: 19 },
    { id: 20 },
  ],
};

const MockMinimumBillingData = {
  totalResults: 20,
  results: [
    {
      id: 1,
      customer: "ABC Limited",
      customerIdentifier: 11,
      scheduleNumber: "MA4999",
      supplier: "Principal",
      supplierOffice: "South",
      spv: "IPS SPV 1",
      scheduleStatus: "Pending TMV Reached",
      currentTmvRemaining: 4005,
      managedUsage: 560,
      taxDate: "12/31/2021",
      invoiceSubtype: "Monthly Minimum",
      invoicingPeriod: "Monthly",
      monthInQtr: 2,
      minimumDueDate: "12/31/2021",
      currency: "GBP",
      netValue: 500,
    },
    {
      id: 2,
      customer: "BSD Ltd",
      customerIdentifier: 75,
      scheduleNumber: "MA5471",
      supplier: "Principal",
      supplierOffice: "South",
      spv: "IPS SPV 1",
      scheduleStatus: "Pending TMV Reached",
      currentTmvRemaining: 4005,
      managedUsage: 150,
      taxDate: "12/31/2021",
      invoiceSubtype: "Quarterly Minimum",
      invoicingPeriod: "Quarterly",
      monthInQtr: null,
      minimumDueDate: "01/05/2022",
      currency: "GBP",
      netValue: 200,
    },
    {
      id: 3,
      customer: "Pluto's Yard",
      customerIdentifier: 55,
      scheduleNumber: "MA4471",
      supplier: "Principal",
      supplierOffice: "South",
      spv: "IPS SPV 1",
      scheduleStatus: "Pending TMV Reached",
      currentTmvRemaining: 75554,
      managedUsage: 1544,
      taxDate: "12/31/2021",
      invoiceSubtype: "Quarterly Minimum",
      invoicingPeriod: "Quarterly",
      monthInQtr: null,
      minimumDueDate: "01/15/2022",
      currency: "GBP",
      netValue: 422,
    },
    {
      id: 4,
      customer: "JYC PLC",
      customerIdentifier: 55,
      scheduleNumber: "MA1842",
      supplier: "Canon",
      supplierOffice: "London",
      spv: "IPS SPV 2",
      scheduleStatus: "Live",
      currentTmvRemaining: 87554,
      managedUsage: 6520,
      taxDate: "01/05/2022",
      invoiceSubtype: "Quarterly Minimum",
      invoicingPeriod: "Quarterly",
      monthInQtr: null,
      minimumDueDate: "12/31/2021",
      currency: "GBP",
      netValue: 7544,
    },
    {
      id: 5,
      customer: "Harvard Year Team Ltd",
      customerIdentifier: 744,
      scheduleNumber: "MA7411",
      supplier: "Canon",
      supplierOffice: "London",
      spv: "IPS SPV 2",
      scheduleStatus: "Live",
      currentTmvRemaining: 7541117,
      managedUsage: 151441,
      taxDate: "01/05/2022",
      invoiceSubtype: "Quarterly Minimum",
      invoicingPeriod: "Quarterly",
      monthInQtr: null,
      minimumDueDate: "01/12/2021",
      currency: "GBP",
      netValue: 5411,
    },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
    { id: 12 },
    { id: 13 },
    { id: 14 },
    { id: 15 },
    { id: 16 },
    { id: 17 },
    { id: 18 },
    { id: 19 },
    { id: 20 },
  ],
};

const MockInvoices = {
  totalResults: 20,
  results: [
    {
      id: 1,
      invoiceNo: "0M3683",
      customer: "ABC Limited",
      invoiceDate: "04/14/2020",
      scheduleNumber: "PRI01-MA5896-SC2558",
      invoiceType: "Quarterly Minimum",
      supplier: "Principal",
      spv: "IPS SPV 1",
      periodFrom: "09/18/2019",
      periodTo: "12/17/2019",
      currency: "GBP",
      netValue: 3500,
      vat: 700,
      grossValue: 4200,
    },
    {
      id: 2,
      invoiceNo: "MI3170",
      customer: "BSD Ltd",
      invoiceDate: "12/11/2019",
      scheduleNumber: "PRI01-MA2145-SC457",
      invoiceType: "Misc Invoices",
      supplier: "Principal",
      supplierOffice: "South",
      spv: "IPS SPV 1",
      periodFrom: "03/18/2019",
      periodTo: "06/18/2019",
      currency: "GBP",
      netValue: 7000,
      vat: 1400,
      grossValue: 8400,
    },
    {
      id: 3,
      invoiceNo: "OM3001",
      customer: "Pluto's Yard",
      invoiceDate: "11/06/2019",
      scheduleNumber: "PRI01-MA6925-SC142",
      invoiceType: "Quarterly Minimum",
      supplier: "Principal",
      supplierOffice: "South",
      spv: "IPS SPV 1",
      periodFrom: "06/18/2019",
      periodTo: "09/17/2019",
      currency: "GBP",
      netValue: 3500,
      vat: 700,
      grossValue: 4200,
    },
    {
      id: 4,
      invoiceNo: "0E2415",
      customer: "JYC PLC",
      invoiceDate: "06/28/2019",
      scheduleNumber: "PRI01-MA2154-SC547",
      invoiceType: "Excess",
      supplier: "Principal",
      supplierOffice: "London",
      spv: "IPS SPV 1",
      periodFrom: "12/18/2018",
      periodTo: "03/18/2019",
      currency: "GBP",
      netValue: -7000,
      vat: -700,
      grossValue: -8400,
      className: "negative",
    },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
    { id: 12 },
    { id: 13 },
    { id: 14 },
    { id: 15 },
    { id: 16 },
    { id: 17 },
    { id: 18 },
    { id: 19 },
    { id: 20 },
  ],
};
