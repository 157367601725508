import React, { useState } from "react";
import { Form } from "@redriver/cinnamon";

const BoilerPlateForm = ({ onChangeUpload, editMode, ...props }) => {
  const [customErrorsBPC, setCustomErrorsBPC] = useState([]);
  const [customErrorsVN, setCustomErrorsVN] = useState([]);

  return (
    <Form {...props}>
      <Form.Input field="masterDescription" label="Description" required />
      <Form.Input
        field="boilerPlateCode"
        label="Boiler Plate"
        required
        showErrors={customErrorsBPC && customErrorsBPC.length}
        customErrors={customErrorsBPC}
        customValidator={(_, __, ___, { value }) => {
          setCustomErrorsBPC([]);
          if (!(value.boilerPlateCode || "").length) return;
          if (value.boilerPlateCode.length !== 3) {
            setCustomErrorsBPC(["Must be 3 letters in length"]);
          } else {
            if (!/^[a-zA-Z]+$/.test(value.boilerPlateCode)) {
              setCustomErrorsBPC(["Must be only letters"]);
            }
          }
          return;
        }}
      />
      <Form.Input
        field="variablesNumber"
        label="Variables"
        required
        showErrors={customErrorsVN && customErrorsVN.length}
        customErrors={customErrorsVN}
        customValidator={(_, __, ___, { value }) => {
          setCustomErrorsVN([]);
          if (!(value.variablesNumber || "").length) return;
          if (value.variablesNumber.length !== 3) {
            setCustomErrorsVN(["Must be 3 numbers in length"]);
          } else if (isNaN(value.variablesNumber)) {
            setCustomErrorsVN(["Variables Must Be Numerical"]);
          }
        }}
      />
      <Form.FileUpload
        field="templateUpload"
        label="Upload Template"
        fileExtensionTypes={["html", "htm"]}
        customValidator={(_, __, ___, { value }) => {
          !!value?.templateUpload && onChangeUpload(value?.templateUpload);
        }}
        required={!editMode}
      />
    </Form>
  );
};

export default BoilerPlateForm;
