import React from "react";
import ListTargetMarkets from "features/../../../shared/features/Settings/LargeFormat/TargetMarket/ListTargetMarkets";

const TargetMarketSettings = ({ dealerOfficeId }) => {
  return (
    <React.Fragment>
      <ListTargetMarkets dealerOfficeId={dealerOfficeId} />
    </React.Fragment>
  );
};

export default TargetMarketSettings;
