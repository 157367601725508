import React from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import SignatoryForm from "./SignatoryForm";
import { addFunderAuthorisedSignatory } from "./actions";
import { AddButton } from "features/../../../shared/components/buttons";

const CreateFunderSignatory = ({ onRefresh, funderId }) => {
  return (
    <ModalBuilder
      withForm
      submitAction={addFunderAuthorisedSignatory}
      submitParams={{ funderId: funderId }}
      onSubmitted={onRefresh}
      renderTrigger={(showModal) => (
        <AddButton
          primary
          content="Add New Funder Signatory"
          onClick={showModal}
        />
      )}
      renderModal={(modalProps, formProps) => (
        <Modal.Add
          {...modalProps}
          header="Create New Funder Signatory"
          headerClassName="yellow"
          submitLabel="Save"
          size="large"
          className="add-modal yellow"
        >
          <SignatoryForm {...formProps} />
        </Modal.Add>
      )}
    />
  );
};

export default CreateFunderSignatory;
