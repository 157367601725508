import React from "react";
import { getProductMeter } from "./actions";
import { Table, FormBuilder, Form } from "@redriver/cinnamon";
import { numericFormatter } from "components/displays/DisplayHelper";

const EditMeterReading = () => {
  return (
    <div className="meter-reading-form center">
      <FormBuilder
        loadAction={getProductMeter}
        renderForm={(formProps) => (
          <Form {...formProps}>
            <Form.ArrayTable
              field="items"
              arrayKey="id"
              propagateUpdates="always"
            >
              <Table.Column field="unit" title="UNIT" width="10%" />
              <Table.Column field="meterCode" title="Meter Code" width="18%" />
              <Table.Column
                render={(item) =>
                  numericFormatter(item.currentMeterReading, "-", 0)
                }
                title="Current Meter Reading"
                width="18%"
              />
              <Table.Column
                render={(item) =>
                  numericFormatter(item.uploadedMeterReading, "-", 0)
                }
                title="Uploaded Meter Reading"
                width="18%"
              />
              <Table.Column
                render={(item) =>
                  item.meterCode != "Total" ? (
                    <Form.Numeric formatValue field="editMeterReading" />
                  ) : (
                    numericFormatter(item.editMeterReading, "-", 0)
                  )
                }
                title="NEW Edit Meter Reading"
                width="18%"
              />
              <Table.Column
                render={(item) => numericFormatter(item.newMeterUsage, "-", 0)}
                title="Meter Usage"
                width="18%"
              />
            </Form.ArrayTable>
            <Form.TextArea className="flex" field="notes" label="Notes" />
          </Form>
        )}
      ></FormBuilder>
    </div>
  );
};

export default EditMeterReading;
