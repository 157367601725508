import { Accordion, Icon } from "semantic-ui-react";
import React from "react";

const AccordionHelper = ({ title, collapsed, toggleVisibility, children, className, hideTitle = false }) => (

        <Accordion className={className}>
            <Accordion.Title active={!collapsed} onClick={toggleVisibility}>
            <div>
                {! hideTitle &&

                <div onClick={toggleVisibility}>
                    <div className="title">
                    <h5>{title}</h5>
                    {toggleVisibility && (
                        <Icon
                        name="dropdown"
                        rotated={collapsed ? "counterclockwise" : null}
                        />
                    )}
                    </div>

                
                </div>
                }

                </div>
            </Accordion.Title>
            <Accordion.Content active={!collapsed}>
            {children}
            </Accordion.Content>
        </Accordion>
);

export default AccordionHelper; 

