import React, { useState } from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { getBoilerPlateTandCs, updateBoilerPlateTandCs } from "./actions";
import BoilerPlateForm from "./BoilerPlateForm";

const EditBoilerPlateModal = ({
  itemId,
  dealerId,
  onUpdated,
  reference,
  ...props
}) => {
  let apiParams = {
    dealerId: dealerId,
    itemId: itemId,
  };
  const [uploadDoc, setUploadDoc] = useState(null);

  const onChangeUpload = (file) => {
    let fileReader = new FileReader();
    fileReader.onload = () => {
      const res = fileReader.result;
      setUploadDoc(res);
    };
    fileReader.readAsDataURL(file);
  };
  return (
    <ModalBuilder
      withForm
      loadAction={getBoilerPlateTandCs}
      loadParams={apiParams}
      submitAction={updateBoilerPlateTandCs}
      submitParams={{ dealerId, itemId, uploadDoc }}
      onSubmitted={onUpdated}
      renderTrigger={(showModal) => (
        <div {...props} onClick={showModal} className="item">
          Edit
        </div>
      )}
      renderModal={(modalProps, formProps) => (
        <Modal.Edit
          {...modalProps}
          header={"Boiler Plate T&C's: " + reference}
          submitLabel="Update"
        >
          <BoilerPlateForm
            {...formProps}
            onChangeUpload={onChangeUpload}
            editMode={true}
          />
        </Modal.Edit>
      )}
    />
  );
};

export default EditBoilerPlateModal;
