import React from "react";
import { Table } from "semantic-ui-react";
import SortHeader from "./SortHeader";

export const SortableHeader = ({ columns, state, events }) => (
    <Table.Header>
      <Table.Row>
        {columns.map(
          c =>
            (c.display == null || c.display) && (
              <Table.HeaderCell
                key={c.key || c.title}
                colSpan={c.colSpan}
                width={c.width}
                className={c.className}
              >
               { c.sortable === undefined || c.sortable ? 
                <SortHeader
                  field={c.field}
                  title={c.title}
                  value={state.filters}
                  onChange={events.onChangeFilters}
                  />
                  :
                  c.title
              }
              </Table.HeaderCell>
            )
        )}
      </Table.Row>
    </Table.Header>
  );

  export default SortableHeader;