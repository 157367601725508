import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { FinanceRoutes } from "constants/routes";
import { ContentBuilder } from "@redriver/cinnamon";
import { CustomerPage, FunderPage, SupplierPage } from "./";

const FinanceRouter = () => (
  <ContentBuilder
    renderContent={() => (
      <Switch>
        <Route exact path={FinanceRoutes.Customer} component={CustomerPage} />
        <Route exact path={FinanceRoutes.Supplier} component={SupplierPage} />
        <Route exact path={FinanceRoutes.Funder} component={FunderPage} />
        <Route render={() => <Redirect to={FinanceRoutes.Customer} />} />
      </Switch>
    )}
  />
);

export default FinanceRouter;
