import React, { useState } from 'react';
import {Form, useFormField } from '@redriver/cinnamon';
import { InchDivision } from '../../constants/enums';

const InchesInput = ({
  field,
  label,
  placeholder,
  required,
  requiredError,
  showErrors
}) => {
  const [value] = useFormField(field),
    [customErrors, setCustomErrors] = useState([]);

  return (
    <Form.Input
      field={field}
      label={label}
      placeholder={placeholder}
      required={required}
      requiredError={requiredError}
      debounce={250}
      showErrors={showErrors || (customErrors && customErrors.length)}
      customErrors={customErrors}
      customValidator={() => {
        setCustomErrors([]);
        if (!(value || '').length) 
          return;

        function hasMultiple(test, search) {
          return test && search &&
            test.indexOf(search) != test.lastIndexOf(search);
        };
        
        const validChars = [`"`, `/`];
        for (let x = 0; x < validChars.length; x++)
          if (hasMultiple(value, validChars[x])) {
            setCustomErrors([
              'Double-prime (") and solidus (/) can appear no more than once each'
            ]);
            return;
          };

        let
          invalidCharMatches = /(?![0-9]|\"|\s|\/|^|$)/g.exec(value),
          wholeInchMatches = /^\d+("|\s|$)/.exec(value || '') || [],
          partInchMatches = /(\d{1,2})(\/|(\s+\/\s+))(\d{1,2})$/.exec(value || '') || [];

        if ((invalidCharMatches || '').length) {
          setCustomErrors([
            'Invalid characters'
          ]);
          return;
        }

        if ((!wholeInchMatches.length && !partInchMatches.length)
            || (partInchMatches.length === 1)) {
          setCustomErrors([
            'Invalid format'
          ]);
          return;
        };

        let denominatorMatches = /\/\d{1,2}/.exec(value);
        if (!(denominatorMatches || '').length)
          return;

        let denominator = parseInt(denominatorMatches[0].replace('/', '')),
          divisions = Object.values(InchDivision);
        
        if (isNaN(denominator) || !divisions.includes(denominator))
          setCustomErrors([
            `Invalid fraction of an inch. Denominator must be one 
            of ${divisions.filter(x => x).join(', ')}`
          ]);
      }}
    />
  );
};

export default InchesInput;
