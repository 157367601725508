import React from "react";
import { ContentPanel } from "components/displays";
import {
  TableDivider,
  numericFormatter,
} from "components/displays/DisplayHelper";
import { Table } from "semantic-ui-react";

const CFAPayment = ({ cfaPayment }) => (
  <ContentPanel
    title="CFA Payment"
    wrapperCssClass="outline-grid"
    headerCssClass="defaults-page-title"
  >
    <div className="padded cfa-payment">
      <CFAPaymentTable cfaPayment={cfaPayment} />
    </div>
  </ContentPanel>
);

const CFAPaymentTable = ({ cfaPayment }) => (
  <Table className="cfa-payment-table">
    <Table.Body>
      <Table.Row className="strong grey">
        <Table.Cell></Table.Cell>
        <Table.Cell>Value {process.env.MAJOR_CURRENCY}</Table.Cell>
      </Table.Row>
      <TableDivider />
      <Table.Row>
        <Table.Cell>Initial CFA Payment</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(cfaPayment?.initialCfaPayment)}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>Settled</Table.Cell>
        <Table.Cell> {numericFormatter(cfaPayment?.settled)}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Adjustment</Table.Cell>
        <Table.Cell> {numericFormatter(cfaPayment?.adjustment)}</Table.Cell>
      </Table.Row>
      <Table.Row className="strong grey">
        <Table.Cell>Current CFA Payment</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(cfaPayment?.currentCfaPayment)}
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export default CFAPayment;
