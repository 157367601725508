import { ContentBuilder } from "@redriver/cinnamon";
import React from "react";
import { Table, Grid, GridColumn, GridRow } from "semantic-ui-react";
import {
  getTermsAndConditions,
  getTAndCMeteredItems,
  getTAndCQuarterlyServices,
} from "./actions";
import { ContentPanel } from "components/displays";

import { SimpleTableBuilder } from "../../../../../../shared/components/tables/sortableTable";

const meteredServicesColumns = [
  { field: "itemId", title: "Item Id", width: "13%" },
  { field: "description", title: "Description", width: "30%" },
  { field: "ipsTandCRef", title: "IPS T&C Ref", width: "19%" },
  { field: "supplierTandCRef", title: "Supplier T&C Ref", width: "19%" },
  {
    title: "Link to Clause",
    width: "19%",
    render: (item) => <a href={void 0}>{item.linkToClause}</a>,
    sortable: false,
  },
];

const quarterlyServicesColumns = [
  { field: "description", title: "Description", width: "43%" },
  { field: "ipsTandCRef", title: "IPS T&C Ref", width: "19%" },
  { field: "supplierTandCRef", title: "Supplier T&C Ref", width: "19%" },
  {
    title: "Link to Clause",
    width: "19%",
    render: (item) => <a href={void 0}>{item.linkToClause}</a>,
    sortable: false,
  },
];

const TermsConditions = () => {
  return (
    <div className="spaced">
      <ContentPanel
        title="Terms & Conditions"
        wrapperCssClass="schedule-tandc-container outline-grid"
        headerCssClass="defaults-page-title"
      >
        <ContentBuilder
          loadAction={getTermsAndConditions}
          renderContent={(data) =>
            data._data && (
              <React.Fragment>
                <Grid doubling>
                  <GridRow>
                    <GridColumn width={6}>
                      <div className="spaced">
                        <ContentPanel
                          title=" Documents Details"
                          wrapperCssClass=" outline-grid"
                          headerCssClass="defaults-page-title"
                        >
                          <div className="spaced">
                            <Table className="details">
                              <Table.Body>
                                <Table.Row>
                                  <Table.HeaderCell style={{ width: "50%" }}>
                                    Master Document
                                  </Table.HeaderCell>
                                  <Table.Cell>
                                    <a href={void 0}>
                                      {data._data[0].details.masterDocument}
                                    </a>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.HeaderCell>
                                    {"Master T&C Ref"}
                                  </Table.HeaderCell>
                                  <Table.Cell>
                                    {data._data[0].details.masterTandCRef}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.HeaderCell>Master Id</Table.HeaderCell>
                                  <Table.Cell>
                                    {data._data[0].details.masterId}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.HeaderCell>
                                    Master Start Date
                                  </Table.HeaderCell>
                                  <Table.Cell>
                                    {data._data[0].details.masterStartDate}
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                            <br />
                            <br />
                            <Table className="details">
                              <Table.Body>
                                <Table.Row>
                                  <Table.HeaderCell style={{ width: "50%" }}>
                                    Schedule Document
                                  </Table.HeaderCell>
                                  <Table.Cell>
                                    <a href={void 0}>
                                      {data._data[0].details.scheduleDocument}
                                    </a>
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.HeaderCell>
                                    {"Schedule T&C Ref"}
                                  </Table.HeaderCell>
                                  <Table.Cell>
                                    {data._data[0].details.scheduleTandCRef}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.HeaderCell>
                                    Schedule Id
                                  </Table.HeaderCell>
                                  <Table.Cell>
                                    {data._data[0].details.scheduleId}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.HeaderCell>
                                    Schedule Start Date
                                  </Table.HeaderCell>
                                  <Table.Cell>
                                    {data._data[0].details.scheduleStartDate}
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </div>
                        </ContentPanel>
                      </div>
                    </GridColumn>
                    <GridColumn width={10}>
                      <div className="spaced">
                        <ContentPanel
                          title="Additional Terms"
                          wrapperCssClass=" outline-grid"
                          headerCssClass="defaults-page-title"
                        >
                          <div className="spaced">
                            <ContentPanel
                              title="Master Additional Terms"
                              wrapperCssClass=" outline-grid"
                              headerCssClass="defaults-page-title"
                            >
                              <div className="empty-container"></div>
                            </ContentPanel>
                            <br />
                            <ContentPanel
                              title="Schedule Additional Terms"
                              wrapperCssClass=" outline-grid"
                              headerCssClass="defaults-page-title"
                            >
                              <div className="empty-container"></div>
                            </ContentPanel>
                          </div>
                        </ContentPanel>
                      </div>
                    </GridColumn>
                  </GridRow>
                </Grid>

                <div className="spaced">
                  <ContentPanel
                    title="Schedule - Services T&C's"
                    wrapperCssClass=" outline-grid"
                    headerCssClass="defaults-page-title"
                  >
                    <div className="spaced">
                      <h4>Metered Item</h4>
                      <SimpleTableBuilder
                        emptyMessage="No items found"
                        columns={meteredServicesColumns}
                        loadAction={getTAndCMeteredItems}
                      />

                      <h4>Quarterly Services</h4>
                      <SimpleTableBuilder
                        emptyMessage="No services found"
                        columns={quarterlyServicesColumns}
                        loadAction={getTAndCQuarterlyServices}
                      />
                    </div>
                  </ContentPanel>
                </div>
              </React.Fragment>
            )
          }
        />
      </ContentPanel>
    </div>
  );
};

export default TermsConditions;
