import React from "react";
import { Form } from "@redriver/cinnamon";
import { TimezonesDropdownLookup } from "features/../../../shared/features/System/lookups";
import AddressForm from "./AddressForm";

const DealerOfficeForm = ({ className, fullCrmCode, ...formProps }) => {
  return (
    <div className={className}>
      <Form {...formProps}>
        <Form.Group>
          <Form.Input
            field="name"
            label="Name"
            fluid
            required
            placeholder="Name..."
            className="largest-form-field"
          />
          <Form.Input
            field="officeId"
            label="Office ID"
            fluid
            placeholder="Office ID..."
            className="small-form-field"
            readOnly
            renderReadOnly={(props) => {
              return (
                <React.Fragment>
                  {`${props.value}`.padStart(2, "0")}
                </React.Fragment>
              );
            }}
          />
          <Form.Input
            field="legalEntityName"
            label="Legal Entity Name"
            fluid
            placeholder="Legal Entity Name..."
            readOnly
            className="large-form-field"
          />
          <Form.Input
            field="territory"
            label="Territory"
            fluid
            readOnly
            className="medium-form-field"
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            field="countryStateId"
            label="Country/State Id"
            fluid
            placeholder="Country/State Id..."
            className="small-form-field"
          />
          <Form.Input
            field="crmId"
            label="CRM ID"
            fluid
            placeholder="CRM ID..."
            readOnly
            renderReadOnly={() => {
              return <React.Fragment>{fullCrmCode}</React.Fragment>;
            }}
          />
          <Form.Input
            field="contactPhone"
            label="Contact Phone"
            fluid
            placeholder="Contact Phone..."
            className="medium-form-field"
          />
          <Form.Email
            field="contactEmail"
            label="Contact Email"
            fluid
            placeholder="Contact Email..."
            className="large-form-field"
          />
          <TimezonesDropdownLookup
            field="timeZone"
            label="Timezone"
            fluid
            placeholder="Timezone..."
            required
            className="large-form-field"
          />
        </Form.Group>
        <AddressForm isDealerOfficeForm={false} />
      </Form>
    </div>
  );
};

export default DealerOfficeForm;
