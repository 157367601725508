export default {
  Summary: "summary",
  Charges: "charges",
  Item: "item",
  CFA: "cfa",
  Services: "services",
  Paper: "paper",
  Schedule: "schedule",
  LargeFormat: "large-format",
  MeterReadings: "meter-readings",
  LSU: "lsu",
  PriceIncrease: "price-increase",
  Invoices: "invoices",
  Attachments: "attachments",
  Notes: "notes",
  TermsAndConditions: "terms-and-conditions",
  Communication: "communication",
};
