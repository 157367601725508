import React from "react";
import { FormBuilder, ActionBar } from "@redriver/cinnamon";
import { ContentPanel } from "components/displays";

export const ContentWrapper = ({ title, children, contentHeader = null }) => (
  <ContentPanel
    title={title}
    wrapperCssClass="outline-grid funders-list yellow"
    headerCssClass="defaults-page-title funders-header yellow"
    headerContent={contentHeader}
  >
    <div className="padded">{children}</div>
  </ContentPanel>
);

export const ContentHeader = ({ items, headerItemsClassName }) => {
  let actionBarItems = [];
  let headerItems = items;

  for (let [key] of Object.entries(headerItems)) {
    const headerItem = headerItems[key];
    const itemName = headerItem.type;

    switch (itemName) {
      case "space": {
        actionBarItems.push(<ActionBar.Space key={key} width={2} />);
        break;
      }
      case "noItem": {
        actionBarItems.push(
          <ActionBar.Item key={key} fluid={headerItem.isFluid}></ActionBar.Item>
        );
        break;
      }
      default: {
        actionBarItems.push(
          <ActionBar.Item key={key} fluid={headerItem.isFluid}>
            {headerItem.item}
          </ActionBar.Item>
        );
        break;
      }
    }
  }

  return (
    <FormBuilder
      renderForm={() => (
        <ActionBar className={headerItemsClassName}>{actionBarItems}</ActionBar>
      )}
    />
  );
};
