import React from "react";
import { ListBuilder, FormBuilder, Form } from "@redriver/cinnamon";
import { ContentPanel } from "components/displays";
import { Table, Grid, GridColumn } from "semantic-ui-react";
import { getBankingItems } from "./actions";
import { AddModalWrapper } from "components/modals/";

import { SortHeader } from "../../../../../../shared/components/tables/sortableTable";

const CustomerBanking = () => {
  return (
    <ListBuilder
      loadAction={getBankingItems}
      renderList={(data, state, events) =>
        data.items && (
          <ContentPanel
            title="Banking"
            wrapperCssClass="outline-grid banking-list"
            headerCssClass="defaults-page-title"
            headerContent={
              <AddModalWrapper title="Add New Banking Details">
                Not Implemented
              </AddModalWrapper>
            }
          >
            <div className="padded">
              <FormBuilder
                value={data}
                renderForm={(formProps) => (
                  <Form {...formProps} disabled className="banking-form">
                    <Grid columns={16}>
                      <GridColumn
                        mobile={4}
                        tablet={4}
                        computer={3}
                        largeScreen={2}
                        widescreen={2}
                        className="right-align"
                      >
                        <b>Allow Late Payment Fees?</b>
                      </GridColumn>
                      <GridColumn
                        mobile={12}
                        tablet={12}
                        computer={2}
                        largeScreen={2}
                        widescreen={2}
                      >
                        <Form.Dropdown
                          field="allowLatePayment"
                          placeholder="Uploaded By"
                          options={boolDrp}
                        />
                      </GridColumn>
                      <GridColumn
                        mobile={4}
                        tablet={4}
                        largeScreen={2}
                        computer={2}
                        widescreen={1}
                        className="right-align"
                      >
                        <b>Interest %</b>
                      </GridColumn>
                      <GridColumn
                        mobile={12}
                        tablet={12}
                        columns={14}
                        computer={2}
                        largeScreen={2}
                        widescreen={2}
                      >
                        <Form.Input field="interest" />
                      </GridColumn>
                    </Grid>
                  </Form>
                )}
              />
              <br />
              <div className="horizontal-scrollbar">
                <Table className="banking-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className="sticky-col">
                        <SortHeader
                          field="acctId"
                          title="Account ID"
                          value={state.filters}
                          onChange={events.onChangeFilters}
                        />
                      </Table.HeaderCell>
                      {data.items.map((d) => (
                        <Table.HeaderCell key={d.id} className="fixed-col">
                          {d.acctId}
                        </Table.HeaderCell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="status"
                      title="Status"
                    ></TableRow>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="endDt"
                      title="End Date"
                    ></TableRow>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="acctName"
                      title="Account Name"
                    ></TableRow>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="acctNumber"
                      title="Account Number"
                    ></TableRow>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="acctSort"
                      title="Sort Code"
                    ></TableRow>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="iban"
                      title="IBAN Number"
                    ></TableRow>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="bic"
                      title="BIC Number"
                    ></TableRow>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="roll"
                      title="Roll Number"
                    ></TableRow>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="countryCode"
                      title="Bank Country Code"
                    ></TableRow>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="currencyAcct"
                      title="Currency Account"
                    ></TableRow>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="currencyInvoice"
                      title="Currency Invoice"
                    ></TableRow>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="currencyPayment"
                      title="Currency Payment"
                    ></TableRow>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="paymentMethod"
                      title="Payment Method"
                    ></TableRow>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="paymentTerms"
                      title="Payment Terms"
                    ></TableRow>
                    <TableRow
                      data={data}
                      state={state}
                      events={events}
                      column="ddCode"
                      title="DD Code"
                    ></TableRow>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </ContentPanel>
        )
      }
    />
  );
};

const boolDrp = [
  {
    value: false,
    text: "No",
  },
  {
    value: true,
    text: "Yes",
  },
];

const TableRow = ({ title, column, data, state, events }) => {
  return (
    <Table.Row>
      <Table.HeaderCell className="sticky-col">
        <SortHeader
          field={column}
          title={title}
          value={state.filters}
          onChange={events.onChangeFilters}
        />
      </Table.HeaderCell>
      {data.items.map((d) => (
        <Table.Cell key={d.id}>{d[column]}</Table.Cell>
      ))}
    </Table.Row>
  );
};

export default CustomerBanking;
