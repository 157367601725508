import { MockArrayData, mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULES/INVOICES";

const ActionTypes = {
  GetScheduleInvoices: `${NAMESPACE}/GET_SCHEDULE_INVOICES`,
};

const mockInvoices = new MockArrayData([
  {
    id: 1,
    invoiceNumber: "QM3683",
    date: "14 Apr 2020",
    type: "Quarterly Minimum",
    periodFrom: "18 Sep 2019",
    periodTo: "17 Dec 2019",
    net: 3500,
    tax: 700,
    gross: 4200,
    paymentStatus: "Due",
    daysOverdue: 0,
    latePaymentCharge: 0,
    created: "14 Apr 2020",
  },
  {
    id: 2,
    invoiceNumber: "MI3170",
    date: "11 Dec 2019",
    type: "Misc Invoices",
    periodFrom: "18 Mar 2019",
    periodTo: "18 Jun 2019",
    net: 7000,
    tax: 1400,
    gross: 8400,
    paymentStatus: "Overdue",
    daysOverdue: 75,
    latePaymentCharge: 3.75,
    created: "11 Dec 2019",
  },
  {
    id: 3,
    invoiceNumber: "QM3001",
    date: "06 Nov 2019",
    type: "Quarterly Minimum",
    periodFrom: "18 Jun 2019",
    periodTo: "17 Sep 2019",
    net: 3500,
    tax: 700,
    gross: 4200,
    paymentStatus: "Paid",
    daysOverdue: 0,
    latePaymentCharge: 0,
    created: "06 Nov 2019",
  },
  {
    id: 4,
    invoiceNumber: "QE2415",
    date: "28 Jun 2019",
    type: "Additional Unit",
    periodFrom: "18 Dec 2018",
    periodTo: "18 Jun 2019",
    net: 2000,
    tax: 400,
    gross: 2400.0,
    paymentStatus: "Paid",
    daysOverdue: 0,
    latePaymentCharge: 0,
    created: "28 Jun 2019",
  },
  {
    id: 5,
    invoiceNumber: "EC100",
    date: "28 Jun 2019",
    type: "Additional Units Credit Note",
    periodFrom: "18 Jun 2018",
    periodTo: "18 Mar 2019",
    net: 7000,
    tax: 1400,
    gross: 8400,
    paymentStatus: "Allocated",
    daysOverdue: 0,
    latePaymentCharge: 0,
    created: "28 Jun 2019",
    isCreditNote: true,
  },
  {
    id: 6,
    invoiceNumber: "QE2413",
    date: "28 Jun 2019",
    type: "Additional Units",
    periodFrom: "18 Dec 2018",
    periodTo: "18 Mar 2019",
    net: 7000,
    tax: 1400,
    gross: 8400,
    paymentStatus: "Allocated",
    daysOverdue: 0,
    latePaymentCharge: 0,
    created: "28 Jun 2019",
  },
  {
    id: 7,
    invoiceNumber: "QM1916",
    date: "13 Mar 2019",
    type: "Quarterly Minimum",
    periodFrom: "18 Mar 2019",
    periodTo: "17 Jun 2019",
    net: 6500,
    tax: 1300,
    gross: 7800,
    paymentStatus: "Paid",
    daysOverdue: 0,
    latePaymentCharge: 0,
    created: "13 Mar 2019",
  },
  {
    id: 8,
    invoiceNumber: "QM1585",
    date: "20 Dec 2018",
    type: "Quarterly Minimum",
    periodFrom: "18 Dec 2018",
    periodTo: "17 Mar 2019",
    net: 6500,
    tax: 1300,
    gross: 7800,
    paymentStatus: "Paid",
    daysOverdue: 0,
    latePaymentCharge: 0,
    created: "20 Dec 2018",
  },
]);

export const getScheduleInvoices = () => {
  return mockSuccess(ActionTypes.GetScheduleInvoices, {
    delay: 200,
    response: mockInvoices._data,
  });
};
