import React from "react";
import ListPrinter from "features/../../../shared/features/Settings/LargeFormat/Printer/PrinterList";

const LargeFormatPrinterSettings = ({ dealerOfficeId }) => {
  return (
    <React.Fragment>
      <ListPrinter dealerOfficeId={dealerOfficeId} />
    </React.Fragment>
  );
};

export default LargeFormatPrinterSettings;
