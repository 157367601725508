import { AppRoutes, FunderRoutes } from "constants/routes";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import FundersListPage from "./FundersListPage";
import FunderDetailsPage from "./FunderDetailsPage";

const FundersRouter = () => (
  <Switch>
    <Route exact path={AppRoutes.Funders} component={FundersListPage} />
    <Route exact path={FunderRoutes.Funder()} component={FunderDetailsPage} />
    <Route render={() => <Redirect to={AppRoutes.Funders} />} />
  </Switch>
);

export default FundersRouter;
