import React from "react";
import { ContentPanel } from "components/displays";

const MissingReads = () => {
return(
        <ContentPanel 
            title="Missing Reads"   
            wrapperCssClass="outline-grid" 
            headerCssClass="defaults-page-title"
        >
            <div className="padded">
                Content to go here
                </div>
        </ContentPanel>
)};
export default MissingReads;