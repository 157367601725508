import React, { useState } from "react";
import { ContentPanel } from "components/displays";
import SPVSteps from "constants/enums/SPVSteps";
import { ContentBuilder, PageContent, Wizard, Form } from "@redriver/cinnamon";
import { Grid, GridColumn, GridRow, Menu } from "semantic-ui-react";
import { getSPVHeader } from "./actions";
import SPVSummary from "./SPVSummary";
import { SpvStatusDropdownLookup } from "./lookups";
import SPVBillingInformation from "./SPVBillingInformation";
import SPVBankAccount from "./SPVBankAccount";

const SPVDetails = ({ id }) => {
  const [headerDetailsTimeStamp, setHeaderDetailsTimeStamp] = useState(
    Date.now()
  );

  const NavigationControls = ({ steps, activeStep, onGoToStep }) => {
    return steps.map((s) => (
      <Menu.Item
        value={0}
        key={s.key}
        active={s === activeStep}
        onClick={() => onGoToStep(s.key)}
      >
        {s.title}
      </Menu.Item>
    ));
  };

  const stepData = [
    {
      key: SPVSteps.Summary,
      title: SPVSteps.Summary,
    },
    {
      key: SPVSteps.SPVBankAccount,
      title: SPVSteps.SPVBankAccount,
    },
    {
      key: SPVSteps.Calendar,
      title: SPVSteps.Calendar,
    },
  ];

  return (
    <PageContent fluid className="spv-details">
      <ContentBuilder
        loadAction={getSPVHeader}
        loadParams={{ id, headerDetailsTimeStamp }}
        dataTransform={(data) => data._data}
        renderContent={(data) => (
          <React.Fragment>
            <div>
              <label className="header-text">SPV: {data.name}</label>
            </div>
            <ContentPanel
              title="SPV Details"
              wrapperCssClass="outline-grid violet"
              headerCssClass="defaults-page-title violet"
            >
              <div className="padded">
                <DetailsForm data={data}></DetailsForm>
              </div>
            </ContentPanel>
          </React.Fragment>
        )}
      ></ContentBuilder>
      <div className="clear"></div>
      <Wizard steps={stepData}>
        <Wizard.Nav
          render={(wizardContext) => (
            <div className="spv-tabs">
              <Menu>
                <NavigationControls {...wizardContext} />
              </Menu>
            </div>
          )}
        />
        <Wizard.Step
          step={SPVSteps.Summary}
          render={() => (
            <div>
              <SPVSummary
                spvId={id}
                onSubmitted={() => {
                  setHeaderDetailsTimeStamp(Date.now());
                }}
              />
              <SPVBillingInformation spvId={id} />
            </div>
          )}
        />
        <Wizard.Step
          step={SPVSteps.SPVBankAccount}
          render={() => (
            <div>
              <SPVBankAccount spvId={id} />
            </div>
          )}
        />
        <Wizard.Step
          step={SPVSteps.Calendar}
          render={() => <div>Calendar</div>}
        />
      </Wizard>
    </PageContent>
  );
};

const DetailsForm = ({ data }) => {
  return (
    <Form value={data} disabled className="spv-details">
      <Grid columns={4}>
        <GridColumn
          mobile={15}
          tablet={15}
          computer={5}
          largeScreen={5}
          widescreen={5}
        >
          <Grid>
            <GridRow>
              <GridColumn
                mobile={7}
                tablet={6}
                computer={7}
                largeScreen={5}
                widescreen={3}
              >
                SPV Name:
              </GridColumn>
              <GridColumn
                mobile={9}
                tablet={10}
                computer={9}
                largeScreen={11}
                widescreen={13}
              >
                {" "}
                <Form.Input field="name" />
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
        <GridColumn
          mobile={16}
          tablet={16}
          computer={3}
          largeScreen={3}
          widescreen={3}
        >
          <Grid>
            <GridRow>
              <GridColumn
                mobile={7}
                tablet={6}
                computer={6}
                largeScreen={7}
                widescreen={5}
              >
                SPV Code:
              </GridColumn>
              <GridColumn
                mobile={5}
                tablet={3}
                computer={8}
                largeScreen={6}
                widescreen={5}
              >
                {" "}
                <Form.Input field="spvId" />
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
        <GridColumn
          mobile={16}
          tablet={16}
          computer={4}
          largeScreen={4}
          widescreen={4}
        >
          <Grid>
            <GridRow>
              <GridColumn
                mobile={5}
                tablet={4}
                computer={5}
                largeScreen={6}
                widescreen={5}
              >
                Jurisdiction:
              </GridColumn>
              <GridColumn
                mobile={7}
                tablet={5}
                computer={8}
                largeScreen={8}
                widescreen={7}
              >
                {" "}
                <Form.Input field="jurisdiction" />
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
        <GridColumn
          mobile={16}
          tablet={16}
          computer={4}
          largeScreen={4}
          widescreen={4}
        >
          <Grid>
            <GridRow>
              <GridColumn
                mobile={7}
                tablet={6}
                computer={7}
                largeScreen={9}
                widescreen={8}
              >
                SPV Status:
              </GridColumn>
              <GridColumn
                mobile={5}
                tablet={3}
                computer={9}
                largeScreen={7}
                widescreen={7}
              >
                <SpvStatusDropdownLookup field="status" />
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
      </Grid>
    </Form>
  );
};

export default SPVDetails;
