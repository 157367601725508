import { ContentPanel } from "components/displays";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  ListBuilder,
  FormBuilder,
  ActionBar,
  usePreferences,
} from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { FilterSidePanel, CrmNewCustomerLinkModal } from ".";
import { getClients } from "features/../../../shared/features/Clients/actions";
import { TextSearch } from "features/../../../shared/components/search";
import { CustomerRoutes } from "constants/routes";
import {
  TableConfigurator,
  ConfigurableTable,
} from "features/CustomConfig/TableConfig";

const Customers = () => {
  const { preferences } = usePreferences();
  const [config, setConfig] = useState(preferences.customerTableConfig);
  const [pageSizeConfig, setPageSizeConfig] = useState(
    preferences.customerTableConfigPageSize
      ? preferences.customerTableConfigPageSize
      : 25
  );

  useEffect(() => {
    setConfig(preferences.customerTableConfig);
  }, [preferences.customerTableConfig]);

  useEffect(() => {
    preferences.customerTableConfigPageSize &&
      setPageSizeConfig(preferences.customerTableConfigPageSize);
  }, [preferences.customerTableConfigPageSize]);

  const columns = [
    {
      field: "name",
      title: "Customer Name",
      width: "15%",
      render: (item) => (
        <Link to={CustomerRoutes.Details(item.id)}>{item.name}</Link>
      ),
    },
    { field: "crmId", title: "CRM ID", width: "7%", sortable: false },
    {
      field: "customerAddress",
      title: "Customer Address",
      width: "20%",
      sortable: false,
    },
    {
      field: "phoneNumber",
      title: "Customer Telephone",
      width: "10%",
      sortable: false,
    },
    { field: "opalId", title: "Opal ID", width: "10%", sortable: false },
    {
      field: "numSheets",
      title: "Number of Schedules",
      width: "7%",
      sortable: false,
    },
    { field: "dealer", title: "Supplier", width: "19%" },
    {
      field: "customerStatus",
      title: "Customer Status",
      width: "7%",
      sortable: false,
    },
  ];

  return (
    <ListBuilder
      withTable
      initialPagination={{ pageSize: pageSizeConfig, pageNumber: 1 }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      loadParams={{ pageSize: pageSizeConfig }}
      loadAction={getClients}
      renderList={(tableProps, state, events) => (
        <ContentPanel
          title="Customers"
          wrapperCssClass="outline-grid customers-list"
          headerCssClass="defaults-page-title"
          headerContent={
            <FilterBar
              state={state}
              events={events}
              config={config}
              columns={columns}
              initialPageSize={25}
              pageSizeConfig={pageSizeConfig}
            ></FilterBar>
          }
        >
          <div className="spaced">
            <React.Fragment>
              <ConfigurableTable
                state={state}
                events={events}
                tableProps={tableProps}
                emptyMessage="No customers found"
                columns={columns}
                config={config}
              />

              <div className="padded">
                <TablePagination
                  pageNumber={state.pagination.pageNumber}
                  onChange={events.onChangePagination}
                  pageSize={pageSizeConfig}
                  totalItems={state.total}
                />
              </div>
            </React.Fragment>
          </div>
        </ContentPanel>
      )}
    />
  );
};

const FilterBar = ({
  state,
  events,
  config,
  columns,
  initialPageSize,
  pageSizeConfig,
}) => (
  <FormBuilder
    renderForm={(formProps) => (
      <ActionBar className="filter-display">
        <ActionBar.Item>
          <TextSearch
            filters={state.filters}
            onChange={events.onChangeFilters}
          ></TextSearch>
        </ActionBar.Item>
        <ActionBar.Item fluid></ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <CrmNewCustomerLinkModal />
        </ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <TableConfigurator
            config={config}
            columns={columns}
            configId="customerTableConfig"
            titlePreFix="Customers"
            initialPageSize={initialPageSize}
            pageSizeConfig={pageSizeConfig}
          ></TableConfigurator>
        </ActionBar.Item>
        <ActionBar.Space />
        <ActionBar.Item>
          <FilterSidePanel formProps={formProps} />
        </ActionBar.Item>
      </ActionBar>
    )}
  />
);

export default Customers;
