import React from "react";
import { registerLookup, apiGet, Form } from "@redriver/cinnamon";

const NAMESPACE = "SPVS/LOOKUPS";

const ActionTypes = {
  GetSPVs: `${NAMESPACE}/GET_SPVS`,
};

const LookupTypes = {
  GetSPVs: "spvsLookup",
};



registerLookup(LookupTypes.GetSPVs, () =>
  apiGet(ActionTypes.GetSPVs, `lookups/spvs`),
);

export const SPVLookupDropdown = props => {  
  return (
    <Form.Dropdown
      {...props}
      search
      clearable
      lookup={LookupTypes.GetSPVs}      
    />
  );
};