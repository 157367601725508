import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { SystemRoutes } from "constants/routes";
import { requestPasswordForgotten } from "./actions";

const PasswordForgotten = () => {
  const [requestSent, setRequestSent] = useState(false);
  const [requestEmail, setRequestEmail] = useState("");

  if (requestSent) {
    return (
      <React.Fragment>
        <p>
          A password reset email has been sent
          {requestEmail ? ` to ${requestEmail}` : ""}
        </p>
        <Button as={Link} to={SystemRoutes.Login} className="action-button">
          Continue
        </Button>
      </React.Fragment>
    );
  }

  return (
    <FormBuilder
      submitAction={requestPasswordForgotten}
      onSubmit={({ email }) => setRequestEmail(email)}
      onSubmitted={() => setRequestSent(true)}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { submitting, slowSubmitting, error },
        events
      ) => (
        <Form {...formProps}>
          <Form.Email
            field="email"
            label="Email"
            placeholder="Enter email address"
            disabled={submitting && slowSubmitting}
            autoFocus
            required
          />
          <Button
            onClick={events.onSubmit}
            disabled={submitting}
            loading={submitting && slowSubmitting}
            className="action-button"
          >
            Continue
          </Button>
          <ErrorMessage error={error} />
        </Form>
      )}
    />
  );
};

export default PasswordForgotten;
