import React from "react";
import { Grid } from "semantic-ui-react";

const SummaryTableRows = (
  value1,
  value2,
  value3,
  value4,
  col2ClassName,
  col1ClassName
) => (
  <div className="schedule-table-row">
    <Grid.Column className={"sum-col sum-col-1 " + col1ClassName}>
      {value1}
    </Grid.Column>
    <Grid.Column
      className={"sum-col sum-col-2 " + col2ClassName + " " + col1ClassName}
    >
      {value2}
    </Grid.Column>
    <Grid.Column className="sum-col sum-col-3"></Grid.Column>
    <Grid.Column className={"sum-col sum-col-4 " + col1ClassName}>
      {value3}
    </Grid.Column>
    <Grid.Column className={"sum-col sum-col-5 " + col1ClassName}>
      {value4}
    </Grid.Column>
  </div>
);

export default SummaryTableRows;
