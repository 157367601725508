import React from "react";
import { ContentBuilder } from "@redriver/cinnamon";
import { ContentPanel } from "components/displays";
import { getProductDetails } from "./actions";
import { Table } from "semantic-ui-react";

const MeterReadingTable = ({ productId }) => {
  return (
    <ContentBuilder
      loadAction={getProductDetails}
      loadParams={productId}
      loadTransform={(data) => data.meterReadings}
      renderContent={(meterReadings) => (
        <ContentPanel
          title="Current Meter Reading"
          wrapperCssClass=" "
          headerCssClass="defaults-page-title"
        >
          <div className="spaced">
            <Table dataKey="meterReadings">
              <Table.Row>
                <Table.Cell>Black Total</Table.Cell>
                <Table.Cell>{meterReadings.blackTotal}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Colour Total</Table.Cell>
                <Table.Cell>{meterReadings.colourTotal}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Scan Total</Table.Cell>
                <Table.Cell>{meterReadings.scanTotal}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Total</Table.Cell>
                <Table.Cell>{meterReadings.total}</Table.Cell>
              </Table.Row>
            </Table>
          </div>
        </ContentPanel>
      )}
    />
  );
};

export default MeterReadingTable;
