import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "COMPANIES/BANKING";

const ActionTypes = {
  getBankingItems: `${NAMESPACE}/GET_BANKING`,
};

const banking = {
  allowLatePayment: true,
  interest: 8.02,
  items: [
    {
      id: 1,
      acctId: "BBB 123",
      status: "Live",
      endDt: null,
      acctName: "A Company Limited",
      acctNumber: 12345678,
      acctSort: "12-34-56",
      iban: null,
      bic: null,
      roll: null,
      countryCode: null,
      currencyAcct: process.env.CURRENCY_CODE,
      currencyInvoice: "Any",
      currencyPayment: "Any",
      paymentMethod: "Direct Debit",
      paymentTerms: "Immediate",
      ddCode: 17,
    },
    {
      id: 2,
      acctId: "BBB 524",
      status: "Live",
      endDt: null,
      acctName: "A Company Limited",
      acctNumber: 12345678,
      acctSort: "12-34-56",
      iban: null,
      bic: null,
      roll: null,
      countryCode: null,
      currencyAcct: process.env.CURRENCY_CODE,
      currencyInvoice: "Any",
      currencyPayment: "Any",
      paymentMethod: "Direct Debit",
      paymentTerms: "Immediate",
      ddCode: 17,
    },
    {
      id: 3,
      acctId: "BBB 123",
      status: "Live",
      endDt: null,
      acctName: "A Company Limited",
      acctNumber: 12345678,
      acctSort: "12-34-56",
      iban: null,
      bic: null,
      roll: null,
      countryCode: null,
      currencyAcct: process.env.CURRENCY_CODE,
      currencyInvoice: "Any",
      currencyPayment: "Any",
      paymentMethod: "Direct Debit",
      paymentTerms: "Immediate",
      ddCode: 17,
    },
    {
      id: 4,
      acctId: "BBB 123",
      status: "Live",
      endDt: null,
      acctName: "A Company Limited",
      acctNumber: 12345678,
      acctSort: "12-34-56",
      iban: null,
      bic: null,
      roll: null,
      countryCode: null,
      currencyAcct: process.env.CURRENCY_CODE,
      currencyInvoice: "Any",
      currencyPayment: "Any",
      paymentMethod: "Direct Debit",
      paymentTerms: "Immediate",
      ddCode: 17,
    },
    {
      id: 5,
      acctId: "BBB 123",
      status: "Live",
      endDt: null,
      acctName: "A Company Limited",
      acctNumber: 12345678,
      acctSort: "12-34-56",
      iban: null,
      bic: null,
      roll: null,
      countryCode: null,
      currencyAcct: process.env.CURRENCY_CODE,
      currencyInvoice: "Any",
      currencyPayment: "Any",
      paymentMethod: "Direct Debit",
      paymentTerms: "Immediate",
      ddCode: 17,
    },
    {
      id: 6,
      acctId: "BBB 123",
      status: "Live",
      endDt: null,
      acctName: "A Company Limited",
      acctNumber: 12345678,
      acctSort: "12-34-56",
      iban: null,
      bic: null,
      roll: null,
      countryCode: null,
      currencyAcct: process.env.CURRENCY_CODE,
      currencyInvoice: "Any",
      currencyPayment: "Any",
      paymentMethod: "Direct Debit",
      paymentTerms: "Immediate",
      ddCode: 17,
    },
    {
      id: 7,
      acctId: "BBB 123",
      status: "Live",
      endDt: null,
      acctName: "A Company Limited",
      acctNumber: 12345678,
      acctSort: "12-34-56",
      iban: null,
      bic: null,
      roll: null,
      countryCode: null,
      currencyAcct: process.env.CURRENCY_CODE,
      currencyInvoice: "Any",
      currencyPayment: "Any",
      paymentMethod: "Direct Debit",
      paymentTerms: "Immediate",
      ddCode: 17,
    },
    {
      id: 8,
      acctId: "BBB 524",
      status: "Live",
      endDt: null,
      acctName: "A Company Limited",
      acctNumber: 12345678,
      acctSort: "12-34-56",
      iban: null,
      bic: null,
      roll: null,
      countryCode: null,
      currencyAcct: process.env.CURRENCY_CODE,
      currencyInvoice: "Any",
      currencyPayment: "Any",
      paymentMethod: "Direct Debit",
      paymentTerms: "Immediate",
      ddCode: 17,
    },
    {
      id: 9,
      acctId: "BBB 123",
      status: "Live",
      endDt: null,
      acctName: "A Company Limited",
      acctNumber: 12345678,
      acctSort: "12-34-56",
      iban: null,
      bic: null,
      roll: null,
      countryCode: null,
      currencyAcct: process.env.CURRENCY_CODE,
      currencyInvoice: "Any",
      currencyPayment: "Any",
      paymentMethod: "Direct Debit",
      paymentTerms: "Immediate",
      ddCode: 17,
    },
    {
      id: 10,
      acctId: "BBB 123",
      status: "Live",
      endDt: null,
      acctName: "A Company Limited",
      acctNumber: 12345678,
      acctSort: "12-34-56",
      iban: null,
      bic: null,
      roll: null,
      countryCode: null,
      currencyAcct: process.env.CURRENCY_CODE,
      currencyInvoice: "Any",
      currencyPayment: "Any",
      paymentMethod: "Direct Debit",
      paymentTerms: "Immediate",
      ddCode: 17,
    },
    {
      id: 11,
      acctId: "BBB 123",
      status: "Live",
      endDt: null,
      acctName: "A Company Limited",
      acctNumber: 12345678,
      acctSort: "12-34-56",
      iban: null,
      bic: null,
      roll: null,
      countryCode: null,
      currencyAcct: process.env.CURRENCY_CODE,
      currencyInvoice: "Any",
      currencyPayment: "Any",
      paymentMethod: "Direct Debit",
      paymentTerms: "Immediate",
      ddCode: 17,
    },
    {
      id: 12,
      acctId: "BBB 123",
      status: "Live",
      endDt: null,
      acctName: "A Company Limited",
      acctNumber: 12345678,
      acctSort: "12-34-56",
      iban: null,
      bic: null,
      roll: null,
      countryCode: null,
      currencyAcct: process.env.CURRENCY_CODE,
      currencyInvoice: "Any",
      currencyPayment: "Any",
      paymentMethod: "Direct Debit",
      paymentTerms: "Immediate",
      ddCode: 17,
    },
  ],
};

export const getBankingItems = () => {
  return mockSuccess(ActionTypes.getBankingItems, {
    delay: 500,
    response: banking,
  });
};
