import React from "react";
import { connect } from "react-redux";
import SummaryTableRows from "./SummaryTableRows";
import { getScheduleDataMasterAgreementState } from "features/Schedules/selectors";
import { ContentPanel } from "components/displays";
const MasterAgreementTable = ({
  agreementNumber,
  finance,
  contractReference,
  startDate,
  salesPerson,
}) => {
  return (
    <ContentPanel
      title="Master Agreement"
      wrapperCssClass=" "
      headerCssClass="defaults-page-title"
    >
      <div className="spaced">
        {SummaryTableRows(
          "Master Agreement",
          agreementNumber,
          "Finance",
          finance,
          "master-agreement-teal",
          "MA"
        )}
        {SummaryTableRows(
          "Start Date",
          startDate,
          "Sales Person",
          salesPerson,
          "",
          "MA"
        )}
        {SummaryTableRows("Contract Ref", contractReference, "", "", "", "MA")}
      </div>
    </ContentPanel>
  );
};

const mapStateToProps = (state) => {
  const {
    agreementNumber,
    finance,
    contractReference,
    startDate,
    salesPerson,
  } = getScheduleDataMasterAgreementState(state) || {};

  return {
    agreementNumber,
    finance,
    contractReference,
    startDate,
    salesPerson,
  };
};

export default connect(mapStateToProps, null)(MasterAgreementTable);
