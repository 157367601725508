import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAuthState } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { requestLogout } from "./actions";

const Logout = ({ loggedIn, requestLogout }) => {
  const [logoutStatus, setLogoutStatus] = useState(null);

  useEffect(() => {
    const logout = async () => {
      const logoutResult = await requestLogout();
      setLogoutStatus(logoutResult);
    };
    logout();
  }, [logoutStatus, requestLogout]);

  if (!loggedIn) {
    return <p>You have been successfully logged out</p>;
  }

  switch (logoutStatus) {
    case false:
      return (
        <div>
          <p>Unable to sign out at this time</p>
          <div style={{ marginTop: 20 }}>
            <Button
              className="action-button"
              onClick={() => setLogoutStatus(null)}
            >
              Try Again
            </Button>
          </div>
        </div>
      );

    default:
      return <p>Logging out...</p>;
  }
};

const mapStateToProps = (state) => ({
  loggedIn: getAuthState(state).loggedIn,
});

export default connect(mapStateToProps, { requestLogout })(Logout);
