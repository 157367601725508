import React from "react";
import { addTargetMarket } from "./actions";
import { IconButton } from "features/../../../shared/components/buttons";
import { ModalBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Actions, Targets } from "constants/permissions";
import TargetMarketModalForm from "./TargetMarketModalForm";
import { TranslatedModal } from "../../../../components/modals";
import { useTranslation } from "react-i18next";

const AddTargetMarket = ({
  as: As = IconButton,
  onAdded,
  dealerOfficeId,
  ...props
}) => {
  const  { t }  = useTranslation('supplierOffices', { keyPrefix:'largeFormat.targetMarket'});
  return(
  <PermissionCheck target={Targets.LargeFormat} action={Actions.Create}>
    <ModalBuilder
      withForm
      submitAction={addTargetMarket}
      submitParams={{ dealerOfficeId }}
      onSubmitted={onAdded}
      renderTrigger={showModal => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <TranslatedModal.Add
          {...modalProps}
          header={t("createTargetMarket")}
          submitLabel={t("createTargetMarket")}
        >
          <TargetMarketModalForm
            formProps={formProps}
            dealerOfficeId={dealerOfficeId}
          />
        </TranslatedModal.Add>
      )}
    />
  </PermissionCheck>
)};

export default AddTargetMarket;
