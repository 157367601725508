import React from "react";
import { Wizard, Modal, ModalBuilder, Table } from "@redriver/cinnamon";
import { Menu } from "semantic-ui-react";
import { Products, FmvInfo, PaymentDetails } from "./";

const OptionsSteps = {
  PaymentDetails: "summary",
  FMVInfo: "detail",
  Products: "products",
};

const Options = () => {
  const NavigationControls = ({ steps, activeStep, onGoToStep }) => {
    return steps.map((s) => (
      <Menu.Item
        value={0}
        key={s.key}
        active={s === activeStep}
        onClick={() => onGoToStep(s.key)}
      >
        {s.title}
      </Menu.Item>
    ));
  };

  const stepData = [
    {
      key: OptionsSteps.PaymentDetails,
      title: "Payment Details",
    },
    {
      key: OptionsSteps.FMVInfo,
      title: "FMV Info",
    },
    {
      key: OptionsSteps.Products,
      title: "Products",
    },
  ];

  return (
    <ModalBuilder
      renderTrigger={(showModal) => (
        <Table.Menu>
          <Table.MenuItem text="Options" onClick={showModal} />
        </Table.Menu>
      )}
      renderModal={(modalProps) => (
        <Modal
          header="Options"
          className="schedule-cfa-options-modal"
          {...modalProps}
        >
          <div className="schedules-cfa-options-container">
            <div className="padded">
              <Wizard steps={stepData}>
                <div className="products-wizard-navigation">
                  <Wizard.Nav
                    render={(wizardContext) => (
                      <Menu>
                        <NavigationControls {...wizardContext} />
                      </Menu>
                    )}
                  />
                </div>
                <Wizard.Step
                  step={OptionsSteps.PaymentDetails}
                  render={() => <PaymentDetails />}
                />
                <Wizard.Step
                  step={OptionsSteps.FMVInfo}
                  render={() => <FmvInfo />}
                />
                <Wizard.Step
                  step={OptionsSteps.Products}
                  render={() => <Products />}
                />
              </Wizard>
            </div>
          </div>
        </Modal>
      )}
    />
  );
};

export default Options;
