import React, { forwardRef } from "react";
import { Grid, Button } from "semantic-ui-react";
import { FormBuilder, Form, Loadable } from "@redriver/cinnamon";
import {
  getCostPerDeviceDefaults,
  updateCostPerDeviceDefaults,
} from "./actions";

import { PermissionCheck } from "features/../../../shared/components/auth";
import { IconButton } from "features/../../../shared/components/buttons";
import Pen from "features/../../../shared/assets/pen.svg";
import Save from "features/../../../shared/assets/save.svg";
import { Targets, Actions } from "constants/permissions";
import AccordionHelper from "components/displays/AccordionHelper";

const CostPerDevice = forwardRef(
  ({ settingsId, toggleVisibility, collapsed }, ref) => {
    return (
      <React.Fragment>
        <div ref={ref} className="cost-per-device-title">
          <FormBuilder
            initialReadOnly
            loadAction={getCostPerDeviceDefaults}
            loadParams={{ settingsId }}
            submitAction={updateCostPerDeviceDefaults}
            submitParams={{ settingsId }}
            renderLoading={false}
            renderSubmitting={false}
            renderError={false}
            renderForm={(formProps, state, events) => {
              return (
                <AccordionHelper
                  collapsed={collapsed}
                  toggleVisibility={toggleVisibility}
                  title="Cost Per Device"
                >
                  <React.Fragment>
                    <div className="defaults-page-title cost-per-device-edit flex">
                      <div className="flex title-bar"></div>
                      <PermissionCheck
                        target={Targets.DefaultsRestrictions}
                        action={Actions.Edit}
                      >
                        <div className="volume-selection-restriction-edit-buttons">
                          <IconButton
                            primary
                            icon={state.readOnly ? Pen : Save}
                            content={state.readOnly ? "Edit" : "Save"}
                            floated="right"
                            onClick={() =>
                              state.readOnly
                                ? events.onToggleReadOnly()
                                : events.onSubmit({ reset: ["readOnly"] })
                            }
                            className={
                              !state.readOnly ? "positive-title-action" : null
                            }
                            loading={state.loading || state.submitting}
                            disabled={state.loading || state.submitting}
                          />
                          {!state.readOnly && (
                            <Button
                              primary
                              className="cancel-title-action"
                              floated="right"
                              content="Cancel"
                              onClick={events.onReset}
                            />
                          )}
                        </div>
                      </PermissionCheck>
                    </div>

                    <Loadable loading={state.loading || state.submitting}>
                      <Form {...formProps} className="highlighted-inputs ">
                        <Grid className="cost-per-device-box">
                          <Grid.Row>
                            <Grid.Column>Default</Grid.Column>
                            <Grid.Column className="cost-per-device-column">
                              <Form.Numeric
                                field="costPerDevice"
                                label=""
                                inline
                                className="volume-selection-field"
                                width={6}
                                formatValue={true}
                                decimalPlaces={2}
                                subLabel={process.env.MAJOR_CURRENCY}
                                subLabelPosition="left"
                                minValue={0}
                                minValueError="Must be 0 or greater"
                              />
                            </Grid.Column>
                            <Grid.Column>per device per month</Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column>Default & Minimum</Grid.Column>
                            <Grid.Column className="default-total-column">
                              <Form.Numeric
                                field="defaultAndMinimumTotalDevicesOnScheduleValue"
                                label=""
                                inline
                                className="volume-selection-field"
                                width={4}
                                formatValue={true}
                                minValue={0}
                                minValueError="Must be 0 or greater"
                                decimalPlaces={0}
                              />
                            </Grid.Column>
                            <Grid.Column>device per Schedule</Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column>
                              <Form.Checkbox
                                field="devicesPriceIncreaseApplicable"
                                label="Price Increase Applicable"
                                disabled={state.readOnly}
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column>
                              <Form.Input
                                field="devicesBillingCode"
                                label="Billing Code"
                                maxLength={20}
                                width={20}
                                inline
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Form>
                    </Loadable>
                  </React.Fragment>
                </AccordionHelper>
              );
            }}
          />
        </div>
      </React.Fragment>
    );
  }
);

export default CostPerDevice;
