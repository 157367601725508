import React from "react";
import {
  ListBuilder,
  FormBuilder,
  ActionBar,
  Format,
} from "@redriver/cinnamon";
import { getAttachments } from "./actions";
import { ContentPanel } from "components/displays";
import { TextSearch } from "features/../../../shared/components/search";
import FilterSidePanel from "./FilterSidePanel";
import { InfoModalWrapper } from "components/modals/";
import { SortableTable } from "../../../../../../shared/components/tables/sortableTable";

const Attachments = () => {
  const columns = [
    {
      field: "detail",
      title: "Detail",
      render: (item) => (
        <a href="#" target="_blank">
          {item.detail}
        </a>
      ),
    },
    { field: "type", title: "Type" },
    { field: "from", title: "From" },
    { field: "to", title: "To" },
    {
      field: "dt",
      title: "Date",
      render: (item) => <Format.Date value={item.dt} format="DD MMM YYYY" />,
    },
  ];

  return (
    <ListBuilder
      withTable
      loadAction={getAttachments}
      renderList={(tableProps, state, events) => (
        <ContentPanel
          title="Attachments"
          wrapperCssClass="outline-grid attachments-list"
          headerCssClass="defaults-page-title"
          headerContent={<FilterBar state={state} events={events}></FilterBar>}
        >
          <div className="padded">
            <SortableTable
              state={state}
              events={events}
              tableProps={tableProps}
              emptyMessage="No products found"
              columns={columns}
            />
          </div>
        </ContentPanel>
      )}
    />
  );
};

const FilterBar = ({ state, events }) => (
  <FormBuilder
    renderForm={(formProps) => (
      <ActionBar className="search-filter-display">
        <ActionBar.Item>
          <TextSearch
            filters={state.filters}
            onChange={events.onChangeFilters}
          ></TextSearch>
        </ActionBar.Item>
        <ActionBar.Item fluid></ActionBar.Item>
        <ActionBar.Space />

        <ActionBar.Item>
          <InfoModalWrapper title="Upload New Document" icon="upload">
            Not implemented.
          </InfoModalWrapper>
        </ActionBar.Item>
        <ActionBar.Space />

        <ActionBar.Item>
          <FilterSidePanel formProps={formProps} />
        </ActionBar.Item>
      </ActionBar>
    )}
  />
);

export default Attachments;
