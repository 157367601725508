export const ViewType = {
    All: "All",
    Active: "Active",
    Inactive: "Inactive",
  };
  
  export const ViewTypeOptions = [
    { value: ViewType.All, text: "(All)" },
    { value: ViewType.Active, text: "Active" },
    { value: ViewType.Inactive, text: "Inactive" }
  ];
  