import React from "react";
import { ContentPanel } from "components/displays";
import {
  TableDivider,
  numericFormatter,
} from "components/displays/DisplayHelper";
import { Table } from "semantic-ui-react";

const Term = ({ term }) => {
  return (
    <ContentPanel
      title="Term"
      wrapperCssClass="outline-grid"
      headerCssClass="defaults-page-title"
    >
      <div className="padded term">
        <TermTable term={term} />
        <p>*approx due to FMV Conversion calculation</p>
      </div>
    </ContentPanel>
  );
};

const TermTable = ({ term }) => (
  <Table className="term-table">
    <Table.Body>
      <TableDivider />
      <Table.Row>
        <Table.Cell>Initial Term</Table.Cell>
        <Table.Cell>{numericFormatter(term?.initialTerm, 0, 3)}</Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>Adjustment</Table.Cell>
        <Table.Cell>{numericFormatter(term?.adjustment, 0, 3)}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>FMV Conversion - Additional Periods*</Table.Cell>
        <Table.Cell>
          {numericFormatter(term?.fmvConversionAdditionalPeriods, 0, 3)}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="strong grey">
        <Table.Cell>Funder Term</Table.Cell>
        <Table.Cell>{numericFormatter(term?.funderTerm, 0, 3)}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Periods Collected</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(term?.periodsCollected, 0, 3)}
        </Table.Cell>
      </Table.Row>
      <Table.Row className="highlighted">
        <Table.Cell>Reallocation</Table.Cell>
        <Table.Cell> {numericFormatter(term?.reallocation, 0, 3)}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Periods Settled</Table.Cell>
        <Table.Cell> {numericFormatter(term?.periodsSettled, 0, 3)}</Table.Cell>
      </Table.Row>
      <Table.Row className="strong grey">
        <Table.Cell>Term Outstanding</Table.Cell>
        <Table.Cell>
          {" "}
          {numericFormatter(term?.termOutstanding, 0, 3)}
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export default Term;
