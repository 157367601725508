import React from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { Icon } from "semantic-ui-react";

const InfoIconModalWrapper = (props) => {
  return (
    <React.Fragment>
      <ModalBuilder
        renderTrigger={(showModal) => (
          <Icon className={props.icon} floated="right" onClick={showModal} />
        )}
        renderModal={(modalProps) => (
          <Modal.Information {...modalProps} header={props.title}>
            {props.children}
          </Modal.Information>
        )}
      />
    </React.Fragment>
  );
};

export default InfoIconModalWrapper;
