import { mockSuccess } from "@redriver/cinnamon";

const NAMESPACE = "COMPANIES/INVOICES";

const ActionTypes = {
  getInvoices: `${NAMESPACE}/GET_INVOICES`,
};

const invoice = [
  {
    id: 1,
    InvoiceNumber: "XSLINV/0001",
    Date: "01/01/2021",
    ScheduleReference: "MA1001-SC1001",
    Type: "Minimum",
    NetAmount: 1500,
    GrossAmount: 1800,
    OutstandingBalance: 1800,
    DueDate: "01/01/2021",
    Status: "Overdue",
    DelinquentDays: 1,
  },
  {
    id: 2,
    InvoiceNumber: "XSLINV/0002",
    Date: "02/01/2021",
    ScheduleReference: "MA1001-SC1002",
    Type: "Minimum",
    NetAmount: 1500,
    GrossAmount: 1800,
    OutstandingBalance: 1800,
    DueDate: "02/01/2021",
    Status: "Overdue",
    DelinquentDays: 10,
  },
  {
    id: 3,
    InvoiceNumber: "XSLINV/0003",
    Date: "03/01/2021",
    ScheduleReference: "MA1001-SC1003",
    Type: "Minimum",
    NetAmount: 1500,
    GrossAmount: 1800,
    OutstandingBalance: 1800,
    DueDate: "03/01/2021",
    Status: "Overdue",
    DelinquentDays: 10,
  },
  {
    id: 4,
    Type: "Total",
    NetAmount: 4500,
    GrossAmount: 5400,
    OutstandingBalance: 5400,
  },
];

export const getInvoices = () => {
  return mockSuccess(ActionTypes.getInvoices, {
    delay: 500,
    response: invoice,
  });
};
