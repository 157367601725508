import React from "react";
import { Form } from "@redriver/cinnamon";

const DealerStructureForm = ({ className, fullCrmCode, ...props }) => {
  return (
    <div className={className}>
      <Form {...props}>
        <Form.Group widths={4}>
          <Form.Input
            field="name"
            label="Territory Name"
            fluid
            required
            placeholder="Territory Name..."
          />
          <Form.Input
            field="territoryId"
            label="Territory ID"
            fluid
            readOnly
            renderReadOnly={(props) => {
              return (
                <React.Fragment>
                  {`${props.value}`.padStart(2, "0")}
                </React.Fragment>
              );
            }}
            placeholder="Office ID..."
          />
          <Form.Input
            field="legalEntityName"
            label="Legal Entity"
            fluid
            readOnly
            placeholder="Legal Entity..."
          />
          <Form.Input
            field="crmId"
            label="CRM ID"
            fluid
            readOnly
            renderReadOnly={() => {
              return <React.Fragment>{fullCrmCode}</React.Fragment>;
            }}
            placeholder="CRM ID..."
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default DealerStructureForm;
