import React from "react";
import MobileTopBar from "./MobileTopBar";

const MobileWrapper = ({ isNarrowViewport, children }) => (
  <div className="mobile-wrapper">
    <MobileTopBar active={isNarrowViewport}>{children}</MobileTopBar>
  </div>
);

export default MobileWrapper;
