import { apiPost, apiGet, apiDelete, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "DEALER_STRUCTURES";
const ActionTypes = {
  CreateDealerStructure: `${NAMESPACE}/CREATE_DEALER_STRUCTURE`,
  GetHierarchy: `${NAMESPACE}/GET_DEALER_HIERARCHY`,
  DeleteDealerStructure: `${NAMESPACE}/DELETE_DEALER`,
  EditDealerStructure: `${NAMESPACE}/EDIT_DEALER_STRUCTURE`,
};

export const createDealerStructure = (data, params) => {
  return apiPost(ActionTypes.CreateDealerStructure, `dealer-structures/`, {
    ...data,
    ...params,
  });
};

export const getDealerHierarchy = (_, id) => {
  return apiGet(ActionTypes.GetHierarchy, `dealer-hierarchy/${id}`);
};

export const deleteDealerStructure = (id) =>
  apiDelete(ActionTypes.DeleteDealerStructure, `dealer-structures/${id}`);

export const editDealerStructure = (name, id) => {
  return apiPut(
    ActionTypes.EditDealerStructure,
    `dealer-structures/${id}`,
    name
  );
};
