import React from "react";
import { ListBuilder } from "@redriver/cinnamon";
import SortableTable from "./SortableTable";

const SimpleTableBuilder = ({ columns, className, loadAction, dataKey, loadParams,emptyMessage = "No Records Found" }) => {

    return (
      <ListBuilder
      withTable
      loadAction={loadAction}
      loadParams={loadParams}
      renderList={(tableProps, state, events, ) => (
          <SortableTable state={state} events={events} tableProps={tableProps} emptyMessage={emptyMessage} 
              columns={columns} className={className} dataKey={dataKey} />      
      )}/>
    );
  };
  
  export default SimpleTableBuilder;
  