import React from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import FunderForm from "./FunderForm";
import { createFunder } from "./actions";
import { AddButton } from "features/../../../shared/components/buttons";

const FunderCreateModal = ({ onRefresh }) => {
  return (
    <ModalBuilder
      withForm
      submitAction={createFunder}
      onSubmitted={onRefresh}
      renderTrigger={showModal => (
        <AddButton
          primary
          content="Add New Funder Legal Entity"
          onClick={showModal}
        />
      )}
      renderModal={(modalProps, formProps) => (
        <Modal.Add
          {...modalProps}
          header="Create New Legal Entity"
          headerClassName="yellow"
          submitLabel="Save"
          size="large"
          className="funder-create-modal yellow"
        >
          <FunderForm {...formProps} />
        </Modal.Add>
      )}
    />
  );
};

export default FunderCreateModal;
