import React from "react";
import { PageContent } from "@redriver/cinnamon";
import { ManageSchedule } from "features/Schedules";
import { RedirectBreadcrumbBar } from "pages/nav";
import { AppRoutes } from "constants/routes";

const SchedulePage = ({ match }) => (
  <PageContent className="manage-schedule">
    <RedirectBreadcrumbBar to={AppRoutes.Schedules} text="Back to Schedules" />
    <ManageSchedule scheduleId={match.params.id} key={match.params.id} />
  </PageContent>
);

export default SchedulePage;
