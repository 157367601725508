import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AppRoutes, DealerRoutes } from "constants/routes";
import DealersListPage from "./DealersListPage";
import DealerDetailsPage from "./DealerDetailsPage";
import DealerOfficePage from "./DealerOfficePage";

const DealersRouter = () => (
  <Switch>
    <Route exact path={AppRoutes.Dealers} component={DealersListPage} />
    <Route exact path={DealerRoutes.Details()} component={DealerDetailsPage} />
    <Route exact path={DealerRoutes.Office()} component={DealerOfficePage} />
    <Route render={() => <Redirect to={AppRoutes.Dealers} />} />
  </Switch>
);

export default DealersRouter;
