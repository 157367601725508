import React, { useState } from "react";
import { Tab } from "semantic-ui-react";
import DetailsSummary from "./Summary/DetailsSummary";
import FinancialDetails from "./Financials/FinancialDetails";
import DetailsStructure from "./Structure/DetailsStructure";
import { BoilerPlateDetails } from "./BoilerPlates";
import { DocumentNumbering } from "./Documents";

const DealerDetailsTabs = ({ id, onSubmitted }) => {
  const [activeTab, setActiveTab] = useState(0);
  const panes = [
    {
      menuItem: "Summary",
      render: () => <DetailsSummary id={id} onSubmitted={onSubmitted} />,
    },
    {
      menuItem: "Structure",
      render: () => <DetailsStructure id={id} />,
    },
    {
      menuItem: "Boiler Plate T&C's",
      render: () => <BoilerPlateDetails dealerId={id} />,
    },
    {
      menuItem: "Financials",
      render: () => <FinancialDetails />,
    },
    {
      menuItem: "Documents",
      render: () => <DocumentNumbering dealerId={id} />,
    },
  ];
  return (
    <React.Fragment>
      <Tab
        menu={{ text: true }}
        panes={panes}
        id="tabs"
        activeIndex={activeTab}
        onTabChange={(event, data) => {
          setActiveTab(data.activeIndex);
        }}
      />
    </React.Fragment>
  );
};

export default DealerDetailsTabs;
