import { apiGet, apiPut, apiPost, notifySuccess } from "@redriver/cinnamon";

const NAMESPACE = "SPVs";

const ActionTypes = {
  GetSPVList: `${NAMESPACE}/GET_SPVS`,
  CreateSPV: `${NAMESPACE}/CREATE_SPV`,
  GetSPVHeader: `${NAMESPACE}/GET_SPV_HEADER`,
  GetSPVSummary: `${NAMESPACE}/GET_SPV_SUMMARY`,
  UpdateSPVSummary: `${NAMESPACE}/UPDATE_SPV_SUMMARY`,
  GetSPVBankAccount: `${NAMESPACE}/GET_SPV_BANK_ACCOUNT`,
  UpdateSPVBankAccount: `${NAMESPACE}/UPDATE_SPV_BANK_ACCOUNT`,
  GetSPVBillingInformation: `${NAMESPACE}/GET_SPV_BILLING_INFORMATION`,
  UpdateSPVBillingInformation: `${NAMESPACE}/UPDATE_SPV_BILLING_INFORMATION`,
  GetSPVSummaryDetails: `${NAMESPACE}/GET_SPV_SUMMARY_DETAILS`,
  GetSPVDetails: `${NAMESPACE}/GET_SPV_DETAILS`,
};

export const getSPVList = ({ filters, pagination }) => {
  let data = {
    ...filters,
    ...pagination,
  };
  return apiGet(ActionTypes.GetSPVList, "SPVs", data);
};

export const createSPV = request => async dispatch => {
  let response = await dispatch(
    apiPost(ActionTypes.CreateSPV, "SPVs", request)
  );
  response &&
    response.success &&
    dispatch(notifySuccess("SPV created successfully"));
  return response;
};

export const getSPVHeader = ({ id }) =>
  apiGet(ActionTypes.GetSPVHeader, `spvs/${id}/header`);

export const getSPVSummary = spvId =>
  apiGet(ActionTypes.GetSPVSummary, `spvs/${spvId}/summary`);

export const updateSPVSummary = (request, spvId) => async dispatch => {
  let response = await dispatch(
    apiPut(ActionTypes.UpdateSPVSummary, `SPVs/${spvId}/summary`, request)
  );
  response &&
    response.success &&
    dispatch(notifySuccess("SPV summary updated successfully"));
  return response;
};

export const getSPVBankAccount = spvId =>
  apiGet(ActionTypes.GetSPVBankAccount, `spvs/${spvId}/bank-account`);

export const updateSPVBankAccount = (request, spvId) => async dispatch => {
  let response = await dispatch(
    apiPut(
      ActionTypes.UpdateSPVBankAccount,
      `SPVs/${spvId}/bank-account`,
      request
    )
  );
  response &&
    response.success &&
    dispatch(notifySuccess("SPV bank account updated successfully"));
  return response;
};

export const getSPVBillingInformation = spvId => async dispatch => {
  let response = await dispatch(
    apiGet(
      ActionTypes.GetSPVBillingInformation,
      `spvs/${spvId}/billing-information`
    )
  );
  const result = {
    billingInformation: response.response,
  };
  return result;
};

export const updateSPVBillingInformation =
  (request, spvId) => async dispatch => {
    let response = await dispatch(
      apiPut(
        ActionTypes.UpdateSPVBillingInformation,
        `SPVs/${spvId}/billing-information`,
        request
      )
    );
    response &&
      response.success &&
      dispatch(notifySuccess("SPV billing information updated successfully"));
    return response;
  };
