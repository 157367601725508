import React from "react";
import { ListBuilder, Table } from "@redriver/cinnamon";
import {
  yesNoFormatter,
  numericFormatter,
  currencyFormatter,
} from "components/displays/DisplayHelper";
import { getLargeFormatPrinters, getPrintersForUnitType } from "./actions";
import { ContentPanel } from "components/displays";
import { Grid } from "semantic-ui-react";
import { SortHeader } from "features/../../../shared/components/tables/sortableTable";
import { InfoModalWrapper } from "components/modals/";

const ConfigTable = ({ printers, config, state, events }) => (
  <Table className="lfpConfigTable" data={config} dataKey="id">
    <Table.Column
      width="3%"
      field="unit"
      title="Unit"
      className="strong"
      render={(unitInfo) => (
        <LargeFormatUnitInfoModal
          unitInfo={unitInfo}
          unitPrinters={getPrintersForUnitType(printers, unitInfo.unit)}
        />
      )}
      header={
        <SortHeader
          field="unit"
          title="Unit"
          value={state.filters}
          onChange={events.onChangeFilters}
        />
      }
    />
    <Table.Column
      width="22%"
      field="description"
      title="Description"
      header={
        <SortHeader
          field="description"
          title="Description"
          value={state.filters}
          onChange={events.onChangeFilters}
        />
      }
    />
    <Table.Column
      width="8%"
      title="CPI (p)"
      className="strong"
      render={(x) => numericFormatter(x.cpi, 0, 0)}
      header={
        <SortHeader
          field="x.cpi"
          title="CPI (p)"
          value={state.filters}
          onChange={events.onChangeFilters}
        />
      }
    />
    <Table.Column
      width="44%"
      field="media"
      title="Media"
      header={
        <SortHeader
          field="media"
          title="Media"
          value={state.filters}
          onChange={events.onChangeFilters}
        />
      }
    />
    <Table.Column
      width="25%"
      title="Paper SKU"
      className="red"
      render={(unitInfo) => <PaperSKUInfoModal unitInfo={unitInfo} />}
      header={
        <SortHeader
          field="sku"
          title="Paper SKU"
          value={state.filters}
          onChange={events.onChangeFilters}
        />
      }
    />
  </Table>
);

const LargeFormat = () => {
  return (
    <ContentPanel
      title="Large Format Printers"
      wrapperCssClass="outline-grid"
      headerCssClass="defaults-page-title"
    >
      <ListBuilder
        key="uniqueId1"
        loadAction={getLargeFormatPrinters}
        dataTransform={(data) => data._data}
        renderList={(data, state, events) =>
          data.map((printer) => (
            <div key={"lfp" + printer.id}>
              <div className="spaced">
                <ContentPanel
                  title={"Printer Configuration " + printer.id}
                  wrapperCssClass="outline-grid"
                  headerCssClass="defaults-page-title"
                >
                  <div className="spaced">
                    <div style={{ maxWidth: "1000px", margin: "auto" }}>
                      <PrinterTable printer={[printer]} id="lfp01" />
                    </div>
                    <br />
                    <br />

                    <ConfigTable
                      printers={data}
                      config={printer.configs}
                      id="lfp02"
                      state={state}
                      events={events}
                    />
                  </div>
                </ContentPanel>
              </div>
            </div>
          ))
        }
      ></ListBuilder>
    </ContentPanel>
  );
};

const PrinterTable = ({ printer }) => (
  <Table data={printer} dataKey="id">
    <Table.Column width="18%" field="name" title="Printer" className="strong" />
    <Table.Column
      width="16%"
      title="Scanner Included"
      className="strong"
      render={(x) => (
        <React.Fragment>{yesNoFormatter(x.scannerIncluded)}</React.Fragment>
      )}
    />
    <Table.Column
      width="15%"
      field="maxRollWidth"
      title="Max Roll Width (mm)"
      className="strong"
      render={(x) => numericFormatter(x.maxRollWidth, 0, 0)}
    />
    <Table.Column
      width="16%"
      field="maxRollWidthInches"
      title="Max Roll Width (inches)"
      className="strong"
      render={(x) => numericFormatter(x.maxRollWidthInches, 0, 0)}
    />
    <Table.Column
      width="19%"
      field="noOfColours"
      title="Number of Colours in Unit"
      className="strong"
      render={(x) => numericFormatter(x.noOfColours, 0, 0)}
    />
    <Table.Column
      width="16%"
      field="priceOption"
      title="Price Option"
      className="strong"
    />
  </Table>
);

const LargeFormatUnitInfoModal = ({ unitInfo, unitPrinters }) => {
  return (
    <InfoModalWrapper
      floated="left"
      title="Unit Info"
      linkText={unitInfo.unit}
      modalClassName="lfui-modal"
      submitLabel="Close"
      submitIcon=""
    >
      <div className="largeFormatUnitInfo" key="unitInfo">
        <div className="unitInfo">
          <div className="infoRow">
            <div className="infoHeader">Unit:</div>
            <div className="infoValue">{unitInfo.unit}</div>
          </div>
          <div className="infoRow">
            <div className="infoHeader">Billing Code:</div>
            <div className="infoValue">{unitInfo.billingCode}</div>
          </div>
          <div className="infoRow">
            <div className="infoHeader">Description:</div>
            <div className="infoValue">{unitInfo.description}</div>
          </div>
        </div>
        <Table data={unitPrinters} dataKey="id" key="unitPrinters">
          <Table.Column
            width="50%"
            field="serialNumber"
            title="Serial Number"
          />
          <Table.Column width="50%" field="name" title="Model" />
        </Table>
      </div>
    </InfoModalWrapper>
  );
};

const PaperSKUInfoModal = ({ unitInfo }) => {
  const skuInfo = unitInfo.sku;

  return (
    <InfoModalWrapper
      floated="left"
      title="Paper SKU Info"
      linkText={unitInfo.sku.paperSku}
      modalClassName="lf-paper-sku-modal"
      submitLabel="Close"
      submitIcon=""
      size="large"
    >
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>
            <h3>Unit: {unitInfo.unit}</h3>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column>
            <div className="horizontal-scrollbar">
              <Table data={[skuInfo]} dataKey="paperSku">
                <Table.Column width="10%" title="Supplier" field="supplier" />
                <Table.Column width="10%" title="Paper SKU" field="paperSku" />
                <Table.Column width="10%" field="no" title="No." />
                <Table.Column
                  width="5%"
                  field="mediaWeight"
                  title="Media Weight (gsm)"
                  render={(x) => numericFormatter(x.mediaWeight, 0, 0)}
                />
                <Table.Column
                  width="5%"
                  field="paperWidthMm"
                  title="Paper Width (mm)"
                  render={(x) => numericFormatter(x.paperWidthMm, 0, 0)}
                />
                <Table.Column
                  width="5%"
                  field="paperWidthIn"
                  title="Paper Width (in)"
                  render={(x) => numericFormatter(x.paperWidthIn, 0, 0)}
                />
                <Table.Column
                  width="5%"
                  field="totalLengthM"
                  title="Total Length (m)"
                  render={(x) => numericFormatter(x.totalLengthM, 0, 0)}
                />
                <Table.Column width="3%" field="m2Total" title="m² Total" />
                <Table.Column
                  width="5%"
                  field="actualCostPrice"
                  title={`Actual Cost Price (${process.env.MAJOR_CURRENCY})`}
                  render={(x) => currencyFormatter(x.actualCostPrice)}
                />
                <Table.Column
                  width="5%"
                  field="scheduleCostPrice"
                  title={`Schedule Cost Price (${process.env.MAJOR_CURRENCY})`}
                  render={(x) => currencyFormatter(x.scheduleCostPrice)}
                />
                <Table.Column
                  width="5%"
                  field="actualCostPerM2"
                  title={`Actual Cost per m² (${process.env.MINOR_CURRENCY})`}
                  render={(x) => numericFormatter(x.actualCostPerM2, 0, 3)}
                />
                <Table.Column
                  width="7%"
                  field="scheduleCostPerM2"
                  title={
                    <React.Fragment>
                      <span>Schedule Cost</span>
                      <br />
                      <span>per m² ({process.env.MINOR_CURRENCY})</span>
                    </React.Fragment>
                  }
                  render={(x) => numericFormatter(x.scheduleCostPerM2, 0, 3)}
                />
              </Table>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </InfoModalWrapper>
  );
};

export default LargeFormat;
