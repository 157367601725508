import React from "react";
import { SimpleTableBuilder } from "../../../../../../../shared/components/tables/sortableTable";
import { getCfaPayment, getContinuingPayments } from "./actions";
import { ListBuilder } from "@redriver/cinnamon";
import { Table } from "semantic-ui-react";
import { numericFormatter } from "components/displays/DisplayHelper";
import { Header } from "./";

const PaymentDetails = () => {
  const cfaPaymentColumns = [
    { field: "title", title: "", sortable: false, className: "left-align" },
    {
      field: "minimum",
      title: `Minimum (${process.env.MAJOR_CURRENCY})`,
      sortable: false,
      render: (x) => numericFormatter(x.minimum, 0, 2),
    },
    {
      field: "scheduleSplit",
      title: "CFA Schedule Split (%)",
      sortable: false,
      render: (x) => numericFormatter(x.scheduleSplit, 0, 2),
    },
    { field: "billingCode", title: "Billing Code", sortable: false },
  ];

  return (
    <React.Fragment>
      <Header />
      <h3>CFA Payment</h3>
      <SimpleTableBuilder
        emptyMessage="No payment found"
        columns={cfaPaymentColumns}
        loadAction={getCfaPayment}
      />
      <h3>Continuing Payments</h3>
      <ListBuilder
        loadAction={getContinuingPayments}
        renderList={(data) => (
          <Table className="cfa-payment-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="empty"></Table.HeaderCell>
                <Table.HeaderCell className="highlight" colSpan={3}>
                  CFA
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell className="border-left"></Table.HeaderCell>
                <Table.HeaderCell>Split (%)</Table.HeaderCell>
                <Table.HeaderCell>
                  Minimum ({process.env.MAJOR_CURRENCY})
                </Table.HeaderCell>
                <Table.HeaderCell>Billing Code</Table.HeaderCell>
              </Table.Row>
              <Table.Row></Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((d) => (
                <Table.Row key={d.id}>
                  <Table.Cell className="left-align">{d.title}</Table.Cell>
                  <Table.Cell>{numericFormatter(d.split, 0, 0)}</Table.Cell>
                  <Table.Cell>{numericFormatter(d.minimum, 0, 2)}</Table.Cell>
                  <Table.Cell>{d.billingCode}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      />
    </React.Fragment>
  );
};

export default PaymentDetails;
