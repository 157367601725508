import React, { useState } from "react";
import { getItems } from "./actions";
import { Table, Button } from "semantic-ui-react";
import { ListBuilder } from "@redriver/cinnamon";
import {
  yesNoFormatter,
  numericFormatter,
} from "components/displays/DisplayHelper";
import { ContentPanel } from "components/displays";
import { SortableHeader } from "../../../../../../shared/components/tables/sortableTable";

const Item = () => {
  const [displayCpiCells, setDisplayCpiCells] = useState(true);
  const [displayTotalCells, setDisplayTotalCells] = useState(true);
  const [quarterly, setQuarterly] = useState(true);

  const headers = [
    { field: "unit", title: "Unit" },
    { field: "managed", title: "Managed?" },
    { field: "minVol", title: "Minimum Volume" },
    {
      field: "cpi.capital",
      title: `Capital CPI (${process.env.MINOR_CURRENCY})`,
      display: displayCpiCells,
    },
    {
      field: "cpi.services",
      title: `Services CPI (${process.env.MINOR_CURRENCY})`,
      display: displayCpiCells,
    },
    {
      field: "cpi.cpi",
      title: `CPI (${process.env.MINOR_CURRENCY})`,
      display: displayCpiCells,
    },
    { field: "cpi.cpu", title: `CPU (${process.env.MINOR_CURRENCY})` },
    {
      field: "total.capital",
      title: `Capital Total (${process.env.MAJOR_CURRENCY})`,
      display: displayTotalCells,
    },
    {
      field: "total.services",
      title: `Services Total (${process.env.MAJOR_CURRENCY})`,
      display: displayTotalCells,
    },
    {
      field: "total.cpi",
      title: `CPI Total ${process.env.MAJOR_CURRENCY}`,
      display: displayTotalCells,
    },
    { field: "total.cpu", title: `CPU Total (${process.env.MAJOR_CURRENCY})` },
    { field: "tonerExclusive", title: "Toner Exclusive" },
    { field: "tieredToner", title: "Tiered Toner" },
  ];

  const btnGroup = (
    <Button.Group floated="right">
      <Button positive={quarterly} onClick={() => setQuarterly(true)}>
        Quarterly
      </Button>
      <Button positive={!quarterly} onClick={() => setQuarterly(false)}>
        Monthly
      </Button>
    </Button.Group>
  );

  return (
    <ContentPanel
      title="Item"
      wrapperCssClass="outline-grid"
      headerCssClass="defaults-page-title"
      headerContent={btnGroup}
    >
      <ListBuilder
        loadAction={getItems}
        loadParams={quarterly}
        dataTransform={(data) => data._data}
        renderList={(data, state, events) => (
          <div>
            <br />
            {data && data.length > 0 && (
              <div className="padded">
                <Table className="collapsable-headers">
                  <Table.Header className="hide">
                    <Table.Row>
                      <Table.HeaderCell colSpan={3} className="left-align">
                        <h4 className="settings-title">
                          {quarterly ? " Quarterly" : " Monthly"} Values
                        </h4>
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        colSpan={displayCpiCells ? 4 : 1}
                        onClick={() => setDisplayCpiCells(!displayCpiCells)}
                      >
                        <div className="grey left-align">
                          <React.Fragment>
                            &gt;&gt;{" "}
                            {displayCpiCells ? (
                              <React.Fragment>Collapse</React.Fragment>
                            ) : (
                              <React.Fragment>Expand</React.Fragment>
                            )}{" "}
                          </React.Fragment>
                        </div>
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        colSpan={displayTotalCells ? 4 : 1}
                        onClick={() => setDisplayTotalCells(!displayTotalCells)}
                      >
                        <div className="grey left-align">
                          <React.Fragment>
                            &gt;&gt;{" "}
                            {displayTotalCells ? (
                              <React.Fragment>Collapse</React.Fragment>
                            ) : (
                              <React.Fragment>Expand</React.Fragment>
                            )}{" "}
                          </React.Fragment>
                        </div>
                      </Table.HeaderCell>
                      <Table.HeaderCell />
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>
                  <SortableHeader
                    columns={headers}
                    state={state}
                    events={events}
                  />
                  <Table.Body>
                    {data.map((d) => (
                      <TableRow
                        key={d.id}
                        data={d}
                        displayCpiCells={displayCpiCells}
                        displayTotalCells={displayTotalCells}
                      />
                    ))}
                  </Table.Body>
                </Table>
              </div>
            )}
          </div>
        )}
      />
    </ContentPanel>
  );
};

const TableRow = ({ data, displayCpiCells, displayTotalCells }) => (
  <Table.Row key={data.id}>
    <Table.Cell style={{ width: "4%" }} className="strong">
      {data.unit}
    </Table.Cell>
    <Table.Cell
      style={{ width: "5%" }}
      className={yesNoFormatter(data.managed).toLowerCase()}
    >
      {yesNoFormatter(data.managed)}
    </Table.Cell>
    <Table.Cell style={{ width: "11%" }} className="strong right-align">
      {numericFormatter(data.minVol, 0, 0)}
    </Table.Cell>
    <CollapsibleCells
      data={data.cpi}
      displayAll={displayCpiCells}
      decimalPlaces={3}
    ></CollapsibleCells>
    <CollapsibleCells
      data={data.total}
      displayAll={displayTotalCells}
      decimalPlaces={2}
    ></CollapsibleCells>
    <Table.Cell
      style={{ width: "4%" }}
      className={yesNoFormatter(data.tonerExclusive).toLowerCase()}
    >
      {yesNoFormatter(data.tonerExclusive)}
    </Table.Cell>
    <Table.Cell
      style={{ width: "4%" }}
      className={yesNoFormatter(data.tieredToner).toLowerCase()}
    >
      {yesNoFormatter(data.tieredToner)}
    </Table.Cell>
  </Table.Row>
);

const CollapsibleCells = ({ data, displayAll, decimalPlaces }) => (
  <React.Fragment>
    <Table.Cell style={{ width: "9%" }}>
      {numericFormatter(data.capital, 0, decimalPlaces)}
    </Table.Cell>
    {displayAll && (
      <React.Fragment>
        <Table.Cell style={{ width: "9%" }}>
          {numericFormatter(data.services, 0, decimalPlaces)}
        </Table.Cell>
        <Table.Cell style={{ width: "9%" }}>
          {numericFormatter(data.cpi, 0, decimalPlaces)}
        </Table.Cell>
        <Table.Cell style={{ width: "9%" }} className="strong right-align">
          {numericFormatter(data.cpu, 0, decimalPlaces)}
        </Table.Cell>
      </React.Fragment>
    )}
  </React.Fragment>
);

export default Item;
