import React from "react";
import { ListBuilder, Table, ActionBar } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { getRollWidth } from "./actions";
import AddRollWidth from "./AddRollWidth";
import Add from "features/../../../shared/assets/white-add.svg";
import RollWidthActions from "./RollWidthActions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { useTranslation } from "react-i18next";

const ListRollWidths = ({ dealerOfficeId }) => {
  const { t } = useTranslation("supplierOffices", {
    keyPrefix: "largeFormat.rollWidth",
  });
  return (
    <PermissionCheck target={Targets.LargeFormat} action={Actions.View}>
      <React.Fragment>
        <h4 className="settings-title">{t("title")}</h4>
        <ListBuilder
          withTable
          initialPagination={{ pageSize: 25, pageNumber: 1 }}
          loadAction={getRollWidth}
          loadParams={{ dealerOfficeId }}
          dataTransform={data => data.results}
          totalTransform={data => data.totalResults}
          renderList={(tableProps, state, events) => (
            <div className="content-container principal-list">
              <div className="action-bar-div">
                <ActionBar.Item>
                  <AddRollWidth
                    primary
                    dealerOfficeId={{ dealerOfficeId }}
                    icon={Add}
                    content={t("newRollWidth")}
                    onAdded={events.onRefresh}
                  />
                </ActionBar.Item>
              </div>
              <Table {...tableProps} dataKey="id">
                <Table.Column field="name" title={t("name")} width="" />
                <Table.Column
                  width=""
                  title={t("widthMM")}
                  render={record =>
                    record && (record.widthMm || "").toString().length
                      ? record.widthMm
                      : "-"
                  }
                />
                <Table.Column
                  width=""
                  title={t("widthInch")}
                  render={record =>
                    record && (record.widthInches || "").toString().length
                      ? record.widthInches.friendlyString
                      : "-"
                  }
                />
                <Table.Column
                  hoverable
                  width=""
                  align="right"
                  render={rollWidth => (
                    <RollWidthActions
                      dealerOfficeId={dealerOfficeId}
                      rollWidth={rollWidth}
                      onRefresh={events.onRefresh}
                    />
                  )}
                />
              </Table>
              <TablePagination
                pageNumber={state.pagination.pageNumber}
                onChange={events.onChangePagination}
                pageSize={state.pagination.pageSize}
                totalItems={state.total}
              />
            </div>
          )}
        />
      </React.Fragment>
    </PermissionCheck>
  );
};

export default ListRollWidths;
