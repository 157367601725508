import React from "react";
import { ListBuilder, Table, ActionBar } from "@redriver/cinnamon";
import { TablePagination } from "components/pagination";
import { getPapers } from "./actions";
import { PermissionCheck } from "features/../../../shared/components/auth";
import { Targets, Actions } from "constants/permissions";
import { useTranslation } from "react-i18next";
import { SortableTable } from "features/../../../shared/components/tables/sortableTable";
import { ViewPaper } from ".";
import { TextSearch } from "features/../../../shared/components/search";

const ListLargeFormatPaper = ({ dealerOfficeId }) => {
  const majorCurrency = process.env.MAJOR_CURRENCY;
  const minorCurrency = process.env.MINOR_CURRENCY;

  const { t } = useTranslation("supplierOffices", {
    keyPrefix: "largeFormat.paper",
  });
  const columns = [
    { field: "description", title: t("description") },
    { field: "code", title: t("code") },
    { field: "ipsBillingCode", title: t("ipsBillingCode") },
    { field: "ipsSku", title: t("ipsSku") },
    { field: "lengthM", title: t("lengthM") },
    { field: "lengthFeet", title: t("lengthFeet") },
    {
      field: "cost",
      title: process.env.USE_MINOR_CURRENCY
        ? t("costMinor", { minorCurrency })
        : t("costMajor", { majorCurrency }),
      sortable: false,
    },
    {
      field: "defaultMinVolume",
      title: t("defaultMinVolume"),
      sortable: false,
    },
    {
      field: "",
      title: "",
      sortable: false,
      render: x => (
        <Table.Menu>
          <ViewPaper paper={x} as={Table.MenuItem} />
        </Table.Menu>
      ),
    },
  ];
  return (
    <PermissionCheck target={Targets.LargeFormat} action={Actions.View}>
      <React.Fragment>
        <h4>{t("title")}</h4>
        <ListBuilder
          withTable
          initialPagination={{ pageSize: 25, pageNumber: 1 }}
          loadAction={getPapers}
          loadParams={{ dealerOfficeId }}
          dataTransform={result => {
            return result.results;
          }}
          totalTransform={data => data.totalResults}
          renderList={(tableProps, state, events) => (
            <div className="content-container principal-list lf-list-columns">
              <ActionBar>
                <ActionBar.Item fluid>
                  <TextSearch
                    filters={state.filters}
                    onChange={events.onChangeFilters}
                  ></TextSearch>
                </ActionBar.Item>
                <ActionBar.Item align="right"></ActionBar.Item>
              </ActionBar>
              <SortableTable
                state={state}
                events={events}
                tableProps={tableProps}
                emptyMessage={t("emptyMessage")}
                columns={columns}
                selectable={false}
              />
              <TablePagination
                pageNumber={state.pagination.pageNumber}
                onChange={events.onChangePagination}
                pageSize={state.pagination.pageSize}
                totalItems={state.total}
              />
            </div>
          )}
        />
      </React.Fragment>
    </PermissionCheck>
  );
};

export default ListLargeFormatPaper;
