import { apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SETTINGS/RESTRICTIONS/VOLUME_SELECTION";

const ActionTypes = {
  UpdateVolumeSelectionRestrictions: `${NAMESPACE}/UPDATE`,
};

export const updateVolumeSelectionRestrictions = (formData, { settingsId }) =>
  apiPut(
    ActionTypes.UpdateVolumeSelectionRestrictions,
    `defaults-restrictions/${settingsId}`,
    {
      volumeSelection: formData,
    }
  );
