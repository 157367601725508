import React, { useState } from "react";
import { ContentPanel } from "components/displays";
import { Format } from "@redriver/cinnamon";
import {
  getCustomerAddresses,
  getCustomerInvoices,
  getCustomerContacts,
} from "../actions";
import { Checkbox, Button, Icon } from "semantic-ui-react";
import { currencyFormatter } from "components/displays/DisplayHelper";
import classNames from "classnames";
import { SimpleTableBuilder } from "../../../../../../shared/components/tables/sortableTable";

const CompanySummary = () => {
  const [tableExtended, setTableExtended] = useState(false);

  const addressColumns = [
    { field: "siteRef", title: "Site Reference" },
    { field: "line1", title: "Address Line 1" },
    { field: "line2", title: "Address Line 2" },
    { field: "line3", title: "Address Line 3" },
    { field: "town", title: "Town/City" },
    { field: "county", title: "County" },
    { field: "postcode", title: "Post Code" },
    { field: "country", title: "Country" },
    { field: "timeZone", title: "Time Zone" },
    { field: "taxCode", title: "Tax Code" },
    { field: "currency", title: "Currency" },
    { field: "telephone", title: "Telephone" },
    {
      field: "email",
      title: "Email",
      render: (item) => <a href={"mailto:" + item.email}>{item.email}</a>,
    },
    {
      field: "validated",
      title: "Validated",
      render: (item) => <Checkbox defaultChecked={item.validated} disabled />,
    },
    {
      field: "primary",
      title: "Primary",
      render: (item) => <Checkbox defaultChecked={item.primary} disabled />,
    },
    {
      field: "productLocation",
      title: "Product Location",
      render: (item) => (
        <Checkbox defaultChecked={item.productLocation} disabled />
      ),
    },
    {
      field: "invoice",
      title: "Invoice",
      render: (item) => <Checkbox defaultChecked={item.invoice} disabled />,
    },
    {
      field: "territory",
      title: "Territory",
      render: (item) => <Checkbox defaultChecked={item.territory} disabled />,
    },
  ];

  const invoiceColumns = [
    { field: "no", title: "Invoice Number" },
    {
      field: "dt",
      title: "Date",
      render: (item) => <Format.Date value={item.dt} format="DD MMM YYYY" />,
    },
    {
      field: "scheduleRef",
      title: "Schedule Ref/ID",
      className: "has-total-row",
    },
    {
      field: "net",
      title: "Net Amount",
      render: (item) => currencyFormatter(item.net, process.env.CURRENCY_CODE),
      className: "has-total-row",
    },
    {
      field: "gross",
      title: "Gross Amount",
      render: (item) =>
        currencyFormatter(item.gross, process.env.CURRENCY_CODE),
      className: "has-total-row",
    },
    {
      field: "outstanding",
      title: "Outstanding Balance",
      render: (item) =>
        currencyFormatter(item.outstanding, process.env.CURRENCY_CODE),
      className: "has-total-row",
    },
    {
      field: "due",
      title: "Due Date",
      render: (item) => <Format.Date value={item.due} format="DD MMM YYYY" />,
    },
    {
      field: "status",
      title: "Status",
      render: (item) => (
        <span className={item.status == "Overdue" ? "red-text" : ""}>
          {" "}
          {item.status}{" "}
        </span>
      ),
    },
  ];

  const contactColumns = [
    { field: "name", title: "First Name" },
    { field: "surname", title: "Last Name" },
    {
      field: "email",
      title: "Email Address",
      render: (item) => <a href={"mailto:" + item.email}>{item.email}</a>,
    },
    { field: "phone", title: "Phone" },
    { field: "role", title: "Role" },
    { field: "territory", title: "Territory" },
  ];

  return (
    <React.Fragment>
      <div className="company-summary">
        <ContentWrapper title="Addresses">
          <div className="horizontal-scrollbar">
            <SimpleTableBuilder
              loadAction={getCustomerAddresses}
              emptyMessage="No addresses found"
              columns={addressColumns}
            />
          </div>
        </ContentWrapper>
        <br />
        <br />
        <ContentWrapper title="Invoices">
          <div className="horizontal-scrollbar">
            <SimpleTableBuilder
              loadAction={getCustomerInvoices}
              emptyMessage="No invoices found"
              columns={invoiceColumns}
              className="invoice-table"
            />
          </div>
        </ContentWrapper>
        <br />
        <br />
        <ContentWrapper title="Contact Details">
          <div
            className={classNames(
              "vertical-scrollbar ",
              tableExtended ? "extended" : "small retracted"
            )}
          >
            <SimpleTableBuilder
              loadAction={getCustomerContacts}
              emptyMessage="No contacts found"
              columns={contactColumns}
            />
          </div>
          <div className="extension-icon">
            <Button onClick={() => setTableExtended(!tableExtended)}>
              {tableExtended ? (
                <Icon name="angle up" />
              ) : (
                <Icon name="angle down" />
              )}
            </Button>
          </div>
        </ContentWrapper>
      </div>
    </React.Fragment>
  );
};

const ContentWrapper = ({ title, children }) => (
  <ContentPanel
    title={title}
    wrapperCssClass="outline-grid"
    headerCssClass="defaults-page-title"
  >
    <div className="padded">{children}</div>
  </ContentPanel>
);

export default CompanySummary;
