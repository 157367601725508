import React, { useState, useEffect } from "react";
import { Accordion, Icon } from "semantic-ui-react";

const AccordionContentPanel = ({
  title,
  children,
  className,
  hideTitle = false,
  wrapperCssClass,
  headerCssClass,
  headerContent,
  expand = false,
  contract = true,
  collapsedOnLoad = false,
}) => {
  //hook for managing state of accordion
  const [collapsed, setCollapsed] = useState(false);

  //function to contract accordion from parent class
  useEffect(() => {
    setCollapsed(true);
  }, [contract]);

  //function to expand accordion from parent class
  useEffect(() => {
    setCollapsed(false);
  }, [expand]);

  //set collapsed status on load
  useEffect(() => {
    setCollapsed(collapsedOnLoad);
  }, [collapsedOnLoad]);

  return (
    <div className={"content-panel " + wrapperCssClass}>
      <Accordion className={className}>
        <Accordion.Title active={!collapsed}>
          <div className={headerCssClass + " flex"}>
            <div className="flex title-bar">
              {!hideTitle && (
                <h4
                  className="settings-title"
                  onClick={() => setCollapsed(!collapsed)}
                >
                  {title}
                  <Icon
                    name="dropdown"
                    rotated={collapsed ? "counterclockwise" : null}
                  />
                </h4>
              )}
            </div>

            <div>{headerContent}</div>
          </div>
        </Accordion.Title>
        <Accordion.Content active={!collapsed}>{children}</Accordion.Content>
      </Accordion>
    </div>
  );
};

export default AccordionContentPanel;
