import React from "react";
import { ListUsers } from "features/../../../shared/features/Settings";
import { PageContent } from "@redriver/cinnamon";

const UserListPage = () => (
  <PageContent>
    <ListUsers />
  </PageContent>
);

export default UserListPage;
